import { isAdmin, isTenant, roles } from '_graphql/_enums'
import {
  GET_SERVICE_REQUEST,
  SERVICE_REQUEST_UPSERT_MUTATION,
  SUBSCRIBE_SERVICE_REQUEST,
} from '_graphql/serviceRequest'
import Calendar from '@2fd/ant-design-icons/lib/Calendar'
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import {
  ConsoleSqlOutlined,
  EditOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { Link, navigate } from '@reach/router'
import {
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  PageHeader,
  Popover,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  Timeline,
  Typography,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useModals } from 'common/ModalsProvider'
import { ChatBox } from 'components/Chat'
import { ContactBox } from 'components/ContactBox'
import { ContractSelect } from 'components/Form/ContractSelect'
import { UserSelect } from 'components/Form/UserSelect'
import { LayoutCard } from 'components/Layout'
import { user } from 'components/ListManager/DefaultTypes'
import moment, { now } from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-use'
import { BuildingDetails, RentalContractDetails } from 'screens'
import { useGlobalState } from 'state'
import stc from 'string-to-color'
import styles from 'styles/side-layout.less'
import { db } from 'utils/dexie'

const ServiceRequestView = ({ forceQuery, onClose, ...props }) => {
  const serviceRequestId = parseInt(props?.id) || undefined
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagmentUser = isAdmin(state.currentUser?.role)
  const isTenantUser = isTenant(state.currentUser?.role)
  const [activeTab, setActiveTab] = useState('chat')
  const [isForm, setIsForm] = useState(!serviceRequestId)
  const [form] = Form.useForm()
  const { openModal } = useModals()
  const [
    fetch,
    { subscribeToMore, data: requestData, loading: requestLoading },
  ] = useLazyQuery(GET_SERVICE_REQUEST, {
    variables: { id: serviceRequestId },
    fetchPolicy: 'no-cache',
  })

  const [request, setRequest] = useState()
  useEffect(() => {
    setRequest(requestData?.response)
  }, [requestData])

  
  const address = request?.contract?.apartment?.building?.addressDetails?.formatted_address
  const buildingSku = request?.contract?.apartment?.building?.id

  const location = useLocation()
  const query = useMemo(
    () => (forceQuery ? forceQuery : new URLSearchParams(location.search)),
    [location.search]
  )

  useEffect(() => {
    if (query.get('contractId')) {
      form.setFieldsValue({
        contractId: +query.get('contractId'),
      })
    }
  }, [query.get('contractId')])

  // Last seen
  useEffect(() => {
    if (request?.id) {
      db.serviceRequestsLastSeen.put({
        id: request.id,
        updatedAt: request.updatedAt,
      })
    }
  }, [request])

  // Subscribe
  useEffect(() => {
    if (subscribeToMore) {
      return subscribeToMore({
        document: SUBSCRIBE_SERVICE_REQUEST,
        variables: { id: serviceRequestId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData?.data?.response) {
            return prev
          }
          setRequest(subscriptionData?.data?.response)
        },
      })
    }
  }, [subscribeToMore])

  useEffect(() => {
    if (request === null && serviceRequestId) {
      message.error('Rental contract not found')
      navigate('/service-requests')
    }
  }, [request])

  // Fetch data
  useEffect(() => {
    if (serviceRequestId) {
      fetch()
    }
  }, [])

  // Form is opened
  useEffect(() => {
    if (request) {
      form.setFieldsValue(request)
    }
  }, [isForm])

  // Submit
  const [upsertServiceRequests] = useMutation(SERVICE_REQUEST_UPSERT_MUTATION)
  const onSubmit = async (values) => {
    const { title, context, type, status, managerId, contractId } = values

    try {
      const { data } = await upsertServiceRequests({
        variables: {
          input: {
            id: serviceRequestId,
            title,
            context,
            type: type ?? 'service',
            status: status ?? 'new',
            managerId: managerId
              ? managerId
              : isManagmentUser
                ? state.currentUser?.id
                : null,
            contractId: contractId ? contractId : +props.contractId,
          },
        },
      })

      message.success('Saved')

      if (onClose && query.has('backUrl')) {
        onClose()
      } else if (serviceRequestId === data.response.id) {
        setIsForm(false)
      } else {
        navigate(`/service-requests/view/${data.response.id}`)
      }
    } catch (e) {
      message.error('The form is filled out incorrectly')
      navigate('/service-requests')
    }
  }

  return (
    <div className={styles.container}>
      <Form
        form={form}
        className={styles.side}
        layout={isForm ? 'vertical' : 'horizontal'}
        onFinish={onSubmit}
      >
        <PageHeader
          className={styles.sideHeader}
          ghost={false}
          title={
            serviceRequestId ? request?.title : t('serviceRequests.form.new')
          }
          tags={
            request?.status ? (
              isManagmentUser ? (
                <Link to={`/service-requests/edit/${request?.id}`}>
                  <Tag color={stc('-' + request?.status)}>
                    {t(`service-requests.stasuses.${request?.status}`)}
                  </Tag>
                </Link>
              ) : (
                <Tag color={stc('-' + request?.status)}>
                  {t(`service-requests.stasuses.${request?.status}`)}
                </Tag>
              )
            ) : null
          }
          // extra={isManagmentUser ? [
          //   <Link to={`/service-requests/edit/${request?.id}`} key="edit">
          //     <Button>
          //       {t('service-requests.actions.edit')}
          //     </Button>
          //   </Link>
          // ] : null}
        >
          {request?.sku ? (
            <Typography.Text type="secondary">
              #{request?.sku?.slice?.(0, 8)}
            </Typography.Text>
          ) : null}
        </PageHeader>

        {/* Date created */}
        {!isForm ? (
          <>
            <Typography.Title level={5}>
              <Calendar style={{ marginRight: 5 }} />
              {t('serviceRequests.page.dates')}
            </Typography.Title>

            {request?.createdAt ? (
              <>
                <div>
                  <Typography.Text strong>
                    {t('serviceRequests.page.createdAt')}:{' '}
                  </Typography.Text>
                  {moment(request?.createdAt).format('YYYY-MM-DD')}
                </div>
                <div>
                  <Typography.Text strong>
                    {t('serviceRequests.page.updatedAt')}:{' '}
                  </Typography.Text>
                  {moment(request?.updatedAt).format('YYYY-MM-DD')}
                </div>
              </>
            ) : null}
          </>
        ) : null}

        {/* Title */}
        {isForm ? (
          <Form.Item
            label={t('serviceRequests.form.title') + ':'}
            name="title"
            style={{ marginBottom: 15 }}
            required
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        ) : null}

        {/* Status */}
        {isForm && isManagmentUser ? (
          <Form.Item
            label={t('serviceRequests.form.status') + ':'}
            name="status"
            style={{ marginBottom: 15 }}
            required
            rules={[{ required: true }]}
            initialValue="new"
          >
            <Select>
              {Object.keys(
                t('serviceRequests.stasuses', { returnObjects: true })
              ).map((status) => (
                <Select.Option value={status} key={status}>
                  {t(`serviceRequests.stasuses.${status}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}

        <Divider />

        <Typography.Title level={5}>
          <TeamOutlined style={{ marginRight: 5 }} />
          {t('serviceRequests.page.objectives')}
        </Typography.Title>

        {/* Type */}
        <Form.Item
          label={t('service-requests.fields.type') + ':'}
          name="type"
          required={isForm}
          rules={[{ required: true }]}
          initialValue="service"
        >
          {isForm && (isManagmentUser || !serviceRequestId) ? (
            <Select>
              {Object.keys(
                t('serviceRequests.types', { returnObjects: true })
              ).map((type) => (
                <Select.Option value={type} key={type}>
                  {t(`serviceRequests.types.${type}`)}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Tag color={stc(request?.type)}>
              {request?.type
                ? t(`service-requests.types.${request?.type}`)
                : '-'}
            </Tag>
          )}
        </Form.Item>

        {/* Contract */}
        <Form.Item
          label={t('serviceRequests.form.contract') + ':'}
          name="contractId"
          required={isForm}
          rules={[{ required: true }]}
        >
          {isForm ? (
            <ContractSelect
              disabled={
                (!!serviceRequestId && !isManagmentUser) ||
                query.has('contractId')
              }
            />
          ) : (
            <a
              onClick={() =>
                openModal(RentalContractDetails, {
                  sku: request?.contract?.sku,
                })
              }
            >
              {`#${request?.contract?.sku?.slice?.(0, 8)}`}
            </a>
          )}
        </Form.Item>

        {/* address */}
        {!isForm && address ? (
          <Form.Item label={t('serviceRequests.page.address') + ':'}>
            <a
              onClick={() =>
                openModal(BuildingDetails, {
                  id: buildingSku,
                })
              }
            >
              {address}
            </a>  
          </Form.Item>
 
        ) : null}

        {!isForm && request?.owner ? (
          <Form.Item name="ownerId" >
            <ContactBox user={request?.owner} />
          </Form.Item>
        ) : null}


        {/* Manager */}
        {!isForm || isManagmentUser ? (
          <Form.Item name="managerId">
            {isForm && isManagmentUser ? (
              <UserSelect roles="b2b-managers" />
            ) : request?.manager ? (
              <ContactBox user={request?.manager}/>
            ) : (
              null
            )}
          </Form.Item>
        ) : null}

        {/* Description */}
        <Form.Item
          label={isForm ? t('service-requests.fields.description') + ':' : null}
          name="context"
          required={isForm}
          rules={[{ required: true }]}
        >
          {isForm ? <TextArea /> : null}
        </Form.Item>

        {/* Edit button */}
        {isManagmentUser && !isForm && serviceRequestId ? (
          <Button
            loading={requestLoading}
            onClick={() => setIsForm(true)}
            block
          >
            {t('service-requests.actions.edit')}
          </Button>
        ) : null}

        {/* Edit form buttons */}
        {isManagmentUser && isForm && serviceRequestId ? (
          <>
            {/* Save button */}
            <Button
              loading={requestLoading}
              type="primary"
              htmlType="submit"
              block
            >
              {t('serviceRequests.form.save')}
            </Button>

            {/* Cancel button */}
            <Button
              type="text"
              onClick={() => setIsForm(false)}
              block
              style={{ marginTop: 15 }}
            >
              {t('serviceRequests.form.cancel')}
            </Button>
          </>
        ) : null}

        {/* Submit */}
        {isForm && !props.id ? (
          <>
            {/* Save button */}
            <Button
              loading={requestLoading}
              type="primary"
              htmlType="submit"
              block
            >
              {t('serviceRequests.form.save')}
            </Button>
          </>
        ) : null}
      </Form>

      {request ? (
        <div className={styles.content}>
          <LayoutCard loading={requestLoading} type="inner">
            <h3 style={{ fontSize: '20px' }}>
              {t('service-requests.view-title')}
            </h3>

            <Tabs
              activeKey={activeTab}
              onChange={setActiveTab}
              className={styles.contentTabs}
            >
              <Tabs.TabPane tab={t('serviceRequests.page.chat.title')} key="chat" />
            </Tabs>
          </LayoutCard>

          {/* Chat */}
          <ChatBox
            title
            entityType="service-request"
            entityId={serviceRequestId}
            сlosed={request?.status != 'completed' ? false : true}
          />
        </div>
      ) : null}
    </div>
  )
}

export default ServiceRequestView
