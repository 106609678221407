import { gql } from 'apollo-boost'

import { recurringPaymentFragment } from '../_fragments'

export const RP_UPSERT_MUTATION = gql`
  mutation recurringPayment($input: RecurringPaymentInput!) {
    response: recurringPayment(input: $input) {
      ...recurringPaymentFragment
    }
  }
  ${recurringPaymentFragment}
`

export const RP_DELETE_MUTATION = gql`
  mutation deleteRP($sku: String) {
    response: deleteRP(sku: $sku)
  }
`
