import { PROFILE_LIST } from '_graphql/profile'
import Eye from '@2fd/ant-design-icons/lib/Eye'
import Plus from '@2fd/ant-design-icons/lib/Plus'
import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Select, Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProfileForm } from 'screens'

function ProfileSelect({ 
  userId,
  value = null,
  onChange,
  ...props
}) {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(!!value)
  const { openModal } = useModals()

  const [fetch, { data, loading }] = useLazyQuery(PROFILE_LIST)

  useEffect(() => {
    if (userId && (value || isFocused)) {
      fetch({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            users: [userId],
          }),
        }
      })
    }
  }, [value, isFocused, userId])

  const setValue = (value) => {
    onChange?.(value)
  }

  return (
    <div style={{ display: 'flex' }}>
      <Select
        showSearch
        loading={loading}
        disabled={!userId}
        onChange={setValue}
        onFocus={() => { !isFocused && setIsFocused(true) }}
        value={value}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...props}
      >
        <Select.Option value={null}>{t('rental-request.components.sales-select.not-selected')}</Select.Option>
        {data?.response?.list?.map?.((profile) => (
          <Select.Option value={profile.id} key={profile.id}>
            {profile.companyName} (#{profile.id})
          </Select.Option>
        ))}
      </Select>
      <div>
        {/* Show profile */}
        {value ? (
          <Tooltip title="View profile" placement="right">
            <Button
              type="text" 
              icon={<Eye />} 
              onClick={() => {
                openModal(ProfileForm, {
                  query: { id: value },
                  onClose() {
                    fetch()
                  }
                })
              }}
            />
          </Tooltip>
        ) : null}
        {/* Create profile */}
        {!value ? (
          <Tooltip title="Create profile" placement="right">
            <Button 
              type="text" 
              icon={<Plus />} 
              disabled={!userId}
              onClick={() => {
                openModal(ProfileForm, {
                  userId,
                  onClose(id) {
                    if (id) {
                      fetch()
                      setValue(id)
                    }
                  }
                })
              }}
            />
          </Tooltip>
        ) : null}
      </div>
    </div>
  )
}

export { ProfileSelect }
