import { gql } from 'apollo-boost'

import { projectFragment } from '../_fragments'

export const PROJECT_UPSERT_MUTATION = gql`
  mutation project($input: ProjectInput!) {
    response: project(input: $input) {
      ...projectFragment
    }
  }
  ${projectFragment}
`

export const PROJECT_DELETE_MUTATION = gql`
  mutation archiveProject($id: Int!) {
    archiveProject(id: $id)
  }
`
