import { DownOutlined } from '@ant-design/icons'
import { Button,Dropdown } from 'antd'
import React from 'react'

/**
 * Dropdown фильтр
 */
// eslint-disable-next-line arrow-body-style
export const DropdownFilter = ({ children, icon, title, preview }) => {
  return (
    <Dropdown overlay={<div><div className="ant-dropdown-menu" onClick={(e) => e.stopPropagation()}>{children}</div></div>} placement="bottomLeft" arrow>
      <Button type={preview.isDisabled ? 'default' : 'primary'} icon={icon} className="list-manager-filter__button">
        {preview.isDisabled || preview.showTitle === true ? <span style={{ margin: '0 5px', opacity: 0.7 }}>{title}:</span> : null}
        <span className="list-manager-filter__preview">{preview.render}</span>
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}
