import { WBS_LIST } from '_graphql'
import Eye from '@2fd/ant-design-icons/lib/Eye'
import Plus from '@2fd/ant-design-icons/lib/Plus'
import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Select, Tooltip } from 'antd'
import { useModals, useOnCloseModalSubscription } from 'common/ModalsProvider'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WbsView } from 'screens'
import { WbsForm } from 'screens/WbsManager/WbsForm'

function WBSSelect({ 
  projectId = null,
  value = null,
  onChange,
  ...props
}) {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(!!value)
  const { openModal, events: modalEvents } = useModals()

  const [fetch, { data, loading }] = useLazyQuery(WBS_LIST)

  const WBSModalId = useOnCloseModalSubscription({
    onClose: () => {
      fetch()
    }
  })

  const WBSCreateModalId = useOnCloseModalSubscription({
    onClose: async (id) => {
      if (id) {
        await fetch()
        setValue(id)
      }
    }
  })

  console.log(WBSModalId, WBSCreateModalId)

  useEffect(() => {
    if (projectId && (value || isFocused)) {
      fetch({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            projectIds: [projectId],
          }),
        }
      })
    }
  }, [value, isFocused, projectId])

  const setValue = (value) => {
    onChange?.(value)
  }

  return (
    <div style={{ display: 'flex' }}>
      <Select
        showSearch
        loading={loading}
        disabled={!projectId}
        style={{ width: '100%' }}
        onChange={setValue}
        onFocus={() => { !isFocused && setIsFocused(true) }}
        value={value}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...props}
      >
        <Select.Option value={null}>{t('rental-request.components.sales-select.not-selected')}</Select.Option>
        {data?.response?.list?.map?.((wbs) => (
          <Select.Option value={wbs.id} key={wbs.id}>
            {wbs.code}
          </Select.Option>
        ))}
      </Select>
      <div>
        {/* Show */}
        {value ? (
          <Tooltip title="View WBS" placement="right">
            <Button
              type="text" 
              icon={<Eye />} 
              onClick={() => {
                openModal(WbsView, {
                  modalId: WBSModalId,
                  sku: data?.response?.list?.find?.((wbs) => (wbs.id === value))?.sku,
                })
              }}
            />
          </Tooltip>
        ) : null}
        {/* Create */}
        {!value ? (
          <Tooltip title="Create WBS" placement="right">
            <Button 
              type="text" 
              icon={<Plus />} 
              disabled={!projectId}
              onClick={() => {
                openModal(WbsForm, {
                  modalId: WBSCreateModalId,
                  projectId,
                })
              }}
            />
          </Tooltip>
        ) : null}
      </div>
    </div>
  )
}

export { WBSSelect }
