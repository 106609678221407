import { SyncOutlined } from '@ant-design/icons'
import { Button, Tooltip, Typography } from 'antd'
import moment from 'moment'
import React, { useEffect,useState } from 'react'
import { useTranslation } from 'react-i18next'

/** Переодичность перезагрузки данных */
const AUTO_RELOAD_PERIODICITY = 300000 // 5 min's

/** Minimal delay between перезагрузками данных */
const MINIMAL_REFETCH_DELAY = 4000 // 4 sec's

/**
 * Автоматическая перезагрузка данных менеджера списков
 */
export const AutoReload = ({ dataLoading, dataError, dataRefetch, dataLastFetchDate }) => {
  const { t } = useTranslation()
  const [tabIsFocused, setTabIsFocused] = useState(!window?.document?.hidden)
  const [timerId, setTimerId] = useState(null)

  /** Может ли пользователь инициировать перезагружку данных вручную */
  const canRefetch = !dataLoading && tabIsFocused && moment().add(MINIMAL_REFETCH_DELAY * -1, 'millisecond').isAfter(dataLastFetchDate)

  /** Триггер обновления данных | Вкладка должна быть активной, последняя перезагрузка не раньше 15 секунд назад */
  const reloadTrigger = () => {
    if (canRefetch) {
      dataRefetch()
    }
  }

  /** Является ли вкладка в фокусе */
  useEffect(() => {
    const onFocus = () => setTabIsFocused(true)
    const onBlur = () => setTabIsFocused(false)

    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)

    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  /** Перезагружаем данные, когда вкладка входит в фокус */
  useEffect(reloadTrigger, [tabIsFocused])

  /** Автоматическая перезагрузка данных по таймеру если вкладка находится в фокусе */
  const clearTimer = () => timerId && clearTimeout(timerId) && setTimerId(null)
  useEffect(() => clearTimer, [])
  useEffect(() => {
    setTimerId(setTimeout(reloadTrigger, AUTO_RELOAD_PERIODICITY))
    return clearTimer
  }, [dataLastFetchDate])

  return (
    <Tooltip title={dataLastFetchDate.fromNow()} placement="right">
      <Button
        disabled={!canRefetch}
        type="text"
        danger={!!dataError}
        icon={<SyncOutlined spin={dataLoading} />}
        onClick={reloadTrigger}
        className="list-manager__refetch-button"
      >
        <Typography.Text type="secondary">{t('list-manager.actions.reload')}</Typography.Text>
      </Button>
    </Tooltip>
  )
}
