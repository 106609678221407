import { gql } from 'apollo-boost'

import { messageFragment } from '../_fragments'

export const MESSAGE_MUTATION = gql`
  mutation message($input: MessageInput!) {
    message(input: $input) {
      ...messageFragment
    }
  }
  ${messageFragment}
`
