import './styles.css'

import { UPLOAD_ATTACHMENT } from '_graphql'
import { DownloadOutlined, EyeOutlined, FileExcelOutlined, FileImageOutlined, FilePdfOutlined, FileUnknownOutlined, FileWordOutlined, FolderAddOutlined, FolderOutlined, UploadOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Button, Form,Input, List, message, Modal, Tooltip, Upload } from 'antd'
import { LayoutCard, LayoutCardTitle } from 'components/Layout'
import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const AttachmentsBlock = ({ attachments, refetch, relationType, relationId }) => {
  const { t } = useTranslation()
  const [uploadAttachment] = useMutation(UPLOAD_ATTACHMENT)
  const [openedAttachment, openAttachment] = useState(null)

  const [currentDirectory, setCurrentDirectory] = useState(null)
  const directories = useMemo(() => [...new Set(['Rental Contract', 'Protocols', 'invoices', 'Correspondence', 'Service Requests', 'Claims', 'Termination', 'Vaucher', 'Pictures MI', 'Pictures Mo', 'Other',  ...attachments.map((item) => (item.context.directory ?? ''))])].filter((item) => !!item).map((path) => ({
    path,
    title: t(`attachments.directories.${path}`, { defaultValue: path }),
    count: attachments.filter((item) => item.context.directory === path).length,
  })), [attachments])

  const [createDirectory, setCreateDirectory] = useState(null)
  const createDirectoryErorr = useMemo(() => {
    const path = String(createDirectory).trim().toLowerCase()

    // Empty
    if (!path) {
      return t('attachments.directoryEmpty')
    }

    // Exist's
    if (directories.find((item) => item.path.toLowerCase() === path)) {
      return t('attachments.directoryAlreadyExists')
    }

    return null
  }, [directories, createDirectory])

  const uploadProps = {
    showUploadList: false,
    name: 'file',
    multiple: true,

    // Uplaod status notification
    onChange(info) {
      const { status } = info.file
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },


    // Upload
    customRequest: async ({ file, onSuccess }) => {
      await uploadAttachment({
        variables: {
          file,
          relationType: String(relationType),
          relationId: relationId,
          directory: currentDirectory?.path ?? '',
        },
      })

      onSuccess()
      return refetch()
    },
  }

  return (
    <LayoutCard>
      <LayoutCardTitle>
        <span onClick={() => setCurrentDirectory(null)} style={currentDirectory ? { cursor: 'pointer', color: 'var(--antd-wave-shadow-color)' } : {}}>{t('attachments.title')}</span>
        {currentDirectory ? <> / <FolderOutlined /> {currentDirectory.title}</> : null}

        {/* Actions */}
        <span style={{ float: 'right' }}>
          {/* Create directory */}
          {currentDirectory ? null : <Button icon={<FolderAddOutlined />} style={{ marginRight: 15 }} onClick={() => setCreateDirectory('')}>{t('attachments.actions.createDirectory')}</Button>}

          {/* Upload button */}
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>{t('attachments.actions.upload')}</Button>
          </Upload>
        </span>
      </LayoutCardTitle>

      {/* Files & Directories */}
      <List
        itemLayout="horizontal"
        dataSource={[...(currentDirectory ? [] : directories), ...attachments.filter((item) => (item.context.directory ?? '') === (currentDirectory?.path ?? ''))]}
        className='attachments-block-list'
        renderItem={(item) => {
          /** Directories */
          if (item.path) {
            return (
              <List.Item onClick={() => setCurrentDirectory(item)} style={{ cursor: 'pointer' }}>
                <List.Item.Meta
                  avatar={<span style={{ fontSize: 27 }}><FolderOutlined /></span>}
                  title={item.title}
                  description={`${item.count} items`}
                />
              </List.Item>
            )
          }

          /** Files */
          // Icon
          let icon = <FileUnknownOutlined />
          let canOpenInPreview = false
          if (item.context.mimetype.includes('image')) {
            icon = <FileImageOutlined />
            canOpenInPreview = true
          } else if (item.context.mimetype.includes('pdf')) {
            icon = <FilePdfOutlined />
            canOpenInPreview = true
          } else if (item.context.mimetype.includes('word')) {
            icon = <FileWordOutlined />
          } else if (item.context.mimetype.includes('excel') || item.context.mimetype.includes('xls')) {
            icon = <FileExcelOutlined />
          }

          return (
            <List.Item actions={[
              canOpenInPreview
                ? (
                  <Tooltip title={t('attachments.actions.preview')}>
                    <Button type="link" size="small" style={{ padding: 0 }} onClick={() => openAttachment(item)}>
                      <EyeOutlined style={{ fontSize: 20 }} />
                    </Button>
                  </Tooltip>
                ) : null,
              <Tooltip key={999} title={t('attachments.actions.download')}>
                <a href={item.context.url} target="_blank" rel="noopener noreferrer" download={item.context.filename}>
                  <DownloadOutlined style={{ fontSize: 20 }} />
                </a>
              </Tooltip>,
            ].filter((action) => !!action)}
            >
              <List.Item.Meta
                avatar={<span style={{ fontSize: 27 }}>{icon}</span>}
                title={(
                  canOpenInPreview
                    ? (
                      <Button type="text" size="small" style={{ padding: 0 }} aria-label={t('attachments.actions.preview')} onClick={() => openAttachment(item)}>
                        {item.context.filename}
                      </Button>
                    ) : (
                      <a href={item.context.url} target="_blank" rel="noopener noreferrer">
                        {item.context.filename}
                      </a>
                    )
                )}
                description={t('attachments.createdAt', { createdAt: DateTime.fromISO(item.createdAt).toFormat('yyyy-LL-dd HH:mm') })}
              />
            </List.Item>
          )
        }}
      />

      {/* Create directory */}
      <Modal
        title={t('attachments.actions.createDirectory')}
        visible={createDirectory !== null}
        onCancel={() => setCreateDirectory(null)}
        onOk={() => { if (!createDirectoryErorr) { setCurrentDirectory({ path: createDirectory, title: createDirectory, count: 0 }); setCreateDirectory(null) } }}
      >
        <Form.Item validateStatus={createDirectoryErorr ? 'error' : null} help={createDirectoryErorr} onChange={(e) => setCreateDirectory(e.target.value)}>
          <Input placeholder="Directory name" defaultValue="" autoFocus />
        </Form.Item>
      </Modal>

      {/* Attachemnt preview */}
      <Modal title={openedAttachment?.context?.filename ?? 'Unnamed'} visible={!!openedAttachment} onCancel={() => openAttachment(null)} footer={null} bodyStyle={{ padding: 0, display: 'flex' }} width={750}>
        {openedAttachment?.context?.mimetype?.includes('image') ? (
          <img
            src={openedAttachment?.context?.url}
            alt={openedAttachment?.context?.filename ?? ''}
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        ) : null}
        {openedAttachment?.context?.mimetype?.includes('pdf') ? <iframe title={openedAttachment?.context?.filename ?? 'Unnamed'} src={openedAttachment?.context?.url} style={{ width: '100%', minHeight: '60vh', border: 'none' }} /> : null }
      </Modal>

    </LayoutCard>
  )
}

/* <Dragger showUploadList={false} customRequest={uploadFloorPlanImg} {...uprops}>
<p className="ant-upload-drag-icon">
  <InboxOutlined />
</p>
<p className="ant-upload-text">{t('apartments.messages.upload-text')}</p>
</Dragger> */
