import { gql } from 'apollo-boost'

export const billRunFragment = gql`
  fragment billRunFragment on BillRun {
    id
    sku
    customerId
    customerProfileId
    grossPrice
    isPaid
    volume
    issueMonth
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`

