import { gql } from 'apollo-boost'

export const amenityFragment = gql`
  fragment amenityFragment on Amenity {
    id
    title
    status
    apartmentId
    typeId
    type {
      id
      title
      status
    }
  }
`
