import { RENTAL_REQUESTS_STATUSES,USER_ROLES } from '_graphql/_enums'
import i18n from 'i18next'
import stc from 'string-to-color'

/** Стандартный булева тип */
const boolean = () => [{
  key: '$none$',
  title: 'Irrelevant',
}, {
  key: '$true$',
  title: 'Yes',
}, {
  key: '$false$',
  title: 'No',
}]

/** Роли пользователей */
const role = (availableRoles) => Object.values(USER_ROLES).filter((role) => !availableRoles || availableRoles.includes(role.id)).map((role) => ({
  key: role.key,
  title: role.title,
  color: role.color,
}))

/** Статусы запросов аренды */
const requestStatuses = () => Object.values(RENTAL_REQUESTS_STATUSES).map((status) => ({
  key: status.key,
  title: status.title,
  color: status.color,
}))

/** Типы запроса сервиса */
const serviceRequestTypes = () => Object.keys(i18n.t('serviceRequests.types', { returnObjects: true })).map((type) => ({
  key: type,
  title: i18n.t(`serviceRequests.types.${type}`),
  color: stc(type)
}))


Object.values(RENTAL_REQUESTS_STATUSES).map((status) => ({
  key: status.key,
  title: status.title,
  color: status.color,
}))

export const presets = {
  boolean,
  role,
  requestStatuses,
  serviceRequestTypes
}

/** Получение значений */
export const getOptions = ({ options }) => {
  // Provided values
  if (options.values) {
    return options.values
  }

  // Preset
  if (presets[options.valuesPreset]) {
    return presets[options.valuesPreset](options.valuesPresetOptions)
  }

  // Empty
  return []
}
