import { Drawer } from 'antd'
import React, { useCallback,useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

const RightOverlay = () => {
  const [state, dispatch] = useGlobalState()

  return (
    <Drawer
      placement="right"
      closable
      onClose={() => {
        dispatch({ type: 'COLLAPSE_RIGHT_BAR' })
        dispatch({ type: 'SET_CURRENT_BUILDING', payload: {} })
        dispatch({ type: 'SET_RIGHT_BAR_COMPONENT', payload: null })
      }}
      visible={!!state.rightBarComponent}
      width={400}
      bodyStyle={{ padding: 0 }}
    >
      {state.rightBarComponent ? <state.rightBarComponent /> : null}
    </Drawer>
  )
}

export { RightOverlay }
