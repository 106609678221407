import { gql } from 'apollo-boost'

export const GET_TUTORIALS = gql`
  query getTutorials {
    response: getTutorials {
      data {
        id
        type
      }
    }
  }
`
