import { gql } from 'apollo-boost'

import { apartmentFragment, buildingFragment, photoFragment, profileFragment, rentalRequestFragment, userFragment } from '../_fragments'

export const REQUESTS_LIST = gql`
  query listRequests($page: Int!, $pageSize: Int!, $sorting: String, $search: String, $filter: JSON) {
    response: listRequests(page: $page, pageSize: $pageSize, sorting: $sorting, search: $search, filter: $filter) {
      list {
        ...rentalRequestFragment
        tenant {
          ...userFragment
        }
        tenantEmployee
        {
          ...userFragment
        }
        sales {
          ...userFragment
        }
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${rentalRequestFragment}
  ${userFragment}
`

export const ALL_RENTAL_REQUESTS_QUERY = gql`
  query allRentalRequests {
    response: allRentalRequests {
      ...rentalRequestFragment
      tenant {
        ...userFragment
      }
      sales {
        ...userFragment
      }
    }
  }
  ${rentalRequestFragment}
  ${userFragment}
`

export const RENTAL_REQUEST_QUERY = gql`
  query findRentalRequest($id: Int!) {
    response: findRentalRequest(id: $id) {
      ...rentalRequestFragment
      sales {
        id
        firstName
      }
      salesId
      tenant {
        ...userFragment
      }
      tenantId
      history {
        id
        oldValue
        newValue
        field
        createdAt
        user {
          ...userFragment
        }
      }
      apartmentsList {
        id
        requestId
        apartmentId
        inspect
        apartment {
          ...apartmentFragment
          building {
            ...buildingFragment
          }
          photos {
            ...photoFragment
          }
        }
        status
        reason
      }
    }
  }
  ${rentalRequestFragment}
  ${apartmentFragment}
  ${buildingFragment}
  ${userFragment}
  ${photoFragment}
`

export const RENTAL_REQUEST_SKU_QUERY = gql`
  query findRentalRequestBySku($sku: String!) {
    response: findRentalRequestBySku(sku: $sku) {
      ...rentalRequestFragment
      apartmentsList {
        id
        apartmentId
        requestId
        status
        reason
        inspect
        apartment { 
          ...apartmentFragment
          building {
            ...buildingFragment
            profile {
              ...profileFragment
            }
          }
          photos {
            ...photoFragment
          }
        }
      }
      tenant {
        ...userFragment
      }
      sales {
        ...userFragment
      }
    }
  }
  ${rentalRequestFragment}
  ${apartmentFragment}
  ${buildingFragment}
  ${userFragment}
  ${photoFragment}
  ${profileFragment}
`

export const RENTAL_REQUEST_CHANGED = gql`
subscription rentalRequestChanged($sku: String!) {
  response: rentalRequestChanged(sku: $sku) {
    ...rentalRequestFragment
    apartmentsList {
      id
      apartmentId
      requestId
      status
      reason
      inspect
      apartment { 
        ...apartmentFragment
        building {
          ...buildingFragment
          profile {
            ...profileFragment
          }
        }
        photos {
          ...photoFragment
        }
      }
    }
    tenant {
      ...userFragment
    }
    sales {
      ...userFragment
    }
  }
}
${rentalRequestFragment}
${apartmentFragment}
${buildingFragment}
${userFragment}
${photoFragment}
${profileFragment}
`

export const SEARCH_PROPERTY_QUERY = gql`
  query searchProperty($search: String!) {
    response: searchProperty(search: $search) {
      ...apartmentFragment
        photos {
          ...photoFragment
        }
        building {
          ...buildingFragment
        }
      }
  }
  ${buildingFragment}
  ${apartmentFragment}
  ${photoFragment}
`
