import { gql } from 'apollo-boost'

import { profileFragment,rentalContractFragment,serviceFragment,userFragment } from '../_fragments'

export const SERVICES_LIST = gql`
  query listServices(
    $page: Int!
    $pageSize: Int!
    $sorting: String
    $search: String
    $filter: JSON
  ) {
    response: listServices(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      search: $search
      filter: $filter
    ) {
      list {
        ...serviceFragment
        serviceProvider {
          ...profileFragment
        }
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${serviceFragment}
  ${profileFragment}
`

export const FETCH_SERVICES = gql`
  query fetchServices {
  response: fetchServices {
    ...serviceFragment
    serviceProvider {
      ...profileFragment
    }
    }
  } 
  ${serviceFragment}
  ${profileFragment}
 `

export const FETCH_SERVICES_BY_CONTRACT = gql`
  query fetchServicesByContract($contractId: Int!) {
    response: fetchServicesByContract(contractId: $contractId) {
      ...serviceFragment
    }
  }
  ${serviceFragment},
`

export const FETCH_SERVICE_BY_SKU = gql`
  query fetchServiceBySku($sku: String) {
    response: fetchServiceBySku(sku: $sku) {
      ...serviceFragment
        serviceProvider {
          ...profileFragment
        }
        contract {
         ...rentalContractFragment
        }
        editor {
          ...userFragment
        }
        creator {
          ...userFragment
        }
    }
  }
  ${serviceFragment},
  ${profileFragment},
  ${rentalContractFragment},
  ${userFragment},
`
