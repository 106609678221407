import { NOTIFICATIONS_LIST, NOTIFICATIONS_STATISTICS } from '_graphql'
import { isTenant,NOTIFICATION_GROUPS, NOTIFICATION_TYPES, roles } from '_graphql/_enums'
import BagPersonalOutline from '@2fd/ant-design-icons/lib/BagPersonalOutline'
import BedKingOutline from '@2fd/ant-design-icons/lib/BedKingOutline'
import Book from '@2fd/ant-design-icons/lib/Book'
import BookClock from '@2fd/ant-design-icons/lib/BookClock'
import BookMinus from '@2fd/ant-design-icons/lib/BookMinus'
import ChevronRight from '@2fd/ant-design-icons/lib/ChevronRight'
import Cog from '@2fd/ant-design-icons/lib/Cog'
import ExitRun from '@2fd/ant-design-icons/lib/ExitRun'
import Fireplace from '@2fd/ant-design-icons/lib/Fireplace'
import SlashForward from '@2fd/ant-design-icons/lib/SlashForward'
import TransitTransfer from '@2fd/ant-design-icons/lib/TransitTransfer'
import { BankOutlined, ContainerOutlined, EnvironmentOutlined, EuroCircleOutlined } from '@ant-design/icons'
import { ProCard, StatisticCard } from '@ant-design/pro-components'
import { useQuery } from '@apollo/react-hooks'
import { Attachment } from '@material-ui/icons'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { Link } from '@reach/router'
import { Button, Card, Col, Empty, Row, Space, Typography } from 'antd'
import Axios from 'axios'
import { CounterCard } from 'components/DashboardWidgets/CounterCard'
import { FancyCard } from 'components/DashboardWidgets/FancyCard'
import { LayoutCard } from 'components/Layout'
import { cost } from 'components/ListManager/DefaultTypes'
import uniqBy from 'lodash/uniqBy'
import { DateTime } from 'luxon'
import moment from 'moment'
import numbro from 'numbro'
import RcResizeObserver from 'rc-resize-observer'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import stc from 'string-to-color'

const { Statistic } = StatisticCard

import styles from './style.less'

const imgStyle = {
  display: 'block',
  width: 42,
  height: 42,
}

const LandlordsDashboard = ({
  data,
}) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const [responsive, setResponsive] = useState(false)
  const role = state.currentUser?.role



  const counters = Object.fromEntries(data || [])

  // console.log(counters)
  const totalOfRent = (counters?.['total-of-rents'] || []).map?.(([date, values]) => ([
  ]))?.flat?.() || []

  // console.log(counters['invoices-history-global'])

  const colors = { 'Rent': 'rgb(29, 195, 131)', 'Utilities': 'hsl(97, 70%, 50%)', 'Services': 'green' }
  const getColor = bar => colors[bar.id]

  console.log(state.currentUser)
  
  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={6}>
          <LayoutCard> 

            <StatisticCard
              colSpan={responsive ? 24 : 6}
              title={t('dashboard.counters.apartments')}
              statistic={{
                value: (counters['all-apartments'] ? counters['all-apartments'] : 0) + ' / ' + (counters['offered-apartments'] ? counters['offered-apartments'] : 0),
                description: <Statistic title="Load" value={((counters['offered-apartments'] / counters['all-apartments']) * 100).toFixed(2) + '%'} />,
                icon: (
                  <div className={styles.counterIcon} style={{ '--color': '#1DC383' }}>
                    <BedKingOutline />
                  </div>
                ),
              }}
            />
            
          </LayoutCard>
        </Col>
        <Col span={18}>
          <LayoutCard> 
            <RcResizeObserver
              key="resize-observer"
              onResize={(offset) => {
                setResponsive(offset.width < 596)
              }}
            >
              <StatisticCard.Group direction={responsive ? 'column' : 'row'}>
              
                <StatisticCard
                  title={t('dashboard.counters.active-rental-bookings')}
                  statistic={{
                    value: counters['active-rental-bookings'],
                    description: <Statistic title="Change" value={(100-(counters['active-rental-bookings'] / counters['last-rental-bookings']) * 100).toFixed(1)+'%'} trend="up" />,
                    icon: (
                      <div className={styles.counterIcon} style={{ '--color': '#1DC383' }}>
                        <ContainerOutlined />
                      </div>
                    ),
                  }}
                />
                <StatisticCard
                  title={t('dashboard.counters.moveIn')}
                  statistic={{
                    value: counters['moveIn-current-month'],
                    // description: <Statistic title="Change" value="10%" trend="up" />,
                    icon: (
                      <div className={styles.counterIcon} style={{ '--color': '#1DC383' }}>
                        <EnvironmentOutlined />
                      </div>
                    ),
                  }}
                />
                <StatisticCard
                  title={t('dashboard.counters.changeOfUser')}
                  statistic={{
                    value: counters['cou-current-month'],
                    // description: <Statistic title="Change" value="10%" trend="up" />,
                    icon: (
                      <div className={styles.counterIcon} style={{ '--color': '#1DC383' }}>
                        <TransitTransfer />
                      </div>
                    ),
                  }}
                />
                <StatisticCard
                  title={t('dashboard.counters.moveOut')}
                  statistic={{
                    value: counters['moveOut-current-month'],
                    // description: <Statistic title="Change" value="10%" trend="up" />,
                    icon: (
                      <div className={styles.counterIcon} style={{ '--color': '#1DC383' }}>
                        <ExitRun />
                      </div>
                    ),
                  }}
                />
              </StatisticCard.Group>
            </RcResizeObserver>
          </LayoutCard>
        </Col>
      </Row>
      

      <Row gutter={[20, 20]}>
        <Col xs={18}>
          <LayoutCard style={{ width: '100%', height: '485px' }}>
            {counters['invoices-history-global'] ? (
              <ResponsiveBar
                data={counters['invoices-history-global']}
                keys={[
                  'Rent',
                  'Utilities',
                  'Services',
                ]}
                indexBy="date"
                margin={{ top: 30, right: 90, bottom: 100, left: 50 }}
                colors={getColor}
                valueFormat={value => cost({value})}
                padding={0.2}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                  }
                ]}
                fill={[
                  {
                    match: {
                      id: 'Utilities'
                    },
                    id: 'empty'
                  },
                  {
                    match: {
                      id: 'Services'
                    },
                    id: 'lines'
                  }
                ]}
                borderColor={{
                  from: 'color',
                  modifiers: [
                    [
                      'darker',
                      1.6
                    ]
                  ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -90,
                  legendOffset: 80
                }}
                enableGridX={true}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Budget',
                  legendPosition: 'middle',
                  legendOffset: -60
                }}
                labelSkipWidth={15}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [
                    [
                      'darker',
                      1.6
                    ]
                  ]
                }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'top-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 30,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1
                        }
                      }
                    ]
                  }
                ]}
                role="application"
              />)

              : <Empty />}
          </LayoutCard>
        </Col>

        <Col span={6}>
          <LayoutCard style={{ backgroundColor: '#1DC383'}}> 
            <StatisticCard
              colSpan={responsive ? 24 : 6}
              style={{ backgroundColor: '#1DC383', color: '#fff !important' }}
              className="statCard"
              title={t('dashboard.counters.earnings')}
              statistic={{
                value: counters['invoices-rent-global'],
                prefix: '€',
                // description: <Statistic title="Change" value="6.47%" trend="down" />,
                icon: (
                  <div className={styles.counterIcon} style={{ '--color': '#fff' }}>
                    <BankOutlined style={{ color: '#1DC383' }}/>
                  </div>
                ),
              }}
            />

          </LayoutCard>
          <LayoutCard> 
            <StatisticCard
              colSpan={responsive ? 24 : 6}
              title={t('dashboard.counters.services')}
              statistic={{
                value: counters['servicesReqs'] + counters['provReqs'] + counters['damageReqs'],
                icon: (
                  <div className={styles.counterIcon} style={{ '--color': '#1DC383' }}>
                    <Cog />
                  </div>
                ),
              }}
              footer={
                <>
                  <Statistic value={counters['servicesReqs']} title={t('dashboard.counters.serviceTypes.service')} layout="horizontal" />
                  <Statistic value={counters['provReqs']} title={t('dashboard.counters.serviceTypes.provision')} layout="horizontal" />
                  <Statistic value={counters['damageReqs']} title={t('dashboard.counters.serviceTypes.damage')} layout="horizontal" />
                    
                </>
              }
            />
          </LayoutCard>
        </Col>
      </Row>
    
  

      {/* Total of rent */}
      
    </>
  )
}

export { CounterCard, LandlordsDashboard }
