import { Result } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <Result
      status="404"
      title="404"
      subTitle={t('system-messages.404-page-content')}
    />
  )
}

export { NotFound }
