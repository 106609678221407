import React from 'react'

import { useProject } from '../common/ProjectProvider'

function Illustration({ name, ...props }) {
  const project = useProject()
  const src = `/images/illustrations/${name}.${project.slug}.svg`
  const alt = name.replace(/(_|-|undraw)/g, ' ').trim()

  return <img src={src} alt={alt} {...props} />
}

export default Illustration
