
import ChevronRight from '@2fd/ant-design-icons/lib/ChevronRight'
import SlashForward from '@2fd/ant-design-icons/lib/SlashForward'
import { Link } from '@reach/router'
import { Button, Card, Col, Empty, Row, Space, Typography } from 'antd'
import React from 'react'

import styles from './style.less'
export function CounterCard ({ 
  Icon, 
  Divider = ChevronRight,
  count, 
  secondCount, 
  title, 
  link, 
  color,
  countTitle = 'Current month',
  secondCountTitle = 'Upcoming month',
  forceTitle = false
}) {
  if (Divider === 'line') {
    Divider = SlashForward
  }
  
  const card = (
    <Card hoverable={!!link} style={{ padding: '12px 17px' }} className="dashboard-page-card">
      <Space direction="horizontal" align="center" style={{ width: '100%' }}>
        {/* Icon */}
        <div className={styles.counterIcon} style={{ '--color': color }}>
          <Icon />
        </div>
  
        <div style={{ marginLeft: '10px' }}>
          {/* Title */}
          <Typography.Text type="secondary" style={{ fontSize: '1rem', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{title}</Typography.Text>
          {/* Counters */}
          <div style={{ height: 28, overflow: 'hidden', marginTop: -5 }}>
            <Typography.Text strong style={{ fontSize: '1.4rem' }}>{count}</Typography.Text>
            {secondCount !== undefined ? (<><Typography.Text strong style={{ fontSize: '1rem', marginLeft: 5, verticalAlign: 1 }} type="secondary"><Divider /> {secondCount}</Typography.Text></>) : null}
          </div>
          {/* Subtitle */}
          {secondCount !== undefined || forceTitle ? (<div style={{ opacity: '0.8', overflow: 'hidden', maxHeight: 22 }}>
            <Typography.Text strong style={{ fontSize: '0.65rem' }}>{countTitle}</Typography.Text>
            {secondCount !== undefined ? <Typography.Text strong style={{ fontSize: '0.6rem', marginLeft: 3, verticalAlign: 0.5 }} type="secondary"><Divider /> {secondCountTitle}</Typography.Text> : null}
          </div>) : null}
        </div>
      </Space>
    </Card>
  )
  
  return (
    <>
      {link && link?.startsWith?.('#') ? <a href={link}>{card}</a> : null }
      {link && !link?.startsWith?.('#') ? <Link to={link}>{card}</Link> : null }
      {!link ? card : null}
    </>
  )
}