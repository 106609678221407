import { APT_DELETE_MUTATION, APT_UPSERT_MUTATION, MAKE_SERVICE } from '_graphql'
import { isAdmin, USER_ROLES } from '_graphql/_enums'
import { APARTMENTS_LIST } from '_graphql/apartment/queries'
import CheckBold from '@2fd/ant-design-icons/lib/CheckBold'
import CloseThick from '@2fd/ant-design-icons/lib/CloseThick'
import EyeOutline from '@2fd/ant-design-icons/lib/EyeOutline'
import Lamp from '@2fd/ant-design-icons/lib/Lamp'
import ToggleSwitch from '@2fd/ant-design-icons/lib/ToggleSwitch'
import { CopyOutlined, CrownOutlined,DeleteOutlined, PlusOutlined, ReloadOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import Apartment from '@material-ui/icons/Apartment'
import CropDin from '@material-ui/icons/CropDin'
import Euro from '@material-ui/icons/Euro'
import EventAvailable from '@material-ui/icons/EventAvailable'
import EventBusy from '@material-ui/icons/EventBusy'
import EventNote from '@material-ui/icons/EventNote'
import InsertInvitation from '@material-ui/icons/InsertInvitation'
import Launch from '@material-ui/icons/Launch'
import Today from '@material-ui/icons/Today'
import { Link,navigate } from '@reach/router'
import { Button, message, Popconfirm, Tag, Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import { ListManager } from 'components/ListManager'
import { cost, string } from 'components/ListManager/DefaultTypes'
import { TutorialUIItem } from 'components/TutorialUI'
import { UseMapOverlay } from 'layouts/MapOverlay'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import styled from 'styled-components'
import { deepOmit } from 'utils/deepOmit'

import { ApartmentTile, defaultApartmentActions } from './ApartmentTile'
import { ApartmentDetails } from './ApatmentDetails'

const ApartmentsManager = ({ refetch, fullpage = false, overlay = true, title, hideFilters = false, actions, filterPresets, filterDefaultPreset, tiles, table, withoutCopy = false, type }) => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)
  const { openModal } = useModals()

  /** Редактирование апартаментов */
  const [upsertApartment] = useMutation(APT_UPSERT_MUTATION)

  const [runFix, { data: result }] = useLazyQuery(MAKE_SERVICE, { fetchPolicy: 'no-cache' })
  const updateApartmentAction = async (id, fields, callback, successMessage) => {
    const upsertion = await upsertApartment({ variables: { input: { id, ...fields } } })
    if (successMessage && upsertion?.data?.response) { message.success(successMessage) }
    if (callback) { callback() }
    return upsertion?.data?.response
  }

  /** Удаление апартаментов */
  const [deleteApartment] = useMutation(APT_DELETE_MUTATION)
  const deleteApartmentAction = async (item, callback) => {
    await deleteApartment({ variables: { id: item.id } })
    callback()
  }

  /** Клонирование апартаментов */
  const cloneApartmentAction = async (item) => {
    const { data: { response: { id } } } = await upsertApartment({
      variables: {
        input: {
          ...deepOmit({
            payload: item,
            keysToOmit: ['__typename', 'building', 'photos', 'floorPlans', 'id', 'sku', 'freeFrom', 'request', 'slots', 'services'],
          }),
          code: item.code + '(copy)',
          amenitiesOptions: item.amenitiesOptions,
          isActive: false,
          isBooked: false,
          freeFrom: null,
        },
      },
    })

    console.log('clone data', id)
    navigate(`/apartments-management/${id}`)
  }

  /** Создать апартаменты */
  const createApartmentAction = async () => {
    const { data: { response: { id } } } = await upsertApartment({ variables: { input: {} } })
    navigate(`/apartments-management/${id}`)
  }

  /** Оверлей Карты */
  UseMapOverlay()
  const zoomToApartmentAction = (item = null) => {
    dispatch({ type: 'BUILDING_HOVER', payload: item?.building?.id || -1 })
  }

  const makeServices = async () => {
    runFix()
    message.success('Automatic services check started')
  }

  return (
    <>
      <ListManager
        slug="apartments-manager"
        title={title || t('apartments.manager-title')}
        query={APARTMENTS_LIST}
        overlay={overlay}
        withoutCopy={withoutCopy}
        refetch={refetch}
        actions={actions ?? [
          (
            <TutorialUIItem key="add">
              <Button id='apartments-management-add-apartment-button' type="primary" icon={<PlusOutlined />} onClick={createApartmentAction}>{t('apartments.actions.add')}</Button>
            </TutorialUIItem>
          )
        ]}
        sorting={{
          default: 'availableFrom|asc',
          variations: [
            {
              slug: 'availableFrom|asc',
              title: t('apartments.sorting.availableFrom|asc'),
              icon: <Today className="anticon ant-dropdown-menu-item-icon" />,
            },
            {
              slug: 'availableFrom|desc',
              title: t('apartments.sorting.availableFrom|desc'),
              icon: <InsertInvitation className="anticon ant-dropdown-menu-item-icon" />,
            },
            {
              slug: 'id|desc',
              title: t('apartments.sorting.id|desc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('apartments.sorting.id|asc'),
              icon: <SortAscendingOutlined />,
            },
            {
              slug: 'squareMeters|desc',
              title: t('apartments.sorting.squareMeters|desc'),
              icon: <CropDin className="anticon ant-dropdown-menu-item-icon" />,
            },
            {
              slug: 'totalMonthPrice|asc',
              title: t('apartments.sorting.totalMonthPrice|asc'),
              icon: <Euro className="anticon ant-dropdown-menu-item-icon" />,
            },
            {
              slug: 'totalMonthPrice|desc',
              title: t('apartments.sorting.totalMonthPrice|desc'),
              icon: <Euro className="anticon ant-dropdown-menu-item-icon" />,
            },
            {
              slug: 'buildingId|desc',
              title: t('apartments.sorting.buildingId|desc'),
              icon: <Apartment className="anticon ant-dropdown-menu-item-icon" />,
            },
          ],
        }}
        filter={{
          defaultPreset: filterDefaultPreset || isManagementUser ? 'all' : 'active',
          presets: filterPresets || [
            {
              title: t('apartments.filter.presets.all'),
              slug: 'all',
              value: {},
            },
            {
              title: t('apartments.filter.presets.active'),
              slug: 'active',
              icon: <ToggleSwitch className="icon--mr" />,
              value: { isActive: true },
              middleware: () => !isManagementUser
            },
            {
              title: t('apartments.filter.presets.not-booked'),
              slug: 'not-booked',
              icon: <EventAvailable className="icon--mr" />,
              value: { isBooked: false },
            },
            {
              title: t('apartments.filter.presets.booked'),
              slug: 'booked',
              icon: <EventBusy className="icon--mr" />,
              value: { isBooked: true },
            },
          ],
          filters: hideFilters ? [] : [
            {
              title: t('apartments.filter.booking-status'),
              slug: 'isBooked',
              type: 'boolean',
              options: {
                values: [
                  { key: '$none$', title: t('apartments.filter.any'), icon: <EventNote className="icon--mr" /> },
                  { key: '$true$', title: t('apartments.filter.presets.booked'), icon: <EventBusy className="icon--mr" /> },
                  { key: '$false$', title: t('apartments.filter.presets.not-booked'), icon: <EventAvailable className="icon--mr" /> },
                ],
              },
            },
            // {
            //   title: t('apartments.filter.publish-status'),
            //   slug: 'isPublished',
            //   type: 'boolean',
            //   options: {
            //     values: [
            //       { key: '$none$', title: t('apartments.filter.any'), icon: <Lamp className="icon--mr" /> },
            //       { key: '$true$', title: t('apartments.fields.isPublished.$true$'), icon: <CheckBold className="icon--mr" /> },
            //       { key: '$false$', title: t('apartments.fields.isPublished.$false$'), icon: <CloseThick className="icon--mr" /> },
            //     ],
            //   },
            // },
            {
              title: t('apartments.filter.active-status'),
              slug: 'isActive',
              type: 'boolean',
              options: {
                values: [
                  { key: '$none$', title: t('apartments.filter.any'), icon: <ToggleSwitch className="icon--mr" /> },
                  { key: '$true$', title: t('apartments.fields.isActive.$true$'), icon: <CheckBold className="icon--mr" /> },
                  { key: '$false$', title: t('apartments.fields.isActive.$false$'), icon: <CloseThick className="icon--mr" /> },
                ],
              },
              middleware: () => !isManagementUser
            },
            {
              title: 'Landlord',
              slug: 'landlords',
              icon: <CrownOutlined />,
              middleware: isManagementUser,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: { role: [USER_ROLES['landlord-owner'].key] },
              },
            },
          ],
        }}
        table={table ?? {
          columns: [
            {
              title: t('apartments.fields.code'),
              key: 'code',
              width: 180,
              fixed: 'left',
              render: ({ value, item }) => (
                <div style={{ display: 'flex' }}>
                  <Link to={`/apartments-management/${item.id}`}>{value ?? `#${item.id}`}</Link>
                  <Button 
                    type="text" 
                    size="small" 
                    onClick={() => openModal(ApartmentDetails, { id: item.id })}
                  >
                    <EyeOutline />
                  </Button>
                </div>
              ),
            },
            {
              title: t('apartments.fields.address.title'),
              key: 'address',
              width: 350,
              render: ({ item }) => (
                <>
                  {/** Link to building */}
                  { item.building?.id ? (
                    <Link to={`/buildings-management/${item.building?.id}`}><Launch fontSize="small" className="icon--mr" /></Link>
                  ) : null }
                  {/** Builing address */}
                  {string({
                    value: [item.building?.city, item.building?.street, item.building?.number].filter((part) => !!part).join(', '),
                    options: { na: true },
                  })}
                </>
              ),
            },
            {
              title: t('apartments.fields.price'),
              key: 'price',
              width: 100,
              render: ({ item }) => {
                const taxRate = item?.building?.profile?.taxRate
                const hasTax = !Number.isNaN(taxRate)
                // eslint-disable-next-line no-mixed-operators
                const totalTax = cost({ value: (item.monthPrice + item.utilitiesPrice + item.deposit), vat: taxRate })
                return (
                  <Tooltip
                    placement="top"
                    title={(
                      <>
                        <strong>{t('apartments.fields.price')}:</strong> <CostSpan>{cost({ value: item.monthPrice, vat: taxRate })}</CostSpan><br />
                        <strong>{t('apartments.fields.utilities.title')}:</strong> <CostSpan>{cost({ value: item.utilitiesPrice, vat: taxRate })}</CostSpan><br />
                        <strong>{t('apartments.fields.deposit')}:</strong> <CostSpan>{cost({ value: item.deposit, vat: taxRate })}</CostSpan>
                        {hasTax ? <><br /><strong>{t('apartments.fields.tax')} {taxRate}%:</strong> <CostSpan>{cost({ value: totalTax })}</CostSpan></> : null}
                      </>
                    )}
                  >
                    {cost({ value: item.monthPrice + item.utilitiesPrice, vat: taxRate })}
                  </Tooltip>
                )
              },
            },
            {
              title: t('apartments.fields.rooms'),
              key: 'rooms',
              width: 100,
              className: 'align-center',
              render: 'string',
            },
            {
              title: t('apartments.fields.floor'),
              key: 'floor',
              render: ({ item }) => `${item.floor} ${item.side ? `(${item.side})` : ''}`.trim(),
            },
            {
              title: t('apartments.fields.isBooked.title'),
              key: 'isBooked',
              width: 100,
              render: ({ value }) => (
                value
                  ? (<Tag color="red">{t('apartments.fields.isBooked.$true$')}</Tag>)
                  : (<Tag color="green">{t('apartments.fields.isBooked.$false$')}</Tag>)
              ),
            },
            {
              title: t('apartments.fields.isActive.title'),
              key: 'isActive',
              width: 100,
              render: ({ value, item, context }) => {
                const handler = () => updateApartmentAction(item.id, { isActive: !value }, context.dataRefetch, `Great! Apartment has been successfully ${!value ? 'Activated' : 'Deactivated'}`)
                return (
                  <span role="button" tabIndex="0" aria-label={t('apartments.actions.apartment-status.toggle')} onClick={handler} onKeyPress={handler} className="cursor-pointer" style={{ display: 'block', minWidth: 102 }}>
                    {value
                      ? (<Tooltip title={t('apartments.actions.apartment-status.deactivate')}><Tag color="green">{t('apartments.fields.isActive.$true$')}</Tag></Tooltip>)
                      : (<Tooltip title={t('apartments.actions.apartment-status.activate')}><Tag color="red">{t('apartments.fields.isActive.$false$')}</Tag></Tooltip>)}
                  </span>
                )
              },
            },
            {
              title: t('apartments.fields.services'),
              key: 'services',
              width: 100,
              render: ({ value, item, context }) => {
                return (
                  <span role="button" tabIndex="0" aria-label={t('apartments.actions.apartment-status.toggle')} className="cursor-pointer" style={{ display: 'block', minWidth: 102 }}>
                    {item?.services ? <Tag color="green">{item.services.length}</Tag> : <Tag color="red">N/A</Tag>}
                  </span>
                )
              },
            },
            {
              key: 'actions',
              title: 'Actions',
              width: 100,
              fixed: 'right',
              render: ({ item, context }) => (
                <>
                  {/** Clone */}
                  <Tooltip placement="bottom" title="Duplicate">
                    <Popconfirm title="Clone this Apartment?" onConfirm={() => cloneApartmentAction(item)}>
                      <Button shape="circle" type="link" icon={<CopyOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                  {/** Remove */}
                  {!item.isBooked ? (
                    <Tooltip placement="bottom" title="Remove">
                      <Popconfirm title="Delete this Apartment?" onConfirm={() => deleteApartmentAction(item, context.dataRefetch)}>
                        <Button shape="circle" type="link" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </Tooltip> ) : null }
                </>
              ),
            },
          ],
        }}
        tiles={tiles || (({ item, context, handlers, index }) => (
          <ApartmentTile key={index} {...handlers} item={item} userRole={state.currentUser?.role} actions={defaultApartmentActions({ item, context, cloneApartmentAction, deleteApartmentAction })} />
        ))}
        handlers={{
          onMouseEnter: ({ item }) => {
            zoomToApartmentAction(item)
          },
          onMouseLeave: () => {
            zoomToApartmentAction(null)
          },
        }}
      />
    </>
  )
}

export { ApartmentDetails } from './ApatmentDetails'
export { ApartmentsManager }

export const CostSpan = styled.span`
  float: right;
  margin-left: 5px;
`
