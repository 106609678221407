import { isTenant, roles } from '_graphql/_enums'
import React from 'react'
import { useGlobalState } from 'state'

import { EditPart } from '../styled'

const EditWrapper = ({ inEditMode, marginBottom, children, onClick, handlers }) => {
  const [state] = useGlobalState()
  if (isTenant(state.currentUser?.role)) {
    return children
  }
  return (
    <EditPart handlers={handlers} inEditMode={inEditMode} marginBottom={marginBottom} onClick={onClick}>
      {children}
    </EditPart>
  )
}

export { EditWrapper }
