import DotsHorizontal from '@2fd/ant-design-icons/lib/DotsHorizontal'
import { Button, Dropdown, Menu, PageHeader as AntdPageHeader } from 'antd'
import { useProject } from 'common/ProjectProvider'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'

export function PageHeader({ avatar, title, subTitle, id, tags, extra, dropdown }) {
  const project = useProject()
  const formatedTitle = useMemo(() => (title ?? null), [title])
  const formatedSubTitle = useMemo(() => (((subTitle ? `${subTitle} ` : '') + (id ? `#${id}`.split('-')[0] : '')).trim()), [subTitle, id])
  const filteredDropdown = useMemo(() => (Array.isArray(dropdown) ? dropdown.filter(item => (!!item)) : []), [dropdown])

  return (
    <>
      {/* SEO */}
      {/* {formatedTitle ? (
        <Helmet>
          <title>{formatedTitle || project.title} {formatedSubTitle ? ` — ${formatedSubTitle}` : null}</title>
        </Helmet>
      ) : null} */}
      <AntdPageHeader
        key={9999}
        style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
        avatar={avatar}
        title={formatedTitle}
        subTitle={formatedSubTitle}
        tags={tags}
        extra={(
          <>
            {/* Actions */}
            {extra ?? null}
            {/* Dropdown */}
            {filteredDropdown.length > 1 ? (
              <Dropdown key="more" overlay={(
                <Menu>
                  {filteredDropdown.map((item, key) => (
                    <Menu.Item key={key}>
                      {item}
                    </Menu.Item>
                  ))}
                </Menu>
              )}>
                <Button>
                  <DotsHorizontal style={{ fontSize: 20, verticalAlign: 'top' }} />
                </Button>
              </Dropdown>
            ) : <>{filteredDropdown}</>}
          </>
        )}
      />
    </>
  )
}
