import { isTenant, roles } from '_graphql/_enums'
import { Button, Space } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { EditOverlay } from './EditOverlay'
import { EditWrapper } from './EditWrapper'

export const ActionButtons = ({ cancel, save }) => {
  const { t } = useTranslation()
  return (
    <div
      className="save-part"
      style={{ bottom: '-30px', right: '0', fontSize: '22px', color: 'green', textAlign: 'end' }}
    >
      <Space size={4}>
        <Button
          size="small"
          type="dashed"
          onClick={(e) => {
            e.stopPropagation()
            cancel('title')
          }}
        >
          {t('apartments.actions.cancel')}
        </Button>
        {/* <Popconfirm title="Are you sure？" onConfirm={save}> */}
        <Button size="small" type="primary" onClick={save}>
          {t('apartments.actions.save')}
        </Button>
        {/* </Popconfirm> */}
      </Space>
    </div>
  )
}

const Editor = ({
  EditComponent,
  ViewComponent,
  editModeName,
  blockName,
  editPart,
  setEditPart,
  dataIsEmpty,
  cancel,
  save,
  verticalPadding,
  marginBottom,
  data,
  profile,
  form,
}) => {
  const inEditMode = editModeName === editPart
  const [state] = useGlobalState()

  if (dataIsEmpty && !inEditMode) {
    return (
      <EditOverlay
        verticalPadding={verticalPadding}
        marginBottom={marginBottom}
        blockName={blockName}
        onClick={() => setEditPart(editModeName)}
      />
    )
  }

  if (inEditMode && !isTenant(state.currentUser.role)) {
    return (
      <EditWrapper
        inEditMode
        marginBottom={marginBottom}
      >
        <EditComponent data={data} form={form} blockName={blockName} editModeName={editModeName} />
        <ActionButtons cancel={cancel} save={save} />
      </EditWrapper>
    )
  }

  return (
    <EditWrapper
      marginBottom={marginBottom}
      onClick={() => {
        /* eslint-disable-next-line */
        form.setFieldsValue({...data, freeFrom: data?.freeFrom ? moment(data.freeFrom) : moment() })
        setEditPart(editModeName)
      }}
    >
      <ViewComponent data={data} profile={profile} />
    </EditWrapper>
  )
}

export { Editor }
