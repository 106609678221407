import { gql } from 'apollo-boost'

import {
  apartmentFragment,
  buildingFragment,
  floorPlanFragment,
  photoFragment,
  profileFragment,
  serviceFragment,
  userFragment,
} from '../_fragments'

// export const ALL_APARTMENTS_QUERY_LIGHT = gql`
//   query allApartments {
//     response: allApartments {
//       ...apartmentFragment
//     }
//   }
//   ${apartmentFragment}
// `;

export const APARTMENTS_LIST = gql`
    query listApartments($page: Int!, $pageSize: Int!, $sorting: String, $search: String, $filter: JSON) {
      response: listApartments(page: $page, pageSize: $pageSize, sorting: $sorting, search: $search, filter: $filter) {
        list {
          ...apartmentFragment
          building {
            id
            city
            street
            number
            addressDetails
            user {
            ...userFragment
            }
            profile {
              ...profileFragment
            }
          }
          photos {
            ...photoFragment
          }
          floorPlans {
            ...floorPlanFragment
          }
          services {
            ...serviceFragment
          }
        }
        pagination {
          page
          pageSize
          total
        }  
      }
  }
  ${apartmentFragment}
  ${photoFragment}
  ${floorPlanFragment}
  ${profileFragment}
  ${serviceFragment}
  ${userFragment}
`

export const ALL_APARTMENTS_QUERY = gql`
  query allApartmentsPages($page: Int!, $pageSize: Int!) {
    response: allApartmentsPages(page: $page, pageSize: $pageSize) {
      list {
        ...apartmentFragment
        building {
          id
          city
          street
          number
          addressDetails
        }
        photos {
          ...photoFragment
        }
        floorPlans {
          ...floorPlanFragment
        }
      }
      meta {
        page
        pageSize
        total
      }
    }
  }
  ${apartmentFragment}
  ${photoFragment}
  ${floorPlanFragment}
`

export const ALL_APARTMENTS_QUERY_FILTERED = gql`
  query allApartmentsPages($page: Int!, $pageSize: Int!, $filters: ApartmentFilters) {
    response: allApartmentsPages(page: $page, pageSize: $pageSize, filters: $filters ) {
      list {
        ...apartmentFragment
        building {
          ...buildingFragment
        }
        photos {
          ...photoFragment
        }
        floorPlans {
          ...floorPlanFragment
        }
      }
      meta {
        page
        pageSize
        total
      }
    }
  }
  ${apartmentFragment}
  ${photoFragment}
  ${floorPlanFragment}
  ${buildingFragment}
`

export const APARTMENT_QUERY = gql`
  query findApartment($id: Int) {
    response: findApartment(id: $id) {
      ...apartmentFragment
      floorPlans {
        ...floorPlanFragment
      }
      photos {
        ...photoFragment
      }
      building {
        ...buildingFragment
        photos {
          ...photoFragment
        }
      }
    }
  }
  ${apartmentFragment}
  ${floorPlanFragment}
  ${photoFragment}
  ${buildingFragment}
`

export const APARTMENT_BY_SKU_QUERY = gql`
  query findApartmentBySku($sku: String!) {
    response: findApartmentBySku(sku: $sku) {
      ...apartmentFragment
      floorPlans {
        ...floorPlanFragment
      }
      photos {
        ...photoFragment
      }
      building {
        ...buildingFragment
        photos {
          ...photoFragment
        }
      }
    }
  }
  ${apartmentFragment}
  ${floorPlanFragment}
  ${photoFragment}
  ${buildingFragment}
`

export const MAKE_SERVICE = gql`
  query makeServicesYearAgo {
    response: makeServicesYearAgo
  }

`
