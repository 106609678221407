import { Button, Space } from 'antd'
import React from 'react'

const ActionButtonsTest = ({ accept, decline, item }) => (
  <Space align="end" style={{ width: '100%', justifyContent: 'flex-end' }}>
    <Button size="large" type="primary" onClick={() => { accept(item) }}>
      Accept
    </Button>
    <Button size="large" danger type="ghost" onClick={() => { decline(item) }}>
      Decline
    </Button>
  </Space>
)

export { ActionButtonsTest }
