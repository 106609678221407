import countryList from '@umpirsky/country-list/data/en/country.json'
import { Select } from 'antd'
import { countryCodeEmoji } from 'country-code-emoji'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

/** Список ключей стран */
export const countries = Object.keys(countryList)

/** Рендер страны */
export const CountryRender = ({ code }) => {
  const { t } = useTranslation()

  try {
    return (
      <Country>
        <CountryIcon role="img" aria-label={t(`countries.${code}`)}>
          {countryCodeEmoji(code)}
        </CountryIcon>
        &nbsp;{t(`countries.${code}`)}
      </Country>
    )
  } catch {
    return '—'
  }
}

/** Список Select */
export const SelectCountry = (options = {}) => {
  const { t } = useTranslation()

  return (
    <Select
      style={{ width: '100%' }}
      placeholder={t('country-picker.placeholder')}
      showSearch
      filterOption={(input, option) => `${option.value}|${option.label}|${option.emoji}`.toLowerCase().indexOf(input.toLowerCase()) !== -1}
      {...options}
    >
      {countries.map((countryCode) => (
        <Select.Option value={countryCode} label={t(`countries.${countryCode}`)} emoji={countryCodeEmoji(countryCode)} key={countryCode}>
          <CountryRender code={countryCode} />
        </Select.Option>
      ))}
    </Select>
  )
}

/** Стили */
export const Country = styled.div`
    display: inline-block;
`

export const CountryIcon = styled.span`
    vertical-align: middle;
`
