import { gql } from 'apollo-boost'

import { profileFragment,projectFragment, userFragment, wbsFragment } from '../_fragments'

export const PROJECT_LIST = gql`
  query listProjects(
    $page: Int!
    $pageSize: Int!
    $sorting: String
    $search: String
    $filter: JSON
  ) {
    response: listProjects(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      search: $search
      filter: $filter
    ) {
      list {
        ...projectFragment
        owner {
          ...userFragment
          profiles {
              ...profileFragment
          }
        }
        manager {
          ...userFragment
        }
        pmo {
          ...userFragment
        }
        employees {
          ...userFragment
        }
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${projectFragment}
  ${userFragment}
  ${profileFragment}
`

export const PROJECT_SKU_QUERY = gql`
  query findProjectBySku($sku: String!) {
    response: findProjectBySku(sku: $sku) {
      ...projectFragment
      owner {
        ...userFragment
      }
      manager {
        ...userFragment
      }
      employees {
        ...userFragment
      }
      pmo {
        ...userFragment
      }
    }
  }
  ${projectFragment}
  ${userFragment}
`

export const ALL_PROJECTS = gql`
  query allProjects {
    response: allProjects {
      ...projectFragment
      owner {
        ...userFragment
      }
      manager {
        ...userFragment
      }
      employees {
        ...userFragment
      }
      pmo {
        ...userFragment
      }
    }
  }
  ${projectFragment}
  ${userFragment}
`

export const SUBSCRIBE_PROJECT = gql`
  subscription projectChanged($id: Int!)  {
    response: projectChanged(id: $id) {
      ...projectFragment
      owner {
        ...userFragment
      }
      manager {
        ...userFragment
      }
      pmo {
        ...userFragment
      }
    }
  }
  ${projectFragment}
  ${userFragment}
`

export const PROJECT_CITIES_LIST = gql`
    query listProjectsCities($page: Int!, $pageSize: Int!, $sorting: String, $search: String, $filter: JSON) {
      response: listProjectsCities(page: $page, pageSize: $pageSize, sorting: $sorting, search: $search, filter: $filter) {
        list {
          id
          title
        }
        pagination {
          page
          pageSize
          total
        }  
      }
  }
`