import { USER_UPSERT_MUTATION } from '_graphql'
import { USER_ROLES } from '_graphql/_enums'
import { useMutation } from '@apollo/react-hooks'
import { Button, Form, Typography } from 'antd'
import { UserSelect } from 'components/Form/UserSelect'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'


const AttachUser = ({ project, onClose }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [, rerender] = useState()
  const { userId } = form.getFieldsValue()
  const [upsertUser] = useMutation(USER_UPSERT_MUTATION)

  const attach = async () => {
    await upsertUser({
      variables: {
        input: { id: userId, projectId: project.id},
      },
    })

    onClose()
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFieldsChange={rerender}
    >
      <Typography.Title level={4}>{t('user.actions.attach')}</Typography.Title>
      <Form.Item name="userId">
        <UserSelect
          roles={[USER_ROLES['tentant-employee'].key,  USER_ROLES['tentant-project-manager'].key,  USER_ROLES['tentant-project-manager'].key, USER_ROLES['tentant-admin'].key, USER_ROLES['tentant-manager'].key]}
          filter={{ ownerId: project.ownerId }} 
        />
      </Form.Item>
      <Button type="primary" block disabled={!userId} onClick={attach}>
        {t('user.actions.attach')}
      </Button>
    </Form>
  )
}

export { AttachUser }
