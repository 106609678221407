import ContentCopy from '@2fd/ant-design-icons/lib/ContentCopy'
import EmailSend from '@2fd/ant-design-icons/lib/EmailSend'
import { Button, Dropdown, Menu,message } from 'antd'
import { useProject } from 'common/ProjectProvider'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCopyToClipboard } from 'react-use'

function Email({ email }) {
  const [, copyToClipboard] = useCopyToClipboard()
  const { t } = useTranslation()
  const project = useProject()
  const link = useMemo(() => `mailto:${email}?subject=${encodeURIComponent(project.title)}`, [email, project])

  const copyEmail = useCallback(() => {
    copyToClipboard(email)
    message.success(t('common.clipboard.done'))
  }, [email])

  if (!email) {
    return '-'
  }

  return (
    <Dropdown overlay={(
      <Menu>
        {/* Send email */}
        <Menu.Item icon={<EmailSend />}>
          <a href={link}>
            {t('widgets.email.sendEmail')}
          </a>
        </Menu.Item>
        {/* Copy email */}
        <Menu.Item icon={<ContentCopy />} onClick={copyEmail}>
          {t('widgets.email.copy')}
        </Menu.Item>
      </Menu>
    )} 
    placement="bottomLeft" 
    arrow
    >
      <Button type="link" href={link}>{email}</Button>
    </Dropdown>
  )
}

export default Email
