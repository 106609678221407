import { gql } from 'apollo-boost'

export const reportFragment = gql`
  fragment reportFragment on Report {
    id
    sku
    customerProfileId
    type
    issueMonth
    createdBy
    createdAt
    updatedAt
  }
`

