import { APT_UPSERT_MUTATION,BUILDING_QUERY, BUILDING_UPSERT_MUTATION, DELETE_BUILDING_MUTATION } from '_graphql'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Col, Form, message, Popover,Row } from 'antd'
import { gql } from 'apollo-boost'
import React, { useEffect, useReducer, useState } from 'react'
import { useGlobalState } from 'state'
import { deepOmit } from 'utils/deepOmit'

// import { history } from 'Layout/routing';
import { BuildingForm } from './BuildingForm'

const UPLOAD_FILE = gql`
  mutation($file: Upload!, $entityId: String) {
    photoUpload(file: $file, entityId: $entityId) {
      url
    }
  }
`

const DELETE_FILE = gql`
  mutation($id: Int!) {
    deletePhoto(id: $id)
  }
`

const initCreateBuildingState = {
  id: null,
  street: '',
  number: '',
  zipCode: '',
  city: '',
  addressDetails: '',
  areaDescription: '',
  buildingDescription: '',
  hasElevator: false,
  hasRamp: false,
  status: true,
}

const reducer = (state, action) => ({ ...state, ...action })

const PopoverDeleteContent = (props) => (
  <Row>
    <Col sm={12}>
      <Button type="link" block size="small" onClick={props.close}>
        Cancel
      </Button>
    </Col>
    <Col sm={12}>
      <Button type="primary" block size="small" onClick={props.delete}>
        Delete
      </Button>
    </Col>
  </Row>
)

const EditBuilding = (props) => {
  const [form] = Form.useForm()
  const [formState, dispatchFormState] = useReducer(reducer, initCreateBuildingState)
  const [state, dispatch] = useGlobalState()
  const [deletePopoverVisible, setDeletePopoverVisible] = useState()

  // const { refetch } = useQuery(BUILDING_QUERY, {
  //   variables: {
  //     id: state?.currentBuilding?.id,
  //   },
  //   onCompleted: (data) => {
  //     if (data?.response) {
  //       dispatch({ type: 'SET_CURRENT_BUILDING', payload: data.response });
  //     }
  //   },
  // });

  const { data, refetch } = useQuery(BUILDING_QUERY, {
    variables: {
      id: state?.currentBuilding?.id,
    },
    onCompleted: (building) => {
      if (building?.response) {
        dispatch({ type: 'SET_CURRENT_BUILDING', payload: building.response })
      }
    },
  })

  const [upsertApartment] = useMutation(APT_UPSERT_MUTATION)

  const [updateBuildingMutation] = useMutation(BUILDING_UPSERT_MUTATION)
  const [deleteBuildingMutation] = useMutation(DELETE_BUILDING_MUTATION)
  const [uploadFiles] = useMutation(UPLOAD_FILE)
  const [deleteFile] = useMutation(DELETE_FILE)

  const setFormValue = (e) => {
    dispatchFormState({ [e.target.name]: e.target.value })
  }

  const handleSwitch = (value, name) => {
    dispatchFormState({ [name]: value })
  }

  useEffect(() => {
    if (data?.response) {
      dispatch({ type: 'SET_CURRENT_BUILDING', payload: data.response })
    }
  },
  [data])

  useEffect(() => {
    form.setFieldsValue(state.currentBuilding)
  }, [state.currentBuilding]);  // eslint-disable-line

  const submit = async (cb) => {
    const input = deepOmit({
      payload: formState, keysToOmit: ['createdAt', 'updatedAt', 'photos', 'places', 'sku', 'apartments', 'user', '__typename'],
    })

    const res = await updateBuildingMutation({
      variables: {
        input,
      },
    })

    if (!res.errors?.length) {
      dispatch({ type: 'REFETCH', payload: 'ALL_BUILDINGS_QUERY' })
      dispatch({ type: 'SET_CURRENT_BUILDING', payload: {} })
      dispatch({ type: 'COLLAPSE_RIGHT_BAR' })

      const pathArray = document.location.pathname.split('/')

      if (pathArray.includes('apartments-management')) {
        await upsertApartment({
          variables: {
            input: {
              id: +pathArray[pathArray.length - 1],
              buildingId: res.data.response.id,
            },
          },
        })

        dispatch({ type: 'REFETCH', payload: 'APARTMENT_QUERY' })
      }
      message.success('Building has been updated')

      if (cb) cb(res.data.response.id)
    } else {
      message.error("Couldn't update building")
    }
  }

  const deleteBuilding = async () => {
    setDeletePopoverVisible(false)
    const res = await deleteBuildingMutation({ variables: { id: formState.id } })
    if (!res.errors?.length) {
      dispatch({ type: 'REFETCH', payload: 'ALL_BUILDINGS_QUERY' })
      dispatch({ type: 'SET_CURRENT_BUILDING', payload: {} })
      dispatch({ type: 'COLLAPSE_RIGHT_BAR' })
      message.success('Building has been deleted')
    } else {
      message.error("Couldn't delete building")
    }
  }

  const uploadImage = async (file) => {
    await uploadFiles({
      variables: {
        file,
        entityId: state.currentBuilding.sku,
      },
    })

    refetch()
  }

  const deleteImage = async (id) => {
    await deleteFile({
      variables: { id },
    })

    // fetchBuilding({
    //   variables: {
    //     id: state?.currentBuilding?.id,
    //   },
    //   onCompleted: (data) => {
    //     if (data?.response) {
    //       dispatch({ type: 'SET_CURRENT_BUILDING', payload: data.response });
    //     }
    //   },
    // });
  }

  return (
    <BuildingForm
      form={form}
      formState={formState}
      handleSwitch={handleSwitch}
      setFormValue={setFormValue}
      uploadImage={uploadImage}
      deleteImage={deleteImage}
    >
      <Row>
        <Col xs={12}>
          <Popover
            content={<PopoverDeleteContent close={() => setDeletePopoverVisible(false)} delete={deleteBuilding} />}
            title="This action can't be undone. Are you sure?"
            trigger="click"
            visible={deletePopoverVisible}
          >
            <Button size="large" block type="link" onClick={() => setDeletePopoverVisible(true)} data-test-id="deleteBuildingButton">
              Delete Building
            </Button>
          </Popover>
        </Col>

        <Col xs={12}>
          <Button size="large" block type="primary" onClick={() => submit(props.cb)} data-test-id="saveBuildingButton">
            Save
          </Button>
        </Col>
      </Row>
    </BuildingForm>
  )
}

export { EditBuilding }
