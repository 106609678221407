import { isAdmin, isLandlord, isTenant, roles, USER_ROLES } from '_graphql/_enums'
import ChevronRight from '@2fd/ant-design-icons/lib/ChevronRight'
import { Link } from '@reach/router'
import { Col, Popover, Row,Tag, Typography } from 'antd'
import { LayoutCard } from 'components/Layout'
import UserAvatar from 'components/User/Widgets/Avatar'
import Email from 'components/Widgets/Email'
import Phone from 'components/Widgets/Phone'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { user as User } from '../ListManager/DefaultTypes'
import styles from './styles.less'

export function ContactBoxLarge({ user, profile }) {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const currentUser = state.currentUser
  const name = [user?.firstName, user?.lastName].filter((part) => !!String(part || '').trim()).join(' ')
  const [visible, setVisible] = useState(false)

  return (
    <LayoutCard>
      
      {/* <UserAvatar size="large" user={user} /> */}
      <div className={styles.boxInfo}>
        {profile ? (
          <>
            <Row>
              <Col xs={12}>
                <Typography.Text type="secondary" className={styles.boxRole}>
                  {t('widgets.profileBox.companyDetails')}
                </Typography.Text>
              </Col>
              <Col xs={12} style={{ textAlign: 'right'}}> <Typography.Text type="secondary" className={styles.boxRole}>
                {isAdmin(user?.role) ? <Tag>{t('user.roleGroups.B2B')}</Tag> : null}
                {isLandlord(user?.role) ? <Tag>{t('user.roleGroups.LANDLORD')}</Tag> : null}
                {isTenant(user?.role) ? <Tag>{t('user.roleGroups.TENANT')}</Tag> : null}
              </Typography.Text></Col>
             
              <Col xs={24}>
                <div className={styles.item}>
                  <Typography.Text strong>{t('widgets.profileBox.companyName')}: </Typography.Text>
                  { isAdmin(currentUser?.role) ? <Link to={`/profiles-management/${profile.id}`}>
                    {profile?.companyName}
                  </Link> :  profile?.companyName }
                </div>
              
                <div className={styles.item}>
                  <Typography.Text strong>{t('widgets.profileBox.address')}: </Typography.Text>
                  {profile?.addressAddition}
                </div>
                 
                {/* Email */}
                { isAdmin(currentUser?.role) ?  <div className={styles.item}>
                  <Typography.Text strong>{t('widgets.profileBox.email')}: </Typography.Text>
                  <Email email={profile?.correspondanceEmail} />
                </div>  : null }

                {/* Phone */}
                { isAdmin(currentUser?.role)  ? <div className={styles.item}>
                  <Typography.Text strong>{t('widgets.profileBox.phone')}: </Typography.Text>
                  <Phone phone={profile?.phoneNumber} />
                </div> : null }
                <div className={styles.item}>
                  <Typography.Text strong>{t('widgets.profileBox.contactName')}: </Typography.Text>
                  {profile?.contactFullName}
                </div>
              </Col>
              
            </Row>
          </>
          
        ) : null}
        {/* Role */}

        <Row>
          <Col xs={24}>
            <Typography.Text type="secondary" className={styles.boxRole}>
              {t('widgets.profileBox.manager')}
            </Typography.Text>
          </Col><Col>
            {/* Name */}
            <div className={styles.item}>
              <Typography.Text strong>{t('widgets.profileBox.managerName')}: </Typography.Text>
              {name}
            </div>
            { isAdmin(currentUser?.role)  ?  <div className={styles.item}>
              <Typography.Text strong>{t('widgets.profileBox.phone')}: </Typography.Text>
              <Phone phone={user.phone} />
            </div> : null }
            { isAdmin(currentUser?.role)  ? <div className={styles.item}>
              <Typography.Text strong>{t('widgets.profileBox.phone')}: </Typography.Text>
              <Email email={user.email} />
            </div> : null }
    
          </Col>
        </Row>
        
      </div>
      {/* <ChevronRight className={styles.arrow} /> */}
      
    </LayoutCard>
  )

}
