import { colors } from '_colors'
import { ALL_BUILDINGS_QUERY,APARTMENT_BY_SKU_QUERY, APARTMENT_QUERY, APT_UPSERT_MUTATION, BUILDING_QUERY } from '_graphql'
import { isAdmin, isLandlord,isTenant, ROLE_GROUPS } from '_graphql/_enums'
import { CheckOutlined, CloseOutlined, InboxOutlined, PauseCircleOutlined,PlayCircleOutlined,PlusOutlined,PushpinTwoTone } from '@ant-design/icons'
import { useLazyQuery,useMutation, useQuery } from '@apollo/react-hooks'
import { Link, useParams } from '@reach/router'
import { Alert,Button, Col, Divider, Form, message, Row, Select, Switch, Tabs, Upload } from 'antd'
import { gql } from 'apollo-boost'
import { useModals } from 'common/ModalsProvider'
import { Gallery, GalleryRO, NotFound } from 'components'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import LayoutTabs from 'components/LayoutTabs'
import React, { useEffect,useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditBuilding } from 'screens/BuildingsManager/AsideComponents'
import { BuildingAddressForm } from 'screens/BuildingsManager/AsideComponents/BuildingAddressForm'
import { ProfileInfoCard } from 'screens/BuildingsManager/BuildingDetails/ProfileInfoCard'
import { ServiceForm, ServicesManager } from 'screens/ServicesManager'
import { useGlobalState } from 'state'
import { swap } from 'utils/arraySwap'
import { CountryRender } from 'utils/countries'

import { AddressDescription } from '../../../components/AddressDescription'
import { AptBuildingInfo } from './AptBuildingInfo'
import {
  Amenities,
  CodeEdit,
  CodeView,
  DescriptionEdit,
  DescriptionView,
  ExtrasEdit,
  ExtrasView,
  PropertyInfoEdit,
  PropertyInfoView,
  RentalConditionsEdit,
  RentalConditionsView,
  Rooms,
  TitleEdit,
  TitleView,
  TotalAmountView,
} from './formParts'
import { AmenitiesView } from './formParts/Amenities/AmenitiesView'
import { ActionButtons,Editor } from './formParts/Editor'
import { EditWrapper } from './formParts/EditWrapper'
import { RoomsView } from './formParts/Rooms'
import { AptWrapper, TabWrapper } from './styled'


const { TabPane } = Tabs

const uprops = {
  name: 'file',
  multiple: true,
  onChange(info) {
    const { status } = info.file
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`)
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  },
}

const UPLOAD_PHOTO = gql`
  mutation($file: Upload!, $entityId: String) {
    photoUpload(file: $file, entityId: $entityId) {
      url
    }
  }
`

const PHOTO_REORDER = gql`
  mutation($input: [PhotoInput]!) {
    orderUpdate(input: $input)
  }
`

const DELETE_PHOTO = gql`
  mutation($id: Int!) {
    deletePhoto(id: $id)
  }
`

const UPLOAD_FLOOR_PLAN = gql`
  mutation($file: Upload!, $id: String) {
    floorUpload(file: $file, id: $id) {
      url
    }
  }
`
const DELETE_FLOOR_PLAN = gql`
  mutation($id: Int!) {
    deleteFloorPlan(id: $id)
  }
`

const { Dragger } = Upload

const ApartmentDetails = (props) => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()

  const params = useParams()
  const { openModal } = useModals()

  const [editPart, setEditPart] = useState(null)
  const [markerPosition, setMarkerPosition] = useState(null)
  const [reorderedPhotosBuffer, setReorderedPhotosBuffer] = useState(null)

  const [map, setMap] = useState(null)
  const [, setMaps] = useState(null)

  const [form] = Form.useForm()

  let sku 
  if (!params?.sku) {
    sku = props.sku
  } else {
    sku = params.sku
  }
 
  const [uploadPhotos] = useMutation(UPLOAD_PHOTO)
  const [reorderPhoto] = useMutation(PHOTO_REORDER)
  const [deletePhoto] = useMutation(DELETE_PHOTO)

  const [fetchBuilding, { data: buildingData }] = useLazyQuery(BUILDING_QUERY)
  const [aptUpsert] = useMutation(APT_UPSERT_MUTATION)

  const [uploadFloorPlan] = useMutation(UPLOAD_FLOOR_PLAN)
  const [deleteFloorPlan] = useMutation(DELETE_FLOOR_PLAN)

  const backUrl = `/apartments-management/${sku}`
  const { data: _apartmentData, loading: apartmentLoading, error: apartmentError, refetch: apartmentRefetch } = useQuery(APARTMENT_QUERY, {
    variables: {
      id: +props.id,
    },
  })
  // const [getApartmentDataBySku, { data: apartmentDataBySku, loading: apartmentLoadingBySku, error: apartmentErrorBySku, refetch: apartmentRefetchBySku }] = useLazyQuery(APARTMENT_BY_SKU_QUERY, {
  //   variables: {
  //     sku: sku,
  //   },
  // })

  const { data: buildingsData, refetch: buildingsRefetch } = useQuery(ALL_BUILDINGS_QUERY, { fetchPolicy: 'no-cache' })

  const apartmentData = _apartmentData

  useEffect(() => {
    apartmentRefetch({
      id: +props.id
    })
  }, [])

  // useEffect(() => {
  //   if (ROLE_GROUPS.TENANT.includes(state.currentUser?.role)) {
  //     getApartmentDataBySku()
  //   }
  // }, [])

  useEffect(() => {
    if (state.refetch === 'APARTMENT_QUERY') {
      buildingsRefetch()
      apartmentRefetch()
      dispatch({ type: 'REFETCH_CLEAR' })
    }
  }, [state.refetch])

  useEffect(() => {
    if (apartmentData?.response) {
      fetchBuilding({ variables: { id: apartmentData?.response?.buildingId } })
    }
  }, [apartmentData])

  const cancel = () => {
    setEditPart(null)
  }

  const save = async (part) => {
    let address
    if (part.keys.includes('address')) {
      address = state.address
    }

    const formValues = form.getFieldsValue(part.keys)
    const freeFrom = formValues.freeFrom ? formValues.freeFrom.unix() : null

    const { data: { response } = {} } = await aptUpsert({
      variables: {
        input: {
          id: +apartmentData.response.id,
          ...formValues,
          ...(freeFrom ? { freeFrom } : {}),
          ...(address ? { address } : {}),
        },
      },
    })

    if (response) {
      const building = fetchBuilding({ variables: { id: response.buildingId } })

      if (building?.data?.response) {
        const newBuildingData = building.data.response
        dispatch({ type: 'SET_CURRENT_BUILDING', payload: newBuildingData })
        dispatch({ type: 'SET_RIGHT_BAR_COMPONENT', payload: EditBuilding })
        form.setFieldsValue({ buildingId: building?.data.id })
      }
      message.success(`Great! ${part.blockName} has been updated successfully.`)
      setEditPart(null)
      dispatch({ type: 'SET_RIGHT_BAR_COMPONENT', payload: null })
      apartmentRefetch()
    }
  }

  const initBuildingCreation = async () => {
    dispatch({
      type: 'SET_RIGHT_BAR_COMPONENT',
      payload: () => (
        <BuildingAddressForm cb={(id) => {
          form.setFieldsValue({ buildingId: id })
          save({ keys: ['buildingId'], blockName: t('apartments.sections.building-attachment') })
          buildingsRefetch()
        }}
        />
      ),
    })
    dispatch({ type: 'EXPAND_RIGHT_BAR' })
  }

  const setIsActive = async (payload) => {
    const { data: { response } = {} } = await aptUpsert({
      variables: {
        input: {
          id: +apartmentData.response.id,
          isActive: payload,
        },
      },
    })

    if (response) {
      message.success(`${payload ? t('apartments.messages.apartmentActivated') : t('apartments.messages.apartmentDeActivated')}.`)
    }
    apartmentRefetch({
      variables: {
        id: +props.id,
      },
    })
  }

  const setIsPublished = async (payload) => {
    const { data: { response } = {} } = await aptUpsert({
      variables: {
        input: {
          id: +apartmentData.response.id,
          isPublished: payload,
        },
      },
    })

    if (response) {
      message.success(`${payload ? t('apartments.messages.apartmentPublished'): t('apartments.messages.apartmentUnPublished')}.`)
    }
    apartmentRefetch({
      variables: {
        id: +props.id,
      },
    })
  }

  const saveRooms = async (rooms) => {
    await aptUpsert({
      variables: {
        input: {
          id: apartmentData.response.id,
          roomOptions: rooms,
        },
      },
    })
    apartmentRefetch()
  }

  const saveAmenities = async (amenities) => {
    await aptUpsert({
      variables: {
        input: {
          id: apartmentData.response.id,
          amenitiesOptions: amenities,
        },
      },
    })
    apartmentRefetch()
  }

  useEffect(() => {
    if (map && apartmentData?.response) {
      const buildingAddress = apartmentData.response.building.addressDetails

      if (!buildingAddress) return

      setMarkerPosition({
        lat: buildingAddress.geometry.location.lat,
        lng: buildingAddress.geometry.location.lng,
      })
    }
  }, [map])

  const data = apartmentData?.response

  if (!apartmentData?.response && !apartmentLoading) {
    return <NotFound />
  }

  if (apartmentLoading) return 'Loading'
  if (apartmentError) return 'Error'

  if (!data || !buildingsData?.response) return null

  const uploadImage = async (file) => {
    await uploadPhotos({
      variables: {
        file,
        entityId: data.sku,
      },
      // context: {
      //   fetchOptions: {
      //     onUploadProgress: ((progress) => {
      //       console.info(progress);
      //     }),
      //   },
      // },
    })
    apartmentRefetch()
  }

  const uploadFloorPlanImg = async (obj) => {
    await uploadFloorPlan({
      variables: {
        file: obj.file,
        id: data.sku,
      },
    })
    await apartmentRefetch()
    obj.onSuccess()
  }

  const deleteImage = async (id) => {
    await deletePhoto({
      variables: { id },
    })
    apartmentRefetch()
  }

  const deleteFloorPlanImg = async (id) => {
    await deleteFloorPlan({
      variables: { id },
    })
    apartmentRefetch()
  }

  const reorderPhotos = async (a, b, images) => {
    const photosTemp = [...images]
    const orderedPhotos = swap(photosTemp, a, b).map((photo, index) => ({ ...photo, order: index }))
    setReorderedPhotosBuffer(orderedPhotos)

    await reorderPhoto({
      variables: {
        input: orderedPhotos.map((p) => ({ id: p.id, order: p.order })),
      },
    })

    await apartmentRefetch()
    setReorderedPhotosBuffer(null)
  }

  const buildingAddress = data.building?.addressDetails

  const handleApiLoaded = ({ map: MAP, maps: MAPS }) => {
    // use map and maps objects
    setMap(MAP)
    setMaps(MAPS)
  }

  const { role } = state.currentUser

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={async (field) => {
          if (field[0]?.name?.[0] === 'buildingId') {
            await save({ keys: ['buildingId'], blockName: t('apartments.sections.building-attachment') })
            apartmentRefetch({
              variables: {
                id: +props.id,
              },
            })
          }
        }}
      >
        <Row gutter={40} style={{ marginBottom: '20px' }}>
          
          <Col xs={16}>
            <LayoutCard>
              <Editor
                EditComponent={TitleEdit}
                ViewComponent={TitleView}
                blockName={t('apartments.fields.title.title')}
                editModeName="title"
                editPart={editPart}
                setEditPart={setEditPart}
                cancel={cancel}
                save={(e) => save({ e, keys: ['title'], blockName: t('apartments.fields.title.title') })}
                verticalPadding={40}
                form={form}
                marginBottom={10}
                data={data}
                dataIsEmpty={data.title === null}
              />
              {!isTenant(role)
                ? (
                  <Editor
                    EditComponent={CodeEdit}
                    ViewComponent={CodeView}
                    blockName={t('apartments.fields.code')}
                    editModeName="code"
                    editPart={editPart}
                    setEditPart={setEditPart}
                    cancel={cancel}
                    save={(e) => save({ e, keys: ['code'], blockName: t('apartments.fields.code') })}
                    verticalPadding={40}
                    form={form}
                    marginBottom={0}
                    data={data}
                    dataIsEmpty={data.code === null}
                  />
                )
                : null}
            </LayoutCard>
          </Col>
          {isLandlord(state.currentUser?.role)
            && (
              <Col xs={8}>
                <LayoutCard>
                  <div style={{ backgroundColor: data.isActive ? colors.main(0.8) : 'rgba(207, 19, 34, 0.3)', padding: '10px' }}>
                    <Switch
                      defaultChecked={data.isActive}
                      onChange={setIsActive}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      disabled={((data.buildingId != null && data.code != null) && data.isBooked != true)  ? false : true}
                    />
                    <span style={{ marginLeft: '10px', fontWeight: '600' }}>
                      {data.isActive ? t('apartments.fields.active-status.active') : t('apartments.fields.active-status.not-active')}
                    </span>
                  </div>
                  <div style={{ backgroundColor: !data.isBooked ? colors.main(0.8) : 'rgba(207, 19, 34, 0.3)', padding: '10px' }}>
                    
                    <span style={{ marginLeft: '10px', fontWeight: '600' }}>
                      {!data.isBooked ? t('apartments.fields.booking-status.available') : t('apartments.fields.booking-status.busy')}
                    </span>
                  </div>
                  {/* {data.isActive ?  <><Alert
                    message={t('apartments.status.active')}
                    type="success"
                    showIcon
                  /> <Button type="primary" icon={<PauseCircleOutlined />} onClick={setIsActive} /></> : <Button type="primary" icon={<PlayCircleOutlined />} onClick={setIsActive} />}
                   */}
                </LayoutCard>
              </Col>
            )}
          {isAdmin(state.currentUser?.role) && (
            <Col xs={8}>
              <LayoutCard>
                {/* <div style={{ backgroundColor: data.isPublished ? colors.main(0.8) : 'transparent', padding: '10px' }}>
                  <Switch
                    defaultChecked={data.isPublished}
                    onChange={setIsPublished}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    disabled={data.buildingId != null && data.code != null ? false : true}
                  />
                  <span style={{ marginLeft: '10px', fontWeight: '600' }}>
                    {data.isPublished ? t('apartments.fields.publish-status.published') : t('apartments.fields.publish-status.not-published')}
                  </span>
                </div> */}
                {/* <div style={{ backgroundColor: data.isActive ? colors.main(0.8) : 'transparent', padding: '10px' }}> */}
                {!data.isBooked 
                  ? <Switch
                    defaultChecked={data.isActive}
                    onChange={setIsActive}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    disabled={data.buildingId != null && data.code != null ? false : true}
                  />
                  : null }
                {/* <span style={{ marginLeft: '10px', fontWeight: '600' }}>
                  {data.isActive ? t('apartments.fields.active-status.active') : t('apartments.fields.active-status.not-active')}
                </span> */}
                {/* </div> */}
                <br />

                {data.isActive ?  <Alert
                  message={t('apartments.fields.active-status.active')}
                  type="success"
                  showIcon
                /> :  <Alert
                  message={t('apartments.fields.active-status.not-active')}
                  type="warning"
                  showIcon
                />}
                <br />
                {data.isBooked ?  <Alert
                  message={t('apartments.fields.booking-status.busy')}
                  type="warning"
                  showIcon
                /> :  <Alert
                  message={t('apartments.fields.booking-status.available')}
                  type="success"
                  showIcon
                /> }
              </LayoutCard>
            </Col>
          )}

        </Row>
        <Row gutter={40}>
          <Col xs={16}>
            <div style={{ marginBottom: '60px' }}>
              {isTenant(role)

                ? <GalleryRO images={reorderedPhotosBuffer || data.photos} />

                : (
                  <Gallery
                    onReorder={reorderPhotos}
                    images={reorderedPhotosBuffer || data.photos}
                    uploadFunc={uploadImage}
                    deleteFunc={deleteImage}
                    multiple
                  />
                )}
            </div>
          </Col>
          {!isTenant(role)
            ? (
              <Col xs={8}>
                <LayoutCard>
                  <TotalAmountView data={data} profile={buildingData?.response?.profile} />
                  <h2>{t('apartments.sections.rental-conditions')}</h2>
                  <Editor
                    EditComponent={data?.isBooked ? RentalConditionsView : RentalConditionsEdit}
                    ViewComponent={RentalConditionsView}
                    blockName={t('apartments.sections.rental-conditions')}
                    editModeName="rentalConditions"
                    editPart={editPart}
                    setEditPart={setEditPart}
                    cancel={cancel}
                    save={(e) => save({
                      e,
                      keys: ['freeFrom', 'minRentalPeriod', 'monthPrice', 'utilitiesPrice', 'deposit'],
                      blockName: t('apartments.sections.rental-conditions'),
                    })}
                    verticalPadding={40}
                    form={form}
                    marginBottom={20}
                    data={data}
                    profile={buildingData?.response?.profile}
                  />

                </LayoutCard>
                <Divider />

                { data.buildingId === null ? (
                  <> <LayoutCard>
                    <Alert
                      message={t('apartments.notice.title')}
                      description={t('apartments.notice.body')}
                      type="info"
                      showIcon
                    />
                  </LayoutCard>
                  <Divider />
                  </>
                )

                  : null }
                <LayoutCard>
                  <Form.Item
                    name="buildingId"
                    initialValue={
                      buildingsData?.response.filter((b) => b.id === data.buildingId).length ? data?.buildingId : null
                    }
                    label={t('apartments.select-building')}
                  >
                    <Select style={{ width: '100%' }} disabled={!buildingsData.response.length}>
                      {buildingsData.response.length ? (
                        buildingsData.response.map((building) => (
                          <Select.Option key={building.id} value={building.id}>
                            {`${building.street} ${building.number}, `}
                            <CountryRender code={building.country} />
                          </Select.Option>
                        ))
                      ) : (
                        <Select.Option value={null}>{t('apartments.noBuilding')}</Select.Option>
                      )}
                    </Select>
                  </Form.Item>



                  <Divider>OR</Divider>

                  <Button type="ghost" block onClick={initBuildingCreation}>
                    {t('apartments.actions.add-building')}
                  </Button>
                </LayoutCard>
              

                {
                  isLandlord(state.currentUser?.role)
                    ?     <><Divider /><LayoutCard><ProfileInfoCard hoverable={false} bordered withTitle profile={buildingData?.response?.profile} /></LayoutCard></>
                    : null
                }
              </Col>
            )
            : null}
        </Row>
        <Row>
          <Col xs={24}>
            
          
            {/* ----------------------------------------------------------------- */}
            <LayoutTabs
              type="card"
              defaultActiveKey="general"
            >
              <TabPane tab={t('apartments.sections.apartment-description')} key="general">
                <LayoutCard>
                  <Editor
                    EditComponent={PropertyInfoEdit}
                    ViewComponent={PropertyInfoView}
                    blockName={t('apartments.sections.property-info')}
                    editModeName="propertyInfo"
                    editPart={editPart}
                    setEditPart={setEditPart}
                    dataIsEmpty={!data.squareMeters && !data.floor && !data.side}
                    cancel={cancel}
                    save={(e) => save({
                      e,
                      keys: ['squareMeters', 'floor', 'side', 'isStudio', 'rooms'],
                      blockName: t('apartments.sections.property-info'),
                    })}
                    verticalPadding={60}
                    form={form}
                    data={data}
                  />
                </LayoutCard>
                <Divider />
                <LayoutCard>
                  <Editor
                    EditComponent={DescriptionEdit}
                    ViewComponent={DescriptionView}
                    blockName={t('apartments.sections.apartment-description')}
                    editModeName="description"
                    editPart={editPart}
                    setEditPart={setEditPart}
                    dataIsEmpty={data.description === null}
                    cancel={cancel}
                    save={(e) => save({ e, keys: ['description'], blockName: t('apartments.sections.apartment-description') })}
                    verticalPadding={60}
                    form={form}
                    data={data}
                  />
                </LayoutCard>

                <Divider />
                <LayoutCard>
                    
                  {editPart === 'rooms' ? (
                    <EditWrapper inEditMode>
                      <h2>{t('apartments.fields.roomsInfo')}</h2>
                      <Rooms apartment={data || {}} saveRooms={saveRooms} />
                      <ActionButtons
                        save={() => {
                          setEditPart(null)
                          message.success(t('apartments.messages.roomsUpdateSuccess'))
                        }}
                        cancel={() => setEditPart(null)}
                      />
                    </EditWrapper>
                  ) : (
                    <EditWrapper onClick={() => setEditPart('rooms')}>
                      <h2>{t('apartments.fields.roomsInfo')}</h2>
                      <RoomsView apartment={data || {}} key='RoomsViewer' />
                    </EditWrapper>
                  )}
                </LayoutCard>
              </TabPane>

              <TabPane tab={t('apartments.sections.inventory')} key="inventory">
                <LayoutCard>
                  {editPart === 'facilities' ? (
                    <EditWrapper inEditMode>
                      <Amenities apartment={data || {}} saveAmenities={saveAmenities} />
                      <ActionButtons
                        save={() => {
                          setEditPart(null)
                          message.success(t('apartments.messages.inventoriesUpdateSuccess'))
                        }}
                        cancel={() => setEditPart(null)}
                      />
                    </EditWrapper>
                  ) : (
                    <EditWrapper onClick={() => setEditPart('facilities')}>
                      <AmenitiesView apartment={data || {}} key='AmenitiesViewer'  />
                    </EditWrapper>
                  )}
                </LayoutCard>
              </TabPane>

              <TabPane tab={t('apartments.sections.extras')} key="extrass">
                <LayoutCard>
                  <Editor
                    EditComponent={ExtrasEdit}
                    ViewComponent={ExtrasView}
                    blockName={t('apartments.sections.extras')}
                    editModeName="extras"
                    editPart={editPart}
                    setEditPart={setEditPart}
                    dataIsEmpty={data.extras === null}
                    cancel={cancel}
                    save={(e) => save({ e, keys: ['extras'], blockName: t('apartments.sections.extras') })}
                    verticalPadding={60}
                    form={form}
                    data={data}
                  />
                </LayoutCard>
              </TabPane>
              {!isLandlord(state.currentUser?.role) ? (
                <TabPane tab={t('apartments.sections.services')} key="Services">
                  <ServicesManager  
                    queryParams={{ filter: { apartmentIds: [data.id] } }} 
                    actions={[
                      <Link
                        to={'/services-management/create'}
                        key="create"
                        onClick={(e) => {
                          e.preventDefault()
                          openModal(ServiceForm, {
                            forceQuery: {
                              apartmentId: data.id,
                              serviceProviderId: data.building.profileId,
                              backUrl: backUrl + '#apartments-management',
                            },
                           
                          })
                        }}
                      >
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          htmlType="span"
                        >
                          {t('service.table.actions.createService')}
                        </Button>
                      </Link>,
                    ]}/>
                </TabPane>
              ) : null}
              
              <TabPane tab={t('apartments.sections.floor-plan')} key="floor-plan">
                <LayoutCard>
                  {!isTenant(state.currentUser?.role)
                    && (
                      <>
                        <Dragger showUploadList={false} customRequest={uploadFloorPlanImg} {...uprops}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">{t('apartments.messages.uploadText')}</p>
                        </Dragger>
                        <Divider />
                      </>
                    )}
                  {data.floorPlans.length
                    ? data.floorPlans.map((plan, i) => (
                      <React.Fragment key={i}>
                        {
                          plan.mimetype.split('/').includes('image')
                            ? <img src={plan.url} width="100%" alt="floor plan" />
                            : <embed src={plan.url} width="100%" height="600px" />
                        }
                        {!isTenant(state.currentUser?.role)
                          && <Button onClick={() => deleteFloorPlanImg(plan.id)}>{t('apartments.actions.delete-floor-plan')}</Button>}
                      </React.Fragment>
                    ))
                    : null}
                </LayoutCard>
              </TabPane>
              <TabPane tab={t('apartments.sections.building-description')} key="building">
                <LayoutCard>
                  <AptBuildingInfo buildingData={data.building} />
                </LayoutCard>
              </TabPane>
              <TabPane tab={t('apartments.sections.map')} key="map">

                <LayoutCard>
                  {buildingAddress && (<AddressDescription building={data?.building} />)}
                </LayoutCard>
              </TabPane>
            </LayoutTabs>

          </Col>
        </Row>
      </Form>
    </>
  )
}

export { ApartmentDetails }
