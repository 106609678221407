/* eslint-disable */
export const swap = (input, indexA, indexB) => {
  while (indexA < 0) {
    indexA += input.length;
  }
  while (indexB < 0) {
    indexB += input.length;
  }
  if (indexB >= input.length) {
    let k = indexB - input.length + 1;
    while (k--) {
      input.push(undefined);
    }
  }
  input.splice(indexB, 0, input.splice(indexA, 1)[0]);
  return input;
};
