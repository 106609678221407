import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import {useHash} from 'react-use'
import styles from 'styles/side-layout.less'

function LayoutTabs({ children, defaultActiveKey, ...props }) {
  const [activeKey, setActiveKey] = useState(null)
  const [hash] = useHash()

  // hash.replace('#', '')

  const onChange = (tab) => {
    setActiveKey(tab)
    // setHash(`#${tab}`)
  }
  

  useEffect(() => {
    if (hash.replace('#', '')) {
      setActiveKey(hash.replace('#', ''))
      history.pushState('', document.title, window.location.pathname + window.location.search)
    }
  }, [hash])

  return (
    <Tabs className={styles.tabs} {...props} onChange={onChange} activeKey={activeKey || defaultActiveKey}>
      {children}
    </Tabs>
  )
}

export default LayoutTabs
