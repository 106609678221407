import { USERS_LIST } from '_graphql'
import { roles, USER_ROLES } from '_graphql/_enums'
import AccountPlus from '@2fd/ant-design-icons/lib/AccountPlus'
import Eye from '@2fd/ant-design-icons/lib/Eye'
import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Select, Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserForm, UserFormCreate } from 'screens'
import { v4 as uuid } from 'uuid'


const presets = {
  'b2b-managers': [ USER_ROLES['b2b-sales'].key, USER_ROLES['b2b-admin'].key ]
}

function UserSelect({ 
  roles = [],
  value = null,
  onChange: setValue,
  filter = {},
  disabled = false,
  itemsFilter = (item) => (item),
  onTitle,
  ...props
}) {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(false)
  const { openModal, events: modalEvents } = useModals()

  const [fetchRaw, { data, loading }] = useLazyQuery(USERS_LIST, { fetchPolicy: 'no-cache' })

  const availableRoles = roles in presets ? presets[roles] : roles

  const fetch = () => {
    return fetchRaw({
      variables: { 
        page: 1,
        pageSize: 1000,
        filter: JSON.stringify({
          ...filter,
          role: availableRoles
        }),
      }
    })
  }

  useEffect(() => {
    if (!disabled && !data && !loading && (value || isFocused)) {
      fetch()
    }
  }, [disabled, value, loading, filter, isFocused])

  useEffect(() => {
    let title = null

    if (value) {
      let user = data?.response?.list?.find?.((user) => (user.id === value))
      title = `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || `#${value}`
    }

    onTitle?.(title)
  }, [value, data])

  const addUserModalId = useMemo(() => uuid(), [])

  useEffect(() => {
    const onUserModalClose = async ([id, options]) => {
      if (addUserModalId === options.modalId) {
        setValue(id)
        await fetch()
      }
    }

    modalEvents.addListener('close', onUserModalClose)

    return () => {
      modalEvents.removeListener('close', onUserModalClose)
    }
  }, [setValue, fetch])

  return (
    <div style={{ display: 'flex' }}>
      <Select
        showSearch
        loading={loading}
        disabled={disabled}
        onChange={setValue}
        onFocus={() => { !isFocused && setIsFocused(true) }}
        value={value}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...props}
      >
        <Select.Option value={null}>{t('rental-request.components.sales-select.not-selected')}</Select.Option>
        {data?.response?.list?.filter?.(itemsFilter)?.map?.((user) => (
          <Select.Option value={user?.id} key={user?.id}>
            {`${user?.firstName} ${user?.lastName}`}
          </Select.Option>
        ))}
      </Select>
      <div>
        {/* Show user */}
        {value ? (
          <Tooltip title="View user" placement="right">
            <Button 
              type="text" 
              icon={<Eye />} 
              onClick={() => {
                openModal(UserForm, {
                  id: value,
                  availableRoles,
                  onClose() {
                    fetch()
                  }
                })
              }}
            />
          </Tooltip>
        ) : null}
        {/* Create user */}
        {!value ? (
          <Tooltip title="Create user" placement="right">
            <Button 
              type="text" 
              icon={<AccountPlus />} 
              disabled={disabled}
              onClick={() => {
                openModal(UserFormCreate, {
                  modalId: addUserModalId,
                  availableRoles,
                })
              }}
            />
          </Tooltip>
        ) : null}
      </div>
    </div>
  )
}

const UserSelectExperimental = ({
  roles = [],
  value = null,
  onChange: setValue,
  filter = {},
  disabled = false,
  itemsFilter = (item) => (item),
  onTitle,
  disableButton,
  ...props
}) => {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(false)
  const { openModal, events: modalEvents } = useModals()

  const [fetchRaw, { data, loading }] = useLazyQuery(USERS_LIST, { fetchPolicy: 'no-cache' })

  const availableRoles = roles in presets ? presets[roles] : roles?.map?.(role => role.key)
  const filtersString = JSON.stringify({
    ...filter,
    role: availableRoles
  })

  const fetch = () => {
    return fetchRaw({
      variables: { 
        page: 1,
        pageSize: 1000,
        filter: JSON.stringify({
          ...filter,
          role: availableRoles
        }),
      }
    })
  }

  useEffect(() => {
    if (!disabled && !loading && isFocused) {
      fetch()
    }
  }, [disabled, isFocused, filtersString])

  useEffect(() => {
    let title = null

    if (value) {
      let user = data?.response?.list?.find?.((user) => (user.id === value))
      title = `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || `#${value}`
    }

    onTitle?.(title)
  }, [value, data])

  const addUserModalId = useMemo(() => uuid(), [])

  useEffect(() => {
    const onUserModalClose = async ([id, options]) => {
      if (addUserModalId === options.modalId) {
        setValue(id)
        await fetch()
      }
    }

    modalEvents.addListener('close', onUserModalClose)

    return () => {
      modalEvents.removeListener('close', onUserModalClose)
    }
  }, [setValue, fetch])

  return (
    <div style={{ display: 'flex' }}>
      <Select
        showSearch
        loading={loading}
        disabled={disabled}
        onChange={setValue}
        onFocus={() => { !isFocused && setIsFocused(true) }}
        value={value}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...props}
      >
        <Select.Option value={null}>{t('rental-request.components.sales-select.not-selected')}</Select.Option>
        {data?.response?.list?.filter?.(itemsFilter)?.map?.((user) => (
          <Select.Option value={user.id} key={user.id}>
            {`${user?.firstName} ${user?.lastName}`}
          </Select.Option>
        ))}
      </Select>
      {!disableButton && (
        <div>
          {/* Show user */}
          {value ? (
            <Tooltip title="View user" placement="right">
              <Button 
                type="text" 
                icon={<Eye />} 
                onClick={() => {
                  openModal(UserForm, {
                    id: value,
                    availableRoles,
                    onClose() {
                      fetch()
                    }
                  })
                }}
              />
            </Tooltip>
          ) : null}
          {/* Create user */}
          {!value ? (
            <Tooltip title="Create user" placement="right">
              <Button 
                type="text" 
                icon={<AccountPlus />} 
                disabled={disabled}
                onClick={() => {
                  openModal(UserFormCreate, {
                    modalId: addUserModalId,
                    availableRoles,
                  })
                }}
              />
            </Tooltip>
          ) : null}
        </div>
      )}
    </div>
  )
}

export { UserSelect, UserSelectExperimental }
