import { Modal } from 'antd'
import * as EventEmitter from 'eventemitter3'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

// @ts-ignore
const modalEvents = new EventEmitter()

const openModal = (component, options) => {
  const modalId = uuid()
  modalEvents.emit('showModal', [component, { modalId,...options }])
}

export const useModals = () => {
  return {
    openModal,
    events: modalEvents
  }
}

export const useOnCloseModalSubscription = ({
  id,
  onClose
}) => {
  const modalId = useMemo(() => id || uuid(), [id])

  useEffect(() => {
    const onModalClose = async (args) => {
      const options = args[args.length - 1]

      if (modalId === options.modalId) {
        onClose(...args)
      }
    }

    modalEvents.addListener('close', onModalClose)

    return () => {
      modalEvents.removeListener('close', onModalClose)
    }
  }, [onClose, id])

  return modalId
}

const modalStyles = { top: 20, position: 'relative', overflow: 'hidden', borderRadius: '6px', marginBottom: 20, minHeight: '80vh' }

const ModalRenderer = React.memo(({
  component: Component,
  options,
  closeModal,
  index
}) => {
  return (
    <Modal 
      visible={true} 
      width="calc(100vw - 200px)" 
      style={modalStyles}
      footer={null}
      destroyOnClose
      closable={false}
      onCancel={() => closeModal(index)}
      className={'is-modal'}
    >
      <Component 
        {...options} 
        isModal 
        onClose={(...args) => { 
          modalEvents.emit('close', [...args, options])
          options?.onClose?.(...args) 
          closeModal(index) 
        }} 
        forceQuery={new Map(Object.entries(options?.forceQuery || {}))}
      />
    </Modal>
  )
})

/**
 * Modals provider
 */
export function ModalsProvider({ children }) {
  const [modals, setModals] = useState([])

  const closeModal = useCallback((index) => {
    const newModals = [...modals]
    newModals.splice(index, 1)
    setModals(newModals)
  }, [modals])

  React.useEffect(() => {
    modalEvents.on('showModal', ([component, options]) => {
      setModals((modals) =>[...modals, [component, options]])
    })

    return () => {
      modalEvents.removeListener('showModal')
    }
  }, [])

  return (
    <>
      {/* Render models */}
      {modals.map(([Component, options], index) => (
        <ModalRenderer
          key={options.modalId}
          index={index}
          component={Component}
          options={options}
          closeModal={closeModal}
        />
      ))}
    </>
  )
}
