import { gql } from 'apollo-boost'

import { logFragment, userFragment} from '../_fragments'

export const LOG_LIST = gql`
    query listLogs($page: Int!, $pageSize: Int!, $sorting: String, $search: String, $filter: JSON) {
      response: listLogs(page: $page, pageSize: $pageSize, sorting: $sorting, search: $search, filter: $filter) {
        list {
          ...logFragment
          user {
            ...userFragment
          }
        }
        pagination {
          page
          pageSize
          total
        }  
      }
  }
  ${logFragment}
  ${userFragment}
`