import { isAdmin,roles, USER_ROLES } from '_graphql/_enums'
import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined } from '@ant-design/icons'
import { Link } from '@reach/router'
import { Avatar, Button,Popover, Tag } from 'antd'
import UserAvatar from 'components/User/Widgets/Avatar'
import i18n from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CountryRender } from 'utils/countries'

/**
 * Стандартные типы выводимых данных
 * @param {Object} props Параметры рендеринга
 * @param {any} props.value Значение item[key]
 * @param {any} props.options Дополнительные опции рендеринга
 * @param {any} props.item Весь объект данных колонки
 * @param {any} props.key Ключ ячейки
 * @param {Object} props.context Контекст Менеджера списков
 */
// const template = ({ value, options, item, key, context }) => <>{value}</>;

/** Вывод строки */
export const string = ({ value, options }) => (options?.na && !value ? <Tag color="default">{options?.na === true ? 'N/A' : options?.na}</Tag> : value || null)

/** Страна */
export const country = ({ value }) => (<CountryRender code={value} />)

/** Ссылка */
export const link = ({ value, options }) => {
  let link = '#'

  switch (options.type) {
  // Email
  case 'email': {
    link = `mailto:${value}`
    break
  }
  // Phone
  case 'phone': {
    link = `tel:${value}`
    break
  }
  // Link
  case 'link': {
    if (String(value).startsWith('http://') || String(value).startsWith('https://')) {
      link = value
    }
    break
  }

  default:
  }

  return value && link !== '#' ? (<a href={link} target={options.type === 'link' ? '_blank' : undefined} rel="noopener noreferrer">{value}</a>) : value ?? '-'
}

/** Пользователь */
export const user = ({ value: user, options }) => {
  if (!user || !user?.id) {
    return <Tag color="default">N/A</Tag>
  }

  const isManagementUser = options?.userRole ? isAdmin(options?.userRole) : true
  const name = [user.firstName, user.lastName].filter((part) => !!String(part).trim()).join(' ')
  const role = USER_ROLES[user.role]

  return (
    <Popover
      content={(
        <>
          {/** Role */}
          { isManagementUser ? <div>{i18n.t('user.fields.role')}: <Tag color={role?.color}>{role?.title}</Tag></div> : null }
          {/** Email */}
          { user.email ? <div><a href={`mailto:${user.email}`}>{user.email}</a></div> : null }
          {/** Phone */}
          { user.phone ? <div><a href={`tel:${user.phone}`}>{user.phone}</a></div> : null }
          {/** Links */}
          { isManagementUser ? <Link to={`/users-management/${user.id}`}><Button htmlType="span" type="default" size="small" icon={<EditOutlined />} block style={{ marginTop: 4 }}>{i18n.t('user.fields.edit')}</Button></Link> : null }
        </>
      )}
      title={name}
    >
      <span>
        <span className="widget-user" style={options?.style ?? {}}>
          <UserAvatar
            className="widget-user__avatar"
            size={24}
            user={user}
          />
          <span className="widget-user__name">{ name }</span>
        </span>
      </span>
    </Popover>
  )
}

/** Цена */
export const cost = ({ value, vat = 0, options }) => {
  if (!value && value !== 0) {
    return (<Tag>N/A</Tag>)
  }

  // eslint-disable-next-line no-mixed-operators
  const vatCost = !Number.isNaN(vat) ? value * vat : 0

  return `${options?.currency ?? '€'} ${Number(value + vatCost).toLocaleString('de-DE')}`
}

/** Булеан тип */
export const boolean = ({ value }) => (value ? <CheckCircleTwoTone twoToneColor="green" style={{ fontSize: 21 }} /> : <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: 21 }} />)
