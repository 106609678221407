import { APT_UPSERT_MUTATION } from '_graphql'
import { useMutation } from '@apollo/react-hooks'
import { Col, Form, InputNumber, Radio, Row, Select, Switch, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { roomsInitialState } from 'screens/BuildingsManager/BuildingDetails/formParts/Rooms/roomsInitialState'
import { useGlobalState } from 'state'
import { parseJson } from 'utils/parseJson'

import { StudioSwitchWrapper } from '../styled'

const PropertyInfoEdit = ({ data, form }) => {
  const { t } = useTranslation()
  const roomOptions = parseJson(data.roomOptions)
  const [studio, setStudio] = useState(data.isStudio)
  const [aptUpsert] = useMutation(APT_UPSERT_MUTATION)
  const [, dispatch] = useGlobalState()

  console.log('data building', data.building)

  const resetRoomsToFull = async () => {
    await aptUpsert({
      variables: {
        input: {
          id: data.id,
          roomOptions: JSON.stringify(roomsInitialState),
        },
      },
    })

    dispatch({ type: 'REFETCH', payload: 'APARTMENT_QUERY' })
  }

  return (
    <>
      <h2 style={{ marginBottom: '10px' }}>{t('apartments.sections.property-info')}</h2>
      <StudioSwitchWrapper>
        <Form.Item
          noStyle
          name="isStudio"
        >
          <Radio.Group onChange={(e) => {
            const isStudio = e.target.value
            if (isStudio) {
              form.setFields([{ name: 'rooms', value: 1 }])
            }
            setStudio(isStudio)
            resetRoomsToFull()
          }}
          >
            <Tooltip
              placement="left"
              title={t('apartments.fields.isStudio.description')}
            >
              <Radio.Button value={true}>{t('apartments.fields.isStudio.$true$')}</Radio.Button> {/* eslint-disable-line */}
            </Tooltip>
            <Radio.Button value={false}>{t('apartments.fields.isStudio.$false$')}</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </StudioSwitchWrapper>
      <Row gutter={[20, 20]}>
        <Col xs={6}>
          <p style={{ fontWeight: '600', fontSize: '14px' }}>{t('apartments.fields.squareMeters')}, ㎡</p>
        </Col>
        <Col xs={6}>
          <Form.Item name="squareMeters">
            <InputNumber style={{ width: '100%' }} min={1} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xs={6}>
          <p style={{ fontWeight: '600', fontSize: '14px' }}>{t('apartments.fields.floorTitle')}</p>
        </Col>
        <Col xs={6}>
          <Form.Item name="floor">
            <InputNumber style={{ width: '100%' }} min={0} max={data?.building?.floor} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xs={6}>
          <p style={{ fontWeight: '600', fontSize: '14px' }}>{t('apartments.fields.side.title')}</p>
        </Col>
        <Col xs={6}>
          <Form.Item name="side">
            <Select defaultValue={null} style={{ width: '100%' }}>
              <Select.Option value={null}>{t('apartments.fields.side.value.empty')}</Select.Option>
              <Select.Option value="left">{t('apartments.fields.side.value.left')}</Select.Option>
              <Select.Option value="middle">{t('apartments.fields.side.value.middle')}</Select.Option>
              <Select.Option value="right">{t('apartments.fields.side.value.right')}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xs={6}>
          <p style={{ fontWeight: '600', fontSize: '14px' }}>{t('apartments.fields.roomsFull')}</p>
        </Col>
        <Col xs={6}>
          <Form.Item name="rooms">
            <InputNumber disabled={studio} style={{ width: '100%' }} min={1} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

const PropertyInfoView = ({ data }) => {
  const { t } = useTranslation()
  const roomOptions = parseJson(data.roomOptions)

  return (
    <>
      <h2>{t('apartments.sections.property-info')}</h2>
      <StudioSwitchWrapper>
        <span>{data.isStudio ? t('apartments.fields.isStudio.$true$') : t('apartments.fields.isStudio.$false$')}</span>
      </StudioSwitchWrapper>
      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col xs={6}>
          <p style={{ fontWeight: '400', fontSize: '14px' }}>{t('apartments.fields.squareMeters')}:</p>
        </Col>
        <Col xs={6}>
          <p style={{ fontWeight: '400', fontSize: '14px' }}>
            {data.squareMeters} ㎡
          </p>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col xs={6}>
          <p style={{ fontWeight: '400', fontSize: '14px' }}>{t('apartments.fields.floorTitle')}:</p>
        </Col>
        <Col xs={6}>
          <p style={{ fontWeight: '400', fontSize: '14px' }}>{data.floor}</p>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col xs={6}>
          <p style={{ fontWeight: '400', fontSize: '14px' }}>{t('apartments.fields.side.title')}:</p>
        </Col>
        <Col xs={6}>
          <p style={{ fontWeight: '400', fontSize: '14px' }}>{t(`apartments.fields.side.value.${data.side ?? '$empty$'}`)}</p>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xs={6}>
          <p style={{ fontWeight: '400', fontSize: '14px' }}>{t('apartments.fields.roomsFull')}</p>
        </Col>
        <Col xs={6}>
          <p>{data.rooms}</p>
        </Col>
      </Row>
    </>
  )
}

export { PropertyInfoEdit, PropertyInfoView }
