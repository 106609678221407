import { gql } from 'apollo-boost'

export const comissionPaymentFragment = gql`
  fragment comissionPaymentFragment on ComissionPayment {
    sku
    landlordId
    landlordProfileId
    recipientProfileId
    totalComission
    totalRent
    isPaid
    volume
    apartments {
      aptId
      tenantName
      commission
      rent
      total
    }
    issueMonth
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`

