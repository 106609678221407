import { gql } from 'apollo-boost'

export const invoiceFragment = gql`
  fragment invoiceFragment on Invoice {
    id
    method
    sku
    rentalContractId
    invoiceDate
    bookingDate
    paymentDate
    reference
    serviceProviderId
    iban
    bicCode
    recipient
    bankName
    grossPrice
    vat
    netPrice
    serviceProviderType
    createdBy
    updatedBy
    createdAt
    updatedAt
  }

`
