import { isAdmin, ROLE_GROUPS, roles, USER_ROLES } from '_graphql/_enums'
import { PROFILE_DELETE_MUTATION,PROFILE_RESTORE_MUTATION,PROFILE_UPSERT_MUTATION } from '_graphql/profile/mutations'
import { PROFILE_LIST } from '_graphql/profile/queries'
import { CopyOutlined, CrownOutlined, DeleteOutlined, PlusOutlined,RollbackOutlined,SortAscendingOutlined, SortDescendingOutlined, UserAddOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Link,navigate } from '@reach/router'
import { Alert, Button, Popconfirm, Tooltip } from 'antd'
import { ListManager } from 'components/ListManager'
import { string } from 'components/ListManager/DefaultTypes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import { CountryRender } from 'utils/countries'
import { deepOmit } from 'utils/deepOmit'

import { ProfileDisplay } from './ProfileDisplay'
import { ProfileForm } from './ProfileForm'

const ProfilesManager = () => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)

  /** Клонирование профиля */
  const [upsertProfile] = useMutation(PROFILE_UPSERT_MUTATION)
  const cloneProfileAction = async (item) => {
    const { data: { response: { id } } } = await upsertProfile({ variables: { input: deepOmit({ payload: item, keysToOmit: ['__typename', 'id', 'user'] }) } })
    navigate(`/profiles-management/${id}`)
  }

  /** Удаление профиля */
  const [deleteProfile] = useMutation(PROFILE_DELETE_MUTATION)
  const deleteProfileAction = async (item, callback) => {
    await deleteProfile({ variables: { id: item.id } })
    callback()
  }


  const [restoreProfile] = useMutation(PROFILE_RESTORE_MUTATION)
  const restoreProfileAction = async (item, callback) => {
    await restoreProfile({ variables: { id: item.id } })
    callback()
  }
  

  return (
    <>
      <ListManager
        slug="profiles-manager"
        title={t('profiles.manager-title')}
        query={PROFILE_LIST}
        actions={[
          ![roles.TENANT_PROJECT_MANAGER].includes(state.currentUser?.role) ? (
            <Link to="/profiles-management/create" key='create'>
              <Button type="primary" icon={<PlusOutlined />} htmlType="span">{t('profiles.actions.add-profile')}</Button>
            </Link>
          ) : null,
        ]}
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('profiles.sorting.id|desc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('profiles.sorting.id|asc'),
              icon: <SortAscendingOutlined />,
            },
            {
              slug: 'userId|desc',
              title: t('profiles.sorting.userId|desc'),
              icon: <UserSwitchOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('profiles.filters.presets.all'),
              slug: 'all',
              value: {},
            },
            {
              title: t('profiles.filters.presets.landlords'),
              slug: 'landlords',
              icon: <CrownOutlined />,
              middleware: isManagementUser,
              value: { 'user.role': [roles.LANDLORD_OWNER] },
            },
            {
              title: t('profiles.filters.presets.tenants'),
              slug: 'tenants',
              icon: <UserOutlined />,
              middleware: isManagementUser,
              value: { 'user.role': [roles.TENANT_ADMIN] },
            },
          ],
          filters: [
            {
              title: t('profiles.filters.user-role'),
              icon: <CrownOutlined />,
              slug: 'user.role',
              middleware: isManagementUser,
              type: 'tags',
              options: {
                valuesPreset: 'role',
                valuesPresetOptions: [roles.LANDLORD_OWNER, roles.TENANT_ADMIN],
              },
            },
            {
              title: t('profiles.filters.user'),
              icon: <UserOutlined />,
              slug: 'users',
              middleware: isManagementUser,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: {
                  role: [USER_ROLES['tentant-admin'].key, USER_ROLES['landlord-owner'].key]
                  // role: Object.values(USER_ROLES).filter((role) => [...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT].includes(role.id)).map(role => role.key)
                },
              },
            },
          ],
        }}
        table={{
          columns: [
            {
              title: t('profiles.fields.profileName'),
              key: 'profileName',
              options: { na: true },
              render: ({ value, options, item }) => item.deletedAt ? string({ value, options }) : <Link to={`/profiles-management/${item.id}`}>{ string({ value, options }) }</Link>,
            },
            {
              title: t('profiles.fields.companyName'),
              key: 'companyName',
              options: { na: true },
              render: 'string',
            },
            {
              title: t('profiles.fields.location'),
              key: 'location',
              render: ({ item }) => (
                <>
                  <CountryRender code={item.country} />
                  {item.city ? `, ${item.city}` : null}
                </>
              ),
            },
           
            {
              title: t('profiles.fields.user'),
              key: 'user',
              render: 'user',
              middleware: () => isAdmin(state.currentUser?.role),
            },
            {
              title: t('profiles.fields.status'),
              key: 'deletedAt',
              middleware: isManagementUser,
              render: ({ item }) => item?.deletedAt ? <Alert type="error"  message="Deleted" /> :  <Alert type="success" message="Active" />,
            },
            {
              
              key: 'actions',
              title: t('profiles.fields.actions'),
              width: 120,
              middleware: isManagementUser,
              render: ({ item, context }) => (


                item.deletedAt ? (
                  <Tooltip placement="bottom" title="Restore">
                    <Popconfirm title="Restore this Profile?" onConfirm={() => restoreProfileAction(item, context.dataRefetch)}>
                      <Button shape="circle" type="link" icon={<RollbackOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                ) : (
                  <>
                    {/** Clone */}
                    <Tooltip placement="bottom" title="Duplicate">
                      <Popconfirm title="Clone this Profile?" onConfirm={() => cloneProfileAction(item)}>
                        <Button shape="circle" type="link" icon={<CopyOutlined />} />
                      </Popconfirm>
                    </Tooltip>
                    {/** Remove */}
                    <Tooltip placement="bottom" title="Remove">
                      <Popconfirm title="Delete this Profile?" onConfirm={() => deleteProfileAction(item, context.dataRefetch)}>
                        <Button shape="circle" type="link" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </Tooltip>
                  </>
                )
               
              ),
            },
          ]
        }}
      />
    </>
  )
}


export { ProfileDisplay } from './ProfileDisplay'
export { ProfileForm } from './ProfileForm'
export { ProfilesManager }
