import { serviceRequestFragment } from '_graphql/_fragments/serviceRequest'
import gql from 'graphql-tag'

export const SERVICE_REQUEST_UPSERT_MUTATION = gql`
  mutation serviceRequest($input: ServiceRequestInput!) {
    response: serviceRequest(input: $input) {
      id
    }
  }
`
