import { gql } from 'apollo-boost'

import { apartmentFragment, invoiceFragment,profileFragment,rentalContractFragment, userFragment } from '../_fragments'

export const INVOICE_LIST = gql`
  query listInvoices(
    $page: Int!
    $pageSize: Int!
    $sorting: String
    $search: String
    $filter: JSON
  ) {
    response: listInvoices(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      search: $search
      filter: $filter
    ) {
      list {
        ...invoiceFragment
        rentalContract {
          ...rentalContractFragment
          apartment {
            ...apartmentFragment
          }
        }
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${invoiceFragment},
  ${rentalContractFragment},
  ${apartmentFragment}
`

export const FETCH_INVOICE_BY_SKU = gql`
  query fetchInvoiceBySku($sku: String!) {
    response: fetchInvoiceBySku(sku: $sku) {
      ...invoiceFragment
      rentalContract {
      ...rentalContractFragment
      }
      serviceProvider {
      ...profileFragment}
    }
  }
  ${invoiceFragment}
  ${rentalContractFragment}
  ${profileFragment}
`
export const GENERATE_INVOICE_BY_CONTRACT = gql`
  query generateInvoices($sku: String!) {
    response: generateInvoices(sku: $sku) {
      ...invoiceFragment
      rentalContract {
      ...rentalContractFragment
      }
      serviceProvider {
      ...profileFragment}
    }
  }
  ${invoiceFragment}
  ${rentalContractFragment}
  ${profileFragment}
`
