import { gql } from 'apollo-boost'

export const projectFragment = gql`
  fragment projectFragment on Project {
    id
    title
    description
    sku
    pmoId
    managerId
    defaultBudget
    city 
    street 
    zipCode
    ownerId
    startedAt
    expireAt
    stats {
      name
      count
      array {
        date
				rent
				rentColor
				utilities
				utilitiesColor
				services
				servicesColor
      }
    }
  }
`
