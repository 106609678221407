import { REQUESTS_LIST } from '_graphql'
import Eye from '@2fd/ant-design-icons/lib/Eye'
import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Select, Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RentalRequestForm } from 'screens'

function RentalRequestSelect({ 
  value = null,
  onChange,
  ...props
}) {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(!!value)
  const { openModal } = useModals()

  const [fetch, { data, loading }] = useLazyQuery(REQUESTS_LIST)

  useEffect(() => {
    if ((value || isFocused)) {
      fetch({
        variables: {
          page: 1,
          pageSize: 1000,
        }
      })
    }
  }, [value, isFocused])

  const setValue = (value) => {
    onChange?.(value)
  }

  return (
    <div style={{ display: 'flex' }}>
      <Select
        showSearch
        loading={loading}
        onChange={setValue}
        onFocus={() => { !isFocused && setIsFocused(true) }}
        value={value}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...props}
      >
        <Select.Option value={null}>{t('rental-request.components.sales-select.not-selected')}</Select.Option>
        {data?.response?.list?.map?.((request) => (
          <Select.Option value={request.id} key={request.id}>
            {request.sku} #{request.code}
          </Select.Option>
        ))}
      </Select>
      <div>
        <Tooltip title="View request" placement="right">
          <Button 
            type="text" 
            icon={<Eye />} 
            disabled={!value}
            onClick={() => {
              openModal(RentalRequestForm, {
                query: { sku: data?.response?.list?.find?.((request) => (request.id === value))?.sku },
                onClose() {
                  fetch()
                }
              })
            }}
          />
        </Tooltip>
      </div>
    </div>
  )
}

export { RentalRequestSelect }
