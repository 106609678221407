import { Pagination } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { AutoReload } from './AutoReload'

/**
 * List manager Footer
 */
export const Footer = ({ dataTotal, currentPage, setCurrentPage, pageSize, setPageSize, dataLoading, dataError, dataRefetch, dataLastFetchDate }) => {
  const { t } = useTranslation()
  return (
    <footer className="list-manager__footer">
      {/* Кнопка обновления данных */}
      <AutoReload {...{ dataLoading, dataError, dataRefetch, dataLastFetchDate }} />

      {/* Пагинация */}
      <div className="list-manager__pagination">
        <Pagination
          pageSize={pageSize}
          disabled={dataLoading}
          current={currentPage}
          onChange={(inputPage, inputPageSize) => {
            setCurrentPage(inputPage)
            setPageSize(inputPageSize)
          }}
          total={dataTotal}
          showTotal={(total) => t('list-manager.total-items', { total })}
        />
      </div>
    </footer>
  )
}
