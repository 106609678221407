import { gql } from 'apollo-boost'

export const serviceFragment = gql`
  fragment serviceFragment on Service {
    id
    sku
    serviceProviderId
    reference
    paymentInterval
    contractId
    lastPaymentDate
    nextPaymentMonth
    serviceProviderType
    status
    netPrice
    tax
    grossPrice
    apartmentId
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`
