import { colors } from '_colors'
import { APT_DELETE_MUTATION,APT_UPSERT_MUTATION } from '_graphql'
import { PROFILE_DELETE_MUTATION,PROFILE_UPSERT_MUTATION } from '_graphql/profile/mutations'
import { CopyOutlined, DeleteOutlined,SettingOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Popconfirm, Tag } from 'antd'
import React from 'react'
import { useGlobalState } from 'state'
import { CountryRender } from 'utils/countries'
import { deepOmit } from 'utils/deepOmit'
import { parseJson } from 'utils/parseJson'

const Actions = (text, data) => {
  console.log(data)
  const [, dispatch] = useGlobalState()
  const [upsertProfile] = useMutation(PROFILE_UPSERT_MUTATION)
  const [deleteProfile] = useMutation(PROFILE_DELETE_MUTATION)

  const cloneProfile = async (id) => {
    await upsertProfile({
      variables: {
        input: {
          ...deepOmit({
            payload: data,
            keysToOmit: ['__typename', 'id', 'user'],
          }),
        },
      },
    })
    dispatch({ type: 'REFETCH', payload: 'ALL_PROFILES_QUERY' })
    dispatch({ type: 'REFETCH', payload: 'USER_QUERY' })
    dispatch({ type: 'REFETCH', payload: null })
  }

  const deleteProfileFunc = async (input) => {
    await deleteProfile(input)
    dispatch({ type: 'REFETCH', payload: 'ALL_PROFILES_QUERY' })
    dispatch({ type: 'REFETCH', payload: 'USER_QUERY' })
    dispatch({ type: 'REFETCH', payload: null })
  }

  return (
    [
      <Popconfirm
        key={9987}
        title="Clone this Profile?"
        onConfirm={(e) => {
          e.stopPropagation()
          cloneProfile(data.id)
        }}
        onCancel={(e) => {
          e.stopPropagation()
        }}
      >
        <CopyOutlined
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          key="Clone"
          style={{ fontSize: 20, marginLeft: '10px', color: colors.main() }}
        />
      </Popconfirm>,
      <Popconfirm
        key={9986}
        title="Delete this Profile?"
        onConfirm={(e) => {
          e.stopPropagation()
          deleteProfileFunc({ variables: { id: data.id } })
        }}
        onCancel={(e) => {
          e.stopPropagation()
        }}
      >
        <DeleteOutlined
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          key="Delete"
          style={{ fontSize: 20, marginLeft: '10px', color: colors.main() }}
        />
      </Popconfirm>,
    ]
  )
}

export const columns = [
  {
    title: 'Name',
    dataIndex: 'profileName',
    key: 'profileName',
  },
  {
    title: 'Company',
    key: 'companyName',
    dataIndex: 'companyName',
  },
  {
    title: 'City',
    key: 'city',
    dataIndex: 'city',
  },
  {
    title: 'Country',
    key: 'country',
    dataIndex: 'country',
    render: (_text, { country }) => <><CountryRender code={country} /></>,
  },
  {
    title: 'Email',
    key: 'correspondanceEmail',
    dataIndex: 'correspondanceEmail',
  },
  {
    title: 'Actions',
    key: 'clone',
    width: 120,
    render: Actions,
  },
]
