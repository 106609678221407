import { detectCurrentProjectStatus, isAdmin,isTenant, roles } from '_graphql/_enums'
import { PROFILE_LIST } from '_graphql/profile'
import { ALL_PROJECTS } from '_graphql/project'
import { WBS_LIST, WBS_UPSERT_MUTATION } from '_graphql/wbs'
import {
  BankOutlined,
  CrownOutlined,
  EllipsisOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useLazyQuery,useMutation, useQuery } from '@apollo/react-hooks'
import { navigate, useLocation, useParams } from '@reach/router'
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { NotFound } from 'components'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { ContextMenuTrigger } from './styled'

const keys = ['code', 'memo', 'projectId', 'pmoId', 'customerId']

const WbsForm = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isModal = props.isModal
  const params = isModal ? {} : useParams()
  const [projectId, setProjectId] = useState(null)

  const [, forceUpdate] = useState(null)

  const [fetchWbsData, { data: wbsData, loading: wbsLoading }] = useLazyQuery(WBS_LIST, { variables: { id: +params.id } })

  const { data: profilesData, loading: profilesLoading } =
  useQuery(PROFILE_LIST, {
    variables: {
      page: 1,
      pageSize: 1000,
    },
  })


  const { data: projectData, loading: projectsLoading } = useQuery(ALL_PROJECTS)

  const [upsertWbs] = useMutation(WBS_UPSERT_MUTATION)

  const [form] = Form.useForm()

  const location = useLocation()

  const queryProps = useMemo(
    
    () => props.forceQuery ? props.forceQuery : new URLSearchParams(location.search),
    [location.search, props]
  )


  const query = useMemo(() => new URLSearchParams(location.search), [location.search])
  useEffect(() => {
    if (queryProps.has('projectId')) {
      form.setFieldsValue({
        projectId: parseInt(queryProps.get('projectId')),
      })
      setProjectId(queryProps.get('projectId'))
    }
  }), [queryProps.get('projectId')]

  useEffect(() => {
    
    if (+params.id > 0) {
      alert()
      fetchWbsData()
      
    }
  }, [params])

  const goBack = (id) => {
    if (props.onClose) {
      props.onClose(id)
    } else if (queryProps.has('backUrl')) {
      navigate(queryProps.get('backUrl'))
    } else {
      navigate('/wbs-management')
    }
  }

  const saveWbs = async () => {
    const values = form.getFieldsValue(keys)

    if (projectData?.response?.id) {
      const d = await upsertWbs({
        variables: {
          input: {
            id: projectData.response.id || null,
            ...values,
          },
        },
      })

      if (d?.data?.response) {
        message.success('saved')
        goBack(d?.data?.response?.id)
      } else {
        message.error('error')
      }
    } else {
      const d = await upsertWbs({
        variables: {
          input: {
            ...values,
          },
        },
      })

      if (d.data?.response) {
        message.success('created')
        goBack(d?.data?.response?.id)
      } else {
        message.error('creation error')
      }
    }
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={saveWbs}
      onChange={() => {
        // setSaveTrigger(!saveTrigger);
        console.log('Change')
      }}
    >
      <LayoutOverlay>
        {!isModal ? (
          <Row gutter={[20, 20]}>
            <Col xs={19} style={{ textAlign: 'left' }}>
              <h2 style={{ fontSize: '24px' }}>{t('wbs.form.new')}</h2>
            </Col>
            <Col xs={4} style={{ textAlign: 'left' }}>
              {' '}
              <Alert
                type="success"
                message="Active"
                showIcon
              />
            </Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              <ContextMenuTrigger>
                <EllipsisOutlined style={{ transform: 'rotate(90deg)' }} />
              </ContextMenuTrigger>
            </Col>
          </Row>) : (<h2 style={{ fontSize: '24px' }}>{t('wbs.form.new')}</h2>)}

        <Row gutter={[20, 20]}>
          <Col xs={isModal ? 24 : 16}>
            <LayoutCard>
              <Row gutter={[20, 20]}>
                <Col xs={12}>
                  <Form.Item
                    name="code"
                    label={t('wbs.form.code')}
                    required
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={t('wbs.form.codeHolder')} />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="memo"
                    label={t('wbs.form.memo')}
                    required
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={t('wbs.form.memoHolder')} />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item name="tenantProfileId" label="Select Entity">
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={t('globalForms.select')}
                      // disabled={!tenantId || profilesLoading}
                    >
                      {profilesData?.response && profilesData.response?.list?.filter((profile) => profile?.companyType === 'customer').map((profile) => (
                        <Select.Option value={profile.id} key={profile.id}>
                          {profile.companyName} (#{profile.id})
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={8}>

                  <Form.Item
                    name="projectId"
                    label={t('wbs.form.project')}
                    required
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={t('globalForms.select')}
                      disabled={!!projectId}
                    >
                      {projectData?.response && projectData.response.map((project) => (
                        <Select.Option value={project.id} key={project.id}>
                          {`${project.title}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </LayoutCard>
          </Col>
          {!isModal ? (
            <Col xs={8}>
              <LayoutCard>
                <Alert
                  message={t('wbs.form.info.title')}
                  description={t('wbs.form.info.description')}
                  type="info"
                  showIcon
                />
              </LayoutCard>
            </Col>
          ) : null}
        </Row>

        <LayoutStickyFooter>
          <LayoutFooterActions>
            <Button size="large" type="default" onClick={() => goBack()}>
              {t('wbs.form.cancel')}
            </Button>
            <Form.Item noStyle>
              <Button size="large" type="primary" htmlType="submit">
                {t('wbs.form.save')}
              </Button>
            </Form.Item>
          </LayoutFooterActions>
        </LayoutStickyFooter>
      </LayoutOverlay>
    </Form>
  )
}

export { WbsForm }
