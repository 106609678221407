import { Col,Row } from 'antd'
import { cost } from 'components/ListManager/DefaultTypes'
import React from 'react'
import { useTranslation } from 'react-i18next'

const TotalAmountView = ({ data, profile }) => {
  const { t } = useTranslation()
  return (
    <Row gutter={20} style={{ marginBottom: '40px' }}>

      <Col xs={12}>
        <h1>{t('apartments.fields.total-amount')}</h1>
      </Col>

      <Col xs={12}>
        <span className="total">
          {cost({ value: data.monthPrice + data.utilitiesPrice })}
        </span>
      </Col>

    </Row>
  )
}

export { TotalAmountView }
