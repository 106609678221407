import './layout.less'

import { Link, Location, Router } from '@reach/router'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import { useProject } from '../../common/ProjectProvider'
import Logo from '../../components/Logo'
import { INITIAL_ANIMATION_DELAY } from '../BaseView'

export default function AuthLayout({ children }) {
  const project = useProject()

  return (
    <div className="auth-layout">
      {/* Conent */}
      <div className="auth-layout__content">
        {/* Header */}
        <header className="auth-layout__header">
          {/* Logo */}
          <Link to="/">
            <Logo
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: INITIAL_ANIMATION_DELAY, duration: 1 }}
              className="auth-layout__logo"
            />
          </Link>
        </header>
        {/* Route */}
        <motion.main
          initial={{ opacity: 0, y: 15, overflowX: 'hidden' }}
          animate={{ opacity: 1, y: 0, overflowX: 'auto' }}
          transition={{ delay: 0.7 + INITIAL_ANIMATION_DELAY, duration: 1.3 }}
          className="auth-layout__main"
        >
          {/* Page switch animation */}
          <Location>
            {({ location }) => (
              <AnimatePresence exitBeforeEnter initial={false}>
                <motion.div
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  variants={{
                    initial: { x: 30, opacity: 0 },
                    enter: { x: 0, opacity: 1, transition: { duration: 0.5, ease: [0.48, 0.15, 0.25, 0.96] } },
                    exit: {
                      x: -30,
                      opacity: 0,
                      transition: { duration: 0.2, ease: [0.48, 0.15, 0.25, 0.96] },
                    },
                  }}
                  key={location.key}
                  className="auth-layout__route"
                >
                  <Router location={location}>{children}</Router>
                </motion.div>
              </AnimatePresence>
            )}
          </Location>
        </motion.main>
      </div>

      {/* Background */}
      <div className="auth-layout__background" style={{ backgroundColor: project.slug === '@landlords' ? '#8598BC' : '#5C4D3D' }}>
        <picture>
          <source media="(max-width: 1080px)" srcSet={`/images/auth-screen.@small.${project.slug}.jpg 761w`} sizes="100vw" />
          <source media="(max-width: 2560px)" srcSet={`/images/auth-screen.@large.${project.slug}.jpg 1080w`} sizes="100vw" />
          <motion.img
            srcSet={`/images/auth-screen.@original.${project.slug}.jpg 4368w`}
            sizes="100vw"
            alt="Auth"
            initial={{ scale: 1.05 }}
            animate={{ scale: 1 }}
            transition={{ delay: INITIAL_ANIMATION_DELAY - 0.1, duration: 2.5, ease: [0.35, 1.65, 1, 1.04] }}
          />
        </picture>
      </div>
    </div>
  )
}
