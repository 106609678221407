import { Checkbox, DatePicker, Form } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

function disabledDateHanlder(date, value, range) {
  const dateNow = moment('2019/1/1').startOf('day').valueOf()
  const dateFrom = value.dateFrom?.startOf?.('day')?.valueOf?.() || dateNow
  const dateCurrent = date.startOf('day').valueOf()

  if (range === 'start') {
    return (
      dateNow > dateCurrent
    )
  }

  if (range === 'end') {
    return !(dateCurrent > dateFrom)
  }

  return true
}

function DateRange({ 
  disabledUnlimited = false, 
  disabledDate = disabledDateHanlder,
  placeholder,
  value = {},
  onChange,
  ...props
}) {
  const { t } = useTranslation()
  const [currentRange, setCurrentRange] = useState('start')

  const setDates = (dates) => {
    onChange?.({
      ...value,
      dateFrom: dates?.[0] || null,
      dateTill: dates?.[1] || null,
      unlimited: disabledUnlimited ? false : !dates?.[1]
    })
  }

  const setUnlimited = (unlimited) => {
    onChange?.({
      ...value,
      unlimited: unlimited.target.checked,
      dateTill: unlimited.target.checked ? null : value.dateTill
    })
  }

  return (
    <DatePicker.RangePicker
      placeholder={[
        placeholder?.[0] || t('rental-request.fields.dateFrom'), 
        !disabledUnlimited && value.unlimited ? t('rental-request.fields.unlimited.true') : placeholder?.[1] || t('rental-request.fields.dateTill')
      ]}
      value={[value.dateFrom, value.dateTill]}
      disabled={[false, value.unlimited === true]}
      allowEmpty={[false, value.unlimited === true]}
      disabledDate={(date) => (disabledDate(date, value, currentRange))}
      onChange={setDates}
      dateRender={(currentDate, _, { range }) => {
        if (range !== currentRange) {
          setCurrentRange(range)
        }

        return <div className="ant-picker-cell-inner">{currentDate.format('D')}</div>
      }}
      renderExtraFooter={() => !disabledUnlimited ? (
        <Checkbox checked={value.unlimited} onChange={setUnlimited}>
          {t('rental-request.fields.unlimited.true')}
        </Checkbox>
      ) : null}
      {...props}
    />
  )
}

export { DateRange }
