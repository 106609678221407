import { BUILDINGS_LIST } from '_graphql'
import { useLazyQuery } from '@apollo/react-hooks'
import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function BuildingSelect({ 
  profileId = null,
  value = null,
  onChange,
  ...props
}) {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(!!value)

  const [fetch, { data, loading }] = useLazyQuery(BUILDINGS_LIST)

  useEffect(() => {
    if (profileId && (value || isFocused)) {
      fetch({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            profileIds: [profileId],
          }),
        }
      })
    }
  }, [value, isFocused, profileId])

  const setValue = (value) => {
    onChange?.(value)
  }

  return (
    <Select
      showSearch
      loading={loading}
      disabled={!profileId}
      style={{ width: '100%' }}
      onChange={setValue}
      onFocus={() => { !isFocused && setIsFocused(true) }}
      value={value}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      {...props}
    >
      <Select.Option value={null}>{t('rental-request.components.sales-select.not-selected')}</Select.Option>
      {data?.response?.list?.map?.((building) => (
        <Select.Option value={building.id} key={building.id}>
          {`#${building.code} Address: ${building.street} ${building.number} ${building.zipCode}`}
        </Select.Option>
      ))}
    </Select>
  )
}

export { BuildingSelect }
