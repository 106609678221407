import { gql } from 'apollo-boost'

import { profileFragment } from '../_fragments'

export const PROFILE_UPSERT_MUTATION = gql`
  mutation profile($input: ProfileInput!) {
    response: profile(input: $input) {
        ...profileFragment
    }
  }
  ${profileFragment}
`

export const PROFILE_DELETE_MUTATION = gql`
  mutation deleteProfile($id: Int!) {
    deleteProfile(id: $id)
  }
`


export const PROFILE_RESTORE_MUTATION = gql`
  mutation restoreProfile($id: Int!) {
    restoreProfile(id: $id) {
      ...profileFragment
    }
  }
  ${profileFragment}
`
