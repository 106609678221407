import i18next from 'i18next'

export const amenitiesInitialState = {
  default: [

    {
      get title() { return i18next.t('buildings.fields.carParking.title') },
      id: 'carParking',
      selected: false,
      options: [
        {
          get title() { return i18next.t('buildings.fields.carParking.paid') },
          id: 'paid',
          selected: false,
        },
        {
          get title() { return i18next.t('buildings.fields.carParking.free') },
          id: 'free',
          selected: false,
        },
      ],
    },
    {
      get title() { return i18next.t('buildings.fields.roofTerrace.title') },
      id: 'roofTerrace',
      selected: false,
    },
    {
      get title() { return i18next.t('buildings.fields.conciergeService.title') },
      id: 'conciergeService',
      selected: false,
    },
    {
      get title() { return i18next.t('buildings.fields.spa.title') },
      id: 'spa',
      selected: false,
    },
    {
      get title() { return i18next.t('buildings.fields.children.title') },
      id: 'children',
      selected: false,
    },
    {
      get title() { return i18next.t('buildings.fields.laundry.title') },
      id: 'laundry',
      selected: false,
    },
  ],
  custom: [],
}
