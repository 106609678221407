import DotsHorizontal from '@2fd/ant-design-icons/lib/DotsHorizontal'
// import { Button, Dropdown, Menu, PageHeader as AntdPageHeader } from 'antd'
import { Layout } from 'antd'
const {  Footer } = Layout
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

export function EntityFooter({ user, date, extra }) {
  const { t } = useTranslation()
  return (
    <>
      {/* SEO */}
      {/* {formatedTitle ? (
        <Helmet>
          <title>{formatedTitle || project.title} {formatedSubTitle ? ` — ${formatedSubTitle}` : null}</title>
        </Helmet>
      ) : null} */}
      <Footer
        style={{ textAlign: 'left',
          marginTop: 'auto',
          paddingBottom: '9px' }}
      >
        <>
          { t('service.form.updatebytext') + ' ' + user.lastName + ' ' + user.firstName}
          <br />
          {DateTime.fromISO(date).toFormat('LLL dd, yyyy | HH:mm:ss')}
        </>
      </Footer>
    </>
  )
}
