import { Tooltip } from 'antd'
import React from 'react'

import { AmenityWrapper,Icon } from './styled'

const RoomAmenity = ({ amenity, AmenityIcon, isSelected, onAmenitySelect }) => (
  <Tooltip title={amenity.label} key={amenity.label}>
    <AmenityWrapper isSelected={isSelected} onClick={onAmenitySelect}>
      {AmenityIcon && <Icon><AmenityIcon /></Icon>}
    </AmenityWrapper>
  </Tooltip>
)

export { RoomAmenity }
