import {
  APARTMENTS_LIST,
  BUILDINGS_LIST,
  REQUESTS_LIST,
  WBS_LIST,
} from '_graphql'
import {
  detectCurrentProjectStatus,
  isAdmin,
  roles,
  USER_ROLES,
} from '_graphql/_enums'
import { APARTMENT_QUERY } from '_graphql/apartment'
import { PROFILE_LIST } from '_graphql/profile'
import { PROJECT_LIST } from '_graphql/project'
import {
  RENTAL_CONTRACT_UPSERT_MUTATION,
  RENTAL_CONTRACTS_LIST,
} from '_graphql/rentalContract'
import { ALL_USERS_QUERY, USERS_LIST } from '_graphql/user/queries'
import {
  BankOutlined,
  CrownOutlined,
  EllipsisOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { navigate, useLocation, useParams } from '@reach/router'
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { NotFound } from 'components'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import { cost, string } from 'components/ListManager/DefaultTypes'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { ContextMenuTrigger } from './styled'

const keys = [
  'projectId',
  'wbsId',
  'tenantId',
  'landlordId',
  'dateFrom',
  'dateTill',
  'apartmentId',
  'landlordProfileId',
  'tenantEmployeeId',
  'tenantProfileId',
]

const ContractForm = ({ asComponent, onClose, contract, isModal }) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const [, rerender] = useState()

  const { data: landlordsData, loading: landlordsLoading } = useQuery(
    USERS_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
        filter: JSON.stringify({ role: [USER_ROLES['landlord-owner'].key] }),
      },
    }
  )

  const [upsertContract] = useMutation(RENTAL_CONTRACT_UPSERT_MUTATION)

  const [fetchTenants, { data: tenantsData, loading: tenantsLoading }] =
    useLazyQuery(USERS_LIST, {
      variables: {
        page: 1,
        pageSize: 1000,
      },
    })

  const [fetchSales, { data: salesData, loading: salesLoading }] = useLazyQuery(
    USERS_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
      },
    }
  )

  const { data: customerData, loading: customerLoading } = useQuery(
    USERS_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
        filter: JSON.stringify({
          role: [USER_ROLES['tentant-admin'].key],
        }),
      },
    }
  )

  const [fetchProjects, { data: projectsData, loading: projectsLoading }] =
    useLazyQuery(PROJECT_LIST, {
      variables: {
        page: 1,
        pageSize: 1000,
      },
    })

  const [fetchWbs, { data: wbsData, loading: wbsLoading }] = useLazyQuery(
    WBS_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
      },
    }
  )

  const { data: requestsData, loading: requestsLoading } = useQuery(
    REQUESTS_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
      },
    }
  )

  const [fetchProfiles, { data: profilesData, loading: profilesLoading }] =
    useLazyQuery(PROFILE_LIST, {
      variables: {
        page: 1,
        pageSize: 1000,
      },
    })

  const [fetchBuildings, { data: buildingsData, loading: buildingsLoading }] =
    useLazyQuery(BUILDINGS_LIST, {
      variables: {
        page: 1,
        pageSize: 1000,
      },
    })

  const [
    fetchApartments,
    { data: apartmentsData, loading: apartmentsLoading },
  ] = useLazyQuery(APARTMENTS_LIST, {
    variables: {
      page: 1,
      pageSize: 1000,
    },
  })

  const [form] = Form.useForm()

  useEffect(() => {
    if (contract) {
      for(const key of keys) {
        let value = contract[key]
        if (['dateFrom', 'dateTill'].includes(key) && typeof value === 'string') {
          value = moment(value)
        }

        form.setFieldsValue({
          [key]: value
        })
      }
      // console.log('contract', contract)
      // 
    }
  }, [contract])

  const location = useLocation()
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )

  const tenantId = form.getFieldValue('tenantId') ?? query.has('tenantId')
  const tenantProfileId =
    form.getFieldValue('tenantProfileId') ?? query.has('tenantProfileId')
  const projectId = form.getFieldValue('projectId') ?? query.has('projectId')
  const landlordId =
    form.getFieldValue('landlordId') ?? query.has('landlordId')
  const buildingId =
    form.getFieldValue('buildingId') ?? query.has('buildingId')
  const landlordProfileId =
    form.getFieldValue('landlordProfileId') ?? query.has('landlordProfileId')
  const apartmentId =
    form.getFieldValue('apartmentId') ?? query.has('apartmentId')
  const tenantEmployeeId =  form.getFieldValue('tenantEmployeeId') ?? query.has('tenantEmployeeId')




  useEffect(() => {
    fetchSales({
      variables: {
        page: 1,
        pageSize: 1000,
        filter: JSON.stringify({
          role: [USER_ROLES['b2b-admin'].key],
        }),
      },
    })
    if (tenantId) {
      fetchProfiles({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            users: [tenantId],
          }),
        },
      })

      fetchProjects({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            users: [tenantId],
          }),
        },
      })
    }
  }, [tenantId])
  useEffect(() => {
    if (query.has('projectId')) {
      form.setFieldsValue({
        projectId: parseInt(query.get('projectId')),
      })
    }
  }, [location.search])

  useEffect(() => {
    if (form.getFieldValue('projectId')) {
      const filter = { projectIds: [form.getFieldValue('projectId')] }
      const baseVariables = {
        page: 1,
        pageSize: 1000,
        filter: JSON.stringify(filter),
      }
     
      fetchTenants({
        variables: {
          ...baseVariables,
          filter: JSON.stringify({
            ...filter,
            role: [USER_ROLES['tentant-employee'].key],
          }),
        },
      })
    }
  }, [form.getFieldValue('projectId')])

  useEffect(() => {
    if (tenantEmployeeId) {
      fetchWbs({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            userIds: [tenantEmployeeId],
          }),
        }
      })
    }
  }, [tenantEmployeeId])

  useEffect(() => {
    if (form.getFieldValue('landlordId')) {
      fetchProfiles({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            users: [landlordId],
          }),
        },
      })

      if (landlordProfileId) {
        fetchBuildings({
          variables: {
            page: 1,
            pageSize: 200,
            filter: JSON.stringify({
              landlords: [landlordProfileId],
            }),
          },
        })
      }
    }
  }, [form.getFieldValue('landlordId'), landlordProfileId])

  useEffect(() => {
    if (buildingId) {
      fetchApartments({
        variables: {
          page: 1,
          pageSize: 200,
          filter: JSON.stringify({
            buildings: [buildingId],
          }),
        },
      })
    }
  }, [buildingId])

  let [currentApartment, setCurrentApartment] = useState(null)
  useEffect(() => {
    setCurrentApartment(
      apartmentsData?.response?.list?.find?.(
        (apartment) => apartment.id === apartmentId
      ) ?? null
    )
  }, [apartmentId, apartmentsData])

  const goBack = (sku = '') => {
    if (onClose) {
      onClose?.(sku)
    } else if (query.has('backUrl')) {
      navigate(query.get('backUrl'))
    } else {
      navigate(`/rental-contracts-management${sku ? `/${sku}` : ''}`)
    }
  }

  const saveContract = async () => {
    const values = form.getFieldsValue(keys)

    console.log(values)

    if (isAdmin(state.currentUser?.role)) {
      values.salesId = state.currentUser?.id
    }
 
    const d = await upsertContract({
      variables: {
        input: {
          ...values,
        },
      },
    })

    if (d.data?.response) {
      message.success('created')
      goBack(d.data?.response?.sku)
    } else {
      message.error('creation error')
    }
  }

  const formElements = (
    <>
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <h3 style={{ fontSize: '20px' }}>
            {t('contract.page.tenant')} Chain
          </h3>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xs={8}>
          <Form.Item name="tenantId" label="Select Tenant Company" required rules={[{ required: true }]}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={customerLoading}
            >
              {customerData?.response?.list?.map?.((user) => (
                <Select.Option value={user.id} key={user.id}>
                  {user.lastName} {user.firstName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item name="tenantProfileId" label="Select Entity" required rules={[{ required: true }]}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={!tenantId || profilesLoading}
            >
              {profilesData?.response?.list?.map?.((profile) => (
                <Select.Option value={profile.id} key={profile.id}>
                  {profile.companyName} (#{profile.id})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item name="projectId" label="Select Project" required rules={[{ required: true }]}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={!tenantProfileId || projectsLoading}
              onChange={rerender}
            >
              {projectsData?.response?.list?.map?.((project) => (
                <Select.Option value={project.id} key={project.id}>
                  {project.title} (#{project.id})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item name="tenantEmployeeId" label="Select employee" >
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={!projectId || tenantsLoading}
            >
              {tenantsData?.response?.list?.map?.((tenant) => (
                !tenant.isBusy ? 
                  <Select.Option value={tenant.id} key={tenant.id}>
                    {tenant.lastName}
                  </Select.Option>
                  : null
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item name="wbsId" label="Select Project Code (WBS)">
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={!tenantEmployeeId  || !projectId || wbsLoading}
            >
              {wbsData?.response?.list?.map?.((wbs) => (
                wbs.projectId === projectId ?
                  <Select.Option value={wbs.id} key={wbs.id}>
                    {wbs.code}
                  </Select.Option>
                  : null
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <h3 style={{ fontSize: '20px' }}>
            {t('contract.page.landlord')} Chain
          </h3>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xs={8}>
          <Form.Item
            name="landlordId"
            label={t('contract.form.landlord')}
            required
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={landlordsLoading}
            >
              {landlordsData?.response?.list?.map?.((user) => (
                <Select.Option value={user.id} key={user.id}>
                  {user.lastName} {user.firstName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item name="landlordProfileId" label="Landlord Company" required>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={!landlordId || profilesLoading}
            >
              {profilesData?.response?.list?.map?.((profile) => (
                <Select.Option value={profile.id} key={profile.id}>
                  {profile.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            name="buildingId"
            label={t('contract.form.building')}
            required
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={buildingsLoading || !landlordProfileId}
              filterOption={(input, option) =>
                option['data-search'].indexOf(
                  input.toLowerCase().trim()
                ) >= 0
              }
            >
              {buildingsData?.response?.list?.map?.((building) => (
                <Select.Option
                  value={building.id}
                  key={building.id}
                  data-search={`#${building.code}`.toLowerCase()}
                >
                  {`#${building.code} Address: ${building.street} ${building.number} ${building.zipCode}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            name="apartmentId"
            label={t('contract.form.apartment')}
            required
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={apartmentsLoading || !buildingId}
              // filterOption={(input, option) =>
              //   option["data-search"].indexOf(
              //     input.toLowerCase().trim()
              //   ) >= 0
              // }
            >
              {apartmentsData?.response?.list?.map?.((apt) => (
                !apt.isBooked ? 
                  <Select.Option
                    value={apt.id}
                    key={apt.id}
                    data-search={`#${apt.code} ${apt.title}`.toLowerCase()}
                  >
                    #{apt.code}, {apt.title} [{apt.monthPrice} + {apt.utilitiesPrice}]
                  </Select.Option>
                  : null
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={16}>
          <br />
          {currentApartment
            ? `Apt. price ${currentApartment.monthPrice} + Utilities ${currentApartment.utilitiesPrice}`
            : ''}
        </Col>
      </Row>
      <Divider />
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <h3 style={{ fontSize: '20px' }}>
            {t('contract.page.service')} Chain
          </h3>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xs={8}>
          <Form.Item name="salesId"  required  rules={[{ required: true }]} label={t('contract.form.sales')}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              
              disabled={salesLoading}
            >
              {salesData?.response?.list?.map?.((user) => (
                <Select.Option value={user.id} key={user.id}>
                  {user.lastName} {user.firstName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            name="rentalRequestId"
            
            label={t('contract.form.request')}
          >
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={projectsLoading}
            >
              {requestsData?.response?.list?.map?.((request) => (
                <Select.Option value={request.id} key={request.id}>
                  {request.sku} #{request.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={4}>
          <Form.Item
            name="dateFrom"
            label={t('contract.form.dateFrom')}
            required
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col xs={4}>
          <Form.Item
            name="dateTill"
            label={t('contract.form.dateTill')}
            required
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
    </>
  )

  const formFooter = (
    <LayoutFooterActions>
      <Button size="large" type="default" onClick={() => goBack()}>
        {t('projects.form.cancel')}
      </Button>
      <Form.Item noStyle>
        <Button size="large" type="primary" htmlType="submit">
          {t('contract.form.save')}
        </Button>
      </Form.Item>
    </LayoutFooterActions>
  )

  if (asComponent) {
    return (
      <Form
        layout="vertical"
        form={form}
        onFinish={saveContract}
        onFieldsChange={rerender}
        onChange={() => {
        // setSaveTrigger(!saveTrigger);
          console.log('Change')
        }}
      >
        {formElements}
        {formFooter}
      </Form>
    )
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={saveContract}
      onFieldsChange={rerender}
      onChange={() => {
        // setSaveTrigger(!saveTrigger);
        console.log('Change')
      }}
    >
      <LayoutOverlay>
        <Row gutter={[20, 20]}>
          <Col xs={20} style={{ textAlign: 'left' }}>
            <h2 style={{ fontSize: '24px' }}>{t('contract.form.new')}</h2>
          </Col>
          <Col xs={4} style={{ textAlign: 'left' }}>
            {' '}
            <Alert
              {...(() => {
                // const status = detectCurrentProjectStatus(projectData);
                const status = {
                  title: 'New',
                  color: 'blue',
                  alertType: 'info',
                }
                return { message: status.title, type: status.alertType }
              })()}
              showIcon
            />
          </Col>
        </Row>

        <Row gutter={[20, 20]}>
          <Col xs={isModal ? 24 : 16}>
            <LayoutCard>
              {formElements}
            </LayoutCard>
          </Col>

          {!isModal ? (
            <Col xs={8}>
              <LayoutCard>
                <Alert
                  message={t('contract.form.alert.title')}
                  description={t('contract.form.alert.description')}
                  type="info"
                  showIcon
                />
              </LayoutCard>
            </Col>
          ) : null}
        </Row>

        <LayoutStickyFooter>
          {formFooter}
        </LayoutStickyFooter>
      </LayoutOverlay>
    </Form>
  )
}

export { ContractForm }
