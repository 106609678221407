import 'firebase/messaging'

import { USER_UPSERT_MUTATION } from '_graphql'
import { useMutation } from '@apollo/client'
import firebase from 'firebase/app'
import React, { useCallback, useEffect, useState } from 'react'
import { useGlobalState } from 'state'

firebase.initializeApp({
  apiKey: 'AIzaSyAUphh0KQ1RfyJGQLRwFFlBSEJqEYlETpU',
  authDomain: 'chairos-01.firebaseapp.com',
  projectId: 'chairos-01',
  storageBucket: 'chairos-01.appspot.com',
  messagingSenderId: '638623838924',
  appId: '1:638623838924:web:dd9b13387f9e2709e88869'
})

let messaging = null

try {
  messaging = firebase.messaging()
} catch {
  messaging = null
}

const STORAGE_KEY = 'BKnU1qAkj6QN7fyTE_VwRpMbY2PCQ46zRGPualIaiw_Ux4kfxgzP35ySECTA7QdvFsbpY6MM8ix-UM_2RMZMA7w'

export const WebPush = () => {
  const [state] = useGlobalState()
  const [token, setToken] = useState(null)
  const [upsertUser] = useMutation(USER_UPSERT_MUTATION)

  const subscribe = useCallback(async () => {
    try {
      const currentToken = await messaging.getToken({vapidKey: 'BKnU1qAkj6QN7fyTE_VwRpMbY2PCQ46zRGPualIaiw_Ux4kfxgzP35ySECTA7QdvFsbpY6MM8ix-UM_2RMZMA7w'})

      if (currentToken) {
        actualizeServerToken(currentToken)
      } else {
        console.warn('Failed to get token.')
        actualizeServerToken(null)
      }
    } catch (err) {
      console.warn('An error occurred while getting the token.', err)
      actualizeServerToken(null)
    }
  }, [])

  const actualizeServerToken = useCallback((currentToken) => {
    try {
      let webpushIds = state?.currentUser?.webpushIds ?? []

      if (state?.currentUser && !webpushIds.includes(currentToken)) {
      // Remove token
        if (!currentToken) {
          webpushIds = webpushIds.filter((item) => item !== token)
          localStorage.removeItem(STORAGE_KEY)
          setToken(null)
        }

        // Add token
        else {
          webpushIds.push(currentToken)
          localStorage.setItem(STORAGE_KEY, currentToken)
          setToken(currentToken)
        }

        // Send to server
        upsertUser({ variables: { input: {
          id: state?.currentUser.id,
          webpushIds,
        } } })
      }
    } catch {
      console.warn('actualizeServerToken')
    }
  }, [state?.currentUser])

  useEffect(() => {
    try {
      if ('Notification' in window) {
        setToken(localStorage.getItem(STORAGE_KEY) || null)

        // if (Notification.permission === 'granted') {
        subscribe()
        // }

      // TODO: Подписываться только если права предоставлены и дать вручную запускать запрос на уведомления
      }
    } catch {
      console.warn('WebPush disabled')
    }
  }, [state?.currentUser])

  return <></>
}
