import { NOTIFICATION_ADDED, NOTIFICATION_READED, NOTIFICATIONS_LIST } from '_graphql'
import { isAdmin, USER_ROLES } from '_graphql/_enums'
import BellOutline from '@2fd/ant-design-icons/lib/BellOutline'
import ChevronLeft from '@2fd/ant-design-icons/lib/ChevronLeft'
import Logout from '@2fd/ant-design-icons/lib/Logout'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import { Link, Location, Router } from '@reach/router'
import { Badge, Button, Divider, Drawer, Layout, Menu,Tooltip } from 'antd'
import { ModalsProvider } from 'common/ModalsProvider'
import Logo from 'components/Logo'
import { Notifications } from 'components/Notifications'
import { TutorialUIItem } from 'components/TutorialUI'
import UserPreview from 'components/User/Widgets/Preview'
import { AnimatePresence, motion } from 'framer-motion'
import { client } from 'GQLClient'
import i18n from 'i18next'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useKeyboardJs from 'react-use/lib/useKeyboardJs'
import { navSetup } from 'routing'
import { useRentalContractsCounter } from 'screens/RentalContracts'
import { useRentalRequestsCounter } from 'screens/RentalRequests'
import { useServiceRequestsCounter } from 'screens/ServiceRequests'
import { useGlobalState } from 'state'

import styles from './layout.less'
const { SubMenu } = Menu

const DashboardMenu = () => {
  const [state, dispatch] = useGlobalState()
  const { currentUser } = state

  // Counters
  const counters = {
    'rental-requests': useRentalRequestsCounter('actionRequired'),
    'rental-contracts': useRentalContractsCounter('actionRequired'),
    'service-requests': useServiceRequestsCounter('new'),
  }

  const [lastUrl, setLastUrl] = useState(window.location.toString())
  useEffect(() => {
    const timer = setInterval(() => {

      Object.values(counters).map(counter => counter.refetch())
      if (lastUrl !== window.location.toString()) {
        
        setLastUrl(window.location.toString())
      }
    }, 5000)

    return () => clearTimeout(timer)
  }, [lastUrl])

  return (
    <Location>
      {({ location }) => (
        <Menu
          mode="inline"
          selectedKeys={navSetup.find((item) => !item.sidebarSkip && item.roles.includes(currentUser?.role) && location.pathname.includes(item.route))?.route}
        >
          {navSetup.map(
            (item) => !item.sidebarSkip && item.roles.includes(currentUser?.role) && (
              <TutorialUIItem key={item.route}>
                <Menu.Item id={item.id && `${item.id}-sidebar-menu-item`} icon={item.icon}>
                  <Link to={item.route} />
                  {item.title}
                  {item.counter ? (
                    <span style={{ float: 'right', pointerEvents: 'none' }}>
                      <Badge count={counters[item.counter]?.value} />
                    </span>
                  ) : null}
                </Menu.Item>
              </TutorialUIItem>
            ),
          )}
        </Menu>
      )}
    </Location>
  )
}

export default function DashboardLayout({ children }) {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)
  const { currentUser } = state
  const [collapsed, setCollapsedRaw] = useState(localStorage.getItem('dasboard.sider') === 'true')
  const [notifications, setNotifications] = useState(false)
  const [counter, setCounter] = useState(0)
  const [newNav, setNewNav] = useState(null)

  const { data: rawNotificationsLastPage, loading: notificationsLoading, refetch: notificationsRefetch } = useQuery(
    NOTIFICATIONS_LIST, 
    { 
      variables: { 
        page: 1, 
        pageSize: 15,
        filter: JSON.stringify({})
      }, 
      fetchPolicy: 'no-cache' 
    })
  // const counter = rawNotificationsLastPage?.response?.list?.length ?? 0

  useEffect(() => {
    if (rawNotificationsLastPage && !counter) {
      setCounter(rawNotificationsLastPage?.response?.pagination?.total)
    }
  }, [rawNotificationsLastPage])

  const { data: notificationsSub } = useSubscription(NOTIFICATION_ADDED, {
    fetchPolicy: 'no-cache',
    onError: (err) => console.error(err),
  })

  
  const { data: notificationReaded } = useSubscription(NOTIFICATION_READED, {
    fetchPolicy: 'no-cache',
    onError: (err) => console.error(err),
  })


  // Subscribe
  useEffect(() => {
    if (notificationsSub) {
      setCounter(counter => counter + 1)
    }
  }, [notificationsSub])

  useEffect(() => {
    if (notificationReaded) {
      setCounter(counter => counter - 1)
    }
  }, [notificationReaded])

  const setCollapsed = useCallback((value) => {
    localStorage.setItem('dasboard.sider', String(value))
    setCollapsedRaw(value)
  }, [collapsed])
  

  // Logout
  const logout = async () => {
    localStorage.removeItem('token')
    await client.resetStore()
    await client.clearStore()
    dispatch({
      type: 'LOGIN',
      payload: false,
    })
  }

  // Collapse menu pressing menu + shift > m
  const [keyIsPresed] = useKeyboardJs('menu + shift > m')
  useEffect(() => { keyIsPresed && setCollapsed(!collapsed) }, [keyIsPresed])

  const changeLanguage = useCallback((lang) => i18n.changeLanguage(lang), [])

  if (!currentUser) {
    return null
  }

  // useEffect(() =>{ 
    
  // }, [navSetup])

  const closeNotifications = () => setNotifications(false)

  return (
    <>
      <Layout className={styles.layout}>

        {/* Side menu */}
        <Layout.Sider collapsed={collapsed} className={styles.sider} width="260" theme="light">
          <div className={styles.siderGrid}>

            {/* Header */}
            <div className={styles.siderHeader}>
              {/* Logo */}
              <Link to="/">
                <Logo className={styles.logo} size={collapsed ? 'small' : 'middle'} />
              </Link>
            </div>

            {currentUser ? (
              <>
                {/* User Widget */}
                <Divider />
                <UserPreview
                  user={currentUser}
                  type={collapsed ? 'short-panel' : 'panel'}
                  actions={(
                    <>
                      {/* Notifications */}
                      <Location>
                        {({ location }) => (
                          <Tooltip placement="right" title="Notifications">
                            <Button type={collapsed ? 'circle' : 'text'} size="small" onClick={() => setNotifications(true)}>
                              <Badge size="small" count={counter ? counter : 0} offset={[-25, 0]}><BellOutline /></Badge>
                            </Button>
                          </Tooltip>
                        )}
                      </Location>
                      <br />
                      {/* Logout */}
                      <Tooltip placement="right" title="Logout">
                        <Button type={collapsed ? 'circle' : 'text'} size="small" onClick={logout}>
                          <Logout />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                />
                <Divider />

                {/* Menu */}
                <DashboardMenu />

              </>
            ) : ''}

            
            {/* Language picker */}
            <motion.div
              className={styles.languagePicker}
              initial="initial"
              animate={!collapsed ? 'enter' : 'exit'}
              variants={{
                initial: { opacity: 0 },
                enter: { opacity: 1, transition: { delay: 0.5, duration: 0.1, ease: [0.48, 0.15, 0.25, 0.96] } },
                exit: { opacity: 0, transition: { duration: 0.1, ease: [0.48, 0.15, 0.25, 0.96] } },
              }}
            >
              { isManagementUser ? <Link to='/changelog' ><span>{t('version')}</span></Link> : null}
              <Divider />
              <Button type={i18n.language === 'en' ? 'link' : 'text'} onClick={() => changeLanguage('en')}>
                <span className="anticon">🇬🇧</span>
                <span>English</span>
              </Button>
              <Button type={i18n.language === 'de' ? 'link' : 'text'} onClick={() => changeLanguage('de')}>
                <span className="anticon">🇩🇪</span>
                <span>Deutsch</span>
              </Button>
            </motion.div>
          </div>

          {/* Colapse button */}
          <Tooltip
            placement="rightTop"
            title={(
              <>
                {t('common.sidebar.toggle-view')}<br />
                <code>alt</code> + <code>shift</code> + <code>m</code>
              </>
            )}
          >
            <Button onClick={() => setCollapsed(!collapsed)} className={styles.collapseSider} type="text">
              <motion.span
                initial="initial"
                animate={collapsed ? 'closed' : 'opened'}
                variants={{
                  initial: { rotate: 180 },
                  opened: { rotate: 0, transition: { duration: 0.5, ease: [0.48, 0.15, 0.25, 0.96] } },
                  closed: { rotate: 180, transition: { duration: 0.5, ease: [0.48, 0.15, 0.25, 0.96] } },
                }}
              >
                <ChevronLeft />
              </motion.span>
            </Button>
          </Tooltip>

        </Layout.Sider>

        {/* Content */}
        <Layout className={styles.content}>
          <Layout.Content>
            {/* Route */}
            <Location>
              {({ location }) => (
              // <AnimatePresence exitBeforeEnter initial={false}>
                <motion.div
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  variants={{
                    initial: { opacity: 0 },
                    enter: { opacity: 1, transition: { duration: 0.5, ease: [0.48, 0.15, 0.25, 0.96] } },
                    exit: { opacity: 0, transition: { duration: 0.2, ease: [0.48, 0.15, 0.25, 0.96] } },
                  }}
                  key={currentUser ? location.key : '@loading'}
                  className={styles.route}
                >
                  <Router location={location}>
                    {children}
                  </Router>
                  <ModalsProvider />
                </motion.div>
              // </AnimatePresence>
              )}
            </Location>
          </Layout.Content>
        </Layout>
      </Layout>

      {/* Notifications */}
      <Drawer 
        placement="right" 
        width="400"
        visible={notifications}
        title="Notifications"
        onClose={closeNotifications}
      >
        <Notifications close={closeNotifications} />
      </Drawer>
    </>
  )
}
