import { gql } from 'apollo-boost'

export const wbsFragment = gql`
  fragment wbsFragment on Wbs {
    id
    sku
    code
    memo
    projectId
    pmoId
    customerId
    createdBy
    createdAt
    updatedAt
    updatedBy
    expireAt
  }
`
