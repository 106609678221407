import { DeleteOutlined } from '@ant-design/icons'
import { Description } from '@material-ui/icons'
import { Button, Col, Input, Row, Space } from 'antd'
import { ReactComponent as DOUBLE_BED } from 'img/icons/amenities/extra_bed.svg'
/* Bedroom icons */
import { ReactComponent as SINGLE_BED } from 'img/icons/amenities/single_bed.svg'
import { ReactComponent as BATH_TUB } from 'img/icons/bathroom/bath_tub.svg'
/* Bathroom icons */
import { ReactComponent as DAYLIGHT_BATH } from 'img/icons/bathroom/daylight_bath.svg'
import { ReactComponent as SHOWER } from 'img/icons/bathroom/shower.svg'
import { ReactComponent as TOILET } from 'img/icons/bathroom/toilet.svg'
import { ReactComponent as VENTILATION } from 'img/icons/bathroom/ventilation.svg'
/* Kitchen icons */
import { ReactComponent as COFFE_MASHINE } from 'img/icons/kitchen/coffee_maschine.svg'
import { ReactComponent as COOKTOP } from 'img/icons/kitchen/cooktop.svg'
import { ReactComponent as DISHWASHER } from 'img/icons/kitchen/dishwasher.svg'
import { ReactComponent as EQUIPPED } from 'img/icons/kitchen/equipped.svg'
import { ReactComponent as EXTRACTOR_HOOD } from 'img/icons/kitchen/extractor_hood.svg'
import { ReactComponent as FREEZER } from 'img/icons/kitchen/freezer.svg'
import { ReactComponent as FRIDGE } from 'img/icons/kitchen/fridge.svg'
import { ReactComponent as MICROWAVE } from 'img/icons/kitchen/microwave.svg'
import { ReactComponent as OVEN } from 'img/icons/kitchen/oven.svg'
import { ReactComponent as SMALL } from 'img/icons/kitchen/small.svg'
import { ReactComponent as TOASTER } from 'img/icons/kitchen/toaster.svg'
import { ReactComponent as WATER_HEATER } from 'img/icons/kitchen/water_heater.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { RoomAmenity } from './RoomAmenity'
import { RoomTypes } from './RoomTypes'
import { RoomWrapper } from './styled'

const icons = {
  singleBed: SINGLE_BED,
  doubleBed: DOUBLE_BED,

  daylight_bath: DAYLIGHT_BATH,
  bathtub: BATH_TUB,
  ventilation: VENTILATION,
  toilet: TOILET,
  shower: SHOWER,

  coffee_maschine: COFFE_MASHINE,
  cooktop: COOKTOP,
  dishwasher: DISHWASHER,
  equipped: EQUIPPED,
  extractor_hood: EXTRACTOR_HOOD,
  freezer: FREEZER,
  fridge: FRIDGE,
  microwave: MICROWAVE,
  oven: OVEN,
  small: SMALL,
  toaster: TOASTER,
  water_heater: WATER_HEATER,
}

const Inventories = ({ data, onAmenitySelect, onTypeChange }) => {
  const { t } = useTranslation()
  return (
    <Space direction="vertical" size={20} style={{ width: '100%' }}>
      <Row>
        {data.types?.length ? (
          <Col xs={12}>
            <h2>{t('apartments.fields.types')}</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {data.types.map((type) => (
                <RoomTypes
                  amenity={type}
                  key={type}
                  isSelected={data.selectedType === type.value}
                  AmenityIcon={icons[type.value]}
                  onTypeChange={() =>
                    onTypeChange ? onTypeChange(type.value) : null
                  }
                />
              ))}
            </div>
          </Col>
        ) : null}
        {data.amenities?.length ? (
          <Col xs={12}>
            <h2>{t('apartments.sections.amenities')}</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {data.amenities.map((amenity) => (
                <RoomAmenity
                  amenity={amenity}
                  key={amenity}
                  isSelected={
                    data.selectedAmenities?.length
                      ? data.selectedAmenities.includes(amenity.value)
                      : false
                  }
                  onAmenitySelect={() => onAmenitySelect(amenity.value)}
                  AmenityIcon={icons[amenity.value]}
                />
              ))}
            </div>
          </Col>
        ) : null}
      </Row>
    </Space>
  )
}

const Room = ({
  roomType,
  data,
  onDelete,
  roomsCount,
  onTitleChange,
  onAmenitySelect,
  onTypeChange,
  onAdditionalChange,
  addAdditional,
  deleteAdditional,
}) => {
  const { t } = useTranslation()
  return (
    <RoomWrapper>
      <Row gutter={[20, 20]} style={{ margin: 0 }}>
        {roomType === 'kitchen' || roomType === 'bathroom' || roomType === 'bedroom' 
          ? <Col xs={16}>
            <Inventories
              roomType={roomType}
              data={data}
              onAmenitySelect={onAmenitySelect}
              onTypeChange={onTypeChange}
            />
          </Col>
          : null}
        
        <Col xs={8}>
          <h2 style={{ marginBottom: 0}}>{t('apartments.sections.additional-inventories')}:</h2><br></br>
          <Button style={{ padding: '0 20px', margin: '8px 0 20px 0'  }} onClick={addAdditional}>
            {t('apartments.actions.add-custom-inventory')}
          </Button><br />
          {data.additional &&
              data.additional.map((item, index) => (
                <>
                  <Input
                    placeholder="custom inventory title"
                    value={item}
                    onChange={(e) => onAdditionalChange(e.target.value, index)}
                    addonAfter={
                      <DeleteOutlined onClick={() => deleteAdditional(index)} />
                    }
                  />
                  <Col xs={12} />
                </>
              ))}
        </Col>
        
        
  

        <Col xs={24} style={{ textAlign: 'right' }}>
          <Button
            disabled={roomsCount === 1 && roomType !== 'custom'}
            size="small"
            onClick={() => onDelete(data)}
          >
            {t('apartments.actions.deleteRoom')}
          </Button>
        </Col>
      </Row>
    </RoomWrapper>
  )
}

export { Room }
