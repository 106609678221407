import { gql } from 'apollo-boost'

import { reportFragment } from '../_fragments'

export const REPORT_UPSERT_MUTATION = gql`
  mutation report($input: ReportInput!) {
    response: report(input: $input) {
      ...reportFragment
    }
  }
  ${reportFragment}
`
export const REPORT_DELETE_MUTATION = gql`
  mutation deleteReport($sku: String) {
    response: deleteReport(sku: $sku)
  }
`
