import { RENTAL_CONTRACTS_LIST } from '_graphql'
import { useLazyQuery } from '@apollo/react-hooks'
import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function ContractSelect({ 
  userId = null,
  value = null,
  onChange,
  ...props
}) {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(!!value)

  const [fetch, { data, loading }] = useLazyQuery(RENTAL_CONTRACTS_LIST)

  useEffect(() => {
    if (value || isFocused) {
      fetch({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            // users: [userId],
          }),
        }
      })
    }
  }, [value, isFocused])

  const setValue = (value) => {
    onChange?.(value)
  }

  return (
    <Select
      showSearch
      loading={loading}
      style={{ width: '100%' }}
      onChange={setValue}
      onFocus={() => { !isFocused && setIsFocused(true) }}
      value={value}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      {...props}
    >
      <Select.Option value={null}>{t('rental-request.components.sales-select.not-selected')}</Select.Option>
      {data?.response?.list?.map?.((contract) => (
        <Select.Option value={contract.id} key={contract.id}>
          {`Code: ${contract.sku.slice(0, 8)} Apt#: ${contract.apartment ? contract.apartment.code : 'not selected'}`}
        </Select.Option>
      ))}
    </Select>
  )
}

export { ContractSelect }
