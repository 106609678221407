/* eslint-disable */

import styled from 'styled-components';
import { UploadOutlined, DeleteOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { colors } from '_colors';

export const Container = styled.div`
  opacity: ${(p) => (p.dragOver ? '0.3' : '1')};
  transition: opacity .5s;
`;

export const PrevImg = styled(LeftCircleOutlined)`
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 36px;
  padding: 4px 4px 4px 0px;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  color: ${colors.main()};
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  transition: opacity 0.3s ease;
`;
export const NextImg = styled(RightCircleOutlined)`
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 36px;
  padding: 4px 0px 4px 4px;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  color: ${colors.main()};
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  transition: opacity 0.3s ease;
`;

export const MainPhotoContainer = styled.div`
  position: relative;
  margin-bottom: 8px;
  :hover {
    .current {
      .actionButtons {
        opacity: 1;
      }
      ${PrevImg} {
        opacity: 1;
      }
      ${NextImg} {
        opacity: 1;
      }
    }
  }
  .current {
    background: url(${(p) => p.current}) center center/contain no-repeat padding-box border-box
      ${(p) => (p.current ? '#ccc' : 'white')};
    width: 100%;
    height: ${(p) => p.height || 550}px;
    border-radius: ${p => p.notRounded ? '0px' : '8px'};

    ${(p) => !p.current
    && `
      border: dashed 1px ${colors.main()};
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        font-size: 38px;
        font-weight: 200;
        margin-bottom: 80px;
      }
    `}

    ${PrevImg} {
      opacity: 0;
    }
    ${NextImg} {
      opacity: 0;
    }

    .actionButtons {
      /* text-align: right; */
      display: ${(p) => (p.current ? 'flex' : 'none')};
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      background-color: rgba(255, 255, 255, 1);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  }
`;

export const ThumbnailsContainer = styled.div`
  /* width: 100%; */
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
`;

export const ThumbnailWrapper = styled.div`
  width: ${(p) => p.containerWidth / p.thumbCount}px;
  height: ${(p) => p.containerWidth / p.thumbCount}px;
  padding: 4px;
  display: inline-block;
`;

export const Thumbnail = styled.div`
  background: url(${(p) => p.url}) center center/cover no-repeat padding-box border-box #ccc;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: inset 0 0 0px ${(p) => (p.active ? '2px' : '0px')} ${colors.main()};
  /* border: solid 4px ${(p) => (p.active ? colors.main() : 'transparent')}; */
  filter: brightness(${(p) => (p.active ? '1' : '0.8')});
  transition: box-shadow .3s ease;
  cursor: ew-resize;
`;

export const UploadIcon = styled(UploadOutlined)`
  font-size: 24px;
  margin-left: 12px;
  cursor: pointer;
  color: ${colors.main()};
`;
export const DeleteIcon = styled(DeleteOutlined)`
  font-size: 24px;
  margin-left: 12px;
  cursor: pointer;
  color: ${colors.main()};
`;
