import { gql } from 'apollo-boost'

import { rentalContractFragment, userFragment, watchFragment } from '../_fragments'

export const RENTAL_CONTRACT_UPSERT_MUTATION = gql`
  mutation rentalContract($input: RentalContractInput!) {
    response: rentalContract(input: $input) {
      ...rentalContractFragment
    }
  }
  ${rentalContractFragment}
`

export const CONTRACT_HISTORY_MUTATION = gql`
   mutation contractHistory($input: ContractHistoryInput!) {
    response: contractHistory(input: $input) {
      id
      sku
      type
      context
      rentalContractId
      userId
      user {
        ...userFragment
      }
    }
  }
  ${userFragment}
`
export const CONTRACT_ACTION_MUTATION = gql`
   mutation contractAction($input: ContractActionInput!) {
    response: contractAction(input: $input) {
      id
      sku
      context
      rentalContractId
      userId
      user {
        ...userFragment
      }
    }
  }
  ${userFragment}
`


export const START_WATCHING_MUTATION = gql`
   mutation watch($input: WatchInput!) {
    response: watch(input: $input) {
      id
      sku
      userId
      entityId
    }
  }
`

