import { Col, Form, Input, InputNumber, Row, Switch } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const BuildingInfoEdit = () => {
  const { t } = useTranslation()
  return (
    <>
      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col xs={24}>
          <Form.Item name="buildingDescription" label={t('buildings.fields.buildingDescription')}>
            <Input.TextArea style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col xs={24}>
          <Form.Item name="areaDescription" label={t('buildings.fields.areaDescription')}>
            <Input.TextArea style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginBottom: '10px' }}>

        <Col xs={8}>
          <Form.Item name="floor" label={t('buildings.fields.floors')}>
            <InputNumber min="0" />
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item name="hasElevator" label={t('buildings.fields.hasElevator')} valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item name="hasRamp" label={t('buildings.fields.hasRamp')} valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

const BuildingInfoView = ({ data }) => {
  const { t } = useTranslation()

  return (
    <>
      <Row gutter={20}>
        <Col xs={24}>
          <h4>{t('buildings.fields.buildingDescription')}:</h4>
          <p>
            {data.buildingDescription || '-'}
          </p>
        </Col>
      </Row>
      <br />
      <Row gutter={20}>
        <Col xs={24}>
          <h4>{t('buildings.fields.areaDescription')}:</h4>
          <p>
           
            {data.areaDescription || '-'}
            
          </p>
        </Col>
      </Row>
      <br />
      <Row gutter={20}>
        <Col xs={8}>
          <h4>{t('buildings.fields.floors')}:</h4>
          <p>
            
            {data.floor || '-'}
            
          </p>
        </Col>
        <Col xs={8}>
          <h4>{t('buildings.fields.hasElevator')}:</h4>
          <p>
           
            {data.hasElevator ? 'Yes' : 'No'}
            
          </p>
        </Col>
        <Col xs={8}>
          <h4>{t('buildings.fields.hasRamp')}:</h4>
          <p>
           
            {data.hasRamp ? 'Yes' : 'No'}
            
          </p>
        </Col>
      </Row>

    </>
  )
}

export { BuildingInfoEdit, BuildingInfoView }
