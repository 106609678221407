import { gql, useQuery } from '@apollo/react-hooks'
import { AutoComplete } from 'antd'
import React from 'react'

export function CityPicker({ fullWidth = true, ...props}) {
  const { data, loading } = useQuery(CITIES)

  return (
    <AutoComplete
      disabled={loading}
      options={data?.response?.map?.(city => ({ value: city })) || []}
      filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
      style={ fullWidth ? { width: '100%' } : null }
      {...props}
    />
  )
}

export const CITIES = gql`
    query listCities {
      response: listCities
  }
`