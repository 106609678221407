import { gql } from 'apollo-boost'

export const roomFragment = gql`
  fragment roomFragment on Room {
    id
    title
    description
    status
    typeId
    apartmentId
    type {
      id
      title
      status
      inventories {
        id
        title
        status
        roomTypeId
      }
    }
  }
`
