import EmailOutlineIcon from '@2fd/ant-design-icons/lib/EmailOutline'
import { Link } from '@reach/router'
import { Button, Divider, Typography } from 'antd'
import { useProject } from 'common/ProjectProvider'
import Illustration from 'components/Illustration'
import AccentedText from 'components/Typography/AccentedText'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import styles from '../styles.less'

export function Registration() {
  const { t } = useTranslation()
  const project = useProject()

  const supportEmail = useMemo(() => `support@${project.domain}`, [project])

  return (
    <>
      {/* SEO */}
      <Helmet>
        <title>{t('auth.sign-up.title')}</title>
      </Helmet>
    
      {/* Page */}
      <Illustration name="undraw_working_remotely" width="1144" height="637" className={styles.illustration} />
      <Typography.Title><AccentedText text={t('auth.sign-up.title')} /></Typography.Title>
      <Typography.Paragraph>{t('auth.sign-up.welcome-message')}</Typography.Paragraph>

      {/* Contact via email */}
      <Button 
        href={`mailto:${supportEmail}?subject=${encodeURIComponent(t('auth.sign-up.title'))}`}
        icon={<EmailOutlineIcon />}
        type="dashed"
        block
      >
        {supportEmail}
      </Button>

      {/* Navigation */}
      <Divider />
      <Typography.Paragraph className={styles.navigation}>
        {t('auth.sign-up.navigation-message')} <Link to="/auth/login">{t('auth.sign-in.title')}</Link>
      </Typography.Paragraph>
    </>
  )
}
