import { gql } from 'apollo-boost'

import { userFragment } from '../_fragments'

export const USER_UPSERT_MUTATION = gql`
  mutation editUser($input: UserInput!) {
    response: editUser(input: $input) {
      user {
        ...userFragment
      }
    }
  }
  ${userFragment}
`

export const USER_DELETE_MUTATION = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id)
  }
`
