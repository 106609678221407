import { Link } from '@reach/router'
import { Typography } from 'antd'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import UserAvatar from '../Avatar'
import UserRole from '../Role'
// import { useProject } from '../common/ProjectProvider'
// import { motion } from 'framer-motion'
import styles from './styles.less'

function UserPreview(props) {
  const { user, type = 'panel', actions } = props

  // Panel view | Short panel view
  if (type === 'panel' || type === 'short-panel') {
    return (
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          initial="initial"
          animate="enter"
          exit="exit"
          variants={{
            initial: { x: -30, opacity: 0 },
            enter: { x: 0, opacity: 1, transition: { duration: 0.2, ease: [0.48, 0.15, 0.25, 0.96] } },
            exit: { x: 30, opacity: 0, transition: { duration: 0.3, ease: [0.48, 0.15, 0.25, 0.96] } },
          }}
          key={type}
          className={styles.panel}
          data-type={type}
        >
          {/* Avatar */}
          <UserAvatar user={user} size={42} className={styles.panelAvatar} />
          {/* User info */}
          {type === 'panel'
            ? (
              <div className={styles.panelContent}>
                <UserRole role={user.role} monochrome type="text" className={styles.panelRole} />
                <Link to='/my-details'>
                  <Typography.Text className={styles.panelName}>{user.firstName} {user.lastName}</Typography.Text>
                </Link>
              </div>
            )
            : null}
          {/* Actions */}
          <div className={styles.panelActions}>
            {actions ? <div>{actions}</div> : null}
          </div>
        </motion.div>
      </AnimatePresence>
    )
  }

  // Short panel view
  if (type === 'short-panel') {
    return (
      <div className={styles.panel}>
        {/* Avatar */}
        <UserAvatar user={user} size={42} className={styles.panelAvatar} />
      </div>
    )
  }
}

export default UserPreview
