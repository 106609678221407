import './style.css'

import { Col, Row, Timeline } from 'antd'
import {
  LayoutCard,
} from 'components/Layout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

const Versions = () => {

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <LayoutCard>
            <br />
            <Timeline>
              <Timeline.Item color="green">
                <div className="changelog">
                  <h2>0.9.8</h2>
                  <code>2022/07/15</code>
                  <ul>
                    <li>⚡️ Reportings Manager</li>
                    <li>⚡️ New History Generation scripts</li>
                    <li>🔥 Upgrades to Contracts Validation script</li>
                    <li>🔥 Upgrades for User Validatoon script </li>
                    <li>🐞 Minor bug fixes (accounting)</li>
                  </ul>
                </div>
              </Timeline.Item>
              <Timeline.Item color="green">
                <div className="changelog">
                  <h2>0.9.7</h2>
                  <code>2022/07/08</code>
                  <ul>
                    <li>🔥 Return of push notifications</li>
                    <li>🔥 Return of notifications</li>
                    <li>⚡️ Subscritions (follow contract to get updates)</li>
                    <li>⚡️ New emails notifications</li>
                    <li>🔥 Automation upgrades</li>
                    <li>🔥 Logs upgrades</li>
                    <li>🐞 Minor bug fixes (accounting)</li>
                  </ul>
                </div>
              </Timeline.Item>
              <Timeline.Item color="green">
                <div className="changelog">
                  <h2>0.9.6</h2>
                  <code>2022/07/08</code>
                  <ul>
                    <li>🔥 Profile comission</li>
                    <li>🔥 Permissons for users updated</li>
                    <li>⚡️ More XLS reports</li>
                    <li>⚡️ Automation for data-relations testing</li>
                    <li>⚡️ Automation for history testing</li>
                    <li>🐞 Minor bug fixes (bill run logical issue, user - wbs)</li>
                  </ul>
                </div>
              </Timeline.Item>
              <Timeline.Item color="green">
                <div className="changelog">
                  <h2>0.9.6</h2>
                  <code>2022/06/28</code>
                  <ul>
                    <li>🔥 Contract creation form upgrade</li>
                    <li>🔥 Contract search and filter fix</li>
                    <li>⚡️ Default project & project key</li>
                    <li>⚡️ Extended reports XLS</li>
                    <li>⚡️ Landing page callback form support</li>
                    <li>🐞 Contract form property validation</li>
                    <li>🐞 Minor bug fixes (fields validation / update)</li>
                  </ul>
                </div>
              </Timeline.Item>
              <Timeline.Item color="green">
                <div className="changelog">
                  <h2>0.9.5</h2>
                  <code>2022/06/17</code>
                  <ul>
                    <li>⚡️ Charts in Projects</li>
                    <li>⚡️ Project extension & page facelift</li>
                    <li>⚡️ Project - WBS relation upgraded</li>
                    <li>⚡️ Basic reports to XLS</li>
                    <li>⚡️ Transaction handover</li>
                    <li>🐞 Transaction dates fix </li>
                    <li>🐞 Minor bug fixes (fields validation / update)</li>
                  </ul>
                </div>
              </Timeline.Item>
              <Timeline.Item color="green">
                <div className="changelog">
                  <h2>0.9.4</h2>
                  <code>2022/06/10</code>
                  <ul>
                    <li>⚡️ Charts extended</li>
                    <li>⚡️ Accounting module extended</li>
                    <li>⚡️ Contract Page upgraded for accounting</li>
                    <li>⚡️ Project Page upgraded for accounting</li>
                    <li>🐞 Minor bug fixes (Relation issues, Profile fields, Translations)</li>
                  </ul>
                </div>
              </Timeline.Item>
              <Timeline.Item color="green">
                <div className="changelog">
                  <h2>0.9.3</h2>
                  <code>2022/05/23</code>
                  <ul>
                    <li>⚡️ Pie and bar Charts</li>
                    <li>⚡️ Extended Recurring Payments accounting</li>
                    <li>⚡️ Extended Bill Run accounting</li>
                    <li>🐞 Prices bug fixes (Invoice + servicePrice)</li>
                  </ul>
                </div>
              </Timeline.Item>
              <Timeline.Item color="green">
                <div className="changelog">
                  <h2>0.9.3</h2>
                  <code>2022/04/20</code>
                  <ul>
                    <li>⚡️ Recurring Payments</li>
                    <li>⚡️ Bill Runs</li>
                    <li>⚡️ Contract extended with Services</li>
                    <li>🌐 Internationalization</li>
                  </ul>
                </div>
              </Timeline.Item>
              <Timeline.Item color="green">
                <div className="changelog">
                  <h2>0.9.2</h2>
                  <code>2021/11/25</code>
                  <ul>
                    <li>⚡️ Optimized Page layout for Tenant & Landlord Groups</li>
                    <li>⚡️ Agreements structure extended</li>
                    <li>🌐 Internationalization</li>
                  </ul>
                </div>
              </Timeline.Item>
              <Timeline.Item color="green">
                <div className="changelog">
                  <h2>0.9.1</h2>
                  <code>2021/11/24</code>
                  <ul>
                    <li>🔥 Contract Tenants Optimized</li>
                    <li>⚡️ Apartment information</li>
                    <li>🐞 Fix Transaction Pagination (pages) </li>
                    <li>🌐 Internationalization</li>
                  </ul>
                </div>
              </Timeline.Item>
              <Timeline.Item color="green">
                <div className="changelog">
                  <h2>0.9.1</h2>
                  <code>2021/11/18</code>
                  <ul>
                    <li>🔥 Service Request optimized</li>
                    <li>⚡️ New chat functions</li>
                    <li>⚡️ Address and additions</li>
                    <li>🐞 Creation bugs fixed </li>
                    <li>🌐 Internationalization</li>
                  </ul>
                </div>
              </Timeline.Item>
            </Timeline>

          </LayoutCard>
        </Col>
      </Row>

    </>
  )
}

export { Versions }
