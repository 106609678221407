import { USERS_LIST, WBS_LIST } from '_graphql'
import { isAdmin, isTenant, roles, USER_ROLES } from '_graphql/_enums'
import {
  PROJECT_SKU_QUERY,
  PROJECT_UPSERT_MUTATION,
  SUBSCRIBE_PROJECT,
} from '_graphql/project'
import Attachment from '@2fd/ant-design-icons/lib/Attachment'
import Cog from '@2fd/ant-design-icons/lib/Cog'
import SlashForward from '@2fd/ant-design-icons/lib/SlashForward'
import TransitTransfer from '@2fd/ant-design-icons/lib/TransitTransfer'
import Wallet from '@2fd/ant-design-icons/lib/Wallet'
import { BankOutlined, EditOutlined, FileExcelOutlined, InfoCircleOutlined, PlusOutlined, UserAddOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/react-hooks'
import { BlurCircularOutlined, ReportOffOutlined, ReportOutlined } from '@material-ui/icons'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveChoropleth } from '@nivo/geo'
import { ResponsivePie } from '@nivo/pie'
import { Link, useParams } from '@reach/router'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Tabs,
  Tooltip,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useModals } from 'common/ModalsProvider'
import { NotFound } from 'components'
import { ContactBox } from 'components/ContactBox'
import { LayoutCard } from 'components/Layout'
import LayoutTabs from 'components/LayoutTabs'
import { cost } from 'components/ListManager/DefaultTypes'
import { PageHeader } from 'components/PageHeader'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CounterCard } from 'screens/Dashboard/DashboardTab'
import { InvoicesManager } from 'screens/InvoiceManager'
import { LogsManager } from 'screens/LogsManager'
import { RentalContracts } from 'screens/RentalContracts'
import { ReportsManager } from 'screens/ReportsManager'
import { ServiceRequests } from 'screens/ServiceRequests'
import { TransactionsManager } from 'screens/TransactionsManager'
import { UsersManager } from 'screens/UsersManager'
import { UserFormCreate } from 'screens/UsersManager/UserFormCreate'
import { WbsManager } from 'screens/WbsManager'
import { WbsForm } from 'screens/WbsManager/WbsForm'
import { useGlobalState } from 'state'
import stc from 'string-to-color'
import styles from 'styles/side-layout.less'

import { AttachUser } from './AttachUser'
import FeatureCollection from './features.json'

const keys = [
  'title',
  'ownerId',
  'managerId',
  'description',
  'startedAt',
  'expireAt',
  'city',
  'street',
  'zipCode',
  'defaultBudget',
  'pmoId',
]

const { TabPane } = Tabs

const ProjectPage = (options) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)
  const { openModal } = useModals()
  const [projectForm] = Form.useForm()

  const [formOpened, setformOpened] = useState(false)
  const [detailsVisible, setdDetailsVisible] = useState(false)
  const [usersRefetch, setUsersRefetch] = useState()

  let sku
  if (options?.sku) {
    sku = options.sku
  } else {
    sku = useParams().sku
  }

  const backUrl = `/projects-management/${sku}`

  const [fetchWbs, { data: wbsData, loading: wbsLoading }] = useLazyQuery(
    WBS_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
      },
    }
  )

  const { data: customerData, loading: customerLoading } = useQuery(
    USERS_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
        filter: JSON.stringify({
          role: [USER_ROLES['tentant-admin'].key, USER_ROLES['tentant-manager'].key, USER_ROLES['tentant-project-manager'].key],
        }),
      },
    }
  )

  const [updateProjectMutation] = useMutation(PROJECT_UPSERT_MUTATION)

  const { data: projectRaw, loading: projectLoadingRaw } = useQuery(
    PROJECT_SKU_QUERY,
    {
      variables: { sku },
      fetchPolicy: 'no-cache',
    }
  )

  const [projectData, setProjectData] = useState(undefined)
  const [projectLoading, setProjectLoading] = useState(true)

  useEffect(() => {
    if (!projectLoadingRaw) {
      setProjectData(projectRaw)
      setProjectLoading(false)
    }
  }, [projectLoadingRaw])

  const data = projectData?.response
  const project = data

  const ownerId = projectForm.getFieldValue('ownerId') 
  const managerId = projectForm.getFieldValue('managerId')
  const role = state.currentUser?.role

  useEffect(() => {
    if (project) {
      projectForm.setFieldsValue({
        ...project,
        startedAt: moment(project.startedAt),
        expireAt: moment(project.expireAt) 
      })

      fetchWbs({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            projectIds: project.id,
          }),
        },
      })
    }
  }, [project, formOpened])

  const saveProjectChanges = async () => {
    const values = projectForm.getFieldsValue()

    const variables = {
      input: {
        id: project.id,
        ...values,
      },
    }

    try {
      await updateProjectMutation({ variables })
      setformOpened(false)
      message.success(t('projects.form.updateSuccess'))
    } catch {
      message.success(t('projects.form.updateError'))
    }
  }

  const { data: projectDataSub } = useSubscription(SUBSCRIBE_PROJECT, {
    fetchPolicy: 'no-cache',
    variables: { id: project?.id },
    onError: (err) => console.error(err),
  })

  // Subscribe
  useEffect(() => {
    if (projectDataSub) {
      setProjectData(projectDataSub)
      projectForm.setFieldsValue({
        ...projectDataSub.response,
      })
    }
  }, [projectDataSub])

  if (!projectData?.response && !projectLoading) {
    return <NotFound />
  }
  if (!data || (!data && !projectLoading)) {
    return t('contract.messages.loading-or-error')
  }

  // const { _sales, tenant, landlord } = data || {}
  const submitform = async () => {
    const form = projectForm.getFieldsValue(keys)

    const variables = {
      input: {
        id: data.id,
        ...form,
      },
    }

    await updateProjectMutation({ variables })
    setformOpened(false)
  }

  const colors = { 'rent': 'rgb(29, 195, 131)', 'utilities': 'hsl(97, 70%, 50%)', 'services': 'green' }
  const getColor = bar => colors[bar.id]


  return (
    <>
      <Form
        layout="vertical"
        form={projectForm}
        // size='small'
        // initialValues={{ period: { unlimited: true }, tenant: {} }}
        // onFieldsChange={setHasChanges}
        onFinish={saveProjectChanges}
      >
        <div className={styles.container} id="projectPage">
          {/* Form */}
       

          <div className={styles.content}>
            <PageHeader
              title={t('projects.page.title')}
              id={data.sku}
              dropdown={ [isAdmin(role) || isTenant(role)  ? (
                <React.Fragment key={999}>
                  <Tooltip title="Show Details">
                    <Button
                      onClick={() => {
                        setdDetailsVisible(!detailsVisible)
                      }}
                    >
                      <InfoCircleOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Edit Project">
                    <Button
                      onClick={() => {
                        setdDetailsVisible(true)
                        setformOpened(!formOpened)
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Reports">
                    <Button
                      onClick={() => {
                        setdDetailsVisible(true)
                        setformOpened(!formOpened)
                      }}
                    >
                      <FileExcelOutlined />
                    </Button>
                  </Tooltip>
                </React.Fragment>
              
              
              ) : null]}
            />
         
            <Row gutter={[20,20]}>
              <Col xs={24}>
              
                { detailsVisible ? (
                  <LayoutCard>
                
                    {/* Edit */}
                    {formOpened ? (
                      <>
                        <Row gutter={[20, 20]}>
                          <Col xs={12}>
                            <Form.Item
                              name="title"
                              label={t('projects.form.title')}
                              required
                              rules={[{ required: true }]}
                            >
                              <Input placeholder={t('projects.form.titleHolder')} />
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              name="defaultBudget"
                              label={t('projects.form.defaultBudget')}
                              required
                              rules={[{ required: true }]}
                            >
                              <InputNumber style={{ width: '100%'}}/>
                            </Form.Item>
                          </Col>
                          <Col xs={8}>
                            <Form.Item
                              name="city"
                              label={t('projects.form.city')}
                              required
                              rules={[{ required: true }]}
                            >
                              <Input placeholder={t('projects.form.cityHolder')} />
                            </Form.Item>
                          </Col>
                          <Col xs={8}>
                            <Form.Item
                              name="zipCode"
                              label={t('projects.form.zipCode')}
                              required
                              rules={[{ required: true }]}
                            >
                              <Input placeholder={t('projects.form.zipCodeHolder')} />
                            </Form.Item>
                
                          </Col>
           
                          <Col xs={8}>
                            <Form.Item
                              name="street"
                              label={t('projects.form.street')}
                              required
                              rules={[{ required: true }]}
                            >
                              <Input placeholder={t('projects.form.streetHolder')} />
                            </Form.Item>
                          </Col>
           
             
                          <Col xs={8}>
                            <Form.Item name="ownerId" label="Select Owner">
                              <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={t('globalForms.select')}
                                disabled={customerLoading}
                              >
                                {customerData?.response?.list?.map?.((user) => (
                                  user.role === 'tentant-admin' ? 
                                    <Select.Option value={user.id} key={user.id}>
                                      {user.lastName} {user.firstName}
                                    </Select.Option>
                                    : null
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xs={8}>
                            <Form.Item name="managerId" label="Select Manager">
                              <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={t('globalForms.select')}
                                disabled={customerLoading}
                              >
                                {customerData?.response?.list?.map?.((user) => (
                                  user.role === 'tentant-manager' && user.ownerId === ownerId ? 
                                    <Select.Option value={user.id} key={user.id}>
                                      {user.lastName} {user.firstName}
                                    </Select.Option>
                                    : null
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={8}>
                            <Form.Item name="pmoId" label="Select PMO">
                              <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={t('globalForms.select')}
                                disabled={customerLoading}
                              >
                                {customerData?.response?.list?.map?.((user) => (
                                  user.role === 'tentant-project-manager' && user.ownerId === ownerId ? 
                                    <Select.Option value={user.id} key={user.id}>
                                      {user.lastName} {user.firstName}
                                    </Select.Option>
                                    : null
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                      
                          <Col xs={12}>
                            <Form.Item
                              name="startedAt"
                              label={t('projects.form.startedAt')}
                              required
                              rules={[{ required: true }]}
                            >
                              <DatePicker/>
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              name="expireAt"
                              label={t('projects.form.expireAt')}
                              required
                              rules={[{ required: true }]}
                            >
                              <DatePicker />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[20, 20]}>
                          <Col xs={24}>
                            <Form.Item
                              name="description"
                              label={t('projects.form.description')}
                              required
                              rules={[{ required: true }]}
                            >
                              <TextArea
                                placeholder={t('projects.form.descriptionHolder')}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Divider />

                        {/* Save contract */}
                        <Button block size="large" type="primary" htmlType="submit">
                          {t('projects.form.update')}
                        </Button>
            
                      </>
                    ) : null}

                 

                    {/* View */}
                    {!formOpened ? (
                      <>
                        {/* Contract range */}
                        <Row gutter={[20,20]}>
                          <Col xs={24}>
                            <Descriptions title="" size="small" layout="horizontal" column={2} bordered>
                              <Descriptions.Item label={t('projects.page.projectName')}>{ project?.title ? project?.title : '-'}</Descriptions.Item>
                              <Descriptions.Item label={t('projects.page.range')}>{moment(project.startedAt).format('YYYY-MM-DD')} : {moment(project.expireAt).format('YYYY-MM-DD')}</Descriptions.Item>
                              <Descriptions.Item label={t('projects.page.city')}>{ project?.city ? project?.city : '-'}</Descriptions.Item>
                              <Descriptions.Item label={t('projects.page.street')}>{ project?.street ? project?.street : '-'}</Descriptions.Item>
                              <Descriptions.Item label={t('projects.page.zipCode')}>{ project?.zipCode ? project?.zipCode : '-'}</Descriptions.Item>
                              <Descriptions.Item label={t('projects.page.defaultBudget')}>{ project?.defaultBudget ? cost({ value : project?.defaultBudget}) : '-'}</Descriptions.Item>
                            </Descriptions>
                          </Col>
                        </Row>
                        <Divider />
                        <Row gutter={[20,20]}>
                          {/* Tenant | Project owner */}
                          <Col xs={8}>
                            {(isAdmin(role) || isTenant(role)) && project?.owner ? (
                              <ContactBox user={project?.owner} />
                            ) : null}
                          </Col>

                          {/* Tenant | Projects manager */}
                          <Col xs={8}>
                            {
                              (isAdmin(role) || isTenant(role)) &&project?.manager  ? 
                                (
                                  <ContactBox user={project?.manager} />
                                ) : null
                            }
                          </Col>
                          {/* Tenant | Single project manager */}
                          <Col xs={8}>
                            {
                              (isAdmin(role) || isTenant(role)) && project?.pmo ? (
                                <ContactBox user={project?.pmo} />
                              ) : null
                            }
                          </Col>
                        </Row>
                      </>
                    ) : null}
                
                  </LayoutCard>)
                  : null } 
              </Col>
            </Row>
         

            <Row gutter={[20, 20]}>
              <Col xs={24}>
                <LayoutTabs type="card" defaultActiveKey="general">
                  <TabPane tab={t('projects.page.tabs.general')} key="general">
                    <Row gutter={[20, 20]}>
                      <Col xs={8}>
                        <Row gutter={[20, 20]} >
                          <Col xs={24}>
                            <CounterCard
                              color={stc('transfers')}
                              Icon={TransitTransfer}
                              count={
                                <>
                                  {project?.stats?.[0].count  || 0}
                                  <SlashForward />
                                  {project?.stats?.[1].count   || 0}
                          
                                </>
                              }
                              countTitle={
                                <>
                                  {t('projects.page.counters.empType.busy')}
                                  <SlashForward />
                                  {t('projects.page.counters.empType.free')}
                         
                                </>
                              }
                              forceTitle
                              title={t('projects.page.counters.employees')}
                              link="#transactions"
                            /></Col>
                          <Col xs={24}>
                            <CounterCard
                              color={stc('invoices-')}
                              Icon={Wallet}
                              Divider="line"
                              count={project?.stats?.[3].count || 0}
                              secondCount={project?.stats?.[2].count  || 0}
                              secondCountTitle={'Total'}
                              title={t('projects.page.counters.contracts')}
                              link="#contracts"
                            />
                          </Col>
                          <Col xs={24}>
                            <CounterCard
                              color={stc('service')}
                              Icon={Cog}
                              Divider="line"
                              count={
                                <>
                                  {project?.stats?.[4].count || 0}
                                  <SlashForward />
                                  {project?.stats?.[5].count  || 0}
                                  <SlashForward />
                                  {project?.stats?.[6].count || 0}
                                </>
                              }
                              countTitle={
                                <>
                                  {t('serviceRequests.types.provision')}
                                  <SlashForward />
                                  {t('serviceRequests.types.damage')}
                                  <SlashForward />
                                  {t('serviceRequests.types.service')}
                                </>
                              }
                              forceTitle
                              title={t('contract.page.counters.services')}
                              link="#service-requests"
                            />
                          </Col>
                          <Col xs={24}>
                            <CounterCard
                              color={stc('invoices')}
                              Icon={BankOutlined}
                              Divider="line"
                              count={
                                <>
                                  {project?.stats?.[7].count || 0}
                                  <SlashForward />
                                  {project?.stats?.[8].count || 0}
                                </>
                              }
                              countTitle={
                                <>
                                  {t('projects.page.counters.invoiceStatus.paid')}
                                  <SlashForward />
                                  {t('projects.page.counters.invoiceStatus.unpaid')}
                                </>
                              }
                              forceTitle
                              title={t('projects.page.counters.invoices')}
                              link="#invoices"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={16}>
                       
                        <LayoutCard style={{ width: '100%', height: '445px'}}>
                          <ResponsivePie
                            data={[
                              {
                                'id': 'rent',
                                'label': 'haskell',
                                'value': project?.stats?.[9].count || 0,
                                'color': 'rgb(29, 195, 131)',
                              },
                              {
                                'id': 'utilities',
                                'label': 'erlang',
                                'value': project?.stats?.[10].count || 0,
                                'color': 'hsl(157, 74.1%, 43.9%)'
                              },
                              {
                                'id': 'services',
                                'label': 'php',
                                'value': project?.stats?.[11].count || 0,
                                'color': 'hsl(157, 74.1%, 43.9%)'
                              },
                            ]}
                            margin={{ top:30, right: 30, bottom: 30, left: 30 }}
                            valueFormat={value => cost({value})}
                            innerRadius={0.5}
                            padAngle={0.6}
                            cornerRadius={5}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                              from: 'color',
                              modifiers: [
                                [
                                  'darker',
                                  0.2
                                ]
                              ]
                            }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                              from: 'color',
                              modifiers: [
                                [
                                  'darker',
                                  2
                                ]
                              ]
                            }}
                            defs={[
                              {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                size: 4,
                                padding: 1,
                                stagger: true
                              },
                              {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                              }
                            ]}
                            fill={[
                              // {
                              //   match: {
                              //     id: 'Rent'
                              //   },
                              //   id: 'lines'
                              // },
                              // {
                              //   match: {
                              //     id: 'Utilities'
                              //   },
                              //   id: 'empty'
                              // },
                              // {
                              //   match: {
                              //     id: 'ServiceFee'
                              //   },
                              //   id: 'dots'
                              // },
             
                            ]}
                            legends={[
                              {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                  {
                                    on: 'hover',
                                    style: {
                                      itemTextColor: '#000'
                                    }
                                  }
                                ]
                              }
                            ]}
                          />
                        </LayoutCard>
                      </Col>
                    </Row>

                    <Row gutter={[20,20]}>
                      <Col xs={24}>
                        <LayoutCard style={{ width: '100%', height: '450px' }}>
                          <ResponsiveBar
                            data={project?.stats?.[12].array}
                            keys={[
                              'rent',
                              'utilities',
                              'services',
                            ]}
                            indexBy="date"
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            colors={getColor}
                            valueFormat={value => cost({value})}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            defs={[
                              {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                              },
                              {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                              }
                            ]}
                            fill={[
                              {
                                match: {
                                  id: 'utilities'
                                },
                                id: 'empty'
                              },
                              {
                                match: {
                                  id: 'services'
                                },
                                id: 'lines'
                              }
                            ]}
                            borderColor={{
                              from: 'color',
                              modifiers: [
                                [
                                  'darker',
                                  1.6
                                ]
                              ]
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: -30,
                              legendOffset: 60
                            }}
                            enableGridX={true}
                            axisLeft={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: 'Budget',
                              legendPosition: 'middle',
                              legendOffset: -60
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                              from: 'color',
                              modifiers: [
                                [
                                  'darker',
                                  1.6
                                ]
                              ]
                            }}
                            legends={[
                              {
                                dataFrom: 'keys',
                                anchor: 'top-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                  {
                                    on: 'hover',
                                    style: {
                                      itemOpacity: 1
                                    }
                                  }
                                ]
                              }
                            ]}
                            role="application"
                            ariaLabel="Nivo bar chart demo"
                            barAriaLabel={function(e){return e.id+': '+e.formattedValue+' in country: '+e.indexValue}}
                          />
                        </LayoutCard>
                      </Col>
                    </Row>

                    {/* <Row gutter={[20, 20]} style={{ margin: '0px'}}>
                      <Col xs={24}>
                        <LayoutCard style={{ width: '100%', height: '450px' }}>
                          <ResponsiveChoropleth
                            data={[
                              {
                                'id': 'DEU',
                                'value': 679580
                              },

                              {
                                'id': 'UKR',
                                'value': 5555
                              },
                            ]}
                            features={FeatureCollection.features}
                            
                            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                            colors="nivo"
                            domain={[ 0, 1000000 ]}
                            unknownColor="#f2f2f2"
                            label="properties.name"
                            valueFormat=".2s"
                            // projectionType="azimuthalEquidistant"
                            projectionType='equalEarth'
                            projectionTranslation={[ 0.50, 1 ]}
                            projectionScale={300}
                            projectionRotation={[ 0, 0, 0 ]}
                            enableGraticule={true}
                            graticuleLineColor="#dddddd"
                            borderWidth={0.5}
                            borderColor="#152538"
                            legends={[
                              {
                                anchor: 'bottom-left',
                                direction: 'column',
                                justify: true,
                                translateX: 20,
                                translateY: -100,
                                itemsSpacing: 0,
                                itemWidth: 94,
                                itemHeight: 18,
                                itemDirection: 'left-to-right',
                                itemTextColor: '#444444',
                                itemOpacity: 0.85,
                                symbolSize: 18,
                                effects: [
                                  {
                                    on: 'hover',
                                    style: {
                                      itemTextColor: '#000000',
                                      itemOpacity: 1
                                    }
                                  }
                                ]
                              }
                            ]}
                          />
                        </LayoutCard>
                      </Col>
                    </Row>
                 */}
                  </TabPane>
                  <TabPane tab={t('projects.page.tabs.employees')} key="users">
                    {data ? (
                      <UsersManager
                        queryParams={{ filter: { projectIds: [data.id] } }}
                        refetch={usersRefetch}
                        fullpage={false}
                        actions={[
                          <Button
                            key="attach"
                            type="primary"
                            icon={<Attachment />}
                            onClick={() => {
                              openModal(AttachUser, {
                                project: data,
                                onClose() {
                                  setUsersRefetch(moment())
                                }
                              })
                            }}
                          >
                            {t('user.actions.attach')}
                          </Button>,
                        
                          <Button
                            key="addUser"
                            type="primary"
                            icon={<UserAddOutlined />}
                            htmlType="span"
                            onClick={() => {
                              openModal(UserFormCreate, {
                                projectId: data.id,
                                ownerId: data.ownerId,
                                role: [roles.TENANT_EMPLOYEE, roles.TENANT_PROJECT_MANAGER, roles.TENANT_ADMIN, roles.TENANT_MANAGER],
                                backUrl: encodeURIComponent(`/projects-management/${data.sku}#users`),
                                onClose() {
                                  setUsersRefetch(moment())
                                }
                              })
                            }}
                          >
                            {t('user.actions.create-link')}
                          </Button>
                          ,
                        ]}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tab={t('projects.page.tabs.projectKeys')} key="keys">
                    {data ? (
                      <WbsManager
                        queryParams={{ filter: { projectIds: [project.id] } }}
                        fullpage={false}
                        actions={[
                          <Link 
                            to="/wbs-management/create" 
                            key="create"
                            onClick={(e) => {
                              e.preventDefault()
                              openModal(WbsForm, {
                                forceQuery: {
                                  projectId: project.id,
                                  backUrl: backUrl + '#keys',
                                },
                             
                              })
                            
                            }}
                          >
                            <Button
                              type="primary"
                              icon={<PlusOutlined />}
                              htmlType="span"
                            >
                              {t('projects.table.actions.createWbs')}
                            </Button>
                          </Link>,
                        ]}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tab={t('projects.page.tabs.contracts')} key="contracts">
                    {data ? (
                      <RentalContracts
                        queryParams={{ filter: { projectIds: [project.id] } }}
                        fullpage={false}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tab={t('projects.page.tabs.invoices')} key="invoices">
                    {data ? (
                      <InvoicesManager
                        queryParams={{ filter: { projectIds: [project.id] } }}
                        fullpage={false}
                        actions={[]}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tab={t('projects.page.tabs.transactions')} key="transactions" >
                    {data ? (
                      <TransactionsManager
                        queryParams={{ filter: { projectIds: [project.id] } }}
                        fullpage={true}
                        actions={[]}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tab={t('projects.page.tabs.requests')} key="requests">
                    {data ? (
                      <ServiceRequests
                        queryParams={{ filter: { projectIds: [project.id] } }}
                        fullpage={false}
                        actions={[
                          <Link key={data.id}
                            to={`/service-requests/create?projectId=${
                            data.id
                          }&backUrl=${encodeURIComponent(
                            `/projects-management/${data.sku}#requests`
                          )}`}
                          >
                            <Button
                              type="primary"
                              icon={<PlusOutlined />}
                              htmlType="span"
                            >
                              {t('serviceRequests.actions.createRequest')}
                            </Button>
                          </Link>,
                        ]}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tab={t('projects.page.tabs.log')} key="log">
                    <LogsManager
                      queryParams={{ filter: { referenceIds:  [project.id] } }}
                      actions={[]}
                    />
                  </TabPane>

                  <TabPane tab={t('projects.page.tabs.reports')} key="reports" disabled='disabled'>
                    <ReportsManager
                      queryParams={{ filter: { projectIds: [project.id] } }}
                      fullpage={true}
                      actions={[]}
                    />
                  </TabPane>
                </LayoutTabs>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </>
  )
}

export { ProjectPage }
