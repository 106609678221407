import { gql } from 'apollo-boost'

import { entryFragment } from '../_fragments'

export const ENTRIES_LIST = gql`
  query listEntries(
    $page: Int!
    $pageSize: Int!
    $sorting: String
    $search: String
    $filter: JSON
  ) {
    response: listEntries(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      search: $search
      filter: $filter
    ) {
      list {
        ...entryFragment
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${entryFragment}
`