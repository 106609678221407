import {
  isAdmin,
  isTenant,
  USER_ROLES,
} from '_graphql/_enums'
import { PROJECT_LIST } from '_graphql/project'
import { ALL_RENTAL_CONTRACTS_QUERY } from '_graphql/rentalContract'
import {
  TRANSACTION_SKU_QUERY,
  TRANSACTION_UPSERT_MUTATION,
  TRANSACTIONS_LIST,
} from '_graphql/transaction'
import { USERS_LIST } from '_graphql/user/queries'
import { useLazyQuery,useMutation, useQuery } from '@apollo/react-hooks'
import { navigate, useLocation, useParams } from '@reach/router'
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  message,
  Row,
  Select,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { NotFound } from 'components'
import { ContractSelect } from 'components/Form/ContractSelect'
import { DateRange } from 'components/Form/DateRange'
import { ProjectSelect } from 'components/Form/ProjectSelect'
import { UserSelect } from 'components/Form/UserSelect'
import {
  LayoutCard,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { ContextMenuTrigger } from './styled'

const keys = [
  'type',
  'userIn',
  'userOut',
  'contractId',
  'creatorId',
  'projectId',
  'dates'
]

const TransactionForm = ({ onClose, isModal, forceQuery }) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const params = isModal ? {} : useParams()
  const [, rerender] = useState()
  const [, setHasChanges] = useState()

  const sku = forceQuery.get('sku')
  const [transactionData, setTransactionData] = useState(undefined)
  const [transactionLoading, setTransactionLoading] = useState(true)

  if (sku) {
    const { data: transactionRaw, loading: transactionLoadingRaw } = useQuery(
      TRANSACTION_SKU_QUERY,
      {
        variables: { sku },
        fetchPolicy: 'no-cache',
      }
    )


    console.log('tdraw', transactionRaw)

  
    useEffect(() => {
      if (!transactionLoadingRaw) {
        setTransactionData(transactionRaw)
        setTransactionLoading(false)
      }
    }, [transactionLoadingRaw, transactionRaw ])

   
  }

  console.log('td', transactionData)

  useEffect(() => {
    if (transactionData?.response) {
      form.setFieldsValue({
        ...transactionData?.response,

      })

    }
  }, [transactionData])

  const [
    fetchTransactionData,
    { data: transactionsData, loading: transactionsLoading },
  ] = useLazyQuery(TRANSACTIONS_LIST, { variables: { id: +params.id } })

  const [fetchTenants, { data: usersData, loading: usersLoading }] = useLazyQuery(USERS_LIST,{
    variables: {
      page: 1,
      pageSize: 1000,
    },
  })
  const { data: rentalContracts, loading: rentalContractsLoading } = useQuery(
    ALL_RENTAL_CONTRACTS_QUERY
  )

  const [upsertTransaction] = useMutation(TRANSACTION_UPSERT_MUTATION)

  const [form] = Form.useForm()
  const values = form.getFieldsValue()

  const location = useLocation()
  const query = useMemo(
    () => forceQuery ? forceQuery : new URLSearchParams(location.search),
    [location.search, forceQuery]
  )

  const type = form.getFieldValue('type') ?? query.has('type')

  const contract = useMemo(
    () =>
      rentalContracts?.response?.find?.(
        (contract) => contract.id === +query.get('contractId')
      ) ?? [],
    [query.get('contractId'), rentalContracts]
  )

  const projectId = form.getFieldValue('projectId') ?? query.has('projectId')

  useEffect(() => {
    if (query.has('contractId') && contract && query.has('projectId')) {
      form.setFieldsValue({
        contractId: parseInt(query.get('contractId')),
      })
      form.setFieldsValue({
        projectId: parseInt(query.get('projectId')),
      })
    }
  }, [location.search, contract])

  useEffect(() => {
    if(projectId) {
      const filter = { projectIds: [projectId]}
      const baseVariables = {
        page: 1,
        pageSize: 1000,
        filter: JSON.stringify(filter),
      }


      fetchTenants({
        variables: {
          ...baseVariables,
          filter: JSON.stringify({
            ...filter,
            role: [USER_ROLES['tentant-employee'].key],
          }),
        },  
      })
    }
  }, [projectId, USER_ROLES, JSON])

  useEffect(() => {
    if (+params.id > 0) {
      alert()
      fetchTransactionData()
    }
  }, [params])

  const goBack = (id) => {
    if (onClose) {
      onClose(id)
    } else if (query.has('backUrl')) {
      navigate(query.get('backUrl'))
    } else if (isTenant(state.currentUser?.role)) {
      navigate('/dashboard')
    } else {
      navigate('/transactions-management')
    }
  }

  const saveTransaction = async () => {
    const values = form.getFieldsValue(keys.filter(key => key !== 'projectId'))

    console.log(values)

    if (!isAdmin(state.currentUser?.role)) {
      values.userId = state.currentUser?.id
    }

    const moveInDate = values.dates
    const moveOutDate = values.dates
    delete values.dates

    if (transactionsData?.response?.id) {
      const d = await upsertTransaction({
        variables: {
          input: {
            id: transactionsData.response.id || null,
            ...values,
            userIn: values.userId,
          },
        },
      })

      if (d?.data?.response) {
        message.success('saved')
        goBack(d?.data?.response?.id)
      } else {
        message.error('error')
      }
    } else {
      const d = await upsertTransaction({
        variables: {
          input: {
            ...values,
            type: parseInt(values.type),
            status: isAdmin(state.currentUser?.role) ? 'waiting' : 'waiting',
            moveInDate: moveInDate,
            moveOutDate: moveOutDate,
          },
        },
      })

      if (d.data?.response) {
        message.success('created')
        goBack(d.data?.response?.id)
      } else {
        message.error('creation error')
      }
    }
  }

  return (
    <Form 
      layout="vertical" 
      form={form} 
      onFinish={saveTransaction}
      onFieldsChange={setHasChanges}
    >
      <LayoutOverlay>
        <Row gutter={[20, 20]}>
          <Col xs={24} style={{ textAlign: 'left' }}>
            <h2 style={{ fontSize: '24px' }}>{t('transactions.form.new')}</h2>
          </Col>
        </Row>

        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <LayoutCard>
              <h2 style={{ fontSize: '18px' }}>
                {t('transactions.form.objectives')}
              </h2>
              <Row gutter={[20, 20]}>
                <Col xs={8}>
                  <Form.Item
                    name="type"
                    label={t('transactions.form.type.input')}
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder={t('globalForms.select')}
                    >
                      <Select.Option value={1} key="1">
                        {t('transactions.form.type.moveIn')}
                      </Select.Option>
                      <Select.Option value={2} key="2">
                        {t('transactions.form.type.moveOut')}
                      </Select.Option>
                      <Select.Option value={3} key="3">
                        {t('transactions.form.type.cou')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    name="contractId"
                    label={t('transactions.form.contract')}
                    rules={[{ required: true }]}
                  >
                    <ContractSelect disabled={query.has('contractId')} />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    name="projectId"
                    label={t('transactions.form.project')}
                    rules={[{ required: true }]}
                  >
                    <ProjectSelect 
                      filter={{ users: [contract?.tenantId] }} 
                      disabled={query.has('projectId')}
                      value={projectId}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    name="userIn"
                    label={t('transactions.form.moveInUser')}
                    rules={[{ required: projectId && [1, 3].includes(Number(values.type)) }]}
                  >
                    <UserSelect 
                      roles={[USER_ROLES['tentant-employee'].key]} 
                      disabled={!projectId || ![1, 3].includes(Number(values.type))}
                      filter={{ projectIds: [projectId]}}
                      itemsFilter={(item) => (item.id !== values.userOut && item.isBusy !== true)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    name="userOut"
                    disabled={!projectId || usersLoading}
                    label={t('transactions.form.moveOutUser')}
                    required={projectId && [2, 3].includes(Number(values.type))}
                    rules={[{ required: projectId && [2, 3].includes(Number(values.type)) }]}
                  >
                    <UserSelect 
                      roles={[USER_ROLES['tentant-employee'].key]} 
                      disabled={!projectId || ![2, 3].includes(Number(values.type))}
                      filter={{ projectIds: [projectId] }}
                      itemsFilter={(item) => (item.id !== values.userIn && item.isBusy === true)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              {/* issueDate - deadline */}
              <Form.Item
                name="dates"
                label={t('transactions.form.dates')}
                rules={[{ required: true }]}
              >
                <DatePicker 
                  disabledUnlimited
                  placeholder={[t('transactions.form.period'), t('transactions.form.deadline')]}
                />
              </Form.Item>
            </LayoutCard>
          </Col>
       
        </Row>

        <LayoutStickyFooter>
          <LayoutFooterActions>
            <Button size="large" type="default" onClick={() => goBack()}>
              {t('transactions.form.cancel')}
            </Button>
            <Form.Item noStyle>
              <Button size="large" type="primary" htmlType="submit">
                {t('transactions.form.save')}
              </Button>
            </Form.Item>
          </LayoutFooterActions>
        </LayoutStickyFooter>
      </LayoutOverlay>
    </Form>
  )
}

export { TransactionForm }
