import { colors } from '_colors'
import React from 'react'
import { BuildingAddressFormEdit } from 'screens/BuildingsManager/AsideComponents/BuildingAddressFormEdit'
import { useGlobalState } from 'state'
import { parseJson } from 'utils/parseJson'

const AddressEdit = ({ data }) => {
  const [, dispatch] = useGlobalState()

  const { addressDetails } = data

  const editAddress = () => {
    dispatch({
      type: 'SET_RIGHT_BAR_COMPONENT',
      payload: () => <BuildingAddressFormEdit buildingId={data.id} />,
    })
    dispatch({ type: 'EXPAND_RIGHT_BAR' })
  }

  return (
    <h1
      role="none"
      onClick={editAddress}
      style={{ color: addressDetails?.formatted_address ? colors.lightGray() : colors.text() }}
    >
      {addressDetails?.formatted_address || 'Add Building Address'}

    </h1>
  )
}

const AddressView = ({ data }) => {
  const [, dispatch] = useGlobalState()

  const { addressDetails } = data

  const editAddress = () => {
    dispatch({
      type: 'SET_RIGHT_BAR_COMPONENT',
      payload: () => <BuildingAddressFormEdit buildingId={data.id} />,
    })
    dispatch({ type: 'EXPAND_RIGHT_BAR' })
  }

  return (
    <h1
      role="none"
      onClick={editAddress}
    >
      {addressDetails?.value || 'Add Building Address'}

    </h1>
  )
}

export { AddressEdit, AddressView }
