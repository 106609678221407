import { APARTMENTS_LIST } from '_graphql'
import { useLazyQuery } from '@apollo/react-hooks'
import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function ApartmentSelect({ 
  buildingId = null,
  value = null,
  onChange,
  onTitle,
  ...props
}) {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(!!value)

  const [fetch, { data, loading }] = useLazyQuery(APARTMENTS_LIST)

  useEffect(() => {
    if (buildingId && (value || isFocused)) {
      fetch({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            buildings: [buildingId],
          }),
        }
      })
    }
  }, [value, isFocused, buildingId])

  useEffect(() => {
    let title = null

    if (value) {
      let apt = data?.response?.list?.find?.((apt) => (apt.id === value))
      title = `#${apt?.code} ${apt?.title}`.trim() || `#${value}`
    }

    onTitle?.(title)
  }, [value, data])

  const setValue = (value) => {
    onChange?.(value)
  }

  return (
    <Select
      showSearch
      loading={loading}
      disabled={!buildingId}
      style={{ width: '100%' }}
      onChange={setValue}
      onFocus={() => { !isFocused && setIsFocused(true) }}
      value={value}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      {...props}
    >
      <Select.Option value={null}>{t('rental-request.components.sales-select.not-selected')}</Select.Option>
      {data?.response?.list?.map?.((apt) => (
        <Select.Option value={apt.id} key={apt.id}>
          #{apt.code}, {apt.title}
        </Select.Option>
      ))}
    </Select>
  )
}

export { ApartmentSelect }
