import 'moment/locale/de'

import { App } from 'App'
import i18n from 'i18next'
import moment from 'moment'
import React from 'react'
import ReactDOM from 'react-dom'
import { initReactI18next } from 'react-i18next'

import { de,en } from './translations'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      de: { translation: de },
      en: { translation: en },
    },

    lng: (() => {
      const currentLang = window.localStorage.getItem('language')
      const lng = ['en', 'de'].includes(currentLang) ? currentLang : 'de'
      moment.locale(lng)
      return lng
    })(),

    fallbackLng: ['en', 'de'],

    interpolation: {
      escapeValue: false,
      format(value, format, lng) {
        if (format === 'sku') return `#${value.split('-')[0]}`
        // if (value instanceof Date) return moment(value).format(format);
        return value
      },
    },
  })

i18n.on('languageChanged', (lng) => {
  moment.locale(lng)
  window.localStorage.setItem('language', lng)
})

ReactDOM.render(
  <App />,
  document.getElementById('root'),
)
