import { gql } from 'apollo-boost'

import { comissionPaymentFragment,profileFragment } from '../_fragments'

export const CP_LIST = gql`
  query listComissionPayments(
    $page: Int!
    $pageSize: Int!
    $sorting: String
    $search: String
    $filter: JSON
  ) {
    response: listComissionPayments(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      search: $search
      filter: $filter
    ) {
      list {
        ...comissionPaymentFragment
        landlordProfile  {
          ...profileFragment
        }
        recipientProfile  {
          ...profileFragment
        }
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${comissionPaymentFragment},
  ${profileFragment},
`

export const FETCH_CP_BY_SKU = gql`
  query fetchComissionPaymentBySku($sku: String!) {
    response: fetchComissionPaymentBySku(sku: $sku) {
      ...comissionPaymentFragment
      landlordProfile  {
        ...profileFragment
      }
      recipientProfile  {
          ...profileFragment
      }
    }
  }
  ${comissionPaymentFragment},
  ${profileFragment},
`


export const REBUILD_CP = gql`
  query cpRegenerate($sku: String!) {
    response: cpRegenerate(sku: $sku) {
      ...comissionPaymentFragment
    }
  }
  ${comissionPaymentFragment}
`

export const CP_MARK_PAID = gql`
  query cpMarkPaid($sku: String!) {
    response: cpMarkPaid(sku: $sku) {
      ...comissionPaymentFragment
    }
  }
  ${comissionPaymentFragment}
`
