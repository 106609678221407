import { Button, Space } from 'antd'
import React, { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { parseJson } from 'utils/parseJson'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'

import { Room } from './Room'
import { roomsInitialState } from './roomsInitialState'

const reducer = (state, payload) => ({ ...state, ...payload })

const Rooms = (props) => {
  const { t } = useTranslation()
  const [roomsState, dispatchRoomsState] = useReducer(reducer, roomsInitialState)
  const [shouldUpdate, setShouldUpdate] = useState(false)

  useEffect(() => {
    const rooms = props.apartment.roomOptions
    dispatchRoomsState(rooms)
    setShouldUpdate(true)
  }, [props.apartment])

  useDebouncedEffect(
    () => {
      if (shouldUpdate) {
        props.saveRooms(roomsState)
      }
    },
    200,
    [roomsState],
  )

  const addRoom = (type, isCustom) => {
    const arr = [...roomsState[type]]
    const el = isCustom ? roomsState.customRoomObject : { ...arr[arr.length - 1] }
    el.radioSelected = ''
    el.selected = []
    el.additional = []
    dispatchRoomsState({ [type]: [...arr, el] })
  }

  const deleteRoom = (index, type) => {
    const arr = [...roomsState[type]]
    dispatchRoomsState({ [type]: arr.filter((_, i) => i !== index) })
  }

  const onTitleChange = (index, type, event) => {
    const arr = [...roomsState[type]]
    arr[index].title = event.target.value
    dispatchRoomsState({ [type]: arr })
  }

  const onAmenitySelect = (index, type, value) => {
    const arr = [...roomsState[type]]
    if (arr[index].selectedAmenities.includes(value)) {
      arr[index].selectedAmenities.splice(arr[index].selectedAmenities.indexOf(value), 1)
    } else {
      arr[index].selectedAmenities.push(value)
    }
    dispatchRoomsState({ [type]: arr })
  }

  const onTypeChange = (index, type, value) => {
    const arr = [...roomsState[type]]
    arr[index].selectedType = value
    dispatchRoomsState({ [type]: arr })
  }

  const addAdditional = (index, type) => {
    const arr = [...roomsState[type]]
    arr[index].additional.push('')
    dispatchRoomsState({ [type]: arr })
  }
  const onAdditionalChange = (index, type, value, recordIndex) => {
    const arr = [...roomsState[type]]
    arr[index].additional[recordIndex] = value
    dispatchRoomsState({ [type]: arr })
  }
  const deleteAdditional = (roomIndex, type, recordIndex) => {
    const arr = [...roomsState[type]]
    const room = arr[roomIndex]
    const { additional } = room
    room.additional = additional.filter((_, i) => i !== recordIndex)
    arr[roomIndex] = room
    dispatchRoomsState({ [type]: arr })
  }

  return (
    <Space direction="vertical" size={40} style={{ width: '100%' }}>
      <Space direction="vertical" size={15} style={{ width: '100%' }}>
        <h2>{t('apartments.fields.bedroom')}</h2>

        {roomsState.bedroom.map((roomData, roomIndex, roomsArray) => (
          <Room
            roomType="bedroom"
            data={roomData}
            roomIndex={roomIndex}
            key={roomIndex}
            roomsCount={roomsArray.length}
            onDelete={() => deleteRoom(roomIndex, 'bedroom')}
            onTypeChange={(value) => onTypeChange(roomIndex, 'bedroom', value)}
            addAdditional={() => addAdditional(roomIndex, 'bedroom')}
            onAdditionalChange={(value, recordIndex) => onAdditionalChange(roomIndex, 'bedroom', value, recordIndex)}
            deleteAdditional={(recordIndex) => deleteAdditional(roomIndex, 'bedroom', recordIndex)}
          />
        ))}
        {!props.apartment.isStudio && (
          <Button type="primary" size="large" onClick={() => addRoom('bedroom')}>
            + {t('apartments.fields.bedroom')}
          </Button>
        )}
      </Space>

      <Space direction="vertical" size={15} style={{ width: '100%' }}>
        <h2>{t('apartments.fields.bathroom')}</h2>

        {roomsState.bathroom.map((roomData, roomIndex, roomsArray) => (
          <Room
            roomType="bedroom"
            data={roomData}
            roomIndex={roomIndex}
            key={roomIndex}
            roomsCount={roomsArray.length}
            onDelete={() => deleteRoom(roomIndex, 'bathroom')}
            onTitleChange={(e) => onTitleChange(roomIndex, 'bathroom', e)}
            onAmenitySelect={(value) => onAmenitySelect(roomIndex, 'bathroom', value)}
            onTypeChange={(value) => onTypeChange(roomIndex, 'bathroom', value)}
            addAdditional={() => addAdditional(roomIndex, 'bathroom')}
            onAdditionalChange={(value, recordIndex) => onAdditionalChange(roomIndex, 'bathroom', value, recordIndex)}
            deleteAdditional={(recordIndex) => deleteAdditional(roomIndex, 'bathroom', recordIndex)}
          />
        ))}

        <Button type="primary" size="large" onClick={() => addRoom('bathroom')}>
          + {t('apartments.fields.bathroom')}
        </Button>
      </Space>

      <Space direction="vertical" size={15} style={{ width: '100%' }}>
        <h2>{t('buildings.fields.kitchen.title')}</h2>

        {roomsState.kitchen.map((roomData, roomIndex, roomsArray) => (
          <Room
            roomType="kitchen"
            data={roomData}
            roomIndex={roomIndex}
            key={roomIndex}
            roomsCount={roomsArray.length}
            onDelete={() => deleteRoom(roomIndex, 'kitchen')}
            onTitleChange={(e) => onTitleChange(roomIndex, 'kitchen', e)}
            onAmenitySelect={(value) => onAmenitySelect(roomIndex, 'kitchen', value)}
            onTypeChange={(value) => onTypeChange(roomIndex, 'kitchen', value)}
            addAdditional={() => addAdditional(roomIndex, 'kitchen')}
            onAdditionalChange={(value, recordIndex) => onAdditionalChange(roomIndex, 'kitchen', value, recordIndex)}
            deleteAdditional={(recordIndex) => deleteAdditional(roomIndex, 'kitchen', recordIndex)}
          />
        ))}

        {!props.apartment.isStudio && (
          <Button type="primary" size="large" onClick={() => addRoom('kitchen')}>
            + {t('buildings.fields.kitchen.title')}
          </Button>
        )}
      </Space>

      {/* Other rooms */}

      {!props.apartment.isStudio && (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.balcony.title')}</h2>

          {roomsState.balkony.map((roomData, roomIndex, roomsArray) => (
            <Room
              roomType="custom"
              data={roomData}
              key={roomIndex}
              roomIndex={roomIndex}
              roomsCount={roomsArray.length}
              onDelete={() => deleteRoom(roomIndex, 'balkony')}
              onTitleChange={(e) => onTitleChange(roomIndex, 'balkony', e)}
              addAdditional={() => addAdditional(roomIndex, 'balkony')}
              onAdditionalChange={(value, recordIndex) => onAdditionalChange(roomIndex, 'balkony', value, recordIndex)}
              deleteAdditional={(recordIndex) => deleteAdditional(roomIndex, 'balkony', recordIndex)}
            />
          ))}

          <Button type="primary" size="large" onClick={() => addRoom('balkony', true)}>
            + {t('buildings.fields.balcony.title')}
          </Button>
        </Space>
      )}

      {!props.apartment.isStudio && (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.livingRoom.title')}</h2>

          {roomsState.livingRoom.map((roomData, roomIndex, roomsArray) => (
            <Room
              roomType="custom"
              data={roomData}
              roomIndex={roomIndex}
              key={roomIndex}
              roomsCount={roomsArray.length}
              onDelete={() => deleteRoom(roomIndex, 'livingRoom')}
              onTitleChange={(e) => onTitleChange(roomIndex, 'livingRoom', e)}
              addAdditional={() => addAdditional(roomIndex, 'livingRoom')}
              onAdditionalChange={(value, recordIndex) => onAdditionalChange(roomIndex, 'livingRoom', value, recordIndex)}
              deleteAdditional={(recordIndex) => deleteAdditional(roomIndex, 'livingRoom', recordIndex)}
            />
          ))}

          <Button type="primary" size="large" onClick={() => addRoom('livingRoom', true)}>
            + {t('buildings.fields.livingRoom.title')}
          </Button>
        </Space>
      )}

      {!props.apartment.isStudio && (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.diningRoom.title')}</h2>

          {roomsState.diningRoom.map((roomData, roomIndex, roomsArray) => (
            <Room
              roomType="custom"
              data={roomData}
              roomIndex={roomIndex}
              key={roomIndex}
              roomsCount={roomsArray.length}
              onDelete={() => deleteRoom(roomIndex, 'diningRoom')}
              onTitleChange={(e) => onTitleChange(roomIndex, 'diningRoom', e)}
              addAdditional={() => addAdditional(roomIndex, 'diningRoom')}
              onAdditionalChange={(value, recordIndex) => onAdditionalChange(roomIndex, 'diningRoom', value, recordIndex)}
              deleteAdditional={(recordIndex) => deleteAdditional(roomIndex, 'diningRoom', recordIndex)}
            />
          ))}

          <Button type="primary" size="large" onClick={() => addRoom('diningRoom', true)}>
            + {t('buildings.fields.diningRoom.title')}
          </Button>
        </Space>
      )}

      {!props.apartment.isStudio && (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.laundryRoom.title')}</h2>

          {roomsState.laundryRoom.map((roomData, roomIndex, roomsArray) => (
            <Room
              roomType="custom"
              data={roomData}
              roomIndex={roomIndex}
              key={roomIndex}
              roomsCount={roomsArray.length}
              onDelete={() => deleteRoom(roomIndex, 'laundryRoom')}
              onTitleChange={(e) => onTitleChange(roomIndex, 'laundryRoom', e)}
              addAdditional={() => addAdditional(roomIndex, 'laundryRoom')}
              onAdditionalChange={(value, recordIndex) => onAdditionalChange(roomIndex, 'laundryRoom', value, recordIndex)}
              deleteAdditional={(recordIndex) => deleteAdditional(roomIndex, 'laundryRoom', recordIndex)}
            />
          ))}

          <Button type="primary" size="large" onClick={() => addRoom('laundryRoom', true)}>
            + {t('buildings.fields.laundryRoom.title')}
          </Button>
        </Space>
      )}

      {!props.apartment.isStudio && (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.workRoom.title')}</h2>

          {roomsState.workRoom.map((roomData, roomIndex, roomsArray) => (
            <Room
              roomType="custom"
              data={roomData}
              roomIndex={roomIndex}
              key={roomIndex}
              roomsCount={roomsArray.length}
              onDelete={() => deleteRoom(roomIndex, 'workRoom')}
              onTitleChange={(e) => onTitleChange(roomIndex, 'workRoom', e)}
              addAdditional={() => addAdditional(roomIndex, 'workRoom')}
              onAdditionalChange={(value, recordIndex) => onAdditionalChange(roomIndex, 'workRoom', value, recordIndex)}
              deleteAdditional={(recordIndex) => deleteAdditional(roomIndex, 'workRoom', recordIndex)}
            />
          ))}

          <Button type="primary" size="large" onClick={() => addRoom('workRoom', true)}>
            + {t('buildings.fields.workRoom.title')}
          </Button>
        </Space>
      )}

      {!props.apartment.isStudio && (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.customRoom.title')}</h2>

          {roomsState.customRoom.map((roomData, roomIndex, roomsArray) => (
            <Room
              roomType="custom"
              data={roomData}
              roomIndex={roomIndex}
              key={roomIndex}
              roomsCount={roomsArray.length}
              onDelete={() => deleteRoom(roomIndex, 'customRoom')}
              onTitleChange={(e) => onTitleChange(roomIndex, 'customRoom', e)}
              addAdditional={() => addAdditional(roomIndex, 'customRoom')}
              onAdditionalChange={(value, recordIndex) => onAdditionalChange(roomIndex, 'customRoom', value, recordIndex)}
              deleteAdditional={(recordIndex) => deleteAdditional(roomIndex, 'customRoom', recordIndex)}
            />
          ))}

          <Button type="primary" size="large" onClick={() => addRoom('customRoom', true)}>
            + {t('buildings.fields.customRoom.title')}
          </Button>
        </Space>
      )}

    </Space>
  )
}

export { RoomsView } from './RoomsView'
export { Rooms }
