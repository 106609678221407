import { isTenant } from '_graphql/_enums'
import { DeleteOutlined } from '@ant-design/icons'
import { Button,Col, Divider, Input, Row } from 'antd'
import { ReactComponent as Cable } from 'img/icons/amenities/cable.svg'
import { ReactComponent as CoinSlot } from 'img/icons/amenities/coin_slot.svg'
import { ReactComponent as Conditioner } from 'img/icons/amenities/conditioner.svg'
import { ReactComponent as DBVT2 } from 'img/icons/amenities/digital_tv.svg'
import { ReactComponent as Dryer } from 'img/icons/amenities/dryer.svg'
import { ReactComponent as ExtraBed } from 'img/icons/amenities/extra_bed.svg'
import { ReactComponent as Included } from 'img/icons/amenities/included.svg'
import { ReactComponent as Internet } from 'img/icons/amenities/internet.svg'
import { ReactComponent as Lan } from 'img/icons/amenities/lan.svg'
import { ReactComponent as Safe } from 'img/icons/amenities/safe.svg'
import { ReactComponent as Sat } from 'img/icons/amenities/sat.svg'
import { ReactComponent as TV } from 'img/icons/amenities/tv.svg'
import { ReactComponent as WashingMaschine } from 'img/icons/amenities/washing_maschine.svg'
import { ReactComponent as WLan } from 'img/icons/amenities/wlan.svg'
import { ReactComponent as WorkingPlace } from 'img/icons/amenities/working_place.svg'
import React, { useEffect,useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import { parseJson } from 'utils/parseJson'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'

import { amenitiesInitialState } from './amenitiesInitialState'
import { AmenityBorder, AmenityWrapper, Check, Content, Icon, OptionsWrapper } from './styled'

const Icons = {
  cable: Cable,
  internet: Internet,
  lan: Lan,
  wlan: WLan,
  airConditioning: Conditioner,
  dryer: Dryer,
  tv: TV,
  washingMaschine: WashingMaschine,
  workingPlace: WorkingPlace,
  safe: Safe,
  extraBed: ExtraBed,
  coinSlot: CoinSlot,
  included: Included,
  sat: Sat,
  dbvt2: DBVT2,
  smartTv: TV,
}

const reducer = (state, payload) => payload

const Amenities = (props) => {
  const { t } = useTranslation()
  const [globalState] = useGlobalState()

  const [amenitiesState, dispatchAmenitiesState] = useReducer(reducer, amenitiesInitialState)
  const [shouldUpdate, setShouldUpdate] = useState(false)

  useEffect(() => {
    const amenities = props.apartment.amenitiesOptions
    if (amenities) {
      dispatchAmenitiesState(amenities)
    } else {
      props.saveAmenities(amenitiesState)
    }
    setShouldUpdate(true)
  }, []); // eslint-disable-line

  useDebouncedEffect(
    () => {
      if (shouldUpdate) {
        props.saveAmenities(amenitiesState)
      }
    },
    1000,
    [amenitiesState],
  )

  const toggleAmenity = (amenityIndex) => {
    if (isTenant(globalState.currentUser.role)) {
      return
    }
    const state = { ...amenitiesState }
    state.default[amenityIndex].selected = !state.default[amenityIndex].selected
    dispatchAmenitiesState(state)
  }

  const toggleOption = (amenityIndex, optionIndex) => {
    if (isTenant(globalState.currentUser.role)) {
      return
    }
    const state = { ...amenitiesState }
    state.default[amenityIndex].options.forEach((_, i) => {
      state.default[amenityIndex].options[i].selected = false
    })
    state.default[amenityIndex].options[optionIndex].selected = !state.default[amenityIndex].options[optionIndex]
      .selected
    dispatchAmenitiesState(state)
  }

  const addCustomAmenity = () => {
    if (isTenant(globalState.currentUser.role)) {
      return
    }
    const state = { ...amenitiesState }
    state.custom.push('')
    dispatchAmenitiesState(state)
  }

  const onCustomChange = (value, index) => {
    if (isTenant(globalState.currentUser.role)) {
      return
    }
    const state = { ...amenitiesState }
    state.custom[index] = value
    dispatchAmenitiesState(state)
  }

  const deleteCustom = (index) => {
    if (isTenant(globalState.currentUser.role)) {
      return
    }
    const state = { ...amenitiesState }
    const newCustom = state.custom.filter((c, i) => index !== i)

    state.custom = newCustom
    dispatchAmenitiesState(state)
  }

  return (
    <>
      {amenitiesState.default && (
        <>
          <h2>{t('apartments.sections.default-amenities')}</h2>
          {amenitiesState.default.map((amenity, amenityIndex) => {
            const CurrentIcon = Icons[amenity.id]
            return (
              <AmenityBorder key={amenityIndex}>
                <AmenityWrapper selected={amenity.selected} onClick={() => toggleAmenity(amenityIndex)}>
                  {CurrentIcon && (
                    <Icon style={{ width: '30px', height: '30px' }}>
                      <CurrentIcon style={{ width: '30px', height: '30px' }} />
                    </Icon>
                  )}
                  <Content>
                    <h2 style={{ margin: 0, lineHeight: 1.2 }}>{amenity.title}</h2>
                    {/* <p style={{ margin: 0, lineHeight: 1.2 }}>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                  </Content>

                  <Check selected={amenity.selected} />
                </AmenityWrapper>

                {amenity.options && (
                  <OptionsWrapper collapsed={!amenity.selected}>
                    <Divider />
                    <Row gutter={[2, 0]}>
                      {amenity.options.map((option, optionIndex, arr) => {
                        const OptionIcon = Icons[option.id]
                        return (
                          <Col xs={24 / arr.length} key={optionIndex}>
                            <AmenityWrapper
                              option
                              selected={option.selected}
                              onClick={() => toggleOption(amenityIndex, optionIndex)}
                            >
                              {OptionIcon && (
                                <Icon style={{ width: '30px', height: '30px' }}>
                                  <OptionIcon style={{ width: '30px', height: '30px' }} />
                                </Icon>
                              )}
                              <Content>
                                <h2 style={{ margin: 0, lineHeight: 1.2 }}>{option.title}</h2>
                                {/* <p style={{ margin: 0, lineHeight: 1.2 }}>
                          Lorem ipsum dolor.
                        </p> */}
                              </Content>

                              {/* <Check selected={option.selected} /> */}
                            </AmenityWrapper>
                          </Col>
                        )
                      })}
                    </Row>
                  </OptionsWrapper>
                )}
              </AmenityBorder>
            )
          })}
        </>
      )}

      {amenitiesState.custom?.length ? (
        <>
          <Divider />
          <h2>{t('apartments.sections.custom-amenities')}</h2>
          {amenitiesState.custom.map((a, index) => (
            <Row gutter={[0, 20]} key={index}>
              <Col xs={12}>
                <Input
                  value={a}
                  
                  onChange={(e) => onCustomChange(e.target.value, index)}
                  addonAfter={<DeleteOutlined onClick={() => deleteCustom(index)} />}
                />
              </Col>
            </Row>
          ))}
        </>
      ) : null}

      <Button onClick={addCustomAmenity}>{t('apartments.actions.add-custom-amenity')}</Button>
    </>
  )
}

export { AmenitiesView } from './AmenitiesView'
export { Amenities }
