import { gql } from 'apollo-boost'

export const messageFragment = gql`
    fragment messageFragment on Message {
        id
        entityType
        entityId
        userId
        user {
            id
            firstName
            lastName
        }
        type
        message
        createdAt
    }
`
