import { billRunFragment } from '_graphql/_fragments/billRun'
import { gql } from 'apollo-boost'

import { profileFragment } from '../_fragments'

export const BR_LIST = gql`
  query listBillRuns(
    $page: Int!
    $pageSize: Int!
    $sorting: String
    $search: String
    $filter: JSON
  ) {
    response: listBillRuns(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      search: $search
      filter: $filter
    ) {
      list {
        ...billRunFragment
        customerProfile  {
          ...profileFragment
        }
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${billRunFragment},
  ${profileFragment},
`

export const FETCH_BR_BY_SKU = gql`
  query fetchBillRunBySku($sku: String!) {
    response: fetchBillRunBySku(sku: $sku) {
      ...billRunFragment
      customerProfile {
        ...profileFragment
      }
    }
  }
  ${billRunFragment},
  ${profileFragment},
`


export const REBUILD_BR = gql`
  query brRegenerate($sku: String!) {
    response: brRegenerate(sku: $sku) 
  }
`

export const MARK_BR_PAID = gql`
  query brMarkPaid($sku: String!) {
    response: brMarkPaid(sku: $sku) 
  }
`
