import { Descriptions } from 'antd'
import * as DefaultTypes from 'components/ListManager/DefaultTypes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CountryRender } from 'utils/countries'

const fields = [
  {
    title: 'business-details',
    fields: [
      {
        key: 'companyName',
      },
      {
        key: 'taxRate',
        render: ({ value }) => `${value}%`,
      },
      {},
      {
        key: 'residenseCert',
        render: 'boolean',
      },
      {
        key: 'tenantExchange',
        render: 'boolean',
      },
      {},
      {
        key: 'billingAddress',
        render: ({ value, profile }) => (value ? (
          <>
            <CountryRender code={value?.country ?? profile.country} />,&nbsp;
            {value?.city},&nbsp;
            {value?.address},&nbsp;
            {value?.zipCode}
          </>
        ) : '-'),
      },
      {},
      {},
      {
        key: 'addressAddition',
        render: ({ profile }) => (
          <>
            <CountryRender code={profile.country} />,&nbsp;
            {profile?.city ?? '-'},&nbsp;
            {profile?.addressAddition ?? '-'},&nbsp;
            {profile?.zipCode ?? '-'}
          </>
        ),
      },
    ],
  },
  {
    title: 'contact-details',
    fields: [
      {
        key: 'contactFullName',
      },
      {
        key: 'phoneNumber',
      },
      {
        key: 'correspondanceEmail',
        options: { type: 'email' },
        render: 'link',
      },
    ],
  },
  {
    title: 'legal-info',
    fields: [
      {
        key: 'receptor',
      },
      {
        key: 'clearingCode',
      },
      {
        key: 'ibanCode',
      },
      {
        key: 'bicCode',
      },
    ],
  },

]

export const ProfileDisplay = ({ profile }) => {
  const { t } = useTranslation()
  return (
    <div>
      {fields.map((block) => (
        <Descriptions title={t(`profiles.sections.${block.title}`)} key={block.index} column="2">
          {block.fields.map((field) => {
            const handler = typeof field.render !== 'string' && field.render !== undefined ? field.render : DefaultTypes[field.render] ?? DefaultTypes.string
            return (
              <Descriptions.Item key={field.key} label={field.key ? t(`profiles.fields.${field.key}`) : null} labelStyle={{ fontWeight: 500 }} contentStyle={{ marginRight: 25 }}>
                {handler({ value: profile[field.key], options: field.options, profile })}
              </Descriptions.Item>
            )
          })}
        </Descriptions>
      ))}
    </div>
  )
}
