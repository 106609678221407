import Book from '@2fd/ant-design-icons/lib/Book'
import { InfoCircleOutlined, MessageOutlined } from '@ant-design/icons'
import i18next from 'i18next'
import { searchNumbers } from 'libphonenumber-js'
import { DateTime } from 'luxon'
import stc from 'string-to-color'

/// /////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////

export const serviceProviderTypes = {
  ACCOMODATION: 'accomodation', 
  UTILITIES: 'utilities', 
  ELECTRYCITY: 'electrycity', 
  GAS: 'gas', 
  HEATING: 'heating', 
  WATER: 'water', 
  BROADCAST: 'broadcast', 
  CABLE: 'cable', 
  CLEANING: 'cleaning', 
  PARKING: 'parking', 
  INTERNET: 'internet', 
  REPAIRS: 'repairs', 
  AGENT_NEW: 'agent-new', 
  AGENT_OLD: 'agent-old', 
  AGENT_HANDOVER: 'agent-handover', 
  OTHER: 'other', 
}

export const CONTRACTSERVICE_PROVIDERS = Object.fromEntries(
  Object.entries(serviceProviderTypes).map(([id, key]) => ([key, {
    key: id,
    id: key,
    get title() { return i18next.t(`profiles.types.${id}`) },
    color: stc(id),
  }])),
)


export const profileTypes = {
  LANDLORD: 'landlord',
  SERVICE_PROVIDER: 'service-provider',
  TENANT: 'customer',
}

export const PROFILE_TYPES = Object.fromEntries(
  Object.entries(profileTypes).map(([id, key]) => ([key, {
    key: id,
    id: key,
    get title() { return i18next.t(`profiles.contractTypes.${id}`) },
    color: stc(id),
  }])),
)



export const roles = {
  TECH_OWNER: 'Tech-owner',
  TECH_DEV: 'Tech-dev',

  SUPER_ADMIN: 'super-admin',

  B2B_ADMIN: 'b2b-admin',
  B2B_SALES: 'b2b-sales',
  B2B_ACCOUNTANT: 'b2b-accountant',
  B2B_TRAINEE: 'b2b-trainee',
  B2B_SUPPORT: 'b2b-support',

  LANDLORD_OWNER: 'landlord-owner',
  LANDLORD_MANAGER: 'landlord-manager',
  LANDLORD_ACCOUNTANT: 'landlord-accountant',
  LANDLORD_CONTENT: 'landlord-content',

  TENANT_ADMIN: 'tentant-admin',
  TENANT_MANAGER: 'tentant-manager',
  TENANT_PROJECT_MANAGER: 'tentant-project-manager',
  TENANT_ACCOUNTANT: 'tentant-accountant',
  TENANT_EMPLOYEE: 'tentant-employee',

  SP_ADMIN: 'SP-admin',
  SP_EMPLOYEE: 'SP_EMPLOYEE',
  SP_ACCOUNTANT: 'SP_ACCOUNTANT',
}

export const USER_ROLES = Object.fromEntries(
  Object.entries(roles).map(([id, key]) => ([key, {
    key: id,
    id: key,
    get title() { return i18next.t(`user.roles.${id}`) },
    color: stc(id),
  }])),
)

export const ROLE_GROUPS = {
  TECH: [
    roles.TECH_OWNER,
    roles.TECH_DEV,
  ],
  B2B: [
    roles.SUPER_ADMIN,
    roles.B2B_ADMIN,
    roles.B2B_SALES,
    roles.B2B_ACCOUNTANT,
    roles.B2B_TRAINEE,
    roles.B2B_SUPPORT,
  ],
  LANDLORD: [
    roles.LANDLORD_OWNER,
    roles.LANDLORD_MANAGER,
    roles.LANDLORD_CONTENT,
    roles.LANDLORD_ACCOUNTANT,
  ],
  TENANT: [
    roles.TENANT_ADMIN,
    roles.TENANT_ACCOUNTANT,
    roles.TENANT_EMPLOYEE,
    roles.TENANT_MANAGER,
    roles.TENANT_PROJECT_MANAGER,
  ],
  SERVICE_PROVIDER: [
    roles.SP_ADMIN,
    roles.SP_EMPLOYEE,
    roles.SP_ACCOUNTANT
  ]
}

export const PROFILE_TYPES_ROLES = {
  [profileTypes.LANDLORD]: [
    USER_ROLES[roles.LANDLORD_OWNER],
  ],
  [profileTypes.SERVICE_PROVIDER]: [
    USER_ROLES[roles.B2B_ADMIN],
    USER_ROLES[roles.B2B_SALES],
  ],
  [profileTypes.TENANT]: [
    USER_ROLES[roles.TENANT_ADMIN],
  ]
}

export const USER_GROUPS = Object.fromEntries(
  Object.entries(ROLE_GROUPS).map(([id, key]) => ([key, {
    key: id,
    id: key,
    get title() { return i18next.t(`user.groups.${id}`)},
    color: searchNumbers(id),
  }])),
)

export function isAdmin(role) {
  return ROLE_GROUPS.B2B.includes(role)
}

export function isLandlord(role) {
  return ROLE_GROUPS.LANDLORD.includes(role)
}

export function isTenant(role) {
  return ROLE_GROUPS.TENANT.includes(role)
}

export function isTenantManager(role) {
  return ROLE_GROUPS.TENANT.includes(role) && role !== roles.TENANT_EMPLOYEE && role !== roles.TENANT_ACCOUNTANT
}

export function isSlave(role) {
  return !isAdmin(role) && role !== roles.LANDLORD_OWNER && role !== roles.TENANT_ADMIN
}

export function isSlaveOwner(role) {
  return role === roles.LANDLORD_OWNER || role === roles.TENANT_ADMIN
}

export function isSingleGroup(role1, role2) {
  return (
    (ROLE_GROUPS.B2B.includes(role1) && ROLE_GROUPS.B2B.includes(role2))
    || (ROLE_GROUPS.LANDLORD.includes(role1) && ROLE_GROUPS.LANDLORD.includes(role2))
    || (ROLE_GROUPS.TENANT.includes(role1) && ROLE_GROUPS.TENANT.includes(role2))
  )
}

/// /////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////

export const RENTAL_REQUESTS_STATUSES = {
  0: {
    key: 0,
    id: 'NEW',
    get title() { return i18next.t('rental-request.statuses.NEW.title') },
    color: 'geekblue',
    alertType: 'info',
  },

  2: {
    key: 2,
    id: 'SALES_ADDED_APARTMENTS',
    get title() { return i18next.t('rental-request.statuses.SALES_ADDED_APARTMENTS.title') },
    get description() { return i18next.t('rental-request.statuses.SALES_ADDED_APARTMENTS.description') },
    color: 'geekblue',
    alertType: 'success',
  },

  3: {
    key: 3,
    id: 'TENANT_RESPONDED',
    get title() { return i18next.t('rental-request.statuses.TENANT_RESPONDED.title') },
    get description() { return i18next.t('rental-request.statuses.TENANT_RESPONDED.description') },
    color: 'geekblue',
    alertType: 'info',
  },

  4: {
    key: 4,
    id: 'SALES_RESPONDED',
    get title() { return i18next.t('rental-request.statuses.SALES_RESPONDED.title') },
    get description() { return i18next.t('rental-request.statuses.SALES_RESPONDED.description') },
    color: 'geekblue',
    alertType: 'info',
  },

  5: {
    key: 5,
    id: 'LANDLORD_RESPONDED',
    get title() { return i18next.t('rental-request.statuses.LANDLORD_RESPONDED.title') },
    get description() { return i18next.t('rental-request.statuses.LANDLORD_RESPONDED.description') },
    color: 'geekblue',
    alertType: 'info',
  },

  6: {
    key: 6,
    id: 'TENANT_RESPONDED_FINAL',
    get title() { return i18next.t('rental-request.statuses.TENANT_RESPONDED_FINAL.title') },
    get description() { return i18next.t('rental-request.statuses.TENANT_RESPONDED_FINAL.description') },
    color: 'geekblue',
    alertType: 'info',
  },

  7: {
    key: 7,
    id: 'SALES_RESPONDED_CONTRACT',
    get title() { return i18next.t('rental-request.statuses.SALES_RESPONDED_CONTRACT.title') },
    get description() { return i18next.t('rental-request.statuses.SALES_RESPONDED_CONTRACT.description') },
    color: 'green',
    alertType: 'success',
  },

  8: {
    key: 8,
    id: 'CLOSED',
    get title() { return i18next.t('rental-request.statuses.CLOSED.title') },
    get description() { return i18next.t('rental-request.statuses.CLOSED.description') },
    color: 'purple',
    alertType: 'success',
  },

}

export const REQUESTED_APARTMENT_STATUSES = {
  0: {
    id: 'NEW',
    get title() { return i18next.t('requested-apartment.statuses.NEW.title') },
    get description() { return i18next.t('requested-apartment.statuses.NEW.description') },
    color: 'green',
    alertType: 'success',
  },
  1: {
    id: 'TENANT_ACCEPT',
    get title() { return i18next.t('requested-apartment.statuses.TENANT_ACCEPT.title') },
    get description() { return i18next.t('requested-apartment.statuses.TENANT_ACCEPT.description') },
    get tenantDescription() { return i18next.t('requested-apartment.statuses.TENANT_ACCEPT.tenantDescription') },
    color: 'green',
    alertType: 'success',
  },
  2: {
    id: 'TENANT_REJECT',
    get title() { return i18next.t('requested-apartment.statuses.TENANT_REJECT.title') },
    get description() { return i18next.t('requested-apartment.statuses.TENANT_REJECT.description') },
    color: 'purple',
    alertType: 'warning',
  },
  3: {
    id: 'SALES_ACCEPT',
    get title() { return i18next.t('requested-apartment.statuses.SALES_ACCEPT.title') },
    get description() { return i18next.t('requested-apartment.statuses.SALES_ACCEPT.description') },
    color: 'green',
    alertType: 'success',
  },
  4: {
    id: 'SALES_REJECT',
    get title() { return i18next.t('requested-apartment.statuses.SALES_REJECT.title') },
    get description() { return i18next.t('requested-apartment.statuses.SALES_REJECT.description') },
    color: 'purple',
    alertType: 'warning',
  },
  5: {
    id: 'LANDLORD_ACCEPT',
    get title() { return i18next.t('requested-apartment.statuses.LANDLORD_ACCEPT.title') },
    get description() { return i18next.t('requested-apartment.statuses.LANDLORD_ACCEPT.description') },
    get tenantDescription() { return i18next.t('requested-apartment.statuses.LANDLORD_ACCEPT.tenantDescription') },
    color: 'green',
    alertType: 'success',
  },
  6: {
    id: 'LANDLORD_REJECT',
    get title() { return i18next.t('requested-apartment.statuses.LANDLORD_REJECT.title') },
    get description() { return i18next.t('requested-apartment.statuses.LANDLORD_REJECT.description') },
    get tenantDescription() { return i18next.t('requested-apartment.statuses.LANDLORD_REJECT.tenantDescription') },
    color: 'purple',
    alertType: 'warning',
  },
  7: {
    id: 'BOOK',
    get title() { return i18next.t('requested-apartment.statuses.BOOK.title') },
    get description() { return i18next.t('requested-apartment.statuses.BOOK.description') },
    color: 'green',
    alertType: 'success',
  },

  10: {
    id: 'ASK_INQUIRY_PERMISSION',
    get title() { return i18next.t('requested-apartment.statuses.ASK_INQUIRY_PERMISSION.title') },
    get description() { return i18next.t('requested-apartment.statuses.ASK_INQUIRY_PERMISSION.description') },
    color: 'green',
    alertType: 'success',
  },


  11: {
    id: 'MANAGER_ACCEPT_INQUIRY',
    get title() { return i18next.t('requested-apartment.statuses.MANAGER_ACCEPT_INQUIRY.title') },
    get description() { return i18next.t('requested-apartment.statuses.MANAGER_ACCEPT_INQUIRY.description') },
    color: 'green',
    alertType: 'success',
  },
  12: {
    id: 'MANAGER_REJECT_INQUIRY',
    get title() { return i18next.t('requested-apartment.statuses.MANAGER_REJECT_INQUIRY.title') },
    get description() { return i18next.t('requested-apartment.statuses.MANAGER_REJECT_INQUIRY.description') },
    color: 'purple',
    alertType: 'warning',
  },

  13: {
    id: 'ASK_RENT_PERMISSION',
    get title() { return i18next.t('requested-apartment.statuses.ASK_RENT_PERMISSION.title') },
    get description() { return i18next.t('requested-apartment.statuses.ASK_RENT_PERMISSION.description') },
    color: 'green',
    alertType: 'success',
  },

  14: {
    id: 'MANAGER_ACCEPT_RENT',
    get title() { return i18next.t('requested-apartment.statuses.MANAGER_ACCEPT_RENT.title') },
    get description() { return i18next.t('requested-apartment.statuses.MANAGER_ACCEPT_RENT.description') },
    color: 'green',
    alertType: 'success',
  },
  15: {
    id: 'MANAGER_REJECT_RENT',
    get title() { return i18next.t('requested-apartment.statuses.MANAGER_REJECT_RENT.title') },
    get description() { return i18next.t('requested-apartment.statuses.MANAGER_REJECT_RENT.description') },
    color: 'purple',
    alertType: 'warning',
  },
}

export const CONTRACT_ACTIONS = {
  1: {
    key: 1,
    id: 'CHANGE_OF_DATES',
    get title() { return i18next.t('contract.actions.CHANGE_OF_DATES.title') },
    get description() { return i18next.t('contract.actions.CHANGE_OF_DATES.description') },
    color: 'blue',
    alertType: 'info',
  },
  2: {
    key: 2,
    id: 'TENANT_SIGN',
    get title() { return i18next.t('contract.actions.TENANT_SIGN.title') },
    get description() { return i18next.t('contract.actions.TENANT_SIGN.description') },
    color: 'green',
    alertType: 'success',
  },
  3: {
    key: 3,
    id: 'LANDLORD_SIGN',
    get title() { return i18next.t('contract.actions.LANDLORD_SIGN.title') },
    get description() { return i18next.t('contract.actions.LANDLORD_SIGN.description') },
    color: 'green',
    alertType: 'success',
  },
  4: {
    key: 4,
    id: 'EXTEND_CONTRACT',
    get title() { return i18next.t('contract.actions.EXTEND_CONTRACT.title') },
    get description() { return i18next.t('contract.actions.EXTEND_CONTRACT.description') },
    color: 'blue',
    alertType: 'info',
  },
  5: {
    key: 5,
    id: 'SUSPEND_CONTRACT',
    get title() { return i18next.t('contract.actions.SUSPEND_CONTRACT.title') },
    get description() { return i18next.t('contract.actions.SUSPEND_CONTRACT.description') },
    color: 'blue',
    alertType: 'info',
  },
}

export const CONTRACT_ACTIONS_BY_ID = Object.fromEntries(Object.keys(CONTRACT_ACTIONS).map((key) => [CONTRACT_ACTIONS[key].id, CONTRACT_ACTIONS[key]]))

const hasDateChanges = ({ actions }) => actions.some((action) => (action.context.type === CONTRACT_ACTIONS_BY_ID.CHANGE_OF_DATES.key))
export const CONTRACT_STATUS = {
  new: {
    id: 'new',
    get title() { return i18next.t('contract.statuses.new.title') },
    color: 'blue',
    alertType: 'info',
  },
  'starting-soon': {
    id: 'starting-soon',
    get title() { return i18next.t('contract.statuses.starting-soon.title') },
    color: 'orange',
    alertType: 'warning',
  },
  ongoing: {
    id: 'ongoing',
    get title() { return i18next.t('contract.statuses.ongoing.title') },
    color: 'green',
    alertType: 'success',
    is: (contract) => {
      const dateFrom = contract.dateFrom ? DateTime.fromISO(contract.dateFrom) : null
      return (
        hasDateChanges(contract) &&
        dateFrom <= DateTime.local()
      )
    },
  },
  'closing-soon': {
    id: 'closing-soon',
    get title() { return i18next.t('contract.statuses.closing-soon.title') },
    color: 'orange',
    alertType: 'warning',
  },
  
  'need-reaction': {
    id: 'need-reaction',
    get title() { return i18next.t('contract.statuses.need-reaction.title') },
    color: 'blue',
    alertType: 'info',
  },
  closed: {
    id: 'closed',
    get title() { return i18next.t('contract.statuses.closed.title') },
    color: 'red',
    alertType: 'error',
  },
}

export const detectCurrentContractStatus = (contract, user) => {
  return contract?.status?.map?.(statusSlug => {
    const [statusName, statusSubtitle] = statusSlug.split(':')
    const status = { ...CONTRACT_STATUS[statusName] }
    status.subtitle = isAdmin(user?.role) && statusSubtitle ? String(statusSubtitle).slice(0, 1).toUpperCase() : null
    status.secondColor = status.subtitle ? stc(status.subtitle) : null
    return status
  }) || []
}

export const CONTRACT_ACTION_STATUS = {
  0: {
    key: 0,
    id: 'NEW',
    get title() { return i18next.t('contract.action-statuses.NEW.title') },
    get description() { return i18next.t('contract.action-statuses.NEW.description') },
    color: 'blue',
    alertType: 'info',
  },
  1: {
    key: 1,
    id: 'ACCEPT',
    get title() { return i18next.t('contract.action-statuses.ACCEPT.title') },
    get description() { return i18next.t('contract.action-statuses.ACCEPT.description') },
    color: 'green',
    alertType: 'success',
  },
  2: {
    key: 2,
    id: 'REJECT',
    get title() { return i18next.t('contract.action-statuses.REJECT.title') },
    get description() { return i18next.t('contract.action-statuses.REJECT.description') },
    color: 'red',
    alertType: 'warning',
  },
}

export const NOTIFICATION_GROUPS = {
  TEST_GROUP: {
    id: 'TEST_GROUP',
    color: '#f5a623',
    get name() { return i18next.t('notification-center.groups.TEST_GROUP.name') },
  },
  RENTAL_GROUP: {
    id: 'RENTAL_GROUP',
    color: 'hsl(211, 91%, 55%)',
    get name() { return i18next.t('notification-center.groups.RENTAL_GROUP.name') },
  },
}

export const NOTIFICATION_TYPES = {
  NOT_DESCRIBED: {
    id: 'NOT_DESCRIBED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('NOT_DESCRIBED+++'),
    icon: InfoCircleOutlined,
    get name() { return 'Notification not described' },
    title(context = {}) { return context.type },
    description(context = {}) { return JSON.stringify(context) },
    link(context = {}) { return '/' },
  },

  TEST_NOTIFICATION: {
    id: 'TEST_NOTIFICATION',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('TEST_NOTIFICATION+++'),
    icon: InfoCircleOutlined,
    get name() { return i18next.t('notification-center.types.TEST_NOTIFICATION.name') },
    title(context = {}) { return i18next.t('notification-center.types.TEST_NOTIFICATION.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.TEST_NOTIFICATION.description', context) },
    link(context = {}) { return '/' },
  },

  /** RENTAL_REQUEST */
  'RENTAL_REQUEST@NEW': {
    id: 'RENTAL_REQUEST@NEW',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('RENTAL_REQUEST@NEW++'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.RENTAL_REQUEST@NEW.name') },
    title(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@NEW.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@NEW.description', context) },
    link(context = {}) { return `/rental-requests-management/${context.sku}` },
  },
  'RENTAL_REQUEST@EDITED': {
    id: 'RENTAL_REQUEST@EDITED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('RENTAL_REQUEST@NEW++'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.RENTAL_REQUEST@EDITED.name') },
    title(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@EDITED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@EDITED.description', context) },
    link(context = {}) { return `/rental-requests-management/${context.sku}` },
  },
  'RENTAL_REQUEST@APARTMENT_PROPOSED': {
    id: 'RENTAL_REQUEST@APARTMENT_PROPOSED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('RENTAL_REQUEST@NEW++'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.RENTAL_REQUEST@APARTMENT_PROPOSED.name') },
    title(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@APARTMENT_PROPOSED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@APARTMENT_PROPOSED.description', context) },
    link(context = {}) { return `/rental-requests-management/${context.sku}` },
  },
  'RENTAL_REQUEST@APARTMENT_INQUIRED': {
    id: 'RENTAL_REQUEST@APARTMENT_INQUIRED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('RENTAL_REQUEST@NEW++'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.RENTAL_REQUEST@APARTMENT_INQUIRED.name') },
    title(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@APARTMENT_INQUIRED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@APARTMENT_INQUIRED.description', context) },
    link(context = {}) { return `/rental-requests-management/${context.sku}` },
  },
  'RENTAL_REQUEST@INQUIRE_APPROVED': {
    id: 'RENTAL_REQUEST@INQUIRE_APPROVED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('RENTAL_REQUEST@NEW++'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.RENTAL_REQUEST@INQUIRE_APPROVED.name') },
    title(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@INQUIRE_APPROVED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@INQUIRE_APPROVED.description', context) },
    link(context = {}) { return `/rental-requests-management/${context.sku}` },
  },
  'RENTAL_REQUEST@INQUIRE_REJECTED': {
    id: 'RENTAL_REQUEST@INQUIRE_REJECTED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('RENTAL_REQUEST@NEW++'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.RENTAL_REQUEST@INQUIRE_REJECTED.name') },
    title(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@INQUIRE_REJECTED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@INQUIRE_REJECTED.description', context) },
    link(context = {}) { return `/rental-requests-management/${context.sku}` },
  },
  'RENTAL_REQUEST@CONTRACT_REQUEST': {
    id: 'RENTAL_REQUEST@CONTRACT_REQUEST',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('RENTAL_REQUEST@NEW++'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.RENTAL_REQUEST@CONTRACT_REQUEST.name') },
    title(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@CONTRACT_REQUEST.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@CONTRACT_REQUEST.description', context) },
    link(context = {}) { return `/rental-requests-management/${context.sku}` },
  },
  'RENTAL_REQUEST@CONTRACT_CREATED': {
    id: 'RENTAL_REQUEST@CONTRACT_CREATED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('RENTAL_REQUEST@NEW++'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.RENTAL_REQUEST@CONTRACT_CREATED.name') },
    title(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@CONTRACT_CREATED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@CONTRACT_CREATED.description', context) },
    link(context = {}) { return `/rental-requests-management/${context.sku}` },
  },
  'RENTAL_REQUEST@CLOSED': {
    id: 'RENTAL_REQUEST@CLOSED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('RENTAL_REQUEST@NEW++'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.RENTAL_REQUEST@CLOSED.name') },
    title(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@CLOSED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@CLOSED.description', context) },
    link(context = {}) { return `/rental-requests-management/${context.sku}` },
  },

  // contract 

  'CONTRACT@NEW': {
    id: 'CONTRACT@NEW',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@NEW.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@NEW.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@NEW.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@EDITED': {
    id: 'CONTRACT@EDITED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@EDITED.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@EDITED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@EDITED.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@SET_DATES': {
    id: 'CONTRACT@SET_DATES',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@SET_DATES.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@SET_DATES.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@SET_DATES.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@LANDLORD_SIGN': {
    id: 'CONTRACT@LANDLORD_SIGN',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@LANDLORD_SIGN.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@LANDLORD_SIGN.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@LANDLORD_SIGN.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@TENANT_SIGN': {
    id: 'CONTRACT@TENANT_SIGN',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@TENANT_SIGN.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@TENANT_SIGN.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@TENANT_SIGN.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@EXTENSION_REQEST_NEW': {
    id: 'CONTRACT@EXTENSION_REQEST_NEW',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@EXTENSION_REQEST_NEW.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@EXTENSION_REQEST_NEW.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@EXTENSION_REQEST_NEW.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@TERMINATION_REQUEST_NEW': {
    id: 'CONTRACT@TERMINATION_REQUEST_NEW',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@TERMINATION_REQUEST_NEW.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@TERMINATION_REQUEST_NEW.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@TERMINATION_REQUEST_NEW.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@EXTENSION_REQEST_ACCEPTED': {
    id: 'CONTRACT@EXTENSION_REQEST_ACCEPTED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@EXTENSION_REQEST_ACCEPTED.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@EXTENSION_REQEST_ACCEPTED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@EXTENSION_REQEST_ACCEPTED.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@EXTENSION_REQEST_REJECTED': {
    id: 'CONTRACT@EXTENSION_REQEST_REJECTED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@EXTENSION_REQEST_REJECTED.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@EXTENSION_REQEST_REJECTED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@EXTENSION_REQEST_REJECTED.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@TERMINATION_REQEST_ACCEPTED': {
    id: 'CONTRACT@TERMINATION_REQEST_ACCEPTED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@TERMINATION_REQEST_ACCEPTED.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@TERMINATION_REQEST_ACCEPTED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@TERMINATION_REQEST_ACCEPTED.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@TERMINATION_REQEST_REJECTED': {
    id: 'CONTRACT@TERMINATION_REQEST_REJECTED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@TERMINATION_REQEST_REJECTED.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@TERMINATION_REQEST_REJECTED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@TERMINATION_REQEST_REJECTED.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@TRANSACTION_REQUEST_NEW': {
    id: 'CONTRACT@TRANSACTION_REQUEST_NEW',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@TRANSACTION_REQUEST_NEW.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@TRANSACTION_REQUEST_NEW.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@TRANSACTION_REQUEST_NEW.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@TRANSACTION_REQUEST_ACCEPTED': {
    id: 'CONTRACT@TRANSACTION_REQUEST_ACCEPTED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@TRANSACTION_REQUEST_ACCEPTED.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@TRANSACTION_REQUEST_ACCEPTED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@TRANSACTION_REQUEST_ACCEPTED.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@TRANSACTION_REQUEST_REJECTED': {
    id: 'CONTRACT@TRANSACTION_REQUEST_REJECTED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@TRANSACTION_REQUEST_REJECTED.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@TRANSACTION_REQUEST_REJECTED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@TRANSACTION_REQUEST_REJECTED.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@EXPIRE_SOON': {
    id: 'CONTRACT@EXPIRE_SOON',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@EXPIRE_SOON.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@EXPIRE_SOON.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@EXPIRE_SOON.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },
  'CONTRACT@CLOSED': {
    id: 'CONTRACT@CLOSED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('CONTRACT@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.CONTRACT@CLOSED.name') },
    title(context = {}) { return i18next.t('notification-center.types.CONTRACT@CLOSED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.CONTRACT@CLOSED.description', context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  },

  'SERVICE_REQUESTS@NEW': {
    id: 'SERVICE_REQUESTS@NEW',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('SERVICE_REQUESTS@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.SERVICE_REQUESTS@NEW.name') },
    title(context = {}) { return i18next.t('notification-center.types.SERVICE_REQUESTS@NEW.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.SERVICE_REQUESTS@NEW.description', context) },
    link(context = {}) { return `/service-requests/${context.sku}` },
  },
  'SERVICE_REQUESTS@EDITED': {
    id: 'SERVICE_REQUESTS@EDITED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('SERVICE_REQUESTS@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.SERVICE_REQUESTS@EDITED.name') },
    title(context = {}) { return i18next.t('notification-center.types.SERVICE_REQUESTS@EDITED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.SERVICE_REQUESTS@EDITED.description', context) },
    link(context = {}) { return `/rservice-requests/${context.sku}` },
  },
  'SERVICE_REQUESTS@FILE_UPLOADED': {
    id: 'SERVICE_REQUESTS@FILE_UPLOADED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('SERVICE_REQUESTS@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.SERVICE_REQUESTS@FILE_UPLOADED.name') },
    title(context = {}) { return i18next.t('notification-center.types.SERVICE_REQUESTS@FILE_UPLOADED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.SERVICE_REQUESTS@FILE_UPLOADED.description', context) },
    link(context = {}) { return `/rservice-requests/${context.sku}` },
  },
  'SERVICE_REQUESTS@COMPLETED': {
    id: 'SERVICE_REQUESTS@COMPLETED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('SERVICE_REQUESTS@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.SERVICE_REQUESTS@COMPLETED.name') },
    title(context = {}) { return i18next.t('notification-center.types.SERVICE_REQUESTS@COMPLETED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.SERVICE_REQUESTS@COMPLETED.description', context) },
    link(context = {}) { return `/rservice-requests/${context.sku}` },
  },
  'SERVICE_REQUESTS@CLOSED': {
    id: 'SERVICE_REQUESTS@CLOSED',
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc('SERVICE_REQUESTS@NEW'),
    icon: MessageOutlined,
    get name() { return i18next.t('notification-center.types.SERVICE_REQUESTS@CLOSED.name') },
    title(context = {}) { return i18next.t('notification-center.types.SERVICE_REQUESTS@CLOSED.title', context) },
    description(context = {}) { return i18next.t('notification-center.types.SERVICE_REQUESTS@CLOSED.description', context) },
    link(context = {}) { return `/rservice-requests/${context.sku}` },
  },

  // 'RENTAL_REQUEST@SALES_ADDED_APARTMENTS': {
  //   id: 'RENTAL_REQUEST@SALES_ADDED_APARTMENTS',
  //   color: 'rgba(0, 0, 0, 0.4)',
  //   backgroundColor: stc('RENTAL_REQUEST@SALES_ADDED_APARTMENTS'),
  //   icon: MessageOutlined,
  //   get name() { return i18next.t('notification-center.types.RENTAL_REQUEST@SALES_ADDED_APARTMENTS.name') },
  //   title(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@SALES_ADDED_APARTMENTS.title', context) },
  //   description(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@SALES_ADDED_APARTMENTS.description', context) },
  //   link(context = {}) { return `/rental-requests-management/${context.sku}` },
  // },

  // 'RENTAL_REQUEST@TENANT_RESPONDED': {
  //   id: 'RENTAL_REQUEST@TENANT_RESPONDED',
  //   color: 'rgba(0, 0, 0, 0.4)',
  //   backgroundColor: stc('RENTAL_REQUEST@TENANT_RESPONDED+'),
  //   icon: MessageOutlined,
  //   get name() { return i18next.t('notification-center.types.RENTAL_REQUEST@TENANT_RESPONDED.name') },
  //   title(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@TENANT_RESPONDED.title', context) },
  //   description(context = {}) { return i18next.t('notification-center.types.RENTAL_REQUEST@TENANT_RESPONDED.description', context) },
  //   link(context = {}) { return `/rental-requests-management/${context.sku}` },
  // },

  ...(Object.fromEntries([
    'RENTAL_CONTRACT@TYPE_1_STATUS_1',
    'RENTAL_CONTRACT@TYPE_2_STATUS_1',
    'RENTAL_CONTRACT@TYPE_3_STATUS_1',
    'RENTAL_CONTRACT@TYPE_4_STATUS_0',
    'RENTAL_CONTRACT@TYPE_4_STATUS_1',
    'RENTAL_CONTRACT@TYPE_4_STATUS_2',
    'RENTAL_CONTRACT@TYPE_5_STATUS_0',
    'RENTAL_CONTRACT@TYPE_5_STATUS_1',
    'RENTAL_CONTRACT@TYPE_5_STATUS_2',
  ].map(id => ([id, {
    id: id,
    color: 'rgba(0, 0, 0, 0.4)',
    backgroundColor: stc(`${id}++`),
    icon: Book,
    get name() { return i18next.t(`notification-center.types.${id}.name`) },
    title(context = {}) { return i18next.t(`notification-center.types.${id}.title`, context) },
    description(context = {}) { return i18next.t(`notification-center.types.${id}.description`, context) },
    link(context = {}) { return `/rental-contracts-management/${context.sku}` },
  }]))))

}

export const PROJECT_STATUS = {
  0: {
    id: 'new',
    get title() { return i18next.t('contract.statuses.new.title') },
    color: 'blue',
    alertType: 'info',
  },
  1: {
    id: 'ongoing',
    get title() { return i18next.t('contract.statuses.ongoing.title') },
    color: 'green',
    alertType: 'success',
  },
  2: {
    id: 'closed',
    get title() { return i18next.t('contract.statuses.closed.title') },
    color: 'red',
    alertType: 'error',
  },
}

export const detectCurrentProjectStatus = (project) => {
  const statuses = Object.values(PROJECT_STATUS).reverse()
  // eslint-disable-next-line no-restricted-syntax
  // console.log(project)
  for (const status of statuses) {
    if (status.is(project)) {
      return status
    }
  }
  return PROJECT_STATUS.new
}
