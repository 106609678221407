import { Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const vatVariations = {
  /** Default */
  DEFAULT: [
    {
      value: 0,
      title: '0%',
    },
  ],
  /** Germany */
  DE: [
    {
      value: 0.19,
      title: '19%',
    },
    {
      value: 0.07,
      title: '7%',
    },
  ],
  /** Switzerland */
  CH: [
    {
      value: 0.037,
      title: '3.7%',
    },
    {
      value: 0.077,
      title: '7.7%',
    },
  ],
  /** Austria */
  AT: [
    {
      value: 0.1,
      title: '10%',
    },
  ],
}

/** Список Select */
export const SelectVAT = (options = {}) => {
  const { t } = useTranslation()
  const value = options.country === undefined ? 0 : options.value
  const rates = [
    ...vatVariations.DEFAULT,
    ...(options?.country in vatVariations ? vatVariations[options.country] : []),
  ]

  /** Сброс недоступного значения */
  if (options.onChange && value !== undefined && !rates.some(((rate) => rate.value === value))) {
    options.onChange({
      target: { value: undefined },
    })
  }

  return (
    <Select
      style={{ width: '100%' }}
      placeholder={t('vat-picker.placeholder')}
      {...options}
      value={value}
    >
      {rates.map((rate) => (
        <Select.Option value={rate.value} label={rate.title} key={rate.value}>
          {rate.title}
        </Select.Option>
      ))}
    </Select>
  )
}
