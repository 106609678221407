import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { useProject } from '../common/ProjectProvider'

export const INITIAL_ANIMATION_DELAY = 0.7

export default function BaseView({ children }) {
  const { i18n } = useTranslation()
  const project = useProject()
  const [state] = useGlobalState()
  const layoutType = state.loggedIn ? 'dashboard' : 'auth'

  return (
    <>
      {/* SEO */}
      <Helmet>
        <title>{project.title}</title>
        <link rel="shortcut icon" href={`/favicon.${project.slug}.ico`} />
        <link rel="manifest" href={`/manifest.${project.slug}.json`} />
        <meta name="theme-color" content={project.primaryColor} />
      </Helmet>

      <AnimatePresence exitBeforeEnter initial>
        <motion.div
          initial="initial"
          animate="enter"
          exit="exit"
          variants={{
            initial: { opacity: 0 },
            enter: { opacity: 1, transition: { delay: 0.3, duration: 0.5, ease: [0.48, 0.15, 0.25, 0.96] } },
            exit: { opacity: 0, transition: { duration: 0.2, ease: [0.48, 0.15, 0.25, 0.96] } },
          }}
          key={layoutType + i18n.language}
          className={`project--${project.slug.replace('@', '')}`}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </>
  )
}
