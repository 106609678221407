import { PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Filter } from './Filter'

/**
 * Шапка (маленький)
 */
export const SimpleHeader = ({ managerSlug, title, actions, filter, renderMode, setRenderMode, canChoseTilesRenderMode, searchValue, setSearchValue, filterValues, setFilterValues, sorting, sortingValue, setSortingValue }) => {
  const { t } = useTranslation()
  const mainTitle = title?.title ?? title
  const subTitle = title?.subTitle ?? ''

  return (
    <PageHeader
      ghost={false}
      title={mainTitle}
      subTitle={subTitle}
    >
      <Filter {...{ managerSlug, filter, searchValue, setSearchValue, filterValues, setFilterValues, sorting, sortingValue, setSortingValue }} />
    </PageHeader>
  )
}
