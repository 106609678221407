import { colors } from '_colors'
import { PushpinTwoTone } from '@ant-design/icons'
import { Card } from 'antd'
import styled from 'styled-components'

export const AptWrapper = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
  h2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
  }
  p {
    font-size: 14px;
    line-height: 30px;
    margin: 0;
  }
  .total-wrapper {
    display: flex;
    align-items: center;
  }
  .total {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-left: 15px;
  }
`

export const OriginalItem = styled.div`
  background-image: url(${(p) => p.args.original});
  width: 100%;
  height: 500px;
  background-position: center;
  background-size: cover;
  border-radius: 7px;
`
export const ThumbItem = styled.div`
  background-image: url(${(p) => p.args.original});
  width: 100%;
  height: 80px;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  border-radius: 12px;
  border: solid 5px white;
  transition: border-color .3s ease-in-out;
  :hover {
    border: solid 5px ${colors.main(0.3)};
  }
`

export const AmenityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 40px;
  .icon {
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
    background-image: url(${(p) => p.icon});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    filter: ${(p) => (p.isSelected ? 'grayscale(1)' : 'grayscale(0)')};
  }
`

export const EditPart = styled.div`
  border: dashed 1px transparent;
  padding: 5px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
  .anticon-edit {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  :hover {
    border: dashed 1px ${colors.text()};
    .anticon-edit {
      opacity: 1;
    }
  }
  ${(p) => p.inEditMode
    && `
    border: dashed 1px ${colors.text()};
    .anticon-edit {
      display: none;
    }
  `}
`

export const StyledCard = styled(Card)`
  h2 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    padding: 0;
  }

  .amenities {
    margin-top: 16px;
  }

  span.no-data {
    color: ${colors.main()}
  }
`

export const StyledPin = styled(PushpinTwoTone)`
  font-size: ${(p) => (p.hovered === 'true' ? '50px' : '30px')};
  opacity: ${(p) => (p.hovered === 'true' ? 1 : p.hashovered === 'true' ? 0 : 1)};
  transform: translate(0%, -100%);
  transition: font-size .17s ease-in-out, opacity .17s linear;
`

export const MapIcon = styled.div`
  padding-top: calc(50vh - 50px);
  color: #fff;
  text-align: center;

  svg {
    font-size: 100px;
    filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 0.1));
  }
`

export const MapOverlayToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  cursor: pointer;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.08);
  left: 4px;
  top: calc(50% - calc(35px / 2));
  font-size: 24px;
  width: 25px;
  height: 35px;
  border-radius: 6px;
  z-index: 2;
`

export const MapOverlayToggleOverlay = styled.div`
  display: none;
  [data-toggle="true"] & {
    display: block;
    content: "";
    position: absolute;
    border-radius: 6px;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`

export const MapOverlay = styled.div`
  --filter: none;
  --right: 25px;

  background-color: #e5e3df;
  position: fixed;
  bottom: 25px; 
  right: var(--right); 
  z-index: 3;
  width: 350px; 
  height: 210px; 
  border-radius: 6px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.11), 0 3px 4px rgba(0, 0, 0, 0.08);
  transition: right .17s ease-in-out;

  &[data-toggle="true"] {
    --filter: blur(5px);
    --right: -316px;

    &:hover {
      --right: -310px;
    }
  }

  &[data-hidden="true"] {
    --filter: blur(5px);
    --right: -380px;
  }

  .map-widget {
    transition: filter 200ms ease-in-out;
    filter: var(--filter);
    overflow: hidden;
    border-radius: 6px;
  }
`
