import { gql } from 'apollo-boost'

import { apartmentFragment, attachmentFragment, buildingFragment, invoiceFragment, photoFragment, profileFragment, projectFragment, rentalContractFragment, rentalRequestFragment, serviceFragment, serviceRequestFragment,transactionFragment, userFragment, wbsFragment } from '../_fragments'

export const RENTAL_CONTRACTS_LIST = gql`
  query listRentalContracts($page: Int!, $pageSize: Int!, $sorting: String, $search: String, $filter: JSON) {
    response: listRentalContracts(page: $page, pageSize: $pageSize, sorting: $sorting, search: $search, filter: $filter) {
      list {
        ...rentalContractFragment
        tenant {
          ...userFragment
          owner {
            ...userFragment
          }
        }
        tenantProfile {
          ...profileFragment
        }
        sales {
          ...userFragment
          profiles {
            ...profileFragment
          }
        }
        landlord {
          ...userFragment
          profiles {
            ...profileFragment
          }
        }
        rentalRequest {
          ...rentalRequestFragment
        }
        apartment {
          ...apartmentFragment
        }
        actions {
          id
          context
          userId
        }
        services {
            ...serviceFragment
        }
        project {
          ...projectFragment
          owner {
            ...userFragment
          }
          manager {
            ...userFragment
          }
        }
        invoices {
          ...invoiceFragment
        }
        transactions {
          ...transactionFragment
        }
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${rentalContractFragment}
  ${apartmentFragment}
  ${userFragment}
  ${rentalRequestFragment}
  ${profileFragment}
  ${projectFragment}
  ${transactionFragment}
  ${invoiceFragment}
  ${serviceFragment}

`

export const ALL_RENTAL_CONTRACTS_QUERY = gql`
  query allRentalContracts {
    response: allRentalContracts {
      ...rentalContractFragment
      tenant {
        ...userFragment
      }
      sales {
        ...userFragment
      }
      landlord {
        ...userFragment
        profiles {
          ...profileFragment
        }
      }
      rentalRequest {
        ...rentalRequestFragment
      }
      apartment {
        ...apartmentFragment
      }
      actions {
        id
        context
        userId
      }
    }
  }
  ${rentalContractFragment}
  ${apartmentFragment}
  ${userFragment}
  ${rentalRequestFragment}
  ${profileFragment}
`

// export const RENTAL_REQUEST_QUERY = gql`
//   query findRentalRequest($id: Int!) {
//     response: findRentalRequest(id: $id) {
//       ...rentalRequestFragment
//     }
//   }
//   ${rentalRequestFragment}
// `;

export const RENTAL_CONTRACT_SKU_QUERY = gql`
  query findRentalContractBySku($sku: String!) {
    response: findRentalContractBySku(sku: $sku) {
      ...rentalContractFragment
      statistics
      sales {
        ...userFragment
      }
      tenant {
        project {
          ...projectFragment
        }
        codes {
          ...wbsFragment
        }
        ...userFragment
        owner {
          ...userFragment
          profiles {
            ...profileFragment
          }
        }
      }
      tenantProfile {
        ...profileFragment
      }
      employees {
        status
        user {
          ...userFragment
        }
        completedAt
      }
      landlord {
        ...userFragment
        profiles {
          ...profileFragment
        }
      }
      actions {
        id
        context
        userId
        user {
          lastName
          firstName
        }
      }
      rentalRequest {
        ...rentalRequestFragment
      }
      apartment {
        ...apartmentFragment
        photos {
          ...photoFragment
        }
        building {
          id
          addressDetails
          profile {
            ...profileFragment
          }
        }
      }
      attachments {
        ...attachmentFragment
      }
      project {
        ...projectFragment
        owner {
          ...userFragment
        }
        manager {
          ...userFragment
        }
      }
      wbs {
        ...wbsFragment
      }
      invoices {
        ...invoiceFragment
      }
      transactions {
        ...transactionFragment
      }
      requests {
        ...serviceRequestFragment
      }
    }
  }
  ${rentalContractFragment}
  ${apartmentFragment}
  ${photoFragment}
  ${userFragment}
  ${profileFragment}
  ${attachmentFragment}
  ${rentalRequestFragment}
  ${projectFragment}
  ${wbsFragment}
  ${transactionFragment}
  ${invoiceFragment}
  ${serviceRequestFragment}
`

// export const SEARCH_PROPERTY_QUERY = gql`
//   query searchProperty($search: String!) {
//     response: searchProperty(search: $search) {
//       ...buildingFragment
//       apartments {
//         ...apartmentFragment
//         photos {
//           ...photoFragment
//         }
//         building {
//           id
//           addressDetails
//         }
//       }
//     }
//   }
//   ${buildingFragment}
//   ${apartmentFragment}
//   ${photoFragment}
// `;

export const SUBSCRIBE_RENTAL_CONTRACT = gql`
  subscription contractChanged($id: Int!)  {
    response: contractChanged(id: $id) {
      ...rentalContractFragment
      sales {
        ...userFragment
      }
      tenant {
        ...userFragment
        codes {
          ...wbsFragment
        }
        project {
          ...projectFragment
        }
        owner {
          ...userFragment
          profiles {
            ...profileFragment
          }
        }
      }
      tenantProfile {
        ...profileFragment
      }
      employees {
        status
        user {
          ...userFragment
        }
      }
      landlord {
        ...userFragment
        profiles {
          ...profileFragment
        }
      }
      actions {
        id
        context
        userId
        user {
          lastName
          firstName
        }
      }
      rentalRequest {
        ...rentalRequestFragment
      }
      apartment {
        ...apartmentFragment
        photos {
          ...photoFragment
        }
        building {
          id
          addressDetails
          profile {
            ...profileFragment
          }
        }
      }
      attachments {
        ...attachmentFragment
      }
      project {
        ...projectFragment
        owner {
          ...userFragment
        }
        manager {
          ...userFragment
        }
      }
      wbs {
        ...wbsFragment
      }
      invoices {
        ...invoiceFragment
      }
      transactions {
        ...transactionFragment
      }
      requests {
        ...serviceRequestFragment
      }
    }
  }

  ${rentalContractFragment}
  ${apartmentFragment}
  ${photoFragment}
  ${userFragment}
  ${profileFragment}
  ${attachmentFragment}
  ${rentalRequestFragment}
  ${projectFragment}
  ${wbsFragment}
  ${transactionFragment}
  ${invoiceFragment}
  ${serviceRequestFragment}
`


export const MAKE_CONTRACT_SERVICE = gql`
  query makeContractServices {
    response: makeContractServices
  }
`
export const MAKE_CLEANUP = gql`
  query makeCleanup {
    response: makeCleanup
  }
`
export const MAKE_SIMULATION = gql`
  query simulateAccounting {
    response: simulateAccounting
  }
`

export const FIX_APARTMENTS_AVAILABILITY = gql`
  query fixApartmentsAvailability {
    response: fixApartmentsAvailability 
  }
`

export const PREPARE_APARTMENTS = gql`
  query prepareContracts {
    response: prepareContracts 
  }
`

export const MAKE_BR_SIMULATION = gql`
  query simulateBillRuns {
    response: simulateBillRuns
  }
`


export const FIX_TRANSACTIONS = gql`
  query fixTransactions {
    response: fixTransactions
  }
`

export const SIM_PUSH = gql`
  query testNotification {
    response: testNotification
  }
`

export const SIM_EMAIL = gql`
  query testMail {
    response: testMail
  }
`

export const SIM_ALL = gql`
  query simulateAll {
    response: simulateAll {
      type
      id
    }
  }
`
export const FIX_DB = gql`
  query dbFix {
    response: dbFix {
      response
      index
      }
  }
`

export const TESTING_CLEANUP = gql`
  query testingCleanup {
    response: testingCleanup {
      type
      volume  
    }
  }
`