import { gql } from 'apollo-boost'

import { apartmentFragment, buildingFragment, photoFragment, profileFragment, userFragment } from '../_fragments'

export const BUILDINGS_LIST = gql`
    query listBuildings($page: Int!, $pageSize: Int!, $sorting: String, $search: String, $filter: JSON) {
      response: listBuildings(page: $page, pageSize: $pageSize, sorting: $sorting, search: $search, filter: $filter) {
        list {
          ...buildingFragment
          profile {
            ...profileFragment
          }
          user {
            ...userFragment
            profiles {
              ...profileFragment
            }
          }
          photos {
            ...photoFragment
          }
          apartments {
            ...apartmentFragment
          }
        }
        pagination {
          page
          pageSize
          total
        }  
      }
  }
  ${buildingFragment}
  ${photoFragment}
  ${apartmentFragment}
  ${profileFragment}
  ${userFragment}
`

export const ALL_BUILDINGS_QUERY = gql`
  query allBuildings {
    response: allBuildings {
        ...buildingFragment
        photos {
          ...photoFragment
        }
        apartments {
          ...apartmentFragment
        }
      }
  }
  ${buildingFragment}
  ${photoFragment}
  ${apartmentFragment}
`
export const ALL_BUILDINGS_QUERY_PAGINATED = gql`
    query allBuildingsPages($page: Int!, $pageSize: Int!) {
      response: allBuildingsPages(page: $page, pageSize: $pageSize) {
        list {
          ...buildingFragment
          profile {
            ...profileFragment
          }
          user {
            ...userFragment
          }
          photos {
            ...photoFragment
          }
          apartments {
            ...apartmentFragment
          }
        }
        meta {
          page
          pageSize
          total
        }  
      }
  }
  ${buildingFragment}
  ${photoFragment}
  ${apartmentFragment}
  ${profileFragment}
  ${userFragment}
`

export const BUILDING_QUERY = gql`
  query fetchBuilding($id: Int!) {
    response: fetchBuilding(id: $id) {
        ...buildingFragment
        photos {
          ...photoFragment
        }
        apartments {
          ...apartmentFragment
          photos {
            ...photoFragment
          }
          building {
            id
            addressDetails
          }
        }

        profile {
          ...profileFragment
        }
    }
  }
  ${buildingFragment}
  ${photoFragment}
  ${apartmentFragment}
  ${profileFragment}
`
