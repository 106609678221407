import { Form,Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ExtrasEdit = () => (
  <Form.Item label="Extras" name="extras">
    <Input.TextArea autoSize={{ minRows: 3 }} />
  </Form.Item>
)

const ExtrasView = ({ data }) => {
  const { t } = useTranslation()
  return (
    <>
      <h2>{t('apartments.sections.extras')}</h2>
      {data?.extras.split('\n').map((d, i) => (
        d !== '' && (
          <>
            <p style={{ lineHeight: '25px'}}>{d}</p>
            <br />
          </>
        )
      ))}
    </>
  )
}

export { ExtrasEdit, ExtrasView }
