import { ALL_USERS_BY_ROLE_QUERY, BUILDING_UPSERT_MUTATION, USER_QUERY } from '_graphql'
import { roles } from '_graphql/_enums'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { Collapse, Divider, message,Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { ProfileInfoCard } from '../BuildingDetails/ProfileInfoCard'
import { ContextContainer } from './styled'

const BuildingContext = ({ building }) => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()

  const [currentUser, setCurrentUser] = useState(state.currentUser)
  const [firstRender, setFirstRender] = useState(state.currentUser)

  const { data: usersData, loading: usersLoading } = useQuery(ALL_USERS_BY_ROLE_QUERY, {
    variables: {
      roles: [roles.LANDLORD_OWNER],
    },
  })
  const [getUser, { data: userData }] = useLazyQuery(USER_QUERY, {
    onCompleted: (d) => {
      setCurrentUser(d.response)
    },
  })
  const [upsertBuilding] = useMutation(BUILDING_UPSERT_MUTATION)

  useEffect(() => {
    if (usersData?.response) {
      const currentUserTemp = usersData.response.find((user) => user.id === building.userId)
      setCurrentUser(currentUserTemp)
    }
  }, [usersData])

  useEffect(() => {
    if (currentUser) {
      getUser({ variables: { id: currentUser.id } })

      if (!firstRender) {
        message.success(`${t('buildings.admin.messageSuccess')} ${currentUser.firstName} ${currentUser.lastName}`)
      }
    }
  }, [currentUser])

  const applyUserId = async (userId) => {
    await upsertBuilding({
      variables: {
        input: {
          id: building.id,
          userId,
          profileId: null,
        },
      },
    })

    dispatch({ type: 'REFETCH', payload: 'BUILDING_QUERY' })

    setFirstRender(false)
    getUser({ variables: { id: userId } })

    // if (upsertion?.data?.response) {
    //   setTimeout(() => {

    //   }, 1000);
    // }
  }

  const profiles = userData?.response?.profiles || []

  return (
    <ContextContainer>
      <h2>{t('buildings.admin.options')}</h2>
      <Divider />

      {state.currentUser?.role !== roles.LANDLORD_OWNER
        && (
          <Collapse accordion>
            <Collapse.Panel header={t('buildings.admin.info')} key="Landlord Info">
              <Select style={{ width: '100%' }} onChange={applyUserId}>
                <Select.Option value={null} key={987654378}>{t('buildings.admin.assign')}</Select.Option>
                {!usersLoading && usersData.response.map((user, index) => (
                  <Select.Option value={user.id} key={index}>
                    {user.firstName}
                    {' '}
                    {user.lastName}
                    {' '}
                    (ID:
                    {' '}
                    {user.id}
                    )
                  </Select.Option>
                ))}
              </Select>

              <Divider />

              <h1>
                {t('buildings.admin.profiles')}
                {' '}
                {profiles.length || 0}
              </h1>

              {profiles.map((profile, index) => (
                <ProfileInfoCard withTitle hoverable bordered profile={profile} key={index} />
              ))}
            </Collapse.Panel>
          </Collapse>
        )}
    </ContextContainer>
  )
}

export { BuildingContext }
