import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import i18next from 'i18next'
import React from 'react'

export const SearchForm = ({ searchValue, setSearchValue }) => (
  <Input
    onChange={(e) => setSearchValue(e.target.value)}
    value={searchValue}
    placeholder={i18next.t('list-manager.filters.search-placeholder')}
    prefix={<SearchOutlined className="list-manager-search__icon" />}
    role="search"
    allowClear
  />
)
