import { NOTIFICATIONS_LIST, NOTIFICATIONS_STATISTICS } from '_graphql'
import { isTenant,NOTIFICATION_GROUPS, NOTIFICATION_TYPES, roles } from '_graphql/_enums'
import BagPersonalOutline from '@2fd/ant-design-icons/lib/BagPersonalOutline'
import BedKingOutline from '@2fd/ant-design-icons/lib/BedKingOutline'
import Book from '@2fd/ant-design-icons/lib/Book'
import BookClock from '@2fd/ant-design-icons/lib/BookClock'
import BookMinus from '@2fd/ant-design-icons/lib/BookMinus'
import ChevronRight from '@2fd/ant-design-icons/lib/ChevronRight'
import Cog from '@2fd/ant-design-icons/lib/Cog'
import ExitRun from '@2fd/ant-design-icons/lib/ExitRun'
import Fireplace from '@2fd/ant-design-icons/lib/Fireplace'
import SlashForward from '@2fd/ant-design-icons/lib/SlashForward'
import TransitTransfer from '@2fd/ant-design-icons/lib/TransitTransfer'
import { BankOutlined, ContainerOutlined, DesktopOutlined, EnvironmentOutlined, EuroCircleOutlined, FileAddOutlined, FormatPainterOutlined, HomeFilled, ToolOutlined } from '@ant-design/icons'
import { ProCard, StatisticCard } from '@ant-design/pro-components'
import { useQuery } from '@apollo/react-hooks'
import { Attachment } from '@material-ui/icons'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { Link } from '@reach/router'
import { Button, Card, Col, Empty, Row, Space, Typography } from 'antd'
import Axios from 'axios'
import { CounterCard } from 'components/DashboardWidgets/CounterCard'
import { FancyCard } from 'components/DashboardWidgets/FancyCard'
import { LayoutCard } from 'components/Layout'
import { cost } from 'components/ListManager/DefaultTypes'
import uniqBy from 'lodash/uniqBy'
import { DateTime } from 'luxon'
import moment from 'moment'
import numbro from 'numbro'
import RcResizeObserver from 'rc-resize-observer'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import stc from 'string-to-color'

const { Statistic } = StatisticCard

import styles from './style.less'

const imgStyle = {
  display: 'block',
  width: 42,
  height: 42,
}

const TenantsDashboard = ({
  data,
}) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const [responsive, setResponsive] = useState(false)
  const role = state.currentUser?.role


  const domain = window.location.href
  console.log(domain)


  const counters = Object.fromEntries(data || [])

  // console.log(counters)
  const totalOfRent = (counters?.['total-of-rents'] || []).map?.(([date, values]) => ([
  ]))?.flat?.() || []

  // console.log(counters['invoices-history-global'])

  const colors = { 'Rent': 'rgb(29, 195, 131)', 'Utilities': 'hsl(97, 70%, 50%)', 'Services': 'green' }
  const getColor = bar => colors[bar.id]
  
  return (
    <>

      <Row gutter={[20, 20]}>
        <Col span={6}>
          <LayoutCard> 

            <StatisticCard
              colSpan={responsive ? 24 : 6}
              title={t('dashboard.counters.vauchers')}
              statistic={{
                value: counters['vauchers'],
                description: <Statistic title="Load" value={((counters['offered-apartments'] / counters['all-apartments']) * 100).toFixed(2) + '%'} />,
                icon: (
                  <div className={styles.counterIconT} style={{ '--color': '#1DC383' }}>
                    <HomeFilled />
                  </div>
                ),
              }}
            />
            
          </LayoutCard>
        </Col>
        <Col span={18}>
          <LayoutCard> 
            <RcResizeObserver
              key="resize-observer"
              onResize={(offset) => {
                setResponsive(offset.width < 596)
              }}
            >
              <StatisticCard.Group direction={responsive ? 'column' : 'row'}>
              
                <StatisticCard
                  title={t('dashboard.counters.active-rental-bookings')}
                  statistic={{
                    value: counters['active-rental-bookings'],
                    description: <Statistic title="Change" value={(100-(counters['active-rental-bookings'] / counters['last-rental-bookings']) * 100).toFixed(1)+'%'} trend="up" />,
                    icon: (
                      <div className={styles.counterIconT} style={{ '--color': '#1DC383' }}>
                        <ContainerOutlined />
                      </div>
                    ),
                  }}
                />
                <StatisticCard
                  title={t('dashboard.counters.moveIn')}
                  statistic={{
                    value: counters['moveIn-current-month'],
                    // description: <Statistic title="Change" value="10%" trend="up" />,
                    icon: (
                      <div className={styles.counterIconT} style={{ '--color': '#1DC383' }}>
                        <EnvironmentOutlined />
                      </div>
                    ),
                  }}
                />
                <StatisticCard
                  title={t('dashboard.counters.changeOfUser')}
                  statistic={{
                    value: counters['cou-current-month'],
                    // description: <Statistic title="Change" value="10%" trend="up" />,
                    icon: (
                      <div className={styles.counterIconT} style={{ '--color': '#1DC383' }}>
                        <TransitTransfer />
                      </div>
                    ),
                  }}
                />
                <StatisticCard
                  title={t('dashboard.counters.moveOut')}
                  statistic={{
                    value: counters['moveOut-current-month'],
                    // description: <Statistic title="Change" value="10%" trend="up" />,
                    icon: (
                      <div className={styles.counterIconT} style={{ '--color': '#1DC383' }}>
                        <ExitRun />
                      </div>
                    ),
                  }}
                />
              </StatisticCard.Group>
            </RcResizeObserver>
          </LayoutCard>
        </Col>
      </Row>
      

      <Row gutter={[20, 20]}>
        <Col span={18}>
          <LayoutCard> 
            <RcResizeObserver
              key="resize-observer"
              onResize={(offset) => {
                setResponsive(offset.width < 596)
              }}
            >
              <StatisticCard.Group direction={responsive ? 'column' : 'row'}>
              
                <StatisticCard
                  title={t('dashboard.counters.services')}
                  statistic={{
                    value: (counters['damageReqs'] + counters['provReqs'] + counters['servicesReqs']),
                    icon: (
                      <div className={styles.counterIconT} style={{ '--color': '#1DC383' }}>
                        <FileAddOutlined />
                      </div>
                    ),
                  }}
                />
                <StatisticCard
                  title={t('dashboard.counters.serviceTypes.service')}
                  statistic={{
                    value: counters['servicesReqs'],
                    // description: <Statistic title="Change" value="10%" trend="up" />,
                    icon: (
                      <div className={styles.counterIconT} style={{ '--color': '#1DC383' }}>
                        <ToolOutlined/>
                      </div>
                    ),
                  }}
                />
                <StatisticCard
                  title={t('dashboard.counters.serviceTypes.provision')}
                  statistic={{
                    value: counters['provReqs'],
                    // description: <Statistic title="Change" value="10%" trend="up" />,
                    icon: (
                      <div className={styles.counterIconT} style={{ '--color': '#1DC383' }}>
                        <DesktopOutlined />
                      </div>
                    ),
                  }}
                />
                <StatisticCard
                  title={t('dashboard.counters.serviceTypes.damage')}
                  statistic={{
                    value: counters['damageReqs'],
                    // description: <Statistic title="Change" value="10%" trend="up" />,
                    icon: (
                      <div className={styles.counterIconT} style={{ '--color': '#1DC383' }}>
                        <FormatPainterOutlined />
                      </div>
                    ),
                  }}
                />
              </StatisticCard.Group>
            </RcResizeObserver>
          </LayoutCard>
        </Col>
        <Col span={6}>
          <LayoutCard   style={{ backgroundColor: domain.includes('chairos.de') ? '#00BDDB' : '#1DC383'}}> 
            <StatisticCard
              colSpan={responsive ? 24 : 6}
              style={{ backgroundColor: domain.includes('chairos.de') ? '#00BDDB' : '#1DC383', color: '#fff !important' }}
              className="statCard"
              title={t('dashboard.counters.serviceTypes.fees')}
              statistic={{
                value: counters['invoices-rent-global'],
                prefix: '€',
                // description: <Statistic title="Change" value="6.47%" trend="down" />,
                icon: (
                  <div className={styles.counterIcon} style={{ '--color': '#fff' }}>
                    <BankOutlined style={{ color: domain.includes('chairos.de') ? '#00BDDB' : '#1DC383' }}/>
                  </div>
                ),
              }}
            />

          </LayoutCard>
          {/* <LayoutCard> 
            <StatisticCard
              colSpan={responsive ? 24 : 6}
              title={t('dashboard.counters.services')}
              statistic={{
                value: counters['servicesReqs'] + counters['provReqs'] + counters['damageReqs'],
                icon: (
                  <div className={styles.counterIcon} style={{ '--color': '#1DC383' }}>
                    <Cog />
                  </div>
                ),
              }}
              footer={
                <>
                  <Statistic value={counters['servicesReqs']} title={t('dashboard.counters.serviceTypes.service')} layout="horizontal" />
                  <Statistic value={counters['provReqs']} title={t('dashboard.counters.serviceTypes.provision')} layout="horizontal" />
                  <Statistic value={counters['damageReqs']} title={t('dashboard.counters.serviceTypes.damage')} layout="horizontal" />
                    
                </>
              }
            />
          </LayoutCard> */}
        </Col>
      </Row>
    
  

      {/* Total of rent */}
      
    </>
  )
}

export { CounterCard, TenantsDashboard }
