import i18next from 'i18next'

export const roomsInitialState = {
  isStudio: false,
  bedroom: [
    {
      types: [
        {
          get label() { return i18next.t('buildings.fields.bedroom.singleBed') },
          value: 'singleBed',
        },
        {
          get label() { return i18next.t('buildings.fields.bedroom.doubleBed') },
          value: 'doubleBed',
        },
      ],
      amenities: [],
      selectedType: null,
      selectedAmenities: [],
      additional: [],
    },
  ],
  bathroom: [
    {
      types: [
        {
          get label() { return i18next.t('buildings.fields.bathroom.daylight_bath') },
          value: 'daylight_bath',
        },
        {
          get label() { return i18next.t('buildings.fields.bathroom.ventilation') },
          value: 'ventilation',
        },
      ],
      amenities: [
        {
          get label() { return i18next.t('buildings.fields.bathroom.toilet') },
          value: 'toilet',
        },
        {
          get label() { return i18next.t('buildings.fields.bathroom.shower') },
          value: 'shower',
        },
        {
          get label() { return i18next.t('buildings.fields.bathroom.bathtub') },
          value: 'bathtub',
        },
      ],
      selectedType: null,
      selectedAmenities: [],
      additional: [],
    },
  ],
  kitchen: [
    {
      types: [
        {
          get label() { return i18next.t('buildings.fields.kitchen.small') },
          value: 'small',
        },
        {
          get label() { return i18next.t('buildings.fields.kitchen.equipped') },
          value: 'equipped',
        },
      ],
      amenities: [
        {
          get label() { return i18next.t('buildings.fields.kitchen.fridge') },
          value: 'fridge',
        },
        {
          get label() { return i18next.t('buildings.fields.kitchen.freezer') },
          value: 'freezer',
        },
        {
          get label() { return i18next.t('buildings.fields.kitchen.dishwasher') },
          value: 'dishwasher',
        },
        {
          get label() { return i18next.t('buildings.fields.kitchen.extractor_hood') },
          value: 'extractor_hood',
        },
        {
          get label() { return i18next.t('buildings.fields.kitchen.cooktop') },
          value: 'cooktop',
        },
        {
          get label() { return i18next.t('buildings.fields.kitchen.oven') },
          value: 'oven',
        },
        {
          get label() { return i18next.t('buildings.fields.kitchen.microwave') },
          value: 'microwave',
        },
        {
          get label() { return i18next.t('buildings.fields.kitchen.water_heater') },
          value: 'water_heater',
        },
        {
          get label() { return i18next.t('buildings.fields.kitchen.coffee_maschine') },
          value: 'coffee_maschine',
        },
        {
          get label() { return i18next.t('buildings.fields.kitchen.toaster') },
          value: 'toaster',
        },
      ],
      selectedType: null,
      selectedAmenities: [],
      additional: [],
    },
  ],
  balkony: [],
  livingRoom: [],
  diningRoom: [],
  laundryRoom: [],
  workRoom: [],
  customRoom: [],

  customRoomObject: {
    title: '',
  },
}
