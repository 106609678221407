import { CONTRACT_STATUS, detectCurrentContractStatus, isAdmin, isLandlord, ROLE_GROUPS, roles, USER_ROLES } from '_graphql/_enums'
import { SERVICE_REQUESTS_LIST } from '_graphql/serviceRequest'
import AccountSupervisor from '@2fd/ant-design-icons/lib/AccountSupervisor'
import CheckboxMultipleBlankCircleOutline from '@2fd/ant-design-icons/lib/CheckboxMultipleBlankCircleOutline'
import EyeOutline from '@2fd/ant-design-icons/lib/EyeOutline'
import { BankOutlined, CrownOutlined, PlusOutlined,SortAscendingOutlined, SortDescendingOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { Link } from '@reach/router'
import { Button,Tag, Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import { ListManager } from 'components/ListManager'
import { string } from 'components/ListManager/DefaultTypes'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInterval } from 'react-use'
import { useGlobalState } from 'state'
import stc from 'string-to-color'
import { db } from 'utils/dexie'

import ServiceRequestView from './ServiceRequestView'

const filters = {
  all: {},
  new: { statuses: ['new'] },
}

const ServiceRequests = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)
  const isLandlordUser = isLandlord(state.currentUser?.role)
  const { openModal } = useModals()
  //   const isTenantUser = isTenant(state.currentUser?.role);
  return (
    <>
      <ListManager
        slug="serviceRequests-manager"
        title={t('serviceRequests.managerTitle')}
        query={SERVICE_REQUESTS_LIST}
        queryParams={ props.contractId }
        lastSeen="serviceRequestsLastSeen"
        actions={[
          ![roles.LANDLORD_OWNER, roles.LANDLORD_ACCOUNTANT, roles.LANDLORD_CONTENT, roles.LANDLORD_MANAGER].includes(state.currentUser?.role) ? (
            <Link 
              to="/service-requests/create" 
              key="create"
              onClick={(e) => {
                e.preventDefault()
                openModal(ServiceRequestView)
              }}
            >
              <Button type="primary" icon={<PlusOutlined />} htmlType="span">
                {t('serviceRequests.actions.createRequest')}
              </Button>
            </Link>) : null
        ]}
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('serviceRequests.sorting.id|desc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('serviceRequests.sorting.id|asc'),
              icon: <SortAscendingOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              slug: 'all',
              title: t('serviceRequests.filters.all'),
              value: {},
            },
            ...Object.keys(t('serviceRequests.stasuses', { returnObjects: true })).map(status => ({
              slug: status,
              title: t(`serviceRequests.stasuses.${status}`),
              value: { statuses: [status] }
            }))
          ],
          filters: [
            {
              slug: 'sales',
              title: t('contract.filters.sales'),
              icon: <BankOutlined />,
              middleware: isManagementUser,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: {
                  role: Object.values(USER_ROLES).filter((role) => ROLE_GROUPS.B2B.includes(role.id)).map(role => role.key)
                },
              },
            },
            {
              slug: 'types',
              title: t('service-requests.fields.type'),
              icon: <CheckboxMultipleBlankCircleOutline />,
              type: 'tags',
              options: {
                valuesPreset: 'serviceRequestTypes',
              },
            },
            {
              slug: 'requestCreator',
              title: t('service-requests.fields.requestCreator'),
              icon: <AccountSupervisor />,
              middleware: isManagementUser,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: {
                  role: [USER_ROLES['landlord-owner'].key, USER_ROLES['tentant-admin'].key]
                },
              },
            },
          ]
        }}
        table={{
          columns: [
            {
              key: 'sku',
              title: t('serviceRequests.fields.sku'),
              options: { na: true },
              render: ({ item }) => (
                <div style={{ display: 'flex' }}>
                  <Link to={`/service-requests/view/${item.id}`}>
                  #{item.sku?.slice?.(0, 8)}
                  </Link>
                
                  {/* Open in modal */}
                  <Button 
                    type="text" 
                    size="small" 
                    onClick={() => openModal(ServiceRequestView, { id: item.id })}
                  >
                    <EyeOutline />
                  </Button>
                </div>
              ),
            },
            {
              key: 'title',
              title: t('serviceRequests.fields.title'),
              options: { na: true },
              render: ({ item }) => (
                <Link to={`/service-requests/view/${item.id}`}>
                  {item.title}
                </Link>
              ),
            },
            {
              key: 'status',
              title: t('serviceRequests.fields.status'),
              render: ({ item }) => {
                return <Tag color={stc('-' + item.status)}>{t(`serviceRequests.stasuses.${item.status}`)}</Tag>
              },
            },
            {
              key: 'type',
              title: t('serviceRequests.fields.type'),
              render: ({ item }) => {
                return <Tag color={stc(item.type)}>{t(`serviceRequests.types.${item.type}`)}</Tag>
              },
            },
            {
              key: 'contract',
              title: t('serviceRequests.fields.contract'),
              render: ({ item }) => {
                return (
                  <Link to={`/rental-contracts-management/${item.contract.sku}`}>
                    #{string({ value: (item.contract.sku.slice(0, 8)).trim() })}
                  </Link>
                )
              },
              middleware: () => !props.withoutContract
            },
            {
              key: 'owner',
              title: t('serviceRequests.fields.requestCreator'),
              render: 'user',
            },
            {
              key: 'manager',
              title: t('serviceRequests.fields.requestManager'),
              render: 'user',
            },
            {
              key: 'createdAt',
              title: t('serviceRequests.fields.createdAt'),
              render: ({ item }) => {
                const createdAt = item?.createdAt ? DateTime.fromISO(item?.createdAt) : null
                return createdAt.toFormat('yyyy-LL-dd')
              },
            },
          ],
        }}
        {...props}
      />
    </>
  )
}

export function useServiceRequestsCounter (filter = 'all') {
  const { data, loading, refetch } = useQuery(SERVICE_REQUESTS_LIST, { variables: { page: 1, pageSize: 1000, filter: JSON.stringify(filters[filter]) }, fetchPolicy: 'no-cache' })
  const { list } = data?.response ?? { list: [] }
  const [lastSeen, setLastSeen] = useState([])

  useInterval(async () => {
    setLastSeen(await db.serviceRequestsLastSeen.where('id').anyOf(...list.map(item => item.id)).toArray())
  }, 3500)

  const total = list.filter(itemLeft => (itemLeft.updatedAt !== lastSeen.find(itemRight => itemRight.id === itemLeft.id)?.updatedAt)).length || 0
  return { value: total, loading: loading || lastSeen.loading, refetch }
}

export { ServiceRequests, ServiceRequestView }