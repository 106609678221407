/* eslint-disable */
import { generate, presetPalettes } from '@ant-design/colors'
import React, { createContext, useContext, useEffect, useMemo } from 'react'
const projects = require('../projects.json')

// Context
const ProjectContext = createContext()
export const useProject = () => useContext(ProjectContext)

// Detect current project
const projectMeta = projects.find(({ domain, aliases }) => ([domain, ...aliases].includes(document.domain)))

// Generate color's palettes
const primary = generate(projectMeta.primaryColor)
primary.primary = projectMeta.primaryColor
const projectPalettes = Object.assign({ primary }, presetPalettes)

// Generate context
export const projectContext = {
  ...projectMeta,
  palettes: projectPalettes
}

// Apply theme
if (projectMeta.slug === '@landlords') {
  import(/* webpackChunkName: "@landlords" */ '../styles/theme.@landlords.less')
} else {
  import(/* webpackChunkName: "@tenants" */ '../styles/theme.@tenants.less')
}

/**
 * Project provider
 */
export function ProjectProvider({ children }) {
  return (
    <ProjectContext.Provider value={projectContext}>
      {children}
    </ProjectContext.Provider>
  )
}
