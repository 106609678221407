import { colors } from '_colors'
import { BUILDING_UPSERT_MUTATION } from '_graphql'
import { PushpinTwoTone } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Collapse, Divider, Tooltip } from 'antd'
import GoogleMapReact from 'google-map-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { deepOmit } from 'utils/deepOmit'

/** Типы объектов */
const poiTypes = [
  { id: 'atm' },
  { id: 'bus_station' },
  { id: 'cafe' },
  { id: 'car_rental' },
  { id: 'drugstore' },
  { id: 'gym' },
  { id: 'laundry' },
  { id: 'parking' },
  { id: 'restaurant' },
  { id: 'spa' },
  { id: 'supermarket' },
  { id: 'subway_station' },
  { id: 'store' },
  { id: 'train_station' },
]

const AddressDescription = ({ building, needToMutate }) => {
  const { t } = useTranslation()
  if (!building?.id) { return <div /> }

  const [map, setMap] = useState(null)
  const [maps, setMaps] = useState(null)
  const [markerPosition, setMarkerPosition] = useState(null)

  const [pois, setPois] = useState([])
  const [poisReady, setPoisReady] = useState(false)

  const [buildingUpsert] = useMutation(BUILDING_UPSERT_MUTATION)

  const buildingAddress = building.addressDetails

  const handleApiLoaded = ({ map: MAP, maps: MAPS }) => {
    // use map and maps objects
    setMap(MAP)
    setMaps(MAPS)
  }

  useEffect(() => {
    if (poisReady && needToMutate) {
      buildingUpsert({
        variables: {
          input: {
            id: building?.id,
            places: deepOmit({ payload: pois, keysToOmit: ['geometry', 'opening_hours', 'photos', 'plus_code'] }),
          },
        },
      })
    }
  }, [pois.length])

  useEffect(() => {
    if (map && building) {
      if (buildingAddress) {
        const markerPositionTemp = {
          lat: buildingAddress.geometry.location.lat,
          lng: buildingAddress.geometry.location.lng,
        }

        setMarkerPosition(markerPositionTemp)

        if (maps) {
          // buildingUpsert({
          //   variables: {
          //     input: {
          //       id: buildingData.response.id,
          //       // places: JSON.stringify(pois),
          //       places: 'false',
          //     },
          //   },
          // });
          if (building?.places && building.places !== 'false' && building.places !== null) {
            setPois(building.places)
            setPoisReady(true)
          } else {
            const service = new maps.places.PlacesService(map)

            const request = (type) => ({
              location: markerPositionTemp,
              radius: '800',
              types: [type],
            })

            const poisTemp = []

            poiTypes.forEach(async (type) => {
              await service.nearbySearch(request(type.id), (p) => {
                if (p?.length) {
                  poisTemp.push({
                    type,
                    p: p.map((_p) => ({
                      ..._p, location: { lat: _p.geometry.location.lat(), lng: _p.geometry.location.lng() },
                    })),
                  })
                }
              })
            })

            setTimeout(() => {
              setPois(poisTemp)
              setPoisReady(true)
            }, 1000)
          }
        }
      }
    }
  }, [map, maps])

  return (
    <>
      <div style={{ height: '600px' }}>
        <GoogleMapReact
          defaultCenter={{
            lat: buildingAddress.geometry.location.lat,
            lng: buildingAddress.geometry.location.lng,
          }}
          defaultZoom={16}
          options={{
            streetViewControl: true,
          }}
          onGoogleApiLoaded={handleApiLoaded}
          yesIWantToUseGoogleMapApiInternals
        >
          {markerPosition
            ? (
              <Tooltip
                title="Building"
                lat={markerPosition.lat}
                lng={markerPosition.lng}
              >

                <PushpinTwoTone
                  twoToneColor={colors.main()}
                  style={{ fontSize: '40px', transform: 'translate(0%, -100%)' }}
                />
              </Tooltip>
            )
            : null}
          {poisReady && pois.length ? pois.map((poiObj, index) => poiObj.p.map((poi) => {
            const tooltipText = (
              <>
                <p style={{ color: colors.main(), margin: 0 }}>{t(`address-description.poi-types.${poiObj.type.id}`)}</p>
                <p style={{ margin: 0 }}>{poi.name}</p>
              </>
            )
            return (
              <Tooltip
                key={index}
                title={tooltipText}
                lat={poi.location.lat}
                lng={poi.location.lng}
              >
                <img src={poi.icon} alt="icon" style={{ width: '35px', height: '35px' }} />
              </Tooltip>
            )
          })) : null}
        </GoogleMapReact>

      </div>

      <Divider />

      <h1>{t('address-description.places')}</h1>

      <Collapse
        accordion
        onChange={() => {
          const main = document.getElementsByClassName('main')[0]
          setTimeout(() => {
            main.scrollTo(0, main.scrollHeight, {
              behavior: 'smooth',
            })
          }, 500)
        }}
      >
        {pois?.map?.((poiObj) => (
          <Collapse.Panel header={t(`address-description.poi-types.${poiObj.type.id}`)} key={poiObj.type.id}>
            {poiObj?.p?.map?.((poi, index) => (
              <p key={index}>{poi.name}</p>
            )) || 'Not found'}
          </Collapse.Panel>
        ))}

      </Collapse>
    </>
  )
}

export { AddressDescription }
