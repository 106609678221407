import { colors } from '_colors'
import { ALL_BUILDINGS_QUERY, BUILDING_QUERY, BUILDING_UPSERT_MUTATION, USER_QUERY } from '_graphql'
import { isAdmin, isLandlord, isTenant, isTenantManager, roles } from '_graphql/_enums'
import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Col, Divider,Form, message, Row, Select,Switch, Tabs, Tag } from 'antd'
import { gql } from 'apollo-boost'
import { Gallery, NotFound } from 'components'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import LayoutTabs from 'components/LayoutTabs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApartmentTile } from 'screens/ApartmentsManager/ApartmentTile'
import { useGlobalState } from 'state'
import { TUTORIAL_TYPES, useTutorials } from 'tutorials'
import { TUTORIALS } from 'tutorials/data'
import { swap } from 'utils/arraySwap'

import { AddressDescription } from '../../../components/AddressDescription'
import { BuildingContext } from '../AsideComponents/BuildingContext'
import {
  AddressEdit,
  AddressView,
  Amenities,
  BuildingInfoEdit,
  BuildingInfoView,
  CodeEdit,
  CodeView,
} from './formParts'
import { Editor } from './formParts/Editor'
import { ProfileInfoCard } from './ProfileInfoCard'
import { AptWrapper, ContextMenuTrigger, TabWrapper } from './styled'

const { TabPane } = Tabs

const UPLOAD_PHOTO = gql`
  mutation($file: Upload!, $entityId: String) {
    photoUpload(file: $file, entityId: $entityId) {
      url
    }
  }
`

const PHOTO_REORDER = gql`
  mutation($input: [PhotoInput]!) {
    orderUpdate(input: $input)
  }
`

const DELETE_PHOTO = gql`
  mutation($id: Int!) {
    deletePhoto(id: $id)
  }
`

const BuildingDetails = (props) => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()

  const [editPart, setEditPart] = useState(null)
  const [reorderedPhotosBuffer, setReorderedPhotosBuffer] = useState(null)

  const [form] = Form.useForm()

  const [uploadPhotos] = useMutation(UPLOAD_PHOTO)
  const [reorderPhoto] = useMutation(PHOTO_REORDER)
  const [deletePhoto] = useMutation(DELETE_PHOTO)
  const [buildingUpsert] = useMutation(BUILDING_UPSERT_MUTATION)

  const { data: buildingData, loading: buildingLoading, refetch: buildingRefetch } = useQuery(BUILDING_QUERY, {
    variables: {
      id: +props.id,
    },
  })
  const { refetch: buildingsRefetch } = useQuery(ALL_BUILDINGS_QUERY, { fetchPolicy: 'no-cache' })
  const { data: userData } = useQuery(USER_QUERY, {
    variables: {
      id: buildingData?.response?.userId || -1,
    },
  })

  useEffect(() => {
    buildingRefetch({
      variables: {
        id: +props.id,
      },
    })
  }, []); // eslint-disable-line

  useEffect(() => {
    if (state.refetch === 'BUILDING_QUERY') {
      buildingsRefetch()
      buildingRefetch()
      dispatch({ type: 'REFETCH_CLEAR' })
    }
  }, [state.refetch]); // eslint-disable-line

  const tutorials = useTutorials()

  if (!buildingData?.response && !buildingLoading) {
    return <NotFound />
  }

  if (buildingLoading) {
    return null
  }

  const cancel = () => {
    setEditPart(null)
  }

  const save = async (part) => {
    let address
    if (part.keys.includes('address')) {
      address = state.address
    }

    const formValues = form.getFieldsValue(part.keys)

    const { data: { response } = {}, errors } = await buildingUpsert({
      variables: {
        input: {
          id: +buildingData.response.id,
          ...formValues,
          ...(address ? { addressDetails: address } : {}),
        },
      },
    })

    if (errors?.[0]?.message) {
      message.error(errors?.[0]?.message)
    }

    if (response) {
      tutorials.startTutorial(TUTORIALS[TUTORIAL_TYPES.CREATE_APARTMENT_TUTORIAL])
      message.success(t('buildings.messages.block-updated', { blockName: part.blockName }))
      setEditPart(null)
      dispatch({ type: 'SET_RIGHT_BAR_COMPONENT', payload: null })
      dispatch({ type: 'COLLAPSE_RIGHT_BAR' })
      buildingRefetch()
    }
  }

  const data = buildingData?.response

  if (!data) return null

  const uploadImage = async (file) => {
    await uploadPhotos({
      variables: {
        file,
        entityId: data.sku,
      },
    })
    buildingRefetch()
  }

  const deleteImage = async (id) => {
    await deletePhoto({
      variables: { id },
    })
    buildingRefetch()
  }

  const reorderPhotos = async (a, b, images) => {
    const photosTemp = [...images]
    const orderedPhotos = swap(photosTemp, a, b).map((photo, index) => ({ ...photo, order: index }))
    setReorderedPhotosBuffer(orderedPhotos)

    await reorderPhoto({
      variables: {
        input: orderedPhotos.map((p) => ({ id: p.id, order: p.order })),
      },
    })

    await buildingRefetch()
    setReorderedPhotosBuffer(null)
  }

  const setStatus = async (payload) => {
    const { data: { response } = {} } = await buildingUpsert({
      variables: {
        input: {
          id: +buildingData.response.id,
          status: payload,
        },
      },
    })

    if (response) {
      message.success(payload ? t('buildings.messages.building-activated') : t('buildings.messages.building-deactivated'))
    }
    buildingRefetch({
      variables: {
        id: +props.id,
      },
    })
  }

  const setIsPublished = async (payload) => {
    const { data: { response } = {} } = await buildingUpsert({
      variables: {
        input: {
          id: +buildingData.response.id,
          isPublished: payload,
        },
      },
    })

    if (response) {
      message.success(`${payload ? t('buildings.messages.building-published') : t('buildings.messages.building-unpublished')}.`)
    }

    buildingRefetch({
      variables: {
        id: +props.id,
      },
    })
  }

  const saveAmenities = async (amenities) => {
    await buildingUpsert({
      variables: {
        input: {
          id: buildingData.response.id,
          amenitiesOptions: amenities,
        },
      },
    })
    buildingRefetch()
  }

  const contextMenuHandler = () => {
    dispatch({ type: 'SET_RIGHT_BAR_COMPONENT', payload: () => <BuildingContext building={data} /> })
    dispatch({ type: 'EXPAND_RIGHT_BAR' })
  }

  const changeProfile = async (profileId) => {
    await buildingUpsert({
      variables: {
        input: {
          id: buildingData.response.id,
          profileId,
        },
      },
    })
    buildingRefetch()
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={async (field) => {
          if (field[0]?.name[0] === 'buildingId') {
            await save({ keys: ['buildingId'], blockName: t('buildings.sections.building-attachment') })
            buildingRefetch({
              variables: {
                id: +props.id,
              },
            })
          }
        }}
      >
        <Row gutter={40} style={{ marginBottom: '20px' }}>
          <Col xs={!isTenant(state.currentUser.role) ? 16 : 24}>
            <LayoutCard>
              {/* <Address data={data} /> */}
              <Editor
                EditComponent={AddressEdit}
                ViewComponent={AddressView}
                blockName={t('buildings.fields.address')}
                editModeName="address"
                editPart={editPart}
                setEditPart={setEditPart}
                cancel={cancel}
                save={(e) => save({ e, keys: ['address'], blockName: t('buildings.fields.address') })}
                verticalPadding={20}
                form={form}
                marginBottom={0}
                data={data}
                dataIsEmpty={data.address === null}
              />
              <Editor
                EditComponent={CodeEdit}
                ViewComponent={CodeView}
                blockName={t('buildings.fields.code')}
                editModeName="code"
                editPart={editPart}
                setEditPart={setEditPart}
                cancel={cancel}
                save={(e) => save({ e, keys: ['code'], blockName: t('buildings.fields.code') })}
                verticalPadding={20}
                form={form}
                marginBottom={0}
                data={data}
                dataIsEmpty={data.code === null}
              />
            </LayoutCard>
          </Col>
          {isLandlord(state.currentUser?.role)
            && (
              <Col xs={8}>
                <LayoutCard>
                  
                  <div style={{
                    backgroundColor: data.status ? colors.main(0.8) : 'transparent',
                    padding: '10px',
                    borderRadius: '4px',
                  }}
                  >
                    <Switch
                      defaultChecked={data.status}
                      onChange={setStatus}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      disabled={data.profileId != null && data.code != null ? false : true}
                    />
                    <span style={{ marginLeft: '10px', fontWeight: '600' }}>
                      {data.status ? t('buildings.fields.status.$true$') : t('buildings.fields.status.$false$')}
                    </span>
                  </div>
                  {!isLandlord(state.currentUser?.role)
                    ? (
                      <ContextMenuTrigger onClick={contextMenuHandler}>
                        <EllipsisOutlined style={{ transform: 'rotate(90deg)' }} />
                      </ContextMenuTrigger>
                    )
                    : null}
                </LayoutCard>
              </Col>
            )}
          {isAdmin(state.currentUser?.role) && (
            <Col xs={8}>
              <LayoutCard>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{
                    backgroundColor: data.status ? colors.main(0.8) : 'transparent',
                    padding: '10px',
                    borderRadius: '4px',
                  }}
                  >
                    <Switch
                      defaultChecked={data.status}
                      onChange={setStatus}
                      disabled={data.isBusy ? 'disabled' : null}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                    <span style={{ marginLeft: '10px', fontWeight: '600' }}>
                      {data.status ? t('buildings.fields.status.$true$') : t('buildings.fields.status.$false$')}
                    </span>
                  </div>
                  <ContextMenuTrigger onClick={contextMenuHandler}>
                    <EllipsisOutlined style={{ transform: 'rotate(90deg)' }} />
                  </ContextMenuTrigger>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{
                    backgroundColor: data.isPublished ? colors.main(0.8) : 'transparent',
                    padding: '10px',
                    borderRadius: '4px',
                  }}
                  >
                
                    <span style={{ fontWeight: '600' }}>
                      <Tag color="yellow">{data.isBusy ? t('buildings.fields.isBusy.$true$') : t('buildings.fields.isBusy.$false$')}</Tag>
                    </span>
                  </div>
                </div>
              </LayoutCard>
            </Col>
          )}

        </Row>
        <Row gutter={40}>
          <Col xs={!isTenant(state.currentUser.role) ? 16 : 24}>
           
            <div style={{ marginBottom: '60px' }}>
              <Gallery
                onReorder={reorderPhotos}
                images={reorderedPhotosBuffer || data.photos}
                uploadFunc={uploadImage}
                deleteFunc={deleteImage}
                multiple
              />
            </div>
      

            {/* ----------------------------------------------------------------- */}

            <LayoutTabs
              type="card"
              defaultActiveKey="general"
            >
              <TabPane tab={t('buildings.sections.building-info')} key="general" style={{ minHeight: '500px'}}>
                <LayoutCard>
                  <Editor
                    EditComponent={BuildingInfoEdit}
                    ViewComponent={BuildingInfoView}
                    blockName={t('buildings.sections.building-info')}
                    editModeName="propertyInfo"
                    editPart={editPart}
                    setEditPart={setEditPart}
                    dataIsEmpty={data.places === null
                      && data.buildingDescription === null
                      && data.areaDescription === null
                      && data.floor === null}
                    cancel={cancel}
                    save={(e) => save({
                      e,
                      keys: ['places', 'buildingDescription', 'areaDescription', 'floor',
                        'hasElevator',
                        'hasRamp'],
                      blockName: 'Building Information',
                    })}
                    verticalPadding={60}
                    form={form}
                    data={data}
                  />
                </LayoutCard>
              </TabPane>
              <TabPane tab={t('buildings.sections.extras')} key="extras" style={{ minHeight: '500px'}}>
                <LayoutCard> <Amenities building={data} saveAmenities={saveAmenities} /></LayoutCard>
              </TabPane>
              <TabPane tab={t('buildings.sections.apartments')} key="apartments" style={{ minHeight: '500px'}}>
                {data.apartments?.length 
                  ? data.apartments.map((apt, index) => <ApartmentTile  key={index} item={apt} roles={roles} />) 
                  : t('buildings.sections.noApartments')
                } 
              </TabPane>
              <TabPane tab={t('buildings.sections.map')} key="map" style={{ minHeight: '400px'}}> 
                <LayoutCard> {buildingData?.response?.addressDetails && <AddressDescription needToMutate building={buildingData?.response} />}</LayoutCard> 
              </TabPane>
            </LayoutTabs>

          </Col>
          { !isTenant(state.currentUser.role) && (
            <Col xs={8}>
              <LayoutCard> 
                <Form.Item name="profileId" label={t('buildings.fields.profileId')}>
                  <Select style={{ width: '100%' }} onChange={changeProfile} defaultValue={data.profileId}>
                    <Select.Option value={null}>{t('buildings.fields.chooseOption')}</Select.Option>
                    {userData?.response?.profiles && userData.response.profiles.map((profile) => (
                      <Select.Option value={profile.id} key={profile.id}>
                        {profile.profileName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </LayoutCard> 
              <Divider />
              <LayoutCard> 
                <ProfileInfoCard hoverable={false} bordered={false} profile={buildingData.response.profile} />
              </LayoutCard> 
            </Col>
          )}
         
        </Row>
      </Form>
    </>
  )
}

export { BuildingDetails }
