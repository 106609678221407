import { isAdmin, isLandlord, roles } from '_graphql/_enums'
import { PROFILE_LIST } from '_graphql/profile'
import { REPORT_DELETE_MUTATION,REPORT_UPSERT_MUTATION,RP_LIST } from '_graphql/report'
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { Delete } from '@material-ui/icons'
import { Link, navigate } from '@reach/router'
import { Button, Col, DatePicker, Form, message, Popconfirm, Row, Select, Tag, Tooltip } from 'antd'
import Axios from 'axios'
import { LayoutCard } from 'components/Layout'
import { ListManager } from 'components/ListManager'
import { DateTime } from 'luxon'
import { type } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'


const ReportsManager = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [onSave] = useState(false)
  const isManagementUser = isAdmin(state.currentUser?.role)
  const [form] = Form.useForm()
  const [profiles, setProfiles] = useState()
  const [itemSku, setItemSku] = useState()

  /** Клонирование профиля */
  const [upsertReport] = useMutation(REPORT_UPSERT_MUTATION)
  const [deleteReport] = useMutation(REPORT_DELETE_MUTATION)
  // const [rebuild, { data: rebuildRP, loading: rebuildRPLoading }] = useLazyQuery(REBUILD_RP, { variables: { sku: itemSku }, fetchPolicy: 'no-cache' })



  const { data: profilesData, loading: profilesLoading } =
    useQuery(PROFILE_LIST, {
      variables: {
        page: 1,
        pageSize: 1000,
      },
      fetchPolicy: 'network-only'
    })

  /** Удаление профиля */
  // const [deleteProject] = useMutation(Project_DELETE_MUTATION);
  const deleteProjectAction = async (item, callback) => {
    // await deleteProject({ variables: { id: item.id } });
    // callback();
    console.log('delete project')
  }

  const handleOk = () => {
    onSave(true)
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const triggerReport = async (customerProfileId, issueMonth, type) => {
    const token = localStorage.getItem('token')
    let config = {
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }
    if (type === 0) {

      console.log('const per person triggered')
      Axios({
        method: 'POST',
        url: 'https://b2bh-back-dev-nfsyp7o4fa-ey.a.run.app/costPerPerson',
        ...config,
        responseType: 'blob',
        data: {
          customerProfileId,
          issueMonth,
          type,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'cost_Per_person_.xls')
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      })
    }
    if (type === 1) {
      Axios({
        method: 'POST',
        url: 'https://b2bh-back-dev-nfsyp7o4fa-ey.a.run.app/costPerApartment',
        ...config,
        responseType: 'blob',
        data: {
          customerProfileId,
          issueMonth,
          type,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'const_Per_Apartment.xls')
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      })
    }
    if (type === 2) {
      Axios({
        method: 'POST',
        url: 'https://b2bh-back-dev-nfsyp7o4fa-ey.a.run.app/paymentPerClient',
        ...config,
        responseType: 'blob',
        data: {
          customerProfileId,
          issueMonth,
          type,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'payment_Per_Client.xls')
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      })
    }

  }

  const onSubmit = async () => {
    const customerProfileId = form.getFieldValue('customerId')
    const issueMonth = form.getFieldValue('date')
    const type =  parseInt(form.getFieldValue('type'))

    const d = await upsertReport({
      variables: {
        input: {
          customerProfileId,
          issueMonth,
          type,
        },
      },
    })

    if (d?.data?.response) {
      message.success(t('report.messages.report-created'))
      const token = localStorage.getItem('token')
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      }

      if (type === 0) {

        console.log('const per person triggered')
        Axios({
          method: 'POST',
          url: 'http://localhost:8081/costPerPerson',
          ...config,
          responseType: 'blob',
          data: {
            customerProfileId,
            issueMonth,
            type,
          }
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'cost_Per_person_.xls')
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(url)
        })
      }
      if (type === 1) {
        Axios({
          method: 'POST',
          url: 'http://localhost:8081/costPerApartment',
          ...config,
          responseType: 'blob',
          data: {
            customerProfileId,
            issueMonth,
            type,
          }
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'const_Per_Apartment.xls')
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(url)
        })
      }
      if (type === 2) {
        Axios({
          method: 'POST',
          url: 'http://localhost:8081/paymentPerClient',
          ...config,
          responseType: 'blob',
          data: {
            customerProfileId,
            issueMonth,
            type,
          }
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'payment_Per_Client.xls')
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(url)
        })
      }

    } else {
      message.error(t('report.messages.report-failed'))
    }
  }

  const onDelete = async (sku) => {
    const d = await deleteReport({
      variables: {
        sku: sku,
      },
    })

    if (d?.data?.response.includes('Done')) {
      message.success(t('report.messages.removed-success'))
      // goBack()
    } else {
      message.error(d?.data?.response)
    
    }
  }

  const getReport = async(customerProfileId, issueMonth, type) => {

    
    
    message.success('Success, you will get report soon', customerProfileId)
  }
  

  return (
    <>
      <br />
      <LayoutCard>
        <Form
          form={form}
          layout="vertical"
        >
          <Row gutter={80}>
            <Col xs={6}>
              <Form.Item name="date" label={t('report.table.selectMonth')}>
                <DatePicker picker="month" style={{ width: '210px'}}/>
              </Form.Item> 
            </Col>
            <Col xs={8}>
              <Form.Item name="customerId" label={t('report.table.selectCustomer')}>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={t('globalForms.select')}
                >
                  {profilesData?.response && profilesData.response?.list?.filter((profile) => profile?.companyType === 'customer').map((profile) => (
                    <Select.Option value={profile.id} key={profile.id}>
                      {profile.companyName} (#{profile.id})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item name="type" label={t('report.table.type')}>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={t('globalForms.select')}
                >
               
                  <Select.Option value='0' key='costPerPerson'>
                    {t('report.table.types.costPerPerson')}
                  </Select.Option>

                  <Select.Option value='1' key='costPerApt'>
                    {t('report.table.types.costPerApartment')}
                  </Select.Option>

                  <Select.Option value='2' key='paymentPerClient'>
                    {t('report.table.types.paymentPerClient')}
                  </Select.Option>
                 
                </Select>
              </Form.Item>
            </Col>
           
            <Col xs={2}>
              <Form.Item style={{ marginTop: '30px', float: 'right'}} >
                <Button type="primary" onClick={() => onSubmit()}>Create</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </LayoutCard>
      <ListManager
        slug="rp-manager"
        title={t('report.table.title')}
        query={RP_LIST}

        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('report.table.sorting.idDesc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('report.table.sorting.idAsc'),
              icon: <SortAscendingOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('report.table.filters.all'),
              slug: 'all',
              value: {},
            },
          ],
          filters: [],
        }}
        table={{
          scroll: { x: 900, y: 350 },
          columns: [
            {
              title: t('report.table.sku'),
              key: 'sku',
              options: { na: true },
              render: ({ value, options, item }) => item?.sku?.slice(0, 8),
            },
            {
              title: t('report.table.customerProfile'),
              key: 'customerProfile',
              render: ({ item }) => {
                return <Link to={`/profiles-management/${item?.customerProfile?.id}`}><span>{item?.customerProfile?.companyName || `#${item?.customerProfile?.id}`}</span></Link>
              },
            },
            {
              title: t('report.table.issueMonth'),
              key: 'issueMonth',
              render: ({ item }) => {

                const createdAt = item?.issueMonth
                  ? DateTime.fromISO(item?.issueMonth)
                  : null

                return createdAt ? createdAt?.toFormat('yyyy LLL') : ''
              },
            },
            {
              title: t('report.table.type'),
              key: 'type',
              render: ({ item }) => {
                return <Tag>{item?.type != 0 ? (item?.type != 2 ? t('report.table.types.costPerApartment') : t('report.table.types.paymentPerClient')) : t('report.table.types.costPerPerson')}</Tag>
              },
            },
            {
              title: t('report.table.createdAt'),
              key: 'createdAt',
              render: ({ item }) => {

                const activeFrom = item?.createdAt
                  ? DateTime.fromISO(item?.createdAt)
                  : null

                return activeFrom ? activeFrom?.toFormat('yyyy-LL-dd / hh:mm') : ''
              },
            },
            {
              key: 'actions',
              title: t('report.table.actions.title'),
              width: 120,
              render: ({ item, context }) => (
                <>
                  <Tooltip placement="bottom" title="Download">
                    <Popconfirm title="Download this Report?" onConfirm={() => triggerReport(item.customerProfileId, item.issueMonth, item.type)}>
                      <Button shape="circle" type="link" icon={<CloudDownloadOutlined />} />
                    </Popconfirm>
                  </Tooltip>

                  <Tooltip placement="bottom" title="Remove">
                    <Popconfirm title="Delete this Report?" onConfirm={() => onDelete(item.sku)}>
                      <Button shape="circle" type="link" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </>
              ),
            },
          ],
        }}
        {...props}
      />
    </>
  )
}

export { ReportsManager }
