import { colors } from '_colors'
import {
  APT_UPSERT_MUTATION,
  CONTRACT_ACTION_MUTATION,
  CONTRACT_HISTORY_MUTATION,
  GENERATE_INVOICE_BY_CONTRACT,
  RENTAL_CONTRACT_SKU_QUERY,
  RENTAL_CONTRACT_UPSERT_MUTATION,
  START_WATCHING_MUTATION,
  SUBSCRIBE_RENTAL_CONTRACT,
  WBS_LIST,
} from '_graphql'
import {
  CONTRACT_ACTION_STATUS,
  CONTRACT_ACTIONS,
  detectCurrentContractStatus,
  isAdmin,
  isLandlord,
  isTenant,
  isTenantManager,
  roles,
} from '_graphql/_enums'
import { PROJECT_LIST } from '_graphql/project'
import Calendar from '@2fd/ant-design-icons/lib/Calendar'
import Cog from '@2fd/ant-design-icons/lib/Cog'
import EyeOutlineIcon from '@2fd/ant-design-icons/lib/EyeOutline'
import Fireplace from '@2fd/ant-design-icons/lib/Fireplace'
import SlashForward from '@2fd/ant-design-icons/lib/SlashForward'
import TransitTransfer from '@2fd/ant-design-icons/lib/TransitTransfer'
import Wallet from '@2fd/ant-design-icons/lib/Wallet'
import {
  BankOutlined,
  CrownOutlined,
  CustomerServiceOutlined,
  EditOutlined,
  EllipsisOutlined,
  EuroCircleOutlined,
  EyeInvisibleOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  TeamOutlined,
  UserAddOutlined,  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined} from '@ant-design/icons'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/react-hooks'
import { Link, useParams } from '@reach/router'
import {
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from 'antd'
import { useModals } from 'common/ModalsProvider'
import { NotFound } from 'components'
import { AttachmentsBlock } from 'components/AttachmentsBlock'
import { ChatBox } from 'components/Chat'
import { ContactBox } from 'components/ContactBox'
import { ContactBoxLarge } from 'components/ContactBoxLarge'
import { DateRange } from 'components/Form/DateRange'
import { LandlordChainPicker } from 'components/Form/LandlordChainPicker'
import { RentalRequestSelect } from 'components/Form/RentalRequestSelect'
import { TenantChainPicker } from 'components/Form/TenantChainPicker'
import { UserSelect } from 'components/Form/UserSelect'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import LayoutTabs from 'components/LayoutTabs'
import { cost, string } from 'components/ListManager/DefaultTypes'
import { Notifications } from 'components/Notifications'
import { PageHeader } from 'components/PageHeader'
import Email from 'components/Widgets/Email'
import Phone from 'components/Widgets/Phone'
import { forEach } from 'lodash'
import moment, { now } from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApartmentCard } from 'screens/ApartmentsManager/ApartmentCard'
import { CounterCard } from 'screens/Dashboard/DashboardTab'
import { InvoicePage, InvoicesManager } from 'screens/InvoiceManager'
import { LogsManager } from 'screens/LogsManager'
import { ServiceRequests, ServiceRequestView } from 'screens/ServiceRequests'
import { ServiceForm, ServicesManager, ServiceView } from 'screens/ServicesManager'
import {
  TransactionForm,
  TransactionsManager,
} from 'screens/TransactionsManager'
import { useGlobalState } from 'state'
import stc from 'string-to-color'
import styles from 'styles/side-layout.less'
import { CountryRender } from 'utils/countries'
import { db } from 'utils/dexie'

import { ActionButtonsTest } from '../../components/actionButtonsTest'
import { ApartmentDetails } from '../ApartmentsManager/ApatmentDetails'
import { BuildingDetails } from '../BuildingsManager/BuildingDetails'
import { RentalContracts } from '.'
import { ContractForm } from './ContractForm'
import { BoxEmpty, ContextMenuTrigger } from './styled'

const FORM_FIELDS = ['dateTill']

const { TabPane } = Tabs

const RentalContractDetails = (options) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)
  const { openModal } = useModals()

  const [contractCloseModalVisible, setContractCloseModalVisible] =
    useState(false)
  const [unlimitedChecked, setUnlimitedChecked] = useState(false)
  const [changeDatesModalVisible, setChangeDatesModalVisible] = useState(false)
  const [extendContractModalVisible, setExtendContractModalVisible] =
    useState(false)
  const [suspendContractModalVisible, setSuspendContractModalVisible] =
    useState(false)
  const [editModal, setEditModal] = useState(false)
  const [watching, setWatching] = useState(false)
  const [declineConfirmItem, setDeclineConfirmItem] = useState(null)
  const [declineReason, setDeclineReason] = useState(null)
  const [acceptConfirmItem, setAcceptConfirmItem] = useState(null)
  const [, rerender] = useState()
  const [editFormOpened, setEditFormOpened] = useState(false)
  const [refetchServiceRequestsState, refetchServiceRequests] = useState()
  const [refetchTransactionsState, refetchTransactions] = useState()
  const [refetchLogsState, refetchLogs] = useState()

  // define sku from url
  let sku
  if (options?.sku) {
    sku = options.sku
  } else {
    sku = useParams().sku
  }

  const [closeContract] = Form.useForm()
  const [datesForm] = Form.useForm()
  const [extendContractForm] = Form.useForm()
  const [suspendContractForm] = Form.useForm()
  const [editForm] = Form.useForm()
  const [form] = Form.useForm()

  const [updateContractMutation] = useMutation(RENTAL_CONTRACT_UPSERT_MUTATION)
  const [contractHistoryMutation] = useMutation(CONTRACT_HISTORY_MUTATION)
  const [contractActionMutation] = useMutation(CONTRACT_ACTION_MUTATION)
  
  const [aptUpsert] = useMutation(APT_UPSERT_MUTATION)

  const [startWatching] = useMutation(START_WATCHING_MUTATION)

  const backUrl = `/rental-contracts-management/${sku}`

  const { data: projectsData, loading: projectsLoading } = useQuery(
    PROJECT_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
      },
    }
  )

  const [fetchWbs, { data: wbsData, loading: wbsLoading }] = useLazyQuery(
    WBS_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
      },
    }
  )

  useEffect(() => {
    if (editForm.getFieldValue('projectId')) {
      fetchWbs({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            projectIds: [editForm.getFieldValue('projectId')],
          }),
        },
      })
    }
  }, [editForm.getFieldValue('projectId')])

  const { data: rentalContractDataRaw, loading: rentalContractLoadingRaw } =
    useQuery(RENTAL_CONTRACT_SKU_QUERY, {
      variables: { sku },
      fetchPolicy: 'no-cache',
    })

  const [rentalContractData, setRentalContractData] = useState(undefined)
  const [rentalContractLoading, setRentalContractLoading] = useState(true)

  useEffect(() => {
    if (!rentalContractLoadingRaw) {
      setRentalContractData(rentalContractDataRaw)
      setRentalContractLoading(false)
    }
  }, [rentalContractLoadingRaw])

  const data = rentalContractData?.response
  const rentalContract = data 

  // console.log(rentalContract)

  useEffect(() => {
    if (rentalContract) {
      form.setFieldsValue({
        salesId: rentalContract.salesId,
        rentalRequestId: rentalContract.rentalRequestId,
        period: {
          dateFrom: rentalContract.dateFrom
            ? moment(rentalContract.dateFrom).utc(false)
            : moment().utc(),
          dateTill: rentalContract.dateTill
            ? moment(rentalContract.dateTill).utc(false)
            : null,
          unlimited: rentalContract.dateTill ? false : true,
        },
        tenant: {
          tenantId: rentalContract.tenantId,
          tenantProfileId: rentalContract.tenantProfileId,
          projectId: rentalContract.projectId,
        },
        landlord: {
          landlordId: rentalContract.landlordId,
          profileId: rentalContract.landlordProfileId,
          buildingId: rentalContract.apartment?.buildingId,
          apartmentId: rentalContract.apartmentId,
        },
      })
    }
  }, [rentalContract, editFormOpened])

  const saveContractChanges = async () => {
    const values = form.getFieldsValue()

    const variables = {
      input: {
        id: rentalContract.id,
        salesId: values.salesId,
        rentalRequestId: values.rentalRequestId,
        
        dateFrom: values.period.dateFrom?.isValid?.() ? values.period.dateFrom.utc(true).startOf('day').format() : null,
        dateTill: values.period.dateTill?.isValid?.() ? values.period.dateTill.utc(true).endOf('day').format() : null,

        tenantId: values.tenant.tenantId,
        tenantProfileId: values.tenant.tenantProfileId,
        projectId: values.tenant.projectId,

        landlordId: values.landlord.landlordId,
        landlordProfileId: values.landlord.profileId,
        apartmentId: values.landlord.apartmentId,
      },
    }

    try {
      await updateContractMutation({ variables })
      setEditFormOpened(false)
      message.success('Saved')
    } catch {
      message.success('Fail')
    }
  }

  // блядь какого хуя
  const rentalContractRefetch = () => {}

  const { data: rentalContractDataSub } = useSubscription(
    SUBSCRIBE_RENTAL_CONTRACT,
    {
      fetchPolicy: 'no-cache',
      variables: { id: Number(rentalContract?.id) },
      onError: (err) => console.error(err),
    }
  )

  // Subscribe
  useEffect(() => {
    if (rentalContractDataSub) {
      setRentalContractData(rentalContractDataSub)
    }
  }, [rentalContractDataSub])

  useEffect(() => {
    if (rentalContractData?.response) {
      datesForm.setFieldsValue({
        dateFrom: rentalContractData.response.dateFrom
          ? moment(rentalContractData.response.dateFrom).utc(false)
          : null,
        dateTill: rentalContractData.response.dateTill
          ? moment(rentalContractData.response.dateTill).utc(false)
          : null,
      })
      editForm.setFieldsValue({
        projectId: rentalContractData.response.projectId,
        wbsId: rentalContractData.response.wbsId,
      })
      setUnlimitedChecked(!rentalContractData.response.dateTill)
    }
  }, [rentalContractData])

  if (!rentalContractData?.response && !rentalContractLoading) {
    return <NotFound />
  }

  const role = state.currentUser?.role

  // Last seen
  useEffect(() => {
    if (data) {
      db.rentalContractsLastSeen.put({
        id: data.id,
        updatedAt: data.updatedAt,
      })
    }
  }, [data])

  if (!data || (!data && !rentalContractLoading)) {
    return t('contract.messages.loading-or-error')
  }

  const { _sales, tenant, landlord } = data || {}

  const submitModalForm = async (input) => {
    await contractActionMutation({ variables: { input } })
  }

  /** Могут ли стороны контракта запрашивать какие бы то нибыло действия. Manager должен указать даты контракта */
  const contractPartiesCanMakeActions = data.actions
    .map((item) => item.context.type)
    .includes(1)

  /**
   * Актуализируем текущий статус и дату окончания квартиры
   */
  const actualizeApartmentStatus = ({ dateTill }) =>
    aptUpsert({
      variables: {
        input: {
          id: data.apartmentId,
          freeFrom:
            dateTill && typeof dateTill === 'object'
              ? dateTill.unix()
              : dateTill,
          isBooked: true,
        },
      },
    })



  const submitEditForm = async () => {
    const form = editForm.getFieldsValue(['projectId', 'wbsId'])

    const variables = {
      input: {
        id: data.id,
        ...form,
      },
    }

    await updateContractMutation({ variables })
    // rentalContractRefetch()
    setEditModal(false)
  }

  const onContractCloseModalOk = async () => {
    setContractCloseModalVisible(false)
    const form = closeContract.getFieldsValue(FORM_FIELDS)

    const variables = {
      input: {
        id: data.id,
        dateTill: form.dateTill.unix(),
      },
    } 

    const response = await updateContractMutation({ variables })

    if (!response.errors) {
      message.success(t('contract.messages.end-date-setuped'))

      await aptUpsert({
        variables: {
          input: {
            id: data.apartmentId,
            freeFrom: form.dateTill.unix(),
          },
        },
      })
    }
  }

  const onLandlordSign = async () => {
    const input = {
      context: {
        type: 3,
        status: 1,
      },
      rentalContractId: rentalContractData.response.id,
    }
    const response = await submitModalForm(input)

    if (!response?.errors) {
      message.success(t('contract.messages.contract-signed-by-landlord'))
      // rentalContractRefetch()
    }
  }

  const onTenantSign = async () => {
    const input = {
      context: {
        type: 2,
        status: 1,
      },
      rentalContractId: rentalContractData.response.id,
    }
    const response = await submitModalForm(input)

    if (!response?.errors) {
      message.success(t('contract.messages.contract-signed-by-tenant'))
      // rentalContractRefetch()
    }
  }

  const onContractCloseModalCancel = () => {
    setContractCloseModalVisible(false)
  }

  const extendContractModalOk = async () => {
    const { reason, extendDate } = extendContractForm.getFieldsValue([
      'extendDate',
      'reason',
    ])
    const input = {
      context: {
        type: 4,
        status: 0,
        dateTill: extendDate ? extendDate.unix() : null,
        unlimit: !extendDate,
        reason,
      },
      rentalContractId: rentalContractData.response.id,
    }
    const response = await submitModalForm(input)

    if (!response?.errors) {
      message.success(t('contract.messages.extension-created'))
      // rentalContractRefetch()
    }
    setExtendContractModalVisible(false)
  }
  const extendContractModalCancel = () => {
    setExtendContractModalVisible(false)
  }

  const changeDatesModalOk = async () => {
    const { dateTill, dateFrom } = datesForm.getFieldsValue([
      'dateTill',
      'dateFrom',
    ])

    const dateFromFormatted = dateFrom?.isValid?.() ? dateFrom.utc(true).startOf('day').format() : null
    const dateTillFormatted = dateTill?.isValid?.() ? dateTill.utc(true).endOf('day').format() : null

    const variables = {
      input: {
        id: rentalContractData?.response.id,
        dateFrom: dateFromFormatted,
        dateTill: dateTillFormatted,
      },
    }

    const contractRes = await updateContractMutation({ variables })
    await actualizeApartmentStatus({
      dateTill: dateTill ? dateTill.unix() : null,
    })
    

    const input = {
      context: {
        type: 1,
        status: 1,
        dateFrom: dateFromFormatted,
        dateTill: dateTillFormatted,
      },
      rentalContractId: rentalContractData.response.id,
    }
    const response = await submitModalForm(input)

    if (!response?.errors) {
      message.success(t('contract.messages.dates-change-request-created'))
    }

    setChangeDatesModalVisible(false)
  }
  const changeDatesModalCancel = () => {
    setChangeDatesModalVisible(false)
  }

  const suspendContractModalOk = async () => {
    const { reason, extendDate } = suspendContractForm.getFieldsValue([
      'extendDate',
      'reason',
    ])
    const input = {
      context: {
        type: 5,
        status: 0,
        dateTill: extendDate.unix(),
        reason,
      },
      rentalContractId: rentalContractData.response.id,
    }
    const response = await submitModalForm(input)

    if (!response?.errors) {
      message.success(t('contract.messages.suspension-request-created'))
      // rentalContractRefetch()
    }
    setSuspendContractModalVisible(false)
  }
  const suspendContractModalCancel = () => {
    setSuspendContractModalVisible(false)
  }

  const accept = async (item) => {
    const input = {
      id: item.id,
      context: {
        ...item.context,
        status: 1,
      },
    }
    const response = await submitModalForm(input)
    setAcceptConfirmItem(null)

    if (!response?.errors) {
      message.success(
        t('contract.messages.action-accepted', {
          title: CONTRACT_ACTIONS[item?.context?.type].title,
        })
      )
    }

    const variables = {
      input: {
        id: rentalContractData?.response.id,
        dateTill: item?.context?.dateTill,
      },
    }

    await updateContractMutation({ variables })
    await actualizeApartmentStatus({ dateTill: item?.context?.dateTill })
  }

  const decline = async (item) => {
    const input = {
      id: item.id,
      context: {
        ...item.context,
        ...(declineReason ? { rejectReason: declineReason } : null),
        status: 2,
      },
    }
    const response = await submitModalForm(input)
    setDeclineConfirmItem(null)
    setDeclineReason('')

    if (!response?.errors) {
      message.success(
        t('contract.messages.action-rejected', {
          title: CONTRACT_ACTIONS[item?.context?.type].title,
        })
      )
    }
  }

  const generateInvs = async (sku) => {
    console.log('triggered inv gen', sku)
    const { data: transactionDataRaw, loading: transactionLoading, refetch: transactionRefetch, } = useQuery(GENERATE_INVOICE_BY_CONTRACT, { variables: { sku: sku }, fetchPolicy: 'no-cache' })  
    console.log(transactionDataRaw)

  }

  /** Dropdown menu */
  const dropdown = [
    // Edit
    isAdmin(role) ? (
      <a
        onClick={() => {
          setEditFormOpened(true)
        }}
      >
        {t('contract.controls.edit-form')}
      </a>
    ) : null,
    // Set Dates
    isAdmin(role) ? (
      <a
        onClick={() => {
          setChangeDatesModalVisible(true)
        }}
      >
        {t('contract.controls.set-dates')}
      </a>
    ) : null,
    // Edit
    isAdmin(role) ? (
      <a
        onClick={() => {
          generateInvs(rentalContract?.sku)
        }}
      >
        {t('contract.controls.generateInvoices')}
      </a>
    ) : null,
    // Extend
    contractPartiesCanMakeActions ? (
      <a
        onClick={() => {
          setExtendContractModalVisible(true)
        }}
      >
        {t('contract.controls.extend')}
      </a>
    ) : null,
    // Terminate
    contractPartiesCanMakeActions ? (
      <a
        onClick={() => {
          setSuspendContractModalVisible(true)
        }}
      >
        {t('contract.controls.terminate')}
      </a>
    ) : null,
    // Edit
    // isAdmin(role) || isTenant(role) ? (
    //   <a
    //     onClick={() => {
    //       setEditModal(true)
    //     }}
    //   >
    //     {t('contract.controls.edit')}
    //   </a>
    // ) : null,
    // Close contract
    contractPartiesCanMakeActions &&
    !data.dateTill &&
    data.landlordSign &&
    data.tenantSign ? (
        <a
          onClick={() => {
            setContractCloseModalVisible(true)
          }}
        >
          {t('contract.controls.close-contract')}
        </a>
      ) : null,
    // Sign by landlord
    // ((contractPartiesCanMakeActions && isAdmin(role)) || isLandlord(role)) &&
    // !data.actions.map((item) => item.context.type).includes(3) ? (
    //     <a onClick={onLandlordSign}>{t('contract.controls.sign-landlort')}</a>
    //   ) : null,
    // // Sign by tenant
    // ((contractPartiesCanMakeActions && isAdmin(role)) || isTenant(role)) &&
    // !data.actions.map((item) => item.context.type).includes(2) ? (
    //     <a onClick={onTenantSign}>{t('contract.controls.sign-tenant')}</a>
    //   ) : null,
  ]

  /** Contract status */
  const rentalContractStatuses = detectCurrentContractStatus(
    rentalContract,
    state.currentUser
  )

  const watch = async() => {
    console.log('start watching')
   
    const variables = {
      input: {
        userId: state.currentUser.id,
        entityId: rentalContract.id,
      },
    }
    
    const response = await startWatching({variables})

    if (!response?.errors) {
      message.success(t('contract.messages.start-watching'))
      // rentalContractRefetch()
      setWatching(true)
    } else { 
      message.success(t('contract.messages.stop-watching'))
      setWatching(false)
    }

  }

  // Graph
  const totalOfRent =
    (rentalContract?.statistics?.totalOfRent || [])
      .map?.(([date, values]) => [
        {
          month: moment(date).format('MMMM'),
          type: 'Rent',
          value: Number(values.rent) * 100,
        },
        {
          month: moment(date).format('MMMM'),
          type: 'Utilities',
          value: Number(values.utilities) * 100,
        },
        {
          month: moment(date).format('MMMM'),
          type: 'Services',
          value: Number(values.services) * 100,
        },
      ])
      ?.flat?.() || []
  
  const dateFrom = moment(rentalContract.dateFrom).utc(false)
  const dateTill = moment(rentalContract.dateTill).utc(false)

  return (
    <>
      <div className={styles.container}>
        {/* Form */}
        <Form
          className={styles.side}
          form={form}
          layout="vertical"
          // initialValues={{ period: { unlimited: true }, tenant: {} }}
          // onFieldsChange={setHasChanges}
          onFinish={saveContractChanges}
        >
          <PageHeader
            title={t('contract.rental-contract-title')}
            id={data.sku}
          />

          {/* Edit */}
          {editFormOpened ? (
            <>
              {/* Tenant */}
              <Form.Item
                name="tenant"
                label={t('rental-request.components.tenant-select.title')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value.tenantId) {
                        return Promise.reject(
                          new Error(t('rental-request.main.not-selected'))
                        )
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <TenantChainPicker />
              </Form.Item>

              {/* Landlord */}
              <Form.Item
                name="landlord"
                label={t('rental-request.components.landlord-select.title')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value.landlordId) {
                        return Promise.reject(
                          new Error(t('rental-request.main.not-selected'))
                        )
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <LandlordChainPicker />
              </Form.Item>

              {/* Period picker */}
              <Form.Item
                name="period"
                label={'Rent period'}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value.dateFrom) {
                        return Promise.reject(
                          new Error(t('rental-request.main.select-date'))
                        )
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <DateRange />
              </Form.Item>

              {/* Manager picker */}
              {isAdmin(role) ? (
                <Form.Item
                  name="salesId"
                  label={t('rental-request.components.sales-select.title')}
                >
                  <UserSelect roles="b2b-managers" />
                </Form.Item>
              ) : null}

              {/* Rental request */}
              {isAdmin(role) ? (
                <Form.Item
                  name="rentalRequestId"
                  label={t('rental-request.main.createNew')}
                >
                  <RentalRequestSelect />
                </Form.Item>
              ) : null}

              <Divider />

              {/* Save contract */}
              <Button block size="large" type="primary" htmlType="submit">
                {t('contract.form.save')}
              </Button>

              {/* Cancel */}
              <Button
                type="text"
                block
                onClick={() => {
                  setEditFormOpened(false)
                }}
                size="large"
              >
                {t('projects.form.cancel')}
              </Button>
            </>
          ) : null}

          {/* View */}
          {!editFormOpened ? (
            <>
              {/* Contract Controls */}
              <div>
                {/* Apprroval waiting */}
                {!contractPartiesCanMakeActions ? (
                  <Alert
                    message={t('contract.controls.approval-waiting')}
                    type="info"
                    showIcon
                    style={{ width: '100%', margin: 4 }}
                  />
                ) : null}

                <Divider />
              </div>

              {/* Contract range */}
              <>
                <Typography.Title level={5}>
                  <Calendar style={{ marginRight: 5 }} />
                  {t('contract.page.range')}
                  {isAdmin(role) ? ( 
                    <div style={{ textAlign: 'right', 
                      width: '95px',
                      right: '0px',
                      position: 'relative',
                      display: 'inline-block'}}>
                      <EditOutlined  onClick={() => {
                        setChangeDatesModalVisible(true)
                      }} /> 
                    </div>) : null}
                </Typography.Title>

                {/* Date from */}
                { rentalContract.dateFrom 
                  ? (
                    <>
                      <div>
                        <Typography.Text strong>
                          {t('contract.fields.dateFrom')}:{' '}
                        </Typography.Text>
                        { dateFrom.format('YYYY-MM-DD')}
                      </div>

                      {/* Date till */}
                      <div>
                        <Typography.Text strong>
                          {t('contract.fields.dateTill')}:{' '}
                        </Typography.Text>
                        {rentalContract.dateTill
                          ? dateTill.format('YYYY-MM-DD')
                          : 'Unlimited'}
                      </div>
                    </>
                  ) : 
                  isAdmin(role) ? (
                    <Button
                      onClick={() => {
                        setChangeDatesModalVisible(true)
                      }}
                    >
                      {t('contract.controls.set-dates')}
                    </Button>
                  ) : 'Dates are not verified'
                
                
                }
               

                <Divider />
              </>

              {/* Objectives */}
              <>
                <Typography.Title level={5}>
                  <Fireplace style={{ marginRight: 5 }} />
                  {t('contract.page.objectives')}
                </Typography.Title>

                {/* Rental contract */}
                <div>
                  <Typography.Text strong>
                    {t('contract.page.rentalRequest')}:{' '}
                  </Typography.Text>
                  {rentalContract?.rentalRequest ? (
                    <Link
                      to={`/rental-requests-management/${rentalContract.rentalRequest.sku}`}
                    >
                      #{rentalContract.rentalRequest.sku.substr(0, 8)}
                    </Link>
                  ) : (
                    <Tag>{t('contract.page.manualContract')}</Tag>
                  )}
                </div>

                {/* Apartment */}
                <div>
                  <Typography.Text strong>
                    {t('contract.page.apartmentCode')}:{' '}
                  </Typography.Text>
                  {rentalContract?.apartment ? (
                    <a
                      onClick={() => openModal(ApartmentDetails, { id: rentalContract.apartment?.id })}
                    >{rentalContract.apartment?.code}
                    </a>
                  ) : (
                    <Tag>N/A</Tag>
                  )}
                </div>
                <div>
                  <Typography.Text strong>
                    {t('contract.page.address')}:{' '}
                  </Typography.Text>
                  {rentalContract?.apartment?.building ? (
                    <a
                      onClick={() => openModal(BuildingDetails, { id: rentalContract.apartment?.building?.id })}
                    >{rentalContract.apartment?.building?.addressDetails?.formatted_address}
                    </a>
                  ) : (
                    <Tag>N/A</Tag>
                  )}
                </div>

                <div>
                  <Typography.Text strong>
                    {t('contract.page.apartmentPrice')}:{' '}
                  </Typography.Text>
                  {cost({ value: rentalContract.apartment.monthPrice }) } 
                  (VAT: { rentalContract.apartment?.building?.profile?.taxRate != 0 ? cost({ value: rentalContract.apartment.monthPrice, vat: rentalContract?.apartment?.building?.profile?.taxRate }) : 0})
                </div> 

                <div>
                  <Typography.Text strong>
                    {t('contract.page.apartmentUtilities')}:{' '}
                  </Typography.Text>
                  {cost({ value: rentalContract.apartment.utilitiesPrice }) } 
                  (VAT: { rentalContract.apartment?.building?.profile?.taxRate != 0 ? cost({ value: rentalContract.apartment.utilitiesPrice, vat: rentalContract.apartment?.building?.profile?.taxRate }) : 0 })
                </div>

                <div>
                  <Typography.Text strong>
                    {t('contract.page.apartmentDeposit')}:{' '}
                  </Typography.Text>
                  {cost({ value: rentalContract?.apartment?.deposit })}
                </div>

                {/* <div>
                  <Typography.Text strong>
                    {t('contract.page.apartmentExtras')}:{' '}
                  </Typography.Text>
                  {rentalContract.apartment.extras}
                </div> */}

                <Divider />
              </>

              {/* Agent */}
              {/* {rentalContract?.sales ? (
                <ContactBox user={rentalContract.sales} />
              ) : null} */}

              {/* Landlord */}
              {/* {(isAdmin(role) || isLandlord(role)) &&
              rentalContract?.landlord ? (
                  <ContactBox
                    user={rentalContract.landlord}
                    profile={rentalContract.apartment?.building?.profile}
                  />
                ) : null} */}

              {/* Tenant | Admin */}
              {/* {(isAdmin(role) || isTenant(role)) && rentalContract?.tenant ? (
                <ContactBox user={rentalContract?.tenant} />
              ) : null} */}

              <Typography.Title level={5}>
                <TeamOutlined style={{ marginRight: 5 }} />
                {t('contract.page.tenants')}
              </Typography.Title>
  

              {/* Tenant | Employee */}
              {/* {isAdmin(role) || isTenant(role)
                ? rentalContract?.employees
                  ?.filter?.((employee) => employee.status === 'in')
                  ?.map?.((employee) => (
                    <ContactBox user={employee.user} key={employee.user.id} />
                  ))
                : null} */}

              <Divider />

              {rentalContract?.employees ? (
                rentalContract?.employees
                  ?.filter?.((employee) => employee.status === 'in' && moment(moment.now()).isAfter(employee.completedAt))
                  ?.map?.((employee) => <ContactBox user={employee.user} key={employee.user.id} />)
              ) : (
                null
              )}

            </>
          ) : null}
        </Form>

        <div className={styles.content} key={9999}>
          <PageHeader
            
            tags={[
              // Status
              rentalContractStatuses?.length > 0 ? (
                <>
                  {rentalContractStatuses.map((status) => (
                    <Tag
                      key={status.id}
                      color={status.secondColor || status.color}
                    >
                      {status.title}
                      {status.subtitle ? ` (${status.subtitle})` : null}
                    </Tag>
                  ))}
                </>
              ) : null,
              // Project | TODO: Каким ролям выводить?
              // rentalContract?.project ? (
              //   <Link
              //     key="project"
              //     to={`/projects-management/${rentalContract.project.sku}`}
              //   >
              //     <Tag color="blue">
              //       {data?.project?.title} #
              //       {rentalContract.project.sku.slice(0, 8)}
              //     </Tag>
              //   </Link>
              // ) : null,
              // // WBS | TODO: Каким ролям выводить?
              // rentalContract?.wbs ? (
              //   <Tag key="wbs" color="magenta">
              //     WBS #{rentalContract.wbs.code}
              //   </Tag>
              // ) : null,
              ( contractPartiesCanMakeActions && isAdmin(role)) ||
              isLandlord(role) ? (
                  !rentalContract.actions
                    .map((item) => item.context.type)
                    .includes(3) ? (
                      <Button disabled={rentalContract.dateFrom != null ? null : 'disabled'} style={{ marginRight: '10px'}}>
                        <a onClick={onLandlordSign}>
                          {t('contract.controls.sign-landlort')}
                        </a>
                      </Button>
                    ) : (
                      <Tag type="success" color="green">
                        {t('contract.controls.signed-landlord')}
                      </Tag>
                    )
                ) : null,
              (contractPartiesCanMakeActions && isAdmin(role)) ||
              isTenantManager(role) ? (
                  !rentalContract.actions
                    .map((item) => item.context.type)
                    .includes(2) ? (
                      <Button disabled={rentalContract.dateFrom != null ? null : 'disabled'}>
                        <a onClick={onTenantSign}>
                          {t('contract.controls.sign-tenant')}
                        </a>
                      </Button>
                    ) : (
                      <Tag type="success" color="green">
                        {t('contract.controls.signed-tenant')}
                      </Tag>
                    )
                ) : null,
            ]}
            dropdown={ rentalContract.dateFrom != null ? dropdown : null}
          />

          {(isAdmin(role) || isLandlord(role)) ? (
            <>
              {rentalContract?.actions &&
                rentalContract.actions
                  .filter(
                    (item) =>
                      (item.context.type === 4 || item.context.type === 5) &&
                      item.context.status === 0
                  )
                  .map((item) => {
                    const type = CONTRACT_ACTIONS[item?.context?.type]
                    return (
                      <Alert
                        key={9984}
                        style={{ marginBottom: '20px' }}
                        message={type.title}
                        description={t('contract.messages.action-description', {
                          reason: item.context?.reason ?? 'no reason',
                          date: item.context?.unlimit
                            ? 'unlimited'
                            : moment
                              .unix(item.context?.dateTill)
                              .format('YYYY-MM-DD'),
                        })}
                        type={type.alertType}
                        action={
                          <ActionButtonsTest
                            accept={() => {
                              if (type.id === 'SUSPEND_CONTRACT') {
                                setAcceptConfirmItem(item)
                              } else {
                                accept(item)
                              }
                            }}
                            decline={() => {
                              if (type.id === 'SUSPEND_CONTRACT') {
                                setDeclineConfirmItem(item)
                              } else {
                                decline(item)
                              }
                            }}
                            item={item}
                          />
                        }
                      />
                    )
                  })}
            </>
          ) : null}

          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <LayoutTabs type="card" defaultActiveKey="general" >
                <TabPane tab={t('contract.page.tabs.general')} key="general" disabled={ rentalContract?.dateFrom != null ? null :  'disabled'}>
                 
                   
                  <Row gutter={[20, 20]} >
                    {(isAdmin(role) || isTenant(role)) && rentalContract?.landlord ? (
                      <Col xs={12} style={{marginBottom: '50px'}}>
                        <ContactBoxLarge user={rentalContract?.landlord} profile={rentalContract?.landlord?.profiles[0]}/>
                      </Col>
                    ) : null}
                    {(isLandlord(role) || isAdmin(role)) && rentalContract?.tenant ? (
                      <Col xs={12} style={{marginBottom: '50px'}}>
                        <ContactBoxLarge user={rentalContract?.tenant} profile={rentalContract?.tenant?.owner?.profiles[0]}/>
                      </Col>) : null}

                    {(isLandlord(role) || isTenant(role)) && rentalContract?.tenant ? (
                      <Col xs={12}>
                        <ContactBoxLarge user={rentalContract?.sales} profile={rentalContract?.sales?.owner?.profiles[0]}/>
                      </Col>) : null}
            
                  
                  </Row>
                

               
                  <>
                  
                    <Row gutter={[20, 20]} >
                      <Col xs={24} style={{marginTop: '-25px'}}>
                        {isAdmin(role) || isTenantManager(role) ? (
                          <TransactionsManager
                            queryParams={{ filter: { contractIds: [data.id] } }}
                            refetch={refetchTransactionsState}
                            actions={[
                              isAdmin(role) ?  <Link
                                to={`/transactions-management/create?contractId=${
                          data.id
                        }&projectId=${
                          data.projectId
                        }&backUrl=${encodeURIComponent(
                          backUrl + '#transactions'
                        )}`}
                                key="create"
                                onClick={(e) => {
                                  e.preventDefault()
                                  openModal(TransactionForm, {
                                    forceQuery: {
                                      contractId: data.id,
                                      projectId: data.projectId,
                                      backUrl: backUrl + '#transactions',
                                    },
                                    onClose: refetchTransactions,
                                  })
                                }}
                              >
                                <Button
                                  type="primary"
                                  icon={<PlusOutlined />}
                                  htmlType="span"
                                >
                                  {t('transactions.table.actions.create')}
                                </Button>
                              </Link> : null,
                            ]}
                          />
                        ) : null }
                      </Col>
                    </Row>
                  </>
                 
               
                </TabPane>

                <TabPane tab={t('contract.page.tabs.agreements')} key="agreements" disabled={ rentalContract?.dateFrom != null ? null :  'disabled'}>
                  <Row gutter={[20, 20]}>
                    <Col xs={24}>
                      <AttachmentsBlock
                        attachments={data.attachments}
                        refetch={rentalContractRefetch}
                        relationType="rental-contract"
                        relationId={data.id}
                      />
                    </Col>
                  </Row>
                </TabPane>
                {/* {isAdmin(role) || isTenantManager(role) ? (<TabPane tab="Transactions" key="transactions" disabled={ rentalContract?.dateFrom != null ? null :  'disabled'}>
                  <TransactionsManager
                    queryParams={{ filter: { contractIds: [data.id] } }}
                    refetch={refetchTransactionsState}
                    actions={[
                      <Link
                        to={`/transactions-management/create?contractId=${
                          data.id
                        }&projectId=${
                          data.projectId
                        }&backUrl=${encodeURIComponent(
                          backUrl + '#transactions'
                        )}`}
                        key="create"
                        onClick={(e) => {
                          e.preventDefault()
                          openModal(TransactionForm, {
                            forceQuery: {
                              contractId: data.id,
                              projectId: data.projectId,
                              backUrl: backUrl + '#transactions',
                            },
                            onClose: refetchTransactions,
                          })
                        }}
                      >
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          htmlType="span"
                        >
                          {t('transactions.table.actions.create')}
                        </Button>
                      </Link>,
                    ]}
                  />
                </TabPane>) : null } */}
                

                <TabPane tab={t('contract.page.tabs.invoices')} key="invoices">
                  <InvoicesManager
                    queryParams={{ filter: { contractIds: [data.id] } }}
                    withoutContract
                    actions={[
                      <Link
                        to={'/invoices-management/create'}
                        key="create"
                        onClick={(e) => {
                          e.preventDefault()
                          openModal(InvoicePage, {
                            forceQuery: {
                              contractId: data.id,
                              backUrl: backUrl + '#invoices',
                            },
                           
                          })
                        }}
                      >
                        {!isLandlord(state.currentUser?.role) ? (
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            htmlType="span"
                          >
                            {t('invoices.table.actions.create')}
                          </Button>
                        ) : null}
                      </Link>,
                    ]}
                  />
                </TabPane>
                {!isLandlord(state.currentUser?.role) ? (
                  <TabPane tab={t('contract.page.tabs.services')} key="services">
                    <ServicesManager
                      queryParams={{ filter: { contractIds: [data.id] } }}
                      withoutContract
                      actions={[
                        <Link
                          to={'/services-management/create'}
                          key="createservice"
                          onClick={(e) => {
                            e.preventDefault()
                            openModal(ServiceForm, {
                              forceQuery: {
                                contractId: data.id,
                                serviceProviderId: data.landlordProfileId,
                                backUrl: backUrl + '#services',
                              },
                           
                            })
                          }}
                        >
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            htmlType="span"
                          >
                            {t('service.table.actions.createService')}
                          </Button>
                        </Link>,
                      ]}
                    />
                  </TabPane>
                ): null}

                <TabPane tab={t('contract.page.tabs.serviceRequests')} key="service-requests" disabled={ rentalContract?.dateFrom != null ? null :  'disabled'}>
                  <ServiceRequests
                    queryParams={{ filter: { contractIds: [rentalContract.id] } }}
                    // withoutContract
                    refetch={refetchServiceRequestsState}
                    actions={ isAdmin(role) && !isTenant(role) ? [
                      (
                        <Link
                          to={`/service-requests/create?contractId=${
                            data.id
                          }&backUrl=${encodeURIComponent(
                            backUrl + '#service-requests'
                          )}`}
                          key="createservicerequest"
                          onClick={(e) => {
                            e.preventDefault()
                            openModal(ServiceRequestView, {
                              forceQuery: {
                                contractId: data.id,
                                backUrl: backUrl + '#service-requests',
                              },
                              onClose: refetchServiceRequests,
                            })
                          }}
                        >
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            htmlType="span"
                          >
                            {t('serviceRequests.actions.createRequest')}
                          </Button>
                        </Link>
                      )
                    ] : null}
                  />
                </TabPane>

                <TabPane tab={t('contract.page.tabs.actions')} key="actions" disabled={ rentalContract?.dateFrom != null ? null :  'disabled'}>
                  <LayoutCard
                    title={t('contract.contract-actions.title')}
                    style={{ marginBottom: '20px' }}
                  >
                    <LayoutCardTitle>
                      {t('contract.contract-actions.title')}
                    </LayoutCardTitle>
                    <Timeline>
                      {data.actions.map((item) => {
                        const status = item?.context?.status
                          ? CONTRACT_ACTION_STATUS[item?.context?.status]
                          : null
                        const defColor = 'gray'
                        const type = CONTRACT_ACTIONS[item?.context?.type]
                        return (
                          <Timeline.Item
                            key={9985}
                            color={status?.color ? status?.color : defColor}
                          >
                            <Typography.Text strong>
                              {type?.title}
                            </Typography.Text>{' '}
                            {t('contract.contract-actions.by')}{' '}
                            {item?.user?.lastName} {item?.user?.firstName}{' '}
                            <Tag>
                              {moment(item.createdAt).format('YYYY-MM-DD')}
                            </Tag>
                            {item?.context?.reason &&
                            (isManagementUser ||
                              item?.user?.id === state?.currentUser?.id) ? (
                                <div>
                                  <Typography.Text strong>
                                    {t('contract.contract-actions.reason')}:
                                  </Typography.Text>{' '}
                                  {item?.context?.reason}
                                </div>
                              ) : null}
                            {item?.context?.rejectReason &&
                            (isManagementUser ||
                              item?.user?.id === state?.currentUser?.id) ? (
                                <div>
                                  <Typography.Text strong>
                                    {t(
                                      'contract.contract-actions.rejection-reason'
                                    )}
                                  :
                                  </Typography.Text>{' '}
                                  {item?.context?.rejectReason}
                                </div>
                              ) : null}
                          </Timeline.Item>
                        )
                      })}
                    </Timeline>
                  </LayoutCard>
                </TabPane>
                {isAdmin(role) ?  ( 
                  <TabPane tab={t('contract.page.tabs.logs')} key="correspondence" disabled={ rentalContract?.dateFrom != null ? null :  'disabled'}>
                    <LogsManager
                      queryParams={{ filter: { referenceIds:  [rentalContract.id] } }}
                      actions={[]}
                    />
                  </TabPane>) : null
                }
               
              </LayoutTabs>
            </Col>
          </Row>

          {/* <ApartmentCard
              style={{ marginBottom: '20px' }}
              apt={data.apartment}
              onClick={() => {
                if (isTenant(state.currentUser.role)) {
                  window.open(`/apartments-management/sku/${data.apartment.sku}`, '_blank');
                } else {
                  window.open(`/apartments-management/${data.apartment.id}`, '_blank');
                }
              }}
            /> */}
        </div>
      </div>

      <Modal
        visible={contractCloseModalVisible}
        onOk={onContractCloseModalOk}
        onCancel={onContractCloseModalCancel}
      >
        <p>{t('contract.contract-actions.contract-close-date')}</p>
        <Form form={closeContract} layout="vertical">
          <Form.Item
            name="dateTill"
            label={t('contract.contract-actions.dateTill.label')}
            rules={[
              {
                required: true,
                message: t('contract.contract-actions.dateTill.message'),
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </Form>
      </Modal>

      {/* Edit form */}
      <Modal
        visible={editModal}
        onOk={submitEditForm}
        onCancel={() => setEditModal(false)}
      >
        <Form form={editForm} layout="vertical">
          {/* Project */}
          <Form.Item name="projectId" label={t('contract.form.project')}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={projectsLoading}
              onChange={rerender}
            >
              {projectsData?.response?.list?.map?.((project) => (
                <Select.Option value={project.id} key={project.id}>
                  {project.title} (#{project.id})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* WBS */}
          <Form.Item
            name="wbsId"
            label={t('contract.form.wbs')}
            style={{ marginTop: 25 }}
          >
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('globalForms.select')}
              disabled={wbsLoading || !editForm.getFieldValue('projectId')}
            >
              {wbsData?.response?.list?.map?.((wbs) => (
                <Select.Option value={wbs.id} key={wbs.id}>
                  {wbs.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={extendContractModalVisible}
        onOk={extendContractModalOk}
        onCancel={extendContractModalCancel}
      >
        <p>{t('contract.contract-actions.extend-contract')}</p>
        <Form form={extendContractForm} layout="vertical">
          <Row gutter={[12, 38]} style={{ height: 64 }}>
            <Col xs={12}>
              {!unlimitedChecked ? (
                <Form.Item
                  name="extendDate"
                  label={t('contract.contract-actions.extendDate.label')}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'contract.contract-actions.extendDate.message'
                      ),
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(date) => {
                      const nowDate = moment.now()
                      const dateTill = moment(
                        data.dateTill ?? nowDate
                      ).valueOf()
                      const dateValue = date.startOf('day').valueOf()
                      return !(dateValue > dateTill && dateValue > nowDate)
                    }}
                  />
                </Form.Item>
              ) : (
                <h3 style={{ fontSize: '16px', marginTop: 33 }}>
                  <b>{t('contract.fields.dateTill')}:</b>{' '}
                  {t('contract.fields.unlimited')}
                </h3>
              )}
            </Col>
            <Col xs={12}>
              <Checkbox
                style={{ marginTop: 36 }}
                checked={unlimitedChecked}
                onChange={(e) => {
                  setUnlimitedChecked(e.target.checked)
                  extendContractForm.setFieldsValue({
                    extendDate: e.target.checked
                      ? null
                      : data.dateTill
                        ? moment(data.dateTill)
                        : moment(),
                  })
                }}
              >
                {t('contract.fields.unlimited')}
              </Checkbox>
            </Col>
          </Row>

          <Form.Item
            name="reason"
            label={t('contract.contract-actions.reason')}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={changeDatesModalVisible}
        onOk={changeDatesModalOk}
        onCancel={changeDatesModalCancel}
      >
        <p>{t('contract.contract-actions.change-contract-dates')}</p>
        <Form form={datesForm} layout="vertical">
          {(isAdmin(role) || isTenant(role)) && (
            
            <Row gutter={[12, 38]}>
              <Col xs={12}>
                <Form.Item name="dateFrom">
                  <DatePicker
                    // disabledDate={(date) => {
                    //   const nowDate = moment().startOf('day').valueOf()
                    //   const dateValue = date.startOf('day').valueOf()
                    //   return dateValue < nowDate
                    // }}
                    // disabledDate={(date) => {
                    //   const dateTill =
                    //     datesForm.getFieldValue('dateTill') ??
                    //     moment(data.dateTill)
                    //   return !(
                    //     unlimitedChecked ||
                    //     date.startOf('day').valueOf() <
                    //       dateTill?.startOf('day')?.valueOf()
                    //   )
                    // }}
                  />
                </Form.Item>
                {/* <h3 style={{ fontSize: '16px' }}><b>Starting At:</b>  {moment(data.dateFrom).format('YYYY-MM-DD')}</h3> */}
              </Col>
              <Col xs={12}>
                <Space direction="vertical" size={10}>
                  {!unlimitedChecked ? (
                    <Form.Item name="dateTill" initialValue={moment.now()}>
                      <DatePicker
                        disabledDate={(date) => {
                          const dateFrom =
                              datesForm.getFieldValue('dateFrom') ??
                              moment(data.dateFrom)
                          return !(
                            date.startOf('day').valueOf() >
                              dateFrom?.startOf('day')?.valueOf()
                          )
                        }}
                      />
                    </Form.Item>
                  ) : (
                    <h3 style={{ fontSize: '16px' }}>
                      <b>{t('contract.fields.dateTill')}:</b>{' '}
                      {t('contract.fields.unlimited')}
                    </h3>
                  )}
                  <Checkbox
                    checked={unlimitedChecked}
                    onChange={(e) => {
                      setUnlimitedChecked(e.target.checked)
                      datesForm.setFieldsValue({
                        dateTill: e.target.checked
                          ? null
                          : data.dateTill
                            ? moment(data.dateTill)
                            : moment(),
                      })
                    }}
                  >
                    {t('contract.fields.unlimited')}
                  </Checkbox>
                </Space>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>

      <Modal
        visible={suspendContractModalVisible}
        onOk={suspendContractModalOk}
        onCancel={suspendContractModalCancel}
      >
        <p>{t('contract.contract-actions.terminate-contract')}</p>
        <Form form={suspendContractForm} layout="vertical">
          <Form.Item
            name="extendDate"
            label={t('contract.contract-actions.extendDate.label')}
            rules={[
              {
                required: true,
                message: t('contract.contract-actions.extendDate.message'),
              },
            ]}
          >
            <DatePicker
              disabledDate={(date) => {
                const currentDate = date.startOf('day').valueOf()
                const todayDate = moment().startOf('day').valueOf()
                const dateFromDate = data.dateFrom
                  ? moment(data.dateFrom)
                    .startOf('day')
                    .add(1, 'month')
                    .valueOf()
                  : 0

                return todayDate >= currentDate || currentDate <= dateFromDate
              }}
            />
          </Form.Item>
          <Form.Item
            name="reason"
            label={t('contract.contract-actions.reason')}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>

      {/** Ввод комментария к отказу от Terminate */}
      <Modal
        visible={declineConfirmItem !== null}
        onCancel={() => {
          setDeclineConfirmItem(null)
          setDeclineReason('')
        }}
        onOk={() => decline(declineConfirmItem)}
      >
        <p>{t('contract.contract-actions.refusal-to-terminate-contract')}</p>
        <Form.Item name="reason" label={t('contract.contract-actions.reason')}>
          <Input.TextArea
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
          />
        </Form.Item>
      </Modal>

      {/** Подтверждение в Terminate */}
      <Modal
        visible={acceptConfirmItem !== null}
        onCancel={() => {
          setAcceptConfirmItem(null)
          setDeclineReason('')
        }}
        onOk={() => accept(acceptConfirmItem)}
      >
        <p>{t('contract.contract-actions.termination-confirmation')}</p>
        <Form.Item
          name="extendDate"
          label={t('contract.contract-actions.extendDate.label')}
          rules={[
            {
              required: true,
              message: t('contract.contract-actions.extendDate.message'),
            },
          ]}
        >
          <DatePicker
            defaultValue={moment(acceptConfirmItem?.context?.dateTill * 1000)}
            onChange={(date) => {
              acceptConfirmItem.context.dateTill = date.unix()
            }}
          />
        </Form.Item>
      </Modal>
    </>
  )
}

export { RentalContractDetails }
