import { detectCurrentProjectStatus, isAdmin,isTenant, roles } from '_graphql/_enums'
import { ALL_PROFILES_QUERY } from '_graphql/profile'
import { ALL_RENTAL_CONTRACTS_QUERY } from '_graphql/rentalContract'
import { SERVICE_UPSERT_MUTATION } from '_graphql/service'
import {
  BankOutlined,
  CrownOutlined,
  EllipsisOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useLazyQuery,useMutation, useQuery } from '@apollo/react-hooks'
import { navigate, useLocation, useParams } from '@reach/router'
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { ApartmentsSelect } from 'components/Form/ApartmentsSelect'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import { count } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { ContextMenuTrigger } from './styled'

const keys = ['contractId', 'serviceProviderId', 'nextPaymentMonth', 'lastPaymentDate', 'paymentInterval', 'serviceProviderType', 'reference', 'apartmentId']

const ServiceForm = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isModal = props.isModal
  const params = isModal ? {} : useParams()
  const [title, setTitle] = useState(null)
  const [apartmentId, setApartmentId] = useState(null)
  const [serviceProviderId, setServiceProviderId] = useState(null)
  const [providerTax, setProviderTax] = useState(0)
  const [filteredService, setFilteredService] = useState(null)
  

  const [, forceUpdate] = useState(null)
  const [types, setTypes] = useState([])

  const { data: profilesData, loading: profilesLoading } = useQuery(ALL_PROFILES_QUERY)
  const { data: contractsData, loading: contractsLoading } = useQuery(ALL_RENTAL_CONTRACTS_QUERY)
  const location = useLocation()

  const [upsertService] = useMutation(SERVICE_UPSERT_MUTATION)

  const queryProps = useMemo(
    () => props.forceQuery ? props.forceQuery : new URLSearchParams(location.search),
    [location.search, props]
  )


  console.log(queryProps)
  
  const contract = useMemo(
    () =>
      contractsData?.response?.find?.(
        (contract) => contract.id === +queryProps.get('contractId')
      ) ?? [],
    [queryProps.get('contractId'), contractsData]
  )

  useEffect(() => {

    if (queryProps.has('contractId')  && contract) {
      form.setFieldsValue({
        contractId: parseInt(queryProps.get('contractId')),
        apartmentId: parseInt(queryProps.get('apartmentId')),
      })

    }

    if (queryProps.has('apartmentId') &&  queryProps.has('serviceProviderId')) {
      form.setFieldsValue({
        apartmentId: parseInt(queryProps.get('apartmentId')),
        serviceProviderId: parseInt(queryProps.get('serviceProviderId')),
      })
      setApartmentId(parseInt(queryProps.get('apartmentId')))
      setServiceProviderId(parseInt(queryProps.get('serviceProviderId')))
    }
  }, [location.search, contract, queryProps.get('apartmentId'), queryProps.get('serviceProviderId')])

  const profile = useMemo(
    () =>
      profilesData?.response?.find?.(
        (profile) => profile.id === +queryProps.get('serviceProviderId')
      ) ?? [],
    [queryProps.get('serviceProviderId'), profilesData]
  )

  useEffect(() => {
    if (queryProps.has('serviceProviderId') && profile) {
      form.setFieldsValue({
        serviceProviderId: parseInt(queryProps.get('serviceProviderId')),
      })
    }
  }, [location.search, profile])


  const [form] = Form.useForm()

  
  const query = useMemo(() => new URLSearchParams(location.search), [location.search])
  useEffect(() => {
    if (query.has('projectId')) {
      form.setFieldsValue({
        projectId: parseInt(query.get('projectId')),
      })
    }
  }, [location.search])

  const handleTypesChange = () => {
    const serviceProviderId = +form.getFieldValue('serviceProviderId')
  
    if (serviceProviderId) {
      const filteredProfile = profilesData?.response?.find((profile) => profile.id === serviceProviderId)
      console.log(filteredProfile)
      setTypes(filteredProfile?.type)
    }
  }

  const goBack = (id) => {
    if (props.onClose) {
      props.onClose(id)
    } else if (query.has('backUrl')) {
      navigate(query.get('backUrl'))
    } else {
      navigate('/wbs-management')
    }
  }

  const saveService = async () => {
    const values = form.getFieldsValue(keys)

    if (values) {
      const d = await upsertService({
        variables: {
          input: {
            ...values,
            paymentInterval: parseInt(values.paymentInterval),

          },
        },
      })

      if (d.data?.response) {
        message.success('created')
        goBack(d?.data?.response?.id)
      } else {
        message.error('creation error')
      }
    }
  }



  const recalculateGross = () => {
    let net = +form.getFieldValue('netPrice')
    let taxRate = +form.getFieldValue('tax')

    if(taxRate) {
      let newGross = 0
      newGross = net * (1 + (taxRate/100))

      form.setFieldsValue({
        grossPrice: Math.round(newGross),
      })
    }
  }

  const recalculateNet = () => {
    let gross = +form.getFieldValue('grossPrice')
    let taxRate = +form.getFieldValue('tax')

    console.log(taxRate)
    if(taxRate) {
      let newNet = 0 
      newNet = gross - (gross * (taxRate/100))

      form.setFieldsValue({
        netPrice: newNet,
      })
    }
  }


  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={saveService}
    >
      <LayoutOverlay>
        {!isModal ? (
          <Row gutter={[20, 20]}>
            
            <Col xs={19} style={{ textAlign: 'left' }}>
              <h2 style={{ fontSize: '24px' }}>{t('service.form.new')}</h2>
            </Col>
            <Col xs={4} style={{ textAlign: 'left' }}>
              {' '}
              <Alert
                type="success"
                message="Active"
                showIcon
              />
            </Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              <ContextMenuTrigger>
                <EllipsisOutlined style={{ transform: 'rotate(90deg)' }} />
              </ContextMenuTrigger>
            </Col>
          </Row>) : (<h2 style={{ fontSize: '24px' }}>{t('service.form.new')}</h2>)}

        <Row gutter={[20, 20]}>
          <Col xs={isModal ? 24 : 16}>
            <LayoutCard>
              
              <Row gutter={[20, 20]}>
                <Col xs={6}>
                  <Form.Item
                    name="contractId"
                    label={t('service.form.contract')}
                  >
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={t('globalForms.select')}
                    >
                      {contractsData?.response && contractsData.response.map((contract) => (
                        <Select.Option value={contract.id} key={contract.id}>
                          <b>RC: #</b>{contract.sku} <b>Apt:</b>{contract.apartment?.code}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item
                    name="apartmentId"
                    label={t('service.form.apartment')}
                    required
                    rules={[{ required: true }]}
                  >
                    <ApartmentsSelect apartmentId={apartmentId} onTitle={setTitle} />
                  </Form.Item>
                </Col>
               
                <Col xs={6}>
                  <Form.Item
                    name="serviceProviderId"
                    label={t('service.form.provider')}
                    required
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={t('globalForms.select')}
                      onChange={handleTypesChange}
                    >
                      {profilesData?.response && profilesData.response.map((profile) => (
                        <Select.Option value={profile.id} key={profile.id}>
                          <b>Company:</b> {profile.companyName} <b>ZIP:</b>{profile.zipCode}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item
                    name="serviceProviderType"
                    label={t('service.form.serviceProviderType')}
                  >
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={t('globalForms.select')}

                    >
                      {types?.map((type, i) => (
                        <Select.Option value={type} key={i}>
                          {type}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item
                    name="nextPaymentMonth"
                    label={t('service.form.nextPaymentMonth')}
                    required
                    rules={[{ required: true }]}
                  >
                    <DatePicker picker="month" />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item
                    name="lastPaymentDate"
                    label={t('service.form.lastPaymentDate')}
                    required
                    rules={[{ required: true }]}
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="paymentInterval"
                    label={t('service.form.paymentInterval')}
                    required
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={t('globalForms.select')}
                    >
                      <Select.Option value='0' key='0'>{t('service.form.paymentIntervals.weekly')}</Select.Option>
                      <Select.Option value='1' key='1'>{t('service.form.paymentIntervals.monthly')}</Select.Option>
                      <Select.Option value='2' key='2'>{t('service.form.paymentIntervals.quarterly')}</Select.Option>
                      
                    </Select>
                  </Form.Item>
                </Col>
               
                <Col xs={12}>
                  <Form.Item
                    name="reference"
                    label={t('service.form.reference')}
                    required
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={t('service.form.referenceHolder')} />
                  </Form.Item>
                </Col>

                <Col xs={24}> <Form.Item 
                  name="grossPrice"
                  label={t('servicePrice.form.grossPrice')}
                >
                  <InputNumber
                    min={0} 
                    defaultValue={0} 
                    onChange={recalculateNet}
                    style={{ width: '100%' }}  
                    formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/€\s?|(,*)/g, '')}
                  />
                </Form.Item>

                <Form.Item 
                  name="netPrice"
                  label={t('servicePrice.form.netPrice')}
                >
                  <InputNumber
                    min={0} 
                    defaultValue={0}
                    onChange={recalculateGross}
                    style={{ width: '100%' }}
                    formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/€\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item 
                  name="tax"
                  label={t('servicePrice.form.tax')}
                >
                  <InputNumber 
                    style={{ width: '100%' }}
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                  />
                </Form.Item>
                </Col>
               
              </Row>
            </LayoutCard>
          </Col>
        </Row>

        <LayoutStickyFooter>
          <LayoutFooterActions>
            <Button size="large" type="default" onClick={() => goBack()}>
              {t('service.form.cancel')}
            </Button>
            <Form.Item noStyle>
              <Button size="large" type="primary" htmlType="submit">
                {t('service.form.save')}
              </Button>
            </Form.Item>
          </LayoutFooterActions>
        </LayoutStickyFooter>
      </LayoutOverlay>
    </Form>
  )
}

export { ServiceForm }
