import i18next from 'i18next'

export const amenitiesInitialState = {
  default: [
    {
      get title() { return i18next.t('apartments.amenities.internet.title') },
      id: 'internet',
      selected: false,
      options: [
        {
          get title() { return i18next.t('apartments.amenities.internet.wlan') },
          id: 'wlan',
          selected: false,
        },
        {
          get title() { return i18next.t('apartments.amenities.internet.lan') },
          id: 'lan',
          selected: false,
        },
      ],
    },
    {
      get title() { return i18next.t('apartments.amenities.tv.title') },
      id: 'tv',
      selected: false,
      options: [
        {
          get title() { return i18next.t('apartments.amenities.tv.sat') },
          id: 'sat',
          selected: false,
        },
        {
          get title() { return i18next.t('apartments.amenities.tv.cable') },
          id: 'cable',
          selected: false,
        },
        {
          get title() { return i18next.t('apartments.amenities.tv.dbvt2') },
          id: 'dbvt2',
          selected: false,
        },
        {
          get title() { return i18next.t('apartments.amenities.tv.smartTv') },
          id: 'smartTv',
          selected: false,
        },
      ],
    },
    {
      get title() { return i18next.t('apartments.amenities.washingMaschine.title') },
      id: 'washingMaschine',
      selected: false,
      options: [
        {
          get title() { return i18next.t('apartments.amenities.washingMaschine.included') },
          id: 'included',
          selected: false,
        },
        {
          get title() { return i18next.t('apartments.amenities.washingMaschine.coinSlot') },
          id: 'coinSlot',
          selected: false,
        },
      ],
    },
    {
      get title() { return i18next.t('apartments.amenities.dryer.title') },
      id: 'dryer',
      selected: false,
      options: [
        {
          get title() { return i18next.t('apartments.amenities.dryer.included') },
          id: 'included',
          selected: false,
        },
        {
          get title() { return i18next.t('apartments.amenities.dryer.coinSlot') },
          id: 'coinSlot',
          selected: false,
        },
      ],
    },
    {
      get title() { return i18next.t('apartments.amenities.airConditioning.title') },
      id: 'airConditioning',
      selected: false,
    },
    {
      get title() { return i18next.t('apartments.amenities.workingPlace.title') },
      id: 'workingPlace',
      selected: false,
    },
    {
      get title() { return i18next.t('apartments.amenities.safe.title') },
      id: 'safe',
      selected: false,
    },
    {
      get title() { return i18next.t('apartments.amenities.extraBed.title') },
      id: 'extraBed',
      selected: false,
    },
  ],
  custom: [],
}
