import { isAdmin, isLandlord, isTenant, roles, USER_ROLES } from '_graphql/_enums'
import ChevronRight from '@2fd/ant-design-icons/lib/ChevronRight'
import { Link } from '@reach/router'
import { Popover, Typography } from 'antd'
import UserAvatar from 'components/User/Widgets/Avatar'
import Email from 'components/Widgets/Email'
import Phone from 'components/Widgets/Phone'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { user as User } from '../ListManager/DefaultTypes'
import styles from './styles.less'

export function ContactBox({ user, profile }) {

  
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const currentUser = state.currentUser
  const name = [user?.firstName, user?.lastName].filter((part) => !!String(part || '').trim()).join(' ')
  const [visible, setVisible] = useState(false)
 
  const isManagmentUser = isAdmin(state.currentUser?.role)

  // if (!isManagmentUser) { setVisible(false) }

  return (
    <Popover 
      content={ isManagmentUser ? (
        <>
          {/* Email */}
          <div className={styles.item}>
            <Typography.Text strong>{t('contract.page.email')}: </Typography.Text>
            <Email email={user?.email} />
          </div>

          {/* Phone */}
          <div className={styles.item}>
            <Typography.Text strong>{t('contract.page.phone')}: </Typography.Text>
            <Phone phone={user?.phone} />
          </div>

          {/* Company */}
          {profile ? (
            <div className={styles.item}>
              <Typography.Text strong>{t('contract.page.tenantProfile')}: </Typography.Text>
              <Link to={`/profiles-management/${profile.id}`}>
                {profile.companyName}
              </Link>
            </div>
          ) : null}

          {/* Project */}
          {user?.project ? (
            <div className={styles.item}>
              <Typography.Text strong>{t('wbs.table.project')}: </Typography.Text>
              <Link to={`/projects-management/${user.project.sku}`}>
                {user?.project.title}
              </Link>
            </div>
          ) : null}

          {/* WBS */}
          {user?.codes?.length > 0 ? (
            <div className={styles.item}>
              <Typography.Text strong>WBS: </Typography.Text>
              {user.codes.map(wbs => (
                <>
                  <Link to={`/wbs-management/${wbs.id}`} key={wbs.id}>
                    {wbs.code}
                  </Link>
                  ;
                </>
              ))}
            </div>
          ) : null}
        </>
      ) : null}
      title={(
        <>
          {isAdmin(user?.role) ? t('contract.page.agent') : null}
          {isLandlord(user?.role) ? t('contract.page.landlord') : null}
          {isTenant(user?.role) ? t(`user.roles.${USER_ROLES[user?.role].key}`) : null}
        </>
      )}
      trigger="click" 
      visible={visible} 
      onVisibleChange={(value) => setVisible(value)}
    >
      <div className={styles.box}>
        <UserAvatar size="large" user={user} />
        <div className={styles.boxInfo}>
          {/* Role */}
          <Typography.Text type="secondary" className={styles.boxRole}>
            {isAdmin(user?.role) ? t('contract.page.agent') : null}
            {isLandlord(user?.role) ? t('contract.page.landlord') : null}
            {isTenant(user?.role) ? t(`user.roles.${USER_ROLES[user?.role].key}`) : null}
          </Typography.Text>
          {/* Name */}
          <Typography.Text>
            {name}
          </Typography.Text>
        </div>
        <ChevronRight className={styles.arrow} />
      </div>
    </Popover>
  )

}
