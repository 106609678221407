import countries from '@umpirsky/country-list/data/en/country.json'

import custom from './en.json'
import apartments from './modules-en/apartments.json'
import attachments from './modules-en/attachments.json'
import auth from './modules-en/auth.json'
import billruns from './modules-en/billruns.json'
import buildings from './modules-en/buildings.json'
import comission from './modules-en/comissions.json'
import contracts from './modules-en/contracts.json'
import dashboard from './modules-en/dashboard.json'
import gallery from './modules-en/gallery.json'
import invoices from './modules-en/invoices.json'
import logs from './modules-en/logs.json'
import notifications from './modules-en/notifications.json'
import profiles from './modules-en/profiles.json'
import projectCodes from './modules-en/projectCodes.json'
import projects from './modules-en/projects.json'
import recurring from './modules-en/recurring.json'
import rentalRequests from './modules-en/rentalRequests.json'
import reports from './modules-en/reports.json'
import routing from './modules-en/routing.json'
import serviceRequests from './modules-en/serviceRequests.json'
import services from './modules-en/services.json'
import transactions from './modules-en/transactions.json'
import users from './modules-en/users.json'
import widgets from './modules-en/widgets.json'


export const en = {
  countries,
  ...apartments,
  ...attachments,
  ...auth,
  ...buildings,
  ...comission,
  ...contracts,
  ...custom,
  ...dashboard,
  ...gallery,
  ...invoices,
  ...logs,
  ...notifications,
  ...profiles,
  ...projectCodes,
  ...projects,
  ...rentalRequests,
  ...serviceRequests,
  ...transactions,
  ...users,
  ...widgets,
  ...services,
  ...custom,
  ...routing,
  ...recurring,
  ...billruns,
  ...reports,
}
