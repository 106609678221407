import { gql } from 'apollo-boost'

import { profileFragment, recurringPaymentFragment } from '../_fragments'

export const RP_LIST = gql`
  query listRecurringPayments(
    $page: Int!
    $pageSize: Int!
    $sorting: String
    $search: String
    $filter: JSON
  ) {
    response: listRecurringPayments(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      search: $search
      filter: $filter
    ) {
      list {
        ...recurringPaymentFragment
        customerProfile  {
          ...profileFragment
        }
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${recurringPaymentFragment},
  ${profileFragment},
`

export const FETCH_RP_BY_SKU = gql`
  query fetchRecurringPaymentBySku($sku: String!) {
    response: fetchRecurringPaymentBySku(sku: $sku) {
      ...recurringPaymentFragment
      customerProfile {
        ...profileFragment
      }
    }
  }
  ${recurringPaymentFragment},
  ${profileFragment},
`


export const REBUILD_RP = gql`
  query rpRegenerate($sku: String!) {
    response: rpRegenerate(sku: $sku) {
      ...recurringPaymentFragment
    }
  }
  ${recurringPaymentFragment}
`

export const MARK_PAID = gql`
  query rpMarkPaid($sku: String!) {
    response: rpMarkPaid(sku: $sku) {
      ...recurringPaymentFragment
    }
  }
  ${recurringPaymentFragment}
`
