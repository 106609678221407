import styled from 'styled-components'

export const LayoutOverlay = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 80px);
`

export const LayoutCard = styled.div`
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.11), 0 3px 4px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    background-color: #fff;
    padding: 16px 24px;
    margin-bottom: 24px;

    .ant-form-item {
        margin-bottom: 14px;
    }
`

export const LayoutCardTitle = styled.h2`
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
`

export const LayoutStickyFooter = styled.div`
    position: sticky;
    bottom: -50px;
    margin-top: 50px;
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: -50px;
    background: #fff;
    padding: 10px 30px;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.11);

    @supports (backdrop-filter: blur(7px)) {
        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(7px);
    }
`

export const LayoutFooterActions = styled.span`
    display: flex;
    justify-content: flex-end;
    > * {
        margin-left: 10px;
    }
`
