import { gql } from 'apollo-boost'

import {
  apartmentFragment,
  buildingFragment,
  floorPlanFragment,
  photoFragment,
} from '../_fragments'

export const APT_UPSERT_MUTATION = gql`
  mutation apartment($input: ApartmentInput!) {
    response: apartment(input: $input) {
      ...apartmentFragment
      floorPlans {
        ...floorPlanFragment
      }
      photos {
        ...photoFragment
      }
      building {
        ...buildingFragment
        photos {
          ...photoFragment
        }
      }
    }
  }
  ${apartmentFragment}
  ${floorPlanFragment}
  ${photoFragment}
  ${buildingFragment}
`

export const APARTMENT_BOOKING_MUTATION = gql`
  mutation bookApartment($id: Int!) {
    response: bookApartment(id: $id)
  }
`

export const APT_DELETE_MUTATION = gql`
  mutation bookApartment($id: Int!) {
    deleteApartment(id: $id)
  }
`
