import { ApolloProvider } from '@apollo/client'
import { ProjectProvider } from 'common/ProjectProvider'
import { TutorialUIProvider } from 'components/TutorialUI'
import { client } from 'GQLClient'
import { BaseLayout } from 'layouts/BaseLayout'
import BaseView from 'layouts/BaseView'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { StateProvider } from 'state'

const initialState = {
  rightBarExpanded: false,
  get loggedIn() {
    return !!localStorage.getItem('token')
  },
  rightBarComponent: null,
  leftBarSM: false,
  mapOverlay: false,
  currentBuilding: {},
  // amenities: {},
  modal: {
    isOpen: false,
    imgUrl: null,
  },
  // places: [],
  // buildings: [],
}

const reducer = (state, action) => {
  if (!action) return state

  switch (action.type) {
  case 'EXPAND_RIGHT_BAR': {
    return { ...state, rightBarExpanded: true, notClosableRightBar: action.notClosableRightBar }
  }
  case 'COLLAPSE_RIGHT_BAR': {
    return { ...state, rightBarExpanded: false, notClosableRightBar: false }
  }
  case 'TOGGLE_RIGHT_BAR': {
    return { ...state, rightBarExpanded: !state.rightBarExpanded, notClosableRightBar: action.notClosableRightBar }
  }
  case 'TOGGLE_LEFT_BAR_SM': {
    return { ...state, leftBarSM: !state.leftBarSM }
  }
  case 'SET_RIGHT_BAR_COMPONENT': {
    return { ...state, rightBarComponent: action.payload }
  }
  case 'SET_MAP_OVERLAY': {
    return { ...state, mapOverlay: action.payload }
  }
  case 'SET_CURRENT_BUILDING': {
    return { ...state, currentBuilding: action.payload }
  }
  // case 'SET_BUILDINGS': {
  //   return { ...state, buildings: action.payload }
  // }
  case 'LOGIN': {
    return { ...state, loggedIn: action.payload }
  }
  case 'REFETCH': {
    return { ...state, refetch: action.payload }
  }
  case 'REFETCH_CLEAR': {
    return { ...state, refetch: '' }
  }
  // case 'AMENITIES': {
  //   return { ...state, amenities: action.payload }
  // }

  case 'MODAL': {
    return { ...state, modal: action.payload }
  }

  case 'GOOGLE_ADDRESS': {
    return { ...state, address: action.payload }
  }

  case 'CURRENT_USER': {
    return { ...state, currentUser: action.payload }
  }

  case 'BUILDING_HOVER': {
    return { ...state, hoveredBuilding: action.payload }
  }

  default:
    return state
  }
}

function App() {
  return (
    <HelmetProvider>
      <ProjectProvider>
        <StateProvider initialState={initialState} reducer={reducer}>
          <ApolloProvider client={client}>
            <TutorialUIProvider>
              <BaseView>
                <BaseLayout />
              </BaseView>
            </TutorialUIProvider>
          </ApolloProvider>
        </StateProvider>
      </ProjectProvider>
    </HelmetProvider>
  )
}

export { App }
