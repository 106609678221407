import { Checkbox, DatePicker as AntDatePicker, Form } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

function disabledDateHanlder(date, value, range) {
  const dateNow = moment().startOf('day').valueOf()
  const dateFrom = value.dateFrom?.startOf?.('day')?.valueOf?.() || dateNow
  const dateCurrent = date.startOf('day').valueOf()

  if (range === 'start') {
    return (
      dateNow > dateCurrent
    )
  }

  if (range === 'end') {
    return !(dateCurrent > dateFrom)
  }

  return true
}

function DatePicker({ 
  value = null,
  onChange,
  ...props
}) {
  const { t } = useTranslation()

  const setDate = (date) => {
    onChange?.(date)
  }

  return (
    <AntDatePicker
      value={value ? moment(value) : null}
      allowEmpty={false}
      onChange={setDate}
      {...props}
    />
  )
}

export { DatePicker }
