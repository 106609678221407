/* eslint-disable */
import { Col, DatePicker, Divider,Form, InputNumber, Row, Tooltip } from 'antd'
import { cost } from 'components/ListManager/DefaultTypes'
import moment from 'moment'
import React from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

const RentalConditionsEdit = () => {
  const { t } = useTranslation()
  const now = DateTime.now()
  return (
    <>
      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col xs={14}>
          <p style={{ fontWeight: '600', fontSize: '14px' }}>{t('apartments.fields.basic-rent')}:</p>
        </Col>
        <Col xs={10}>
          <Form.Item name="monthPrice">
            <InputNumber min={0} defaultValue={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col xs={14}>
          <p style={{ fontWeight: '600', fontSize: '14px' }}>{t('apartments.fields.utilitiesPrice')}:</p>
        </Col>
        <Col xs={10}>
          <Form.Item name="utilitiesPrice">
            <InputNumber min={0} defaultValue={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col xs={14}>
          <p style={{ fontWeight: '600', fontSize: '14px' }}>{t('apartments.fields.deposit')}:</p>
        </Col>
        <Col xs={10}>
          <Form.Item name="deposit">
            <InputNumber min={0} defaultValue={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col xs={14}>
          <p style={{ fontWeight: '600', fontSize: '14px' }}>{t('apartments.fields.freeFrom')}</p>
        </Col>
        <Col xs={10}>
          <Form.Item name="freeFrom">
            <DatePicker defaultValue={now} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col xs={14}>
          <p style={{ fontWeight: '600', fontSize: '14px' }}>{t('apartments.fields.minRentalPeriod')}</p>
        </Col>
        <Col xs={10}>
          <Form.Item name="minRentalPeriod">
            <InputNumber style={{ width: '100%' }} defaultValue={1} min={1} value={1} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

const RentalConditionsView = ({ data, profile }) => {
  const { t } = useTranslation()
  return (
    <>
      <Row gutter={20}>
        <Col xs={14}>
          <p>{t('apartments.fields.basic-rent')}:</p>
        </Col>
        <Col xs={10}>
          <p><b>{cost({ value: data.monthPrice })}</b></p>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={14}>
          <p>{t('apartments.fields.utilitiesPrice')}:</p>
        </Col>
        <Col xs={10}>
          <p><b>{cost({ value: data.utilitiesPrice })}</b></p>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={14}>
          <p>{t('apartments.fields.deposit')}:</p>
        </Col>
        <Col xs={10}>
          <p><b>{cost({ value: data.deposit })}</b></p>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={14}>
          <p>{t('profiles.fields.taxRate')}:</p>
        </Col>
        <Col xs={10}>
          <p>
            <b>
              <span>{profile?.taxRate ? `${Math.floor(profile?.taxRate * 100)}%` : '-'} </span>
              <Tooltip placement="top" title={t('apartments.fields.basic-rent')}>
                / {cost({ value: data.monthPrice * profile?.taxRate })} 
              </Tooltip>
              <Tooltip placement="top" title={t('apartments.fields.utilitiesPrice')}>
                / {cost({ value: data.utilitiesPrice * profile?.taxRate })} 
              </Tooltip>
            </b></p>
        </Col>
      </Row>

      <Divider />

      <Row gutter={20}>
        <Col xs={14}>
          <p>{t('apartments.fields.freeFrom')}</p>
        </Col>
        <Col xs={10}>
          <p>
            <b>{data.freeFrom && moment(data.freeFrom).format('DD MMMM, YYYY')}</b>
          </p>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={14}>
          <p>{t('apartments.fields.minRentalPeriod')}</p>
        </Col>
        <Col xs={10}>
          <p>
            <b>
              {data.minRentalPeriod} {t('apartments.fields.month')} 
            </b>
          </p>
        </Col>
      </Row>
    </>
  )
}

export { RentalConditionsEdit, RentalConditionsView }
