import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import {
  Container,
  MainPhotoContainer,
  NextImg,
  PrevImg,
  Thumbnail,
  ThumbnailsContainer,
  ThumbnailWrapper,
} from './styled'

const GalleryRO = (props) => {
  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [renderTrigger, setRenderTrigger] = useState(false)
  const [state, dispatch] = useGlobalState()

  const thumbContainerRef = useRef(null)

  useEffect(() => {
    setRenderTrigger(!renderTrigger)
  }, [thumbContainerRef.current?.offsetWidth, props.images])

  useEffect(() => {
    const keyPressFn = (e) => {
      if (state.modal.isOpen) {
        const { keyCode } = e
        const getNextIndex = (current, len) => {
          if (current === len - 1) return 0
          return current + 1
        }
        const getPrevIndex = (current, len) => {
          if (current === 0) return len - 1
          return current - 1
        }

        if (keyCode === 39) {
          setCurrentIndex(getNextIndex(currentIndex, props.images.length))
          dispatch({
            type: 'MODAL',
            payload: {
              isOpen: true,
              imgUrl: props.images[getNextIndex(currentIndex, props.images.length)].url,
            },
          })
        }
        if (keyCode === 37) {
          setCurrentIndex(getPrevIndex(currentIndex, props.images.length))
          dispatch({
            type: 'MODAL',
            payload: {
              isOpen: true,
              imgUrl: props.images[getPrevIndex(currentIndex, props.images.length)].url,
            },
          })
        }
      }
    }

    window.addEventListener('keydown', keyPressFn)
    return () => window.removeEventListener('keydown', keyPressFn)
  }, [state.modal.isOpen])

  if (!props.images?.length) {
    return (
      <Container>
        <MainPhotoContainer height={props.height}>
          <div className="current">
            <div style={{ textAlign: 'center' }}>
              <h2>{t('gallery.messages.empty')}</h2>
            </div>
          </div>
        </MainPhotoContainer>
      </Container>
    )
  }

  return (
    <>
      <Container>
        {props.images[currentIndex]?.url && (
          <>
            <MainPhotoContainer current={props.images[currentIndex].url} height={props.height}>
              <div
                role="none"
                className="current"
                onClick={() => {
                  dispatch({
                    type: 'MODAL',
                    payload: {
                      isOpen: true,
                      imgUrl: props.images[currentIndex].url,
                    },
                  })
                }}
              >
                {props.images.length > 1 && (
                  <>
                    <PrevImg
                      onClick={(e) => {
                        e.stopPropagation()
                        return currentIndex > 0 ? setCurrentIndex(currentIndex - 1) : setCurrentIndex(props.images.length - 1)
                      }}
                    />
                    <NextImg
                      onClick={(e) => {
                        e.stopPropagation()
                        return currentIndex < props.images.length - 1 ? setCurrentIndex(currentIndex + 1) : setCurrentIndex(0)
                      }}
                    />
                  </>
                )}
              </div>
            </MainPhotoContainer>
            {props.images.length > 1 && (
              <ThumbnailsContainer ref={thumbContainerRef}>
                <div className="arr-left" />

                {props.images.map((it, index) => (
                  <ThumbnailWrapper
                    key={it.url}
                    containerWidth={thumbContainerRef?.current?.offsetWidth}
                    thumbCount={props.thumbCount || 6}
                  >
                    <Thumbnail active={index === currentIndex} url={it.url} onClick={() => setCurrentIndex(index)} />
                  </ThumbnailWrapper>
                ))}
                <div className="arr-right" />
              </ThumbnailsContainer>
            )}
          </>
        )}
      </Container>
    </>
  )
}

export { GalleryRO }
