import { attachmentFragment } from '_graphql/_fragments'
import { gql } from 'apollo-boost'

export const UPLOAD_ATTACHMENT = gql`
  mutation($file: Upload, $relationType: String, $relationId: Int, $directory: String) {
    attachmentUpload(file: $file, directory: $directory, relationType: $relationType, relationId: $relationId) {
      id
      relationType
      relationId
      context
      ownerId
      createdAt
    }
  }
`
