import {
  APARTMENTS_LIST,
  BUILDINGS_LIST,
  REQUESTS_LIST,
  WBS_LIST,
} from '_graphql'
import {
  detectCurrentProjectStatus,
  isAdmin,
  roles,
  USER_ROLES,
} from '_graphql/_enums'
import { APARTMENT_QUERY } from '_graphql/apartment'
import { PROFILE_LIST } from '_graphql/profile'
import { PROJECT_LIST } from '_graphql/project'
import { PROJECT_UPSERT_MUTATION } from '_graphql/project'
import {
  RENTAL_CONTRACT_UPSERT_MUTATION,
  RENTAL_CONTRACTS_LIST,
} from '_graphql/rentalContract'
import { ALL_USERS_QUERY, USERS_LIST } from '_graphql/user/queries'
import {
  BankOutlined,
  CrownOutlined,
  EllipsisOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { navigate, useLocation, useParams } from '@reach/router'
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { NotFound } from 'components'
import { UserSelect } from 'components/Form/UserSelect'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { ContextMenuTrigger } from './styled'

const keys = [
  'title',
  'ownerId',
  'managerId',
  'description',
  'startedAt',
  'expireAt',
  'city',
  'street',
  'zipCode',
  'defaultBudget',
  'pmoId',
]

const ProjectForm = ({ asComponent, onClose, contract, isModal, userId }) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const [, rerender] = useState()

  const { data: customerData, loading: customerLoading } = useQuery(
    USERS_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
        filter: JSON.stringify({
          role: [USER_ROLES['tentant-admin'].key],
        }),
      },
    }
  )

  const { data: usersData, loading: usersLoading } = useQuery(ALL_USERS_QUERY)

  const [upsertProject] = useMutation(PROJECT_UPSERT_MUTATION)

  const [form] = Form.useForm()

  const role = state.currentUser?.role

  const location = useLocation()
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )

  const goBack = (sku = '', id) => {
    if (onClose) {
      onClose?.(id)
    } else if (query.has('backUrl')) {
      navigate(query.get('backUrl'))
    } else {
      navigate(`/projects-manager${sku ? `/${sku}` : ''}`)
    }
  }

  useEffect(() => {
    if (userId) {
      form.setFieldsValue({ ownerId: userId })
    }
  }, [userId])

  const saveProject = async () => {
    const values = form.getFieldsValue(keys)

    const d = await upsertProject({
      variables: {
        input: {
          ...values,
          defaultBudget: parseFloat(values.defaultBudget),
          ownerId: values.ownerId ? values.ownerId : state.currentUser?.ownerId
        },
      },
    })

    if (d.data?.response) {
      message.success('created')
      goBack(d.data?.response?.sku, d.data?.response?.id)
    } else {
      message.error('creation error')
    }
  }

  const formElements = (
    <>
      <Row gutter={[20, 20]}>
        <Col xs={16}>
          <LayoutCard>
            <Row gutter={[20, 20]}>
              <Col xs={12}>
                <Form.Item
                  name="title"
                  label={t('projects.form.title')}
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t('projects.form.titleHolder')} />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="defaultBudget"
                  label={t('projects.form.defaultBudget')}
                  required
                  rules={[{ required: true }]}
                >
                  <Input type='number'/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col xs={8}>
                <Form.Item
                  name="city"
                  label={t('projects.form.city')}
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t('projects.form.cityHolder')} />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  name="street"
                  label={t('projects.form.street')}
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t('projects.form.streetHolder')} />
                </Form.Item>
              </Col>
           
              <Col xs={8}>
                <Form.Item
                  name="zipCode"
                  label={t('projects.form.zipCode')}
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t('projects.form.zipCodeHolder')} />
                </Form.Item>
              </Col>
           
             
            </Row>

         
            <Row gutter={[20, 20]}>
              <Col xs={8}>
                {/* Manager picker */}
                {isAdmin(role) ? (
                  <Form.Item
                    name="ownerId"
                    label={t('projects.form.tenantAdmin')}
                  >
                    <UserSelect disabled={!!userId} roles={[USER_ROLES['tentant-admin'].key]} />
                  </Form.Item>
                ) : null}
              </Col>
              <Col xs={8}>
                {/* User */}
                <Form.Item
                  label={t('projects.form.managerId') + ':'}
                  name="managerId"
                  rules={[{ required: true }]}
                >
                  <UserSelect
                    roles={[
                      USER_ROLES['tentant-admin'].key,
                      USER_ROLES['tentant-manager'].key,
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={8}>
                {/* User */}
                <Form.Item
                  label={t('projects.form.pmo') + ':'}
                  name="pmoId"
                  rules={[{ required: true }]}
                >
                  <UserSelect
                    roles={[
                      USER_ROLES['tentant-admin'].key,
                      USER_ROLES['tentant-project-manager'].key,
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col xs={12}>
                <Form.Item
                  name="startedAt"
                  label={t('projects.form.startedAt')}
                  required
                  rules={[{ required: true }]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="expireAt"
                  label={t('projects.form.expireAt')}
                  required
                  rules={[{ required: true }]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col xs={24}>
                <Form.Item
                  name="description"
                  label={t('projects.form.description')}
                  required
                  rules={[{ required: true }]}
                >
                  <TextArea
                    placeholder={t('projects.form.descriptionHolder')}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
          </LayoutCard>
        </Col>
        <Col xs={8}>
          <LayoutCard>
            <Alert
              message={t('projects.form.info.title')}
              description={t('projects.form.info.description')}
              type="info"
              showIcon
            />
          </LayoutCard>
        </Col>
      </Row>
    </>
  )

  const formFooter = (
    <LayoutFooterActions>
      <Button size="large" type="default" onClick={() => goBack()}>
        {t('projects.form.cancel')}
      </Button>
      <Form.Item noStyle>
        <Button size="large" type="primary" htmlType="submit">
          {t('projects.form.save')}
        </Button>
      </Form.Item>
    </LayoutFooterActions>
  )

  if (asComponent) {
    return (
      <Form
        layout="vertical"
        form={form}
        onFinish={saveProject}
        onFieldsChange={rerender}
      >
        {formElements}
        {formFooter}
      </Form>
    )
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={saveProject}
      onFieldsChange={rerender}
      onChange={() => {
        // setSaveTrigger(!saveTrigger);
        console.log('Change')
      }}
    >
      <LayoutOverlay>
        <Row gutter={[20, 20]}>
          <Col xs={20} style={{ textAlign: 'left' }}>
            <h2 style={{ fontSize: '24px' }}>{t('projects.form.new')}</h2>
          </Col>
        </Row>

        <Row gutter={[20, 20]}>
          <Col xs={isModal ? 24 : 16}>{formElements}</Col>

          {!isModal ? (
            <Col xs={8}>
              <LayoutCard>
                <Alert
                  message={t('projects.form.alert.title')}
                  description={t('projects.form.alert.description')}
                  type="info"
                  showIcon
                />
              </LayoutCard>
            </Col>
          ) : null}
        </Row>

        <LayoutStickyFooter>{formFooter}</LayoutStickyFooter>
      </LayoutOverlay>
    </Form>
  )
}

export { ProjectForm }
