import { gql } from 'apollo-boost'

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $email: String!
    $code: String
    $password: String
  ) {
    data: resetPassword(
      email: $email
      code: $code
      password: $password
    ) {
      user {
        id
      }
      token
    }
  }
`
