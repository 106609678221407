import { gql } from 'apollo-boost'

import { apartmentFragment } from './apartment'
import { buildingFragment } from './building'

export const rentalContractFragment = gql`
    ${apartmentFragment}
    ${buildingFragment}
    fragment rentalContractFragment on RentalContract {
      id
      sku
      status
      tenantId
      tenantProfileId
      salesId
      landlordId
      landlordProfileId
      apartmentId
      projectId
      wbsId
      dateFrom
      dateTill
      updatedAt
      apartment { 
        ...apartmentFragment
        building {
          ...buildingFragment
        }
      }
    }
`
