import { Space } from 'antd'
import React, { useEffect,useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { parseJson } from 'utils/parseJson'

import { roomsInitialState } from './roomsInitialState'
import { RoomView } from './RoomView'

const reducer = (state, payload) => ({ ...state, ...payload })

const RoomsView = (props) => {
  const { t } = useTranslation()
  const [roomsState, dispatchRoomsState] = useReducer(reducer, roomsInitialState)

  useEffect(() => {
    const rooms = props.apartment.roomOptions
    dispatchRoomsState(rooms)
  }, [props.apartment])

  return (
    <Space direction="vertical" size={40} style={{ width: '100%' }}>
      <Space direction="vertical" size={15} style={{ width: '100%' }}>
        <h2>{t('buildings.fields.bedroom.title')}</h2>

        {roomsState.bedroom.map((roomData, i) => (
          <RoomView roomType="bedroom" data={roomData} key={'bedroom' + i} />
        ))}
      </Space>

      <Space direction="vertical" size={15} style={{ width: '100%' }}>
        <h2>{t('buildings.fields.bathroom.title')}</h2>

        {roomsState.bathroom.map((roomData, i) => (
          <RoomView roomType="bathroom" data={roomData} key={'bathroom' + i} />
        ))}
      </Space>

      <Space direction="vertical" size={15} style={{ width: '100%' }}>
        <h2>{t('buildings.fields.kitchen.title')}</h2>

        {roomsState.kitchen.map((roomData, i) => (
          <RoomView roomType="kitchen" data={roomData} key={'kitchen' + i} />
        ))}
      </Space>

      {/* Other rooms */}
      {roomsState.balkony?.length ? (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.balcony.title')}</h2>

          {roomsState.balkony.map((roomData, i) => (
            <RoomView roomType="custom" data={roomData} key={'balkony' + i} />
          ))}
        </Space>
      ) : null}

      {roomsState.livingRoom?.length ? (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.livingRoom.title')}</h2>

          {roomsState.livingRoom.map((roomData, i) => (
            <RoomView roomType="custom" data={roomData} key={'livingRoom'+ i} />
          ))}
        </Space>
      ) : null}

      {roomsState.diningRoom?.length ? (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.diningRoom.title')}</h2>

          {roomsState.diningRoom.map((roomData, i) => (
            <RoomView roomType="custom" data={roomData} key={'diningRoom' + i} />
          ))}
        </Space>
      ) : null}

      {roomsState.laundryRoom?.length ? (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.laundryRoom.title')}</h2>

          {roomsState.laundryRoom.map((roomData, i) => (
            <RoomView roomType="custom" data={roomData} key={'laundryRoom' +i} />
          ))}
        </Space>
      ) : null}

      {roomsState.workRoom?.length ? (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.workRoom.title')}</h2>

          {roomsState.workRoom.map((roomData, i) => (
            <RoomView roomType="custom" data={roomData} key={'workRoom' + i} />
          ))}
        </Space>
      ) : null}

      {roomsState.customRoom?.length ? (
        <Space direction="vertical" size={15} style={{ width: '100%' }}>
          <h2>{t('buildings.fields.customRoom.title')}</h2>

          {roomsState.customRoom.map((roomData, i) => (
            <RoomView roomType="custom" data={roomData} key={'customRoom' + i} />
          ))}
        </Space>
      ) : null}
    </Space>
  )
}

export { RoomsView }
