import { colors } from '_colors'
import { Card } from 'antd'
import styled from 'styled-components'

export const StyledCard = styled(Card)`
  ${(p) => p.selected && `border-color: ${colors.main()}`};
  ${(p) => p.accepted && `border-color: ${colors.green()}`};
  h2 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    padding: 0;
  }

  .amenities {
    margin-top: 16px;
  }

  span.no-data {
    color: ${colors.main()}
  }
`

export const StyledMiniCard = styled(Card)`
  .ant-card-head-title {
    font-size: 14px;
  }

  .ant-card-head, .ant-card-body {
    padding: 0 8px;
  }

  p {
    font-size: 12px;
    margin-bottom: 4px;
    padding: 0;
  }

  p.value {
    font-weight: 700;
  }

  span.no-data {
    color: ${colors.main()}
  }
`
