import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button,Popconfirm, Tooltip } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DragDropContext, Draggable,Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { Container, DeleteIcon, MainPhotoContainer, NextImg, PrevImg,Thumbnail, ThumbnailsContainer, ThumbnailWrapper, UploadIcon } from './styled'

// const getListStyle = (isDraggingOver) => ({
//   background: isDraggingOver ? 'lightblue' : 'lightgrey',
//   padding: 8,
//   width: 250,
// });

const getItemStyle = (draggableStyle, isDragging) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 8 * 2,
  marginBottom: 8,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',
  boxShadow: '1px 0 1px rgba(0,0,0,0.03), 2px 0 2px rgba(0,0,0,0.03), 4px 0 4px rgba(0,0,0,0.03), 6px 0 8px rgba(0,0,0,0.03), 8px 0 16px rgba(0,0,0,0.03)',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const Gallery = (props) => {
  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [renderTrigger, setRenderTrigger] = useState(false)
  const [dragOver, setDragOver] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [state, dispatch] = useGlobalState()

  const thumbContainerRef = useRef(null)
  const inputRef = useRef(null)

  const images = useMemo(() => {
    return [...props.images].sort((a, b) => a.order - b.order)
  }, [props.images])

  useEffect(() => {
    setRenderTrigger(!renderTrigger)
  }, [thumbContainerRef.current?.offsetWidth, images])

  useEffect(() => {
    const keyPressFn = (e) => {
      if (state.modal.isOpen) {
        const { keyCode } = e
        const getNextIndex = (current, len) => {
          if (current === len - 1) return 0
          return current + 1
        }
        const getPrevIndex = (current, len) => {
          if (current === 0) return len - 1
          return current - 1
        }

        if (keyCode === 39) {
          setCurrentIndex(getNextIndex(currentIndex, images.length))
          dispatch({
            type: 'MODAL',
            payload: {
              isOpen: true,
              imgUrl: images[getNextIndex(currentIndex, images.length)].url,
            },
          })
        }
        if (keyCode === 37) {
          setCurrentIndex(getPrevIndex(currentIndex, images.length))
          dispatch({
            type: 'MODAL',
            payload: {
              isOpen: true,
              imgUrl: images[getPrevIndex(currentIndex, images.length)].url,
            },
          })
        }
      }
    }

    window.addEventListener('keydown', keyPressFn)
    return () => window.removeEventListener('keydown', keyPressFn)
  }, [state.modal.isOpen, currentIndex])

  const onDelete = (id) => {
    if (!props.deleteFunc) throw Error('The "deleteFunc" prop was not provided to Gallery component')

    if (currentIndex === images?.length - 1 && images?.length > 1) {
      setCurrentIndex(currentIndex - 1)
    }

    props.deleteFunc(id)
  }

  if (!images) {
    return null
  }

  const onDragOver = (e) => {
    setDragOver(true)
    e.preventDefault()
    e.stopPropagation()
  }

  const onDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setUploading(true)

    setDragOver(false)

    const { files } = e.dataTransfer || e.target
    const filePromises = []

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i]
      filePromises.push(props.uploadFunc(file))
    }

    Promise.all(filePromises).then(() => {
      setDragOver(false)
      setUploading(false)
    })
  }

  if (!images?.length) {
    return (
      <Container
        onClick={() => { }}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragLeave={() => setDragOver(false)}
        onDragEnd={() => setDragOver(false)}
        onDragExit={() => setDragOver(false)}
      >
        <input type="file" ref={inputRef} multiple={props.multiple} style={{ display: 'none' }} onChange={onDrop} />
        <MainPhotoContainer height={props.height} dragOver={dragOver} notRounded={props.notRounded}>
          <div className="current">
            <div style={{ textAlign: 'center' }}>
              {uploading && <LoadingOutlined style={{ fontSize: '50px' }} />}
              {!uploading && (dragOver ? <h2>{t('gallery.messages.drop-to-upload')}</h2> : <h2>{t('gallery.messages.drag-to-upload')}</h2>)}
              {!uploading && (
                <Button
                  onClick={() => {
                    if (inputRef.current) inputRef.current.click()
                  }}
                  icon={<CloudUploadOutlined />}
                  size="large"
                  type="ghost"
                >
                  {t('gallery.actions.upload')}
                </Button>
              )}
            </div>
          </div>
        </MainPhotoContainer>
      </Container>
    )
  }

  return (
    <>
      <Container
        onClick={() => { }}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragLeave={() => setDragOver(false)}
        onDragEnd={() => setDragOver(false)}
        onDragExit={() => setDragOver(false)}
        dragOver={dragOver}
      >
        <input type="file" ref={inputRef} multiple={props.multiple} style={{ display: 'none' }} onChange={onDrop} />
        {/* dragOver ? (
          <div style={{ height: '550px', width: '100%' }} />
        ) : ( */
          images?.[currentIndex]?.url && (
            <>
              <MainPhotoContainer current={images[currentIndex].url} height={props.height}>
                {uploading
                  && (
                    <LoadingOutlined
                      style={{ fontSize: '50px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    />
                  )}
                <div
                  className="current"
                  onClick={() => {
                    dispatch({
                      type: 'MODAL',
                      payload: {
                        isOpen: true,
                        imgUrl: images[currentIndex].url,
                      },
                    })
                  }}
                  role="none"
                >
                  <div className="actionButtons" onClick={(e) => e.stopPropagation()} role="none">
                    <div>
                      <span>{images[currentIndex].filename}</span>
                    </div>
                    <div>
                      <Tooltip title={t('gallery.messages.upload-more')}>
                        <UploadIcon
                          onClick={(e) => {
                            if (inputRef.current) {
                              e.stopPropagation()
                              inputRef.current.click()
                            }
                          }}
                        />
                      </Tooltip>
                      <Popconfirm
                        placement="bottom"
                        title={`${t('gallery.messages.delete')}?`}
                        onConfirm={() => onDelete(images[currentIndex].id)}
                        okText={t('gallery.actions.yes')}
                        cancelText={t('gallery.actions.no')}
                      >
                        <Tooltip title={t('gallery.messages.delete')}>
                          <DeleteIcon />
                        </Tooltip>
                      </Popconfirm>
                    </div>
                  </div>

                  {images.length > 1
                    && (
                      <>
                        <PrevImg
                          onClick={(e) => {
                            e.stopPropagation()
                            return currentIndex > 0 ? setCurrentIndex(currentIndex - 1) : setCurrentIndex(images.length - 1)
                          }}
                        />
                        <NextImg
                          onClick={(e) => {
                            e.stopPropagation()
                            return currentIndex < images.length - 1 ? setCurrentIndex(currentIndex + 1) : setCurrentIndex(0)
                          }}
                        />
                      </>
                    )}
                </div>
              </MainPhotoContainer>

              <DragDropContext
                onDragEnd={(result) => {
                  if (!result?.destination || !props.onReorder) return null
                  props.onReorder(result.source.index, result.destination.index, images)
                  return null
                }}
              >
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided) => (
                    <div ref={thumbContainerRef}>
                      <ThumbnailsContainer ref={provided.innerRef} {...provided.droppableProps}>
                        <div
                          ref={provided.innerRef}
                          // style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >

                          {images
                            ? images.map((it, index) => (
                              <Draggable key={it.url} index={index} draggableId={`${it.url}${it.order}`}>
                                {(_provided, snapshot) => (
                                  <>
                                    <ThumbnailWrapper
                                      key={it.url}
                                      containerWidth={thumbContainerRef?.current?.offsetWidth}
                                      thumbCount={props.thumbCount || 6}
                                      ref={_provided.innerRef}
                                      style={getItemStyle(
                                        _provided.draggableStyle,
                                        snapshot.isDragging,
                                      )}
                                      {..._provided.draggableProps}
                                      {..._provided.dragHandleProps}
                                    >
                                      <Thumbnail
                                        active={index === currentIndex}
                                        url={it.url}
                                        onClick={() => setCurrentIndex(index)}
                                      />
                                    </ThumbnailWrapper>
                                    {_provided.placeholder}
                                  </>
                                )}
                              </Draggable>
                            ))
                            : null}
                          {provided.placeholder}
                        </div>
                      </ThumbnailsContainer>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )
        }
      </Container>
    </>
  )
}

export { Gallery }
