import { colors } from '_colors'
import { Card, Descriptions } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ProfileInfoCard = ({ profile, hoverable, bordered, withTitle }) => {
  const { t } = useTranslation()
  if (!profile) {
    return (
      <>{t('apartments.noProfile')}</>
    )
  }

  return (
    <>
      {withTitle
        ? (
          <h2>
            {t('profiles.fields.profileName')}: {profile.profileName}
          </h2>
        )
        : null}
      <p>{t('profiles.sections.contact-details')}</p>
      <Descriptions layout="horizontal" bordered size="small">
        <Descriptions.Item span={3} label={t('profiles.fields.contactFullName')}>{profile.contactFullName}</Descriptions.Item>
        <Descriptions.Item span={3} label={t('profiles.fields.phoneNumber')}>{profile.phoneNumber}</Descriptions.Item>
        <Descriptions.Item span={3} label={t('profiles.fields.correspondanceEmail')}>{profile.correspondanceEmail}</Descriptions.Item>
        <Descriptions.Item span={3} label={t('profiles.fields.taxRate')}>{profile.taxRate ? `${Math.floor(profile.taxRate * 100)}%` : '-'}</Descriptions.Item>
      </Descriptions>
    </>
  )
}

export { ProfileInfoCard }
