import { ME_QUERY } from '_graphql'
import { useLazyQuery } from '@apollo/react-hooks'
import { Redirect } from '@reach/router'
import { Spin } from 'antd'
import AuthLayout from 'layouts/Auth'
import DashboardLayout from 'layouts/Dashboard'
import React, { PureComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_ROUTE,navSetup } from 'routing'
import { Forgot,Login, Registration } from 'screens'
import { Verify } from 'screens/Auth'
import { useGlobalState } from 'state'
import { TutorialsProvider } from 'tutorials'

import { MapOverlay } from './MapOverlay'
import { RightOverlay } from './RightOverlay'
import { WebPush } from './WebPush'

const BaseLayout = () => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()

  /** Current user */
  const [currentSessionToken, setCurrentSessionToken] = useState(localStorage.getItem('token'))
  useEffect(() => {
    const localToken = localStorage.getItem('token')
    if (currentSessionToken !== localToken) {
      setCurrentSessionToken(localToken)
    }
  }, [state.loggedIn, currentSessionToken])
  const [fetchCurrentUser, { data: currentUserData, loading: currentUserLoading, error: currentUserError }] = useLazyQuery(ME_QUERY, { fetchPolicy: 'no-cache' })

  // Fetch user, if token exist's
  useEffect(() => {
    // Unauthorized
    if (currentUserError) {
      dispatch({
        type: 'LOGIN',
        payload: false,
      })
      return
    }

    // Authorized
    if (currentUserData && state.currentUser?.id !== currentUserData.response?.id) {
      dispatch({
        type: 'CURRENT_USER',
        payload: currentUserData.response,
      })
    }
  }, [currentUserData, currentUserError])

  useEffect(() => {
    if (currentSessionToken) {
      fetchCurrentUser()
    }
  }, [currentSessionToken, state.loggedIn])

  


  // Unauthorized screens
  if (!state.loggedIn) {
    return (
      <AuthLayout>
        <Registration path="/auth/registration" />
        <Forgot path="/auth/forgot" />
        <Redirect from="*" to="/auth/login" noThrow />
        <Login path="/auth/login" />
        <Verify path="/auth/verify" />
      </AuthLayout>
    )
  }

  // Users Dasboard
  return (
    
    <Spin size="large" tip={t('layout.fetching-user-message')} style={{ width: '100%', height: '100%' }} spinning={currentUserLoading}>
      { state.currentUser ? (
        <>
          {/* Dashboard */}
          <TutorialsProvider>
            <DashboardLayout>
              {/* Routes */}
              {navSetup.map((it) => (it.screen && it.roles.includes(state.currentUser?.role) 
                ? <it.screen key={it.route} path={it.route} />
                : null // Можно вывести 404 ошибку
              ))}
              <Redirect from="*" to={DEFAULT_ROUTE[state.currentUser?.role] ?? DEFAULT_ROUTE.default} noThrow />
            </DashboardLayout>
          </TutorialsProvider>

          {/* Plugins */}
          <>
            {/* Map Overlay */}
            <MapOverlay />
            {/* Right Overlay */}
            <RightOverlay />
            {/* WEB Push */}
            <WebPush />
          </>
        </>
      ) : null}

    </Spin>
  )
}

export { BaseLayout }
