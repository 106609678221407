import { gql } from 'apollo-boost'

import { comissionPaymentFragment } from '../_fragments'

export const CP_UPSERT_MUTATION = gql`
  mutation comissionPayment($input: ComissionPaymentInput!) {
    response: comissionPayment(input: $input) {
      ...comissionPaymentFragment
    }
  }
  ${comissionPaymentFragment}
`

export const DELETE_CP = gql`
  mutation deleteCP($sku: String) {
    response: deleteCP(sku: $sku)
  }
`
