import { gql } from 'apollo-boost'

import { invoiceFragment } from '../_fragments'

export const INVOICE_UPSERT_MUTATION = gql`
  mutation invoice($input: InvoiceInput!) {
    response: invoice(input: $input) {
      ...invoiceFragment
    }
  }
  ${invoiceFragment}
`

export const INVOICE_DELETE_MUTATION = gql`
  mutation archiveInvoice($id: Int!) {
    archiveInvoice(id: $id)
  }
`
