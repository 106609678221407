import { gql } from 'apollo-boost'

import { profileFragment, userFragment } from '../_fragments'

export const PROFILE_LIST = gql`
    query listProfiles($page: Int!, $pageSize: Int!, $sorting: String, $search: String, $filter: JSON) {
      response: listProfiles(page: $page, pageSize: $pageSize, sorting: $sorting, search: $search, filter: $filter) {
        list {
          ...profileFragment
          user {
            ...userFragment
          }
        }
        pagination {
          page
          pageSize
          total
        }  
      }
  }
  ${profileFragment}
  ${userFragment}
`

export const ALL_PROFILES_QUERY = gql`
  query allProfiles {
    response: allProfiles {
      ...profileFragment
      user {
        ...userFragment
      }
    }
  }
  ${profileFragment}
  ${userFragment}
`

export const PROFILE_QUERY = gql`
  query findProfile($id: Int) {
    response: findProfile(id: $id) {
      ...profileFragment
      user {
        ...userFragment
      }
    }
  }
  ${profileFragment}
  ${userFragment}
`

export const PROFILE_QUERY_LIGHT = gql`
  query findProfile($id: Int) {
    response: findProfile(id: $id) {
      ...profileFragment
    }
  }
  ${profileFragment}
`

export const MY_PROFILE_QUERY = gql`
  query fetchProfile {
    response: fetchProfile {
      ...profileFragment
    }
  }
  ${profileFragment}
`
