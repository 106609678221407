import { ALL_BUILDINGS_QUERY } from '_graphql'
import ChevronLeft from '@2fd/ant-design-icons/lib/ChevronLeft'
import ChevronRight from '@2fd/ant-design-icons/lib/ChevronRight'
import { useLazyQuery } from '@apollo/react-hooks'
import React, { useCallback,useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BuildingsMap } from 'screens/ApartmentsManager/AsideComponents'
import { MapOverlay as MapOverlayBlock, MapOverlayToggle, MapOverlayToggleOverlay } from 'screens/ApartmentsManager/styled'
import { useGlobalState } from 'state'

const ROUTES_WITH_MAP = [
  '/buildings-management',
  '/apartments-management',
]

export const UseMapOverlay = () => {
  const [, dispatch] = useGlobalState()
  useEffect(() => {
    dispatch({ type: 'SET_MAP_OVERLAY', payload: true })
    return () => dispatch({ type: 'SET_MAP_OVERLAY', payload: false })
  }, [])

  return []
}

export const MapOverlay = () => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()

  // Current map overlay status
  const [mapIsHidden, setMapIsHidden] = useState(false)
  useEffect(() => {
    setMapIsHidden(window.localStorage.getItem('map-overlay') !== 'true')
  }, [])

  // Fetch buildings
  const [fetchBuildings, { data: allBuildings, loading }] = useLazyQuery(ALL_BUILDINGS_QUERY)

  useEffect(() => {
    if (allBuildings?.response) {
      dispatch({
        type: 'SET_BUILDINGS',
        payload: allBuildings.response,
      })
    }
  }, [allBuildings])

  useEffect(() => {
    if (!allBuildings && !loading && !mapIsHidden && state.currentUser) {
      fetchBuildings()
    }
  }, [state.currentUser, mapIsHidden, loading, allBuildings])

  // Toggle overlay
  const mapOverlayToggle = useCallback(() => {
    setMapIsHidden(!mapIsHidden)
    window.localStorage.setItem('map-overlay', String(mapIsHidden))
  }, [mapIsHidden])

  return (
    <MapOverlayBlock data-hidden={!state.mapOverlay} data-toggle={mapIsHidden}>
      <MapOverlayToggleOverlay onClick={mapOverlayToggle} />
      <MapOverlayToggle onClick={mapOverlayToggle}>
        {mapIsHidden ? <ChevronLeft /> : <ChevronRight />}
      </MapOverlayToggle>
      <BuildingsMap buildings={allBuildings?.response ?? []} />
    </MapOverlayBlock>
  )
}

