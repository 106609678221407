import { gql } from 'apollo-boost'

import { wbsFragment } from '../_fragments'

export const WBS_UPSERT_MUTATION = gql`
  mutation wbs($input: WbsInput!) {
    response: wbs(input: $input) {
      ...wbsFragment
    }
  }
  ${wbsFragment}
`
