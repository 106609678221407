import { useProject } from 'common/ProjectProvider'
import { motion } from 'framer-motion'
import React from 'react'

function Logo(props) {
  const { size = 'middle', className, ...otherProps } = props
  const project = useProject()
  const src = `/images/logo.${project.slug}.png`
  return <motion.img src={src} className={`${className} ui-logo ui-logo--${size}`.trim()} alt={project.title} {...otherProps} />
}

export default Logo
