/* eslint-disable simple-import-sort/imports */
import './style.css'

import { colors } from '_colors'
import { DASHBOARD } from '_graphql'
import { useQuery } from '@apollo/react-hooks'
import { Alert, Col, PageHeader, Row, Tabs, Typography } from 'antd'
import { Notifications } from 'components/Notifications'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import { Link, navigate } from '@reach/router'
import styles from 'styles/side-layout.less'
import {
  isAdmin,
  isLandlord,
  isTenant,
  roles,
} from '_graphql/_enums'

import { DashboardTab } from './DashboardTab'
import { LayoutCard, LayoutCardTitle, LayoutOverlay } from 'components/Layout'
import LayoutTabs from 'components/LayoutTabs'
import Axios from 'axios'
import { ConsoleSqlOutlined } from '@ant-design/icons'
import { Attachment } from '@material-ui/icons'
import { LandlordsDashboard } from './LandlordsDashboard'
import { TenantsDashboard } from './TenantsDashboard'
import { ProfileDisplay, ProfileForm } from 'screens/ProfilesManager'

const { TabPane } = Tabs

const Dashboard = () => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const { data: dashboard } = useQuery(DASHBOARD)
  const [activeTab, setActiveTab] = useState('dashboard')
  const role = state.currentUser?.role

  return (
    <LayoutOverlay>
      {/** Header */}
      <Row>
        <Col xs={24}>
          {isAdmin(role) ? <DashboardTab data={dashboard?.response} /> : null}
          {isLandlord(role) ? (state.currentUser.profiles.length != 0 && state.currentUser.defaultProfile != null ? <LandlordsDashboard data={dashboard?.response} /> : <ProfileForm />) : null}
          {isTenant(role) ? (state.currentUser.profiles.length != 0 && state.currentUser.defaultProfile != null  ? <TenantsDashboard data={dashboard?.response} /> : <ProfileForm />) : null}
        </Col>
      </Row>

    </LayoutOverlay>
  )
}

export { Dashboard }
