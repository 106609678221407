import { isAdmin, isLandlord, isTenant, isTenantManager, RENTAL_REQUESTS_STATUSES, ROLE_GROUPS, roles, USER_ROLES } from '_graphql/_enums'
import { REQUESTS_LIST } from '_graphql/rentalRequest/queries'
import { BankOutlined,PlusOutlined, SortAscendingOutlined, SortDescendingOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { Link } from '@reach/router'
import { Button, Tag,Tooltip } from 'antd'
import { ListManager } from 'components/ListManager'
import { string } from 'components/ListManager/DefaultTypes'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {useAsync, useInterval} from 'react-use'
import { useGlobalState } from 'state'
import { db } from 'utils/dexie'

// import { ProposalPage } from './RentalRequestForm/proposal'

const useFilters = () => {
  const [state] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)
  const isTenantUser = isTenant(state.currentUser?.role)
  const isLandlordUser = isLandlord(state.currentUser?.role)
  const isTenantPmo = isTenantManager(state.currentUser?.role)

  const filters = {
    all: {},
    new: { status: [RENTAL_REQUESTS_STATUSES[0].key] },
    inProgress: { status: [RENTAL_REQUESTS_STATUSES[2].key, RENTAL_REQUESTS_STATUSES[3].key, RENTAL_REQUESTS_STATUSES[4].key, RENTAL_REQUESTS_STATUSES[5].key, RENTAL_REQUESTS_STATUSES[6].key] },
    closed: { status: [RENTAL_REQUESTS_STATUSES[8].key] },
    contractCreated: { status: [RENTAL_REQUESTS_STATUSES[7].key] }
  }

  // Action required :: B2B Team
  if (isManagementUser) {
    filters.actionRequired = {
      status: [
        RENTAL_REQUESTS_STATUSES[0].key,
        RENTAL_REQUESTS_STATUSES[3].key,
        RENTAL_REQUESTS_STATUSES[6].key
      ]
    }
  }

  // Action required :: Landlord
  if (isLandlordUser) {
    filters.actionRequired = {
      status: [
        RENTAL_REQUESTS_STATUSES[3].key,
        RENTAL_REQUESTS_STATUSES[6].key
      ]
    }
  }

  // Action required :: Tenant
  if (isTenantUser) {
    filters.actionRequired = {
      status: [
        RENTAL_REQUESTS_STATUSES[2].key,
        RENTAL_REQUESTS_STATUSES[4].key,
        RENTAL_REQUESTS_STATUSES[5].key,
      ]
    }
  }

  if (isTenantPmo) {
    filters.actionRequired = {
      status: [
        RENTAL_REQUESTS_STATUSES[2].key,
        RENTAL_REQUESTS_STATUSES[3].key,
        RENTAL_REQUESTS_STATUSES[4].key,
        RENTAL_REQUESTS_STATUSES[5].key,
        RENTAL_REQUESTS_STATUSES[6].key,
      ]
    }
  }

  return filters
}

const RentalRequests = () => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)
  const isTenantUser = isTenant(state.currentUser?.role)
  const isLandlordUser = isLandlord(state.currentUser?.role)
  const filters = useFilters()

  return (
    <>
      <ListManager
        slug="rental-requests-manager"
        title={t('rental-request.manager-title')}
        query={REQUESTS_LIST}
        lastSeen="rentalRequestsLastSeen"
        actions={isManagementUser || isTenantUser ? [
          <Link to="/rental-requests-management/create" key="create">
            <Button type="primary" icon={<PlusOutlined />} htmlType="span">{t('rental-request.actions.add')}</Button>
          </Link>,
        ] : []}
        sorting={{
          default: 'id|asc',
          variations: [
            {
              slug: 'id|asc',
              title: t('rental-request.sorting.id|asc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|desc',
              title: t('rental-request.sorting.id|desc'),
              icon: <SortAscendingOutlined />,
            },
            {
              slug: 'tenantId|desc',
              title: t('rental-request.sorting.tenantId|desc'),
              icon: <UserSwitchOutlined />,
            },
            {
              slug: 'salesId|desc',
              title: t('rental-request.sorting.salesId|desc'),
              icon: <UserSwitchOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'actionRequired',
          presets: [
            {
              title: t('rental-request.filter.presets.all'),
              slug: 'all',
              value: filters.all,
            },
            {
              title: t('rental-request.filter.presets.new'),
              slug: 'new',
              value: filters.new,
            },
            {
              title: t('rental-request.filter.presets.actionRequired'),
              slug: 'actionRequired',
              value: filters.actionRequired,
            },
            {
              title: t('rental-request.filter.presets.inProgress'),
              slug: 'inProgress',
              value: filters.inProgress,
            },
            {
              title: t('rental-request.filter.presets.closed'),
              slug: 'closed',
              value: filters.closed,
            },
            {
              title: t('rental-request.filter.presets.contractCreated'),
              slug: 'contractCreated',
              value: filters.contractCreated,
            },
          ],
          filters: [
            {
              title: t('rental-request.filter.tenants'),
              slug: 'tenants',
              icon: <UserOutlined />,
              middleware: isManagementUser,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: { role: [USER_ROLES['tentant-admin'].key] },
              },
            },
            {
              title: t('rental-request.filter.sales'),
              slug: 'sales',
              icon: <BankOutlined />,
              middleware: isManagementUser,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: {
                  role: Object.values(USER_ROLES).filter((role) => ROLE_GROUPS.B2B.includes(role.id)).map(role => role.key)
                },
              },
            },
          ],
        }}
        table={{
          columns: [
            {
              title: t('rental-request.fields.sku'),
              key: 'sku',
              width: 100,
              options: { na: true },
              render: ({ options, item }) => (
                <Link to={`/rental-requests-management/${item.sku}`}>
                  {string({ value: (item?.sku.slice(0, 8)).trim(), options })}
                </Link>
              ),
            },
            {
              title: t('rental-request.fields.tenant'),
              key: 'tenantEmployee',
              render: 'user',
              middleware: isManagementUser,
            },
            {
              title: t('rental-request.fields.sales'),
              key: 'sales',
              options: { userRole: state.currentUser?.role },
              render: 'user',
            },
            {
              title: t('rental-request.fields.city'),
              key: 'city',
              options: { na: false },
              render: 'string',
            },
            {
              title: t('rental-request.fields.budget'),
              key: 'budget',
              width: 100,
              render: 'cost',
            },
            {
              title: t('rental-request.fields.unlimited.title'),
              key: 'unlimited',
              width: 120,
              render: ({ value }) => t(`rental-request.fields.unlimited.${value ? 'true' : 'false'}`),
            },
            {
              title: t('rental-request.fields.dates'),
              key: 'dates',
              render: ({ item }) => {
                const dateFrom = item?.dateFrom ? DateTime.fromISO(item?.dateFrom) : null
                const dateTill = item?.dateTill ? DateTime.fromISO(item?.dateTill) : null
                const diff = dateFrom && dateTill ? dateTill.diff(dateFrom, ['months', 'days']) : null

                return `${dateFrom ? dateFrom.toFormat('yyyy-LL-dd') : ''}${dateFrom && dateTill ? ' — ' : ''}${dateTill ? dateTill.toFormat('yyyy-LL-dd') : ''}${diff ? ` (${[diff.months ? `${Math.ceil(diff.months)}mo.` : '', diff.days ? `${Math.ceil(diff.days)}d.` : ''].filter((item) => !!item).join(' ')})` : ''}`
              },
            },
            {
              title: t('rental-request.fields.status'),
              key: 'status',
              options: { na: true },
              render: ({ options, item }) => string({ value: <Tooltip title={RENTAL_REQUESTS_STATUSES[item?.status].description}><Tag color={RENTAL_REQUESTS_STATUSES[item?.status].color}>{RENTAL_REQUESTS_STATUSES[item?.status].title}</Tag></Tooltip>, options }),
            },
          ],
        }}
      />
    </>
  )
}

export function useRentalRequestsCounter (filter = 'all') {
  const filters = useFilters()
  const { data, loading, refetch } = useQuery(REQUESTS_LIST, { variables: { page: 1, pageSize: 1000, filter: JSON.stringify(filters[filter]) }, fetchPolicy: 'no-cache', })
  const { list } = data?.response ?? { list: [] }
  const [lastSeen, setLastSeen] = useState([])

  useInterval(async () => {
    setLastSeen(await db.rentalRequestsLastSeen.where('id').anyOf(...list.map(item => item.id)).toArray())
  }, 3500)

  const total = list.filter(itemLeft => (itemLeft.updatedAt !== lastSeen.find(itemRight => itemRight.id === itemLeft.id)?.updatedAt)).length || 0
  return { value: total, loading: loading || lastSeen.loading, refetch }
}

export { RentalRequests }
// export { ProposalPage }
export { RentalRequestForm } from './RentalRequestForm'


