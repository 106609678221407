import { USER_UPSERT_MUTATION, WBS_LIST } from '_graphql'
import {
  isAdmin,
  isSingleGroup,
  isSlave,
  roles,
  USER_ROLES,
} from '_graphql/_enums'
import { PROJECT_LIST } from '_graphql/project'
import { USER_QUERY } from '_graphql/user/queries'
import { useLazyQuery, useMutation,useQuery } from '@apollo/react-hooks'
import { InputOutlined } from '@material-ui/icons'
import { navigate } from '@reach/router'
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
} from 'antd'
import { NotFound } from 'components'
import { UserSelect } from 'components/Form/UserSelect'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import { PageHeader } from 'components/PageHeader'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProfilesManager } from 'screens/ProfilesManager'
import { columns } from 'screens/ProfilesManager/tableSetup'
import { WbsManager } from 'screens/WbsManager'
import { useGlobalState } from 'state'
import styled from 'styled-components'

const UserForm = (props) => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()
  const [, rerender] = useState()

  const {
    data: userData,
    loading: userLoading,
    refetch: userRefetch,
  } = useQuery(USER_QUERY, {
    variables: {
      id: +props.id,
    },
  })

  const [fetchProjects, { data: projectsData, loading: projectsLoading }] = useLazyQuery(PROJECT_LIST, {
    variables: {
      page: 1,
      pageSize: 1000,
    },
  })

  const [fetchWbs, { data: wbsData, loading: wbsLoading }] = useLazyQuery(WBS_LIST, {
    variables: {
      page: 1,
      pageSize: 1000,
    },
  })

  useEffect(() => {
    if (state.refetch === 'ALL_PROFILES_QUERY') {
      userRefetch()
      dispatch({ type: 'REFETCH_CLEAR' })
    }
  }, [state.refetch, userRefetch, dispatch])

  const [upsertUser] = useMutation(USER_UPSERT_MUTATION)

  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()

  const formData = form1.getFieldsValue()

  const [saveTrigger, setSaveTrigger] = useState(false)
  const [, forceUpdate] = useState()

  useEffect(() => {
    form1.setFieldsValue(userData?.response)
    form3.setFieldsValue(userData?.response)
    forceUpdate(Date.now())
  }, [userData])

  useEffect(() => {
    if (
      form1.getFieldValue('role') === roles.TENANT_EMPLOYEE
      && !projectsData && form1.getFieldValue('ownerId') && form1.getFieldValue('projectId')
    ) {
      fetchProjects({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            owner: [form1.getFieldValue('ownerId')],
          }),
        },
      })
      fetchWbs({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify({
            projectIds: [form3.getFieldValue('projectId')],
          }),
        },
      })
    }
  }, [form1.getFieldValue('role'), form3.getFieldValue('projectId'), form1.getFieldValue('ownerId')])

  if (!userData?.response && !userLoading) {
    return <NotFound />
  }

  const submitInfo = async () => {
    const input = form1.getFieldsValue([
      'firstName',
      'lastName',
      'email',
      'phone',
      'role',
      'projectId',
    ])

    if (isSlave(input.role)) {
      input.ownerId = state.currentUser.ownerId
    }

    if (!input.firstName) {
      message.warning(
        t('user.messages.errors.blank-field', {
          field: t('user.fields.firstName'),
        }),
      )
      return null
    }
    if (!input.lastName) {
      message.warning(
        t('user.messages.errors.blank-field', {
          field: t('user.fields.lastName'),
        }),
      )
      return null
    }
    if (!input.email) {
      message.warning(
        t('user.messages.errors.blank-field', { field: t('user.fields.email') }),
      )
      return null
    }

    input.id = userData?.response?.id

    const d = await upsertUser({ variables: { input } })

    if (d?.errors?.length) {
      message.error(t('user.messages.errors.error-saving') + `: ${d?.errors?.[0]?.message}`)
    } else {
      message.success(t('user.messages.updated-successfully'))
    }
  }

  const updateProject = async () => {
    const input = form3.getFieldsValue('projectId')



    input.id = userData?.response?.id

    const d = await upsertUser({ variables: { input } })

    if (d.response?.errors?.length) {
      message.error(t('user.messages.errors.error-saving'))
    } else {
      message.success(t('user.messages.updated-successfully'))
    }
  }

  const updateWBS = async () => {
    const input = form3.getFieldsValue('wbsId')



    input.id = userData?.response?.id

    const d = await upsertUser({ variables: { input } })

    if (d.response?.errors?.length) {
      message.error(t('user.messages.errors.error-saving'))
    } else {
      message.success(t('user.messages.updated-successfully'))
    }
  }

  const submitPassword = async () => {
    const input = form2.getFieldsValue(['password1', 'password2'])

    if (!input.password1) {
      message.warning(
        t('user.messages.errors.blank-field', {
          field: t('user.fields.password'),
        }),
      )
      return null
    }

    if (input.password1 !== input.password2) {
      message.warning(t('user.messages.errors.password-do-not-match'))
      return null
    }

    const d = await upsertUser({
      variables: {
        input: { id: userData.response.id, password: input.password1 },
      },
    })

    if (d.errors?.length) {
      message.error(t('user.messages.errors.error-updating-password'))
    } else {
      message.success(t('user.messages.password-updated'))
      form2.setFieldsValue({ password1: '', password2: '' })
    }
  }

  return (
    <LayoutOverlay>
    
      <PageHeader
        title={t('user.form.current')}
        id={userData?.response?.id}
        tags={userData?.response ? (
          userData?.response.isActive ? <Tag color="green">{t('user.fields.status.active')}</Tag> : <Tag color="red">{t('user.fields.status.inactive')}</Tag>
        ) : null}
      />

      <Row gutter={[20, 20]}>
        <Col xs={16}>
          <Form
            layout="vertical"
            form={form1}
            onFieldsChange={rerender} 
            onChange={() => {
              setSaveTrigger(!saveTrigger)
            }}
          >
            <LayoutCard>
              <h2 style={{ fontSize: '24px' }}>{t('user.form.userInfo')}</h2>
              <Form.Item label={t('user.fields.firstName')} name="firstName">
                <Input />
              </Form.Item>
              <Form.Item label={t('user.fields.lastName')} name="lastName">
                <Input />
              </Form.Item>
              <Form.Item label={t('user.fields.email')} name="email">
                <Input />
              </Form.Item>
              <Form.Item
                label={t('user.fields.phone')}
                name="phone"
                rules={[
                  {
                    validator(_rule, value, callback) {
                      if (
                        value
                        && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(
                          value,
                        )
                      ) {
                        callback(
                          t('user.messages.errors.invalid-field', {
                            field: t('user.fields.phone'),
                          }),
                        )
                      }
                      callback()
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="role" label={t('user.fields.role')}>
                <Select onChange={rerender}>
                  {Object.values(USER_ROLES)
                    .filter(
                      (role) => (isAdmin(state.currentUser.role)
                          && !isSlave(role.id))
                        || (!isAdmin(state.currentUser.role)
                          && isSlave(role.id)
                          && isSingleGroup(state.currentUser.role, role.id)),
                    )
                    .map((role) => (role.id !== roles.SUPER_ADMIN
                      || state.currentUser?.role === roles.SUPER_ADMIN ? (
                        <Select.Option value={role.id} key={role.id}>
                          {role.title}
                        </Select.Option>
                      ) : null))}
                </Select>
              </Form.Item>
              { isAdmin(state.currentUser.role) ? 
                (<Form.Item name="ownerId"label={t('user.fields.owner')}>
                  <UserSelect roles={[USER_ROLES['tentant-admin'].key]}  />
                </Form.Item>)
                : null }
              <Button type="primary" onClick={submitInfo}>
                {t('user.actions.save')}
              </Button>
            </LayoutCard>
          </Form>
        </Col>

        <Col xs={8}>
          {form1.getFieldValue('role') === roles.TENANT_EMPLOYEE ? (
            <Form layout="vertical" form={form3}>
              <LayoutCard>
                <h2 style={{ fontSize: '24px' }}>
                  {t('user.form.userProject')}
                </h2>
                <Form.Item name="projectId" label={t('user.form.project')}>
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('globalForms.select')}
                    disabled={projectsLoading}
                    onChange={updateProject}
                  >
                    {projectsData?.response?.list?.map?.((project) => (
                      project.owerId === userData.ownerId ?
                        <Select.Option value={project.id} key={project.id}>
                          {project.title} (#{project.id})
                        </Select.Option>
                        : null
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="wbsId" label={t('user.form.projectKey')}>
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('globalForms.select')}
                    disabled={wbsLoading}
                    onChange={updateWBS}
                  >
                    {wbsData?.response?.list?.map?.((wbs) => (
                      <Select.Option value={wbs.id} key={wbs.id}>
                        {wbs.code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </LayoutCard>
            </Form>
          ) : null}

          <Form layout="vertical" form={form2}>
            <LayoutCard>
              <h2 style={{ fontSize: '24px' }}>
                {t('user.form.changePassword')}
              </h2>

              <Form.Item label={t('user.form.new-password')} name="password1">
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={t('user.form.repeat-password')}
                name="password2"
              >
                <Input.Password />
              </Form.Item>

              <Button type="primary" onClick={submitPassword}>
                {t('user.actions.save')}
              </Button>
            </LayoutCard>
          </Form>
        </Col>
      </Row>

      {/* <Table
        columns={columns}
        dataSource={userData?.response?.profiles}
        bordered
        tableLayout="auto"
        rowClassName="pointer"
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            navigate(`/profiles-management/${+record.id}`);
          },
        })}
      /> */}
    
      {/* {userData 
        ? <ProfilesManager queryParams={{ filter: { users: [userData?.response?.id] } }} />
        : null
      } */}
    </LayoutOverlay>
  )
}

export { UserForm }

const SpacedForm = styled.div`
  .ant-space-item {
    margin-bottom: auto;
  }
`

const SpacedButton = styled.div`
  margin-top: 30px;
`
