import { isAdmin, isTenant, roles, USER_ROLES } from '_graphql/_enums'
import { CP_MARK_PAID,FETCH_CP_BY_SKU, REBUILD_CP } from '_graphql/comissionPayment'
import { BankOutlined, DownOutlined, FieldTimeOutlined,FileExcelFilled,FileExcelOutlined,FilePdfOutlined,MailOutlined,ReloadOutlined,SyncOutlined,TeamOutlined,UserOutlinedm } from '@ant-design/icons'
import { ConsoleSqlOutlined, EditOutlined } from '@ant-design/icons'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { Link, navigate, useParams } from '@reach/router'
import {
  Alert,
  Badge,
  Button,
  Calendar,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from 'antd'
import { Layout } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useModals } from 'common/ModalsProvider'
import { ContactBox } from 'components/ContactBox'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import { cost } from 'components/ListManager/DefaultTypes'
import { user } from 'components/ListManager/DefaultTypes'
import { PageHeader } from 'components/PageHeader'
import domToPdf from 'dom-to-pdf'
import { saveAs } from 'file-saver'
import { DateTime } from 'luxon'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EntriesManager } from 'screens/EntriesManager'
import { InvoicesManager } from 'screens/InvoiceManager'
import { RentalContractDetails } from 'screens/RentalContracts'
import { useGlobalState } from 'state'
import * as XLSX from 'xlsx'
import XlsxPopulate from 'xlsx-populate'

const ComissionPaymentsPage = (options) => {

  let sku
  if (options?.sku) {
    sku = options.sku
  } else {
    sku = null
  }
  const { openModal } = useModals()
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagmentUser = isAdmin(state.currentUser?.role)
  const [, rerender] = useState()
  const [recurring, setRecurring] = useState(null)
  const [fetch, { data: recurringPayment, loading: recurringPaymentLoading }] = useLazyQuery(FETCH_CP_BY_SKU, { variables: { sku: sku }, fetchPolicy: 'no-cache' })  

  const [rebuild, { data: rebuildRP, loading: rebuildRPLoading }] = useLazyQuery(REBUILD_CP, { variables: { sku: sku }, fetchPolicy: 'no-cache' }) 
  const [makePaid, { data: makePaidRP, loading: makePaidRPLoading }] = useLazyQuery(CP_MARK_PAID, { variables: { sku: sku }, fetchPolicy: 'no-cache' })
  // const [makePDF, { data: makePDF, loading: makePDFLoading }] = useLazyQuery(CP_MAKE_PDF, { variables: { sku: sku }, fetchPolicy: 'no-cache' })
  // const [makeXLS, { data: makeXLS, loading: makeXLSLoading }] = useLazyQuery(CP_MAKE_XLS, { variables: { sku: sku }, fetchPolicy: 'no-cache' })  


  useEffect(() => {
    if(sku){
      fetch()
    }
  }, [sku])
  useEffect(() => {
    if(!recurringPaymentLoading) {
      setRecurring(recurringPayment?.response)
    }
  }, [recurringPayment])
  // Last seen
  //   useEffect(() => {
  //     if (recurringPayment?.id) {
  //       db.sku.put({
  //         id: invoices.id,
  //         updatedAt: invoices.updatedAt
  //       })
  //     }
  //   }, [invoice])

  // Subscribe
  // useEffect(() => {
  //   if (subscribeToMore) {
  //     return subscribeToMore({
  //       document: SUBSCRIBE_invoice,
  //       variables: { sku: sku },
  //       updateQuery: (prev, { subscriptionData }) => {
  //         if (!subscriptionData?.data?.response) {
  //           return prev
  //         }
  //         setInvoice(subscriptionData?.data?.response)
  //       }
  //     })
  //   }
  // }, [subscribeToMore])


  const reinit = (sku) => {
    rebuild()
    message.success('Reinit triggered for sku: ' + sku)
  }


  const markPaid = (sku) => {
    makePaid()
    message.success('RP  triggered paid for sku: ' + sku)
  }

  const generatePDF = (sku) => {
    
    message.success('generatePDF triggered for sku: ' + sku)
    
    setTimeout(exportPdf, 1000)

  
    
  }

  const exportPdf = (sku) => {
  
    const element = document.getElementById('myPage')
    const options = {
      filename: recurring?.sku + '.pdf'
    }
    domToPdf(element, options, function() {
      console.log('done')
    })
  }

  

  const columns = [
    {
      title: t('comission.form.apartmentCode'),
      dataIndex: 'aptId',
      key: 'aptId',
      render: (code) => <span>{code}</span>,
    },
    {
      title: t('comission.form.tenant'),
      dataIndex: 'tenantName',
      key: 'tenantName',
    },
    {
      title: t('comission.form.rent'),
      dataIndex: 'rent',
      key: 'rent',
      render: (item) => <span>€ {item.toFixed(2)}</span>,
    },

    {
      title: t('comission.form.comission'),
      dataIndex: 'commission',
      key: 'commission',

      render: (item) => <span>{item}%</span>,
    },
    {
      title: t('comission.form.total'),
      dataIndex: 'total',
      key: 'total',
      render: (item) => <span>€ {item.toFixed(2)}</span>,
    },
  
  ]

  const columnsInvoice = [
    {
      title: t('comission.form.apartments'),
      dataIndex: 'apts',
      key: 'apts',
    },
    {
      title: t('comission.form.rent'),
      dataIndex: 'rent',
      key: 'rent',
      render: (item) => <span>€ {item.toFixed(2)}</span>,
    },
    {
      title: t('comission.form.comission'),
      dataIndex: 'comission',
      key: 'comission',
      render: (item) => <span>{item}%</span>,
    },
    {
      title: t('comission.form.total'),
      dataIndex: 'total',
      key: 'total',
      render: (item) => <span>€ {item.toFixed(2)}</span>,
    },
  
  ]

  function getSheetData(data, header) {
    var fields = Object.keys(data[0])
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : ''
      })
    })
    sheetData.unshift(header)
    return sheetData
  }

  const generateXLS = (sku) => {
    
    message.success('generateXLS triggered for sku: ' + sku)



    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      let header = ['Apartment Code', 'Tenant Name', 'Rent', 'Comission', 'Total']

      const sheet1 = workbook.sheet(0)
      const sheetData = getSheetData(recurring?.apartments, header)
      const totalColumns = sheetData[0].length

      sheet1.cell('A1').value(sheetData)
      const range = sheet1.usedRange()
      const endColumn = String.fromCharCode(64 + totalColumns)
      sheet1.row(1).style('bold', true)
      sheet1.range('A1:' + endColumn + '1').style('fill', 'BFBFBF')
      range.style('border', true)
      return workbook.outputAsync().then((res) => {
        saveAs(res, recurring.sku+'.xlsx')
      })
    })

  
  }





  return (
    <>
      { recurring 
        ? (
          <>

            <PageHeader
              title={t('comission.form.current')}
              id={sku}
              tags={recurring ? (recurring.isPaid ? <Tag color="green">{t('recurring.table.isPaid.$true$')}</Tag>  :  <Tag color="red">{t('recurring.table.isPaid.$false$')}</Tag>) : null}
              extra={
                <>
                  <Tooltip title="Make PDF">
                    Export:
                  </Tooltip>
                  <Tooltip title="Make PDF">
                    <Button onClick={() => generatePDF(recurring.sku)}>
                      <FilePdfOutlined/>
                    </Button>  
                  </Tooltip>
                  <Tooltip title="Make XLS">
                    <Button onClick={() => generateXLS(recurring.sku)}>
                      <FileExcelOutlined />
                    </Button>  
                  </Tooltip>
                  
                  <Divider type="vertical" />
                  { !recurring.isPaid ? (
                    <>
                      <Tooltip title="Regenerate">
                        <Button onClick={() => reinit(recurring.sku)}>
                          <ReloadOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Mark as Paid">
                        <Button onClick={() => markPaid(recurring.sku)}>
                          <BankOutlined />
                        </Button>
                      </Tooltip>
                    </>
                  ) : null}
                </>}/>
          
            <div id="myPage">
              <Row gutter={[20,20]}>
                <Col xs={12} >
                  <LayoutCard styles={{ marginRight: '10px' }}>
                    <Descriptions title="" size="small" layout="horizontal" column={1} bordered >
                      <Descriptions.Item label={t('comission.form.companyName')}>{ recurring?.recipientProfile && recurring?.recipientProfile?.companyName ? recurring?.recipientProfile?.companyName : '-'}</Descriptions.Item>
                      <Descriptions.Item label={t('comission.form.iban')}>{ recurring?.recipientProfile?.ibanCode ? recurring?.recipientProfile.ibanCode : '-'}</Descriptions.Item>
                      <Descriptions.Item label={t('comission.form.bicCode')}>{ recurring?.recipientProfile?.bicCode ? recurring?.recipientProfile.bicCode : '-'}</Descriptions.Item>
                      <Descriptions.Item label={t('comission.form.receptor')}>{ recurring?.recipientProfile?.receptor ? recurring?.recipientProfile.receptor : '-'}</Descriptions.Item>
                      <Descriptions.Item label={t('comission.form.issueMonth')}>{ recurring?.issueMonth ? DateTime.fromISO(recurring?.issueMonth).toFormat('LLL yyyy') : '-'}</Descriptions.Item>
                      <Descriptions.Item label={t('comission.form.createdAt')}>{ recurring?.createdAt ? DateTime.fromISO(recurring?.createdAt).toFormat('yyyy-MM-dd / hh:mm') : '-'}</Descriptions.Item>
                    </Descriptions>
                  </LayoutCard>
                </Col>

            
              </Row>


              <LayoutCard>

                <Row>
                  <Col xs={24} >
                 

                    <Table
                      columns={columnsInvoice}
                      dataSource={[{apts: recurring.volume, rent: recurring.totalRent, comission: recurring?.landlordProfile?.comission, total: recurring.totalComission }]} 
                      bordered
                      pagination={false}
                      summary={
                        pageData => {
                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={3}>{t('comission.form.grandTotal')}</Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1}>
                              € {recurring.totalComission.toFixed(2)}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          )
                        }}
                    />

                  </Col>
               
                </Row>
             
              </LayoutCard>
            </div>
            <LayoutCard>

              <Row>
                <Col xs={24} >
                  <Table bordered columns={columns} dataSource={recurring?.apartments} pagination={false}/>
                </Col>
               
              </Row>
             
            </LayoutCard>
            
          </>

        )
        : 'nothing to show'
      }
    </> 
  )
}

export { ComissionPaymentsPage }
