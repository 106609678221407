import { gql } from 'apollo-boost'

export const FINISH_TUTORIAL_MUTATION = gql`
  mutation finishTutorial($type: String!) {
    response: finishTutorial(type: $type) {
      data {
        id
        type
      }
    }
  }
`
