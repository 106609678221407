import { gql } from 'apollo-boost'

import { transactionFragment } from '../_fragments'

export const TRANSACTION_UPSERT_MUTATION = gql`
  mutation transaction($input: TransactionInput!) {
    response: transaction(input: $input) {
      ...transactionFragment
    }
  }
  ${transactionFragment}
`

export const TRANSACTION_DELETE_MUTATION = gql`
  mutation deleteTransaction($id: Int!) {
    deleteTransaction(id: $id)
  }
`
