import ContentCopy from '@2fd/ant-design-icons/lib/ContentCopy'
import { BarsOutlined,TableOutlined } from '@ant-design/icons'
import { Button, message, PageHeader, Radio } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCopyToClipboard } from 'react-use'

import { Filter } from './Filter'

/**
 * Шапка
 */
export const Header = ({ managerSlug, title, actions = [], filter, renderMode, setRenderMode, canChoseTilesRenderMode, searchValue, setSearchValue, filterValues, setFilterValues, filterUri, sorting, sortingValue, setSortingValue }) => {
  const { t } = useTranslation()
  const [, copyToClipboard] = useCopyToClipboard()
  const mainTitle = title?.title ?? title
  const subTitle = title?.subTitle ?? ''

  return (
    <PageHeader
      ghost={false}
      title={mainTitle}
      subTitle={subTitle}
      extra={[
        // Режим отображения
        canChoseTilesRenderMode ? (
          <>
            <Radio.Group value={renderMode} onChange={({ target: { value } }) => { setRenderMode(value) }}>
              <Radio.Button value="table"><TableOutlined /> {t('list-manager.render-modes.table')}</Radio.Button>
              <Radio.Button value="tiles"><BarsOutlined /> {t('list-manager.render-modes.tiles')}</Radio.Button>
            </Radio.Group>
          </>
        ) : null,
        // Ссылка на фильтр
        filterUri ? (
          <Button type="text" onClick={() => {
            copyToClipboard(filterUri)
            message.info('The link is copied to the clipboard')
          }}>
            <ContentCopy />
            Copy link
          </Button>
        ) : null,
        ...actions,
      ]}
    >
      {/** Фильтрация */}
      <Filter {...{ managerSlug, filter, searchValue, setSearchValue, filterValues, setFilterValues, sorting, sortingValue, setSortingValue }} />
    </PageHeader>
  )
}
