import cx from 'classnames'
import * as EventEmitter from 'eventemitter3'
import React, { useContext, useEffect, } from 'react'
import Joyride, { STATUS } from 'react-joyride'

// @ts-ignore
const events = new EventEmitter()

const context = React.createContext({
  currentStep: null,
  // steps: null,
  setSteps: () => {},
  highlightedElement: null,
  moveNext: () => {},
  movePrevious: () => {},
  events,
})

export const useTutorialUI = () => useContext(context)

const BeaconDisabled = () => {
  return null
}

export const TutorialUIProvider = ({
  children
}) => {
  const [config, setConfig] = React.useState({
    run: false,
    currentStepIdx: null,
  })

  const setSteps = (config) => {
    setConfig({
      ...config,
      currentStepIdx: 0,
      steps: Object.entries(config.steps).map(([target, config]) => {
        const tooltip = {}
        if (config.disableTooltip) {
          tooltip.floaterProps = {
            open: false
          }
          tooltip.beaconComponent = BeaconDisabled
        }

        return {
          ...config,
          ...tooltip,
          target
        }
      })
    })
  }

  const moveNext = () => {
    setConfig(config => ({ ...config, currentStepIdx: config.currentStepIdx + 1, }))
  }

  const movePrevious = () => {
    setConfig(config => ({ ...config, currentStepIdx: config.currentStepIdx - 1, }))
  }

  const run = () => {
    setConfig(config => ({ ...config, run: true }))
  }

  const currentElement = config.steps && config.steps[config.currentStepIdx]

  const value = {
    currentStepIdx: config.currentStepIdx,
    setSteps,
    currentElement: currentElement,
    moveNext,
    movePrevious,
    run,
    events,
  }

  const handleJoyrideCallback = (data) => {
    const { status, type } = data
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]

    if (status === STATUS.FINISHED) {
      value.events.emit('onFinish', config)
    }

    if (finishedStatuses.includes(status)) {
      setConfig(config => ({ ...config, run: false }))
    }
  }

  return (
    <context.Provider value={value}>
      {children}
      {config.steps && (
        <Joyride
          continuous
          callback={handleJoyrideCallback}
          run={config.run}
          stepIndex={config.currentStepIdx}
          steps={config?.steps}
        />
      )}
    </context.Provider>
  )
}

export const TutorialUIItem = React.forwardRef(({
  children,
  ...props
}, ref) => {
  const tutorialUI = useTutorialUI()
  const child = React.Children.only(children)
  const childClassName = child.props.className || ''
  const isActive = tutorialUI.currentElement?.target === `#${child.props.id}`

  useEffect(() => {
    if (isActive) {
      tutorialUI.run()
    }
  }, [isActive])

  const onClick = (...args) => {
    if (isActive) {
      tutorialUI.moveNext()
    }

    child.props?.onClick?.(...args)
  }

  return React.cloneElement(child, {
    ...props,
    ref,
    onClick,
    className: cx(props.className, childClassName),
  })
})