import { isAdmin, isTenant, roles, USER_ROLES } from '_graphql/_enums'
import { ALL_PROFILES_QUERY } from '_graphql/profile'
import { ALL_RENTAL_CONTRACTS_QUERY } from '_graphql/rentalContract'
import { FETCH_SERVICE_BY_SKU, SERVICE_UPSERT_MUTATION } from '_graphql/service'
import { ALL_USERS_QUERY, ME_QUERY } from '_graphql/user/queries'
import CalendarIcon from '@2fd/ant-design-icons/lib/Calendar'
import Fireplace from '@2fd/ant-design-icons/lib/Fireplace'
import { DownOutlined, FieldTimeOutlined,SyncOutlined,TeamOutlined,UserOutlinedm } from '@ant-design/icons'
import { ConsoleSqlOutlined, EditOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { Link, navigate, useParams } from '@reach/router'
import {
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  Timeline,
  Typography,
} from 'antd'
import { Layout } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useModals } from 'common/ModalsProvider'
import { ChatBox } from 'components/Chat'
import { ContactBox } from 'components/ContactBox'
import { EntityFooter } from 'components/EntityFooter'
import { ContractSelect } from 'components/Form/ContractSelect'
import { DatePicker } from 'components/Form/DatePicker'
import { ProfileSelect } from 'components/Form/ProfileSelect'
import { ProjectSelect } from 'components/Form/ProjectSelect'
import { UserSelect } from 'components/Form/UserSelect'
import { LayoutCard } from 'components/Layout'
import { cost, user } from 'components/ListManager/DefaultTypes'
import { PageHeader } from 'components/PageHeader'
import { DateTime } from 'luxon'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApartmentDetails } from 'screens/ApartmentsManager'
import { ProfileDisplay } from 'screens/ProfilesManager'
import { RentalContractDetails } from 'screens/RentalContracts'
import { useGlobalState } from 'state'
import stc from 'string-to-color'
import styles from 'styles/side-layout.less'
import { db } from 'utils/dexie'

const {  Footer } = Layout

const ServiceView = (options) => {

  let sku
  if (options?.sku) {
    sku = options.sku
  } else {
    sku = useParams().sku
  }
  const { openModal } = useModals()
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagmentUser = isAdmin(state.currentUser?.role)
  const [, rerender] = useState()
  const [isForm, setIsForm] = useState(!sku)
  const [form] = Form.useForm()
  const [types, setTypes] = useState([])
  const [fetch, { subscribeToMore, data: serviceData, loading: serviceLoading }] = useLazyQuery(FETCH_SERVICE_BY_SKU, { variables: { sku: sku }, fetchPolicy: 'no-cache' })  
  const [providerTax, setProviderTax] = useState(0)
  const [filteredService, setFilteredService] = useState(null)

  const { data: profilesData, loading: profilesLoading } = useQuery(ALL_PROFILES_QUERY)
  const { data: contractsData, loading: contractsLoading } = useQuery(ALL_RENTAL_CONTRACTS_QUERY)

  const [service, setService] = useState()
  useEffect(() => {
    if (serviceData?.response) {
      setService(serviceData.response)
      form.setFieldsValue({
        ...serviceData.response,
      })
    }
  }, [serviceData])

  useEffect(() => {
    if (sku) {
      fetch()
      handleTypesChange()
    }
  }, [sku])

  // Last seen
  //   useEffect(() => {
  //     if (service?.id) {
  //       db.sku.put({
  //         id: service.id,
  //         updatedAt: service.updatedAt
  //       })
  //     }
  //   }, [service])

  // Subscribe
  // useEffect(() => {
  //   if (subscribeToMore) {
  //     return subscribeToMore({
  //       document: SUBSCRIBE_service,
  //       variables: { sku: sku },
  //       updateQuery: (prev, { subscriptionData }) => {
  //         if (!subscriptionData?.data?.response) {
  //           return prev
  //         }
  //         setservice(subscriptionData?.data?.response)
  //       }
  //     })
  //   }
  // }, [subscribeToMore])

  useEffect(() => {
    if (service === null && sku) {
      message.error('Service not found')
      navigate('/contract-services')
    }
  }, [service])

  // Fetch data
  useEffect(() => {
    if (sku) {
      fetch()
    }
  }, [sku])

  // Form is opened
  useEffect(() => {
    if (service) {
      form.setFieldsValue(service)
    }
  }, [isForm])


  const handleTypesChange = () => {
    const serviceProviderId = +form.getFieldValue('serviceProviderId')
  
    if (serviceProviderId) {
      const filteredProfile = profilesData?.response?.find((profile) => profile.id === serviceProviderId)
      setTypes(filteredProfile?.type)
    }
  }

  // Submit
  const [upsertservice] = useMutation(SERVICE_UPSERT_MUTATION)
  const onSubmit = async (values) => {
    const {code, memo, expireAt, projectId, grossPrice, tax, netPrice} = values
    
    try {
      const { data } = await upsertservice({ variables: {
        input: {
          id: service?.id,
          code,
          memo,
          projectId,
          expireAt: expireAt, //.unix(),
          grossPrice, tax, netPrice

        }
      } })

      message.success('Saved')

      if (sku === data.response.id) {
        setIsForm(false)
      } else {
        navigate(`/contract-services/${data.response.sku}`)
      }
    } catch (e) {
      console.log('///////', e)
      message.error('The form is filled out incorrectly')
      navigate('/contract-services')
    }
  }

  const backUrl = `/contract-services/${sku}`
  const handleServiceChange = async () => {
    const serviceId = +form.getFieldValue('serviceId')

    if (serviceId) {
      const filterService = serviceData?.response?.find((service) => service.id === serviceId)
      setProviderTax(filterService?.serviceProvider?.taxRate)
      setFilteredService(filterService)
    }
  }

  const recalculateGross = () => {
    let net = +form.getFieldValue('netPrice')
    let taxRate = +form.getFieldValue('tax')

    if(taxRate) {
      let newGross = 0
      newGross = net * (1 + (taxRate/100))

      form.setFieldsValue({
        grossPrice: Math.round(newGross),
      })
    }
  }

  const recalculateNet = () => {
    let gross = +form.getFieldValue('grossPrice')
    let taxRate = +form.getFieldValue('tax')

    console.log(taxRate)
    if(taxRate) {
      let newNet = 0 
      newNet = gross - (gross * (taxRate/100))

      form.setFieldsValue({
        netPrice: newNet,
      })
    }
  }

  const handleStatusChange = async (status) => {

    const { data } = await upsertservice({ variables: {
      input: {
        id: service?.id,
        status: status === 0 ? 1 : 2,
      }
    } })

    message.success('Updated')

    if (sku === data.response.id) {
      setIsForm(false)
    } else {
      navigate(`/contract-services/${data.response.sku}`)
    }
  }

  return (
    <div className={styles.container}>
      <Form 
        form={form}
        className={styles.side} 
        layout={isForm ? 'vertical' : 'horizontal'}
        onFieldsChange={rerender} 
        onFinish={onSubmit}
      >
        <div>
          <PageHeader
            title={t('service.form.current')}
            id={sku}
          />
           
          {service 
            ? ( 
              <a onClick={() => handleStatusChange(service.status)}>
                { service?.status != 0 ? (service?.status != 1 ? <Tag  color="red">Deactivated</Tag> : <Tag color="green">Active</Tag>) : <Tag color="grey">Draft</Tag> } </a> ) 
            : null}
        </div>

        <br />
        

        { isForm ? (
          <>
           
            <Form.Item
              label={t('service.form.contract') + ':'}
              name="contractId"
            >
              <ContractSelect />
            </Form.Item>
            <Form.Item
              name="serviceProviderId"
              label={t('service.form.profile')}
              required
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder={t('globalForms.select')}
                onChange={handleTypesChange}
              >
                {profilesData?.response && profilesData.response.map((profile) => (
                  <Select.Option value={profile.id} key={profile.id}>
                    <b>Company:</b> {profile.companyName} <b>ZIP:</b>{profile.zipCode}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="nextPaymentMonth"
              label={t('service.form.nextPaymentMonth')}
              required={isForm}
              rules={[{ required: true }]}
            >
              <DatePicker picker="month" />
            </Form.Item>
            <Form.Item
              name="lastPaymentDate"
              label={t('service.form.lastPaymentDate')}
              required
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="paymentInterval"
              label={t('service.form.paymentInterval')}
              required
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder={t('globalForms.select')}
              >
                <Select.Option value={t('service.form.paymentIntervals.weekly')} key='0'>{t('service.form.paymentIntervals.weekly')}</Select.Option>
                <Select.Option value={t('service.form.paymentIntervals.monthly')} key='1'>{t('service.form.paymentIntervals.monthly')}</Select.Option>
                <Select.Option value={t('service.form.paymentIntervals.quarterly')} key='2'>{t('service.form.paymentIntervals.quarterly')}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="serviceProviderType"
              label={t('service.form.serviceProviderType')}
              required
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder={t('globalForms.select')}

              >
                {types?.map((type, i) => (
                  <Select.Option value={type} key={i}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="reference"
              label={t('service.form.reference')}
              required
              rules={[{ required: true }]}
            >
              <Input placeholder={t('service.form.referenceHolder')} />
            </Form.Item>
            <Form.Item 
              name="grossPrice"
              label={t('service.form.grossPrice')}
            >
              <InputNumber 
                min={0} 
                defaultValue={0} 
                onChange={recalculateNet}
                style={{ width: '100%' }}  
                formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/€\s?|(,*)/g, '')}
              />
            </Form.Item>

            <Form.Item 
              name="netPrice"
              label={t('service.form.netPrice')}
            >
              <InputNumber
                min={0} 
                defaultValue={0}
                onChange={recalculateGross}
                style={{ width: '100%' }}
                formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/€\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item 
              name="tax"
              label={t('service.form.tax')}
            >
              <InputNumber 
                defaultValue={providerTax ?? 0} 
                style={{ width: '100%' }}
                min={0}
                max={100}
                formatter={value => `${value}%`}
              />
            </Form.Item>

          </>

        ) : (
          <>
            <Typography.Title level={5}>
              <Fireplace style={{ marginRight: 5 }} />
              {t('service.form.objectives')}
            </Typography.Title>
            <div>
              <Typography.Text strong>
                {t('service.form.contract')}:{' '}
              </Typography.Text>
              {service?.contract ? (
                <a
                  onClick={() => openModal(RentalContractDetails, { sku: service.contract?.sku })}
                >{service.contract?.sku.slice(0,8)}
                </a>
              ) : (
                <Tag>N/A</Tag>
              )}
            </div>
            <div>
              <Typography.Text strong>
                {t('service.form.apartment')}:{' '}
              </Typography.Text>
              {service?.contract ? (
                <a
                  onClick={() => openModal(ApartmentDetails, { id: service.contract?.apartment?.id })}
                >{service.contract?.apartment?.code}
                </a>
              ) : (
                <Tag>N/A</Tag>
              )}
            </div>
            <div>
              <Typography.Text strong>
                {t('service.form.serviceProvider')}:{' '}
              </Typography.Text>
              {service?.contract ? (
                <a
                  onClick={() => openModal(ProfileDisplay, { id: service.serviceProvider?.id })}
                >{service.serviceProvider?.companyName}
                </a>
              ) : (
                <Tag>N/A</Tag>
              )}
            </div>
            <div>
              <Typography.Text strong>
                {t('service.form.serviceProviderType')}:{' '}
              </Typography.Text>
              <Tag>{service?.serviceProviderType}</Tag>
            </div>

            <div>
              <Typography.Text strong>
                {t('service.form.reference')}:{' '}
              </Typography.Text>
              {service ? service.reference : 'N/A' }
            </div>

            <br />

            <Typography.Title level={5}>
              <CalendarIcon style={{ marginRight: 5 }} />
              {t('service.form.sheduleAndDates')}
            </Typography.Title>

            <div>
              <Typography.Text strong>
                {t('service.form.paymentInterval')}:{' '}
              </Typography.Text>
              { service?.paymentInterval ? ( service?.paymentInterval != 0 ?  (service?.paymentInterval != 1 ? t('service.form.paymentIntervals.quarterly') : t('service.form.paymentIntervals.monthly')) : t('service.form.paymentIntervals.weekly')) : null}
            </div>

            <div>
              <Typography.Text strong>
                {t('service.form.nextPaymentMonth')}:{' '}
              </Typography.Text>
              { service?.nextPaymentMonth ? DateTime.fromISO(service.nextPaymentMonth).toFormat('LLL yyyy') : '-'}
            </div>
            <div>
              <Typography.Text strong>
                {t('service.form.lastPaymentDate')}:{' '}
              </Typography.Text>
              { service?.lastPaymentDate ? DateTime.fromISO(service.lastPaymentDate).toFormat('LLL dd, yyyy') : '-'}
            </div>

            <br />

            <Typography.Title level={5}>
              <CalendarIcon style={{ marginRight: 5 }} />
              {t('service.form.Prices')}
            </Typography.Title>

            <div>
              <Typography.Text strong>
                {t('service.form.grossPrice')}:{' '}
              </Typography.Text>
              { service?.grossPrice ? cost({ value: service?.grossPrice}) : null}
            </div>

            <div>
              <Typography.Text strong>
                {t('service.form.tax')}:{' '}
              </Typography.Text>
              { service?.tax ? service?.tax : 0 }%
            </div>
            <div>
              <Typography.Text strong>
                {t('service.form.netPrice')}:{' '}
              </Typography.Text>
              { service?.netPrice ? cost({ value: service?.netPrice}) : null}
            </div>
          </>
        )}

        <br />

        {/* Edit button */}
        {isManagmentUser && !isForm && service ? (
          <Button loading={serviceLoading} onClick={() => setIsForm(true)} block>
            {t('service.form.edit')}
          </Button>
        ) : null}

        {/* Edit form buttons */}
        {isManagmentUser && isForm && service ? (
          <>
            {/* Save button */}
            <Button loading={serviceLoading} type="primary" htmlType="submit" block>
              {t('service.form.save')}
            </Button>

            {/* Cancel button */}
            <Button type="text" onClick={() => setIsForm(false)} block style={{ marginTop: 15 }}>
              {t('service.form.cancel')}
            </Button>
          </>
        ) : null}

        {/* Submit */}
        {isForm && !sku ? (
          <>
            {/* Save button */}
            <Button loading={serviceLoading} type="primary" htmlType="submit" block>
              {t('service.form.save')}
            </Button>
          </>
        ) : null}
        {!isForm ? (
          <>
            <div style={{ position: 'absolute', bottom: '10px'}}>
              { service?.creator ? <ContactBox user={service?.creator} key={service?.createdBy} /> : null }

              { service?.editor != null &&  service?.createdBy != service?.updatedBy ? <ContactBox user={service?.editor} key={service?.updatedBy} /> : null }
              <div>

                { service?.updatedAt ?  <Tag>Last update: {DateTime.fromISO(service.updatedAt).toFormat('LLL dd, yyyy | HH:mm:ss')}</Tag> : '-'}
              </div>
            </div>
          </>
        ) : null}
      </Form>

    
    </div>
  )
}

export { ServiceView }
