import { BUILDING_UPSERT_MUTATION,BUILDINGS_LIST, DELETE_BUILDING_MUTATION } from '_graphql'
import { isAdmin, roles, USER_ROLES } from '_graphql/_enums'
import CheckBold from '@2fd/ant-design-icons/lib/CheckBold'
import CloseThick from '@2fd/ant-design-icons/lib/CloseThick'
import Lamp from '@2fd/ant-design-icons/lib/Lamp'
import ToggleSwitch from '@2fd/ant-design-icons/lib/ToggleSwitch'
import { CopyOutlined, CrownOutlined, DeleteOutlined, PlusOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import PersonIcon from '@material-ui/icons/Person'
import PublicIcon from '@material-ui/icons/Public'
import { Link, navigate } from '@reach/router'
import { Button, message, Popconfirm,Tag, Tooltip } from 'antd'
import { ListManager } from 'components/ListManager'
import { string } from 'components/ListManager/DefaultTypes'
import { TutorialUIItem } from 'components/TutorialUI'
import { UseMapOverlay } from 'layouts/MapOverlay'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BuildingsMap } from 'screens/ApartmentsManager/AsideComponents'
import { useGlobalState } from 'state'
import { CountryRender } from 'utils/countries'
import { deepOmit } from 'utils/deepOmit'

import { BuildingAddressForm } from './AsideComponents/BuildingAddressForm'
import { BuildingTile, defaultBuildingActions } from './BuildingTileNew'

const BuildingsManager = () => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)

  /** Редактирование билдинга */
  const [updateBuilding] = useMutation(BUILDING_UPSERT_MUTATION)
  const updateBuildingAction = async (id, fields, callback, successMessage) => {
    const upsertion = await updateBuilding({ variables: { input: { id, ...fields } } })
    if (successMessage && upsertion?.data?.response) { message.success(successMessage) }
    if (callback) { callback() }
    return upsertion?.data?.response
  }

  /** Удаление билдинга */
  const [deleteBuilding] = useMutation(DELETE_BUILDING_MUTATION)
  const deleteBuildingAction = async (item, callback) => {
    await deleteBuilding({ variables: { id: item.id } })
    callback()
  }

  /** Клонирование билдинга */
  const cloneBuildingAction = async (item) => {
    const { data: { response: { id } } } = await updateBuilding({
      variables: {
        input: {
          ... deepOmit({
            payload: item,
            keysToOmit: ['__typename', 'sku', 'photos', 'createdAt', 'updatedAt', 'apartments', 'id', 'profile', 'user'],
          }),
          code: item.code + '(copy)',
        }
      },
    })
    navigate(`/buildings-management/${id}`)
  }

  /** Оверлей Карты */
  UseMapOverlay()
  const zoomToApartmentAction = (item = null) => {
    dispatch({ type: 'BUILDING_HOVER', payload: item?.building?.id || -1 })
  }

  /** Оверлей Создания билдинга */
  const createBuildingOpenOverlay = () => {
    dispatch({ type: 'SET_RIGHT_BAR_COMPONENT', payload: () => <BuildingAddressForm /> })
    dispatch({ type: 'EXPAND_RIGHT_BAR', notClosableRightBar: false })
  }

  return (
    <>
      <ListManager
        slug="buildings-manager"
        title={t('buildings.manager-title')}
        query={BUILDINGS_LIST}
        actions={[
          <TutorialUIItem key={9988}>
            <Button id='buildings-management-add-building-button' type="primary" icon={<PlusOutlined />} onClick={createBuildingOpenOverlay}>{t('buildings.actions.add-building')}</Button>
          </TutorialUIItem>,
        ]}
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('buildings.sorting.id|desc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('buildings.sorting.id|asc'),
              icon: <SortAscendingOutlined />,
            },
            {
              slug: 'userId|desc',
              title: t('buildings.sorting.userId|desc'),
              icon: <PersonIcon className="anticon ant-dropdown-menu-item-icon" />,
              middleware: isManagementUser,
            },
            {
              slug: 'country|desc',
              title: t('buildings.sorting.country|desc'),
              icon: <PublicIcon className="anticon ant-dropdown-menu-item-icon" />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'active',
          presets: [
            {
              title: t('buildings.filter.presets.all'),
              slug: 'all',
              value: {},
            },
            {
              title: t('apartments.filter.presets.active'),
              slug: 'active',
              icon: <ToggleSwitch className="icon--mr" />,
              value: { isActive: true },
            },
          ],
          filters: [
            {
              title: t('buildings.filter.landlords'),
              slug: 'landlords',
              icon: <CrownOutlined />,
              middleware: isManagementUser,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: { role: [USER_ROLES['landlord-owner'].key] },
              },
            },
            {
              title: t('apartments.filter.active-status'),
              slug: 'isActive',
              type: 'boolean',
              options: {
                values: [
                  { key: '$none$', title: t('apartments.filter.any'), icon: <ToggleSwitch className="icon--mr" /> },
                  { key: '$true$', title: t('apartments.fields.isActive.$true$'), icon: <CheckBold className="icon--mr" /> },
                  { key: '$false$', title: t('apartments.fields.isActive.$false$'), icon: <CloseThick className="icon--mr" /> },
                ],
              },
            },
          ],
        }}
        table={{
          columns: [
            {
              title: t('buildings.fields.code'),
              key: 'code',
              fixed: 'left',
              width: 80,
              render: ({ value, item }) => (
                <Link to={`/buildings-management/${item.id}`}>{value || `#${item.id}`}</Link>
              ),
            },
            {
              title: t('buildings.fields.address'),
              key: 'address',
              width: 200,
              render: ({ item }) => (
                string({
                  value: [item.street, item.number].filter((part) => !!part).join(', '),
                  options: { na: true },
                })
              ),
            },
            {
              title: t('buildings.fields.location'),
              key: 'location',
              width: 200,
              render: ({ item }) => (
                <>
                  <CountryRender code={item?.country} />
                  {item?.city ? `, ${item?.city}` : null}
                </>
              ),
            },
            {
              title: t('buildings.fields.profileId'),
              key: 'profileId',
              width: 150,
              render: ({ item }) => {
                if (item?.profile) {
                  return (
                    <Tooltip title="Go to profile page">
                      <Link to={`/profiles-management/${item.profile.id}`}>
                        {item.profile.receptor || `#${item.profile.id}`}
                      </Link>
                    </Tooltip>
                  )
                } else {
                  return (
                    <Tooltip title="There is no any profile attached to this Building">
                      <Link to={`/profiles-management/create?${isManagementUser ? `userId=${item.userId}&` : ''}buildingId=${item.id}`}>
                        <Tag color="warning">Please setup profile</Tag>
                      </Link>
                    </Tooltip>
                  )
                }
            
              },
            },
            {
              title: t('buildings.fields.isBusy.title'),
              key: 'isBusy',
              width: 80,
              middleware: isManagementUser,
              render: ({ value, item, context }) => {
              
                return (
                  <span style={{ display: 'block', minWidth: 103 }}>
                    {value
                      ? <Tag color="red">{t('buildings.fields.isBusy.$true$')}</Tag>
                      : <Tag color="green">{t('buildings.fields.isBusy.$false$')}</Tag>}
                  </span>
                )
              },
            },
            {
              title: t('buildings.fields.status.title'),
              key: 'status',
              width: 60,
              render: ({ value, item, context }) => {
                const handler = () => updateBuildingAction(item.id, { status: !value }, context.dataRefetch, `Great! Building has been successfully ${!value ? 'Activated' : 'Deactivated'}`)
                return (
                  <span role="button" tabIndex="0" aria-label="Toggle building status" onClick={!item.isBusy ? handler : null} onKeyPress={!item.isBusy ? handler : null} className="cursor-pointer" style={{ display: 'block', minWidth: 102 }}>
                    {value
                      ? (<Tooltip title={ !item.isBusy ? 'Click to Deactivate building' : "You can't deactivate busy building"}><Tag color="green">{t('buildings.fields.status.$true$')}</Tag></Tooltip>)
                      : (<Tooltip title="Click to Activate building"><Tag color="red">{t('buildings.fields.status.$false$')}</Tag></Tooltip>)}
                  </span>
                )
              },
            },
           
            {
              key: 'actions',
              title: 'Actions',
              width: 80,
              fixed: 'right',
              render: ({ item, context }) => (
                <>
                  {/** Clone */}
                  <Tooltip placement="bottom" title="Duplicate">
                    <Popconfirm title="Clone this Building?" onConfirm={() => cloneBuildingAction(item)}>
                      <Button shape="circle" type="link" icon={<CopyOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                  {/** Remove */}
                  { !item.isBusy 
                    ? 
                    <Tooltip placement="bottom" title="Remove">
                      <Popconfirm title="Delete this Building?" onConfirm={() => deleteBuildingAction(item, context.dataRefetch)}>
                        <Button shape="circle" type="link" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </Tooltip>
                    : null 
                  }
                </>
              ),
            },
          ],
        }}
        tiles={({ item, context, handlers }) => (
          <BuildingTile {...handlers} item={item} actions={defaultBuildingActions({ item, context, cloneBuildingAction, deleteBuildingAction })} userRole={state.currentUser?.role} key={item.id} />
        )}
        handlers={{
          onMouseEnter: ({ item }) => {
            zoomToApartmentAction(item)
          },
          onMouseLeave: () => {
            zoomToApartmentAction(null)
          },
        }}
      />
    </>
  )
}

export { BuildingDetails } from './BuildingDetails'
export { BuildingsManager }
