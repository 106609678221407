import { useMutation } from '@apollo/react-hooks'
import { navigate } from '@reach/router'
import { Button, Form, message,Typography } from 'antd'
import Illustration from 'components/Illustration'
import AccentedText from 'components/Typography/AccentedText'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import OtpInput from 'react-otp-input'
import { useGlobalState } from 'state'

import { LOGIN } from '../Login/graphql'
import styles from '../styles.less'

export function resetVerifySession(redirect = '/auth/login') {
  window.sessionStorage.removeItem('verify-session')
  if (redirect) {
    navigate(redirect)
  }
}

export function getVerifySession() {
  try {
    const session = JSON.parse(window.sessionStorage.getItem('verify-session'))
    return {
      email: session.email,
      password: session.password
    }
  } catch {
    resetVerifySession()
  }
}

export function setVerifySession({ email, password }) {
  window.sessionStorage.setItem('verify-session', JSON.stringify({
    email,
    password
  }))

  navigate('/auth/verify')
}

export function Verify() {
  const { t } = useTranslation()
  const [code, setCode] = useState('')
  const [login] = useMutation(LOGIN)
  const session = getVerifySession()
  const [, dispatch] = useGlobalState()
  const otpRef = useRef(null)
  const [errored, setErrored] = useState(false)
  const [loading, setLoading] = useState(false)
  const left = useMemo(() => 6 - code.length, [code])
  const form = Form.useForm()
  const submitText = useMemo(() => (
    errored 
      ? t('auth.verify.submit.error')
      : (left > 0)
        ? t('auth.verify.submit.left', { left })
        : t('auth.verify.submit.continue')
  ), [left, errored])

  // Submit handler
  const submitHandler = useCallback((code) => {
    setLoading(true)
    login({
      variables: {
        email: session.email,
        password: session.password,
        code
      }
    }).then(({ data, errors }) => {
      if (data.login?.token) {
        localStorage.setItem('token', data.login.token)
        dispatch({ type: 'LOGIN', payload: true })
        resetVerifySession(false)
      } else if (errors?.length) {
        const msg = errors?.[0]?.message
        message.error(msg)

        setErrored(true)
        if (otpRef && otpRef.current) {
          otpRef.current.focusInput(0)
        }

        if (msg.includes('confirmno users') || msg.includes('Password') || msg.includes('not active')) {
          resetVerifySession()
        }
      }

      setLoading(false)
    })
  }, [])

  // Hide error styling after 2.5 seconds
  useEffect(() => {
    if (errored) {
      const timer = setTimeout(() => setErrored(false), 2500)
      return () => clearTimeout(timer)
    }
  }, [errored])

  // Hide errored styling on code change
  useEffect(() => {setErrored(false)}, [code])

  return (
    <>
      {/* SEO */}
      <Helmet>
        <title>{t('auth.verify.title')}</title>
      </Helmet>
  
      {/* Page */}
      <Illustration name="undraw_safe_re" width="807.5" height="500" className={styles.illustration} />
      <Typography.Title><AccentedText text={t('auth.verify.title')} /></Typography.Title>
      <Typography.Paragraph>{t('auth.verify.welcome-message')}</Typography.Paragraph>

      <Form form={form} className={styles.verify} onFinish={() => left === 0 && submitHandler(code)}>
        {/* Input */}
        <OtpInput
          ref={otpRef}
          value={code}
          onChange={setCode}
          numInputs={6}
          inputStyle="ant-input ant-input-lg"
          containerStyle={styles.verifyContainer}
          disabled={loading}
          shouldAutoFocus
          isInputNum
        />

        {/* Submit */}
        <Button 
          size="large"
          type="primary"
          htmlType="submit"
          disabled={!errored && left !== 0}
          danger={errored}
          loading={loading}
          block
        >
          {submitText}
        </Button>
      </Form>

      {/* Navigation */}
      <Typography.Paragraph className={styles.navigation}>
        {t('auth.verify.navigation-message')} <Button type="link" size="small" onClick={() => resetVerifySession()}>{t('auth.verify.logout')}</Button>
      </Typography.Paragraph>
    </>
  )
}
