import 'react-chat-elements/dist/main.css'
import './chat.css'

import { isAdmin } from '_graphql/_enums'
import { MESSAGE_MUTATION, MESSAGE_QUERY, MESSAGE_SUBSCRIPTION } from '_graphql/message'
import { PaperClipOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { Button, Input, Typography, Upload } from 'antd'
import { LayoutCard, LayoutCardTitle } from 'components/Layout'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MessageBox, MessageList } from 'react-chat-elements'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import stc from 'string-to-color'

const ChatBox = (props) => {
  const { t } = useTranslation()
  const { entityType, entityId } = props
  const [state] = useGlobalState()
  const isManagmentUser = isAdmin(state.currentUser?.role)
  const [sendMessageRaw, { loading: sendingMessage }] = useMutation(MESSAGE_MUTATION)

  console.log('props', props)

  // Messages
  const [messages, setMessages] = useState([])

  // Send message action
  const sendMessageAction = (input) => {
    return sendMessageRaw({
      variables: {
        input
      }
    })
  }
    
  const formatedMessages = useMemo(() => {
    return messages.map((message) => {
      try {
        let type = 'text'
        let text = message.message
        let data = undefined
        let onClick = undefined

        // File
        if (message.type === 1) {
          const context = JSON.parse(message.message)
          onClick = () => window.open(context.url, '_blank')
          type = context.mimetype.includes('image') ? 'photo' : 'file'
          text = context.filename
          data = {
            uri: context.url,
          }
        }

        // Service request | Close
        if (message.type === 2) {
          text = (
            <>
              <div style={{ marginBottom: 7 }}>  {t('serviceRequests.page.chat.closeMessage')}</div>
              {!message.message ? (
                <>
                  <Button type="primary" style={{ marginRight: 7 }} onClick={() => { sendMessageAction({ id: message.id, message: 'no' }) }}>{t('serviceRequests.page.chat.markAsCompleted')}</Button>
                  <Button type="text" onClick={() => { sendMessageAction({ id: message.id, message: 'yes' }) }}>{t('serviceRequests.page.chat.yes')}</Button>
                </>
              ) : (
                <Typography.Text code><Typography.Text strong>{t('serviceRequests.page.chat.response')}:</Typography.Text> {message.message}</Typography.Text>
              )}
            </>
          )
        }
                
        return {
          id: message.id,
          position: state.currentUser?.id === message.userId ? 'right' : 'left',
          title: `${message.user?.firstName ?? ''} ${message.user?.lastName ?? ''}`.trim() || (message.userId ? `#${message.userId}` : 'System'),
          titleColor: stc(message.userId),
          type,
          text,
          data,
          onClick,
          date: message.createdAt,
        }
      } catch {
        return null
      }
    }).filter(message => !!message)
  }, [messages, state.currentUser])

  const { data: messagesResponse } = useQuery(MESSAGE_QUERY, { 
    variables: { entityType: String(entityType), entityId: Number(entityId) },
    fetchPolicy: 'no-cache'
  })
  const { data: messagesSubscriptionResponse } = useSubscription(MESSAGE_SUBSCRIPTION, {
    variables: { entityType: String(entityType), entityId: Number(entityId) },
  })
  useEffect(() => {
    const newMessages = [
      ...(messagesSubscriptionResponse?.response ? [messagesSubscriptionResponse?.response] : []),
      ...(messagesResponse?.response ?? []),
    ].filter((messageLeft) => (!messages.find(messageRight => JSON.stringify(messageLeft) === JSON.stringify(messageRight))))
    setMessages([
      ...messages.filter((messageLeft) => (!newMessages.find(messageRight => messageLeft.id === messageRight.id))), 
      ...newMessages.map(message => ({ ...message, createdAt: new Date(message.createdAt) }))
    ].sort((left, right) => (left.createdAt - right.createdAt)))
  }, [messagesResponse, messagesSubscriptionResponse])

  // Message
  const [message, setMessage] = useState('')
  const [isSending, setIsSending] = useState(false)
  const disabled = !String(message).trim() || isSending || sendingMessage
  const sendMessage = useCallback(async (file) => {
    try {
      setIsSending(true)
      await sendMessageRaw({
        variables: {
          input: {
            entityType: String(entityType),
            entityId: Number(entityId),
            message,
            file,
            type: file ? 1 : 0
          }
        }
      })

      setMessage('')
    } catch (e) {
      console.error(e)
    } finally {
      setIsSending(false)
    }
  }, [props.type, props.id, message])

  // Upload
  const uploadProps = {
    showUploadList: false,
    name: 'file',
    multiple: true,
    
    // Uplaod status notification
    onChange(info) {
      const { status } = info.file
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    
    // Upload
    customRequest: async ({ file, onSuccess }) => {
      await sendMessage(file)
    },
  }

  // Templates
  const [templateDisabled, setTemplateDisabled] = useState(false)
  useEffect(() => {
    const status = sendingMessage || isSending

    const timer = setTimeout(() => {
      setTemplateDisabled(status)
    }, status ? 0 : 3000)

    return () => clearTimeout(timer)
  }, [sendingMessage, isSending])

  const hasTemplates = isManagmentUser && entityType === 'service-request'

  // Scroll down
  useEffect(() => {
    try {
      const messages = document.getElementById('messagesBox')
      messages.scrollTo(0, messages.scrollHeight)
    } catch {
      //
    }
  }, [formatedMessages])

  return (
    <LayoutCard style={{ marginBottom: '10px', overflow: 'hidden', padding: 0, paddingBottom: hasTemplates ? 120 : 75, position: 'relative' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 15, zIndex: 15, background: 'linear-gradient(#fff, rgba(0, 0, 0, 0))' }} />
      <div style={{ minHeight: '66vh', maxHeight: '66vh', overflowY: 'scroll', padding: '15px 0' }} id="messagesBox">
        <div className="message-list">
          {formatedMessages?.map((message) => (
            <MessageBox 
              key={message.id}
              lockable={true}
              toBottomHeight={'100%'}
              {...message}
            />
          ))}
        </div>
      </div>

      <div style={{ borderTop: '2px solid #eff1f2', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px', position: 'absolute', bottom: 0, left: 0, width: '100%', background: '#fff' }}>
        {/* Templates */}
        {hasTemplates ? (
          <div style={{ padding: '7px 16px', borderBottom: '1px solid #f0f0f0' }}>
            <Button 
              disabled={templateDisabled}
              style={{ backgroundColor: '#990066', color: '#fff', borderRadius: 6, borderColor: 'rgba(0, 0, 0, 0)' }}
              onClick={() => sendMessageAction({
                entityType: String(entityType),
                entityId: Number(entityId),
                type: 2
              })}
            >
              {t('serviceRequests.page.chat.finishButton')}
            </Button>
          </div>
        ) : null}
        {/* Message */}
        <div style={{ display: 'flex' }}>
          {/* Message */}
          <Input.TextArea autoFocus value={message}  onChange={(e) => setMessage(e.target.value)} rows={2} style={{ borderWidth: 0, boxShadow: 'none', resize: 'none' }} placeholder={t('serviceRequests.page.chat.messagePlaceholder')} />
          {/* Submit */}
          <Button onClick={() => sendMessage()} disabled={disabled} size="large" type="primary" shape="circle" icon={<SendOutlined />} style={{ margin: 10 }} />
          {/* Attachment */}
          <Upload {...uploadProps}>
            <Button style={{ marginTop: 10, marginRight: 5 }} disabled={!disabled || isSending} size="large" type="text" icon={<PaperClipOutlined />} />
          </Upload>
        </div>
      </div>
    </LayoutCard>
  )
}

export { ChatBox }
