import { USER_UPSERT_MUTATION } from '_graphql'
import {
  isAdmin,
  isSingleGroup,
  isSlave,
  roles,
  USER_ROLES,
} from '_graphql/_enums'
import { PROJECT_LIST } from '_graphql/project'
import { ME_QUERY, USER_QUERY } from '_graphql/user/queries'
import { useLazyQuery, useMutation,useQuery } from '@apollo/react-hooks'
import { InputOutlined } from '@material-ui/icons'
import { navigate } from '@reach/router'
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from 'antd'
import { NotFound } from 'components'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProfilesManager } from 'screens/ProfilesManager'
import { columns } from 'screens/ProfilesManager/tableSetup'
import { WbsManager } from 'screens/WbsManager'
import { useGlobalState } from 'state'
import styled from 'styled-components'

const UserDetails = (props) => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()
  const [, rerender] = useState()

  const {
    data: userData,
    loading: userLoading,
    refetch: userRefetch,
  } = useQuery(ME_QUERY)


  const [upsertUser] = useMutation(USER_UPSERT_MUTATION)

  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()

  const formData = form1.getFieldsValue()

  const [saveTrigger, setSaveTrigger] = useState(false)
  const [, forceUpdate] = useState()

  useEffect(() => {
    form1.setFieldsValue(userData?.response)
    form3.setFieldsValue(userData?.response)
    forceUpdate(Date.now())
  }, [userData])

  if (!userData?.response && !userLoading) {
    return <NotFound />
  }

  const submitInfo = async () => {
    const input = form1.getFieldsValue([
      'firstName',
      'lastName',
      'email',
      'phone',
      'role',
    ])

    if (isSlave(input.role)) {
      input.ownerId = state.currentUser.ownerId
    }

    if (!input.firstName) {
      message.warning(
        t('user.messages.errors.blank-field', {
          field: t('user.fields.firstName'),
        }),
      )
      return null
    }
    if (!input.lastName) {
      message.warning(
        t('user.messages.errors.blank-field', {
          field: t('user.fields.lastName'),
        }),
      )
      return null
    }
    if (!input.email) {
      message.warning(
        t('user.messages.errors.blank-field', { field: t('user.fields.email') }),
      )
      return null
    }

    input.id = userData?.response?.id

    const d = await upsertUser({ variables: { input } })

    if (d.response?.errors?.length) {
      message.error(t('user.messages.errors.error-saving'))
    } else {
      message.success(t('user.messages.updated-successfully'))
    }
  }


  const submitPassword = async () => {
    const input = form2.getFieldsValue(['password1', 'password2'])

    if (!input.password1) {
      message.warning(
        t('user.messages.errors.blank-field', {
          field: t('user.fields.password'),
        }),
      )
      return null
    }

    if (input.password1 !== input.password2) {
      message.warning(t('user.messages.errors.password-do-not-match'))
      return null
    }

    const d = await upsertUser({
      variables: {
        input: { id: userData.response.id, password: input.password1 },
      },
    })

    if (d.errors?.length) {
      message.error(t('user.messages.errors.error-updating-password'))
    } else {
      message.success(t('user.messages.password-updated'))
      form2.setFieldsValue({ password1: '', password2: '' })
    }
  }

  return (
    <LayoutOverlay>
      <Row gutter={[20, 20]}>
        <Col xs={24} style={{ textAlign: 'left' }}>
          <h2 style={{ fontSize: '24px' }}>
            {t('user.form.welcome', userData)}
          </h2>
        </Col>
      </Row>

      <Row gutter={[20, 20]}>
        <Col xs={16}>
          <Form
            layout="vertical"
            form={form1}
            onFieldsChange={rerender} 
            onChange={() => {
              setSaveTrigger(!saveTrigger)
            }}
          >
            <LayoutCard>
              <h2 style={{ fontSize: '24px' }}>{t('user.form.userInfo')}</h2>
              <Form.Item label={t('user.fields.firstName')} name="firstName">
                <Input />
              </Form.Item>
              <Form.Item label={t('user.fields.lastName')} name="lastName">
                <Input />
              </Form.Item>
              <Form.Item label={t('user.fields.email')} name="email">
                <Input />
              </Form.Item>
              <Form.Item
                label={t('user.fields.phone')}
                name="phone"
                rules={[
                  {
                    validator(_rule, value, callback) {
                      if (
                        value
                        && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(
                          value,
                        )
                      ) {
                        callback(
                          t('user.messages.errors.invalid-field', {
                            field: t('user.fields.phone'),
                          }),
                        )
                      }
                      callback()
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="role" label={t('user.fields.role')}>
                <Select onChange={rerender}>
                  {Object.values(USER_ROLES)
                    .filter(
                      (role) => (isAdmin(state.currentUser.role)
                          && !isSlave(role.id))
                        || (!isAdmin(state.currentUser.role)
                          && isSlave(role.id)
                          && isSingleGroup(state.currentUser.role, role.id)),
                    )
                    .map((role) => (role.id !== roles.SUPER_ADMIN
                      || state.currentUser?.role === roles.SUPER_ADMIN ? (
                        <Select.Option value={role.id} key={role.id}>
                          {role.title}
                        </Select.Option>
                      ) : null))}
                </Select>
              </Form.Item>
              <Button type="primary" onClick={submitInfo}>
                {t('user.actions.save')}
              </Button>
            </LayoutCard>
          </Form>
        </Col>

        <Col xs={8}>

          <Form layout="vertical" form={form2}>
            <LayoutCard>
              <h2 style={{ fontSize: '24px' }}>
                {t('user.form.changePassword')}
              </h2>

              <Form.Item label={t('user.form.new-password')} name="password1">
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={t('user.form.repeat-password')}
                name="password2"
              >
                <Input.Password />
              </Form.Item>

              <Button type="primary" onClick={submitPassword}>
                {t('user.actions.save')}
              </Button>
            </LayoutCard>
          </Form>
        </Col>
      </Row>

    </LayoutOverlay>
  )
}

export { UserDetails }

const SpacedForm = styled.div`
  .ant-space-item {
    margin-bottom: auto;
  }
`

const SpacedButton = styled.div`
  margin-top: 30px;
`
