import { Avatar } from 'antd'
import React, { useMemo } from 'react'
import stc from 'string-to-color'

import { detectRoleGroup } from '../Role'
import styles from './styles.less'

function UserAvatar(props) {
  const { user, ...antdProps } = props
  const userColor = useMemo(() => stc(user?.id), [user?.id])
  const roleColor = useMemo(() => stc(user?.role), [user?.role])
  const roleGroupColor = useMemo(() => stc(detectRoleGroup(user?.role)), [user?.role])
  const text = useMemo(
    () => ([user.firstName, user.lastName].map(part => (part ?? '').substr(0, 1)).join('')), 
    [user?.firstName, user?.lastName]
  )

  return (
    <Avatar 
      style={{ 
        backgroundColor: userColor, 
        backgroundImage: `radial-gradient( circle farthest-side at -4.3% 78%, ${roleGroupColor} 0%, ${roleColor} 23.7%, ${userColor} 89.7% )`,
        verticalAlign: 'middle'
      }} 
      className={styles.block} 
      {...antdProps}
    >
      {text}
    </Avatar>
  )
}

export default UserAvatar
