import { CONTRACTSERVICE_PROVIDERS, isAdmin,isLandlord, isTenant, PROFILE_TYPES, PROFILE_TYPES_ROLES, roles, USER_ROLES } from '_graphql/_enums'
import { PROFILE_UPSERT_MUTATION } from '_graphql/profile/mutations'
import { PROFILE_QUERY } from '_graphql/profile/queries'
import { ALL_USERS_QUERY, ME_QUERY } from '_graphql/user/queries'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useMutation,useQuery } from '@apollo/react-hooks'
import { navigate, useParams } from '@reach/router'
import { Button, Col, Divider, Form, Input, message, Row, Select, Space, Spin, Switch, Tooltip } from 'antd'
import { NotFound } from 'components'
import { UserSelectExperimental } from 'components/Form/UserSelect'
import { LayoutCard, LayoutCardTitle, LayoutFooterActions,LayoutOverlay, LayoutStickyFooter } from 'components/Layout'
import IBAN from 'iban'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import { TUTORIAL_TYPES, useTutorials } from 'tutorials'
import { TUTORIALS } from 'tutorials/data'
import { CountryRender, SelectCountry } from 'utils/countries'
import { SelectVAT } from 'utils/vat'


const keys = [
  'type',
  'invoiceLang',
  'userId',
  'companyName',
  'profileName',
  'billingAddress.country',
  'billingAddress.city',
  'billingAddress.address',
  'billingAddress.zipCode',
  'taxRate',
  'addressAddition',
  'zipCode',
  'city',
  'country',
  'residenseCert',
  'tenantExchange',
  'contactFullName',
  'phoneNumber',
  'correspondanceEmail',
  'receptor',
  'ibanCode',
  'bicCode',
  'clearingCode',
  'bankName',
  'companyType',
  'comission',
]

const ProfileUserSelect = () => {
  const form = Form.useFormInstance()
  const { t } = useTranslation()

  useEffect(() => {
    form.setFieldValue('userId', null)
  }, [form.getFieldValue('companyType')])

  return (
    <Form.Item name="userId" label={t('profiles.fields.user')}>
      <UserSelectExperimental roles={PROFILE_TYPES_ROLES[form.getFieldValue('companyType')]} disableButton />
    </Form.Item>
  )
}

const ProfileForm = ({ isModal, query, onClose, userId }) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const params = query ? query : useParams()

  const role = state.currentUser?.role

  const { data: profileData, loading: profileLoading } = useQuery(PROFILE_QUERY, { variables: { id: +params.id } })
  // const { data: usersData, loading: usersLoading } = useQuery(ALL_USERS_QUERY)

  const [upsertProfile] = useMutation(PROFILE_UPSERT_MUTATION)

  const [form] = Form.useForm()

  const [saveTrigger, setSaveTrigger] = useState(false)
  const [, forceUpdate] = useState(null)

  useEffect(() => {
    form.setFieldsValue({ email: state.currentUser?.email })
    form.setFieldsValue({ companyType: isAdmin(role) ? null : (isLandlord(role) ? 'landlord' : 'customer' )})
    form.setFieldsValue({ invoiceLang: 'german'})
  }, [state.currentUser, role])

  useEffect(() => {
    const values = profileData?.response
    if (!values) {
      form.setFieldsValue({ billingAddressSameAsWork: true })
      return
    }

    // Same as work address
    if (
      !values?.billingAddress
      || (
        values['billingAddress.country'] === values.country
        && values['billingAddress.city'] === values.city
        && values['billingAddress.address'] === values.addressAddition
        && values['billingAddress.zipCode'] === values.zipCode
      )
    ) {
      values.billingAddressSameAsWork = true
    }

    form.setFieldsValue(values)
    forceUpdate()
  }, [profileData])

  useEffect(() => {
    if (userId) {
      form.setFieldsValue({ userId })
    }
  }, [userId])

  const tutorials = useTutorials()

  if (!profileData?.response && !profileLoading && params.id) {
    return <NotFound />
  }

  const billingAddressSameAsWork = form.getFieldValue('billingAddressSameAsWork')

  const goBack = (id) => {
    if (onClose) {
      onClose(id)
    } else {
      navigate('/profiles-management')
    }
  }

  const saveProfile = async () => {
    const values = form.getFieldsValue(keys)

    // Billing address same as work
    if (billingAddressSameAsWork) {
      values['billingAddress.country'] = values.country
      values['billingAddress.city'] = values.city
      values['billingAddress.address'] = values.addressAddition
      values['billingAddress.zipCode'] = values.zipCode
    }

    // Format billing address
    values.billingAddress = {
      country: values['billingAddress.country'],
      city: values['billingAddress.city'],
      address: values['billingAddress.address'],
      zipCode: values['billingAddress.zipCode'],
    }

    delete values['billingAddress.country']
    delete values['billingAddress.city']
    delete values['billingAddress.address']
    delete values['billingAddress.zipCode']

    if (isLandlord(state.currentUser?.role) || isTenant(state.currentUser?.role)) {
      values.userId = state.currentUser?.ownerUserId ?? state.currentUser?.id
    }

    if (profileData.response?.id) {
      const d = await upsertProfile({
        variables: {
          input: {
            id: profileData.response.id || null,
            ...values,
          },
        },
      })

      if (d?.data?.response) {
        message.success(t('profiles.messages.profile-saved'))
        tutorials.startTutorial(TUTORIALS[TUTORIAL_TYPES.CREATE_BUILDING_TUTORIAL])
        goBack()
      } else {
        message.error(t('profiles.messages.profile-save-error'))
      }
    } else {
      const d = await upsertProfile({
        variables: {
          input: {
            ...values,
          },
        },
      })

      if (d.data?.response) {
        message.success(t('profiles.messages.profile-created'))
        tutorials.startTutorial(TUTORIALS[TUTORIAL_TYPES.CREATE_BUILDING_TUTORIAL])
        goBack(d.data?.response?.id)
      } else {
        message.error(t('profiles.messages.profile-creation-error'))
      }
    }
  }

  return (
    <Spin tip={t('profiles.messages.fetching')} spinning={profileLoading}>
      <Form
        layout="vertical"
        form={form}
        onFinish={saveProfile}
        onChange={() => {
          setSaveTrigger(!saveTrigger)
        }}
        scrollToFirstError
      >
        <LayoutOverlay>
          {!isTenant(role) ? (
            <LayoutCard>
              <LayoutCardTitle>{t('profiles.sections.attach-profile')}</LayoutCardTitle>
              <Divider />
              <Row  gutter={80}>
                <Col xs={12} lg={12}>
                  <Form.Item name="companyType" label={t('profiles.fields.type')} required rules={[{ required: true }]}>
                    <Select allowClear disabled={isAdmin(role) ? false : true }  >
                      {Object.values(PROFILE_TYPES).map((companyType) =>  (
                        <Select.Option value={companyType.id} key={companyType.id}>
                          {companyType.title}
                        </Select.Option>
                      ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                {/** Выбор пользователя | TODO: Виджет */}
                {!isLandlord(role) && !isTenant(role) ? (
                  <Col xs={12} lg={12}>
                    <Form.Item 
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => prevValues.companyType !== currentValues.companyType}
                    >
                      {() => <ProfileUserSelect />}
                    </Form.Item>
                  </Col>
                ) : null}
                {/** Язык счетов */}
                {isLandlord(role) || isAdmin(role) ? (
                  <Col xs={24} lg={12}>
                    <Form.Item name="type" label={t('profiles.fields.serviceType')}>
                      <Select mode="multiple" allowClear >
                        {Object.values(CONTRACTSERVICE_PROVIDERS).map((service) =>  (
                          <Select.Option value={service.id} key={service.id}>
                            {service.title}
                          </Select.Option>
                        ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
            </LayoutCard> ) 
            : null }
          {!isTenant(role) ? (
            <LayoutCard>
              <LayoutCardTitle>{t('profiles.sections.business-details')}</LayoutCardTitle>
              <Divider />
              <Row gutter={80}>
                <Col xs={24} lg={7}>
                  {/** Profile Type */}
                  <Form.Item name="invoiceLang" label={t('profiles.fields.invoiceLang')}  rules={[{ required: true }]}>
                    <Select>
                      <Select.Option value="german">
                      German
                      </Select.Option>
                      <Select.Option value="english">
                      English
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/** Residence Certificate */}
                <Col xs={24} lg={5}>
                  <Form.Item name="residenseCert" label={t('profiles.fields.residenseCert')} valuePropName="checked">
                    <Switch onChange={() => { setSaveTrigger(!saveTrigger) }} />
                  </Form.Item>
                </Col>
                {/** Residence Certificate */}
                <Col xs={24} lg={5}>
                  <Form.Item name="tenantExchange" label={t('profiles.fields.tenantExchange')} valuePropName="checked">
                    <Switch onChange={() => { setSaveTrigger(!saveTrigger) }} />
                  </Form.Item>
                </Col>
                {!isLandlord(state.currentUser?.role) && !isTenant(state.currentUser?.role) ? (
                  <Col xs={24} lg={7}>
                    <Form.Item name="comission" label={t('profiles.fields.comissions')} >
                      <Select>
                        <Select.Option value={5}>5% </Select.Option>
                        <Select.Option value={10}>10% </Select.Option>
                        <Select.Option value={12}>12%</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                ) : null }
              </Row> 
            </LayoutCard> ) 
            : null }

          <LayoutCard>
            <LayoutCardTitle>{t('profiles.sections.company-details')}</LayoutCardTitle>
            <Divider />
            <Row gutter={80}>
              <Col xs={24} lg={12}>

                {/** Company Name */}
                <Form.Item name="companyName" label={t('profiles.fields.companyName')} required rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

                {/** Country */}
                <Form.Item name="country" label={t('profiles.fields.country')} required rules={[{ required: true }]}>
                  <SelectCountry onChange={forceUpdate} />
                </Form.Item>

                {/** City */}
                <Form.Item name="city" label={t('profiles.fields.city')} required rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

              </Col>
              <Col xs={24} lg={12}>

                {/** Work Address */}
                <Form.Item name="addressAddition" label={t('profiles.fields.addressAddition')} required rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

                {/** ZIP */}
                <Form.Item name="zipCode" label={t('profiles.fields.zipCode')} required rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                {/** Tax Rate */}
                {!isTenant(state.currentUser?.role) ? (
                  <Form.Item name="taxRate" label={t('profiles.fields.taxRate')} required rules={[{ required: true }]}>
                    <SelectVAT country={form.getFieldValue('country')} />
                  </Form.Item>
                ): null}
              </Col>
            </Row>

          </LayoutCard>

          <LayoutCard>
            <LayoutCardTitle>
              {t('profiles.sections.billing-details')}

              <span style={{ float: 'right' }}>
                <Form.Item name="billingAddressSameAsWork" label={t('profiles.fields.billingAddressSameAsWork')} valuePropName="checked">
                  <Switch onChange={() => { setSaveTrigger(!saveTrigger); forceUpdate() }} />
                </Form.Item>
              </span>

            </LayoutCardTitle>
            <Divider />
            <Row gutter={80}>

              <Col xs={24} lg={12}>
                {/** Country */}
                <Form.Item name="billingAddress.country" label={t('profiles.fields.country')} required disabled rules={[{ required: !billingAddressSameAsWork }]}>
                  {billingAddressSameAsWork ? <CountryRender code={form.getFieldValue('country')} /> : <SelectCountry />}
                </Form.Item>

                {/** City */}
                <Form.Item name="billingAddress.city" label={t('profiles.fields.city')} required rules={[{ required: !billingAddressSameAsWork }]}>
                  {billingAddressSameAsWork ? form.getFieldValue('city') : <Input />}
                </Form.Item>

              </Col>
              <Col xs={24} lg={12}>
                {/** Billing Address */}
                <Form.Item name="billingAddress.address" label={t('profiles.fields.address')} required rules={[{ required: !billingAddressSameAsWork }]}>
                  {billingAddressSameAsWork ? form.getFieldValue('addressAddition') : <Input />}
                </Form.Item>

                {/** ZIP */}
                <Form.Item name="billingAddress.zipCode" label={t('profiles.fields.zipCode')} required rules={[{ required: !billingAddressSameAsWork }]}>
                  {billingAddressSameAsWork ? form.getFieldValue('zipCode') : <Input />}
                </Form.Item>
              </Col>
            </Row>
          </LayoutCard>

          <LayoutCard>
            <LayoutCardTitle>{t('profiles.sections.contact-details')}</LayoutCardTitle>
            <Divider />
            <Row gutter={80}>
              <Col xs={24} lg={12}>
                {/** Profile Name */}
                <Form.Item name="profileName" label={t('profiles.fields.profileName')} rules={[{ required: true, message: 'Profile name is required' }]}>
                  <Input suffix={(
                    <Tooltip title="This field will be used as internal profile identificator">
                      <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  )}
                  />
                </Form.Item>

                {/** Contact Full Name */}
                <Form.Item name="contactFullName" label={t('profiles.fields.contactFullName')}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                {/** Phone Number */}
                <Form.Item
                
                  name="phoneNumber"
                  label={t('profiles.fields.phoneNumber')}
                  required
                  rules={[{
                    required: true,
                    validator(_rule, value, callback) {
                      if (value && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(value)) {
                        callback('Invalid phone number')
                      }
                      callback()
                    },
                  }]}
                  
                >
                  <Input />
                </Form.Item>

                {/** Email for Correspondence */}
                <Form.Item
                  name="correspondanceEmail"
                  label={`${t('profiles.fields.correspondanceEmail')} (optional)`}
                  rules={[{
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </LayoutCard>
          {!isTenant(state.currentUser?.role) ? (
            <>
              <LayoutCard>
                <LayoutCardTitle>{t('profiles.sections.financial-details')}</LayoutCardTitle>
                <Divider />
                <Row gutter={80}>
                  <Col xs={24} lg={12}>
                    {/** Receptor */}
                    <Form.Item name="receptor" label={t('profiles.fields.receptor')} required rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>

                    {/** Bank name */}
                    <Form.Item name="bankName" label={t('profiles.fields.bankName')}>
                      <Input />
                    </Form.Item>

                    {/** Clearing Code */}
                    {form.getFieldValue('country') === 'CH' ? (
                      <Form.Item name="clearingCode" label={t('profiles.fields.clearingCode')}>
                        <Input />
                      </Form.Item>
                    ) : null}
                  </Col>

                  <Col xs={24} lg={12}>

                    {/** BIC */}
                    <Form.Item
                      name="bicCode"
                      label={`${t('profiles.fields.bicCode')} (11 symbols)`}
                      required
                      rules={[{
                        pattern: /(^([A-Z]{6}[A-Z2-9][A-NP-Z1-2])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$|(^.{0}$))/,
                        transform: (value) => (value.toUpperCase()),
                        message: 'Invalid BIC code',
                        required: true,
                      }]}
                    >
                      <Input placeholder="BICBICB1XXX" />
                    </Form.Item>

                    {/** IBAN */}
                    <Form.Item
                      name="ibanCode"
                      label={`${t('profiles.fields.ibanCode')} (up to 34 symbols)`}
                      required
                      rules={[{
                        validator(rule, value, callback) {
                          if (value && !IBAN.isValid(value)) {
                            callback('Invalid IBAN code')
                          } else {
                            callback()
                          }
                        },
                        required: true,
                      }]}
                    >
                      <Input placeholder="BE98 1337 0000 1111" />
                    </Form.Item>
                  </Col>
                </Row>
              </LayoutCard>
            </>) : null}

          <LayoutStickyFooter>
            <LayoutFooterActions>
              <Button size="large" type="default" onClick={() => goBack()}>{t('profiles.actions.cancel')}</Button>
              <Form.Item noStyle>
                <Button size="large" type="primary" htmlType="submit">{t('profiles.actions.save')}</Button>
              </Form.Item>
            </LayoutFooterActions>
          </LayoutStickyFooter>

        </LayoutOverlay>
      </Form>
    </Spin>
  )
}

export { ProfileForm }
