import { gql } from 'apollo-boost'

export const watchFragment = gql`
  fragment watchFragment on Watch {
    id
    sku
    userId
    entityId
  }
`
