import EmailOutlineIcon from '@2fd/ant-design-icons/lib/EmailOutline'
import LockOutlineIcon from '@2fd/ant-design-icons/lib/LockOutline'
import { useMutation } from '@apollo/react-hooks'
import { Link } from '@reach/router'
// import { useLocalStorage } from 'utils/useLocalStorage';
import { useLocalStorage,writeStorage } from '@rehooks/local-storage'
import { Button, Checkbox, Col,Form, Input, message, Row, Typography } from 'antd'
import AccentedText from 'components/Typography/AccentedText'
import { divide } from 'ramda'
import React, { useEffect, useMemo,useReducer, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useTimeoutFn } from 'react-use'
import { useGlobalState } from 'state'

import styles from '../styles.less'
import { setVerifySession } from '../Verify'
import { LOGIN } from './graphql'

const initLoginState = {
  email: '',
  password: '',
}

const reducer = (state, action) => ({ ...state, ...action })

export function Login() {
  const { t } = useTranslation()
  const [, dispatch] = useGlobalState()
  const [formState, dispatchFormState] = useReducer(reducer, initLoginState)
  const [formIsValid, setFormIsValid] = useState(false)
  
  const [form] = Form.useForm()

  const [login, { loading }] = useMutation(LOGIN)

  const userLogin = async () => {
    login({
      variables: formState,
    }).then(({ data, errors }) => {
      if (data.login?.token) {
        localStorage.setItem('token', data.login.token)
        dispatch({ type: 'LOGIN', payload: true })
      } else if (!loading && errors?.length) {
        const msg = errors?.[0]?.message
        if (msg.includes('confirm')) {
          setVerifySession(formState)
        }
        message.error(msg)
      } else {
        message.error('Unexpected error')
      }
    })
  }

  const setFormValue = (e) => {
    dispatchFormState({ [e.target.name]: e.target.value })
  }

  const validateForm = async () => {
    try {
      await form.validateFields(['email', 'password'])
      return []
    } catch (res) {
      return res.errorFields
    }
  }
  
  const formIsEmpty = useMemo(() => Object.values(formState).filter(value => !!value).length === 0, [formState])

  useEffect(() => {
    const f = async () => {
      if (!formIsEmpty) {
        const errors = await validateForm()
        setFormIsValid(!errors.length)
      } else {
        setFormIsValid(true)
      }
    }
    f()
  }, [formState])

  // Focus on form after animation complate
  const [alreadyFocused, setAlreadyFocused] = useState(false)
  const focusableRef = useRef(null)
  useTimeoutFn(() => !alreadyFocused && focusableRef.current.focus(), 2500)

  return (
    <>
      {/* SEO */}
      <Helmet>
        <title>{t('auth.sign-up.title')}</title>
      </Helmet>

      {/* Page */}
      <Typography.Title><AccentedText text={t('auth.sign-in.title')} /></Typography.Title>
      <Typography.Paragraph>{t('auth.sign-in.welcome-message')}</Typography.Paragraph>

      <Form form={form} size="large" className={styles.form} validateTrigger="onChange">

        {/* Email */}
        <Form.Item name="email" rules={[{ required: true }]}>
          <Input 
            name="email"
            type="email"
            value={formState.email}
            onChange={setFormValue}
            prefix={<EmailOutlineIcon />}
            placeholder={t('fields.email.enter')}
            ref={!formState.email || formState.password ? focusableRef : undefined}
            onFocus={() => setAlreadyFocused(true)}  
          />
        </Form.Item>

        {/* Password */}
        <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password 
            name="password" 
            type="password" 
            value={formState.password}
            onChange={setFormValue}
            prefix={<LockOutlineIcon />} 
            placeholder={t('fields.password.short')} 
            ref={formState.email && !formState.password ? focusableRef : undefined}
            onFocus={() => setAlreadyFocused(true)}  
          />
        </Form.Item>


        {/* Addition options */}
        <div className={styles.addition}>
          <Form.Item name="remember">
            <Checkbox name="remember" value={formState.remember}>
              {t('fields.remember.me')}
            </Checkbox>
          </Form.Item>
          <Link to="/auth/forgot">
            {t('auth.forgot-password.title')}?
          </Link>
        </div>

        {/* Navigation */}
        <div>
          <Button type="primary" htmlType="submit" block disabled={!formIsValid || formIsEmpty} loading={loading} onClick={userLogin}>
            {t('auth.sign-in.submit')}
          </Button>

          <Typography.Paragraph className={styles.navigation}>
            {t('auth.sign-in.navigation-message')} <Link to="/auth/registration">{t('auth.sign-up.title')}</Link>
          </Typography.Paragraph>
        </div>

      </Form>
    </>
  )
}
