import { isLandlord, roles } from '_graphql/_enums'
import { SERVICE_UPSERT_MUTATION,SERVICES_LIST } from '_graphql/service'
import EyeOutline from '@2fd/ant-design-icons/lib/EyeOutline'
import {
  CloudServerOutlined,
  CopyOutlined,
  CrownOutlined,
  DeleteOutlined,
  FolderViewOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Link, navigate } from '@reach/router'
import { Button, message, Popconfirm, Tag, Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import { ListManager } from 'components/ListManager'
import { string } from 'components/ListManager/DefaultTypes'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import { CountryRender } from 'utils/countries'
import { deepOmit } from 'utils/deepOmit'

import { ServiceForm } from './ServiceForm'
import { ServiceView } from './ServiceView'

const ServicesManager = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const { openModal } = useModals()

  /** Клонирование профиля */
  const [upsertService] = useMutation(SERVICE_UPSERT_MUTATION)
  const cloneProjectAction = async (item) => {
    // const { data: { response: { id } } } = await upsertProject({ variables: { input: deepOmit({ payload: item, keysToOmit: ['__typename', 'id', 'user'] }) } });
    // navigate(`/wbs-management/${id}`);
    console.log('clone project')
  }

  /** Удаление профиля */
  // const [deleteProject] = useMutation(Project_DELETE_MUTATION);
  const archiveWbsAction = async (item, callback) => {
    // await deleteProject({ variables: { id: item.id } });
    // callback();
    console.log('archive project')
  }

  const updateServiceStatus = async (id, fields, callback, successMessage) => {
    const upsertion = await upsertService({ variables: { input: { id, ...fields } } })
    if (successMessage && upsertion?.data?.response) { message.success(successMessage) }
    if (callback) { callback() }
    return upsertion?.data?.response
  }

  return (
    <>
      <ListManager
        slug="service-manager"
        title={t('service.table.title')}
        query={SERVICES_LIST}
        actions={[
          <Link 
            to="/wbs-management/create"
            key="create"
            onClick={(e) => {
              e.preventDefault()
              openModal(ServiceView)
            }}s
          >
            <Button type="primary" icon={<PlusOutlined />} htmlType="span">
              {t('service.table.actions.createService')}
            </Button>
          </Link>,
        ]}
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('wbs.table.sorting.idDesc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('wbs.table.sorting.idAsc'),
              icon: <SortAscendingOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('wbs.table.filters.all'),
              slug: 'all',
              value: {},
            },
          ],
          filters: [],
        }}
        table={{
          columns: [
            // {
            //   title: t('wbs.table.id'),
            //   key: 'id',
            //   options: { na: true },
            //   render: ({ value, options, item }) => (
            //     <Link to={`/wbs-management/${item.id}`}>{item.id}</Link>
            //   ),
            // },
            {
              title: t('service.table.sku'),
              key: 'sku',
              options: { na: true },
              render: ({ options, item }) => (
                <div style={{ display: 'flex' }}>
                  <Link to={`/contract-services/${item?.sku ? item.sku : item.id}`}>
                    {item?.sku ? item.sku.slice(0,8) : item.id}
                  </Link>

                  {/* Open in modal */}
                  <Button 
                    type="text" 
                    size="small" 
                    onClick={() => openModal(ServiceView, { sku: item.sku })}
                  >
                    <EyeOutline />
                  </Button>
                </div>
              ),
            },
            {
              title: t('service.table.serviceProvider'),
              key: 'serviceProvider',
              width: 200,
              render: ({ item }) => {
                return <Link to={`/profiles-management/${item?.serviceProvider?.id}`}><Tag>{item?.serviceProvider?.companyName || `#${item?.serviceProvider?.id}`}</Tag></Link>
              },
            },
            {
              title: t('service.table.reference'),
              key: 'reference',
              options: { na: true },
              render: 'string',
            },
            {
              title: t('service.table.paymentPeriod'),
              key: 'paymentInterval',
              options: { na: true },
              render: ({ item }) => {
                return item?.paymentInterval ? ( item?.paymentInterval != 0 ?  (item?.paymentInterval != 1 ? t('service.form.paymentIntervals.quarterly') : t('service.form.paymentIntervals.monthly')) : t('service.form.paymentIntervals.weekly')) : null
              },
            },
            
            {
              title: t('service.table.nextPaymentMonth'),
              key: 'nextPaymentMonth',
              render: ({ item }) => { 
                
                const createdAt = item?.nextPaymentMonth
                  ? DateTime.fromISO(item?.nextPaymentMonth)
                  : null
                
                return createdAt ? createdAt?.toFormat('yyyy LLL') : ''
              },
            },
            {
              title: t('service.table.paymentDeadline'),
              key: 'lastPaymentDate',
              render: ({ item }) => {
                const lastPaymentDate = item?.lastPaymentDate
                  ? DateTime.fromISO(item?.lastPaymentDate)
                  : null
                const now = DateTime.now()
                const diff =
                  now && lastPaymentDate
                    ? lastPaymentDate.diff(now, ['months', 'days'])
                    : null

                return `${lastPaymentDate ? lastPaymentDate.toFormat('yyyy-LL-dd') : ''}${
                  diff
                    ? ` (${[
                      diff.months ? `${Math.ceil(diff.months)}mo.` : '',
                      diff.days ? `${Math.ceil(diff.days)}d.` : '',
                    ]
                      .filter((item) => !!item)
                      .join(' ')})`
                    : ''
                }`
              },
            },
            {
              title: t('service.table.status'),
              key: 'status',
              width: 80,
              render: ({ value, item, context }) => {
                const handler = () => updateServiceStatus(item.id, { status: !value }, context.dataRefetch, `Great! Service has been successfully ${!value ? 'Activated' : 'Deactivated'}`)
                return (
                  <span role="button" tabIndex="0" aria-label={t('service.table.status.toggle')} onClick={handler} onKeyPress={handler} className="cursor-pointer" style={{ display: 'block', minWidth: 102 }}>
                    {value
                      ? (<Tooltip title={t('service.tableapartment-status.deactivate')}><Tag color="green">{t('service.table.isActive.$true$')}</Tag></Tooltip>)
                      : (<Tooltip title={t('service.table.apartment-status.activate')}><Tag color="red">{t('service.table.isActive.$false$')}</Tag></Tooltip>)}
                  </span>
                )
              },
            },
            {
              key: 'actions',
              title: t('service.table.actions.title'),
              width: 120,
              render: ({ item, context }) => (
                <>
                  {/** Clone */}
                  {/* <Tooltip
                    placement="bottom"
                    title={t('wbs.table.actions.duplicate')}
                  >
                    <Popconfirm
                      title={t('wbs.table.actions.duplicateConfirm')}
                      onConfirm={() => cloneProjectAction(item)}
                    >
                      <Button
                        shape="circle"
                        type="link"
                        icon={<CopyOutlined />}
                      />
                    </Popconfirm>
                  </Tooltip> */}
                  {/** Remove */}
                  <Tooltip
                    placement="bottom"
                    title={t('wbs.table.actions.archive')}
                  >
                    <Popconfirm
                      title={t('wbs.table.actions.archiveConfirm')}
                      onConfirm={() =>
                        archiveWbsAction(item, context.dataRefetch)
                      }
                    >
                      <Button
                        shape="circle"
                        type="link"
                        icon={<CloudServerOutlined />}
                      />
                    </Popconfirm>
                  </Tooltip>
                </>
              ),
            },
          ],
        }}
        {...props}
      />
    </>
  )
}

export { ServiceForm } from './ServiceForm'
export { ServiceView } from './ServiceView'
export { ServicesManager }
