import { Descriptions } from 'antd'
import * as DefaultTypes from 'components/ListManager/DefaultTypes'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const LogDisplay = ({ log }) => {
  const { t } = useTranslation()
  return (
    <div>
      <Descriptions title="Context" key={log.id} column="2">
        <Descriptions.Item
          key={log.id}
          labelStyle={{ fontWeight: 500 }}
          contentStyle={{ marginRight: 25 }}
        >
          {JSON.stringify(log.context)}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}
