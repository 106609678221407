import { gql } from 'apollo-boost'

export const attachmentFragment = gql`
fragment attachmentFragment on Attachment {
    id
    relationType
    relationId
    context
    ownerId
    createdAt
}
`
