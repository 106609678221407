import { USERS_LIST } from '_graphql'
import { USER_ROLES } from '_graphql/_enums'
import { SearchOutlined } from '@ant-design/icons'
import { Avatar,Checkbox, Input, List } from 'antd'
import UserAvatar from 'components/User/Widgets/Avatar'
import { client } from 'GQLClient'
import i18next from 'i18next'
import { isEmpty } from 'lodash'
import keyBy from 'lodash/keyBy'
import without from 'lodash/without'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { DropdownFilter } from './Layout'

/** Динамичный выбор пользователя */
export const userDynamic = {
  keyField: 'id',
  sorting: 'id|asc',
  query: USERS_LIST,

  /** Превью выбранных значений */
  valuesPreview: ({ value: valueRaw, filterState }) => {
    const collection = filterState?.collection ?? {}
    const value = (valueRaw ?? []).filter((id) => !!collection[id])

    if (value.length === 0) {
      return {
        isDisabled: true,
        render: i18next.t('list-manager.filters.all'),
      }
    }

    return {
      isDisabled: false,
      showTitle: true,
      render: (
        <Avatar.Group maxCount={3} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }} size="small" style={{ verticalAlign: 'middle' }}>
          {value.map((id) => {
            const user = collection[id]
            const role = USER_ROLES[user.role]
            return (
              <UserAvatar
                key={user.id}
                className="widget-user__avatar"
                user={user}
              />
            )
          })}
        </Avatar.Group>
      ),
    }
  },

  itemRender: ({ item }) => {
    return (
      <List.Item.Meta
        className="widget-user-select"
        avatar={(
          <UserAvatar 
            className="widget-user__avatar"
            user={item}
          />
        )}
        title={`${item.firstName} ${item.lastName}`.trim() || `#${item.id}`}
        description={item.email}
      />

    )
  },
}

export const presets = {
  users: userDynamic,
}

/** Детектим готовые пресеты */
const parseOptions = (options = {}) => {
  // Preset
  if (presets[options.preset]) {
    return { ...options, ...presets[options.preset] }
  }

  // Empty preset
  if (isEmpty(options)) {
    return {
      keyField: 'id',
      itemRender: ({ item }) => `#${item.id}`,
    }
  }

  return options
}

/** Динамичный выбор */
export default ({ title, icon, options }) => {
  const { keyField, itemRender, query, sorting, filter, valuesPreview } = parseOptions(options)

  return {
    /** Превью выбранных значений */
    valuesPreview: ({ value: valueRaw, filterState }) => {
      const collection = filterState?.collection ?? {}
      const value = (valueRaw ?? []).filter((id) => !!collection[id])

      if (value.length === 0) {
        return {
          isDisabled: true,
          render: i18next.t('list-manager.filters.all'),
        }
      }

      return {
        isDisabled: false,
        showTitle: true,
        render: (
          <Avatar.Group maxCount={3} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }} size="small" style={{ verticalAlign: 'middle' }}>
            {value.map((id) => {
              const user = collection[id]
              const role = USER_ROLES[user.role]
              return (
                <UserAvatar
                  key={user.id}
                  className="widget-user__avatar"
                  user={user}
                />
              )
            })}
          </Avatar.Group>
        ),
      }
    },

    /** Рендер фильтра */
    filterRender({ value, setValue, filterState = {}, setFilterState }) {
      const selectedIds = value ?? []
      const search = filterState.search ?? ''
      const { hasMore, data: items } = filterState.searchResults && filterState.searchResults.has(search) ? filterState.searchResults.get(search) : { hasMore: true, data: [] }
      const collection = filterState?.collection ?? {}
      const preview = valuesPreview ? valuesPreview({ value, filterState }) : this.valuesPreview({ value, filterState })

      return (
        <DropdownFilter title={title} icon={icon} preview={preview}>
          <div style={{ padding: '5px 5px 0 5px' }}>
            <Input
              onChange={(e) => { setFilterState({ ...filterState, search: e.target.value }) }}
              value={search}
              placeholder={i18next.t('list-manager.filters.search-placeholder')}
              prefix={<SearchOutlined className="list-manager-search__icon" />}
              role="search"
              allowClear
            />
            <div style={{ height: 250, overflow: 'auto', marginTop: 3, marginBottom: -4, display: 'flex', flexDirection: 'column' }}>
              {/** Selected */}
              {selectedIds.map((id) => (collection[id])).filter((item) => !!item).map((item) => (
                <List.Item
                  key={item[keyField]}
                  style={{ cursor: 'pointer', padding: '2px 0', borderBottom: '1px solid #f0f0f0', marginRight: 0 }}
                  onClick={() => setValue(without(selectedIds, item[keyField]))}
                  actions={[<Checkbox key={9999} checked />]}
                >
                  {itemRender({ item })}
                </List.Item>
              ))}
              {/** Not selected */}
              <InfiniteScroll hasMore={hasMore} loadMore={(page) => this.loadMore({ page, search, filterState, setFilterState })} useWindow={false} key={search} style={{ display: 'flex', flexDirection: 'column' }}>
                {items.filter((item) => !selectedIds.includes(item[keyField])).map((item) => (
                  <List.Item
                    key={item[keyField]}
                    style={{ cursor: 'pointer', padding: '2px 0', borderBottom: '1px solid #f0f0f0', marginRight: 0 }}
                    onClick={() => setValue([...selectedIds, item[keyField]])}
                    actions={[<Checkbox key={9998} checked={false} />]}
                  >
                    {itemRender({ item })}
                  </List.Item>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </DropdownFilter>
      )
    },

    /** Подгружаем больше данных */
    loadMore: async ({ page, search, filterState, setFilterState }) => {
      const { data: { response: { list } } } = await client.query({
        query,
        variables: {
          page,
          pageSize: 10,
          sorting,
          search,
          filter: JSON.stringify(filter || {}),
        },
      })

      const updatedFilterState = { ...filterState }

      // Первая загрузка
      if (isEmpty(updatedFilterState)) {
        // eslint-disable-next-line no-param-reassign
        updatedFilterState.collection = {}
        // eslint-disable-next-line no-param-reassign
        updatedFilterState.searchResults = new Map()
      }

      // Первый результат поиска
      if (!updatedFilterState.searchResults.has(search)) {
        updatedFilterState.searchResults.set(search, {
          hasMore: true,
          data: [],
        })
      }

      const searchResult = updatedFilterState.searchResults.get(search)

      // Empty list
      if (list.length < 10) {
        searchResult.hasMore = false
      }

      // Update state
      Object.assign(updatedFilterState.collection, keyBy(list, keyField))
      searchResult.data.push(...list)
      setFilterState(updatedFilterState)
    },
  }
}
