import ContentCopy from '@2fd/ant-design-icons/lib/ContentCopy'
import PhoneInTalk from '@2fd/ant-design-icons/lib/PhoneInTalk'
import { Button, Dropdown, Menu,message } from 'antd'
import parsePhoneNumber from 'libphonenumber-js'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCopyToClipboard } from 'react-use'

function Phone({ phone }) {
  const [, copyToClipboard] = useCopyToClipboard()
  const { t } = useTranslation()
  const formatedPhone = useMemo(() => parsePhoneNumber(phone ?? '', { defaultCountry: 'DE' }), [phone])

  const copyPhone = useCallback(() => {
    copyToClipboard(formatedPhone.formatInternational())
    message.success(t('common.clipboard.done'))
  }, [phone])

  if (!phone || !formatedPhone) {
    return '-'
  }

  return (
    <Dropdown overlay={(
      <Menu>
        {/* Send phone */}
        <Menu.Item icon={<PhoneInTalk />}>
          <a href={formatedPhone.getURI()}>
            {t('widgets.phone.phoneCall')}
          </a>
        </Menu.Item>
        {/* Copy phone */}
        <Menu.Item icon={<ContentCopy />} onClick={copyPhone}>
          {t('widgets.phone.copy')}
        </Menu.Item>
      </Menu>
    )} 
    placement="bottomLeft" 
    arrow
    >
      <Button type="link" href={formatedPhone.getURI()}>{formatedPhone.formatInternational()}</Button>
    </Dropdown>
  )
}

export default Phone
