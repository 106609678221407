import { colors } from '_colors'
import { BUILDING_UPSERT_MUTATION } from '_graphql'
import { PushpinTwoTone } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { AutoComplete, Button } from 'antd'
import GoogleMapReact from 'google-map-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { geocodeByAddress,getLatLng } from 'react-places-autocomplete'
import { useGlobalState } from 'state'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'

const BuildingAddressFormEdit = (props) => {
  const { t } = useTranslation()
  const [placesOptions, setPlacesOptions] = useState([])
  const [addressQuery, setAddressQuery] = useState('')
  const [placeLatLng, setPlaceLatLng] = useState(null)
  const [addressPayload, setAddressPayload] = useState(null)
  const [, dispatch] = useGlobalState()

  const [upsertBuildingMutation] = useMutation(BUILDING_UPSERT_MUTATION)

  const [map, setMap] = useState(null)
  const [, setMaps] = useState(null)

  const editBuilding = async (payload) => {
    const building = await upsertBuildingMutation({ variables: { input: { id: props.buildingId, addressDetails: payload } } })

    if (building.data?.response?.id) {
      dispatch({ type: 'SET_CURRENT_BUILDING', payload: building.data.response })
      dispatch({
        type: 'SET_RIGHT_BAR_COMPONENT',
        payload: null,
      })
      dispatch({ type: 'COLLAPSE_RIGHT_BAR' })
    }
    return null
  }

  useDebouncedEffect(
    () => {
      if (addressQuery.length >= 3) {
        geocodeByAddress(addressQuery)
          .then((results) => {
            setPlacesOptions(results.map((d) => ({ value: d.formatted_address, ...d })))
            return getLatLng(results[0])
          })
          .catch(() => {
            setPlacesOptions([])
          })
      } else {
        setPlacesOptions([])
      }
    },
    300,
    [addressQuery],
  )

  const onChange = (value) => {
    setAddressQuery(value)
  }

  const onSelect = (value, payload) => {
    setAddressPayload(payload)

    map.setCenter({ lat: payload.geometry.location.lat(), lng: payload.geometry.location.lng() })
    map.setZoom(18)

    setPlaceLatLng({
      lat: payload.geometry.location.lat(),
      lng: payload.geometry.location.lng(),
    })
  }

  const handleApiLoaded = ({ map: MAP, maps: MAPS }) => {
    // use map and maps objects
    setMap(MAP)
    setMaps(MAPS)
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '40px',
        flexDirection: 'column',
      }}
    >
      <h1>{t('buildings.actions.create')}</h1>
      <AutoComplete
        onChange={onChange}
        onSelect={onSelect}
        options={placesOptions}
        style={{ fontSize: '18px', fontWeight: '500', width: '100%', marginBottom: '20px' }}
      />

      <GoogleMapReact
        defaultCenter={{
          lat: 51.45400691005982,
          lng: 10.239257812500002,
        }}
        defaultZoom={6}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleApiLoaded}
      >
        {placeLatLng && (
          <PushpinTwoTone
            twoToneColor={colors.main()}
            style={{ fontSize: '40px', transform: 'translate(0%, -100%)' }}
            {...placeLatLng}
          />
        )}
      </GoogleMapReact>

      <Button
        onClick={() => editBuilding(addressPayload)}
        block
        size="large"
        disabled={!placeLatLng}
        type="primary"
        style={{ marginTop: '20px' }}
      >
        {t('buildings.actions.submit')}
      </Button>
    </div>
  )
}

export { BuildingAddressFormEdit }
