import { gql } from 'apollo-boost'

import { projectFragment, rentalContractFragment,transactionFragment, userFragment } from '../_fragments'

export const TRANSACTIONS_LIST = gql`
  query listTransactions(
    $page: Int!
    $pageSize: Int!
    $sorting: String
    $search: String
    $filter: JSON
  ) {
    response: listTransactions(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      search: $search
      filter: $filter
    ) {
      list {
        ...transactionFragment
        creator {
          ...userFragment
        }
        contract {
          ...rentalContractFragment
        }
        moveInUser {
          ...userFragment
        }
        moveOutUser {
          ...userFragment
        }
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${transactionFragment},
  ${userFragment},
  ${rentalContractFragment},
`

export const TRANSACTION_SKU_QUERY = gql`
  query fetchTransactionBySku($sku: String!) {
    response: fetchTransactionBySku(sku: $sku) {
      ...transactionFragment
      creator {
          ...userFragment
        }
        contract {
          ...rentalContractFragment
          project {
            ...projectFragment
          }
        }
        moveInUser {
          ...userFragment
          project {
            ...projectFragment
          }
        }
        moveOutUser {
          ...userFragment
          project {
            ...projectFragment
          }
        }
    }
  }
  ${transactionFragment}
  ${userFragment},
  ${rentalContractFragment},
  ${projectFragment}
`
