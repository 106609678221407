import { Checkbox, Input, Radio } from 'antd'
import keyBy from 'lodash/keyBy'
import without from 'lodash/without'
import React from 'react'

import dynamicFilter from './DynamicFilters'
import { DropdownFilter } from './Layout'
import { getOptions } from './OptionsPreset'

/** Булева */
export const boolean = ({ title, icon, options }) => {
  const values = getOptions({ options })
  const valueCollection = keyBy(values, 'key')

  const booleanToRaw = (value) => (value === true ? '$true$' : value === false ? '$false$' : '$none$')
  const rawToBoolean = (value) => (value === '$true$' ? true : value === '$false$' ? false : undefined)

  return {
    /** Превью выбранных значений */
    valuesPreview: ({ value }) => {
      const valueRaw = booleanToRaw(value)

      return {
        isDisabled: valueRaw === '$none$',
        render: (
          <>
            {valueRaw !== '$none$' ? valueCollection[valueRaw].icon : null}
            {valueCollection[valueRaw].title}
          </>
        ),
      }
    },

    /** Рендер фильтра */
    filterRender({ value, setValue }) {
      const valueRaw = booleanToRaw(value)

      return (
        <DropdownFilter title={title} icon={valueRaw === '$none$' ? valueCollection.$none$.icon : icon} preview={this.valuesPreview({ value })}>
          <>
            <Radio.Group onChange={(e) => { setValue(e.target.value) }} style={{ margin: '5px 10px' }}>
              {values.map((item) => {
                const itemValue = rawToBoolean(item.key)
                return (
                  <Radio.Button key={item.key} value={itemValue} checked={valueRaw === item.key}>
                    {item.icon}
                    {item.title}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </>
        </DropdownFilter>
      )
    },
  }
}

/** Выбор тегов */
export const tags = ({ title, icon, options }) => {
  const values = getOptions({ options })
  const valueCollection = keyBy(values, 'key')

  return {
    /** Превью выбранных значений */
    valuesPreview: ({ value: valueRaw }) => {
      const value = valueRaw ?? []

      if (value.length === 0) {
        return {
          isDisabled: true,
          render: 'All',
        }
      }

      return {
        isDisabled: false,
        render: value.map((key) => valueCollection[key].title).join(', '),
      }
    },

    /** Рендер фильтра */
    filterRender({ value: valueRaw, setValue }) {
      const value = valueRaw ?? []

      return (
        <DropdownFilter title={title} icon={icon} preview={this.valuesPreview({ value })}>
          <>
            {values.map((item, index) => {
              const isChecked = value.includes(item.key)

              const toggle = () => {
                if (!isChecked) {
                  setValue([...value, item.key])
                } else {
                  setValue(without(value, item.key))
                }
              }

              return (
                <div key={index} className="list-manager-checkbox" style={{ '--color': item.color }}>
                  <Checkbox key={item.key} checked={isChecked} onChange={toggle} className="ant-dropdown-menu-item">
                    {item.title}
                  </Checkbox>
                </div>

              )
            })}
          </>
        </DropdownFilter>
      )
    },
  }
}

/** Динамичный выбор */
export const dynamic = dynamicFilter
