import { colors } from '_colors'
import { ALL_BUILDINGS_QUERY } from '_graphql'
import { useQuery } from '@apollo/react-hooks'
import ExploreIcon from '@material-ui/icons/Explore'
import { Spin } from 'antd'
import GoogleMapReact from 'google-map-react'
import React, { useEffect, useState } from 'react'
import { useGlobalState } from 'state'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'

import { MapIcon, StyledPin } from '../styled'

const BuildingsMap = ({ buildings }) => {
  const [state] = useGlobalState()
  const [buildingsWithParsedAddresses, setBuildingsWithParsedAddresses] = useState([])
  const { data: { response: buildingsData } = {}, loading: buildingsLoading } = useQuery(ALL_BUILDINGS_QUERY)
  const [map, setMap] = useState(null)
  const [bounds, setBounds] = useState(null)
  const [maps, setMaps] = useState(null)
  const [initialized, setInitialized] = useState(false)

  /** Отложенная иницилизация */
  useEffect(() => {
    const timerId = setTimeout(() => (setInitialized(true)), 1000)
    return () => clearTimeout(timerId)
  })

  const createMarkers = () => {
    const buildingsTemp = buildings || buildingsData
    if (buildingsTemp?.length) {
      return buildingsTemp.map((building) => {
        const { addressDetails } = building

        // due to the fact we store now info as json type in PG DB
        const parsedAddress = addressDetails
        return { ...building, parsedAddress }
      })
    }

    return []
  }

  useEffect(() => {
    const addresses = createMarkers()
    setBuildingsWithParsedAddresses(addresses)
  }, [buildingsData, buildings])

  /* set bounds */
  useEffect(() => {
    if (map && maps && buildingsWithParsedAddresses.length) {
      const boundsTemp = new maps.LatLngBounds()
      buildingsWithParsedAddresses.forEach((building) => {
        boundsTemp.extend(new maps.LatLng(building?.parsedAddress?.geometry?.location.lat, building?.parsedAddress?.geometry?.location.lng))
      })
      setBounds(boundsTemp)
      map.fitBounds(boundsTemp)
    }
  }, [map, maps, buildingsWithParsedAddresses])

  // function smoothZoom(_map, max, cnt) {
  //   if (maps?.event) {
  //     if (cnt <= max) {
  //       const z = maps.event.addListener(_map, 'zoom_changed', () => {
  //         maps.event.removeListener(z);
  //         if (cnt <= max) {
  //           smoothZoom(_map, max, cnt + 1);
  //         } else if (cnt >= max) {
  //           smoothZoom(_map, max, cnt - 1);
  //         }
  //       });
  //       setTimeout(() => { _map.setZoom(cnt); }, 80);
  //     }
  //   }
  // }

  /* handle data hover */
  useDebouncedEffect(() => {
    if (map && state.hoveredBuilding <= 0) {
      map.fitBounds(bounds)
      return
    }

    if (map && state.hoveredBuilding) {
      const targetBuilding = buildingsWithParsedAddresses.find((b) => b.id === state.hoveredBuilding)
      if (targetBuilding) {
        // smoothZoom(map, 17, map.getZoom());
        map.setZoom(17)
        map.panTo({ ...targetBuilding.parsedAddress.geometry.location })
      }
    }
  }, state.hoveredBuilding <= 0 ? 0 : 3000, [state.hoveredBuilding])

  const handleApiLoaded = ({ map: MAP, maps: MAPS }) => {
    if (MAP && MAPS) {
      setMap(MAP)
      setMaps(MAPS)
    }
  }

  return (
  // <Spin tip="Fetching buildings..." size="large" spinning={!buildingsData?.length || buildingsLoading}>
    <div style={{ background: '#e5e3df', width: '100%', height: '100%' }} className="map-widget">
      {initialized && !buildingsLoading ? (
        <GoogleMapReact
          defaultCenter={{
            lat: 51.45400691005982,
            lng: 10.239257812500002,
          }}
          defaultZoom={6}
          // yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
        >
          {buildingsWithParsedAddresses.map((building) => (
            <StyledPin
              key={building.id}
              hovered={(building.id === state.hoveredBuilding).toString()}
              hashovered={(state.hoveredBuilding > 0).toString()}
              twoToneColor={colors.main()}
              {...building?.parsedAddress?.geometry?.location}
            />
          ))}

        </GoogleMapReact>
      ) : <MapIcon><ExploreIcon /></MapIcon> }
    </div>
  // </Spin>
  )
}

export { BuildingsMap }
