import { isAdmin, isTenant, roles, USER_ROLES } from '_graphql/_enums'
import { PROJECT_LIST } from '_graphql/project'
import { USERS_LIST } from '_graphql/user'
import { ALL_USERS_QUERY, ME_QUERY } from '_graphql/user/queries'
import { GET_WBS,GET_WBS_BY_SKU,SUBSCRIBE_WBS,WBS_LIST, WBS_UPSERT_MUTATION } from '_graphql/wbs'
import { BankOutlined, DownOutlined, InfoCircleOutlined, ReloadOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons'
import { ConsoleSqlOutlined, EditOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { Attachment } from '@material-ui/icons'
import { Link, navigate, useParams } from '@reach/router'
import {
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Typography} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useModals } from 'common/ModalsProvider'
import { NotFound } from 'components'
import { ChatBox } from 'components/Chat'
import { ContactBox } from 'components/ContactBox'
import { ContractSelect } from 'components/Form/ContractSelect'
import { DatePicker } from 'components/Form/DatePicker'
import { ProjectSelect } from 'components/Form/ProjectSelect'
import { UserSelect } from 'components/Form/UserSelect'
import {
  LayoutCard,
} from 'components/Layout'
import LayoutTabs from 'components/LayoutTabs'
import { user } from 'components/ListManager/DefaultTypes'
import { PageHeader } from 'components/PageHeader'
import { filter } from 'lodash'
import { DateTime } from 'luxon'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InvoicesManager } from 'screens/InvoiceManager'
import { ProjectPage } from 'screens/ProjectsManager'
import { ProjectDetails } from 'screens/ProjectsManager/ProjectDetails'
import { RentalContracts } from 'screens/RentalContracts'
import { TransactionsManager } from 'screens/TransactionsManager'
import { UsersManager } from 'screens/UsersManager'
import { useGlobalState } from 'state'
import stc from 'string-to-color'
import styles from 'styles/side-layout.less'
import { db } from 'utils/dexie'

import { AttachUser } from './AttachUser'

const { TabPane } = Tabs

const WbsView = (options) => {

  let sku = null
  if (options?.sku) {
    sku = options.sku
    if(!sku) {
      sku = useParams().sku
    }
  } 

  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagmentUser = isAdmin(state.currentUser?.role)
  const [, rerender] = useState()
  const [isForm, setIsForm] = useState(!sku)
  const [form] = Form.useForm()
  const role = state.currentUser?.role
  const [wbs, setWbs] = useState()
  const { openModal } = useModals()
    
  const [formOpened, setformOpened] = useState(true)
  const [detailsVisible, setdDetailsVisible] = useState(true)
  const [customersList, setCustomersList] = useState()
  const [pmoList, setPmoList] = useState()
  const [usersRefetch, setUsersRefetch] = useState()
 
  const [ fetch, { data: wbsData, loading: wbsLoading}] = useLazyQuery(GET_WBS_BY_SKU, { variables: { sku: sku }, fetchPolicy: 'no-cache' })
  
  

  const query = useMemo(
    () => {
      new URLSearchParams(location.search)
    },
    [location.search]
  )

  useEffect(() => {
    if(sku) {
      fetch()
    }
  }, [sku])
  
  useEffect(() => {
    
    if (wbsData && !wbsLoading) {
      setformOpened(false)
      setWbs(wbsData.response)
      form.setFieldsValue({
        ...wbsData.response,
        expireAt: wbsData.response.expireAt ? moment(wbsData.response.expireAt) : null,
      })
    } 
  }, [wbsData])

  const { data: projectsData, loading: projectsLoading } =
    useQuery(PROJECT_LIST, {
      variables: {
        page: 1,
        pageSize: 1000,
      },
    })

  const { data: customerData, loading: customerLoading } = useQuery(
    USERS_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
        filter: JSON.stringify({
          role: [USER_ROLES['tentant-admin'].key],
        }),
      },
    }
  )

  const { data: pmoData, loading: pmoLoading } = useQuery(
    USERS_LIST,
    {
      variables: {
        page: 1,
        pageSize: 1000,
        filter: JSON.stringify({
          role: [USER_ROLES['tentant-project-manager'].key],
        }),
      },
    }
  )

  useEffect(() => {
    if (wbs === null && sku) {
      message.error('Wbs not found')
      navigate('/wbs-management')
    }
  }, [wbs, sku])

  // Form is opened
  useEffect(() => {
    if (wbs) {
      form.setFieldsValue(wbs)
    }
  }, [isForm])


  const projectId = form.getFieldValue('projectId') ?? null

  useEffect(() => {
    if (projectId && projectsData && customerData && pmoData) {

      const filteredProject = projectsData?.response.list.filter(p => p.id === projectId)

      form.setFieldsValue({
        customerId: parseInt(filteredProject[0]?.ownerId),
        pmoId: parseInt(filteredProject[0]?.pmoId),
      })
    }
  }, [projectId, projectsData, customerData, pmoData])

  // Submit
  const [upsertWbs] = useMutation(WBS_UPSERT_MUTATION)
  const onSubmit = async (values) => {
    const {code, memo, expireAt, projectId, customerId, pmoId} = values
    
    try {
      const input = {
        ...values,
        id: wbs ? wbs.id : undefined
      }

      const data = await upsertWbs({ variables: {
        input
      }})
     
      if (data.data.response) {
        data.data.response.updatedAt ? message.success('WBS Key updated successfully!') : message.success('WBS Key created successfully!')
        setIsForm(false)
        navigate(`/wbs-management/${data.data.response.sku}`)
      } else {
        message.error('There was an error during WBS update.')
      }
  
    } catch (e) {
      message.error('The form is filled out incorrectly')
      console.log('error', e)
      // navigate('/wbs-management')
    }
  }

  // if (!sku) {
  //   return <NotFound />
  // }

  // if (wbsLoading) return 'Loading'

  return (
    <>
      <Form 
        form={form}
        onFieldsChange={rerender} 
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className={styles.container} id="wbsPage">
          {/* Form */}
 

          <div className={styles.content}>

            <PageHeader
              title={t('wbs.form.current')}
              id={wbs?.sku}
              extra={ isAdmin(role) || isTenant(role) ? (
                <>
                  <Tooltip title="Show Details">
                    <Button
                      onClick={() => {
                        setdDetailsVisible(!detailsVisible)
                      }}
                    >
                      <InfoCircleOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Edit Project">
                    <Button
                      onClick={() => {
                        setdDetailsVisible(true)
                        setformOpened(!formOpened)
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                </>
              ) : null}
            />
        
            <Row gutter={[20,20]}>
              <Col xs={24}>
              
                { detailsVisible ? (
                  <LayoutCard>
                
                    {/* Edit */}
                    {formOpened ? (
                      <>
                        <Row gutter={[20, 20]}>
                          <Col xs={8}>
                            <Form.Item
                              name="code"
                              label={t('wbs.form.code')}
                              required
                              rules={[{ required: true }]}
                            >
                              <Input placeholder={t('wbs.form.code')} />
                            </Form.Item>
                          </Col>
                          <Col xs={8}>
                            <Form.Item
                              name="memo"
                              label={t('wbs.form.memo')}
                              required
                              rules={[{ required: true }]}
                            >
                              <Input placeholder={t('wbs.form.memoHolder')} />
                            </Form.Item>
                          </Col>
                          <Col xs={8}>
                            <Form.Item
                              name="expireAt"
                              label={t('wbs.form.expireAt')}
                              required
                              rules={[{ required: true }]}
                            >
                              <DatePicker />
                            </Form.Item>
                          </Col>
                
                          <Col xs={8}>
                            <Form.Item name="projectId" label="Select Project">
                              <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={t('globalForms.select')}
                                disabled={projectsLoading}
                              >
                                {projectsData?.response?.list?.map?.((project) => (
                                  <Select.Option value={project.id} key={project.id}>
                                    {project.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xs={8}>
                            <Form.Item name="customerId" label="Select Customer">
                              <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={t('globalForms.select')}
                                disabled={projectsLoading}
                              >
                                {customerData?.response?.list?.map?.((customer) => ( 
                                  <Select.Option value={customer.id} key={customer.id}>
                                    {customer.lastName} {customer.firstName}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xs={8}>
                            <Form.Item name="pmoId" label="Select PMO">
                              <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={t('globalForms.select')}
                                disabled={projectsLoading}
                              >
                                {pmoData?.response?.list?.map?.((pmo) => (
                                  <Select.Option value={pmo.id} key={pmo.id}>
                                    {pmo.lastName} {pmo.firstName}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                     
                        </Row>

                        <Divider />

                        {/* Save contract */}
                        <Button block size="large" type="primary" htmlType="submit">
                          {wbs?.id ? t('wbs.form.update') : t('wbs.form.create')}
                        </Button>
            
                      </>
                    ) : null}

                    {/* View */}
                    {!formOpened ? (
                      <>
                        {/* Contract range */}
                        <Row gutter={[20,20]}>
                          <Col xs={24}>
                            <Descriptions title="" size="small" layout="horizontal" column={2} bordered>
                              <Descriptions.Item label={t('wbs.page.code')}>{ wbs?.code ? wbs?.code : '-'}</Descriptions.Item>
                              <Descriptions.Item label={t('wbs.page.memo')}>{ wbs?.memo ? wbs?.memo : '-'}</Descriptions.Item>
                              <Descriptions.Item label={t('wbs.page.project')}> <a
                                onClick={() => openModal(ProjectPage, { sku: wbs?.project?.sku })}
                              >{wbs?.project?.sku.slice(0,8)}
                              </a></Descriptions.Item>
                              <Descriptions.Item label={t('wbs.page.range')}>{ wbs?.expireAt ? moment(wbs?.expireAt).format('YYYY-MM-DD') : '-'}</Descriptions.Item>
                            </Descriptions>
                          </Col>
                        </Row>
                        <Divider />
                        <Row gutter={[20,20]}>
                          {/* Tenant | Project owner */}
                          <Col xs={12}>
                            {(isAdmin(role) || isTenant(role)) && wbs?.customer ? (
                              <ContactBox user={wbs?.customer} />
                            ) : null}
                          </Col>

                          {/* Tenant | Projects manager */}
                          <Col xs={12}>
                            {(isAdmin(role) || isTenant(role)) && wbs?.pmo  ? (
                              <ContactBox user={wbs?.pmo} />
                            ) : null}
                          </Col>
                        </Row>
                      </>
                    ) : null}
                
                  </LayoutCard>)
                  : null } 
              </Col>
            </Row>

            { wbs ?  <Row gutter={[20, 20]}>
              <Col xs={24}>
                <LayoutTabs type="card" defaultActiveKey="general">
                  <TabPane tab="General" key="general">
                    main page
                  </TabPane>
                  <TabPane tab="Employees" key="users">
                    {wbs ? (
                      <UsersManager
                        queryParams={{ filter: { wbsIds: [wbs?.id] } }}
                        // refetch={usersRefetch}
                        fullpage={false}
                        actions={[
                          <Button
                            key="attach"
                            type="primary"
                            icon={<Attachment />}
                            onClick={() => {
                              openModal(AttachUser, {
                                wbs: wbs ? wbs : null,
                                onClose() {
                                  setUsersRefetch(moment())
                                }
                              })
                            }}
                          >
                            {t('user.actions.attach')}
                          </Button>,
                          <Link
                            key="create"
                            to={`/users-management/create?projectId=${wbs?.id}&role=${roles.TENANT_EMPLOYEE}&backUrl=${encodeURIComponent(`/projects-management/${wbs.sku}#users`)}`}
                          >
                            <Button
                              type="primary"
                              icon={<UserAddOutlined />}
                              htmlType="span"
                            >
                              {t('user.actions.create-link')}
                            </Button>
                          </Link>,
                        ]}
                      />
                    ) : null}
                  </TabPane>
                 
                  <TabPane tab="Contracts" key="contracts">
                    {wbs ? (
                      <RentalContracts
                        queryParams={{ filter: { wbsId: wbs.id } }}
                        fullpage={false}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tab="Invoices" key="invoices">
                    {wbs ? (
                      <InvoicesManager
                        queryParams={{ filter: { projectIds: [wbs.projectId] } }}
                        fullpage={false}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tab="Transactions" key="transactions" >
                    {wbs ? (
                      <TransactionsManager
                        queryParams={{ filter: { projectIds: [wbs.projectId] } }}
                        fullpage={false}
                      />
                    ) : null}
                  </TabPane>
                  
                  <TabPane tab="Project Log" key="log" disabled={true}>
                 project log
                  </TabPane>
                </LayoutTabs>
              </Col>
            </Row> : null }
          </div>
        </div>
      </Form>
    </>
  )
}

export { WbsView }
