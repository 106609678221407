import { isAdmin, isLandlord, isTenant, ROLE_GROUPS,roles, USER_ROLES } from '_graphql/_enums'
import { PROJECT_DELETE_MUTATION, PROJECT_LIST,PROJECT_UPSERT_MUTATION } from '_graphql/project'
import Account from '@2fd/ant-design-icons/lib/Account'
import CheckboxMultipleBlankCircleOutlineIcon from '@2fd/ant-design-icons/lib/CheckboxMultipleBlankCircleOutline'
import City from '@2fd/ant-design-icons/lib/City'
import {
  CopyOutlined,
  CrownOutlined,
  DeleteOutlined,
  FolderViewOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Link,navigate } from '@reach/router'
import { Button, Popconfirm, Tag, Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import { ListManager } from 'components/ListManager'
import { string } from 'components/ListManager/DefaultTypes'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import { CountryRender } from 'utils/countries'
import { deepOmit } from 'utils/deepOmit'

import { ProjectForm } from './ProjectForm'
import { ProjectPage } from './ProjectPage'
import { projectsCitiesPreset } from './projectsCitiesPreset'

const ProjectsManager = () => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)
  const { openModal } = useModals()

  /** Архивация проекта */
  const [deleteProject] = useMutation(PROJECT_DELETE_MUTATION)
  const deleteProjectAction = async (item, callback) => {
    await deleteProject({ variables: { id: item.id } })
    // callback();
    console.log('Archive project')
  }



  return (
    <>
      <ListManager
        slug="projects-manager"
        title={t('projects.table.title')}
        query={PROJECT_LIST}
        actions={[...ROLE_GROUPS.B2B, roles.TENANT_ADMIN, roles.TENANT_MANAGER, roles.TENANT_PROJECT_MANAGER].includes(state.currentUser?.role) ? [
          <Link 
            to="/projects-management/create" 
            key="create"
            onClick={(e) => {
              e.preventDefault()
              openModal(ProjectForm)
            }}
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="span"
            >
              {t('projects.table.actions.createProject')}
            </Button>
          </Link>,
        ]: []}
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('projects.table.sorting.idDesc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('projects.table.sorting.idAsc'),
              icon: <SortAscendingOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('projects.table.filters.all'),
              slug: 'all',
              value: {},
            },
          ],
          filters: [
            {
              slug: 'manager',
              title: t('projects.table.manager'),
              icon: <Account />,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: {
                  role: [USER_ROLES['tentant-project-manager'].key, USER_ROLES['tentant-manager'].key]
                },
              },
            },
            {
              slug: 'pmo',
              title: t('projects.table.pmo'),
              icon: <Account />,
              middleware: isManagementUser,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: {
                  role: [USER_ROLES['tentant-project-manager'].key]
                },
              },
            },
            {
              slug: 'city',
              title: t('projects.table.city'),
              icon: <City />,
              middleware: isManagementUser,
              type: 'dynamic',
              options: projectsCitiesPreset,
            },

          ],
        }}
        table={{
          columns: [
            {
              title: t('projects.table.code'),
              key: 'sku',
              options: { na: true },
              render: ({ value, options, item }) => (
                <Link to={`/projects-management/${item.sku}`}>
                  {item.sku.slice(0, 8)}
                </Link>
              ),
            },
            {
              title: t('projects.table.projectName'),
              key: 'title',
              options: { na: true },
              render: 'string',
            },
            {
              title: t('projects.table.companyName'),
              key: 'companyName',
              width: 150,
              render: ({ item }) => {
                if (item?.owner?.profiles[0]) {
                  return (
                    <Tooltip title="Go to profile page">
                      <Link to={`/profiles-management/${item?.owner?.profiles[0]?.id}`}>
                        {item?.owner?.profiles[0]?.companyName ?? '-'}
                      </Link>
                    </Tooltip>
                  )
                } else {
                  return '-'
                }
                
            
              },
            },
            {
              title: t('projects.table.city'),
              key: 'city',
              options: { na: true },
              render: 'string',
            },
            {
              title: t('projects.table.manager'),
              key: 'manager',
              render: 'user',
              middleware: () => isAdmin(state.currentUser?.role) || isTenant(state.currentUser?.role),
            },
            {
              title: t('projects.table.pmo'),
              key: 'pmo',
              render: 'user',
              middleware: () =>
                !isLandlord(
                  state.currentUser?.role
                ),
            },
            {
              key: 'dates',
              title: t('projects.table.dates'),
              render: ({ item }) => {
                const startedAt = item?.startedAt
                  ? DateTime.fromISO(item?.startedAt)
                  : null
                const expireAt = item?.expireAt
                  ? DateTime.fromISO(item?.expireAt)
                  : null
                const diff =
                startedAt && expireAt
                  ? expireAt.diff(startedAt, ['months', 'days'])
                  : null

                return `${startedAt ? startedAt.toFormat('yyyy-LL-dd') : ''}${
                  startedAt && expireAt ? ' — ' : ''
                }${expireAt ? expireAt.toFormat('yyyy-LL-dd') : ''}${
                  diff
                    ? ` (${[
                      diff.months ? `${Math.ceil(diff.months)}mo.` : '',
                      diff.days ? `${Math.ceil(diff.days)}d.` : '',
                    ]
                      .filter((item) => !!item)
                      .join(' ')})`
                    : ''
                }`
              },
            },
            {
              key: 'actions',
              title: t('projects.table.actions.title'),
              width: 120,
              render: ({ item, context }) => (
                <>
                  {/** Clone */}
                  <Tooltip
                    placement="bottom"
                    title={t('projects.table.actions.archive')}
                  >
                    <Popconfirm
                      title={t('projects.table.actions.archiveConfirm')}
                      onConfirm={() =>
                        deleteProjectAction(item, context.dataRefetch)
                      }
                    >
                      <Button
                        shape="circle"
                        type="link"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Tooltip>
                </>
              ),
            },
          ],
        }}
      />
    </>
  )
}

export { ProjectPage } from './ProjectPage'
export { ProjectsManager }
