import { gql } from 'apollo-boost'

export const notificationFragment = gql`
  fragment notificationFragment on Notification {
    id
    context
    userId
    createdAt
    updatedAt
  }
`
