import { gql } from 'apollo-boost'

export const rentalRequestFragment = gql`
  fragment rentalRequestFragment on RentalRequest {
    id
    sku
    city
    budget
    details
    dateFrom
    dateTill
    status
    tenantId
    tenantEmployeeId
    tenantProfileId
    salesId
    projectId
    wbsId
    unlimited
    createdAt
    updatedAt
  }
`
