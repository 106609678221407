import { gql } from 'apollo-boost'

import { messageFragment } from '../_fragments'

export const MESSAGE_QUERY = gql`
  query fetchMessages($entityType: String!, $entityId: Int!) {
    response: fetchMessages(entityType: $entityType, entityId: $entityId) {
        ...messageFragment
    }
  }
  ${messageFragment}
`

export const MESSAGE_SUBSCRIPTION = gql`
  subscription messageAdded($entityType: String!, $entityId: Int!) {
    response: messageAdded(entityType: $entityType, entityId: $entityId) {
        ...messageFragment
    }
  }
  ${messageFragment}
`
