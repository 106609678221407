import { gql } from 'apollo-boost'

export const LOGIN = gql`
  mutation login(
    $email: String!
    $password: String!
    $code: String
  ) {
    login(
      email: $email
      password: $password
      code: $code
    ) {
      user {
        id
      }
      token
    }
  }
`
