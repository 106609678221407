import { gql } from 'apollo-boost'

import { buildingFragment, photoFragment } from '../_fragments'

export const CREATE_BUILDING_MUTATION = gql`
  mutation building($input: BuildingInput!) {
    response: building(input: $input) {
      ...buildingFragment
      photos {
        ...photoFragment
      }
    }
  }
  ${buildingFragment}
  ${photoFragment}
`

export const BUILDING_UPSERT_MUTATION = gql`
  mutation building($input: BuildingInput!) {
    response: building(input: $input) {
        ...buildingFragment
        photos {
          ...photoFragment
        }
    }
  }
  ${buildingFragment}
  ${photoFragment}
`

export const DELETE_BUILDING_MUTATION = gql`
  mutation deleteBuilding($id: Int!) {
    deleteBuilding(id: $id)
  }
`
