import { ClearOutlined, DownOutlined, SwapOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Menu,Row, Tabs } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import keyBy from 'lodash/keyBy'
import omitBy from 'lodash/omitBy'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FilterForm } from './FilterForm'
import { SearchForm } from './SearchForm'

/**
 * Кнопка сброса фильтров
 */
const FilterResetButton = ({ filter, currentFilterPreset, setCurrentFilterPreset, filterValues, setFilterValues, searchValue, setSearchValue, presetsCollection, sorting, sortingValue, setSortingValue }) => {
  const { t } = useTranslation()
  const isDefaultPreset = currentFilterPreset === filter.defaultPreset
  const isEmptyFilters = isEqual(presetsCollection[currentFilterPreset]?.value, filterValues) || isEmpty(filterValues)
  const isEmptySearchQuery = searchValue === ''
  const isSortingDefault = sortingValue === sorting.default

  const reset = () => {
    setCurrentFilterPreset(filter.defaultPreset)
    setFilterValues({})
    setSearchValue('')
    setSortingValue(sorting.default)
  }

  return (
    <Button disabled={isDefaultPreset && isEmptyFilters && isEmptySearchQuery && isSortingDefault} icon={<ClearOutlined />} onClick={reset}>
      {t('list-manager.actions.reset-filters')}
    </Button>
  )
}

/**
 * Выпадающее меню сортировки
 */
const SortingButton = ({ sorting, sortingValue, setSortingValue }) => {
  const variationCollection = keyBy(sorting.variations, 'slug')

  return (
    <Dropdown
      placement="bottomRight"
      overlay={(
        <Menu selectedKeys={[sortingValue]} onClick={({ key: slug }) => { setSortingValue(slug) }}>
          {sorting.variations.filter(({ middleware }) => middleware === undefined || middleware === true || (middleware !== false && middleware())).map((variation) => (
            <Menu.Item key={variation.slug} icon={variation.icon}>
              {variation.title}
            </Menu.Item>
          ))}
        </Menu>
      )}
    >
      <Button><SwapOutlined rotate={90} /> {variationCollection[sortingValue]?.title ?? 'Sort by'} <DownOutlined /></Button>
    </Dropdown>
  )
}

/**
 * Фильтрация
 */
export const Filter = ({ managerSlug, filter, searchValue, setSearchValue, filterValues, setFilterValues: setFilterValuesRaw, sorting, sortingValue, setSortingValue }) => {
  const [currentFilterPreset, setCurrentFilterPreset] = useState(filter.defaultPreset)
  const presetsCollection = keyBy(filter.presets, 'slug')

  // Отрпаливаем изменения фильтра и подбираем подходящий фильтр
  const setFilterValues = (value) => {
    const omitedValue = omitBy(value, (value) => isEmpty(value) && typeof value !== 'boolean')

    setFilterValuesRaw(omitedValue)
    // eslint-disable-next-line no-restricted-syntax
    for (const preset of filter.presets) {
      if (preset.value && isEqual(preset.value, omitedValue)) {
        setCurrentFilterPreset(preset.slug)
        break
      }

      setCurrentFilterPreset(filter.defaultPreset)
    }
  }

  /** Установка фильтра из URL */
  useEffect(() => {
    if (managerSlug) {
      let url = new URL(window.location)
      const uriFilters = []

      // Get filter
      for (const uriFilter of url.searchParams.getAll('list-manager')) {
        try {
          uriFilters.push(JSON.parse(uriFilter))
        } catch {
        //
        }
      }

      // Find filter 4 current list-manager
      const { __slug, __preset, ...uriFilter } = uriFilters.find(uriFilter => uriFilter.__slug === managerSlug) || {}

      // Preset
      if (__preset) {
        const value = filter.presets.find((preset) => preset.slug === __preset)?.value
        if (value) {
          console.log('value', value)
          setFilterValues(value)
        }
      }

      // Custom filter
      else if (__slug && uriFilter) {
        // Preset
        setFilterValues(uriFilter)
      }

      // Remove filter from url
      try {
        url.searchParams.delete('list-manager')
        if (window.location.toString() !== url.toString()) {
          history.replaceState({}, window.document.title, url.pathname + url.search)
        }
      } catch {
        // 
      }
    }
  }, [])

  return (
    <>
      {/* Строка поиска */}
      <Row wrap={false}>
        <Col flex="auto">
          <SearchForm {...{ filter, searchValue, setSearchValue }} />
        </Col>
        <Col flex="none" style={{ marginLeft: 15 }}>
          {FilterResetButton({ filter, currentFilterPreset, setCurrentFilterPreset, filterValues, setFilterValues, searchValue, setSearchValue, presetsCollection, sorting, sortingValue, setSortingValue })}
        </Col>
      </Row>

      {/** Фильтр */}
      <FilterForm {...{ filterValues, setFilterValues, filter, searchValue, setSearchValue }} />

      {/** Пресеты фильтров */}
      <Tabs
        activeKey={currentFilterPreset}
        onChange={(preset) => { setFilterValues(presetsCollection[preset].value ?? {}) }}
        tabBarExtraContent={{ right: SortingButton({ sorting, sortingValue, setSortingValue }) }}
      >
        {filter?.presets?.filter(({ middleware }) => middleware === undefined || middleware === true || (middleware !== false && middleware()))?.map((preset) => <Tabs.TabPane tab={<>{preset.icon}{preset.title}</>} key={preset.slug} />)}
      </Tabs>
    </>
  )
}
