
import ChevronRight from '@2fd/ant-design-icons/lib/ChevronRight'
import SlashForward from '@2fd/ant-design-icons/lib/SlashForward'
import { EuroCircleOutlined } from '@ant-design/icons'
import { Link } from '@reach/router'
import { Button, Card, Col, Empty, Row, Space, Typography } from 'antd'
import React from 'react'

import styles from './style.less'
export const FancyCard = ({ 
  Icon, 
  Divider = ChevronRight,
  count, 
  secondCount, 
  title, 
  link, 
  countTitle = 'Current month',
  secondCountTitle = 'Upcoming month',
  forceTitle = false
}) => {
  if (Divider === 'line') {
    Divider = SlashForward
  }

  const card = (
    <Card hoverable={!!link} style={{ padding: '1px' }} className="dashboard-page-card">
      <Space direction="horizontal" align="center" style={{ width: '100%', gap: '20%' }}>
      
        <div style={{ marginLeft: '10px' }}>
          {/* Title */}
          <Typography.Text type="secondary" style={{ fontSize: '1.4rem', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{title}</Typography.Text>
          {/* Counters */}
          <div style={{ overflow: 'hidden', marginTop: '10px' }}>
            <Typography.Text strong style={{ fontSize: '1.6rem' }}>€ {count}</Typography.Text>
          </div>
          {/* Subtitle */}
          <div style={{ opacity: '0.8', overflow: 'hidden', maxHeight: 22 }}>
            <Typography.Text strong style={{ fontSize: '0.65rem' }}>Current Month</Typography.Text>
          </div>
        </div>
         

        <div className={styles.counterIcon} style={{ '--color': '#1DC383', borderRadius: '50%' }}>
          <EuroCircleOutlined />
        </div>
         
      </Space>
    </Card>
  )

  return (
    <>
      {card}
    </>
  )
}