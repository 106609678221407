import './style.css'

import { colors } from '_colors'
import { useQuery } from '@apollo/react-hooks'
import isEqual from 'lodash.isequal'
import moment from 'moment'
import React, { useEffect, useMemo, useRef,useState } from 'react'
import { useInterval } from 'react-use'
import { db } from 'utils/dexie'

import { Footer } from './Footer'
import { Header } from './Header'
import { SimpleHeader } from './SimpleHeader'
import { TableRenderMode } from './TableRenderMode'
import { TilesRenderMode } from './TilesRenderMode'

/**
 * Менеджер списков
 * @param {Object} props List manager props
 *
 * @param {string} props.slug List manager slug (Used for localStorage)
 * 
 * @param {string} props.lastSeen Dexie table name
 *
 * Title
 * @param {string|Object} props.title Title
 * @param {string} props.title.title Main Title
 * @param {string} props.title.subtitle Subtitle
 *
 * Header actions
 * @param {Array.<React>} props.actions Header actions
 *
 * GraphQL query
 * @param {Query} props.query Data fetch query
 *
 * Sorting
 * @param {Object} props.sorting Sorting settings
 *
 * @param {string} props.sorting.default Default sorting value
 *
 * @param {Array.<Object>} props.sorting.variations Variations
 * @param {string} props.sorting.variations.slug Slug
 * @param {string} props.sorting.variations.title Title
 * @param {React} props.sorting.variations.icon Icon
 * @param {CallableFunction|boolean} props.sorting.variations.middleware Do I need to display the sorting option
 *
 * Filtering
 * @param {Object} props.filter Filter settings
 *
 * @param {string} props.filter.defaultPreset Default filter preset slug
 *
 * @param {Object} props.filter.filters Avalable filters
 * @param {string} props.filter.filters.slug Slug
 * @param {string} props.filter.filters.title Title
 * @param {string} props.filter.filters.type Filter type
 * @param {string} props.filter.filters.options Filter options
 *
 * @param {Array.<Object>}  props.filter.presets Filter presets
 * @param {string} props.filter.presets.title Title
 * @param {string} props.filter.presets.slug Slug (stored in URI)
 * @param {React} props.filter.presets.icon Icon
 * @param {Object} props.filter.presets.value Filter value
 *
 * Pagination
 * @param {Object} props.pagination Pagination settings
 * @param {number} props.pagination.pageSize Default items per page
 *
 * Table View
 * @param {Object} props.table Table view settings
 *
 * @param {Array.<Object>} props.table.columns Columns
 *
 * @param {string} props.table.columns.title Title
 * @param {string} props.table.columns.key Key to display
 * @param {string} props.table.columns.className Class name
 * @param {any} props.table.columns.options Custom options
 * @param {CallableFunction|string} props.table.columns.render Renderer function/default handler
 * @param {CallableFunction|boolean} props.table.columns.middleware Do I need to display the column
 * @param {number} props.table.columns.width Column width
 * @param {number} props.table.columns.fixed Side to fix column
 *
 * @param {Object} props.table.row Rows
 * @param {string} props.table.row.key Row key
 * @param {CallableFunction} props.table.row.color Row color apply method
 * @param {CallableFunction} props.table.row.expandable Extendable block render method
 *
 * @param {Object} props.table.scoll Whether the table can be scrollable, config
 *
 * Tiles View
 * @param {CallableFunction} props.tiles Tiles view function
 *
 * Handlers
 * @param {Object} props.handlers Handlers
 * @param {CallableFunction} props.handlers.onMouseEnter Action on mouse enter on item
 * @param {CallableFunction} props.handlers.onMouseLeave Action on mouse leave from item
 *
 * Styling
 * @param {Object} props.style
 * @param {string} props.class
 * @param {boolean} props.fullpage
 * @param {boolean} props.simple
 *
 */
export const ListManager = ({
  slug: managerSlug,
  title,
  actions = [],
  query,
  queryParams = {},
  filter = { filters: [] },
  pagination = { pageSize: 10 },
  table,
  tiles,
  sorting,
  lastSeen = null,
  refetch,

  handlers = {},

  style = {},
  class: className,
  fullpage = false,
  withoutCopy = false,
  overlay = true,
  simple = false
  
}) => {
  const listManagerRef = useRef(null)

  /** Пагинация */
  // eslint-disable-next-line prefer-const
  let [currentPage, setCurrentPageRaw] = useState(1)
  // eslint-disable-next-line prefer-const
  let [pageSize, setPageSizeRaw] = useState(pagination.pageSize)

  /** Фильтр */
  const defaultFilterValues = Array.isArray(filter?.presets) ? filter.presets.find((preset) => preset.slug === filter.defaultPreset)?.value ?? {} : {}
  // eslint-disable-next-line prefer-const
  let [filterValues, setFilterValuesRaw] = useState(defaultFilterValues)

  /** Поисковая строка */
  // eslint-disable-next-line prefer-const
  let [searchValue, setSearchValueRaw] = useState('')

  /** Сортировка */
  // eslint-disable-next-line prefer-const
  let [sortingValue, setSortingValueRaw] = useState(sorting.default)

  /** GraphQL API */
  const { data: rawData, loading: dataLoadingRaw, error: dataError, refetch: dataRefetchRaw } = useQuery(query, { variables: { ...queryParams, page: 1, pageSize: pagination.pageSize, filter: JSON.stringify({ ...defaultFilterValues, ...queryParams?.filter } ), sorting: sorting.default }, fetchPolicy: 'no-cache' })
  const { list: data, pagination: { total: dataTotal } } = rawData?.response ?? { list: [], pagination: { total: 0 } }
  const dataRefetch = () => (dataRefetchRaw({ ...queryParams, page: currentPage, pageSize, search: searchValue, filter: JSON.stringify({ ...filterValues, ...queryParams?.filter }), sorting: sortingValue }, { fetchPolicy: 'no-cache' }))

  /** Last seen */
  const [lastSeenData, setLastSeenData] = useState([])
  const lastSeenFn = async () => {
    if (lastSeen) {
      const ids = data?.map?.(item => item.id) ?? []
      if (ids.length > 0) {
        setLastSeenData(await db[lastSeen].where('id').anyOf(...ids).toArray())
      }
    }
  }
  // useInterval(lastSeenFn, lastSeen ? 3500 : null)
  // useEffect(() => { lastSeenFn() }, [data])

  /** Дата последней подгрузки данных */
  const [dataLastFetchDate, setDataLastFetchDate] = useState(moment())
  useEffect(() => { !dataLoadingRaw && setDataLastFetchDate(moment) }, [dataLoadingRaw])

  /**
   * Триггер на перезагрузку данных
   */
  const [refetchTriggerTimer, setRefetchTriggerTimer] = useState(null)
  const [refetchTriggerPending, setRefetchTriggerPending] = useState(false)

  /** Сбрасываем триггер перезагрузки данных */
  // const resetRefetchTrigger = () => {
  //   refetchTriggerTimer && clearTimeout(refetchTriggerTimer)
  //   setRefetchTriggerTimer(null)
  // }

  /** Триггер перезагрузки данных */
  const refetchTrigger = async (type) => {
    let timer = 1500
    let scrollTop = false
    let resetPagination = false

    switch (type) {
    case 'pagination': {
      timer = 500
      scrollTop = true
      break
    }
    case 'filter': {
      timer = 1000
      resetPagination = true
      break
    }
    case 'sorting': {
      timer = 50
      resetPagination = true
      break
    }

    default:
    }

    setRefetchTriggerPending(true)

    // Пагинация
    resetPagination && setCurrentPageRaw(1)

    // Прокрутка страницы вверх
    if (scrollTop) {
      try { listManagerRef.current.scrollIntoView() } catch {
        console.log('Can\'t scroll top')
      }
    }

    // Подгружаем данные
    try {
      await dataRefetch()
    } finally {
      setRefetchTriggerPending(false)
    }

    // return resetRefetchTrigger;
  }

  /** Триггер переключения между страницами */
  const setCurrentPage = (value) => { currentPage = value; setCurrentPageRaw(value); refetchTrigger('pagination') }
  const setPageSize = (value) => { pageSize = value; setPageSizeRaw(value); refetchTrigger('pagination') }

  /** Триггер фильтров */
  const setFilterValues = (value) => { filterValues = value; setFilterValuesRaw(value); refetchTrigger('filter') }
  const setSearchValue = (value) => {
    if (value !== searchValue) {
      searchValue = value
      setSearchValueRaw(value)
      refetchTrigger('filter')
    }
  }

  /** Ручной перезапуск данных */
  useEffect(() => {
    refetchTrigger('filter')
  }, [refetch])

  /** Ссылка на текущий фильтр */
  const filterUri = useMemo(() => {
    if (managerSlug && !withoutCopy) {
      let url = new URL(window.location)
      url.searchParams.delete('list-manager')
      let presetName = filter.presets.find((preset) => isEqual(preset.value, filterValues))?.slug

      url.searchParams.set('list-manager', JSON.stringify({
        __slug: managerSlug,
        ...(presetName ? { __preset: presetName } : null),
        ...(!presetName ? filterValues : null),
      }))

      return url.toString()
    }

    return null
  }, [filterValues])

  /** Триггер сортировки */
  const setSortingValue = (value) => { sortingValue = value; setSortingValueRaw(value); refetchTrigger('sorting') }

  /** Статус загрузки данных */
  const dataLoading = dataLoadingRaw || refetchTriggerPending

  /** /////////////////////////////////// */

  /** Режим отображения */
  const canChoseTilesRenderMode = !!tiles && !!table
  const localStorageKey = `list-manager:${managerSlug}`
  const [renderMode, setRenderModeRaw] = useState((canChoseTilesRenderMode && window.localStorage.getItem(localStorageKey) !== 'table') || !table ? 'tiles' : 'table')
  const setRenderMode = (value) => {
    setRenderModeRaw(value)
    if (canChoseTilesRenderMode && managerSlug) {
      window.localStorage.setItem(localStorageKey, value)
    }
  }

  return (
    <div className={overlay ? 'list-manager__overlay' : ''} style={{ ...(fullpage ? { margin: '0px -22px 0px', minHeight: '100vh' } : { padding: 5 }), '--main-color': colors.main() }}>
      <section style={style} className={`list-manager list-manager--${renderMode}-mode ${className ?? ''}`.trim()} ref={listManagerRef}>
        {!simple 
          ? <Header {...{ managerSlug, title, actions, filter, renderMode, setRenderMode, canChoseTilesRenderMode, searchValue, setSearchValue, filterValues, setFilterValues, filterUri, sorting, sortingValue, setSortingValue }} /> 
          : <SimpleHeader {...{ managerSlug, title, actions, filter, renderMode, setRenderMode, canChoseTilesRenderMode, searchValue, setSearchValue, filterValues, setFilterValues, sorting, sortingValue, setSortingValue }} />
        }

        {(renderMode === 'tiles' && !simple)
          ? <TilesRenderMode {...{ tiles, data, dataLoading, dataRefetch, handlers, lastSeen, lastSeenData }} />
          : <TableRenderMode {...{ table, data, dataLoading, dataRefetch, handlers, lastSeen, lastSeenData }} />}

        <Footer {...{ dataTotal, currentPage, setCurrentPage, pageSize, setPageSize, dataLoading, dataRefetch, dataError, dataLastFetchDate }} />
      </section>
    </div>
  )
}
