import Dexie from 'dexie'

export const db = new Dexie('b2bhomer')

db.version(1).stores({
  rentalRequestsLastSeen: 'id,updatedAt',
  rentalContractsLastSeen: 'id,updatedAt',
  serviceRequestsLastSeen: 'id,updatedAt'
})

