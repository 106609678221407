import countries from '@umpirsky/country-list/data/de/country.json'

import custom from './en.json'
import apartments from './modules-de/apartments.json'
import attachments from './modules-de/attachments.json'
import auth from './modules-de/auth.json'
import billruns from './modules-de/billruns.json'
import buildings from './modules-de/buildings.json'
import comission from './modules-de/comissions.json'
import contracts from './modules-de/contracts.json'
import dashboard from './modules-de/dashboard.json'
import gallery from './modules-de/gallery.json'
import invoices from './modules-de/invoices.json'
import logs from './modules-de/logs.json'
import notifications from './modules-de/notifications.json'
import profiles from './modules-de/profiles.json'
import projectCodes from './modules-de/projectCodes.json'
import projects from './modules-de/projects.json'
import recurring from './modules-de/recurring.json'
import rentalRequests from './modules-de/rentalRequests.json'
import reports from './modules-de/reports.json'
import routing from './modules-de/routing.json'
import serviceRequests from './modules-de/serviceRequests.json'
import services from './modules-de/services.json'
import transactions from './modules-de/transactions.json'
import users from './modules-de/users.json'
import widgets from './modules-de/widgets.json'


export const de = {
  countries,
  ...apartments,
  ...attachments,
  ...auth,
  ...buildings,
  ...comission,
  ...contracts,
  ...custom,
  ...dashboard,
  ...gallery,
  ...invoices,
  ...logs,
  ...notifications,
  ...profiles,
  ...projectCodes,
  ...projects,
  ...rentalRequests,
  ...serviceRequests,
  ...transactions,
  ...users,
  ...widgets,
  ...services,
  ...custom,
  ...routing,
  ...recurring,
  ...billruns,
  ...reports,
}
