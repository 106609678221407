import { colors } from '_colors'
import styled from 'styled-components'

export const AptWrapper = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
  h2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
  }
  p {
    font-size: 14px;
    line-height: 30px;
    margin: 0;
  }
  .total-wrapper {
    display: flex;
    align-items: center;
    margin: 5px;
  }
  .total {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
`

export const OriginalItem = styled.div`
  background-image: url(${(p) => p.args.original});
  width: 100%;
  height: 500px;
  background-position: center;
  background-size: cover;
  border-radius: 7px;
`
export const ThumbItem = styled.div`
  background-image: url(${(p) => p.args.original});
  width: 100%;
  height: 80px;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  border-radius: 12px;
  /* border: solid 5px ${colors.veryLightGray()}; */
  border: solid 5px white;
  transition: border-color .3s ease-in-out;
  :hover {
    border: solid 5px ${colors.main(0.3)};
  }
`

export const AmenityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  opacity: ${(p) => (p.isSelected ? '1' : '0.5')};
  .icon {
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
    background-image: url(${(p) => p.icon});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    filter: ${(p) => (p.isSelected ? 'grayscale(0)' : 'grayscale(1)')};
  }
`

export const EditPart = styled.div`
  border: dashed 1px transparent;
  padding: 5px;
  margin-bottom: ${(p) => p.marginBottom || 0}px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
  .anticon-edit {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  :hover {
    border: dashed 1px ${colors.text()};
    .anticon-edit {
      opacity: 1;
    }
  }
  ${(p) => p.inEditMode
    && `
    border: dashed 1px ${colors.text()};
    .anticon-edit {
      display: none;
    }
  `}
`

export const EditOverlayStyled = styled(EditPart)`
  padding: ${(p) => p.verticalPadding || 0}px 0;
  margin-bottom: ${(p) => p.marginBottom || 0}px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  border-color: ${colors.main()};
  :hover {
    background-color: ${colors.main(0.95)};
    border: solid 1px ${colors.main()};
  }
`

export const TabWrapper = styled.div`
  padding: 40px 0;
  h2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }
`

export const StudioSwitchWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`
