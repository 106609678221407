import './style.css'

import { colors } from '_colors'
import { DASHBOARD, FIX_APARTMENTS_AVAILABILITY, FIX_TRANSACTIONS,MAKE_BR_SIMULATION,MAKE_CLEANUP, MAKE_CONTRACT_SERVICE, MAKE_SIMULATION, PREPARE_APARTMENTS, SIM_ALL, SIM_EMAIL,SIM_PUSH} from '_graphql'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { Alert, Button, Col, Descriptions, message, PageHeader, Row, Spin, Tabs, Timeline, Typography } from 'antd'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import { SimpleHeader } from 'components/ListManager/SimpleHeader'
import { Notifications } from 'components/Notifications'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LogsManager } from 'screens/LogsManager'
import { useGlobalState } from 'state'
import styles from 'styles/side-layout.less'

const System = () => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const { data: dashboard } = useQuery(DASHBOARD)

  const [runClean, { data: result1 }] = useLazyQuery(MAKE_CLEANUP, { fetchPolicy: 'no-cache' })

  const [runSim, { data: result2 }] = useLazyQuery(MAKE_SIMULATION, { fetchPolicy: 'no-cache' })

  const [prepareContracts, { data: result3 }] = useLazyQuery(PREPARE_APARTMENTS, { fetchPolicy: 'no-cache' })
  const [prepareBillRuns, { data: result4 }] = useLazyQuery(MAKE_BR_SIMULATION, { fetchPolicy: 'no-cache' })
  const [fixTransactions, { data: transactionsData }] = useLazyQuery(FIX_TRANSACTIONS, { fetchPolicy: 'no-cache' })
  const [simPush, { data: simPushRes }] = useLazyQuery(SIM_PUSH, { fetchPolicy: 'no-cache' })
  const [simEmail, { data: simEmailRes }] = useLazyQuery(SIM_EMAIL, { fetchPolicy: 'no-cache' })


  const [fixApartmentsAvailability, { data: fixApts }] = useLazyQuery(FIX_APARTMENTS_AVAILABILITY, { fetchPolicy: 'no-cache' })

  return (

    <Row gutter={[20, 20]}>
      <Col xs={24}>
        <LayoutCard>
              
          <Row gutter={[20, 20]}>
            <Col xs={4}><Button type="primary" onClick={runClean}>Make cleanup</Button></Col>
            <Col xs={20}> { result1 ? <Alert message={result1?.response}></Alert> : null } </Col>
          </Row>
    
        </LayoutCard>
        <LayoutCard>
              
          <Row gutter={[20, 20]}>
            <Col xs={4}><Button type="primary" onClick={fixTransactions}>Fix Transactions</Button></Col>
            <Col xs={20}> { transactionsData ? <Alert message={transactionsData?.response}></Alert> : null } </Col>
          </Row>
              
        </LayoutCard>
        <LayoutCard>
          <Row gutter={[20, 20]}>
            <Col xs={4}>  <Button type="primary" onClick={prepareContracts}>Prepare contracts</Button></Col>
            <Col xs={20}> { result3 ? <Alert message={result3?.response}></Alert> : null } </Col>
          </Row>
        </LayoutCard>
        <LayoutCard>
          <Row gutter={[20, 20]}>
            <Col xs={4}>  <Button type="primary" onClick={runSim}>Make History RP</Button></Col>
            <Col xs={20}> { result2 ? <Alert message={result2?.response}></Alert> : null } </Col>
          </Row>
        </LayoutCard>
        <LayoutCard>
          <Row gutter={[20, 20]}>
            <Col xs={4}>  <Button type="primary" onClick={prepareBillRuns}>Make History BR</Button></Col>
            <Col xs={20}> { result4 ? <Alert message={result4?.response}></Alert> : null } </Col>
          </Row>
        </LayoutCard>
        <LayoutCard>
          <Row gutter={[20, 20]}>
            <Col xs={4}>  <Button type="primary" onClick={fixApartmentsAvailability}>Fix apartments</Button></Col>
            <Col xs={20}> { fixApts ? <Alert message={fixApts?.response}></Alert> : null } </Col>
          </Row>
        </LayoutCard>

        <LayoutCard>
          <Row gutter={[20, 20]}>
            <Col xs={4}>  <Button type="primary" onClick={simPush}>Simulate push</Button></Col>
            <Col xs={20}> { simPushRes ? <Alert message={simPushRes?.response}></Alert> : null } </Col>
          </Row>
        </LayoutCard>

        <LayoutCard>
          <Row gutter={[20, 20]}>
            <Col xs={4}>  <Button type="primary" onClick={simEmail}>Simulate email</Button></Col>
            <Col xs={20}> { simEmailRes ? <Alert message={simEmailRes?.response}></Alert> : null } </Col>
          </Row>
        </LayoutCard>
      </Col>
    </Row>
       
  )
}

export { System }
