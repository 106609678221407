import './style.css'

import { NOTIFICATION_ADDED, NOTIFICATIONS_LIST, READ_ALL } from '_graphql'
import { NOTIFICATION_TYPES } from '_graphql/_enums'
import Calendar from '@2fd/ant-design-icons/lib/Calendar'
import { CloseOutlined, ContainerFilled, ContainerOutlined, UserOutlined } from '@ant-design/icons'
import { useMutation,useQuery } from '@apollo/react-hooks'
import { Link } from '@reach/router'
import { Alert, Button, Empty, List, notification,PageHeader, Space,Typography } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import { subscribe } from 'graphql'
import uniqBy from 'lodash/uniqBy'
import { DateTime } from 'luxon'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import styles from 'styles/side-layout.less'

// const IconText = ({ icon, text }) => (
//   <Space>
//     {React.createElement(icon)}
//     {text}
//   </Space>
// )

const NotificationRenderer = ({ notification, onLinkClick, onClose }) => {
  let notificationTypeContext = NOTIFICATION_TYPES[notification.context.type]
  if (!notificationTypeContext) {
    notificationTypeContext = NOTIFICATION_TYPES['NOT_DESCRIBED']
  }

  // Render
  return (
    <Alert
      className='notification-item'
      action={<Link onClick={() => onLinkClick({ notification })} to={notificationTypeContext.link(notification.context)} className='notification-item-link' />}
      message={notificationTypeContext.title(notification.context)}
      description={notificationTypeContext.description(notification.context) + ' at: ' + DateTime.fromISO(notification.createdAt).toFormat('yyyy-LL-dd HH:mm')}
      type="info"
      style={{ marginBottom: '20px'}}
      showIcon
      icon={<ContainerOutlined />}
      closable
      onClose={() => onClose?.({ notification })}
    /> 
  )
}


const Notifications = ({ filter = {}, close }) => {
  const { t } = useTranslation()
  const [notifications, setNotifications] = useState([])
  const [readAllMutation] = useMutation(READ_ALL)
  const { data: rawNotificationsLastPage, loading: notificationsLoading, refetch: notificationsRefetch, subscribeToMore } = useQuery(
    NOTIFICATIONS_LIST, 
    { 
      variables: { 
        page: 1, 
        pageSize: 15,
        filter: JSON.stringify(filter)
      }, 
      fetchPolicy: 'no-cache' 
    })
  const notificationsLastPage = rawNotificationsLastPage?.response

  useEffect(() => {
    if (notificationsLastPage?.list) {
      setNotifications(uniqBy([...notifications, ...notificationsLastPage?.list], 'id'))
      notificationsRefetch()
    }
  }, [notificationsLastPage])

  const readMyNotification= async (id) => {
    console.log('Clicked')
    const res = await readAllMutation({
      variables: {
        id: id
      },
    })
    console.log('Res' ,res)
    await notificationsRefetch()
  }

  const onLinkClickHandler = async ({ notification }) => {
    setNotifications(notifications => notifications.filter((item) => item.id !== notification.id))
    close?.()
    await readMyNotification(notification.id)
  }

  const onCloseHandler = ({ notification }) => {
    readMyNotification(notification.id)
  }

  return (
    <InfiniteScroll
      hasMore={!notificationsLoading && notificationsLastPage?.pagination && (notificationsLastPage.pagination.page * notificationsLastPage.pagination.pageSize < notificationsLastPage.list?.length)}
      loadMore={(page) => {
        if (page > notificationsLastPage?.pagination?.page) {
          return notificationsRefetch({ page, pageSize: 15 })
        }

        return true
      }}
      useWindow={false}

      subscribeToNewComments={() =>
        subscribeToMore({
          document: NOTIFICATION_ADDED,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            const notifications = subscriptionData.data

            return Object.assign({}, prev, {
              notifications: [notifications, ...prev.post.comments]
            })
          }
        })
      }
    >

      {notifications.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : null}
      {notifications.map((notification) => (
        <NotificationRenderer
          key={notification.id}
          notification={notification}
          onLinkClick={onLinkClickHandler}
          onClose={onCloseHandler}
        />
      ))}
    </InfiniteScroll>
  )
}

export { Notifications }


