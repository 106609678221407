import React from 'react'

import { EditPart } from '../styled'

const EditWrapper = ({ inEditMode, marginBottom, children, onClick, handlers }) => (
  inEditMode
    ? (
      <EditPart handlers={handlers} inEditMode marginBottom={marginBottom} onClick={onClick}>
        {children}
      </EditPart>
    )
    : (
      <EditPart handlers={handlers} marginBottom={marginBottom} onClick={onClick}>
        {children}
      </EditPart>
    )
)

export { EditWrapper }
