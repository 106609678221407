import { ApolloClient,InMemoryCache } from '@apollo/client'
import { buildAxiosFetch } from '@lifeomic/axios-fetch'
import { split } from 'apollo-link'
// import { HttpLink } from 'apollo-link-http';
// import { onError } from '@apollo/link-error';
import { setContext } from 'apollo-link-context'
import { WebSocketLink } from 'apollo-link-ws'
import { createUploadLink as CreateUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import axios from 'axios'
import { projectContext } from 'common/ProjectProvider'

const uri = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_SERVER_LOCAL : process.env.REACT_APP_SERVER_REMOTE

const link = new CreateUploadLink({
  uri,
  fetch: buildAxiosFetch(axios, (config, _input, _init) => {
    const configCopy = { ...config }

    if (config.data instanceof FormData) {
      configCopy.headers['content-type'] = 'multipart/form-data'
    }

    return {
      ...configCopy,
      // onUploadProgress: init.onUploadProgress,
      // onUploadProgress(progressEvent) {
      //   const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //   console.log(percentCompleted);
      // },
    }
  }),
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      project: projectContext.slug,
      Authorization: token && !(_.operationName === 'login' || _.operationName === 'user') ? `Bearer ${token}` : '',
    },
  }
})

const wsLink = new WebSocketLink({
  uri: uri.replace('http', 'ws').replace('/graphql', '/subscriptions'),
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem('token')
      return {
        headers: {
          project: projectContext.slug,
          authorization: token ? `Bearer ${token}` : null,
        }
      }
    }
  },
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(link),
)

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})
