import {
  isAdmin,
  isTenant,
  USER_ROLES,
} from '_graphql/_enums'
import { PROJECT_LIST } from '_graphql/project'
import { ALL_RENTAL_CONTRACTS_QUERY } from '_graphql/rentalContract'
import {
  TRANSACTION_DELETE_MUTATION,
  TRANSACTION_SKU_QUERY,
  TRANSACTION_UPSERT_MUTATION,
  TRANSACTIONS_LIST,
} from '_graphql/transaction'
import { USERS_LIST } from '_graphql/user/queries'
import AccountCancel from '@2fd/ant-design-icons/lib/AccountCancel'
import AccountCheck from '@2fd/ant-design-icons/lib/AccountCheck'
import AccountClock from '@2fd/ant-design-icons/lib/AccountClock'
import AccountClockIcon from '@2fd/ant-design-icons/lib/AccountClock'
import { BankOutlined, ReloadOutlined, UserDeleteOutlined } from '@ant-design/icons'
import { useLazyQuery,useMutation, useQuery } from '@apollo/react-hooks'
import { Delete } from '@material-ui/icons'
import { Link, navigate, useLocation, useParams } from '@reach/router'
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  message,
  Popover,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { NotFound } from 'components'
import { ContactBox } from 'components/ContactBox'
import { ContractSelect } from 'components/Form/ContractSelect'
import { DateRange } from 'components/Form/DateRange'
import { ProjectSelect } from 'components/Form/ProjectSelect'
import { UserSelect } from 'components/Form/UserSelect'
import {
  LayoutCard,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import { PageHeader } from 'components/PageHeader'
import { DateTime } from 'luxon'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
  
const keys = [
  'type',
  'userIn',
  'userOut',
  'moveInDate',
  'moveOutDate',
  'contractId',
  'creatorId',
  'projectId',
  'dates'
]

const Status = ({
  status,
}) => {
  const { t } = useTranslation()

  if (status === 'accepted') {
    return <Typography.Text type="success">{t('transactions.table.accepted')}</Typography.Text>
  } else if (status === 'completed') {
    return <Typography.Text>{t('transactions.table.completed')}</Typography.Text>
  } else if (status === 'canceled') {
    return <Typography.Text type="danger">{t('transactions.table.canceled')}</Typography.Text>
  }  else if (status === 'handover') {
    return <Typography.Text type="success">{t('transactions.table.handover')}</Typography.Text>
  } else {
    return <Typography.Text type="warning">{t('transactions.table.waiting')}</Typography.Text>
  }
}
  
const TransactionDetails = ({ onClose, isModal, forceQuery }) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const params = isModal ? {} : useParams()
  const [, rerender] = useState()
  const [, setHasChanges] = useState()
  const role = state.currentUser?.role
  
  const sku = forceQuery.get('sku')
  const [transactionData, setTransactionData] = useState(null)
  const [upsertTransaction] = useMutation(TRANSACTION_UPSERT_MUTATION)
  const [deleteTransaction] = useMutation(TRANSACTION_DELETE_MUTATION)

  const [fetch, { data: transactionDataRaw, loading: transactionLoading, refetch: transactionRefetch, }] = useLazyQuery(TRANSACTION_SKU_QUERY, { variables: { sku: sku }, fetchPolicy: 'no-cache' })  


  useEffect(() => {
    if(sku){
      fetch()
    }
  }, [sku])
  useEffect(() => {
    if(!transactionLoading) {
      setTransactionData(transactionDataRaw?.response)
    }
  }, [transactionDataRaw, transactionLoading])

  
  const [form] = Form.useForm()
  const values = form.getFieldsValue()
  
  
  const saveTransaction = async () => {
    const values = form.getFieldsValue(keys.filter(key => key !== 'projectId'))
  
    if (!isAdmin(state.currentUser?.role)) {
      values.userId = state.currentUser?.id
    }
  
    values.moveInDate = values.dates.dateFrom
    values.moveOutDate = values.dates.dateTill
    delete values.dates
  
    if (transactionData?.id) {
      const d = await upsertTransaction({
        variables: {
          input: {
            id: transactionData.id || null,
            ...values,
            userIn: values.userId,
          },
        },
      })
  
      if (d?.data?.response) {
        message.success('saved')
    
      } else {
        message.error('error')
      }
    } else {
      const d = await upsertTransaction({
        variables: {
          input: {
            ...values,
            type: parseInt(values.type),
            status: isAdmin(state.currentUser?.role) ? 'accepted' : 'waiting'
          },
        },
      })
  
      if (d.data?.response) {
        message.success('created')
        
      } else {
        message.error('creation error')
      }
    }
  }


  /** Set status */
  const updateStatus = async (id, status, completedAt = null) => {
    const response = await upsertTransaction({
      variables: {
        input: {
          id,
          status,
          completedAt
        },
      },
    })

    if (!response?.errors) {
      message.success(t('transactions.form.updateSuccess'))
      await transactionRefetch()
    } else { 
      message.warning(t('transactions.form.updateError'))
      
    }

  }

  const archiveTransaction = async (id) => {
    const response = await deleteTransaction({
      variables: {
        id
      },
    })

    if (!response?.errors) {
      message.success(t('transactions.form.deleteSuccess'))
    } else { 
      message.warning(t('transactions.form.deleteError'))
      
    }
  }
  
  return (
    <>

      <PageHeader
        title={t('transactions.form.current')}
        id={sku}
        dropdown={ [isAdmin(role) && transactionData?.status === 'waiting' ? (
          <React.Fragment key={999}>
            <Tooltip title={t('transactions.table.actions.accept')}>
              <Button onClick={() => updateStatus(transactionData?.id, 'accepted')}>
                <AccountClock />
              </Button>
            </Tooltip>
            <Tooltip title={t('transactions.table.actions.cancel')}>
              <Button onClick={() => updateStatus(transactionData?.id, 'canceled')}>
                <AccountCancel />
              </Button>
            </Tooltip>
            <Tooltip title={t('transactions.table.actions.archive')}>
              <Button onClick={() => archiveTransaction(transactionData?.id)}>
                <UserDeleteOutlined />
              </Button>
            </Tooltip>
           
          </React.Fragment>
          
          
        ) : null]}
      />
     
      <LayoutCard>

        <Row>
          <Col xs={24} >
            <Descriptions title="" size="small" layout="horizontal" column={2} bordered>
              <Descriptions.Item label={t('transactions.form.type.input')}>{ transactionData?.type ? (transactionData?.type != 1 ? (transactionData?.type != 2 ? t('transactions.table.changeOfUser') : t('transactions.form.type.moveOut')) : t('transactions.form.type.moveIn')) : '-'}</Descriptions.Item>
              <Descriptions.Item label={t('transactions.form.date')}>{ transactionData?.moveInDate ? DateTime.fromISO(transactionData?.moveInDate).toFormat('dd LLL yyyy') : '-'}</Descriptions.Item>
              <Descriptions.Item label={t('transactions.form.contract')}>
                <Link to={`/rental-requests-management/${transactionData?.contract?.sku}`}>
                    #{transactionData?.contract?.sku.substr(0, 8)}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label={t('transactions.form.status')}>
                {transactionData && <Status status={transactionData?.status} />}
              </Descriptions.Item>
              { 
                transactionData?.type === 3 ? (
                  <>
                    <Descriptions.Item label={t('transactions.form.projectIn')}>{transactionData?.moveInUser?.project?.title}</Descriptions.Item>
                    <Descriptions.Item label={t('transactions.form.projectOut')}>{transactionData?.moveOutUser?.project?.title}</Descriptions.Item>
                  </>
                ) : <Descriptions.Item label={t('transactions.form.project')}>{transactionData?.contract?.project?.title}</Descriptions.Item>
              }
              
              {
                transactionData?.type &&
                  transactionData?.type != 1 ? 
                  transactionData?.type != 2 ? 
                    <>
                      <Descriptions.Item label={t('transactions.form.moveInUser')}>{ transactionData?.moveInUser ? <ContactBox user={transactionData?.moveInUser} key={transactionData.moveInUser?.id} /> : null}</Descriptions.Item>
                      <Descriptions.Item label={t('transactions.form.moveOutUser')}>{ transactionData?.moveOutUser ? <ContactBox user={transactionData?.moveOutUser} key={transactionData.moveOutUser?.id} /> : null}</Descriptions.Item>
                    </>        
                    : <Descriptions.Item label={t('transactions.form.user')}>{ transactionData?.moveOutUser ? <ContactBox user={transactionData?.moveOutUser} key={transactionData.moveOutUser?.id} /> : null}</Descriptions.Item>
                  : <Descriptions.Item label={t('transactions.form.user')}>{ transactionData?.moveInUser ? <ContactBox user={transactionData?.moveInUser} key={transactionData.moveInUser?.id} /> : null}</Descriptions.Item>
            
              }

              { transactionData?.status === 'accepted' ?
                <>
                  <Descriptions.Item label={t('transactions.form.handover')}>
                    <Form 
                      initialValues={{ date: moment() }} 
                      form={form}
                      layout='inline'
                      style={{ width: 170 }}
                      onFinish={({ date }) => {
                        const args = [transactionData?.id, 'handover', date]
                        updateStatus(...args)
                      }}
                    >
                      <Form.Item name="date" noStyle>
                        <DatePicker  />
                      </Form.Item>
                      <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }}>
                        {t('transactions.actions.continue')}
                      </Button>
                    </Form>
          
                  </Descriptions.Item>
                </>
                : null }
             

            </Descriptions>
           

          </Col>
       
        </Row>
     
      </LayoutCard>
    
    </>
  )
}
  
export { TransactionDetails }
  