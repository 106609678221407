import {
  RENTAL_CONTRACT_UPSERT_MUTATION,
  RENTAL_REQUEST_APARTMENT_DELETE_MUTATION,
  RENTAL_REQUEST_APARTMENT_MUTATION,
  RENTAL_REQUEST_CHANGED,
  RENTAL_REQUEST_SKU_QUERY,
  RENTAL_REQUEST_UPSERT_MUTATION,
  SCHEDULE_INSPECTION,} from '_graphql'
import { isAdmin, isLandlord, isTenant, isTenantManager, RENTAL_REQUESTS_STATUSES, REQUESTED_APARTMENT_STATUSES } from '_graphql/_enums'
import Calendar from '@2fd/ant-design-icons/lib/Calendar'
import Eye from '@2fd/ant-design-icons/lib/Eye'
import Fireplace from '@2fd/ant-design-icons/lib/Fireplace'
import { useLazyQuery, useMutation, useQuery,useSubscription } from '@apollo/react-hooks'
import { navigate, useParams } from '@reach/router'
import { Link } from '@reach/router'
import {
  Alert,
  Badge,
  Button,
  Divider,
  Empty,
  Form,
  Input,
  message,
  PageHeader,
  Popconfirm,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { useModals } from 'common/ModalsProvider'
import { CityPicker } from 'components/AddressDescription/CityPicker'
import { ContactBox } from 'components/ContactBox'
import { AmountInput } from 'components/Form/AmounInput'
import { DateRange } from 'components/Form/DateRange'
import { TenantChainPicker } from 'components/Form/TenantChainPicker'
import { UserSelect } from 'components/Form/UserSelect'
import { divide } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo,useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApartmentsManager } from 'screens/ApartmentsManager'
import { ApartmentTile } from 'screens/ApartmentsManager/ApartmentTile'
import { ProfileInfoCard } from 'screens/BuildingsManager/BuildingDetails/ProfileInfoCard'
import { useGlobalState } from 'state'
import styles from 'styles/side-layout.less'
import { db } from 'utils/dexie'

// import { ProposalPage } from './proposal'

function RentalRequestForm({ isModal, query }) {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const [form] = Form.useForm()
  const [hasChanges, setHasChanges] = useState(false)
  const [, rerender] = useState()
  const [declineApartment, setDeclineApartment] = useState(null)
  const [inquiryConfirmation, setInquiryConfirmation] = useState(null)
  const { openModal } = useModals()
  
  const { sku } = query ? query : useParams()
  const role = state.currentUser?.role
  const formData = form.getFieldsValue()
  const [editFormOpened, setEditFormOpened] = useState(false)
  const isManagementUser = isAdmin(state.currentUser?.role)
  const isLandlordUser = isAdmin(state.currentUser?.role)
  const [contractSku, setContractSku] = useState('')

  /** Fetch rental request */

  const { data: rentalRequestDataRaw, loading: rentalRequestLoadingRaw } =
  useQuery(RENTAL_REQUEST_SKU_QUERY, {
    variables: { sku },
    fetchPolicy: 'no-cache',
  })

  const [rentalRequest, setRentalRequestData] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!rentalRequestLoadingRaw) {
      setRentalRequestData(rentalRequestDataRaw?.response)
      setIsLoading(false)
    }
  }, [rentalRequestLoadingRaw])

  const { data: rentalRequestDataSub } = useSubscription(
    RENTAL_REQUEST_CHANGED,
    {
      fetchPolicy: 'no-cache',
      variables: { sku: rentalRequest?.sku },
      onError: (err) => console.error(err),
    }
  )

  // Subscribe
  useEffect(() => {
    if (rentalRequestDataSub) {
      setRentalRequestData(rentalRequestDataSub?.response)
      setIsLoading(false)
    }
  }, [rentalRequestDataSub])

  // Last seen
  const [updatedAt, setUpdatedAt] = useState(null)
  useEffect(() => {
    if (rentalRequest) {
      setUpdatedAt(rentalRequest.updatedAt)
      db.rentalRequestsLastSeen.put({
        id: rentalRequest.id,
        updatedAt: rentalRequest.updatedAt
      })
    }
  }, [rentalRequest])

  // useEffect(() => { if (sku) { fetchRentalRequest({ variables: { sku } }) } else { setIsLoading(false) } }, [sku])
  useEffect(() => {
    if (rentalRequest) {
      form.setFieldsValue({
        salesId: rentalRequest.salesId,
        city: rentalRequest.city,
        details: rentalRequest.details,
        budget: rentalRequest.budget,
        period: {
          dateFrom: rentalRequest.dateFrom ? moment(rentalRequest.dateFrom) : null,
          dateTill: rentalRequest.dateTill ? moment(rentalRequest.dateTill) : null,
          unlimited: rentalRequest.unlimited,
        },
        tenant: {
          tenantId: rentalRequest.tenantId,
          tenantEmployeeId: rentalRequest.tenantEmployeeId,
          tenantProfileId: rentalRequest.tenantProfileId,
          projectId: rentalRequest.projectId,
        }
      })
      setIsLoading(false)
      setHasChanges(false)
    }
  }, [rentalRequest])

  /** Submit rental request */
  const [rentalRequestMutation] = useMutation(RENTAL_REQUEST_UPSERT_MUTATION)
  const onSubmit = async (values) => {
    setIsLoading(true)
    // console.log(values)
    try {
      const variables = {}

      // Exist's request
      if (rentalRequest?.id) {
        variables.id = rentalRequest.id
      }

      // Fields
      variables.salesId = isAdmin(role) ? values.salesId || null : undefined
      variables.city = String(values.city || '').trim()
      variables.details = String(values.details || '').trim()
      variables.budget = Number(values.budget) || null
  
      // Period
      variables.dateFrom = values.period?.dateFrom?.unix()
      variables.dateTill = values.period?.unlimited ? null : (1 + values?.period.dateTill?.unix()) || null
      variables.unlimited = !variables.dateTill
  
      // Tenant
      variables.tenantId = values.tenant.tenantId || null
      variables.tenantEmployeeId = values.tenant.tenantEmployeeId || null
      variables.projectId = values.tenant.projectId || null
      variables.tenantProfileId = values.tenant.tenantProfileId || null

      const rentalRequestResponse = (await rentalRequestMutation({ variables: { input: variables } })).data.response

      message.success(
        t('rental-request.main.success-message.template', {
          type: t(`rental-request.main.success-message.${rentalRequestResponse.status > 0 ? 'updated' : 'created'}`),
        })
      )

      setRentalRequestData(rentalRequestResponse)

      // Refetch or redirect
      // if (sku !== rentalRequestResponse.sku) {
      //   navigate(`/rental-requests-management/${rentalRequestResponse.sku}`)
      // }
      // else {
      //   console.log('there was a refetch')
      // }
    } catch (e) {
      console.log('RentalRequest error', e)
      message.error(t('rental-request.main.fail-message'))
      setIsLoading(false)
    }
  }

  /** Switch contract request status */
  const formDisabled = rentalRequest?.status >= 3
  const switchRentalRequestStatus = async (status) => {
    // console.log('Switch status triggered')
    if (rentalRequest) {
      // console.log('switch status update started')
      setIsLoading(true)
      try {
        const upd = await rentalRequestMutation({
          variables: {
            input: {
              id: rentalRequest.id,
              status,
            },
          },
        })

        // console.log('update request', upd)

        // rentalRequestRefetch()
        // message.success('Rental Request has been updated')


        status === 8 && message.success(t('rental-request.main.rental-request-closed'))
        status === 2 && message.success(t('rental-request.main.rental-request-reopened'))
      } catch {
        message.error(t('rental-request.main.abstract-fail'))
        setIsLoading(false)
      }
    }
  }

  /** Actions */
  const [requestApartmentMutationRaw] = useMutation(RENTAL_REQUEST_APARTMENT_MUTATION)
  const requestApartmentMutation = async (...args) => {
    const response = await requestApartmentMutationRaw(...args)
    setTimeout(() => rerender(args), 150)
    setUpdatedAt(String(new Date()))
    return response
  }

  const declineApartmentHandler = async () => {
    setIsLoading(true)
    const apartment = declineApartment
    const requestApartment = apartment.request
    let response = null

    const variables = {
      input: {
        id: requestApartment.id,
        requestId: rentalRequest.id,
        apartmentId: apartment.id,
        reason: requestApartment.reason,
        status: null,
      },
    }

    setDeclineApartment(null)

    if (requestApartment.status === 0) {
      variables.input.status = 2
      response = await requestApartmentMutation({ variables })
      switchRentalRequestStatus(3)
    }

    if (requestApartment.status === 1) {
      if (isAdmin(role)) {
        variables.input.status = 4
        response = await requestApartmentMutation({ variables })
        switchRentalRequestStatus(4)
      }

      if (isLandlord(role)) {
        variables.input.status = 6
        response = await requestApartmentMutation({ variables })
        switchRentalRequestStatus(5)
      }
    }

    if (response?.data?.response) {
      Object.assign(apartment.request, response?.data?.response)
    }

    message.success(t('rental-request.main.apartment-rejected'))
    setIsLoading(false)
  }

  /** Landlord/Admin actions */
  const inquiryApartmentByLandlord = async (apartment) => {
    let variables = {}
    if (isAdmin(role)) {
      variables = {
        input: {
          id: apartment.request?.id,
          status: 3,
          reason: t('rental-request.main.sales-added'),
        },
      }
    }

    if (isLandlord(role)) {
      variables = {
        input: {
          id: apartment.request?.id,
          status: 5,
          reason: t('rental-request.main.sales-added'),
        },
      }
    } 

    try {
      const response = await requestApartmentMutation({ variables })
      if (response?.data?.response) {
        Object.assign(apartment.request, response?.data?.response)
      }

      if (isAdmin(role)) {
        await switchRentalRequestStatus(4)
      }

      if (isLandlord(role)) {
        await switchRentalRequestStatus(5)
      }

      // await rentalRequestRefetch()
      // await 

      message.success(t('rental-request.main.inquery-accepted'))
    } catch {
      message.error(t('rental-request.main.abstract-fail'))
    }

    setInquiryConfirmation(null)
  }

  const [createContractMutation] = useMutation(RENTAL_CONTRACT_UPSERT_MUTATION)
  const createContract = async (apartment) => {
    setIsLoading(true)
    const variables = {
      input: {
        tenantId: rentalRequest.tenantId,
        salesId: rentalRequest.salesId,
        rentalRequestId: rentalRequest.id,
        landlordId: apartment.building.profile.userId,
        apartmentId: apartment.id,
        dateFrom: null,
        dateTill: null,
        projectId: rentalRequest.projectId || null,
        wbsId: rentalRequest.wbsId || null,
        tenantEmployeeId: rentalRequest.tenantEmployeeId || null,
        tenantProfileId: rentalRequest.tenantProfileId || null,
        landlordProfileId: apartment.building.profile.id || null
      },
    }

    try {
      const response = await createContractMutation({ variables })
      await switchRentalRequestStatus(7)
      setContractSku(response?.data?.response?.sku)
      message.success(t('rental-request.main.contract-created'))
      navigate(`/rental-contracts-management/${response.data.response.sku}`)
    } finally {
      setIsLoading(false)
    }
  }

  const askInquiryPermission = async (apartment) => {
    try {
      setIsLoading(true)
      await requestApartmentMutation({
        variables: {
          input: {
            id: apartment.request.id,
            reason: t('rental-request.components.tenant-apartments-list.requestInquiry'),
            status: 10,
          },
        },
      })

      message.success(t('rental-request.components.tenant-apartments-list.inquery-success'))

      // if (rentalRequest?.status < 3) {
      //   await switchRentalRequestStatus(3)
      // }
    } finally {
      setIsLoading(false)
    }
  }

  const setRejectInquiryApartment = async (apartment) => {
    try {
      setIsLoading(true)
      await requestApartmentMutation({
        variables: {
          input: {
            id: apartment.request.id,
            reason: 'Tenant Manager Rejected inquiry',
            status: 12,
          },
        },
      })

      // message.success(t('rental-request.components.tenant-apartments-list.inquery-success'))

      // if (rentalRequest?.status < 3) {
      //   await switchRentalRequestStatus(3)
      // }
    } finally {
      setIsLoading(false)
    }
  }

  const setAcceptInquiryApartment = async (apartment) => {
    try {
      setIsLoading(true)
      await requestApartmentMutation({
        variables: {
          input: {
            id: apartment.request.id,
            reason: t('rental-request.components.tenant-apartments-list.acceptInquiryRequest'),
            status: 11,
          },
        },
      })

      // message.success(t('rental-request.components.tenant-apartments-list.inquery-success'))

      // if (rentalRequest?.status < 3) {
      //   await switchRentalRequestStatus(3)
      // }
    } finally {
      setIsLoading(false)
    }
  }

  const proposeForRent = async (apartment) => {
    let response
    try {
      if (apartment?.request) {
        response = await requestApartmentMutation({ variables: {
          input: {
            id: apartment?.request?.id,
            requestId: rentalRequest.id,
            apartmentId: apartment.id,
            status: 0,
            reason: '',
          }
        } })
      } else {
        response = await requestApartmentMutation({ variables: {
          input: {
            
            requestId: rentalRequest.id,
            apartmentId: apartment.id,
            status: 0,
            reason: '',
          }
        } })
      }
    

      if (response?.data?.response) {
        apartment.request = response?.data?.response
      }

      if (rentalRequest.status === 0 || rentalRequest.status === 3) {
        switchRentalRequestStatus(2)
      }

      message.success(t('rental-request.main.apartment-added'))
    } catch {
      message.error(t('rental-request.main.abstract-fail'))
    }
  }

  const [requestApartmentMutationDelete] = useMutation(RENTAL_REQUEST_APARTMENT_DELETE_MUTATION)
  const revokeProposal = async (apartment) => {
    try {
      await requestApartmentMutationDelete({ 
        variables: { id: apartment?.request?.id } 
      })

      setUpdatedAt(String(new Date()))

      apartment.request = null

      if (rentalRequest?.status < 2) {
        await switchRentalRequestStatus(2)
      }

      message.success(t('rental-request.main.apartment-has-been-revoked'))

    } catch {
      message.error(t('rental-request.main.abstract-fail'))
    }
  }

  /** Tenant actions */
  const inquiryApartmentByTenant = async (apartment) => {
    try {
      setIsLoading(true)
      await requestApartmentMutation({
        variables: {
          input: {
            id: apartment.request.id,
            reason: t('rental-request.components.tenant-apartments-list.inquery-reason'),
            status: 1,
          },
        },
      })

      message.success(t('rental-request.components.tenant-apartments-list.inquery-success'))

      if (rentalRequest?.status < 3) {
        await switchRentalRequestStatus(3)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const [sendInspectionData] = useMutation(SCHEDULE_INSPECTION)
  const inspectApartment = async (apartment) => {
    try {
      const response = await sendInspectionData({
        variables: {
          requestId: rentalRequest.id,
          apartmentId: apartment.id,
        },
      })

      if (response?.data?.response) {
        Object.assign(apartment.request, response?.data?.response)
      }

      message.success(t('rental-request.components.tenant-apartments-list.inspection-success'))
    } catch {
      message.error(t('rental-request.components.tenant-apartments-list.inspection-fail'))
    }
  }

  const rentApartment = async (apartment) => {
    try {
      
      await requestApartmentMutation({
        variables: {
          input: {
            id: apartment.request.id,
            reason: t('rental-request.components.tenant-apartments-list.decision-reason'),
            status: 7,
          },
        },
      })

      switchRentalRequestStatus(6)
      message.success(t('rental-request.components.tenant-apartments-list.decision-success'))

    } catch {
      message.error(t('rental-request.main.abstract-fail'))
    }
   
  }

  const askRentPermission = async (apartment) => {
    try {
      
      await requestApartmentMutation({
        variables: {
          input: {
            id: apartment.request.id,
            reason: t('rental-request.components.tenant-apartments-list.decision-reason'),
            status: 13,
          },
        },
      })

      // switchRentalRequestStatus(6)
      message.success(t('rental-request.components.tenant-apartments-list.decision-success'))

    } catch {
      message.error(t('rental-request.main.abstract-fail'))
    }
  }

  const setRejectRentApartment = async (apartment) => {

    try {
      
      await requestApartmentMutation({
        variables: {
          input: {
            id: apartment.request.id,
            reason: t('rental-request.components.tenant-apartments-list.decision-reason'),
            status: 15,
          },
        },
      })

      // switchRentalRequestStatus(6)
      message.success(t('rental-request.components.tenant-apartments-list.decision-success'))

    } catch {
      message.error(t('rental-request.main.abstract-fail'))
    }
  }

  const redirectToContract = () => {
    navigate(`/rental-contracts-management/${contractSku}`)
  }

  const setAcceptRentApartment = async (apartment) => {

    try {
      
      await requestApartmentMutation({
        variables: {
          input: {
            id: apartment.request.id,
            reason: t('rental-request.components.tenant-apartments-list.decision-reason'),
            status: 14,
          },
        },
      })

      // switchRentalRequestStatus(6)
      message.success(t('rental-request.components.tenant-apartments-list.decision-success'))

    } catch {
      message.error(t('rental-request.main.abstract-fail'))
    }
  }

  const setButtons = (apartment) => {
    let buttons

    const reqestApartmentStatus = apartment.request?.status
    
    if (isTenant(role)) {
      if (reqestApartmentStatus === 0) {
        if (apartment?.monthPrice <= rentalRequest.budget) {
          buttons =  <>
            <Button size="small" block type="ghost" style={{ marginBottom: 5 }} onClick={() => setDeclineApartment(apartment)}>
              {t('rental-request.components.tenant-apartments-list.actions.reject')}
            </Button>
      
            {/* Inquiry */}
            <Button size="small" block type="primary" onClick={() => { inquiryApartmentByTenant(apartment) }}>
              {t('rental-request.components.tenant-apartments-list.actions.Inquiry')}
            </Button>,
          </>
        } else  {
        
          buttons = <Button size="small" block type="ghost" style={{ marginBottom: 5 }} onClick={() => askInquiryPermission(apartment)}>
            {t('rental-request.components.tenant-apartments-list.actions.askManager')}
          </Button>  
        }
      }

      if (reqestApartmentStatus === 10 && isTenantManager(role)) {
        buttons =   <>
          {/* Reject */}
          <Button
            size="small"
            block
            type="dashed"
            style={{ marginBottom: 5 }}
            onClick={() => setRejectInquiryApartment(apartment)}
          >
            {t('rental-request.components.tenant-apartments-list.actions.rejectPR')}
          </Button>
    
          {/* Accept */}
          <Button
            size="small"
            block
            type="primary" onClick={() => setAcceptInquiryApartment(apartment)}
          >
            {t('rental-request.components.tenant-apartments-list.actions.acceptPR')}
          </Button>
        </>
      }

      if (reqestApartmentStatus === 13 && isTenantManager(role)) {
        buttons =   <>
          {/* Reject */}
          <Button
            size="small"
            block
            type="dashed"
            style={{ marginBottom: 5 }}
            onClick={() => setRejectRentApartment(apartment)}
          >
            {t('rental-request.components.tenant-apartments-list.actions.rejectRent')}
          </Button>
    
          {/* Accept */}
          <Button
            size="small"
            block
            type="primary" onClick={() => setAcceptRentApartment(apartment)}
          >
            {t('rental-request.components.tenant-apartments-list.actions.acceptRent')}
          </Button>
        </>
      }

      if (reqestApartmentStatus === 11) {
        buttons =  <>
          <Button size="small" block type="ghost" style={{ marginBottom: 5 }} onClick={() => setDeclineApartment(apartment)}>
            {t('rental-request.components.tenant-apartments-list.actions.reject')}
          </Button>
  
          {/* Inquiry */}
          <Button size="small" block type="primary" onClick={() => { inquiryApartmentByTenant(apartment) }}>
            {t('rental-request.components.tenant-apartments-list.actions.Inquiry')}
          </Button>,
        </>
      }

      if (reqestApartmentStatus === 7 && rentalRequest?.status === 7) {
        buttons =  <>
          <Button size="small" block type="ghost" style={{ marginBottom: 5 }} onClick={() => redirectToContract()}>
            {t('rental-request.components.tenant-apartments-list.actions.redirectToContract')}
          </Button>
  
         
        </>
      }

      if (!apartment.isBooked && [3, 5, 14].includes(reqestApartmentStatus) && [3, 4, 5, 6].includes(rentalRequest?.status)) { 
        if (isTenantManager(role) || reqestApartmentStatus === 14) {
          buttons =   <Popconfirm
            title={t('rental-request.components.tenant-apartments-list.confirm.title')}
            onConfirm={() => { rentApartment(apartment) }}
            okText={t('rental-request.components.tenant-apartments-list.confirm.content')}
            cancelText={t('rental-request.components.tenant-apartments-list.actions.cancel')}
          >
            <Button size="small" block type="primary">
              {t('rental-request.components.tenant-apartments-list.actions.rent')}
            </Button>
          </Popconfirm>
        } else {

          buttons = <>
            {/* <Button
              size="small"
              block
              type="ghost"
              disabled={apartment.request?.inspect}
              style={{ marginBottom: 5 }}
              onClick={() => inspectApartment(apartment)}
            >
              {t(`rental-request.components.tenant-apartments-list.actions.inspect.${apartment.request?.inspect ? 'true' : 'false'}`)}
            </Button> */}
  
            {/* Rent */}
            <Button
              size="small"
              block
              type="primary"
              onClick={() => askRentPermission(apartment)}
            >
              {t('rental-request.components.tenant-apartments-list.actions.askForRent')}
            </Button>
          </>

        }
       
      }


      if (apartment.isBooked) {
        buttons = <Button
          size="small"
          block
          type="danger"
          disabled="disabled"
          
        >
          {t('rental-request.components.all-apartments-list.actions.taken')}
        </Button>
      }
    }

    if (isAdmin(role)) {
      if (!apartment.request || [0, 2, 4, 6].includes(reqestApartmentStatus)) {
        if( ![2, 4, 6].includes(reqestApartmentStatus) && apartment.isBooked === false && apartment.request && !isLandlord(role)) {
          buttons = <Button
            size="small"
            type="link"
            block
            onClick={() => { revokeProposal(apartment) }}
          >
            {t('rental-request.components.all-apartments-list.actions.revoke')}
          </Button>
        } else {
          /* Propose */
          buttons = <Button
            size="small"
            type="primary"
            block
            onClick={() => { proposeForRent(apartment) }}
          >
            {t('rental-request.components.all-apartments-list.actions.propose')}
          </Button>
        }
      } else { 
        if (rentalRequest?.status < 7 && reqestApartmentStatus === 1) {
          buttons = <>
            {/* Reject */}
            <Button
              size="small"
              block
              type="dashed"
              style={{ marginBottom: 5 }}
              onClick={() => setDeclineApartment(apartment)}
            >
              {t('rental-request.components.all-apartments-list.actions.reject')}
            </Button>
      
            {/* Accept */}
            <Button
              size="small"
              block
              type="primary"
              onClick={() => setInquiryConfirmation(apartment)}
            >
              {t('rental-request.components.all-apartments-list.actions.accept')}
            </Button>
          </>
        }

        if (!apartment.isBooked && (rentalRequest?.status === 4 || rentalRequest?.status === 6) && reqestApartmentStatus === 7) {
          buttons = <Button
            size="small"
            block
            type="primary"
            onClick={() => { createContract(apartment) }}
          >
            {t('rental-request.components.all-apartments-list.actions.create')}
          </Button>
        }

        if (apartment.isBooked) {
          buttons = <Button
            size="small"
            block
            type="danger"
            disabled={true}
            
          >
            {t('rental-request.components.all-apartments-list.actions.taken')}
          </Button>
        }
      }
    }

    if (isLandlord(role)) {
      if (rentalRequest?.status < 7 && reqestApartmentStatus === 1) {
        buttons = <>
          {/* Reject */}
          <Button
            size="small"
            block
            type="dashed"
            style={{ marginBottom: 5 }}
            onClick={() => setDeclineApartment(apartment)}
          >
            {t('rental-request.components.all-apartments-list.actions.reject')}
          </Button>

          {/* Accept */}
          <Button
            size="small"
            block
            type="primary"
            onClick={() => inquiryApartmentByLandlord(apartment)}
          >
            {t('rental-request.components.all-apartments-list.actions.accept')}
          </Button>
        </>
      }
    }

    return buttons
  }


  return (
    <div className={styles.container}>
      {/* Form */}
      <Form 
        className={styles.side}
        form={form}
        layout="vertical"
        initialValues={{ period: { unlimited: true }, tenant: {} }}
        onFieldsChange={setHasChanges}
        onFinish={onSubmit}
      >
        <PageHeader
          className={styles.sideHeader}
          ghost={false}
          title={t('rental-request.main.rental-request-details')}
          tags={
            RENTAL_REQUESTS_STATUSES[rentalRequest?.status] ? (
              <Tooltip title={RENTAL_REQUESTS_STATUSES[rentalRequest?.status].description}>
                <Tag color={RENTAL_REQUESTS_STATUSES[rentalRequest?.status].color}>
                  {RENTAL_REQUESTS_STATUSES[rentalRequest?.status].title}
                </Tag>
              </Tooltip>
            ) : null
          }
          extra={[]}
        >
          {sku ? <Typography.Text type="secondary">#{sku.slice(0, 8)}</Typography.Text> : null}
        </PageHeader>

        {!isLandlord(role) ? ( 
          <>

            {/* Manager picker */}
            {isAdmin(role) ? (
              <Form.Item
                name="salesId"
                label={t('rental-request.components.sales-select.title')}
              >
                <UserSelect className={formDisabled ? 'ant-select-disabled' : ''} disabled={formDisabled} roles="b2b-managers" />
              </Form.Item>
            ) : null}

            {/* Employe */}
            <Form.Item
              name="tenant"
              label={t('rental-request.components.tenant-select.title')}
              rules={[{ required: true, validator: (_, value) => {
                if (!value.tenantId) {
                  return Promise.reject(new Error(t('rental-request.main.not-selected')))
                } 
                return Promise.resolve()
              } }]}
            >
              <TenantChainPicker disabled={formDisabled} />
            </Form.Item>

            {/* City picker */}
            <Form.Item
              name="city"
              label={t('rental-request.fields.city')}
            >
              <CityPicker disabled={formDisabled} />
            </Form.Item>
              
            {/* Period picker */}
            <Form.Item
              name="period"
              label={'Rent period'}
              rules={[{ required: true, validator: (_, value) => {
                if (!value.dateFrom) {
                  return Promise.reject(new Error(t('rental-request.main.select-date')))
                } 
                return Promise.resolve()
              } }]}
            >
              <DateRange disabled={formDisabled} />
            </Form.Item>

            {/* Budget */}
            <Form.Item
              name="budget"
              label={t('rental-request.fields.budget')}
            >
              <AmountInput disabled={formDisabled} />
            </Form.Item>

            {/* Notes */}
            <Form.Item
              name="details"
              label={t('rental-request.fields.details')}
            >
              <Input.TextArea disabled={formDisabled} />
            </Form.Item>

            {/* Submit */}
            {rentalRequest?.status >= 3 ? null : (
              <Badge color={hasChanges ? 'orange' : undefined} dot={hasChanges}>
                <Button loading={isLoading} type="primary" htmlType="submit" block>
                  {sku
                    ? t('rental-request.actions.update')
                    : t('rental-request.actions.create')}
                </Button>
              </Badge>
            )}

            {/* Close/Reopen request */}
            {!rentalRequest || rentalRequest?.status >= 3 ? null : (
              <>
                <Divider style={{ margin: '10px 0'}}/>
                <Button
                  type="text"
                  block
                  danger={rentalRequest.status !== 8}
                  onClick={() => switchRentalRequestStatus(rentalRequest.status === 8 ? 2 : 8)}
                  size="small"
                >
                  {rentalRequest.status === 8
                    ? t('rental-request.main.reopen-request')
                    : t('rental-request.main.close-request')}
                </Button>
              </>
            )}

            {rentalRequest?.sales ? (
              <>
                <Divider style={{ margin: '10px 0'}}/>
                <Typography.Title level={5}>{t('rental-request.main.employee-responsible')}</Typography.Title>
                <ContactBox user={rentalRequest.sales} />
                {/* <Divider style={{ margin: '10px 0'}}/>
                <Button 
                  type='dashed'
                  icon={<Eye />} 
                  onClick={() => openModal(ProposalPage, { query: rentalRequest })}
                >Export Proposal</Button> */}
              </>
            ) : null}
          </>
        ) : null}


        { isLandlord(role) 
          ? <>
            {/* Objectives */}
            <Typography.Title level={5}>
              <Calendar style={{ marginRight: 5 }} />
              {t('rentalRequest.fields.objectives')}
            </Typography.Title>

            {/* Date created */}
            <div>
              <Typography.Text strong>{t('rentalRequest.fields.created')}: </Typography.Text>
              {moment(rentalRequest?.createdAt).format('YYYY-MM-DD')}
            </div>
            <br />
            {/* Date from */}
            <div>
              <Typography.Text strong>{t('rentalRequest.fields.dateFrom')}: </Typography.Text>
              {moment(rentalRequest?.dateFrom).format('YYYY-MM-DD')}
            </div>

            {/* Date till */}
            <div>
              <Typography.Text strong>{t('rentalRequest.fields.dateTill')}: </Typography.Text>
              {rentalRequest?.dateTill
                ? moment(rentalRequest?.dateTill).format('YYYY-MM-DD')
                : 'Unlimited'}
            </div>
            <br />
            <div>
              <Typography.Text strong>{t('rentalRequest.fields.city')}: </Typography.Text>
              {rentalRequest?.city
                ? rentalRequest?.city
                : 'Undefined'}
            </div>


            <Divider />

            {rentalRequest?.sales ? (
              <ContactBox user={rentalRequest.sales} />
            ) : null}

            {/* <a
              onClick={() => openModal(ProposalPage, { sku: rentalRequest.sku })}
            >show proposal
            </a> */}
          </>
          : null
        }
      </Form>
    

      {/* Apartment's manager */}
      <div className={styles.content}>
        {!rentalRequest
          ? <Empty description={t('rental-request.components.tenant-apartments-list.empty')} /> 
          : (
            <ApartmentsManager
              // key={isAdmin(role) ? rentalRequest.id + formData.city + formData?.period?.dateFrom?.unix?.() : rentalRequest.id}
              refetch={updatedAt}
              fullpage={false}
              overlay={false}
              table={false}
              withoutCopy
              title="Apartments"
              hideFilters
              filterDefaultPreset="all"
              actions={
                RENTAL_REQUESTS_STATUSES[rentalRequest?.status] ? ([
                  <Alert
                    key="status"
                    style={{ width: '100%' }}
                    type={RENTAL_REQUESTS_STATUSES[rentalRequest?.status]?.alertType}
                    message={`${t('rental-request.components.all-apartments-list.current-status')} ${RENTAL_REQUESTS_STATUSES[rentalRequest?.status]?.description || RENTAL_REQUESTS_STATUSES[rentalRequest?.status]?.title}`}
                  />
                ]) : []}
              filterPresets={[
                {
                  title: t('rental-request.main.actions.history'),
                  slug: 'all',
                  value: {
                    rentalRequest: String(rentalRequest.id)
                  },
                },
                isAdmin(role) ? {
                  title: t('rental-request.main.actions.filter'),
                  slug: 'filter',
                  value: {
                    // ...(formData?.city ? { city: formData.city } : null),
                    ...({isBooked: false}),
                    ...(formData?.period?.freeFrom ? { freeFrom: String(formData?.period?.dateFrom?.unix?.()) } : null),
                    rentalRequest: String(rentalRequest.id * -1)
                  }
                } : null,
              ].filter(filter => !!filter)}
              tiles={({ item, context, handlers }) => {
                const reqestApartmentStatus = item.request?.status
           
                return (
                  (reqestApartmentStatus === 2 || reqestApartmentStatus === 11)  &&  isLandlord(role) ? null 
                    : <ApartmentTile 
                      key={item.id} 
                      item={item}
                      userRole={role} 
                      type="rr"
                      actions={setButtons(item)}
                    />
              
                )
              }}
            />
          )}

        {/* Decline model */}
        <Modal
          title={t('rental-request.components.tenant-decline-modal.title')}
          visible={!!declineApartment}
          onOk={declineApartmentHandler}
          onCancel={() => setDeclineApartment(null)}
          width="40%"
        >
          <Form form={form} layout="vertical">
            <Form.Item name={declineApartment?.id} label={declineApartment?.title}>
              <Input.TextArea style={{ height: '100%' }} onChange={(event) => { declineApartment.request.reason = event.target.value }} autoFocus />
            </Form.Item>
          </Form>
        </Modal>

        {/* Profile confirmation */}
        <Modal
          visible={!!inquiryConfirmation}
          onCancel={() => setInquiryConfirmation(null)}
          okText={
            inquiryConfirmation?.building?.profile
              ? t('rental-request.actions.accept-inquery')
              : t('rental-request.actions.update-building')
          }
          onOk={() =>
            inquiryConfirmation?.building?.profile
              ? inquiryApartmentByLandlord(inquiryConfirmation)
              : setInquiryConfirmation(null)
          }
        >
          {inquiryConfirmation?.building?.profile ? (
            <>
              <h2>{t('rental-request.main.approve-message')}</h2>
              <ProfileInfoCard
                profile={inquiryConfirmation?.building?.profile}
              />
            </>
          ) : (
            <>
              <h2>{t('rental-request.main.no-profile')}</h2>
              <Link to={`/buildings-management/${inquiryConfirmation?.building?.id}`}>
                <Button type="link" block htmlType="span">
                  {t('buildings.actions.edit')}
                </Button>
              </Link>
            </>
          )}
        </Modal>
      </div>
    </div>
  )
}

export { RentalRequestForm }
