import {
  Col,
  Form,
  Input,
  Modal,
  Row,
} from 'antd'
import React from 'react'

const LandlordDeclineModal = ({
  landlordDeclineModalVisible,
  landlordDeclineModalConfirmLoading,
  handleDeclineOk,
  handleDeclineCancel,
  declineForm,
  rentalRequestData,
  apartmentId,
}) => {
  const apartment = rentalRequestData?.apartmentsList.find((apt) => apt.id === apartmentId)
  return (
    <Modal
      title="Please provide the reason for rejection of the proposed apartments"
      visible={landlordDeclineModalVisible}
      confirmLoading={landlordDeclineModalConfirmLoading}
      onOk={handleDeclineOk}
      onCancel={handleDeclineCancel}
      width="40%"
    >
      {landlordDeclineModalVisible
        && apartment && (
        <Form form={declineForm} layout="vertical">
          <Row style={{ marginBottom: '12px' }} gutter={8}>
            <Col xs={24}>
              <Form.Item name={apartment?.id} label={apartment?.apartment.title}>
                <Input.TextArea style={{ height: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  )
}

export { LandlordDeclineModal }
