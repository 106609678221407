import { ROLE_GROUPS,roles } from '_graphql/_enums'
import AccountSupervisor from '@2fd/ant-design-icons/lib/AccountSupervisor'
import BadgeAccountHorizontal from '@2fd/ant-design-icons/lib/BadgeAccountHorizontal'
import Basket from '@2fd/ant-design-icons/lib/Basket'
import Book from '@2fd/ant-design-icons/lib/Book'
import CardSearch from '@2fd/ant-design-icons/lib/CardSearch'
import ClipboardList from '@2fd/ant-design-icons/lib/ClipboardListOutline'
import Cog from '@2fd/ant-design-icons/lib/Cog'
import Fireplace from '@2fd/ant-design-icons/lib/Fireplace'
import Folder from '@2fd/ant-design-icons/lib/Folder'
import HomeCity from '@2fd/ant-design-icons/lib/HomeCity'
import LightningBolt from '@2fd/ant-design-icons/lib/LightningBolt'
import TransitTransfer from '@2fd/ant-design-icons/lib/TransitTransfer'
import ViewDashboardOutline from '@2fd/ant-design-icons/lib/ViewDashboardOutline'
import { ContainerFilled, EuroCircleOutlined, FileDoneOutlined, PercentageOutlined  } from '@ant-design/icons'
import { ListAltTwoTone } from '@material-ui/icons'
import { createBrowserHistory } from 'history'
import i18next from 'i18next'
import React from 'react'
import {
  ApartmentDetails,
  ApartmentsManager,
  BillRunPage,
  BillRuns,
  BuildingDetails,
  BuildingsManager,
  Changelog,
  ComissionPayments,
  ComissionPaymentsPage,
  ContractForm,
  Dashboard,
  EntriesManager,
  InvoicePage,
  InvoicesManager,
  LogsManager,
  ProfileForm,
  ProfilesManager,
  ProjectPage,
  ProjectsManager,
  ProposalPage,
  RecurringPaymentPage,
  RecurringPayments,
  RentalContractDetails,
  RentalContracts,
  RentalRequestForm,
  RentalRequestLandlord,
  RentalRequests,
  ReportsManager,
  ServiceForm,
  ServiceRequests,
  ServiceRequestView,
  ServicesManager,
  ServiceView,
  TransactionDetails,
  TransactionForm,
  TransactionsManager,
  UserDetails,
  UserForm,
  UserFormCreate,
  UsersManager,
  WbsManager,
  WbsView,
} from 'screens'

export const history = createBrowserHistory()

export const DEFAULT_ROUTE = {
  default: '/dashboard',
}

export const navSetup = [
  {
    get title() { return i18next.t('routing.dashboard') },
    icon: <ViewDashboardOutline />,
    screen: Dashboard,
    route: '/dashboard',
    badgeCount: 0,
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('routing.rental-requests') },
    counter: 'rental-requests',
    icon: <LightningBolt />,
    screen: RentalRequests,
    route: '/rental-requests-management',
    badgeCount: 0,
    group: 'booking',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('routing.rental-requests-sku') },
    icon: <LightningBolt />,
    screen: RentalRequestLandlord,
    route: '/landlord/rental-requests-management/:sku',
    sidebarSkip: true,
    badgeCount: 0,
    group: 'booking',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD],
  },
  {
    get title() { return i18next.t('routing.rental-requests-edit') },
    icon: <LightningBolt />,
    screen: RentalRequestForm,
    route: '/rental-requests-management/:sku',
    sidebarSkip: true,
    badgeCount: 0,
    group: 'booking',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  },
  // {
  //   get title() { return i18next.t('routing.rental-requests-edit') },
  //   icon: <LightningBolt />,
  //   screen: ProposalPage,
  //   route: '/rental-proposal/:sku',
  //   sidebarSkip: true,
  //   badgeCount: 0,
  //   group: 'booking',
  //   roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  // },
  {
    get title() { return i18next.t('routing.rental-requests-create') },
    icon: <LightningBolt />,
    screen: RentalRequestForm,
    route: '/rental-requests-management/create',
    sidebarSkip: true,
    badgeCount: 0,
    group: 'booking',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('routing.contracts') },
    icon: <Book />,
    screen: RentalContracts,
    counter: 'rental-contracts',
    route: '/rental-contracts-management',
    badgeCount: 0,
    group: 'booking',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('routing.service-requests') },
    icon: <Cog />,
    screen: ServiceRequests,
    counter: 'service-requests',
    route: '/service-requests',
    badgeCount: 0,
    group: 'service',
    sidebarSkip: false,
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('routing.service-requests') },
    sidebarSkip: true,
    screen: ServiceRequestView,
    route: '/service-requests/create',
    badgeCount: 0,
    group: 'service',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('routing.service-requests') },
    sidebarSkip: true,
    screen: ServiceRequestView,
    route: '/service-requests/view/:id',
    badgeCount: 0,
    group: 'service',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('routing.contracts-details') },
    sidebarSkip: true,
    screen: RentalContractDetails,
    route: '/rental-contracts-management/:sku',
    badgeCount: 0,
    group: 'booking',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('routing.contracts-details') },
    sidebarSkip: true,
    screen: ContractForm,
    route: '/rental-contracts-management/create',
    badgeCount: 0,
    group: 'booking',
    roles: [...ROLE_GROUPS.B2B],
  },
  {
    id: 'apartments-management',
    get title() { return i18next.t('routing.apartments') },
    icon: <Fireplace />,
    screen: ApartmentsManager,
    route: '/apartments-management',
    badgeCount: 0,
    group: 'property',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD].filter(role => ([roles.LANDLORD_OWNER, roles.LANDLORD_MANAGER, roles.LANDLORD_CONTENT, roles.B2B_ADMIN,  roles.B2B_SALES, roles.B2B_TRAINEE].includes(role))),
  },
  {
    get title() { return i18next.t('routing.apartments-details') },
    screen: ApartmentDetails,
    route: '/apartments-management/:id',
    sidebarSkip: true,
    group: 'property',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD],
  },
  {
    get title() { return i18next.t('routing.apartments-sku') },
    screen: ApartmentDetails,
    route: '/apartments-management/sku/:sku',
    sidebarSkip: true,
    group: 'property',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  },
  {
    id: 'buildings-management',
    get title() { return i18next.t('routing.buildings') },
    icon: <HomeCity />,
    screen: BuildingsManager,
    route: '/buildings-management',
    badgeCount: 0,
    group: 'property',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD].filter(role => ([roles.LANDLORD_OWNER, roles.LANDLORD_MANAGER, roles.LANDLORD_CONTENT, roles.B2B_ADMIN,  roles.B2B_SALES, roles.B2B_TRAINEE].includes(role))),
  },
  
  {
    get title() { return i18next.t('routing.buildings-details') },
    screen: BuildingDetails,
    route: '/buildings-management/:id',
    sidebarSkip: true,
    group: 'property',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD],
  },
  {
    get title() { return i18next.t('projects.table.title') },
    icon: <Folder />,
    screen: ProjectsManager,
    sidebarSkip: false,
    route: '/projects-manager',
    group: 'projects',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.TENANT].filter(role => ([roles.B2B_ADMIN,  roles.B2B_SALES, roles.TENANT_ADMIN, roles.TENANT_MANAGER, roles.TENANT_PROJECT_MANAGER].includes(role))),
  },
  {
    get title() { return i18next.t('projects.table.createProject') },
    screen: ProjectPage,
    route: '/projects-management/create',
    sidebarSkip: true,
    group: 'projects',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('projects.table.createProject') },
    screen: ProjectPage,
    route: '/projects-management/:sku',
    sidebarSkip: true,
    group: 'projects',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('routing.users') },
    icon: <AccountSupervisor />,
    screen: UsersManager,
    route: '/users-management',
    group: 'users',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT].filter(role => (![roles.TENANT_EMPLOYEE].includes(role))),
  },
  {
    get title() { return i18next.t('routing.users-edit') },
    screen: UserForm,
    sidebarSkip: true,
    route: '/users-management/:id',
    group: 'users',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT].filter(role => (![roles.TENANT_EMPLOYEE].includes(role))),
  },
  {
    get title() { return i18next.t('routing.users-create') },
    screen: UserFormCreate,
    sidebarSkip: true,
    route: '/users-management/create',
    group: 'users',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT].filter(role => (![roles.TENANT_EMPLOYEE].includes(role))),
  },
  {
    get title() { return i18next.t('routing.current-user-details') },
    screen: UserDetails,
    route: '/my-details',
    sidebarSkip: true,
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('routing.profiles') },
    screen: ProfilesManager,
    route: '/profiles-management',
    icon: <BadgeAccountHorizontal />,
    group: 'users',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT].filter(role => (![roles.TENANT_EMPLOYEE].includes(role))),
  },
  {
    get title() { return i18next.t('routing.profiles-create') },
    screen: ProfileForm,
    route: '/profiles-management/create',
    sidebarSkip: true,
    group: 'users',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT].filter(role => (![roles.TENANT_EMPLOYEE, roles.TENANT_PROJECT_MANAGER].includes(role))),
  },
  {
    get title() { return i18next.t('routing.profiles-edit') },
    screen: ProfileForm,
    route: '/profiles-management/:id',
    sidebarSkip: true,
    group: 'users',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD, ...ROLE_GROUPS.TENANT].filter(role => (![roles.TENANT_EMPLOYEE].includes(role))),
  },
 
  {
    get title() { return i18next.t('routing.logs') },
    icon: <ListAltTwoTone />,
    screen: LogsManager,
    route: '/logs-management',
    sidebarSkip: true,
    badgeCount: 0,
    group: 'accounting',
    roles: [...ROLE_GROUPS.B2B],
  },

  // {
  //   get title() { return i18next.t('routing.invoices'); },
  //   screen: InvoicesManager,
  //   icon: <LightningBolt />,
  //   route: '/invoices-management',
  //   sidebarSkip: false,
  //   roles: [...ROLE_GROUPS.B2B,  ...ROLE_GROUPS.LANDLORD],
  // },

  // Invoice Section

  {
    get title() { return i18next.t('routing.invoices') },
    screen: InvoicesManager,
    icon: <ContainerFilled />,
    route: '/invoices-management',
    sidebarSkip: false,
    group: 'accounting',
    roles: [...ROLE_GROUPS.B2B].filter(role => (![roles.TENANT_ACCOUNTANT, roles.LANDLORD_ACCOUNTANT].includes(role))),
  },

  {
    get title() { return i18next.t('routing.invoiceCreate') },
    screen: InvoicePage,
    route: '/invoices-management/create',
    sidebarSkip: true,
    group: 'accounting',
    roles: [...ROLE_GROUPS.B2B],
  },
  {
    get title() { return i18next.t('routing.invoiceEdit') },
    screen: InvoicePage,
    route: '/invoices-management/:sku',
    sidebarSkip: true,
    group: 'accounting',
    roles: [...ROLE_GROUPS.B2B],
  },

  // Bill  Run Section

  {
    get title() { return i18next.t('routing.billRuns') },
    icon: <Basket />,
    screen: BillRuns,
    route: '/bill-runs',
    sidebarSkip: false,
    group: 'accounting',
    roles: [...ROLE_GROUPS.B2B].filter(role => (![roles.TENANT_ACCOUNTANT, roles.LANDLORD_ACCOUNTANT].includes(role))),
  },

  {
    get title() { return i18next.t('routing.billRuns') },
    icon: <Basket />,
    screen: BillRunPage,
    route: '/bill-runs/:sku',
    sidebarSkip: true,
    group: 'accounting',
    roles: [...ROLE_GROUPS.B2B],
  },

  {
    get title() { return i18next.t('routing.recurring') },
    icon: <EuroCircleOutlined />,
    screen: RecurringPayments,
    route: '/recurring-payments',
    sidebarSkip: false,
    group: 'accounting',
    roles: [...ROLE_GROUPS.B2B].filter(role => (![roles.TENANT_ACCOUNTANT, roles.LANDLORD_ACCOUNTANT].includes(role))),
  },


  {
    get title() { return i18next.t('routing.comission') },
    icon: <PercentageOutlined />,
    screen: ComissionPayments,
    route: '/comission-payments',
    sidebarSkip: false,
    group: 'accounting',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.LANDLORD].filter(role => (![roles.LANDLORD_CONTENT, roles.LANDLORD_MANAGER].includes(role))),
  },

  {
    get title() { return i18next.t('routing.recurringPage') },
    screen: ComissionPaymentsPage,
    route: '/comission-payments/:sku',
    sidebarSkip: true,
    group: 'accounting',
    roles: [...ROLE_GROUPS.B2B],
  },

  {
    get title() { return i18next.t('routing.recurringPage') },
    screen: RecurringPaymentPage,
    route: '/recurring-payments/:sku',
    sidebarSkip: true,
    group: 'accounting',
    roles: [...ROLE_GROUPS.B2B],
  },

  // {
  //   get title() { return i18next.t('routing.recurringPage') },
  //   screen: BillRunPage,
  //   route: '/bill-runs/:sku',
  //   sidebarSkip: true,
  //   group: 'accounting',
  //   roles: [...ROLE_GROUPS.B2B],
  // },

  // {
  //   get title() { return i18next.t('routing.billCreate') },
  //   screen: BillForm,
  //   route: '/bills-management/create',
  //   sidebarSkip: true,
  //   group: 'accounting',
  //   roles: [...ROLE_GROUPS.B2B],
  // },
  // {
  //   get title() { return i18next.t('routing.billEdit') },
  //   screen: BillForm,
  //   route: '/bills-management/:id',
  //   sidebarSkip: true,
  //   group: 'accounting',
  //   roles: [...ROLE_GROUPS.B2B],
  // },

  // Transactions Section

  {
    get title() { return i18next.t('routing.transactions') },
    screen: TransactionsManager,
    icon: <TransitTransfer />,
    route: '/transactions-management',
    sidebarSkip: true,
    group: 'booking',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.TENANT],
  },

  {
    get title() { return i18next.t('transactions.form.new') },
    screen: TransactionForm,
    route: '/transactions-management/create',
    sidebarSkip: true,
    group: 'booking',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.TENANT],
  },
  {
    get title() { return i18next.t('transactions.table.actions.edit') },
    screen: TransactionDetails,
    route: '/transactions-management/:id',
    sidebarSkip: true,
    group: 'booking',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.TENANT],
  },

  // WBS Section

  {
    get title() { return i18next.t('wbs.table.title') },
    screen: WbsManager,
    icon: <CardSearch />,
    route: '/wbs-management',
    sidebarSkip: true,
    group: 'projects',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.TENANT],
  },

  {
    get title() { return i18next.t('wbs.form.new') },
    screen: WbsView,
    route: '/wbs-management/create',
    sidebarSkip: true,
    group: 'projects',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.TENANT],
  },


  {
    get title() { return i18next.t('wbs.form.view') },
    screen: WbsView,
    route: '/wbs-management/:sku',
    sidebarSkip: true,
    group: 'projects',
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.TENANT],
  },


  {
    get title() { return i18next.t('changelog.page.title') },
    screen: Changelog,
    icon: <ClipboardList />,
    route: '/changelog',
    sidebarSkip: true,
    roles: [...ROLE_GROUPS.B2B],
  },

  // Contract Services

  {
    get title() { return i18next.t('service.table.title') },
    screen: ServicesManager,
    icon: <CardSearch />,
    route: '/contract-services',
    sidebarSkip: true,
    group: 'projects',
    roles: [...ROLE_GROUPS.B2B],
  },

  {
    get title() { return i18next.t('services.new') },
    screen: ServiceForm,
    route: '/contract-services/create',
    sidebarSkip: true,
    group: 'projects',
    roles: [...ROLE_GROUPS.B2B],
  },


  {
    get title() { return i18next.t('wbs.form.view') },
    screen: ServiceView,
    route: '/contract-services/:sku',
    sidebarSkip: true,
    group: 'projects',
    roles: [...ROLE_GROUPS.B2B],
  },

  {
    get title() { return i18next.t('entries.form.title') },
    screen: EntriesManager,
    icon: <CardSearch />,
    route: '/rp-entries',
    sidebarSkip: true,
    group: 'projects',
    roles: [...ROLE_GROUPS.B2B],
  },

  {
    get title() { return i18next.t('report.form.title') },
    screen: ReportsManager,
    icon: <CardSearch />,
    route: '/reports-manager',
    sidebarSkip: false,
    roles: [...ROLE_GROUPS.B2B, ...ROLE_GROUPS.TENANT].filter(role => ([roles.B2B_ADMIN,  roles.B2B_SALES, roles.TENANT_ADMIN, roles.TENANT_MANAGER, roles.TENANT_PROJECT_MANAGER].includes(role))),
  },


]
