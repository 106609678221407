import { Tooltip } from 'antd'
import React from 'react'

import { AmenityWrapper,Icon } from './styled'

const RoomTypes = ({ amenity, AmenityIcon, isSelected, onTypeChange }) => (
  <Tooltip title={amenity.label} key={amenity.label}>
    <AmenityWrapper isSelected={isSelected} onClick={onTypeChange}>
      {AmenityIcon && <Icon><AmenityIcon /></Icon>}
    </AmenityWrapper>
  </Tooltip>
)

export { RoomTypes }
