import { projectContext } from 'common/ProjectProvider'

function rgbaLogShade(rgba, coefficient) {
  const i = parseInt
  const r = Math.round
  const [a, b, c, d] = rgba.split(',')
  const PBool = coefficient < 0
  const t = PBool ? 0 : coefficient * 255 ** 2
  const P = PBool ? 1 + coefficient : 1 - coefficient
  return `rgba(${r((P * i(a[3] === 'a' ? a.slice(5) : a.slice(4)) ** 2 + t) ** 0.5)},${r(
    (P * i(b) ** 2 + t) ** 0.5,
  )},${r((P * i(c) ** 2 + t) ** 0.5)},${d}`
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null
}

export const colors = {
  main: (shade = 0, alpha = 1) => {
    const primaryColor = hexToRgb(projectContext.primaryColor)
    return rgbaLogShade(`rgba(${primaryColor.r}, ${primaryColor.g}, ${primaryColor.b}, ${alpha})`, shade)
  },
  text: (shade = 0, alpha = 1) => rgbaLogShade(`rgba(89, 89, 89, ${alpha})`, shade),

  darkGray: (shade = 0, alpha = 1) => rgbaLogShade(`rgba(120, 120, 120, ${alpha})`, shade),
  gray: (shade = 0, alpha = 1) => rgbaLogShade(`rgba(160, 160, 160, ${alpha})`, shade),
  lightGray: (shade = 0, alpha = 1) => rgbaLogShade(`rgba(200, 200, 200, ${alpha})`, shade),
  veryLightGray: (shade = 0, alpha = 1) => rgbaLogShade(`rgba(229, 229, 229, ${alpha})`, shade),
  green: (shade = 0, alpha = 1) => rgbaLogShade(`rgba(82, 196, 26, ${alpha})`, shade),
}
