import { Badge, Table } from 'antd'
import React from 'react'

import * as DefaultTypes from './DefaultTypes'

/**
 * Рендер таблицей
 */
export const TableRenderMode = ({ table, data, dataRefetch, handlers, lastSeen, lastSeenData }) => {
  const context = { dataRefetch }

  const columns = table.columns
    .filter((column) => (column.middleware === undefined || column.middleware === true || (column.middleware !== false && column.middleware())))
    .map((column) => ({
      title: column.title,
      key: column.key,
      width: column.width,
      className: column.className,
      fixed: column.fixed,
      render: (_text, record) => {
        const Handler = typeof column.render !== 'string' ? column.render : DefaultTypes[column.render] ?? DefaultTypes.string

        const content = <Handler {...{ value: record[column.key], options: column.options, item: record, key: column.key, context }} />

        if (lastSeen && column.key === 'sku' && 'id' in record && 'updatedAt' in record) {
          const unreaded = record.updatedAt !== lastSeenData.find(itemRight => itemRight.id === record.id)?.updatedAt

          return (
            <Badge key={column.key + ':badge'} color={unreaded ? 'orange' : 'white'} dot={unreaded}>
              {content}
            </Badge>
          )
        }

        return content
      },
    }))

  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={data}
      size="small"
      rowKey={table?.row?.rowKey ?? 'id'}
      expandable={table?.row?.expandable ? {
        expandedRowRender: (record) => table.row.expandable({ item: record, context }),
        rowExpandable: () => true,
      } : undefined}
      onRow={(item) => Object.fromEntries(Object.entries(handlers).map(([name, handler]) => [name, () => handler({ item })]))}
      scroll={table?.scroll || { x: 900, y: 430 }}
    />
  )
}
