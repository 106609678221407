import { colors } from '_colors'
import styled from 'styled-components'

export const RoomWrapper = styled.div`
  border: 1px solid ${colors.veryLightGray()};
  border-radius: 2px;
  padding: 10px;
`

export const Icon = styled.div`
  transition: fill 0.3s, transform 0.3s;
`

export const AmenityWrapper = styled.div`
  padding: 8px;
  width: 60px;
  border: solid 1px ${(p) => (p.isSelected ? colors.main() : colors.veryLightGray())};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px;

  transition: border-color 0.3s;

  ${Icon} {
    fill: ${(p) => (p.isSelected ? colors.main(0, 1) : colors.lightGray(0, 1))};
  }
`
