import { colors } from '_colors'
import {
  RENTAL_REQUEST_APARTMENT_MUTATION,
  RENTAL_REQUEST_SKU_QUERY,
  RENTAL_REQUEST_UPSERT_MUTATION,
} from '_graphql'
import { RENTAL_REQUESTS_STATUSES } from '_graphql/_enums'
import { CheckOutlined } from '@ant-design/icons'
import {
  BankOutlined,
  CrownOutlined,
  EllipsisOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useParams } from '@reach/router'
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  message,
  Row,
  Space,
  Spin,
  Tabs,
} from 'antd'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApartmentTile } from 'screens/ApartmentsManager/ApartmentTile'
import { useGlobalState } from 'state'

import { LandlordDeclineModal } from './LandlordDeclineModal'

const RentalRequestLandlord = () => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()
  const role = state.currentUser?.role
  const { sku } = useParams()
  const [declineForm] = Form.useForm()

  const { TabPane } = Tabs

  const [declineModalVisible, setDeclineModalVisible] = useState(false)
  const [declineList, setDeclineList] = useState([])
  const [acceptList, setAcceptList] = useState([])
  const [apartmentId, setApartmentId] = useState()

  const [rentalRequestMutation] = useMutation(RENTAL_REQUEST_UPSERT_MUTATION)
  const [requestApartmentMutation] = useMutation(
    RENTAL_REQUEST_APARTMENT_MUTATION
  )

  const {
    data: requestData,
    loading: requestLoading,
    refetch: requestRefetch,
    error: requestError,
  } = useQuery(RENTAL_REQUEST_SKU_QUERY, {
    variables: {
      sku,
    },
  })

  const userId = state.currentUser?.id

  const rentalReq = requestData?.response

  if (!rentalReq) return null

  const apts = requestData?.response?.apartmentsList.filter(
    (apt) => apt?.apartment?.building?.userId === userId
  )

  const changeRentalRequestStatus = async (status) => {
    await rentalRequestMutation({
      variables: {
        input: {
          id: requestData.response.id,
          status,
        },
      },
    })
  }

  const rejectRentalRequest = () => {
    setDeclineModalVisible(true)
  }

  const acceptRentalRequest = async () => {
    if (declineList.length) {
      rejectRentalRequest(true)
      return
    }
    message.success( t('rentalRequest.messages.requestAccept') )

    await rentalRequestMutation({
      variables: {
        input: {
          id: rentalReq.id,
          status: 9,
        },
      },
    })

    // navigate('/landlord/rental-requests-management');
  }

  const handleDeclineOk = async () => {
    setDeclineModalVisible(true)

    const requestApartment = rentalReq?.apartmentsList.find(
      (apt) => apt.id === apartmentId
    )
    const declineData = declineForm.getFieldsValue(requestApartment)

    const variables = {
      input: {
        id: requestApartment.id,
        requestId: rentalReq.id,
        apartmentId: requestApartment.apartmentId,
        reason: declineData[requestApartment.id],
        status: null,
      },
    }

    setDeclineModalVisible(false)

    if (requestApartment.status === 1) {
      variables.input.status = 6
      await requestApartmentMutation({ variables })
      changeRentalRequestStatus(5)
    }

    message.success(t('rentalRequest.messages.requestReject') )
    requestRefetch()
  }

  const handleDeclineCancel = () => {
    setDeclineModalVisible(false)
  }

  const declineInquiry = (id) => {
    setApartmentId(id)
    setDeclineModalVisible(true)
  }

  const acceptInquiry = async (id) => {
    const variables = {
      input: {
        id,
        status: 5,
        reason: t('rentalRequest.messages.requestAcceptSales'),
      },
    }

    const response = await requestApartmentMutation({ variables })

    if (!response.errors) {
      message.success(t('rentalRequest.messages.requestAccept'))
      changeRentalRequestStatus(5)
    } else {
      message.error(t('rentalRequest.messages.error'))
    }
    requestRefetch()
  }

  const finals = apts.find((apt) => apt.status === 7)

  const createButtons = (apt) => {
    if (
      (rentalReq.status === 3 ||
        rentalReq.status === 4 ||
        rentalReq.status === 5) &&
      apt.status === 1
    ) {
      return [
        <Button
          key={9983}
          type="dashed"
          onClick={(e) => {
            e.stopPropagation()
            declineInquiry(apt.id)
          }}
        >
          {t('rentalRequest.buttons.reject')} 
        </Button>,
        <Button
          key={9982}
          type="primary"
          onClick={(e) => {
            e.stopPropagation()
            acceptInquiry(apt.id)
          }}
        >
          {t('rentalRequest.buttons.accept')} 
        </Button>,
      ]
    }
  }

  const showAlert = (apt) => {
    if (apt.status === 2) {
      return [
        <Alert
          key={9981}
          message={t('rentalRequest.messages.tenantReject')} 
          type="warning"
          showIcon
        />,
      ]
    }

    if (apt.status === 3) {
      return [
        <Alert
          key={9980}
          message={t('rentalRequest.messages.inquirySalesAccept')} 
          type="success"
          showIcon
        />,
      ]
    }

    if (apt.status === 5) {
      return [
        <Alert
          key={9979}
          message={t('rentalRequest.messages.inquiryAccept')} 
          type="success"
          showIcon
        />,
      ]
    }

    if (apt.status === 4) {
      return [
        <Alert
          key={9978}
          message={t('rentalRequest.messages.inquirySalesReject')} 
          type="warning"
          showIcon
        />,
      ]
    }

    if (apt.status === 6) {
      return [
        <Alert
          key={9977}
          message={t('rentalRequest.messages.inquiryReject')} 
          description={apt.reason}
          type="warning"
          showIcon
        />,
      ]
    }

    if (apt.status === 7) {
      return [
        <Alert
          key={9976}
          message={t('rentalRequest.messages.requestSucces')} 
          description={t('rentalRequest.messages.requestSuccessDesc')} 
          type="success"
          showIcon
        />,
      ]
    }
  }

  return (
    <>
      <LayoutOverlay>
        <Row gutter={[20, 20]}>
          <Col xs={16} style={{ textAlign: 'left' }}>
            <h2 style={{ fontSize: '24px' }}>
              {t('rentalRequest.main.title')} #{rentalReq?.sku.slice(0, 8)}
            </h2>
          </Col>

          <Col xs={8}>
            {rentalReq.status === 3 ? (
              <Alert
                message={t('rentalRequest.messages.requestWaiting')}
                description={t('rentalRequest.messages.requestWaitingDesc')}
                type="info"
                showIcon
              />
            ) : rentalReq.status === 4 ? (
              <Alert
                message={t('rentalRequest.messages.salesResponded')}
                description={t('rentalRequest.messages.salesRespondedDesc')}
                type="info"
                showIcon
              />
            ) : rentalReq.status === 5 ? (
              <Alert
                message={t('rentalRequest.messages.landlordResponded')}
                description={t('rentalRequest.messages.landlordRespondedDesc')}
                type="success"
                showIcon
              />
            ) : rentalReq.status === 6 && finals !== undefined ? (
              <Alert
                message={t('rentalRequest.messages.tenantResponded')}
                description={t('rentalRequest.messages.tenantRespondedDesc')}
                type="success"
                showIcon
              />
            ) : rentalReq.status === 7 && finals !== undefined ? (
              <Alert
                message={t('rentalRequest.messages.contractCreatedSuccess')}
                description={t('rentalRequest.messages.contractCreatedSuccessDesc')}
                type="success"
                showIcon
              />
            ) : (
              <Alert
                message={t('rentalRequest.messages.contractCreatedError')}
                description={t('rentalRequest.messages.contractCreatedErrorDesc')}
                type="info"
                showIcon
              />
            )}
          </Col>
        </Row>

        <Tabs type="card">
          <TabPane tab={t('rentalRequest.tabs.general')} key="1">
            <Row gutter={[12, 12]}>
              <Col xs={16}>
                <LayoutCard style={{ marginBottom: '12px' }}>
                  <Row style={{ marginBottom: '12px' }}>
                    <Col xs={8} style={{ textAlign: 'left' }}>
                      <h3 style={{ fontSize: '20px' }}>
                        {t('rentalRequest.main.requestDetails')}:
                      </h3>
                      <span style={{ fontSize: '14px' }}>
                        <b>{t('rentalRequest.fields.created')}</b>:{' '}
                        {moment(rentalReq.createdAt).format('LL')}
                      </span>
                      <br></br>
                      <span style={{ fontSize: '14px' }}>
                        <b>{t('rentalRequest.fields.city')}: </b>
                        {rentalReq?.city}
                      </span>
                    </Col>

                    <Col xs={8}>
                      <h3 style={{ fontSize: '20px' }}>
                        {t('rentalRequest.main.scope')}
                      </h3>
                      <span style={{ fontSize: '14px' }}>
                        <b>{t('rentalRequest.fields.bookingStart')}:</b>{' '}
                        {moment(rentalReq?.dateFrom).format('LL')}
                      </span>
                      <br></br>
                      <span style={{ fontSize: '14px' }}>
                        <b>{t('rentalRequest.fields.bookingEnd')}</b>:{' '}
                        {rentalReq.dateTill != null
                          ? moment(rentalReq?.dateTill).format('LL')
                          : t('rentalRequest.fields.unlimited')}
                      </span>
                    </Col>
                    <Col xs={8}>
                      <h3 style={{ fontSize: '20px' }}>
                        {t('rentalRequest.main.agentTitle')}
                      </h3>
                      <span style={{ fontSize: '14px' }}>
                        <b>{t('rentalRequest.fields.agentName')}:</b>{' '}
                        {rentalReq?.sales?.lastName}{' '}
                        {rentalReq?.sales?.firstName}
                      </span>
                      <br></br>
                      <span style={{ fontSize: '14px' }}>
                        <b>{t('rentalRequest.fields.agentContact')}:</b>{' '}
                        {rentalReq?.sales?.email}
                      </span>
                    </Col>
                  </Row>
                </LayoutCard>
                {apts.map((apt, index) => (
                  <ApartmentTile
                    key={index}
                    noControls={rentalReq.status !== 7}
                    item={apt.apartment}
                    onClick={() =>
                      window.open(
                        `/apartments-management/${apt.apartment.id}`,
                        '_blank'
                      )
                    }
                    controls={createButtons(apt)}
                  >
                    {apt.inspect === true && (
                      <>
                        <Alert
                          message="Tenant Request"
                          description="Tenant requested apartment Inspection. Contact tenant in order to schedule an Inspection."
                          type="info"
                          showIcon
                          style={{ marginBottom: '10px' }}
                        />
                      </>
                    )}
                    {showAlert(apt)}
                  </ApartmentTile>
                ))}
              </Col>
              <Col xs={8}>
                <LayoutCard style={{ marginBottom: '12px' }}>
                  <h1>
                    <b>{t('rentalRequest.main.notifications')}:</b>

                    {t('rentalRequest.main.inDev')}
                  </h1>
                </LayoutCard>
              </Col>
            </Row>
          </TabPane>
          <TabPane
            tab={t('rentalRequest.tabs.correspondance')}
            disabled
            key="2"
          >
            <Row gutter={[20, 20]}>
              <Col xs={18}>yo</Col>
            </Row>
          </TabPane>
        </Tabs>

        <LandlordDeclineModal
          landlordDeclineModalVisible={declineModalVisible}
          handleDeclineOk={handleDeclineOk}
          handleDeclineCancel={handleDeclineCancel}
          declineForm={declineForm}
          rentalRequestData={rentalReq}
          declineList={declineList}
          apartmentId={apartmentId}
        />
      </LayoutOverlay>
    </>
  )
}

export { RentalRequestLandlord }
