import { gql } from 'apollo-boost'

export const apartmentFragment = gql`
  fragment apartmentFragment on Apartment {
    id
    title
    code
    deposit
    freeFrom
    minRentalPeriod
    maxRentalPeriod
    monthPrice
    floor
    utilitiesPrice
    rooms
    slots
    extras
    squareMeters
    description
    isActive
    isPublished
    buildingId
    sku
    roomOptions
    amenitiesOptions
    side
    isStudio
    isBooked
    request {
      id
      requestId
      apartmentId
      inspect
      status
      reason
    }
  }
`
