import { Form,Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DescriptionEdit = () => {
  const { t } = useTranslation()
  return (
    <Form.Item label={t('apartments.sections.apartment-description')} name="description">
      <Input.TextArea autoSize={{ minRows: 3 }} />
    </Form.Item>
  )
}

const DescriptionView = ({ data }) => {
  const { t } = useTranslation()
  return (
    <>
      <h2>{t('apartments.sections.property-description')}</h2>
      {data?.description.split('\n').map((d, i) => (
        d !== '' && (
          <React.Fragment key={i}>
            <p style={{ lineHeight: '25px'}}>{d}</p>
          </React.Fragment>
        )
      ))}
    </>
  )
}

export { DescriptionEdit, DescriptionView }
