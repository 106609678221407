import { navigate } from '@reach/router'
import { Button,Divider } from 'antd'
import { GalleryRO } from 'components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { parseJson } from 'utils/parseJson'

const AptBuildingInfo = ({ buildingData }) => {
  const { t } = useTranslation()

  if (!buildingData) {
    return (
      <h4>{t('apartments.noBuilding')}</h4>
    )
  }

  const addressDetails = buildingData?.addressDetails

  if (!addressDetails) {
    return (
      <>
        <h4>{t('apartments.noBuilding')}</h4>
        <Button onClick={() => navigate(`/buildings-management/${buildingData.id}`)} type="dashed">Click here to edit Building</Button>
      </>
    )
  }

  return (
    <>
      <h2>{addressDetails.formatted_address}</h2>
      <GalleryRO height={400} images={buildingData.photos} />

      <Divider />

      <h2 style={{ fontWeight: '600', fontSize: '20px' }}>{t('buildings.fields.areaDescription')}</h2>
      {buildingData.buildingDescription ? buildingData.buildingDescription.split('\n').map((d, i) => (
        d !== '' && (
          <>
            {/* eslint-disable-next-line */}
          <p style={{ lineHeight: '25px'}}>{d}</p>
            <br />
          </>
        )
      )) : <p style={{ lineHeight: '25px'}}>{t('buildings.fields.noBuildingDescription')}</p>}

      <h2 style={{ fontWeight: '600', fontSize: '20px' }}>{t('buildings.fields.areaDescription')}</h2>
      {buildingData.areaDescription ? buildingData.areaDescription.split('\n').map((d, i) => (
        d !== '' && (
          <>
            {/* eslint-disable-next-line */}
          <p style={{ lineHeight: '25px'}}>{d}</p>
            <br />
          </>
        )
      )) : <p style={{ lineHeight: '25px'}}>{t('buildings.fields.noAreaDescription')}</p>}
    </>
  )
}

export { AptBuildingInfo }
