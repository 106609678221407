import { gql } from 'apollo-boost'

import { rentalRequestFragment } from '../_fragments'

export const RENTAL_REQUEST_UPSERT_MUTATION = gql`
  mutation rentalRequest($input: RentalRequestInput!) {
    response: rentalRequest(input: $input) {
      ...rentalRequestFragment
    }
  }
  ${rentalRequestFragment}
`

export const RENTAL_REQUEST_APARTMENT_MUTATION = gql`
  mutation requestApartment($input: RequestApartmentInput!) {
    response: requestApartment(input: $input) {
      id
      requestId
      apartmentId
      inspect
      status
      reason
    }
  }
`

export const SCHEDULE_INSPECTION = gql`
  mutation sheduleInspection($requestId: Int!, $apartmentId: Int!) {
    response: sheduleInspection(requestId: $requestId, apartmentId: $apartmentId) {
      id
      requestId
      apartmentId
      inspect
      status
      reason
    }
  }
`

export const RENTAL_REQUEST_DELETE_MUTATION = gql`
  mutation deleteRentalRequest($id: Int!) {
    deleteRentalRequest(id: $id)
  }
`

export const RENTAL_REQUEST_APARTMENT_DELETE_MUTATION = gql`
  mutation removeRequestApartment($id: Int!) {
    removeRequestApartment(id: $id)
  }
`
