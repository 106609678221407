import { gql } from 'apollo-boost'

import { profileFragment, userFragment } from '../_fragments'

export const USERS_LIST = gql`
    query listUsers($page: Int!, $pageSize: Int!, $sorting: String, $search: String, $filter: JSON) {
      response: listUsers(page: $page, pageSize: $pageSize, sorting: $sorting, search: $search, filter: $filter) {
        list {
          ...userFragment
          profiles {
            ...profileFragment
          }
        }
        pagination {
          page
          pageSize
          total
        }  
      }
  }
  ${userFragment}
  ${profileFragment}
`

export const ALL_USERS_QUERY = gql`
  query allUsers {
    response: allUsers {
      ...userFragment
      profiles {
        ...profileFragment
      }
    }
    
  }
  ${userFragment}
  ${profileFragment}
`

export const ALL_USERS_BY_ROLE_QUERY = gql`
  query allUsersByRole($roles: [String]) {
    response: allUsersByRole(roles: $roles) {
      ...userFragment
    }
  }
  ${userFragment}
`

export const ALL_TENANTS_QUERY = gql`
  query allTenants {
    response: allTenants {
      ...userFragment
    }
  }
  ${userFragment}
`

export const USER_QUERY = gql`
  query findUser($id: Int!) {
    response: findUser(id: $id) {
      ...userFragment
      profiles {
        ...profileFragment
      }
    }
  }
  ${userFragment}
  ${profileFragment}
`
export const USER_QUERY_LIGHT = gql`
  query findUser($id: Int!) {
    response: findUser(id: $id) {
      id
      firstName
      lastName
    }
  }
`

export const ME_QUERY = gql`
  query fetchUser {
    response: fetchUser {
      ...userFragment
      profiles {
        ...profileFragment
      }
    }
  }
  ${userFragment}
  ${profileFragment}
`

export const FIX_USERS = gql`
  query fixUsers {
    response: fixUsers
  }

`