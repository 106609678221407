import { isAdmin, isTenant, roles, USER_ROLES } from '_graphql/_enums'
import { FETCH_BR_BY_SKU, MARK_BR_PAID,REBUILD_BR } from '_graphql/billRun'
import { BankOutlined, DownOutlined, FieldTimeOutlined,MailOutlined,ReloadOutlined,SyncOutlined,TeamOutlined,UserOutlinedm } from '@ant-design/icons'
import { ConsoleSqlOutlined, EditOutlined } from '@ant-design/icons'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { Link, navigate, useParams } from '@reach/router'
import {
  Alert,
  Badge,
  Button,
  Calendar,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from 'antd'
import { Layout } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useModals } from 'common/ModalsProvider'
import { ContactBox } from 'components/ContactBox'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import { cost } from 'components/ListManager/DefaultTypes'
import { user } from 'components/ListManager/DefaultTypes'
import { PageHeader } from 'components/PageHeader'
import { DateTime } from 'luxon'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EntriesManager } from 'screens/EntriesManager'
import { InvoicesManager } from 'screens/InvoiceManager'
import { RentalContractDetails } from 'screens/RentalContracts'
import { useGlobalState } from 'state'

const BillRunPage = (options) => {

  let sku
  if (options?.sku) {
    sku = options.sku
  } else {
    sku = null
  }
  const { openModal } = useModals()
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagmentUser = isAdmin(state.currentUser?.role)
  const [, rerender] = useState()
  const [recurring, setRecurring] = useState(null)
  const [fetch, { data: recurringPayment, loading: recurringPaymentLoading }] = useLazyQuery(FETCH_BR_BY_SKU, { variables: { sku: sku }, fetchPolicy: 'no-cache' })  

  const [rebuild, { data: rebuildBR, loading: rebuildBRLoading }] = useLazyQuery(REBUILD_BR, { variables: { sku: sku }, fetchPolicy: 'no-cache' }) 
  const [makePaid, { data: makePaidBR, loading: makePaidBRLoading }] = useLazyQuery(MARK_BR_PAID, { variables: { sku: sku }, fetchPolicy: 'no-cache' })  

  useEffect(() => {
    if(sku){
      fetch()
    }
  }, [sku])
  useEffect(() => {
    if(!recurringPaymentLoading) {
      setRecurring(recurringPayment?.response)
    }
  }, [recurringPayment])
  // Last seen
  //   useEffect(() => {
  //     if (recurringPayment?.id) {
  //       db.sku.put({
  //         id: invoices.id,
  //         updatedAt: invoices.updatedAt
  //       })
  //     }
  //   }, [invoice])

  // Subscribe
  // useEffect(() => {
  //   if (subscribeToMore) {
  //     return subscribeToMore({
  //       document: SUBSCRIBE_invoice,
  //       variables: { sku: sku },
  //       updateQuery: (prev, { subscriptionData }) => {
  //         if (!subscriptionData?.data?.response) {
  //           return prev
  //         }
  //         setInvoice(subscriptionData?.data?.response)
  //       }
  //     })
  //   }
  // }, [subscribeToMore])


  const reinit = (sku) => {
    rebuild()
    message.success('Reinit triggered for sku: ' + sku)
  }


  const markPaid = (sku) => {
    makePaid()

    message.success('RP  triggered paid for sku: ' + sku)
  }


  return (
    <>
      { recurring 
        ? (
          <>

            <PageHeader
              title={t('billruns.form.current')}
              id={sku}
              tags={recurring ? (recurring.isPaid ? <Tag color="green">{t('billruns.table.isPaid.$true$')}</Tag>  :  <Tag color="red">{t('billruns.table.isPaid.$false$')}</Tag>) : null}
              extra={!recurring.isPaid ? (
                <>

                  <Tooltip title="Regenerate">
                    <Button onClick={() => reinit(recurring.sku)}>
                      <ReloadOutlined/>
                    </Button>  
                  </Tooltip>
                  <Tooltip title="Mark as Paid">
                    <Button onClick={() => markPaid(recurring.sku)}>
                      <BankOutlined />
                    </Button>   
                  </Tooltip>
                </>
              ) : null}
            />
            <LayoutCard>

              <Row>
                <Col xs={24} >
                  <Descriptions title="" size="small" layout="horizontal" column={2} bordered>
                    <Descriptions.Item label={t('billruns.form.companyName')}>{ recurring?.customerProfile.companyName ? recurring?.customerProfile.companyName : '-'}</Descriptions.Item>
                    <Descriptions.Item label={t('billruns.form.issueMonth')}>{ recurring?.issueMonth ? DateTime.fromISO(recurring?.issueMonth).toFormat('LLL yyyy') : '-'}</Descriptions.Item>
                    <Descriptions.Item label={t('billruns.form.grossPrice')}>{cost({ value: recurring?.grossPrice })}</Descriptions.Item>
                    <Descriptions.Item label={t('billruns.form.volume')}>{recurring?.volume }</Descriptions.Item>
                    <Descriptions.Item label={t('billruns.form.createdAt')}>{ recurring?.createdAt ? DateTime.fromISO(recurring?.createdAt).toFormat('yyyy-MM-dd / hh:mm') : '-'}</Descriptions.Item>
                    {recurring?.updatedAt ? (<Descriptions.Item label={t('billruns.form.updatedAt')}>{ recurring?.updatedAt ? DateTime.fromISO(recurring?.updatedAt).toFormat('yyyy-MM-dd / hh:mm') : '-'}</Descriptions.Item>) : null}
                  </Descriptions>

                </Col>
               
              </Row>
             
            </LayoutCard>
            

            <EntriesManager queryParams={{ filter: { poolIds: [recurring.id] } }} />
          </>

        )
        : 'nothing to show'
      }
    </> 
  )
}

export { BillRunPage }
