import { isAdmin, isTenant, roles, USER_ROLES } from '_graphql/_enums'
import { FETCH_INVOICE_BY_SKU, INVOICE_UPSERT_MUTATION } from '_graphql/invoice'
import { ALL_PROFILES_QUERY } from '_graphql/profile'
import { ALL_RENTAL_CONTRACTS_QUERY } from '_graphql/rentalContract'
import { ALL_USERS_QUERY, ME_QUERY } from '_graphql/user/queries'
import CalendarIcon from '@2fd/ant-design-icons/lib/Calendar'
import Fireplace from '@2fd/ant-design-icons/lib/Fireplace'
import { BankOutlined, DownOutlined, FieldTimeOutlined,MailOutlined,SyncOutlined,TeamOutlined,UserOutlinedm } from '@ant-design/icons'
import { ConsoleSqlOutlined, EditOutlined } from '@ant-design/icons'
import { useLazyQuery,useMutation, useQuery } from '@apollo/react-hooks'
import { Link, navigate, useParams } from '@reach/router'
import {
  Alert,
  Badge,
  Button,
  Calendar,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  Timeline,
  Typography,
} from 'antd'
import { Layout } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useModals } from 'common/ModalsProvider'
import { ContactBox } from 'components/ContactBox'
import { EntityFooter } from 'components/EntityFooter'
import { ContractSelect } from 'components/Form/ContractSelect'
import { DatePicker } from 'components/Form/DatePicker'
import { ProfileSelect } from 'components/Form/ProfileSelect'
import { ProjectSelect } from 'components/Form/ProjectSelect'
import { UserSelect } from 'components/Form/UserSelect'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import { cost } from 'components/ListManager/DefaultTypes'
import { user } from 'components/ListManager/DefaultTypes'
import { PageHeader } from 'components/PageHeader'
import { isNull } from 'lodash'
import { DateTime } from 'luxon'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApartmentDetails } from 'screens/ApartmentsManager'
import { ProfileDisplay } from 'screens/ProfilesManager'
import { RentalContractDetails } from 'screens/RentalContracts'
import { useGlobalState } from 'state'
import stc from 'string-to-color'
import styles from 'styles/side-layout.less'
import { db } from 'utils/dexie'

const InvoicePage = (options) => {

  let sku
  if (options?.sku) {
    sku = options.sku
  } else {
    sku = null
  }
  const { openModal } = useModals()
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagmentUser = isAdmin(state.currentUser?.role)
  const [, rerender] = useState()
  const [isForm, setIsForm] = useState(!sku)
  const [form] = Form.useForm()
  const [types, setTypes] = useState([])
  const [fetch, { subscribeToMore, data: invoiceData, loading: invoiceLoading }] = useLazyQuery(FETCH_INVOICE_BY_SKU, { variables: { sku: sku }, fetchPolicy: 'no-cache' })  
  

  const { data: profilesData, loading: profilesLoading } = useQuery(ALL_PROFILES_QUERY)
  const { data: contractsData, loading: contractsLoading } = useQuery(ALL_RENTAL_CONTRACTS_QUERY)

  const [invoice, setInvoice] = useState()
  const [profile, setProfile] = useState()
  const query = useMemo(
    () => options.forceQuery ? options.forceQuery : null,
    [options]
  )

  useEffect(() => {
    if (query && query.has('contractId')) {
      form.setFieldsValue({
        rentalContractId: parseInt(query.get('contractId')),
      })
    }
  }, [query])

  useEffect(() => {
    if (sku) {
      fetch()
      setIsForm(false)
      console.log('invoice data l', invoiceData)
      handleProfileChange()
    }
  }, [sku])

  useEffect(() => {
    if(options?.forceQuery?.contractId) {
      console.log(options?.forceQuery?.contractId)
      form.setFieldsValue({
        rentralContractId: options?.forceQuery?.contractId
      })
    }
  }, [options])

  useEffect(() => {
    if (invoiceData?.response) {
      setInvoice(invoiceData.response)
      console.log('invoice data loaded', invoiceData.response)
      form.setFieldsValue({
        ...invoiceData.response,
      })
    }
  }, [invoiceData])

  // Last seen
  //   useEffect(() => {
  //     if (invoice?.id) {
  //       db.sku.put({
  //         id: invoices.id,
  //         updatedAt: invoices.updatedAt
  //       })
  //     }
  //   }, [invoice])

  // Subscribe
  // useEffect(() => {
  //   if (subscribeToMore) {
  //     return subscribeToMore({
  //       document: SUBSCRIBE_invoice,
  //       variables: { sku: sku },
  //       updateQuery: (prev, { subscriptionData }) => {
  //         if (!subscriptionData?.data?.response) {
  //           return prev
  //         }
  //         setInvoice(subscriptionData?.data?.response)
  //       }
  //     })
  //   }
  // }, [subscribeToMore])

  useEffect(() => {
    if (invoice === null && sku) {
      message.error('invoice not found')
      navigate('/contract-invoices')
    }
  }, [invoice])

  console.log(invoice)

  // Form is opened
  useEffect(() => {
    if (invoice) {
      form.setFieldsValue({ ...invoice})
    }
  }, [invoice])


  const handleProfileChange = () => {
    const invoiceProviderId = +form.getFieldValue('serviceProviderId')
  
    if (invoiceProviderId) {
      const filteredProfile = profilesData?.response?.find((profile) => profile.id === invoiceProviderId)
      setTypes(filteredProfile?.type)
      setProfile(filteredProfile)

      const vat = 100 * filteredProfile?.taxRate
      
      form.setFieldsValue({
        iban: filteredProfile?.ibanCode,
        bicCode: filteredProfile?.bicCode,
        bankName: filteredProfile?.bankName,
        recipient: filteredProfile?.contactFullName,
        vat: vat,
      })
    }
  }

  const recalculateGross = () => {
    let gross = 0
    gross = +form.getFieldValue('netPrice')
    let tax = +form.getFieldValue('vat')

    if(tax) {
      let newGross = 0
      newGross = gross * (1 + (tax/100))

      form.setFieldsValue({
        grossPrice: Math.round(newGross),
      })
    }
  }

  const recalculateNet = () => {
    let net = 0
    net = +form.getFieldValue('grossPrice')
    let tax = +form.getFieldValue('vat')


    if(tax) {
      let newNet = 0 
      newNet = net - (net * (tax/100))

      form.setFieldsValue({
        netPrice:  Math.round(newNet),
      })
    }
  }

  // Submit
  const [upsertinvoice] = useMutation(INVOICE_UPSERT_MUTATION)
  const onSubmit = async (values) => {
    const {rentalContractId, invoiceDate, bookingDate, reference, serviceProviderId, iban, bic, recipient, bankName, grossPrice, vat, netPrice, serviceProviderType} = values
    
    try {
      const { data } = await upsertinvoice({ variables: {
        input: {
          id: invoice?.id,
          ...values,
          method: 1,
        }
      } })

      message.success('Saved')

      if (sku === data.response.id) {
        setIsForm(false)
      } else {
        navigate(`/invoices-management/${data.response.sku}`)
      }
    } catch (e) {
      console.log('///////', e)
      message.error('The form is filled out incorrectly')
      navigate('/invoices-management')
    }
  }

  const goBack = () => {
    navigate('/invoices-management')
  }

  const formFooter = (
    <LayoutFooterActions>
      <Button size="large" type="default" onClick={() => goBack()}>
        {t('projects.form.cancel')}
      </Button>
      <Form.Item noStyle>
        <Button size="large" type="primary" htmlType="submit">
          {t('contract.form.save')}
        </Button>
      </Form.Item>
    </LayoutFooterActions>
  )

  return (
    <>
      { isForm ? (
        <Form 
          form={form}
          layout={'vertical'}
          onFieldsChange={rerender} 
          onFinish={onSubmit}
        >

          { invoice != null ? ( <PageHeader
            title={t('servicePrice.form.current')}
            id={sku}
          />) : null}
        
         

          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <LayoutCard>

                <>
                  <Row gutter={[20, 20]}>
                    <Col xs={12} style={{ textAlign: 'left' }}>
                      <Form.Item
                        label={t('invoices.form.contract') + ':'}
                        name="rentalContractId"
                        required={isForm}
                    
                        rules={[{ required: true }]}
                      >
                        <ContractSelect />
                      </Form.Item>
                    </Col>
                    <Col xs={12} style={{ textAlign: 'left' }}>
                      <Form.Item
                        name="serviceProviderId"
                        label={t('invoices.form.profile')}
                        required
                        rules={[{ required: true }]}
                      >
                        <Select
                          allowClear
                          style={{ width: '100%' }}
                          placeholder={t('globalForms.select')}
                          onChange={handleProfileChange}
                        >
                          {profilesData?.response && profilesData.response.map((profile) => (
                            <Select.Option value={profile.id} key={profile.id}>
                              <b>Company:</b> {profile.companyName} <b>ZIP:</b>{profile.zipCode}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={6} style={{ textAlign: 'left' }}>
                      <Form.Item
                        name="invoiceDate"
                        label={t('invoices.form.invoiceDate')}
                        required={isForm}
                        rules={[{ required: true }]}
                      >
                        <DatePicker />
                      </Form.Item>
                    </Col>
                    <Col xs={6} style={{ textAlign: 'left' }}>
                      <Form.Item
                        name="bookingDate"
                        label={t('invoices.form.bookingDate')}
                        required
                        rules={[{ required: true }]}
                      >
                        <DatePicker />
                      </Form.Item>
                    </Col>
                    <Col xs={12} style={{ textAlign: 'left' }}>
                      <Form.Item
                        name="serviceProviderType"
                        label={t('invoices.form.serviceProviderType')}
                      
                      >
                        <Select
                          allowClear
                          style={{ width: '100%' }}
                          placeholder={t('globalForms.select')}

                        >
                          {types?.map((type, i) => (
                            <Select.Option value={type} key={i}>
                              {type}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={[20, 20]}>
                    <Col xs={12} style={{ textAlign: 'left' }}>
                      <Form.Item
                        name="iban"
                        label={t('invoices.form.iban')}
                        required
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="BE98 1337 0000 1111"/>
                      </Form.Item>
                    </Col>
                    <Col xs={12} style={{ textAlign: 'left' }}>
                      <Form.Item
                        name="bicCode"
                        label={t('invoices.form.bicCode')}
                        required
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="BICBICB1XXX" />
                      </Form.Item>
                    </Col>
                  </Row>
             
              
                  <Row gutter={[20, 20]}>
                    <Col xs={12} style={{ textAlign: 'left' }}>
                      <Form.Item
                        name="recipient"
                        label={t('invoices.form.recipient')}
                        required
                        rules={[{ required: true }]}
                      >
                        <Input placeholder={t('invoices.form.recipientHolder')} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} style={{ textAlign: 'left' }}>
                      <Form.Item
                        name="bankName"
                        label={t('invoices.form.bankName')}
                        required
                        rules={[{ required: true }]}
                      >
                        <Input placeholder={t('invoices.form.bankNameHolder')} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider />
                  <Form.Item
                    name="reference"
                    label={t('invoices.form.reference')}
                    required
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={t('invoices.form.referenceHolder')} />
                  </Form.Item>
                
                  <Divider /> 
               
                  <Row gutter={[20, 20]}>
                    <Col xs={9} style={{ textAlign: 'left' }}>
                      <Form.Item 
                        name="grossPrice"
                        label={t('invoices.form.grossPrice')}
                      >
                        <InputNumber
                          min={0} 
                          defaultValue={0} 
                          onChange={recalculateNet}
                          style={{ width: '100%' }}  
                          formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/€\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={9} style={{ textAlign: 'left' }}>
                      <Form.Item 
                        name="netPrice"
                        label={t('invoices.form.netPrice')}
                      >
                        <InputNumber 
                          min={0} 
                          defaultValue={0} 
                          onChange={recalculateGross}
                          style={{ width: '100%' }}
                          formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/€\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={6} style={{ textAlign: 'left' }}>

                      <Form.Item 
                        name="vat"
                        label={t('invoices.form.vat')}
                      >
                        <InputNumber 
                          style={{ width: '100%' }}
                          min={0}
                          max={100}

                          formatter={value => `${value}%`}
                          // parser={value => value.replace('%', '')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
            
                </>
          
              </LayoutCard>
            </Col>
          </Row>
          <LayoutStickyFooter>
            {formFooter}
          </LayoutStickyFooter>
        </Form>
         
      ) 
        : (
          invoice 
            ? (
              <>
                <PageHeader
                  title={t('invoices.form.current')}
                  id={invoice.sku}
                  extra={(
                    <>
                      <Button>
                        <EditOutlined onClick={() => setIsForm(true)}/>
                      </Button>   
                    </>
                  )}
                />

                <LayoutCard>

                  <Row>
                    <Col xs={12} >
                      <Typography.Title level={5}>
                        <MailOutlined style={{ marginRight: 5 }} />
                        {t('invoices.form.invoiceTo')}
                      </Typography.Title>
                      <br />
                      <Typography.Text strong>
                        {t('invoices.form.serviceProvider')}:{' '}
                      </Typography.Text>
                      {invoice?.serviceProvider ? (
                        <a
                          onClick={() => openModal(RentalContractDetails, { sku: invoice.serviceProvider?.sku })}
                        >{invoice.serviceProvider?.companyName}
                        </a>
                      ) : (
                        <Tag>N/A</Tag>
                      )}
                      <br />
                      <Typography.Text strong>
                        {t('invoices.form.serviceProviderType')}:{' '}
                      
                      </Typography.Text>
                      <Tag>{invoice?.serviceProviderType}</Tag>
                      <Divider />
                      <Typography.Text strong>
                        {t('invoices.form.recipient')}:{' '}
                      
                      </Typography.Text>
                      <span>{invoice?.recipient}</span>
                      <br />
                      <Typography.Text strong>
                        {t('invoices.form.bankName')}:{' '}
                      
                      </Typography.Text>
                      <span>{invoice?.bankName}</span>
                      <br />
                      <Typography.Text strong>
                        {t('invoices.form.iban')}:{' '}
                      </Typography.Text>
                      <span>{invoice?.iban}</span>
                      <br />
                      <Typography.Text strong>
                        {t('invoices.form.bicCode')}:{' '}
                      </Typography.Text>
                      <span>{invoice?.bicCode}</span>
                      <br />
                   
                    </Col>
                    <Col xs={12} >
                      <Typography.Title level={5}>
                        <Fireplace style={{ marginRight: 5 }} />
                        {t('service.form.objectives')}
                      </Typography.Title>
                      <br />
                      <Typography.Text strong>
                        {t('invoices.form.contract')}:{' '}
                      </Typography.Text>
                      {invoice?.rentalContract ? (
                        <a
                          onClick={() => openModal(RentalContractDetails, { sku: invoice.rentalContract?.sku })}
                        >{invoice.rentalContract?.sku.slice(0,8)}
                        </a>
                      ) : (
                        <Tag>N/A</Tag>
                      )}
                      <br />
                      <Typography.Text strong>
                        {t('invoices.form.invoiceNumber')}:{' '}
                      </Typography.Text>
                      { invoice?.sku ? invoice?.sku.slice(0,8) : '-'}
                      <Divider />
                      <Typography.Text strong>
                        {t('invoices.form.bookingDate')}:{' '}
                      </Typography.Text>
                      { invoice?.bookingDate ? DateTime.fromISO(invoice?.bookingDate).toFormat('LLL dd, yyyy') : '-'}
                      <br />
                      <Typography.Text strong>
                        {t('invoices.form.invoiceDate')}:{' '}
                      </Typography.Text>
                      { invoice?.invoiceDate ? DateTime.fromISO(invoice?.invoiceDate).toFormat('LLL dd, yyyy') : '-'}
                      <br />
                      <Typography.Text strong>
                        {t('invoices.form.createdBy')}:{' '}
                      </Typography.Text>
                      { invoice?.createdBy ?  invoice?.creator?.firstName : '-'}
                      <br />

                   
                    </Col>
                  </Row>

                  <Divider />
                  
                  <Descriptions title="" layout="vertical" column={4} bordered>
                    <Descriptions.Item label={t('invoices.form.reference')}>{ invoice?.reference ? invoice?.reference : '-'}</Descriptions.Item>
                    <Descriptions.Item label={t('invoices.form.grossPrice')}>{cost({ value: invoice?.grossPrice })}</Descriptions.Item>
                    <Descriptions.Item label={t('invoices.form.vat')}>{invoice?.vat }%</Descriptions.Item>
                    <Descriptions.Item label={t('invoices.form.netPrice')}>{cost({ value: invoice?.netPrice })}</Descriptions.Item>
                  </Descriptions>

          
                </LayoutCard>
              </>

            )
            : null


        )}
    </> 
  )
}

export { InvoicePage }
