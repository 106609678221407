import { gql } from 'apollo-boost'

import { serviceFragment } from '../_fragments'

export const SERVICE_UPSERT_MUTATION = gql`
  mutation service($input: ServiceInput!) {
    response: service(input: $input) {
      ...serviceFragment
    }
  }
  ${serviceFragment}
`
