import { isLandlord, roles } from '_graphql/_enums'
import { WBS_LIST, WBS_UPSERT_MUTATION } from '_graphql/wbs'
import EyeOutline from '@2fd/ant-design-icons/lib/EyeOutline'
import {
  CloudServerOutlined,
  CopyOutlined,
  CrownOutlined,
  DeleteOutlined,
  FolderViewOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Link, navigate } from '@reach/router'
import { Button, Popconfirm, Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import { ListManager } from 'components/ListManager'
import { string } from 'components/ListManager/DefaultTypes'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import { CountryRender } from 'utils/countries'
import { deepOmit } from 'utils/deepOmit'

import { WbsView } from './WbsView'

const WbsManager = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const { openModal } = useModals()

  /** Клонирование профиля */
  const [upsertWbs] = useMutation(WBS_UPSERT_MUTATION)
  const cloneProjectAction = async (item) => {
    // const { data: { response: { id } } } = await upsertProject({ variables: { input: deepOmit({ payload: item, keysToOmit: ['__typename', 'id', 'user'] }) } });
    // navigate(`/wbs-management/${id}`);
    console.log('clone project')
  }

  /** Удаление профиля */
  // const [deleteProject] = useMutation(Project_DELETE_MUTATION);
  const archiveWbsAction = async (item, callback) => {
    // await deleteProject({ variables: { id: item.id } });
    // callback();
    console.log('archive project')
  }

  return (
    <>
      <ListManager
        slug="wbs-manager"
        title={t('wbs.table.title')}
        query={WBS_LIST}
        actions={[
          <Link 
            to="/wbs-management/create"
            key="create"
            onClick={(e) => {
              e.preventDefault()
              openModal(WbsView)
            }}
          >
            <Button type="primary" icon={<PlusOutlined />} htmlType="span">
              {t('wbs.table.actions.createProject')}
            </Button>
          </Link>,
        ]}
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('wbs.table.sorting.idDesc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('wbs.table.sorting.idAsc'),
              icon: <SortAscendingOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('wbs.table.filters.all'),
              slug: 'all',
              value: {},
            },
          ],
          filters: [],
        }}
        table={{
          columns: [
            // {
            //   title: t('wbs.table.id'),
            //   key: 'id',
            //   options: { na: true },
            //   render: ({ value, options, item }) => (
            //     <Link to={`/wbs-management/${item.id}`}>{item.id}</Link>
            //   ),
            // },
            {
              title: t('wbs.table.sku'),
              key: 'sku',
              options: { na: true },
              render: ({ options, item }) => (
                <div style={{ display: 'flex' }}>
                  <Link to={`/wbs-management/${item?.sku ? item.sku : item.id}`}>
                    {item?.sku ? item.sku.slice(0,8) : item.id}
                  </Link>

                  {/* Open in modal */}
                  <Button 
                    type="text" 
                    size="small" 
                    onClick={() => openModal(WbsView, { sku: item.sku })}
                  >
                    <EyeOutline />
                  </Button>
                </div>
              ),
            },
            {
              title: t('wbs.table.code'),
              key: 'code',
              options: { na: true },
              render: 'string',
            },
            {
              title: t('wbs.table.memo'),
              key: 'memo',
              options: { na: true },
              render: 'string',
            },
            {
              title: t('wbs.table.createdAt'),
              key: 'createdAt',
              render: ({ item }) => { 
                
                const createdAt = item?.createdAt
                  ? DateTime.fromISO(item?.createdAt)
                  : null
                
                return createdAt ? createdAt?.toFormat('yyyy-LL-dd') : ''
              },
            },
            {
              title: t('wbs.table.expireAt'),
              key: 'expireAt',
              render: ({ item }) => {
                const expireAt = item?.expireAt
                  ? DateTime.fromISO(item?.expireAt)
                  : null
                const now = DateTime.now()
                const diff =
                  now && expireAt
                    ? expireAt.diff(now, ['months', 'days'])
                    : null

                return `${expireAt ? expireAt.toFormat('yyyy-LL-dd') : ''}${
                  diff
                    ? ` (${[
                      diff.months ? `${Math.ceil(diff.months)}mo.` : '',
                      diff.days ? `${Math.ceil(diff.days)}d.` : '',
                    ]
                      .filter((item) => !!item)
                      .join(' ')})`
                    : ''
                }`
              },
            },
            {
              key: 'actions',
              title: t('wbs.table.actions.title'),
              width: 120,
              render: ({ item, context }) => (
                <>
                  {/** Clone */}
                  {/* <Tooltip
                    placement="bottom"
                    title={t('wbs.table.actions.duplicate')}
                  >
                    <Popconfirm
                      title={t('wbs.table.actions.duplicateConfirm')}
                      onConfirm={() => cloneProjectAction(item)}
                    >
                      <Button
                        shape="circle"
                        type="link"
                        icon={<CopyOutlined />}
                      />
                    </Popconfirm>
                  </Tooltip> */}
                  {/** Remove */}
                  <Tooltip
                    placement="bottom"
                    title={t('wbs.table.actions.archive')}
                  >
                    <Popconfirm
                      title={t('wbs.table.actions.archiveConfirm')}
                      onConfirm={() =>
                        archiveWbsAction(item, context.dataRefetch)
                      }
                    >
                      <Button
                        shape="circle"
                        type="link"
                        icon={<CloudServerOutlined />}
                      />
                    </Popconfirm>
                  </Tooltip>
                </>
              ),
            },
          ],
        }}
        {...props}
      />
    </>
  )
}

export { WbsView } from './WbsView'
export { WbsManager }
