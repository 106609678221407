import { DeleteOutlined } from '@ant-design/icons'
import { Button,Col, Divider, Input, Row } from 'antd'
import { ReactComponent as Paid } from 'img/icons/amenities/coin_slot.svg'
import { ReactComponent as Free } from 'img/icons/amenities/included.svg'
import { ReactComponent as CarParking } from 'img/icons/buildingAmenities/car_parking.svg'
import { ReactComponent as Children } from 'img/icons/buildingAmenities/children.svg'
import { ReactComponent as ConciergeService } from 'img/icons/buildingAmenities/concierge_service.svg'
import { ReactComponent as Laundry } from 'img/icons/buildingAmenities/laundry.svg'
import { ReactComponent as Neighbourhood } from 'img/icons/buildingAmenities/neighbourhood.svg'
import { ReactComponent as NewHouse } from 'img/icons/buildingAmenities/new_house.svg'
import { ReactComponent as RoofTerrace } from 'img/icons/buildingAmenities/roof_terrace.svg'
import { ReactComponent as Spa } from 'img/icons/buildingAmenities/spa.svg'
import React, { useEffect,useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { parseJson } from 'utils/parseJson'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'

import { amenitiesInitialState } from './amenitiesInitialState'
import { AmenityBorder, AmenityWrapper, Check, Content, Icon, OptionsWrapper } from './styled'

const Icons = {
  carParking: CarParking,
  children: Children,
  conciergeService: ConciergeService,
  laundry: Laundry,
  neighbourhood: Neighbourhood,
  newBuilding: NewHouse,
  roofTerrace: RoofTerrace,
  spa: Spa,

  paid: Paid,
  free: Free,
}

const reducer = (state, payload) => payload

const Amenities = (props) => {
  const { t } = useTranslation()
  const [amenitiesState, dispatchAmenitiesState] = useReducer(reducer, amenitiesInitialState)
  const [shouldUpdate, setShouldUpdate] = useState(false)

  useEffect(() => {
    const amenities = props.building.amenitiesOptions
    if (amenities) {
      dispatchAmenitiesState(amenities)
    } else {
      props.saveAmenities(amenitiesState)
    }
    setShouldUpdate(true)
  }, []);  // eslint-disable-line

  useDebouncedEffect(
    () => {
      if (shouldUpdate) {
        props.saveAmenities(amenitiesState)
      }
    },
    1000,
    [amenitiesState],
  )

  const toggleAmenity = (amenityIndex) => {
    const state = { ...amenitiesState }
    state.default[amenityIndex].selected = !state.default[amenityIndex].selected
    dispatchAmenitiesState(state)
  }

  const toggleOption = (amenityIndex, optionIndex) => {
    const state = { ...amenitiesState }
    state.default[amenityIndex].options.forEach((_, i) => {
      state.default[amenityIndex].options[i].selected = false
    })
    state.default[amenityIndex].options[optionIndex].selected = !state.default[amenityIndex].options[optionIndex]
      .selected
    dispatchAmenitiesState(state)
  }

  const addCustomAmenity = () => {
    const state = { ...amenitiesState }
    state.custom.push('')
    dispatchAmenitiesState(state)
  }

  const onCustomChange = (value, index) => {
    const state = { ...amenitiesState }
    state.custom[index] = value
    dispatchAmenitiesState(state)
  }

  const deleteCustom = (index) => {
    const state = { ...amenitiesState }
    const newCustom = state.custom.filter((c, i) => index !== i)

    state.custom = newCustom
    dispatchAmenitiesState(state)
  }

  return (
    <>
      {amenitiesState.default && (
        <>
          <h2>Default Amenities</h2>
          {amenitiesState.default.map((amenity, amenityIndex) => {
            const CurrentIcon = Icons[amenity.id]
            return (
              <AmenityBorder key={amenityIndex} >
                <AmenityWrapper selected={amenity.selected} onClick={() => toggleAmenity(amenityIndex)}>
                  {CurrentIcon && (
                    <Icon style={{ width: '30px', height: '30px' }}>
                      <CurrentIcon style={{ width: '30px', height: '30px' }} />
                    </Icon>
                  )}
                  <Content>
                    <h2 style={{ margin: 0, lineHeight: 1.2 }}>{t(`buildings.fields.${amenity.id}.title`)}</h2>
                  </Content>

                  <Check selected={amenity.selected} />
                </AmenityWrapper>

                {amenity.options && (
                  <OptionsWrapper collapsed={!amenity.selected}>
                    <Divider />
                    <Row gutter={[2, 0]}>
                      {amenity.options.map((option, optionIndex, arr) => {
                        const OptionIcon = Icons[option.id]
                        return (
                          <Col xs={24 / arr.length} key={optionIndex}>
                            <AmenityWrapper
                              option
                              selected={option.selected}
                              onClick={() => toggleOption(amenityIndex, optionIndex)}
                            >
                              {OptionIcon && (
                                <Icon style={{ width: '30px', height: '30px' }}>
                                  <OptionIcon style={{ width: '30px', height: '30px' }} />
                                </Icon>
                              )}
                              <Content>
                                <h2 style={{ margin: 0, lineHeight: 1.2 }}>{t(`buildings.fields.${amenity.id}.${option.id}`)}</h2>
                              </Content>
                            </AmenityWrapper>
                          </Col>
                        )
                      })}
                    </Row>
                  </OptionsWrapper>
                )}
              </AmenityBorder>
            )
          })}
        </>
      )}

      {amenitiesState.custom?.length ? (
        <>
          <Divider />
          <h2>Custom Amenities</h2>
          {amenitiesState.custom.map((a, index) => (
            <Row gutter={[0, 20]} key={index}>
              <Col xs={12}>
                <Input
                  value={a}
                  onChange={(e) => onCustomChange(e.target.value, index)}
                  addonAfter={<DeleteOutlined onClick={() => deleteCustom(index)} />}
                />
              </Col>
            </Row>
          ))}
        </>
      ) : null}

      <Button style={{ display: 'none' }} onClick={addCustomAmenity}>Add Custom Amenity</Button>
    </>
  )
}

export { AmenitiesView } from './AmenitiesView'
export { Amenities }
