import { isAdmin, roles } from '_graphql/_enums'
import { ConsoleSqlOutlined, CopyOutlined, DeleteOutlined, SettingOutlined } from '@ant-design/icons'
import { Link } from '@reach/router'
import { Button, Popconfirm, Tag, Tooltip,Typography } from 'antd'
import { user as userRender } from 'components/ListManager/DefaultTypes'
import BUILDING_PLACEHOLDER from 'img/building_placeholder.jpg'
import head from 'lodash/head'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

/** Обрезание описание  */
const DescriptionSliceStyle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
`

/**
 * Апартаменты : Плитка
 */
export const BuildingTile = ({ item, actions, onMouseEnter, onMouseLeave, userRole = roles.TENANT_ADMIN }) => {
  const buildingPreviewPhoto = item.photos?.find((p) => p.order === 0)?.url ?? BUILDING_PLACEHOLDER
  const isManagementUser = isAdmin(userRole)
  const { t } = useTranslation()

  return (
    <div key={item.id} className="list-tile" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {/** Галерея */}
      <div className="list-tile__galery">
        <img className="list-tile__galery--photo" loading="lazy" src={buildingPreviewPhoto} alt={item.title} />
      </div>
      {/** Контент */}
      <div className="list-tile__content">
        {/** Статусы */}
        <div className="list-tile__statuses">
          {/** Activation status */}
          {item.status
            ? <Tag color="success">{t('buildings.status.active')}</Tag>
            : <Tag color="error">{t('buildings.status.notActive')}</Tag>}
          {/** Publish status */}
          {isManagementUser ? (item.isBusy
            ?<Tag color="red">{t('buildings.fields.isBusy.$true$')}</Tag>
            :<Tag color="green">{t('buildings.fields.isBusy.$false$')}</Tag>) : null}

        </div>
        {/** Действия */}
        <div className="list-tile__actions">
          { actions }
        </div>
        {/** Основная информация */}
        <div className="list-tile__main">
          {/** Заголовок */}
          <Link to={`/buildings-management/${item.id}`}>
            <Typography.Title level={4} className="list-tile__title">{item.addressDetails?.formatted_address || <Typography.Text type="warning">Unnamed Building</Typography.Text>}</Typography.Title>
          </Link>
          {/** Building description */}
          {item.buildingDescription
            ? <DescriptionSliceStyle>{item.buildingDescription}</DescriptionSliceStyle>
            : ''}
        </div>
        {/** Сводка */}
        <div className="list-tile__overview list-tile-overview">
          <div className="list-tile-overview__list">
            {/** Владелец */}
            {isManagementUser
              ? (
                <div className="list-tile-overview__item">
                  <span style={{ marginRight: 3 }}>{t('buildings.owner')}:</span>
                  {item.user !== null
                    ? userRender({ value: item.user, options: { style: { verticalAlign: 'middle', marginLeft: 6 } } })
                    : <Link to={`/buildings-management/${item.id}`}><Tag color="error">{t('buildings.addProfile')}</Tag></Link>}
                </div>
              )
              : null}
            {/** Профиль */}
            <div className="list-tile-overview__item">
              <span style={{ marginRight: 3 }}>{t('buildings.profile')}:</span>
              {/** Требование от клиента: если у лендлорда нету профайла то его нужно создать / если есть то очевидно присвоить */}      
              { item?.user?.profiles?.length !== 0 
                ? 
                ( item.profile !== null
                  ? <Link to={`/profiles-management/${item.profile.id}`}><Tag>{item.profile.companyName || `#${item.profile.id}`}</Tag></Link>
                  : <Link to={`/buildings-management/${item.id}`}><Tag color="error">{t('buildings.addProfile')}</Tag></Link>)
                : <Link to={'/profiles-management/create'}><Tag color="warning">{t('profiles.actions.add-profile')}</Tag></Link>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Действия по умолчанию
 */
export const defaultBuildingActions = ({ item, cloneBuildingAction, deleteBuildingAction, context }) => [
  /** Edit building */
  <Tooltip key={9989} placement="bottom" title="Edit building">
    <Link to={`/buildings-management/${item.id}`} aria-label="Edit building" key="edit">
      <Button shape="circle" type="link" size="large" icon={<SettingOutlined />} htmlType="span" />
    </Link>
  </Tooltip>,
  /** Clone building */
  cloneBuildingAction ? (
    <Tooltip placement="bottom" title="Duplicate">
      <Popconfirm title="Clone this Building?" onConfirm={() => cloneBuildingAction(item)} key="clone">
        <Button shape="circle" type="link" size="large" icon={<CopyOutlined />} />
      </Popconfirm>
    </Tooltip>
  ) : null,
  /** Delete building */
  !item.isBusy && deleteBuildingAction && context.dataRefetch ? (
    <Tooltip placement="bottom" title="Remove">
      <Popconfirm title="Delete this Building?" onConfirm={() => deleteBuildingAction(item, context.dataRefetch)}>
        <Button shape="circle" type="link" size="large" icon={<DeleteOutlined />} />
      </Popconfirm>
    </Tooltip>
  ) : null,
].filter((action) => !!action)
