import { Col, Divider,Row } from 'antd'
import { ReactComponent as Cable } from 'img/icons/amenities/cable.svg'
import { ReactComponent as CoinSlot } from 'img/icons/amenities/coin_slot.svg'
import { ReactComponent as Conditioner } from 'img/icons/amenities/conditioner.svg'
import { ReactComponent as DBVT2 } from 'img/icons/amenities/digital_tv.svg'
import { ReactComponent as Dryer } from 'img/icons/amenities/dryer.svg'
import { ReactComponent as ExtraBed } from 'img/icons/amenities/extra_bed.svg'
import { ReactComponent as Included } from 'img/icons/amenities/included.svg'
import { ReactComponent as Internet } from 'img/icons/amenities/internet.svg'
import { ReactComponent as Lan } from 'img/icons/amenities/lan.svg'
import { ReactComponent as Safe } from 'img/icons/amenities/safe.svg'
import { ReactComponent as Sat } from 'img/icons/amenities/sat.svg'
import { ReactComponent as TV } from 'img/icons/amenities/tv.svg'
import { ReactComponent as smartTV } from 'img/icons/amenities/tv.svg'
import { ReactComponent as WashingMaschine } from 'img/icons/amenities/washing_maschine.svg'
import { ReactComponent as WLan } from 'img/icons/amenities/wlan.svg'
import { ReactComponent as WorkingPlace } from 'img/icons/amenities/working_place.svg'
import React, { useEffect,useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { parseJson } from 'utils/parseJson'

import { amenitiesInitialState } from './amenitiesInitialState'
import { AmenityBorder, AmenityWrapper, Check, Content, Icon, OptionsWrapper } from './styled'

const Icons = {
  cable: Cable,
  internet: Internet,
  lan: Lan,
  wlan: WLan,
  airConditioning: Conditioner,
  dryer: Dryer,
  tv: TV,
  washingMaschine: WashingMaschine,
  workingPlace: WorkingPlace,
  safe: Safe,
  extraBed: ExtraBed,
  coinSlot: CoinSlot,
  included: Included,
  sat: Sat,
  dbvt2: DBVT2,
  smartTv: smartTV,
}

const reducer = (state, payload) => payload

const AmenitiesView = (props) => {
  const { t } = useTranslation()
  const [amenitiesState, dispatchAmenitiesState] = useReducer(reducer, amenitiesInitialState)

  useEffect(() => {
    const amenities = props.apartment.amenitiesOptions
    if (amenities) {
      dispatchAmenitiesState(amenities)
    }
  }, []); // eslint-disable-line

  return (
    <>
      {amenitiesState.default && (
        <>
          <h2>{t('apartments.sections.default-amenities')}</h2>
          {amenitiesState.default.map((amenity, index) => {
            const CurrentIcon = Icons[amenity.id]
            return (
              <AmenityBorder key={index} >
                <AmenityWrapper selected={amenity.selected}>
                  {CurrentIcon && (
                    <Icon style={{ width: '30px', height: '30px' }}>
                      <CurrentIcon style={{ width: '30px', height: '30px' }} />
                    </Icon>
                  )}
                  <Content>
                    <h2 style={{ margin: 0, lineHeight: 1.2 }}>{t(`apartments.amenities.${amenity.id}.title`)}</h2>
                  </Content>

                  <Check selected={amenity.selected} />
                </AmenityWrapper>

                {amenity.options && (
                  <OptionsWrapper collapsed={!amenity.selected}>
                    <Divider />
                    <Row gutter={[2, 0]}>
                      {amenity.options.map((option, optionIndex, arr) => {
                        const OptionIcon = Icons[option.id]
                        return (
                          <Col xs={24 / arr.length} key={optionIndex}>
                            <AmenityWrapper
                              option
                              selected={option.selected}
                            >
                              {OptionIcon && (
                                <Icon style={{ width: '30px', height: '30px' }}>
                                  <OptionIcon style={{ width: '30px', height: '30px' }} />
                                </Icon>
                              )}
                              <Content>
                                <h2 style={{ margin: 0, lineHeight: 1.2 }}>{t(`apartments.amenities.${amenity.id}.${option.id}`)}</h2>
                              </Content>

                            </AmenityWrapper>
                          </Col>
                        )
                      })}
                    </Row>
                  </OptionsWrapper>
                )}
              </AmenityBorder>
            )
          })}
        </>
      )}

      {amenitiesState.custom?.length ? (
        <>
          <Divider />
          <h2>{t('apartments.sections.custom-amenities')}</h2>
          {amenitiesState.custom.map((a, i) => (
            <Row gutter={[0, 5]} key={i}>
              <Col xs={12}>
                <p>{a}</p>
              </Col>
            </Row>
          ))}
        </>
      ) : null}
    </>
  )
}

export { AmenitiesView }
