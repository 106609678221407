import BUILDING_PLACEHOLDER from 'img/building_placeholder.jpg'
import styled from 'styled-components'

export const ImageWrapper = styled.div`
  height: 300px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #dddddd;
  background-image: url(${(p) => p.img || BUILDING_PLACEHOLDER});
  box-shadow: inset 0px -137px 66px -80px rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  div {
    padding: 12px 23px;
    h3 {
      font-size: 20px;
      color: white;
      margin: 0;
    }
    p {
      font-size: 16px;
      color: white;
      margin: 0;
    }
  }
`

export const FormWrapper = styled.div`
  overflow-y: auto;
  height: 100vh;
`

export const ContextContainer = styled.div`
  padding: 40px 20px;
  overflow-y: auto;
`
