import { gql } from 'apollo-boost'

export const profileFragment = gql`
    fragment profileFragment on Profile {
      id
      companyName
      billingAddress
      addressAddition
      zipCode
      city
      country
      invoiceLang
      isActive
      type
      residenseCert
      tenantExchange
      taxRate
      comission
      userId
      contactFullName
      phoneNumber
      correspondanceEmail
      receptor
      bankName
      ibanCode
      bicCode
      clearingCode
      profileName
      companyType
      deletedAt
      user {
        id
        email
      }
    }
`
