import { isAdmin, isLandlord, roles } from '_graphql/_enums'
import { PROFILE_LIST } from '_graphql/profile'
import { MARK_PAID, REBUILD_RP, RP_DELETE_MUTATION, RP_LIST, RP_UPSERT_MUTATION } from '_graphql/recurringPayment'
import {
  CloudServerOutlined,
  CopyOutlined,
  CrownOutlined,
  DeleteOutlined,
  DownOutlined,
  EyeOutlined,
  FolderViewOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { Link, navigate } from '@reach/router'
import { Button, Col, DatePicker, Divider, Dropdown, Form, Menu, message, Modal, Popconfirm, Row, Select, Tag, Tooltip } from 'antd'
import { TenantChainPicker } from 'components/Form/TenantChainPicker'
import { LayoutCard } from 'components/Layout'
import { ListManager } from 'components/ListManager'
import { cost, string } from 'components/ListManager/DefaultTypes'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import { CountryRender } from 'utils/countries'
import { deepOmit } from 'utils/deepOmit'

import { RecurringPaymentPage } from './rpPage'

const RecurringPayments = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [onSave] = useState(false)
  const isManagementUser = isAdmin(state.currentUser?.role)
  const [form] = Form.useForm()
  const [profiles, setProfiles] = useState()
  const [itemSku, setItemSku] = useState()

  /** Клонирование профиля */
  const [upsertRP] = useMutation(RP_UPSERT_MUTATION)
  const [deleteRP] = useMutation(RP_DELETE_MUTATION)
  const [rebuild, { data: rebuildRP, loading: rebuildRPLoading }] = useLazyQuery(REBUILD_RP, { variables: { sku: itemSku }, fetchPolicy: 'no-cache' })
  const [makePaid, { data: makePaidRP, loading: makePaidRPLoading }] = useLazyQuery(MARK_PAID, { variables: { sku: itemSku }, fetchPolicy: 'no-cache' })



  const { data: profilesData, loading: profilesLoading } =
    useQuery(PROFILE_LIST, {
      variables: {
        page: 1,
        pageSize: 1000,
      },
      fetchPolicy: 'network-only'
    })

  /** Удаление профиля */
  // const [deleteProject] = useMutation(Project_DELETE_MUTATION);
  const deleteProjectAction = async (item, callback) => {
    // await deleteProject({ variables: { id: item.id } });
    // callback();
    console.log('delete project')
  }

  const handleOk = () => {
    onSave(true)
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onSubmit = async () => {
    const customerProfileId = form.getFieldValue('customerId')
    const issueMonth = form.getFieldValue('date')

    const d = await upsertRP({
      variables: {
        input: {
          customerProfileId,
          issueMonth,
        },
      },
    })

    if (d?.data?.response) {
      message.success(t('profiles.messages.profile-saved'))
      // goBack()
    } else {
      message.error(t('profiles.messages.profile-save-error'))
    }
  }

  const onDelete = async (sku) => {
    const d = await deleteRP({
      variables: {
        sku: sku,
      },
    })

    if (d?.data?.response.includes('Done')) {
      message.success(d?.data?.response)
      // message.success(t('profiles.messages.profile-saved'))
      // goBack()
    } else {
      message.error(d?.data?.response)
    }
  }

  const reinit = (sku) => {
    setItemSku(sku)
    rebuild()
    fetch()
    message.success('Reinit triggered for sku: ' + sku)
  }


  const markPaid = (sku) => {
    setItemSku(sku)
    makePaid()
    const response = fetch()
    console.log(response)
    console.log(makePaidRP)
    message.success('RP  triggered paid for sku: ' + sku)
  }


  const menu = (item) => {
    return (
      <>
        <Menu>
          <Menu.Item icon={<EyeOutlined />} disabled={item.isPaid ? 'disabled' : null}>
            <a onClick={() => reinit(item.sku)} rel="noopener noreferrer">
              {t('recurring.table.actions.regenerate')}
            </a>
          </Menu.Item>
          <Menu.Item icon={<EyeOutlined />} disabled={item.isPaid ? 'disabled' : null}>
            <a onClick={() => markPaid(item.sku)} rel="noopener noreferrer">
              {t('recurring.table.actions.markPaid')}
            </a>
          </Menu.Item>
          <Divider />
          <Menu.Item icon={<DeleteOutlined />} disabled={item.isPaid ? 'disabled' : null}>
            <a onClick={() => onDelete(item.sku)} rel="noopener noreferrer">
              {t('recurring.table.actions.delete')}
            </a>
          </Menu.Item>
        </Menu>
      </>
    )
  }

  return (
    <>
      <br />
      <LayoutCard>
        <Form
          form={form}
          layout="vertical"
        >
          <Row gutter={80}>
            <Col xs={5}>
              <Form.Item name="date" label={t('recurring.table.paymentMonth')}>
                <DatePicker picker="month" style={{ width: '210px'}}/>
              </Form.Item> 
            </Col>
            <Col xs={17}>
              <Form.Item name="customerId" label={t('recurring.table.selectCustomer')}>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={t('globalForms.select')}
                >
                  {profilesData?.response && profilesData.response?.list?.filter((profile) => profile?.companyType === 'customer').map((profile) => (
                    <Select.Option value={profile.id} key={profile.id}>
                      {profile.companyName} (#{profile.id})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
           
            <Col xs={2}>
              <Form.Item style={{ marginTop: '30px', float: 'right'}} >
                <Button type="primary" onClick={() => onSubmit()}>Generate</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </LayoutCard>
      <ListManager
        slug="rp-manager"
        title={t('recurring.table.title')}
        query={RP_LIST}

        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('recurring.table.sorting.idDesc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('recurring.table.sorting.idAsc'),
              icon: <SortAscendingOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('recurring.table.filters.all'),
              slug: 'all',
              value: {},
            },
          ],
          filters: [],
        }}
        table={{
          scroll: { x: 900, y: 350 },
          columns: [
            {
              title: t('recurring.table.sku'),
              key: 'sku',
              options: { na: true },
              render: ({ value, options, item }) => (
                <Link to={`/recurring-payments/${item?.sku}`}>
                  {item?.sku?.slice(0, 8)}
                </Link>
              ),
            },
            {
              title: t('recurring.table.customerProfile'),
              key: 'customerProfile',
              render: ({ item }) => {
                return <Link to={`/profiles-management/${item?.customerProfile?.id}`}><span>{item?.customerProfile?.companyName || `#${item?.customerProfile?.id}`}</span></Link>
              },
            },
            {
              title: t('recurring.table.issueMonth'),
              key: 'issueMonth',
              render: ({ item }) => {

                const createdAt = item?.issueMonth
                  ? DateTime.fromISO(item?.issueMonth)
                  : null

                return createdAt ? createdAt?.toFormat('yyyy LLL') : ''
              },
            },
            {
              title: t('recurring.table.invoices'),
              key: 'volume',
              render: 'string',
            },
            {
              title: t('recurring.table.totalGross'),
              key: 'grossPrice',
              render: ({ item }) => {

                return (
                  <>
                    {cost({ value: item.grossPrice })}
                  </>
                )
              },
            },
            {
              title: t('recurring.table.status'),
              key: 'isPaid',
              render: ({ value, item, context }) => {

                return (
                  <>
                    {value
                      ? <Tag color="green">{t('recurring.table.isPaid.$true$')}</Tag>
                      : <Tag color="red">{t('recurring.table.isPaid.$false$')}</Tag>
                    }
                  </>
                )
              },
            },
            {
              title: t('recurring.table.createdAt'),
              key: 'createdAt',
              render: ({ item }) => {

                const activeFrom = item?.createdAt
                  ? DateTime.fromISO(item?.createdAt)
                  : null

                return activeFrom ? activeFrom?.toFormat('yyyy-LL-dd / hh:mm') : ''
              },
            },
            {
              key: 'actions',
              title: t('recurring.table.actions.title'),
              width: 120,
              render: ({ item, context }) => (
                <>
                  <Dropdown overlay={menu(item)}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                      {t('list-manager.actions.title')} <DownOutlined />
                    </a>
                  </Dropdown>
                </>
              ),
            },
          ],
        }}
        {...props}
      />
    </>
  )
}

export { RecurringPayments }
export { RecurringPaymentPage }
