import { isAdmin, isTenant, roles, USER_ROLES } from '_graphql/_enums'
import LeadPencil from '@2fd/ant-design-icons/lib/LeadPencil'
import { Button, Drawer, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { ProfileSelect } from './ProfileSelect'
import { ProjectSelect } from './ProjectSelect'
import { UserSelect } from './UserSelect'
import { WBSSelect } from './WBSSelect'

function TenantChainPicker({
  disabled = false,
  value = null,
  onChange,
  ...props
}) {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()
  const [form] = Form.useForm()
  const [isOpened, setIsOpened] = useState(false)
  const [title, setTitle] = useState(null)
  const [, rerender] = useState()

  const role = state.currentUser?.role
  const formData = form.getFieldsValue()


  const tenantId = formData.tenantId || value.tenantEmployeeId || (isTenant(role) ? state.currentUser?.ownerId : undefined) || undefined
  const tenantEmployeeId = formData.tenantEmployeeId || value.tenantEmployeeId || (role === roles.TENANT_EMPLOYEE ? state.currentUser?.id : undefined) || undefined
  const projectId =  formData.projectId || value.projectId || (role === roles.TENANT_EMPLOYEE ? state.currentUser?.projectId : undefined) || undefined
  const tenantProfileId = formData.tenantProfileId || value.tenantProfileId || (role === roles.TENANT_EMPLOYEE ? state.currentUser?.defaultProfile : undefined) || undefined

  // Disable form element to Employee
  if (tenantEmployeeId === state.currentUser?.id) {
    disabled = true
  }

  const reset = () => {
    form.resetFields()
    form.setFieldsValue({ ...value, tenantId, tenantEmployeeId, projectId, tenantProfileId })
  }
  useEffect(reset, [value, state.currentUser])

  const onApply = (formData) => {
    // TODO: Validation
    onChange?.({ ...value, ...formData, tenantId, tenantEmployeeId, projectId, tenantProfileId })
    setIsOpened(false)
  }
  useEffect(onApply, [])

  return (
    <>
      <div className={`ant-select ant-select-single ant-select-show-arrow ${disabled ? 'ant-select-disabled' : ''}`} style={{ width: '100%' }} disabled={disabled} onClick={() => disabled ? null : setIsOpened(true)} {...props}>
        <div className="ant-select-selector">
          <span className="ant-select-selection-item">
            {title === null ? t('rental-request.components.sales-select.not-selected') : title}
          </span>
        </div>
        <span className="ant-select-arrow" aria-hidden="true">
          <LeadPencil />
        </span>
      </div>
      <Drawer
        title="Tenant Chain"
        placement="left"
        closable={false}
        forceRender
        onClose={() => { setIsOpened(false); reset() }}
        visible={isOpened}
      >
        <Form 
          layout="vertical" 
          form={form} 
          onFieldsChange={rerender} 
          onFinish={onApply}
        >

          {/* Tenant */}
          {isAdmin(role) ? (
            <Form.Item name="tenantId" label="Tenant Company">
              <UserSelect roles={[ USER_ROLES['tentant-admin'].key ]} />
            </Form.Item>
          ) : null }

          {/* Profile */}
          <Form.Item name="tenantProfileId" label="Profile Entity">
            <ProfileSelect userId={tenantId} />
          </Form.Item>

          {/* Project */}
          <Form.Item name="projectId" label="Project">
            <ProjectSelect userId={tenantId} />
          </Form.Item>

          {/* WBSSelect */}
          <Form.Item name="wbsId" label="Project Code (WBS)">
            <WBSSelect projectId={formData.projectId} />
          </Form.Item>
          
          {/* Employee */}
          <Form.Item name="tenantEmployeeId" label="Employee">
            <UserSelect 
              roles={[ USER_ROLES['tentant-employee'].key ]}
              disabled={!formData.projectId} 
              filter={{ projectIds: [formData.projectId] }}
              onTitle={setTitle}
            />
          </Form.Item>

          {/* Apply */}
          <Button type="primary" htmlType="submit" block>
            {t('rental-request.actions.update')}
          </Button>
        </Form>
      </Drawer>
    </>
  )
}

export { TenantChainPicker }
