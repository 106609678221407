import { gql } from 'apollo-boost'

import { profileFragment, reportFragment } from '../_fragments'

export const RP_LIST = gql`
  query listReports(
    $page: Int!
    $pageSize: Int!
    $sorting: String
    $search: String
    $filter: JSON
  ) {
    response: listReports(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      search: $search
      filter: $filter
    ) {
      list {
        ...reportFragment
        customerProfile  {
          ...profileFragment
        }
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${reportFragment},
  ${profileFragment},
`

export const FETCH_REPORT_BY_SKU = gql`
  query findReportBySku($sku: String!) {
    response: findReportBySku(sku: $sku) {
      ...reportFragment
      customerProfile {
        ...profileFragment
      }
    }
  }
  ${reportFragment},
  ${profileFragment},
`