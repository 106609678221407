import { gql } from 'apollo-boost'

import { rentalContractFragment } from './rentalContract'
import { userFragment } from './user'

export const serviceRequestFragment = gql`
    ${rentalContractFragment}
    ${userFragment}

    fragment serviceRequestFragment on ServiceRequest {
        id
        sku
        contractId
        contract { ...rentalContractFragment }
        owner { ...userFragment }
        managerId
        manager { ...userFragment }
        status
        type
        title
        context
        createdAt
        updatedAt
    }
`
