import { InputNumber } from 'antd'
import React from 'react'


function AmountInput({ 
  ...props
}) {
  return (
    <InputNumber 
      style={{ width: '100%' }}
      formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value => value.replace(/€\s?|(,*)/g, '')}
      {...props}
    />
  )
}

export { AmountInput }
