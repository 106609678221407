import { TUTORIAL_TYPES } from './constants'

export const TUTORIALS = {
  [TUTORIAL_TYPES.CREATE_BUILDING_TUTORIAL]: {
    type: TUTORIAL_TYPES.CREATE_BUILDING_TUTORIAL,
    run: true,
    steps: {
      '#buildings-management-sidebar-menu-item': {
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: 'bottom',
        spotlightClicks: true,
        disableTooltip: true,
      },
      '#buildings-management-add-building-button': {
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: 'bottom',
        spotlightClicks: true,
        disableTooltip: true,
      },
    },
  },
  [TUTORIAL_TYPES.CREATE_APARTMENT_TUTORIAL]: {
    type: TUTORIAL_TYPES.CREATE_APARTMENT_TUTORIAL,
    run: true,
    steps: {
      '#apartments-management-sidebar-menu-item': {
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: 'bottom',
        spotlightClicks: true,
        disableTooltip: true,
      },
      '#apartments-management-add-apartment-button': {
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: 'bottom',
        spotlightClicks: true,
        disableTooltip: true,
      },
    },
  },
}
