import './style.css'

import { FIX_DB } from '_graphql'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { Alert, Button, Col, Descriptions,  Row, Spin } from 'antd'
import {
  LayoutCard,
} from 'components/Layout'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'


const DBHealth = () => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const [systemHealth, setSystemHealth] = useState()

  const [simulateAll, { data: simalationResponse, loading: simLoading }] = useLazyQuery(FIX_DB, { fetchPolicy: 'no-cache' })

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col xs={24}>
  
          <LayoutCard>
            <Row gutter={[20, 20]}>
              <Col xs={24}>  <Button type="primary" onClick={simulateAll}>Fix DB</Button></Col>    
            </Row>

            <br />

            <Row gutter={[20, 20]}>
              <Col xs={24}>
                
                { simLoading ? <Spin /> : simalationResponse?.response?.map((res, index) => {
                  return <Fragment key={index}><Alert type="success" message={res?.response + ' ' + res.index} /><br /></Fragment>
                })}
                
              </Col>    
            </Row>

            <br />
          
          </LayoutCard>
       
        </Col>
      </Row>
       
    </>
  )
}

export { DBHealth }
