import { Button, Space } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { EditOverlay } from './EditOverlay'
import { EditWrapper } from './EditWrapper'

export const ActionButtons = ({ cancel, save }) => {
  const { t } = useTranslation()
  return (
    <div
      className="save-part"
      style={{ bottom: '-30px', right: '0', fontSize: '22px', color: 'green', textAlign: 'end' }}
    >
      <Space size={4}>
        <Button
          size="small"
          type="dashed"
          onClick={(e) => {
            e.stopPropagation()
            cancel('title')
          }}
        >
          {t('buildings.actions.cancel')}
        </Button>
        {/* <Popconfirm title="Are you sure？" onConfirm={save}> */}
        <Button size="small" type="primary" onClick={save}>
          {t('buildings.actions.save')}
        </Button>
        {/* </Popconfirm> */}
      </Space>
    </div>
  )
}

const Editor = ({
  EditComponent,
  ViewComponent,
  editModeName,
  blockName,
  editPart,
  setEditPart,
  dataIsEmpty,
  cancel,
  save,
  verticalPadding,
  marginBottom,
  data,
  form,
}) => {
  const inEditMode = editModeName === editPart

  if (dataIsEmpty && !inEditMode) {
    return (
      <EditOverlay
        verticalPadding={verticalPadding}
        marginBottom={marginBottom}
        blockName={blockName}
        onClick={() => setEditPart(editModeName)}
      />
    )
  }

  if (inEditMode) {
    return (
      <EditWrapper
        inEditMode
        marginBottom={marginBottom}
      >
        <EditComponent data={data} blockName={blockName} editModeName={editModeName} />
        <ActionButtons cancel={cancel} save={save} />
      </EditWrapper>
    )
  }

  return (
    <EditWrapper
      marginBottom={marginBottom}
      onClick={() => {
        setEditPart(editModeName)
        /* eslint-disable-next-line */
        data.freeFrom = moment(data.freeFrom);
        form.setFieldsValue(data)
      }}
    >
      <ViewComponent data={data} />
    </EditWrapper>
  )
}

export { Editor }
