import EmailOutlineIcon from '@2fd/ant-design-icons/lib/EmailOutline'
import LockOutlineIcon from '@2fd/ant-design-icons/lib/LockOutline'
import { useMutation } from '@apollo/react-hooks'
import { Link } from '@reach/router'
import { Button, Checkbox, Col,Form, Input, message, Row, Typography } from 'antd'
import AccentedText from 'components/Typography/AccentedText'
import React, { useEffect, useMemo,useReducer, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import OtpInput from 'react-otp-input'
import { useTimeoutFn } from 'react-use'
import { useGlobalState } from 'state'

import styles from '../styles.less'
import { RESET_PASSWORD } from './graphql'

const initLoginState = {
  email: '',
  code: '',
  password: '',
}

const reducer = (state, action) => ({ ...state, ...action })

export function Forgot() {
  const { t } = useTranslation()
  const [, dispatch] = useGlobalState()
  const [formState, dispatchFormState] = useReducer(reducer, initLoginState)
  const [formIsValid, setFormIsValid] = useState(false)
  
  const [form] = Form.useForm()

  // Reset password
  const [resetRaw, { loading }] = useMutation(RESET_PASSWORD)
  const [codeWasSent, setCodeWasSent] = useState(false)
  const reset = async () => {
    resetRaw({
      variables: formState,
    }).then(({ data, errors }) => {
      if (data.data?.token) {
        // Password changed
        localStorage.setItem('token', data.data.token)
        dispatch({ type: 'LOGIN', payload: true })
      } else if (!loading && errors?.length) {
        // Error
        message.error(errors?.[0]?.message)
      } else {
        // Code was sent
        message.info('Code was sent')
        setCodeWasSent(true)
        focusableRef.current.focus()
      }
    })
  }

  const setFormValue = (e) => {
    dispatchFormState({ [e.target.name]: e.target.value })
  }

  const validateForm = async () => {
    try {
      await form.validateFields(['email', 'password'])
      return []
    } catch (res) {
      return res.errorFields
    }
  }
  
  const formIsEmpty = useMemo(() => Object.values(formState).filter(value => !!value).length === 0, [formState])

  useEffect(() => {
    const f = async () => {
      if (!formIsEmpty) {
        const errors = await validateForm()
        setFormIsValid(!errors.length)
      } else {
        setFormIsValid(true)
      }
    }
    f()
  }, [formState])

  // Focus on form after animation complate
  const [alreadyFocused, setAlreadyFocused] = useState(false)
  const focusableRef = useRef(null)
  useTimeoutFn(() => !alreadyFocused && focusableRef.current.focus(), 2500)

  // OTP code
  const otpRef = useRef(null)
  const left = useMemo(() => 6 - formState.code.length, [formState.code])
  const submitText = useMemo(() => (
    (left > 0 && codeWasSent)
      ? t('auth.verify.submit.left', { left })
      : t('auth.verify.submit.continue')
  ), [left, codeWasSent])

  return (
    <>
      {/* SEO */}
      <Helmet>
        <title>{t('auth.forgot-password.title')}</title>
      </Helmet>

      {/* Page */}
      <Typography.Title><AccentedText text={t('auth.forgot-password.title')} /></Typography.Title>
      <Typography.Paragraph>{t('auth.forgot-password.welcome-message')}</Typography.Paragraph>
      <Form
        form={form}
        size="large"
        className={styles.form}
        validateTrigger="onChange"
      >
        {/* Email */}
        <Form.Item name="email" rules={[{ required: true }]}>
          <Input 
            name="email"
            type="email"
            value={formState.email}
            disabled={codeWasSent}
            onChange={setFormValue}
            prefix={<EmailOutlineIcon />}
            placeholder={t('fields.email.enter')}
            ref={!formState.email || formState.password ? focusableRef : undefined}
            onFocus={() => setAlreadyFocused(true)}  
          />
        </Form.Item>

        {/* New password */}
        { codeWasSent ? <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password 
            name="password" 
            type="password" 
            value={formState.password}
            onChange={setFormValue}
            prefix={<LockOutlineIcon />} 
            placeholder={t('fields.password.new')} 
            ref={formState.email && !formState.password ? focusableRef : undefined}
            onFocus={() => setAlreadyFocused(true)}  
          />
        </Form.Item> : null }

        {/* OTP */}
        { codeWasSent ? (
          <div className={styles.verify} style={{ marginBottom: 15 }}>
            <Form.Item name="code" rules={[{ required: true }]} help={t('auth.verify.welcome-message')}>
              <OtpInput
                ref={otpRef}
                value={formState.code}
                onChange={(value) => setFormValue({ target: { name: 'code', value }})}
                numInputs={6}
                inputStyle="ant-input ant-input-lg"
                containerStyle={styles.verifyContainer}
                disabled={loading}
                shouldAutoFocus
                isInputNum
                onFocus={() => setAlreadyFocused(true)}
              />
            </Form.Item> 
          </div> 
        ) : null }

        {/* Navigation */}
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={!formIsValid || formIsEmpty || (codeWasSent && left !== 0)} loading={loading} onClick={reset} block>
            {submitText}
          </Button>

          <Typography.Paragraph className={styles.navigation}>
            {t('auth.forgot-password.navigation-message')} <Link to="/auth">{t('auth.sign-in.title')}</Link>
          </Typography.Paragraph>
        </Form.Item>
      </Form>
    </>
  )
}
