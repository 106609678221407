import { Form,Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CodeEdit = () => {
  const { t } = useTranslation()
  return (
    <Form.Item label={t('buildings.fields.id')} name="code">
      <Input />
    </Form.Item>
  )
}

const CodeView = ({ data }) => {
  const { t } = useTranslation()
  return (
    <h4 style={{ margin: 0 }}>
      {t('buildings.fields.id')}: <span style={{ textTransform: 'uppercase'}}>{data.code}</span>
    </h4>
  )
}

export { CodeEdit, CodeView }
