import { USER_ROLES } from '_graphql/_enums'
import { Tag, Typography } from 'antd'
import { useProject } from 'common/ProjectProvider'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import stc from 'string-to-color'

export const isTenant = (role) => /^TENANT_/.test(role)
export const isLandlord = (role) => /^LANDLORD_/.test(role)
export const isAdmin = (role) => /^(SUPER_|B2B_)/.test(role)
export const isOwnOwner = (role) => isAdmin(role) || /(_ADMIN|_OWNER)$/.test(role)
export const isSlaveOwner = (role) => !isAdmin(role) && isOwnOwner(role)

export const detectRoleGroup = (role) => (isAdmin(role) ? 'B2B' : isLandlord(role) ? 'LANDLORD' : 'TENANT')
export const detectOwnerRole = (role) => {
  const group = detectRoleGroup(role)
  if (group === 'LANDLORD') {
    return 'LANDLORD_OWNER'
  } if (group === 'TENANT') {
    return 'TENANT_ADMIN'
  }
  return undefined
}

export const useRoles = () => {
  const { t } = useTranslation()
  const roles = Object.keys(t('user.fields.role.enum', { returnObjects: true }))
  return {
    roles,
    groups: {
      B2B: roles.filter(isAdmin),
      LANDLORD: roles.filter(isLandlord),
      TENANT: roles.filter(isTenant),
    },
  }
}

function UserRole(props) {
  const { role, type = 'badge', monochrome = false, forceShort = false, ...antProps } = props
  const { t } = useTranslation()
  const project = useProject()
  const [state] = useGlobalState()
  const { currentUser } = state
  const color = useMemo(() => (!monochrome ? getRoleColor({ role }) : 'rgba(0,0,0,.45)'), [role, monochrome])
  const title = useMemo(() => {
    // Tenant
    if (!forceShort && isTenant(role)) {
      if (isLandlord(currentUser.role)) {
        return t('user.fields.role.groups.TENANT')
      }

      if (isAdmin(currentUser.role)) {
        return `${t('user.fields.role.groups.TENANT')} ${USER_ROLES[role].title}`
      }
    }

    // Landlord
    if (!forceShort && isLandlord(role)) {
      if (isTenant(currentUser.role)) {
        return t('user.fields.role.groups.LANDLORD')
      }

      if (isAdmin(currentUser.role)) {
        return `${t('user.fields.role.groups.LANDLORD')} ${USER_ROLES[role].title}`
      }
    }

    // Admin
    if (!forceShort && isAdmin(role)) {
      if (!isAdmin(currentUser.role)) {
        return t('user.fields.role.alternative.B2B', { project: project.title })
      }
    }

    // Full title
    return USER_ROLES[role].title
  }, [role, currentUser])

  if (type === 'badge') {
    return <Tag color={color} {...antProps}>{title}</Tag>
  }

  if (type === 'text') {
    return (
      <Typography.Text style={{ color }} {...antProps}>
        {title}
      </Typography.Text>
    )
  }

  return role
}

export function UserRoleGroup(props) {
  const { type, group, monochrome, ...antProps } = props
  const { t } = useTranslation()
  const project = useProject()
  const color = useMemo(() => (!monochrome ? getRoleColor({ group }) : 'rgba(0,0,0,.45)'), [group, monochrome])
  const title = useMemo(() => t(`user.fields.role.groups.${group}`, { project: project.title }), [])

  if (type === 'badge') {
    return <Tag color={color} {...antProps}>{title}</Tag>
  }

  if (type === 'text') {
    return (
      <Typography.Text style={{ color }} {...antProps}>
        {title}
      </Typography.Text>
    )
  }

  return group
}

export function getRoleColor(props) {
  return stc(props.role || props.group)
}

export default UserRole
