import { isAdmin, isLandlord, roles } from '_graphql/_enums'
import { ENTRIES_LIST } from '_graphql/entry'
import {
  CloudServerOutlined,
  CopyOutlined,
  CrownOutlined,
  DeleteOutlined,
  FolderViewOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Link,navigate } from '@reach/router'
import { Button, Modal,Popconfirm, Tag,Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import { ListManager } from 'components/ListManager'
import { cost } from 'components/ListManager/DefaultTypes'
import { string } from 'components/ListManager/DefaultTypes'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

const EntriesManager = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [onSave] = useState(false)
  const isManagementUser = isAdmin(state.currentUser?.role)
  const { openModal } = useModals()

  return (
    <>
      <ListManager
        slug="bills-manager"
        title={t('invoices.table.title')}
        query={ENTRIES_LIST}
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('invoices.table.sorting.idDesc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('invoices.table.sorting.idAsc'),
              icon: <SortAscendingOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('invoices.table.filters.all'),
              slug: 'all',
              value: {},
            },
          ],
          filters: [],
        }}
        table={{
          scroll: { x: 100, y: 250 },
          columns: [
            {
              title: t('invoices.table.sku'),
              key: 'sku',
              options: { na: true },
              render: ({ value, options, item }) => item?.sku?.slice(0, 8),
            },
            {
              title: t('invoices.table.reference'),
              key: 'reference',
              render: 'string',
            },
            {
              title: t('invoices.table.recipient'),
              key: 'recipient',
              render: 'string',
            },
            {
              title: t('invoices.table.grossPrice'),
              key: 'grossPrice',
              render: ({ item }) => {
               
                return (
                  <Tooltip
                    placement="top"
                    title={(
                      <>
                        <strong>{t('invoices.table.grossPrice')}:</strong> <span>{cost({ value: item.grossPrice })}</span><br />
                        <strong>{t('invoices.table.netPrice')}:</strong> <span>{cost({ value: item.netPrice })}</span><br />
                        <strong>{t('invoices.table.tax')}:</strong> <span>{item.vat}%</span>
                      </>
                    )}
                  >
                    {cost({ value: item.grossPrice })}
                  </Tooltip>
                )
              },
            },
            {
              title: t('invoices.table.paymentDate'),
              key: 'paymentDate',
              render: ({ item }) => { 
                
                const activeFrom = item?.paymentDate
                  ? DateTime.fromISO(item?.paymentDate)
                  : null
                
                return activeFrom ? activeFrom?.toFormat('yyyy LLL dd') : ''
              },
            },
            {
              title: t('invoices.table.invoiceDate'),
              key: 'invoiceDate',
              render: ({ item }) => { 
                
                const activeFrom = item?.invoiceDate
                  ? DateTime.fromISO(item?.invoiceDate)
                  : null
                
                return activeFrom ? activeFrom?.toFormat('yyyy LLL dd') : ''
              },
            },
            {
              title: t('invoices.table.bookingDate'),
              key: 'bookingDate',
              render: ({ item }) => { 
                
                const activeFrom = item?.bookingDate
                  ? DateTime.fromISO(item?.bookingDate)
                  : null
                
                return activeFrom ? activeFrom?.toFormat('yyyy LLL dd') : ''
              },
            },
            {
              title: t('invoices.table.method.title'),
              key: 'status',
              options: { na: true },
              render: ({ value, options, item }) => (<Tag>{ item.method != 1 ? t('invoices.table.method.recurring') : t('invoices.table.method.bill') }</Tag>),
            },
          ],
        }}
        {...props}
      />
    </>
  )
}

export { EntriesManager }
