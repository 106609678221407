import { PROJECT_LIST } from '_graphql/project'
import Eye from '@2fd/ant-design-icons/lib/Eye'
import Plus from '@2fd/ant-design-icons/lib/Plus'
import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Select, Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectPage } from 'screens'
import { ProfileDisplay, ProfileForm } from 'screens/ProfilesManager'
import { ProjectForm } from 'screens/ProjectsManager/ProjectForm'

function ProjectSelect({ 
  userId = null,
  value = null,
  onChange,
  ...props
}) {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(!!value)
  const { openModal } = useModals()

  const [fetch, { data, loading }] = useLazyQuery(PROJECT_LIST)

  useEffect(() => {
    if ((userId || userId === null) && (value || isFocused)) {
      fetch({
        variables: {
          page: 1,
          pageSize: 1000,
          filter: JSON.stringify(userId ? {
            users: [userId],
          } : {}),
        }
      })
    }
  }, [value, isFocused, userId])

  const setValue = (value) => {
    onChange?.(value)
  }

  return (
    <div style={{ display: 'flex' }}>
      <Select
        showSearch
        loading={loading}
        disabled={!userId && userId !== null}
        onChange={setValue}
        onFocus={() => { !isFocused && setIsFocused(true) }}
        value={value}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...props}
      >
        <Select.Option value={null}>{t('rental-request.components.sales-select.not-selected')}</Select.Option>
        {data?.response?.list?.map?.((project) => (
          <Select.Option value={project.id} key={project.id}>
            {project.title} (#{project.id})
          </Select.Option>
        ))}
      </Select>
      <div>
        {/* Show project */}
        {value ? (
          <Tooltip title="View project" placement="right">
            <Button
              type="text" 
              icon={<Eye />} 
              onClick={() => {
                openModal(ProjectPage, {
                  sku: data?.response?.list?.find?.((project) => (project.id === value))?.sku,
                  onClose() {
                    fetch()
                  }
                })
              }}
            />
          </Tooltip>
        ) : null}
        {/* Create project */}
        {!value ? (
          <Tooltip title="Create project" placement="right">
            <Button 
              type="text" 
              icon={<Plus />} 
              disabled={!userId}
              onClick={() => {
                openModal(ProjectForm, {
                  userId,
                  onClose(id) {
                    if (id) {
                      fetch()
                      setValue(id)
                    }
                  }
                })
              }}
            />
          </Tooltip>
        ) : null}
      </div>
    </div>
  )
}

export { ProjectSelect }
