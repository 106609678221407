import { gql } from 'apollo-boost'

import { notificationFragment, userFragment } from '../_fragments'

export const NOTIFICATIONS_LIST = gql`
    query listNotifications($page: Int!, $pageSize: Int!, $filter: JSON) {
      response: listNotifications(page: $page, pageSize: $pageSize, filter: $filter) {
        list {
          ...notificationFragment
          user {
            ...userFragment
          }
        }
        pagination {
          page
          pageSize
          total
        }  
      }
  }
  ${notificationFragment}
  ${userFragment}
`
export const NOTIFICATION_ADDED = gql`
  subscription notificationAdded  {
    response: notificationAdded {
      ...notificationFragment
    }
  }
  ${notificationFragment}
`

export const NOTIFICATION_READED = gql`
  subscription notificationReaded  {
    response: notificationReaded {
      ...notificationFragment
    }
  }
  ${notificationFragment}
`

export const NOTIFICATIONS_STATISTICS = gql`
    query statisticsNotifications {
      response: statisticsNotifications {
        dates {
          date,
          metrics {
            type,
            count
          }
        }
      }
  }
`
