import { isAdmin, ROLE_GROUPS, roles, USER_ROLES } from '_graphql/_enums'
import { LOG_LIST } from '_graphql/log/queries'
import { CopyOutlined, CrownOutlined, DeleteOutlined, PlusOutlined,SortAscendingOutlined, SortDescendingOutlined, UserAddOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Link,navigate } from '@reach/router'
import { Button, Popconfirm, Tooltip } from 'antd'
import { ListManager } from 'components/ListManager'
import { string } from 'components/ListManager/DefaultTypes'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import { CountryRender } from 'utils/countries'
import { deepOmit } from 'utils/deepOmit'

import { LogDisplay } from './LogDisplay'

const LogsManager = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  // const isManagementUser = isAdmin(state.currentUser?.role)
  // const [refetchStateRaw, refetch] = useState()
  // const refetchState = useMemo(() => {
  //   return [refetchStateRaw, refetchStateProp]
  // }, [refetchStateRaw, refetchStateProp])


  return (
    <>
      <ListManager
        slug="logs-manager"
        title={t('logs.manager-title')}
        query={LOG_LIST}
        // refetch={refetchState}
        queryParams={ props.referenceId }
        actions={[
        ]}
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('profiles.sorting.id|desc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('profiles.sorting.id|asc'),
              icon: <SortAscendingOutlined />,
            },
            {
              slug: 'userId|desc',
              title: t('profiles.sorting.userId|desc'),
              icon: <UserSwitchOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('logs.filters.presets.all'),
              slug: 'all',
              value: {},
            },
          ],
          filters: [
          
          ],
        }}
        table={{
          columns: [
            {
              title: t('logs.fields.id'),
              key: 'id',
              options: { na: true },
              render: 'id',
            },
            
            {
              title: t('logs.fields.user'),
              key: 'user',
              render: 'user',
              middleware: () => isAdmin(state.currentUser?.role),
            },
            {
              title: t('logs.fields.type'),
              key: 'referenceType',
              options: { na: true },
              render: 'string',
            },
            {
              title: t('logs.fields.referenceId'),
              key: 'referenceId',
              options: { na: true },
              render: 'string',
            },
          ],
          row: {
            expandable: ({ item }) => <LogDisplay log={item} />,
          },
        }}
        {...props}
      />
    </>
  )
}

export { LogsManager }
