import './tiles-mode.css'

import { Empty } from 'antd'
import React from 'react'

/**
 * Рендер Tiles
 */
export const TilesRenderMode = ({ tiles: Tiles, data, dataRefetch, handlers: rawHandlers }) => {
  const context = { dataRefetch }

  /** Empty list */
  if (data.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: '102.5px 0 49px 0' }} />
  }

  /** Render */
  return (
    <>
      {data.map((item) => (<Tiles key={item?.id + '-'} item={item} context={context} handlers={Object.fromEntries(Object.entries(rawHandlers).map(([name, handler]) => [name, () => handler({ item })]))} />))}
    </>
  )
  // return data.map((item) => tiles({
  //   item,
  //   context,
  //   handlers: Object.fromEntries(Object.entries(rawHandlers).map(([name, handler]) => [name, () => handler({ item })])),
  // }))
}
