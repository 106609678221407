import cloneDeep from 'lodash/cloneDeep'
import React, { useState } from 'react'

import * as DefaultFilters from './DefaultFilters'

/**
 * Блок фильтрации | TODO: Оптимизровать стек вызовов
 */
export const FilterForm = ({ filter, filterValues: values, setFilterValues: setValues }) => {
  const [renderHandlers, setRenderHandlers] = useState({})
  const [filterStateValues, setFilterStateValues] = useState({})

  /** Установка значения */
  const setFilterValue = (name) => (value) => {
    setValues({ ...values, [name]: value })
  }

  /** Получение функции рендеринга */
  const getRenderHandler = (item) => {
    if (renderHandlers[item.slug]) {
      return renderHandlers[item.slug]
    }

    const handler = DefaultFilters[item.type](item)
    setRenderHandlers({ ...renderHandlers, [item.slug]: handler })
    return handler
  }

  return (
    <div className="list-manager-filter">
      {filter.filters.filter(({ middleware }) => middleware === undefined || middleware === true || (middleware !== false && middleware())).map((item) => (
        <span key={item.slug}>
          {getRenderHandler(item).filterRender({
            value: values[item.slug],
            setValue: setFilterValue(item.slug),
            filterState: filterStateValues[item.slug],
            setFilterState: (value) => setFilterStateValues({ ...filterStateValues, [item.slug]: value }),
          })}
        </span>
      ))}
    </div>
  )
}
