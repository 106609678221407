import { PROJECT_CITIES_LIST } from '_graphql/project'
import { List } from 'antd'
import i18next from 'i18next'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'

export const projectsCitiesPreset = {
  keyField: 'id',
  sorting: 'id|asc',
  query: PROJECT_CITIES_LIST,

  valuesPreview: ({ filterState, value: rawIds }) => {
    const collection = filterState?.collection ?? {}
    const value = (rawIds ?? []).filter((id) => !!collection[id])

    // Empty list
    if (value.length === 0) {
      return {
        isDisabled: true,
        render: i18next.t('list-manager.filters.all'),
      }
    }

    return {
      isDisabled: false,
      render: value.map((id) => {
        const item = collection[id]
        return item.title
      }).join(', '),
    }
  },

  itemRender: ({ item }) => {
    return (
      <List.Item.Meta
        className="widget-projects-cities-select"
        title={item.title}
      />
    )
  },
}