import { FINISH_TUTORIAL_MUTATION, GET_TUTORIALS } from '_graphql/tutorials'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useTutorialUI } from 'components/TutorialUI'
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react'

export const TutorialsContext = createContext({
  data: {},
  getTutorialByType: () => {},
  startTutorial: () => {},
})

export const TutorialsProvider = ({ children }) => {
  const { data: tutorialsData, refetch: getTutorials } = useQuery(GET_TUTORIALS)
  const [finishTutorial] = useMutation(FINISH_TUTORIAL_MUTATION)
  const tutorialUI = useTutorialUI()

  const data = useMemo(() => {
    return tutorialsData?.response?.data?.reduce?.((acc, tutorial) => {
      return {
        ...acc,
        [tutorial.type]: tutorial
      }
    }, {}) || {}
  }, [tutorialsData])

  const getTutorialByType = useCallback((name) => {
    return value?.data?.[name]
  }, [data])

  const startTutorial = useCallback((data) => {
    const tutorial = getTutorialByType(data?.type)

    if (!tutorial) {
      return
    }

    tutorialUI.setSteps(data)
  }, [getTutorialByType, tutorialUI.setSteps])

  const value = useMemo(() => {
    return {
      data,
      getTutorialByType,
      startTutorial,
    }
  }, [data])

  useEffect(() => {
    const onFinishHandler = async (data) => {
      if (!data.type) {
        return
      }

      await finishTutorial({
        variables: {
          type: data.type
        }
      })

      await getTutorials()
    }

    tutorialUI.events.addListener('onFinish', onFinishHandler)

    return () => {
      tutorialUI.events.removeListener('onFinish')
    }
  }, [data, finishTutorial, getTutorials])

  return (
    <TutorialsContext.Provider
      value={value}
    >
      {children}
    </TutorialsContext.Provider>
  )
}

export const useTutorials = () => useContext(TutorialsContext)
