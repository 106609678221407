import { serviceRequestFragment } from '_graphql/_fragments/serviceRequest'
import { gql } from 'apollo-boost'

export const SERVICE_REQUESTS_LIST = gql`
  query listServiceRequests($page: Int!, $pageSize: Int!, $sorting: String, $search: String, $filter: JSON) {
    response: listServiceRequests(page: $page, pageSize: $pageSize, sorting: $sorting, search: $search, filter: $filter) {
      list {
        ...serviceRequestFragment
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${serviceRequestFragment}
`

export const GET_SERVICE_REQUEST = gql`
  query getServiceRequest($id: Int!) {
    response: getServiceRequest(id: $id) {
      ...serviceRequestFragment
    }
  }
  ${serviceRequestFragment}
`

export const SUBSCRIBE_SERVICE_REQUEST = gql`
  subscription serviceRequestChanged($id: Int!)  {
    response: serviceRequestChanged(id: $id) {
      ...serviceRequestFragment
    }
  }

  ${serviceRequestFragment}
`