import { gql } from 'apollo-boost'

export const floorPlanFragment = gql`
  fragment floorPlanFragment on FloorPlan {
    id
    filename
    mimetype
    encoding
    url
    apartmentId
  }
`

export const photoFragment = gql`
  fragment photoFragment on Photo {
    id
    filename
    mimetype
    encoding
    url
    apartmentId
    buildingId
    order
  }
`
