import { gql } from 'apollo-boost'

import { billRunFragment } from '../_fragments'

export const BR_UPSERT_MUTATION = gql`
  mutation billRun($input: BillRunInput!) {
    response: billRun(input: $input) {
      ...billRunFragment
    }
  }
  ${billRunFragment}
`

export const BR_DELETE_MUTATION = gql`
  mutation deleteBR($sku: String) {
    response: deleteBR(sku: $sku)
  }
`
