import React from 'react'
import { useTranslation } from 'react-i18next'

import { EditOverlayStyled } from '../styled'

const EditOverlay = ({
  verticalPadding,
  marginBottom,
  onClick,
  blockName,
}) => {
  const { t } = useTranslation()
  return (
    <EditOverlayStyled verticalPadding={verticalPadding} marginBottom={marginBottom} onClick={onClick}>
      <span>
        {t('apartments.actions.add-block', { blockName })}
      </span>
    </EditOverlayStyled>
  )
}

export { EditOverlay }
