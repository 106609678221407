import { gql } from 'apollo-boost'

export const transactionFragment = gql`
  fragment transactionFragment on Transaction {
    id
    sku
    type
    status
    userIn
    userOut
    moveInDate
    moveOutDate
    contractId
    creatorId
    completedAt
    createdAt
    updatedAt
  }
`
