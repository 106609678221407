import { gql } from 'apollo-boost'

export const buildingFragment = gql`
  fragment buildingFragment on Building {
    id
    sku
    code
    floor
    code
    street
    number
    zipCode
    city
    addressDetails
    country
    areaDescription
    buildingDescription
    hasElevator
    hasRamp
    status
    isBusy
    lat
    long
    name
    places
    additionalInfo
    amenitiesOptions
    profileId
    userId
    createdAt
    updatedAt
  }
`
