import { Form, Input,Row } from 'antd'
import React from 'react'

const TitleEdit = (props) => (
  <Row style={{ marginBottom: '10px' }}>
    <Form.Item form={props.form} label={props.blockName} name={props.editModeName} style={{ width: '100%' }}>
      <Input.TextArea style={{ fontSize: '24px', fontWeight: '500' }} rows={1} />
    </Form.Item>
  </Row>
)

const TitleView = ({ data }) => (
  <h2 style={{ margin: 0 }}>{data?.title}</h2>
)

export { TitleEdit, TitleView }
