import { USER_UPSERT_MUTATION } from '_graphql'
import {
  isAdmin,
  isLandlord,
  isSingleGroup,
  isSlave,
  roles,
  USER_ROLES,
} from '_graphql/_enums'
import { PROJECT_LIST } from '_graphql/project'
import { USER_QUERY } from '_graphql/user/queries'
import { useLazyQuery,useMutation, useQuery } from '@apollo/react-hooks'
import { navigate, useLocation } from '@reach/router'
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
} from 'antd'
import { UserSelect } from 'components/Form/UserSelect'
import {
  LayoutCard,
  LayoutCardTitle,
  LayoutFooterActions,
  LayoutOverlay,
  LayoutStickyFooter,
} from 'components/Layout'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

const UserFormCreate = ({ onClose, isModal, availableRoles }) => {
  const { t } = useTranslation()
  const [upsertUser] = useMutation(USER_UPSERT_MUTATION)
  const [, rerender] = useState()

  const [fetchProjects, { data: projectsData, loading: projectsLoading }] = useLazyQuery(PROJECT_LIST, { variables: {
    page: 1,
    pageSize: 1000,
  } })

  const [state] = useGlobalState()

  const [form1] = Form.useForm()

  const location = useLocation()
  const query = useMemo(() => new URLSearchParams(location.search), [location.search])
  useEffect(() => {
    if (query.has('projectId')) {
      form1.setFieldsValue({
        projectId: parseInt(query.get('projectId')),
      })
    }

    if (query.has('role')) {
      form1.setFieldsValue({
        role: query.get('role'),
      })
    }
  }, [location.search])

  useEffect(() => {
    if (form1.getFieldValue('role') === roles.TENANT_EMPLOYEE && !projectsData) {
      fetchProjects()
    }
  }, [form1.getFieldValue('role')])

  const [saveTrigger, setSaveTrigger] = useState(false)

  const submitUserCreate = async () => {
    const input = form1.getFieldsValue([
      'firstName',
      'lastName',
      'email',
      'phone',
      'role',
      'projectId',
      'ownerId'
    ])

    if (!input.ownerId && isSlave(input.role)) {
      input.ownerId = state.currentUser.ownerId
      input.defaultProfile = state.currentUser.defaultProfile
    }

    const d = await upsertUser({ variables: { input } })

    if (d.errors?.length) {
      message.error(t('user.messages.errors.error-creating') + `: ${d?.errors?.[0]?.message}`)
    } else {
      message.success(t('user.messages.created-successfully'))
      goBack(d?.data?.response?.user?.id)
    }
  }

  const goBack = (id) => {
    if (onClose) {
      onClose?.(id)
    } else if (query.has('backUrl')) {
      navigate(query.get('backUrl'))
    } else {
      navigate('/users-management')
    }
  }

  return (
    <Form
      layout="vertical"
      form={form1}
      onChange={() => {
        setSaveTrigger(!saveTrigger)
      }}
    >
      <LayoutOverlay>
        <Row gutter={[20, 20]}>
          <Col xs={24} style={{ textAlign: 'left' }}>
            <h2 style={{ fontSize: '24px' }}>{t('user.form.new')}</h2>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={16}>
            <LayoutCard>
              <h2 style={{ fontSize: '24px' }}>{t('user.form.userInfo')}</h2>
              <Form.Item label={t('user.fields.firstName')} name="firstName">
                <Input />
              </Form.Item>
              <Form.Item label={t('user.fields.lastName')} name="lastName">
                <Input />
              </Form.Item>
              <Form.Item label={t('user.fields.email')} name="email">
                <Input />
              </Form.Item>
              <Form.Item
                label={t('user.fields.phone')}
                name="phone"
                rules={[
                  {
                    validator(_rule, value, callback) {
                      if (
                        value
                        && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(
                          value,
                        )
                      ) {
                        callback(
                          t('user.messages.errors.invalid-field', {
                            field: t('user.fields.phone'),
                          }),
                        )
                      }
                      callback()
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="role" label={t('user.fields.role')}>
                <Select onChange={rerender} disabled={query.has('role')}>
                  {Object.values(USER_ROLES)
                    .filter(
                      (role) => query.has('role') || state.currentUser.role === roles.SUPER_ADMIN || (
                        (isAdmin(state.currentUser.role))
                        || (!isAdmin(state.currentUser.role) && isSlave(role.id) && isSingleGroup(state.currentUser.role, role.id))
                      ),
                    )
                    .filter((role) => !availableRoles || availableRoles.includes(role.key))
                    .map((role) => (role.id !== roles.SUPER_ADMIN
                      || state.currentUser?.role === roles.SUPER_ADMIN ? (
                        <Select.Option value={role.id} key={role.id}>
                          {role.title}
                        </Select.Option>
                      ) : null))}
                </Select>
              </Form.Item>

              {form1.getFieldValue('role') && isSlave(form1.getFieldValue('role')) && isAdmin(state.currentUser.role) ? (
                <Form.Item rules={[{ required: true }]} name="ownerId" label={t('user.fields.owner')}>
                  <UserSelect roles={[
                    isLandlord(form1.getFieldValue('role')) 
                      ? USER_ROLES['landlord-owner'].key
                      : USER_ROLES['tentant-admin'].key
                  ]} />
                </Form.Item>
              ) : null}
            </LayoutCard>
          </Col>
          <Col xs={8}>
            {form1.getFieldValue('role') === roles.TENANT_EMPLOYEE ? (

              <LayoutCard>
                <h2 style={{ fontSize: '24px' }}>
                  {t('user.form.userProject')}
                </h2>
                <Form.Item name="projectId" label={t('user.form.project')}>
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('globalForms.select')}
                    disabled={projectsLoading || query.has('projectId')}
                  >
                    {projectsData?.response?.list?.map?.((project) => (
                      <Select.Option value={project.id} key={project.id}>
                        {project.title} (#{project.id})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </LayoutCard>

            ) : null}
          </Col>
        </Row>
        <LayoutStickyFooter>
          <LayoutFooterActions>
            <Button size="large" type="default" onClick={() => goBack()}>
              {t('wbs.form.cancel')}
            </Button>
            <Form.Item noStyle>
              <Button type="primary" size="large" onClick={submitUserCreate}>
                {t('user.actions.create')}
              </Button>
            </Form.Item>
          </LayoutFooterActions>
        </LayoutStickyFooter>
      </LayoutOverlay>
    </Form>
  )
}

export { UserFormCreate }
