import { isAdmin, isTenant, roles, USER_ROLES } from '_graphql/_enums'
import LeadPencil from '@2fd/ant-design-icons/lib/LeadPencil'
import { Button, Drawer, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { ApartmentSelect } from './ApartmentSelect'
import { BuildingSelect } from './BuildingSelect'
import { ProfileSelect } from './ProfileSelect'
import { UserSelect } from './UserSelect'

function LandlordChainPicker({
  disabled = false,
  value = null,
  onChange,
  ...props
}) {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()
  const [form] = Form.useForm()
  const [isOpened, setIsOpened] = useState(false)
  const [title, setTitle] = useState(null)
  const [, rerender] = useState()

  const role = state.currentUser?.role
  const formData = form.getFieldsValue()

  const landlordId = formData.landlordId || value.landlordId || (isTenant(role) ? state.currentUser?.ownerId : undefined) || undefined
  const profileId = formData.profileId || value.profileId || undefined
  const buildingId = formData.buildingId || value.buildingId || undefined
  const apartmentId = formData.apartmentId || value.apartmentId || undefined

  const reset = () => {
    form.resetFields()
    form.setFieldsValue({ ...value, landlordId, profileId, buildingId, apartmentId })
  }
  useEffect(reset, [value, state.currentUser])

  const onApply = (formData) => {
    // TODO: Validation
    onChange?.({ ...value, ...formData, landlordId, profileId, buildingId, apartmentId })
    setIsOpened(false)
  }
  useEffect(onApply, [])

  return (
    <>
      <div className={`ant-select ant-select-single ant-select-show-arrow ${disabled ? 'ant-select-disabled' : ''}`} style={{ width: '100%' }} disabled={disabled} onClick={() => disabled ? null : setIsOpened(true)} {...props}>
        <div className="ant-select-selector">
          <span className="ant-select-selection-item">
            {title === null ? t('rental-request.components.sales-select.not-selected') : title}
          </span>
        </div>
        <span className="ant-select-arrow" aria-hidden="true">
          <LeadPencil />
        </span>
      </div>
      <Drawer
        title="Landlord Chain"
        placement="left"
        closable={false}
        forceRender
        onClose={() => { setIsOpened(false); reset() }}
        visible={isOpened}
      >
        <Form 
          layout="vertical" 
          form={form} 
          onFieldsChange={rerender} 
          onFinish={onApply}
        >

          {/* Landlord */}
          {isAdmin(role) ? (
            <Form.Item name="landlordId" label="Landlord Company">
              <UserSelect roles={[ USER_ROLES['landlord-owner'].key ]} />
            </Form.Item>
          ) : null }

          {/* Profile */}
          <Form.Item name="profileId" label="Profile Entity">
            <ProfileSelect userId={landlordId} />
          </Form.Item>

          {/* Building */}
          <Form.Item name="buildingId" label="Building">
            <BuildingSelect profileId={profileId} />
          </Form.Item>

          {/* Apartment */}
          <Form.Item name="apartmentId" label="Apartment">
            <ApartmentSelect buildingId={buildingId} onTitle={setTitle} />
          </Form.Item>

          {/* Apply */}
          <Button type="primary" htmlType="submit" block>
            {t('rental-request.actions.update')}
          </Button>
        </Form>
      </Drawer>
    </>
  )
}

export { LandlordChainPicker }
