import { useProject } from 'common/ProjectProvider'
import React from 'react'

function AccentedText({ text, color = 'primary' }) {
  const project = useProject()
  const [, start, end] = /^(.* )(.*)$/s.exec(text) ?? [null, '', text]

  return (
    <>
      {start}
      <span style={{ color: project.palettes[color].primary }}>{end}</span>
    </>
  )
}

export default AccentedText
