import { gql } from 'apollo-boost'

export const logFragment = gql`
    fragment logFragment on Log {
      id
      context
      userId
      referenceType
      referenceId
      createdAt
    }
`
