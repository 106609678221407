import { colors } from '_colors'
import { CheckOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export const Icon = styled.div`
  /* max-height: 100%; */
  width: 50%;
  margin: 0 auto;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
  fill: ${colors.lightGray()};
  transition: fill 0.5s, transform 0.5s;
`

export const Content = styled.div`
  padding: 0 20px;
  flex-grow: 3;
  color: ${colors.lightGray()};

  p,
  h2 {
    color: ${colors.lightGray()};
    transition: color 0.2s;
  }
`

export const AmenityWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${(p) => p.option && 'border-color: transparent;'}
  margin-bottom: 2px;
  cursor: pointer;

  :hover {
    h2,
    p {
      color: ${colors.text()};
    }

    ${Icon} {
      transform: scale(0.95);

      fill: ${colors.text()};
    }

    ${(p) => p.selected
      && `
      h2, p {
        color: ${colors.text()};
      }
    ${Icon} {
        fill: ${colors.main()};
      }
    `}
  }

  ${(p) => p.selected
    && `
      h2, p {
        color: ${colors.text()};
      }

      ${Icon} {
        fill: ${colors.main()};
        transform: scale(0.95);
      }
    `}
`

export const Check = styled(CheckOutlined)`
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 20px;
  opacity: ${(p) => (p.selected ? 1 : 0)};
  transition: opacity 0.3s;
  color: ${colors.main()};
`

export const OptionsWrapper = styled.div`
  height: ${(p) => (p.collapsed ? 0 : 'auto')};
  overflow: hidden;
  /* padding: 0 20px; */
`

export const AmenityBorder = styled.div`
  border: solid 2px ${(p) => (p.selected ? colors.gray() : colors.veryLightGray())};
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
`
