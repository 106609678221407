import { USER_UPSERT_MUTATION } from '_graphql'
import { isAdmin, isSingleGroup, USER_ROLES } from '_graphql/_enums'
import { FIX_USERS, USERS_LIST } from '_graphql/user/queries'
import { CrownOutlined, ReloadOutlined, SortAscendingOutlined, SortDescendingOutlined, UserAddOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Link } from '@reach/router'
import { Button, message, Tag, Tooltip } from 'antd'
import { ListManager } from 'components/ListManager'
import { string } from 'components/ListManager/DefaultTypes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

const UsersManager = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)


  const [runFix, { data: result }] = useLazyQuery(FIX_USERS, { fetchPolicy: 'no-cache' })

  /** Активация/деактивация пользователя */
  const [upsertUser] = useMutation(USER_UPSERT_MUTATION)
  const toggleUserStatus = (item, context) => upsertUser({ variables: { input: { id: item.id, isActive: !item.isActive } } }).then((upsertion) => {
    if (upsertion?.data?.response) {
      message.success(t(`user.messages.activation-status-changed.${!item.isActive ? 'activated' : 'deactivated'}`))
      context.dataRefetch()
    }
  })

  const fixUsers = async () => {
    const res = await runFix()

    message.success(res.data.response)
  }

  return (
    <>
      <ListManager
        slug="users-manager"
        title={t('user.manager-title')}
        query={USERS_LIST}
        actions={[
          <Link key="create" to="/users-management/create">
            <Button type="primary" icon={<UserAddOutlined />} htmlType="span">{t('user.actions.create-link')}</Button>
          </Link>
        ]}
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('user.sorting.id|desc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('user.sorting.id|asc'),
              icon: <SortAscendingOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('user.filters.all-preset'),
              slug: 'all',
              value: {},
            },
            ...Object.values(USER_ROLES).filter(
              (role) => isManagementUser || isSingleGroup(state.currentUser?.role, role.id)
            ).map((role) => ({
              title: role.title,
              slug: role.id,
              value: { role: [role.key] },
            })),
          ],
          filters: [
            {
              title: t('user.filters.role'),
              icon: <CrownOutlined />,
              slug: 'role',
              type: 'tags',
              middleware: isManagementUser,
              options: {
                valuesPreset: 'role',
              },
            },
          ],
        }}
        table={{
          columns: [
            {
              title: t('user.fields.name'),
              key: 'name',
              options: { na: true },
              render: ({ options, item }) => <Link to={`/users-management/${item.id}`}>{ string({ value: (`${item.firstName} ${item.lastName}`).trim(), options }) }</Link>,
            },
            {
              title: t('user.fields.email'),
              key: 'email',
              options: { type: 'email' },
              render: 'link',
            },
            {
              title: t('user.fields.phone'),
              key: 'phone',
              options: { type: 'phone' },
              render: 'link',
            },
            {
              title: t('user.fields.role'),
              key: 'role',
              options: { na: true },
              render: ({ value, options }) => string({ value: <Tag color={USER_ROLES[value].color}>{USER_ROLES[value].title}</Tag>, options }),
            },
            {
              title: t('user.fields.profiles.title'),
              key: 'profiles',
              render: ({ value, item }) => (value.length > 0
                ? (value.map((profile, index) => <Link key={index} to={`/profiles-management/${profile.id}`}><span color="green">{profile.companyName}</span>,</Link>))
                : <Link to={`/profiles-management/create?userId=${item.id}`}><span>{t('user.fields.profiles.create-link')}</span></Link>),
            },
            {
              title: t('user.fields.status.title'),
              key: 'isActive',
              render: ({ value, item, context }) => (
                <span tabIndex="0" role="button" >
                  {!item.isBusy ? (value
                    ? (
                      <Tooltip title="Click to Deactivate User" style={{ cursor: 'pointer' }} onClick={() => toggleUserStatus(item, context)} onKeyPress={() => toggleUserStatus(item, context)}>
                        <Tag color="green">{t('user.fields.status.active')}</Tag>
                      </Tooltip>
                    )
                    : (
                      <Tooltip title="Click to Activate User" style={{ cursor: 'pointer' }} onClick={() => toggleUserStatus(item, context)} onKeyPress={() => toggleUserStatus(item, context)}>
                        <Tag color="red">{t('user.fields.status.not-active')}</Tag>
                      </Tooltip>
                    ))
                    : (value
                      ? <Tag color="green">{t('user.fields.status.active')}</Tag>
                      : <Tag color="red">{t('user.fields.status.not-active')}</Tag>
                    )
                  }
                </span>
              ),
            },
            {
              title: t('user.fields.isBusy.title'),
              key: 'isBusy',
              render: ({ value, item, context }) => (
                <span tabIndex="0" role="button" style={{ cursor: 'pointer' }} >
                  {value
                    ? <Tag color="red">{t('user.fields.isBusy.active')}</Tag>
                    : <Tag color="green">{t('user.fields.isBusy.not-active')}</Tag>
                  }
                </span>
              ),
            },
          ],
        }}
        {...props}
      />
    </>
  )
}

export { UsersManager }
