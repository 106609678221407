import { gql } from 'apollo-boost'

export const userFragment = gql`
  fragment userFragment on User {
    id
    ownerId
    projectId
    defaultProfile
    email
    phone
    role
    wbsId
    lastName
    firstName
    isActive
    isBusy
    webpushIds
    createdAt
    updatedAt
  }
`
