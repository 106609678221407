import { LoadingOutlined } from '@ant-design/icons'
import { Form, Input, InputNumber,Switch } from 'antd'
import { Gallery } from 'components/Gallery'
import React from 'react'
import { useGlobalState } from 'state'

import { FormWrapper } from './styled'

const BuildingForm = ({
  form,
  formState,
  handleSwitch,
  setFormValue,
  children,
  uploadImage,
  deleteImage,
  create,
}) => {
  const [state] = useGlobalState()

  if (!state.currentBuilding?.id && !create) {
    return <LoadingOutlined style={{ fontSize: '50px' }} />
  }

  return (
    <>
      <FormWrapper>
        <Gallery images={state.currentBuilding.photos || []} height={300} multiple uploadFunc={uploadImage} deleteFunc={deleteImage} notRounded />
        <Form form={form} layout="vertical" style={{ padding: '32px' }}>
          <h2 style={{ fontSize: '24px' }}>Building Settings</h2>
          <div
            style={{
              marginBottom: '25px',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              textAlign: 'center',
            }}
          >
            <Form.Item labelAlign="right" label="Elevator is present" name="hasElevator" style={{ width: '33.3333%' }}>
              <Switch
                size="large"
                checked={formState.hasElevator}
                name="hasElevator"
                onChange={(e) => handleSwitch(e, 'hasElevator')}
                data-test-id="hasElevator"
              />
            </Form.Item>
            <Form.Item labelAlign="right" label="Ramp is present" name="hasRamp" style={{ width: '33.3333%' }}>
              <Switch size="large" checked={formState.hasRamp} name="hasRamp" onChange={(e) => handleSwitch(e, 'hasRamp')} data-test-id="hasRamp" />
            </Form.Item>
            <Form.Item labelAlign="right" label="Status" name="status" style={{ width: '33.3333%' }}>
              <Switch size="large" checked={formState.status} name="status" onChange={(e) => handleSwitch(e, 'status')} data-test-id="status" />
            </Form.Item>
          </div>
          <div style={{ marginBottom: '25px' }}>
            <Form.Item label="Area Description" name="areaDescription" rules={[{ required: true }]}>
              <Input.TextArea
                size="large"
                value={formState.areaDescription}
                name="areaDescription"
                onChange={setFormValue}
                data-test-id="areaDescription"
              />
            </Form.Item>
          </div>
          <div style={{ marginBottom: '25px' }}>
            <Form.Item label="Building Description" name="buildingDescription" rules={[{ required: true }]}>
              <Input.TextArea
                size="large"
                value={formState.buildingDescription}
                name="buildingDescription"
                onChange={setFormValue}
                data-test-id="buildingDescription"
              />
            </Form.Item>
          </div>
          <div style={{ marginBottom: '25px' }}>
            <Form.Item label="Floors" name="floor" rules={[{ required: true }]}>
              <InputNumber
                style={{ width: '100%' }}
                size="large"
                value={formState.floor}
                name="floor"
                onChange={(v) => setFormValue({ target: { value: v, name: 'floor' } })}
                data-test-id="floor"
              />
            </Form.Item>
          </div>
          {children}
        </Form>
      </FormWrapper>
    </>
  )
}

export { BuildingForm }
