import './style.css'

import { Col, Row, Tabs } from 'antd'
import {
  LayoutCard,
} from 'components/Layout'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LogsManager } from 'screens/LogsManager'
import styles from 'styles/side-layout.less'

import { DBHealth } from './dbhealth'
import { Health } from './health'
import { System } from './system'
import { Versions } from './versions'

const Changelog = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState('SystemUpdate')
 
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {/** Header */}
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            {' '}
            {/** Title */}
            <LayoutCard>
              <h1 style={{ fontWeight: 600, fontSize: '20px', marginBottom: '0px' }}>
                {t('changelog.page.title')}
              </h1>

              <Tabs activeKey={activeTab} onChange={setActiveTab} className="dashboard-page__tabs">
                <Tabs.TabPane tab={t('changelog.page.systemUpdate')} key="SystemUpdate" />
                <Tabs.TabPane tab={t('changelog.page.systemConfig')} key="System" />
                
                <Tabs.TabPane tab={t('changelog.page.dbhealth')} key="DBHealth" />
                <Tabs.TabPane tab={t('changelog.page.globalLog')} key="GlobalLog" />
              </Tabs>
            </LayoutCard>
          </Col>
        </Row>

        {activeTab === 'GlobalLog' ? <LogsManager /> : null}
        {activeTab === 'System' ? <System /> : null}
        {activeTab === 'SystemUpdate' ? <Versions /> : null }
        {activeTab === 'DBHealth' ? <DBHealth /> : null }

      </div>
    </div>
  )
}

export { Changelog }
